import OrderAbandonedListingRouteParams from "../../common/routes/types/order-abandoned-listing.route-params";
import useRouteQueryParams from "../../../../common/hooks/use-route-query-params";
import OrderAbandonedListingFilter from "../common/types/order-abandoned-listing-filter";
import orderAbandonedListingFiltersService from "./order-abandoned-listing-filters.service";
import OrderAbandonedListingSortKey from "../common/types/order-abandoned-listing-sort-key";

const useOrderAbandonedListingFiltersDao = () => {
  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<OrderAbandonedListingRouteParams>();

  const getFilters = (): OrderAbandonedListingFilter[] => {
    return orderAbandonedListingFiltersService.getFilters(routeQueryParams);
  };

  const getPage = (): number | undefined => {
    return orderAbandonedListingFiltersService.getPage(routeQueryParams);
  };

  const getPageSize = (): number | undefined => {
    return orderAbandonedListingFiltersService.getPageSize(routeQueryParams);
  };

  const getSortKey = (): OrderAbandonedListingSortKey | undefined => {
    return orderAbandonedListingFiltersService.getSortKey(routeQueryParams);
  };

  const saveFilters = (
    filters: OrderAbandonedListingFilter[],
    sortKey: OrderAbandonedListingSortKey,
    page: number,
    pageSize: number
  ) => {
    const routeQueryParams =
      orderAbandonedListingFiltersService.createRouteQueryParams(
        filters,
        page,
        pageSize,
        sortKey
      );

    setRouteQueryParams(routeQueryParams);
  };

  return {
    getFilters,
    getPage,
    getPageSize,
    getSortKey,
    saveFilters,
  };
};

export default useOrderAbandonedListingFiltersDao;
