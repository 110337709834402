import { FC, useEffect, useState } from "react";
import DriverDetailsDriverPlanEntry from "../common/types/driver-details-plan-entry";
import driverDetailsActiveRoutePlansByRailyApiService from "./common/api/driver-details-active-route-plans-by-raily-api.service";
import DriverDetailsActiveRoutePlansByRailyPlanResponse, {
  DriverDetailsPlanByRailyPlanResponseData,
} from "./common/api/driver-details-active-route-plans-by-raily-plan.response";
import driverDetailsActiveRoutePlansByRailyPlanEntryFactory from "./common/driver-details-active-route-plans-by-raily-plan-entry.factory";
import LoaderComponent from "../../../../../../common/components/loader/loader.component";
import DriverDetailsActiveRoutePlansContentComponent from "../driver-details-active-route-plans-content.component";
import DriverDetailsCandidatureComponent from "../../candidature/driver-details-candidature.component";
import { useAppContext } from "../../../../../../context/app.context";
import UserRole from "../../../../../../common/types/user-role";

type DriverDetailsPlanByRailyProps = {
  driverUuid: string;
  isVisible: boolean;
  asCardComponent?: boolean;
};

const DriverDetailsPlanByRailyComponent: FC<DriverDetailsPlanByRailyProps> = (
  props
) => {
  const { user } = useAppContext();
  const [isPlanFetching, setIsPlanFetching] = useState(true);

  const [planEntries, setPlanEntries] = useState<
    DriverDetailsDriverPlanEntry[]
  >([]);

  const onPlanFetchSuccess = (
    responseData: DriverDetailsPlanByRailyPlanResponseData
  ) => {
    const planEntries =
      driverDetailsActiveRoutePlansByRailyPlanEntryFactory.createPlanEntries(
        responseData.entries,
        user?.roles.includes(UserRole.ADMIN) ?? false
      );

    setPlanEntries(planEntries);
  };

  const onPlanFetchFailure = () => {};

  const handlePlanResponse = (
    response: DriverDetailsActiveRoutePlansByRailyPlanResponse
  ) => {
    if (response.status === 200) {
      onPlanFetchSuccess(response.data);
      return;
    }

    onPlanFetchFailure();
  };

  const fetchPlan = () => {
    setIsPlanFetching(true);

    driverDetailsActiveRoutePlansByRailyApiService
      .fetchPlan(props.driverUuid)
      .then(handlePlanResponse)
      .catch(onPlanFetchFailure)
      .finally(() => setIsPlanFetching(false));
  };

  useEffect(() => {
    if (!props.isVisible || planEntries.length) {
      return;
    }

    fetchPlan();
  }, [props.isVisible, props.driverUuid]);

  if (isPlanFetching && !planEntries.length) {
    return (
      <div className="driver_details_active_route_plans_loader_wrapper">
        <LoaderComponent type="primary" />
      </div>
    );
  }

  return (
    <>
      <DriverDetailsCandidatureComponent
        driverUuid={props.driverUuid}
        isVisible={props.isVisible}
        onAcceptCandidatureSuccess={fetchPlan}
        currentPlanEntries={planEntries}
      />
      <DriverDetailsActiveRoutePlansContentComponent
        driverUuid={props.driverUuid}
        planEntries={planEntries}
        refetchPlan={fetchPlan}
        asCardComponent={props.asCardComponent}
      />
    </>
  );
};

export default DriverDetailsPlanByRailyComponent;
