import DriverEditRequest, {
  DriverEditRequestBody,
} from "./driver-edit.request";

const createBody = (request: DriverEditRequest): DriverEditRequestBody => {
  return {
    addresses: request.addresses,
    associations: request.associations,
    driver: request.driver,
    user: request.user,
  };
};

const driverEditRequestFactory = {
  createBody,
};

export default driverEditRequestFactory;
