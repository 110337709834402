import { FC, useEffect, useState } from "react";
import orderTranslationsHelper from "../../../../../../languages/order-translations.helper";
import ModalComponent from "../../../../../../common/components/modal/modal.component";
import SingleSelectComponent from "../../../../../../common/components/form/select/single-select/single-select.component";
import { OrderAddPassengerAddress } from "../../types/order-add-passenger";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import FormFieldComponent from "../../../../../../common/components/form/field/form-field.component";

type OrderAddHomeAddressModalProps = {
  isOpen: boolean;
  onClose: () => void;
  availableAddresses: OrderAddPassengerAddress[];
  onConfirm: (address: OrderAddPassengerAddress) => void;
};

const OrderAddHomeAddressModalComponent: FC<OrderAddHomeAddressModalProps> = (
  props
) => {
  const translations =
    orderTranslationsHelper.getAddTranslations().homeAddressModal;

  const [selectedAddress, setSelectedAddress] =
    useState<OrderAddPassengerAddress | null>(null);

  useEffect(() => {
    if (props.isOpen) {
      setSelectedAddress(props.availableAddresses[0] || null);
    }
  }, [props.isOpen, props.availableAddresses]);

  const handleAddressChange = (
    selectedValue: { value: OrderAddPassengerAddress } | null
  ) => {
    if (selectedValue) {
      setSelectedAddress(selectedValue.value);
    }
  };

  const handleConfirmClick = () => {
    if (selectedAddress) {
      props.onConfirm(selectedAddress);
      props.onClose();
    }
  };

  const addressOptions = props.availableAddresses.map((address) => ({
    value: address,
    label: address.displayName,
  }));

  return (
    <ModalComponent
      header={{ title: translations.headingText }}
      isOpen={props.isOpen}
      onClose={props.onClose}
      actions={[
        <ButtonComponent
          type="success"
          title={translations.confirmHomeAddressButtonTitle}
          onClick={handleConfirmClick}
          isDisabled={!selectedAddress}
        >
          {translations.confirmHomeAddressButtonText}
        </ButtonComponent>,
      ]}
    >
      <FormFieldComponent label={translations.messageText}>
        <SingleSelectComponent
          onChange={handleAddressChange}
          options={addressOptions}
          value={
            selectedAddress
              ? { value: selectedAddress, label: selectedAddress.displayName }
              : null
          }
        />
      </FormFieldComponent>
    </ModalComponent>
  );
};

export default OrderAddHomeAddressModalComponent;
