import appConfig from "../../../../../../../app.config";
import authService from "../../../../../../auth/common/auth.service";
import DriverContractAddTaxiAssociationsResponse from "./driver-contract-add-taxi-associations.response";
import DriverContractAddRequest from "./driver-contract-add.request";
import DriverContractAddResponse from "./driver-contract-add.response";

const addContract = (
  request: DriverContractAddRequest
): Promise<DriverContractAddResponse> => {
  const path = `/contracts/taxi-driver`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    body: JSON.stringify(request),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchTaxiAssociations = (
  driverUuid: string
): Promise<DriverContractAddTaxiAssociationsResponse> => {
  const path = `/drivers/${driverUuid}/taxi-driver-associations`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const driverContractAddApiService = {
  addContract,
  fetchTaxiAssociations,
};

export default driverContractAddApiService;
