export enum CargoTaxiContractAddRequestBillingModel {
  ABA = "ABA",
  SABS = "SABS",
  AB = "AB",
  OTHER = "OTHER",
}

export type CargoTaxiContractAddRequestBody = {
  allowChargeWhileApproaching: boolean;
  allowChargeWhileReturning: boolean;
  allowChargeDuringRide: boolean;
  discountLimit: number;
  validSince: Date;
  validTo: Date;
  disabled: boolean;
  model: CargoTaxiContractAddRequestBillingModel;
  distanceRate: number;
  haltingTimeRate: number;
  haltingTimeAfterMinutes: number;
  publishingAllowed: boolean;
  cargoCompanyId: string;
  taxiCorporationId: string;
  contactPassengerAfterMinutes: number;
  cancelByTaxiAllowed?: boolean;
  editByTaxiAllowed?: boolean;
};

type CargoTaxiContractAddRequest = {
  allowChargeWhileApproaching: boolean;
  allowChargeWhileReturning: boolean;
  allowChargeDuringRide: boolean;
  discountLimit: number;
  validSince: Date;
  validTo: Date;
  disabled: boolean;
  model: CargoTaxiContractAddRequestBillingModel;
  distanceRate: number;
  haltingTimeRate: number;
  haltingTimeAfterMinutes: number;
  publishingAllowed: boolean;
  cargoCompanyId: string;
  taxiCorporationId: string;
  contactPassengerAfterMinutes: number;
  cancelOrderByTaxiAllowed?: boolean;
  editOrderByTaxiAllowed?: boolean;
};

export default CargoTaxiContractAddRequest;
