import { FC } from "react";
import OrderOptimizerDetailsShowDrivingRouteComponent from "./elements/order-optimizer-details-show-driving-route.component";
import OrderOptimizerDetailsItem from "../common/types/order-optimizer-details-item";

type OrderOptimizerDetailsToolsProps = {
  details?: OrderOptimizerDetailsItem;
  isDriverSelected: boolean;
  isButtonClicked: boolean;
  handleAddHomeMapMarker: () => void;
};

const OrderOptimizerDetailsToolsComponent: FC<
  OrderOptimizerDetailsToolsProps
> = (props) => {
  return (
    <div className="order_optimizer_details_tools">
      <OrderOptimizerDetailsShowDrivingRouteComponent
        details={props.details!}
        onSuccess={props.handleAddHomeMapMarker}
        isButtonClicked={props.isButtonClicked}
        idDisabled={!props.isDriverSelected}
      />
    </div>
  );
};

export default OrderOptimizerDetailsToolsComponent;
