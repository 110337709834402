import DelegationListLoadParams, {
  DelegationListLoadParamsDelegationType,
  DelegationListLoadParamsSortKey,
  DelegationListLoadParamsState,
} from "../../../../common/services/delegation/list/delegation-list-load-params";
import DelegationListingDelegationStatus from "./types/delegation-listing-delegation-status";
import DelegationListingDelegationType from "./types/delegation-listing-delegation-type";
import DelegationListingFilter, {
  DelegationListingCargoCompanyNameFilter,
  DelegationListingCostFilter,
  DelegationListingCreationDateFilter,
  DelegationListingDelegationTypeFilter,
  DelegationListingEndDateFilter,
  DelegationListingFirstCsvDownloadDateFilter,
  DelegationListingFirstPdfDownloadDateFilter,
  DelegationListingStartDateFilter,
  DelegationListingStateFilter,
  DelegationListingTotalDistanceFilter,
} from "./types/delegation-listing-filter";
import DelegationListingFilterType from "./types/delegation-listing-filter-type";
import DelegationListingSortKey from "./types/delegation-listing-sort-key";

const createType = (
  type: DelegationListingDelegationType | undefined
): DelegationListLoadParamsDelegationType | undefined => {
  switch (type) {
    case DelegationListingDelegationType.AUTO:
      return DelegationListLoadParamsDelegationType.AUTO;
    case DelegationListingDelegationType.MANUAL:
      return DelegationListLoadParamsDelegationType.MANUAL;
    default:
      return undefined;
  }
};

const createState = (
  state: DelegationListingDelegationStatus | undefined
): DelegationListLoadParamsState | undefined => {
  switch (state) {
    case DelegationListingDelegationStatus.DELETED:
      return DelegationListLoadParamsState.DELETED;
    case DelegationListingDelegationStatus.DOWNLOADED:
      return DelegationListLoadParamsState.DOWNLOADED;
    case DelegationListingDelegationStatus.UNDOWNLOADED:
      return DelegationListLoadParamsState.UNDOWNLOADED;
    default:
      return undefined;
  }
};

const createOrder = (
  type: DelegationListingSortKey | null
): DelegationListLoadParamsSortKey | undefined => {
  switch (type) {
    case DelegationListingSortKey.COST_ASC:
      return DelegationListLoadParamsSortKey.COST_ASC;
    case DelegationListingSortKey.COST_DESC:
      return DelegationListLoadParamsSortKey.COST_DESC;
    case DelegationListingSortKey.CREATION_DATE_ASC:
      return DelegationListLoadParamsSortKey.CREATION_DATE_ASC;
    case DelegationListingSortKey.CREATION_DATE_DESC:
      return DelegationListLoadParamsSortKey.CREATION_DATE_DESC;
    case DelegationListingSortKey.DELEGATION_NUMBER_ASC:
      return DelegationListLoadParamsSortKey.DELEGATION_NUMBER_ASC;
    case DelegationListingSortKey.DELEGATION_NUMBER_DESC:
      return DelegationListLoadParamsSortKey.DELEGATION_NUMBER_DESC;
    case DelegationListingSortKey.END_DATE_ASC:
      return DelegationListLoadParamsSortKey.END_DATE_ASC;
    case DelegationListingSortKey.END_DATE_DESC:
      return DelegationListLoadParamsSortKey.END_DATE_DESC;
    case DelegationListingSortKey.FIRST_CSV_DOWNLOAD_ASC:
      return DelegationListLoadParamsSortKey.FIRST_CSV_DOWNLOAD_ASC;
    case DelegationListingSortKey.FIRST_CSV_DOWNLOAD_DESC:
      return DelegationListLoadParamsSortKey.FIRST_CSV_DOWNLOAD_DESC;
    case DelegationListingSortKey.FIRST_PDF_DOWNLOAD_ASC:
      return DelegationListLoadParamsSortKey.FIRST_PDF_DOWNLOAD_ASC;
    case DelegationListingSortKey.FIRST_PDF_DOWNLOAD_DESC:
      return DelegationListLoadParamsSortKey.FIRST_PDF_DOWNLOAD_DESC;
    case DelegationListingSortKey.START_DATE_ASC:
      return DelegationListLoadParamsSortKey.START_DATE_ASC;
    case DelegationListingSortKey.START_DATE_DESC:
      return DelegationListLoadParamsSortKey.START_DATE_DESC;
    case DelegationListingSortKey.TOTAL_DISTANCE_ASC:
      return DelegationListLoadParamsSortKey.TOTAL_DISTANCE_ASC;
    case DelegationListingSortKey.TOTAL_DISTANCE_DESC:
      return DelegationListLoadParamsSortKey.TOTAL_DISTANCE_DESC;
    case DelegationListingSortKey.WORKER_NAME_ASC:
      return DelegationListLoadParamsSortKey.WORKER_NAME_ASC;
    case DelegationListingSortKey.WORKER_NAME_DESC:
      return DelegationListLoadParamsSortKey.WORKER_NAME_DESC;
    case DelegationListingSortKey.CARGO_COMPANY_NAME_ASC:
      return DelegationListLoadParamsSortKey.CARGO_COMPANY_NAME_ASC;
    case DelegationListingSortKey.CARGO_COMPANY_NAME_DESC:
      return DelegationListLoadParamsSortKey.CARGO_COMPANY_NAME_DESC;
    default:
      return undefined;
  }
};

const create = (
  page: number,
  pageSize: number,
  filters: DelegationListingFilter[],
  sortKey: DelegationListingSortKey | null
): DelegationListLoadParams => {
  const startDateFilter = filters.find(
    (filter) => filter.type == DelegationListingFilterType.START_DATE
  )?.value as DelegationListingStartDateFilter["value"] | undefined;

  const endDateFilter = filters.find(
    (filter) => filter.type == DelegationListingFilterType.END_DATE
  )?.value as DelegationListingEndDateFilter["value"] | undefined;

  const creationDateFilter = filters.find(
    (filter) => filter.type == DelegationListingFilterType.CREATION_DATE
  )?.value as DelegationListingCreationDateFilter["value"] | undefined;

  const firstCsvDownloadDateFiltler = filters.find(
    (filter) => filter.type == DelegationListingFilterType.FIRST_CSV_DOWNLOAD
  )?.value as DelegationListingFirstCsvDownloadDateFilter["value"] | undefined;

  const firstPdfDownloadDateFilter = filters.find(
    (filter) => filter.type == DelegationListingFilterType.FIRST_PDF_DOWNLOAD
  )?.value as DelegationListingFirstPdfDownloadDateFilter["value"] | undefined;

  const costFilter = filters.find(
    (filter) => filter.type == DelegationListingFilterType.COST
  )?.value as DelegationListingCostFilter["value"] | undefined;

  const totalDistanceFilter = filters.find(
    (filter) => filter.type == DelegationListingFilterType.TOTAL_DISTANCE
  )?.value as DelegationListingTotalDistanceFilter["value"] | undefined;

  const delegationTypeFilter = filters.find(
    (filter) => filter.type == DelegationListingFilterType.DELEGATION_TYPE
  )?.value as DelegationListingDelegationTypeFilter["value"] | undefined;

  const stateFilter = filters.find(
    (filter) => filter.type == DelegationListingFilterType.STATE
  )?.value as DelegationListingStateFilter["value"] | undefined;

  const distanceMin = totalDistanceFilter
    ? totalDistanceFilter.from > 0
      ? totalDistanceFilter.from - 0.5
      : 0
    : undefined;

  const distanceMax = totalDistanceFilter
    ? totalDistanceFilter.to + 0.49
    : undefined;

  return {
    page: page,
    pageSize: pageSize,
    sortKey: createOrder(sortKey),
    delegationNumber: filters.find(
      (filter) => filter.type == DelegationListingFilterType.DELEGATION_NUMBER
    )?.value as string | undefined,
    type: createType(delegationTypeFilter),
    workerName: filters.find(
      (filter) => filter.type == DelegationListingFilterType.WORKER_NAME
    )?.value as string | undefined,
    cargoCompanyName: filters.find(
      (filter) => filter.type == DelegationListingFilterType.CARGO_COMPANY_NAME
    )?.value as DelegationListingCargoCompanyNameFilter["value"] | undefined,
    startDateSince: startDateFilter?.from,
    startDateTo: startDateFilter?.to,
    endDateSince: endDateFilter?.from,
    endDateTo: endDateFilter?.to,
    creationDateSince: creationDateFilter?.from,
    creationDateTo: creationDateFilter?.to,
    firstCsvDownloadDateSince: firstCsvDownloadDateFiltler?.from,
    firstCsvDownloadDateTo: firstCsvDownloadDateFiltler?.to,
    firstPdfDownloadDateSince: firstPdfDownloadDateFilter?.from,
    firstPdfDownloadDateTo: firstPdfDownloadDateFilter?.to,
    costMin: costFilter?.from,
    costMax: costFilter?.to,
    totalDistanceMin: distanceMin,
    totalDistanceMax: distanceMax,
    state: createState(stateFilter),
  };
};

const delegationListingLoadParamsFactory = {
  create,
};

export default delegationListingLoadParamsFactory;
