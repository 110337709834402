import { FC, useMemo } from "react";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import CardComponent from "../../../../../common/components/card/card.component";
import TableComponent from "../../../../../common/components/table/table.component";
import BillingRouteDetailsTableRow from "./types/billing-route-details-table-row";
import billingRouteDetailsHelper from "./billing-route-details.helper";
import { BillingSummaryData } from "../types/billing-form.data";

type BillingRouteDetailsProps = {
  billingSummaryData: BillingSummaryData | undefined;
};

const BillingRouteDetailsComponent: FC<BillingRouteDetailsProps> = (props) => {
  const createTableRow = (
    billingSummaryData: BillingSummaryData
  ): BillingRouteDetailsTableRow => {
    const amountForDistance =
      billingSummaryData.distance *
      billingSummaryData.contractDetails!.distanceRate;

    return {
      id: billingSummaryData.internalOrderId.toString(),
      value: {
        distance: (
          <div title={billingSummaryData.distance?.toString()}>
            {billingSummaryData.distance} KM
          </div>
        ),
        rateForDistance: (
          <div
            title={billingSummaryData.contractDetails?.distanceRate?.toString()}
          >
            {billingSummaryData.contractDetails?.distanceRate?.toFixed(2)} PLN
          </div>
        ),
        amountForDistance: (
          <div title={amountForDistance.toString()}>
            {amountForDistance.toFixed(2)} PLN
          </div>
        ),
        amountForChargeHaltings: (
          <div title={billingSummaryData.amountForChargeHaltings.toString()}>
            {billingSummaryData.amountForChargeHaltings?.toFixed(2)} PLN
          </div>
        ),
        amountForChargeHighways: (
          <div title={billingSummaryData.amountForChargeHighways.toString()}>
            {billingSummaryData.amountForChargeHighways?.toFixed(2)} PLN
          </div>
        ),
      },
    };
  };

  const tableColumns = billingRouteDetailsHelper.getTableColumns();

  const tableRows: BillingRouteDetailsTableRow[] = useMemo(() => {
    if (props.billingSummaryData) {
      const tableRow = createTableRow(props.billingSummaryData);
      return [tableRow];
    }

    return [];
  }, [props.billingSummaryData]);

  const translations = billingsTranslationsHelper.getRouteSummaryTranslations();

  return (
    <CardComponent
      classNames={{ root: "billing_route_details" }}
      header={{ title: translations.summaryLabel }}
    >
      <TableComponent columns={tableColumns} rows={tableRows} />
    </CardComponent>
  );
};

export default BillingRouteDetailsComponent;
