import MapRoute from "../../../../../common/components/map/types/map-route";
import {
  SearchRoadRoutingResponseRouteGeometryCoordinate,
  SearchRoadRoutingResponseRoute,
} from "../../../../../common/utils/search-road-route/search-road-routing.response";
import { BillingGpsDataCoordinates } from "../api/billing-gps-data.response";

const createCompletedMapRouteWaypoints = (
  responseItems: BillingGpsDataCoordinates[]
): MapRoute["waypoints"] => {
  const sortedResponseItems = responseItems.sort(
    (itemA, itemB) =>
      new Date(itemA.record_time).getTime() -
      new Date(itemB.record_time).getTime()
  );

  const waypoints: MapRoute["waypoints"] = sortedResponseItems.map((item) => ({
    latitude: item.lat,
    longitude: item.lon,
  }));

  return waypoints;
};

const createCompletedMapRoute = (
  responseItems: BillingGpsDataCoordinates[]
): MapRoute => {
  const waypoints = createCompletedMapRouteWaypoints(responseItems);

  const mapRoute: MapRoute = {
    waypoints,
    options: {
      color: "#3699ff",
    },
  };

  return mapRoute;
};

const createPlannedMapRouteWaypoints = (
  routesResponseGeometryCoordinates: SearchRoadRoutingResponseRouteGeometryCoordinate[]
): MapRoute["waypoints"] => {
  return routesResponseGeometryCoordinates.map((coordinate) => {
    return {
      latitude: coordinate[1],
      longitude: coordinate[0],
    };
  });
};

const createPlannedMapRoute = (
  routesResponseGeometryCoordinates: SearchRoadRoutingResponseRouteGeometryCoordinate[]
): MapRoute => {
  const waypoints = createPlannedMapRouteWaypoints(
    routesResponseGeometryCoordinates
  );

  return {
    waypoints,
  };
};

const createPlannedMapRoutes = (
  responseRoutes: SearchRoadRoutingResponseRoute[]
): MapRoute[] => {
  return responseRoutes.map((responseRoute) =>
    createPlannedMapRoute(responseRoute.geometry.coordinates)
  );
};

const billingsMapRouteFactory = {
  createCompletedMapRoute,
  createPlannedMapRoutes,
  createPlannedMapRoute,
};

export default billingsMapRouteFactory;
