import userTranslationsHelper from "../../../../../../languages/user-translations.helper";
import DriverAddCarOwnershipType from "../types/driver-add-car-ownership-type";
import DriverAddCarOwnershipTypeSelectOption from "../types/driver-add-car-ownership-type-select-option";

const carOwnershipLabel = (type: DriverAddCarOwnershipType): string => {
  const translations =
    userTranslationsHelper.getDriverAddTranslations().form.vehicleData;
  switch (type) {
    case DriverAddCarOwnershipType.OWNER:
      return translations.typeOwner;
    case DriverAddCarOwnershipType.BUSINESS:
      return translations.typeBusiness;
  }
};

const createSelectOption = (
  type: DriverAddCarOwnershipType
): DriverAddCarOwnershipTypeSelectOption => {
  return {
    label: carOwnershipLabel(type),
    value: type,
    idForTesting: `driver-add-car-ownership-${String(type)}-select-option`,
  };
};

const createSelectOptions = (): DriverAddCarOwnershipTypeSelectOption[] => {
  return Object.keys(DriverAddCarOwnershipType).map((value) =>
    createSelectOption(value as DriverAddCarOwnershipType)
  );
};

const driverAddCarOwnershipTypeFactory = {
  createSelectOptions,
  createSelectOption,
};

export default driverAddCarOwnershipTypeFactory;
