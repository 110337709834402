import MapConfig from "./types/map-config";
import MapMarker from "./types/map-marker";

const getDefaultConfig = (): MapConfig => {
  const config: MapConfig = {
    defaultCenterLocation: {
      lat: 52.06,
      lng: 19.25,
    },
    defaultZoom: 5,
    defaultRouteColor: "#df4f5d",
  };

  return config;
};

const getMarkersWithoutDuplicates = (markers: MapMarker[] = []): MapMarker[] => {
  return markers.reduce((uniqueMarkers: MapMarker[], marker) => {
    const duplicate = uniqueMarkers.find(existingMarker => {
      const sameCoordinates =
        existingMarker.coordinate.latitude === marker.coordinate.latitude &&
        existingMarker.coordinate.longitude === marker.coordinate.longitude;
      if (sameCoordinates && !existingMarker.isDriverMarker && !marker.isDriverMarker) {
        return true;
      }
      return false;
    });
    if (!duplicate) {
      uniqueMarkers.push(marker);
    }
    return uniqueMarkers;
  }, []);
};

const mapHelper = {
  getDefaultConfig,
  getMarkersWithoutDuplicates,
};

export default mapHelper;
