import { FC } from "react";
import TaxiFleetPartnerListingItem from "../common/types/taxi-fleet-partner-listing-item";
import useTaxiFleetPartnerListingUserPermissions from "../common/user-permissions/use-taxi-fleet-partner-listing-user-permissions";
import TaxiFleetPartnerListingTableColumn from "../common/types/taxi-fleet-partner-listing-table-column";
import taxiFleetPartnerListingTableHelper from "./taxi-fleet-partner-listing-table.helper";
import TaxiFleetPartnerListingTableRow from "../common/types/taxi-fleet-partner-listing-table-row";
import taxiTranslationsHelper from "../../../../../languages/taxi-translations.helper";
import TableLinkButtonComponent from "../../../../../common/components/table/button/link/table-link-button.component";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import dateService from "../../../../../common/utils/date/date.service";
import TableComponent from "../../../../../common/components/table/table.component";
import taxiRoutesHelper from "../../../common/routes/taxi-routes.helper";

type TaxiFleetPartnerListingTableProps = {
  isError: boolean;
  isLoading: boolean;
  listingItems: TaxiFleetPartnerListingItem[];
  taxiCorporationUuid: string;
};

const TaxiFleetPartnerListingTableComponent: FC<
  TaxiFleetPartnerListingTableProps
> = (props) => {
  const translations =
    taxiTranslationsHelper.getTaxiFleetPartnerListingTranslations();

  const userPermissions = useTaxiFleetPartnerListingUserPermissions();

  const columns: TaxiFleetPartnerListingTableColumn[] =
    taxiFleetPartnerListingTableHelper.getColumns();

  const createTableRow = (
    listingItem: TaxiFleetPartnerListingItem
  ): TaxiFleetPartnerListingTableRow => {
    const orderCreationDate = dateService.formatDateTime(
      listingItem.creationDate
    );

    const EditFleetPartnerLinkButton = (
      <TableLinkButtonComponent
        to={taxiRoutesHelper.getTaxiFleetPartnerEditRoute({
          taxiCorporationUuid: props.taxiCorporationUuid,
          fleetPartnerUuid: listingItem.uuid,
        })}
        title={translations.table.actions.editFleetPartnerLinkButtonTitle}
        icon={faPenToSquare}
        data-test-id={`taxi-fleet-partner-listing-item-${listingItem.uuid}-edit`}
      />
    );

    return {
      id: listingItem.uuid,
      idForTesting: listingItem.uuid,
      value: {
        name: <div title={listingItem.name}>{listingItem.name}</div>,
        creationDate: <div title={orderCreationDate}>{orderCreationDate}</div>,
        notes: (
          <div title={!!listingItem.notes ? listingItem.notes : "---"}>
            {!!listingItem.notes ? listingItem.notes : "---"}
          </div>
        ),
        actions: (
          <div className="d-flex">
            {userPermissions.hasAccessToEditTaxiFleetPartner &&
              EditFleetPartnerLinkButton}
          </div>
        ),
      },
    };
  };

  const rows: TaxiFleetPartnerListingTableRow[] = props.listingItems.map(
    (listingItem) => createTableRow(listingItem)
  );

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default TaxiFleetPartnerListingTableComponent;
