import RevertOrderCancelRequestRequest from "../../../api/raily/order/revert-cancel-request/revert-cancel-request.request";
import RevertOrderCancelRequestParams from "./revert-order-cancel-request-params";

const create = (
  params: RevertOrderCancelRequestParams
): RevertOrderCancelRequestRequest => {
  return {
    orderId: params.orderUuid,
  };
};

const revertOrderCancelRequestRequestFactory = {
  create,
};

export default revertOrderCancelRequestRequestFactory;
