import { RouteActiveListItem } from "../../../../common/services/route/active-list/route-active-list";
import listingFilterService from "../../../../common/utils/listing/filters/listing-filter.service";
import RouteActiveListingFilter, {
  RouteActiveListingCargoOrderHumanIdFilter,
  RouteActiveListingDriverFilter,
  RouteActiveListingPassengerFilter,
  RouteActiveListingRouteAddressFilter,
  RouteActiveListingHumanIdFilter,
  RouteActiveListingTaxiCorporationFilter,
} from "./types/route-active-listing-filter";
import RouteActiveListingFilterType from "./types/route-active-listing-filter-type";

const filterByCargoOrderHumanId = (
  listingItems: RouteActiveListItem[],
  queries: RouteActiveListingCargoOrderHumanIdFilter["value"][]
): RouteActiveListItem[] => {
  return listingFilterService.filterByArrayOfNumbersProperty(
    listingItems,
    "cargoOrderHumanIds",
    queries
  );
};

const filterByDriver = (
  listingItems: RouteActiveListItem[],
  queries: RouteActiveListingDriverFilter["value"][]
): RouteActiveListItem[] => {
  return listingFilterService.filterByStringProperty(
    listingItems,
    "driverName",
    queries
  );
};

const filterByPassenger = (
  listingItems: RouteActiveListItem[],
  queries: RouteActiveListingPassengerFilter["value"][]
): RouteActiveListItem[] => {
  return listingFilterService.filterByArrayOfStringsProperty(
    listingItems,
    "passengers",
    queries
  );
};

const filterByRouteAddress = (
  listingItems: RouteActiveListItem[],
  queries: RouteActiveListingRouteAddressFilter["value"][]
): RouteActiveListItem[] => {
  let filteredListingItems: RouteActiveListItem[] = [];

  queries.forEach((query) => {
    const newFilteredItems = listingItems.filter((item) =>
      item.routeAddresses
        .flat()
        .some((address) =>
          address.trim().toLowerCase().includes(query.trim().toLowerCase())
        )
    );

    filteredListingItems = [...filteredListingItems, ...newFilteredItems];
  });

  return filteredListingItems;
};

const filterByHumanId = (
  listingItems: RouteActiveListItem[],
  queries: RouteActiveListingHumanIdFilter["value"][]
): RouteActiveListItem[] => {
  return listingFilterService.filterByNumberProperty(
    listingItems,
    "humanId",
    queries
  );
};

const filterByTaxiCorporation = (
  listingItems: RouteActiveListItem[],
  queries: RouteActiveListingTaxiCorporationFilter["value"][]
): RouteActiveListItem[] => {
  let filteredListingItems: RouteActiveListItem[] = [];

  queries.forEach((query) => {
    const newFilteredItems = listingItems.filter((item) =>
      item.taxiCorporationName
        ?.trim()
        .toLowerCase()
        .includes(query.trim().toLowerCase())
    );

    filteredListingItems = [...filteredListingItems, ...newFilteredItems];
  });

  return filteredListingItems;
};

const filterListingItems = (
  listingItems: RouteActiveListItem[],
  filters: RouteActiveListingFilter[]
): RouteActiveListItem[] => {
  if (!filters.length) {
    return [...listingItems];
  }

  let filteredListingItems: RouteActiveListItem[] = [...listingItems];

  const cargoOrderHumanIdFilterValues =
    listingFilterService.getValuesOfGivenFilterType<
      RouteActiveListingFilterType,
      RouteActiveListingCargoOrderHumanIdFilter["value"]
    >(filters, RouteActiveListingFilterType.CARGO_ORDER_HUMAN_ID);

  if (cargoOrderHumanIdFilterValues.length) {
    filteredListingItems = filterByCargoOrderHumanId(
      filteredListingItems,
      cargoOrderHumanIdFilterValues
    );
  }

  const driverFilterValues = listingFilterService.getValuesOfGivenFilterType<
    RouteActiveListingFilterType,
    RouteActiveListingDriverFilter["value"]
  >(filters, RouteActiveListingFilterType.DRIVER);

  if (driverFilterValues.length) {
    filteredListingItems = filterByDriver(
      filteredListingItems,
      driverFilterValues
    );
  }

  const passengersFilterValues =
    listingFilterService.getValuesOfGivenFilterType<
      RouteActiveListingFilterType,
      RouteActiveListingPassengerFilter["value"]
    >(filters, RouteActiveListingFilterType.PASSENGER);

  if (passengersFilterValues.length) {
    filteredListingItems = filterByPassenger(
      filteredListingItems,
      passengersFilterValues
    );
  }

  const routeAddressFilterValues =
    listingFilterService.getValuesOfGivenFilterType<
      RouteActiveListingFilterType,
      RouteActiveListingRouteAddressFilter["value"]
    >(filters, RouteActiveListingFilterType.ROUTE_ADDRESS);

  if (routeAddressFilterValues.length) {
    filteredListingItems = filterByRouteAddress(
      filteredListingItems,
      routeAddressFilterValues
    );
  }

  const routeIdFilterValues = listingFilterService.getValuesOfGivenFilterType<
    RouteActiveListingFilterType,
    RouteActiveListingHumanIdFilter["value"]
  >(filters, RouteActiveListingFilterType.HUMAN_ID);

  if (routeIdFilterValues.length) {
    filteredListingItems = filterByHumanId(
      filteredListingItems,
      routeIdFilterValues
    );
  }

  const taxiCorporationFilterValues =
    listingFilterService.getValuesOfGivenFilterType<
      RouteActiveListingFilterType,
      RouteActiveListingTaxiCorporationFilter["value"]
    >(filters, RouteActiveListingFilterType.TAXI_CORPORATION);

  if (taxiCorporationFilterValues.length) {
    filteredListingItems = filterByTaxiCorporation(
      filteredListingItems,
      taxiCorporationFilterValues
    );
  }

  return filteredListingItems;
};

const routeActiveListingFilteringService = {
  filterListingItems,
};

export default routeActiveListingFilteringService;
