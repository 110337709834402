import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import orderDetailsHelper from "../common/order-details.helper";
import OrderDetailsDriver, {
  OrderDetailsDriverRole,
} from "../common/types/order-details-driver";
import LinkButtonComponent from "../../../../common/components/button/link/link-button.component";
import userRoutesHelper from "../../../user/common/routes/user-routes.helper";
import orderDetailsUserPermissionsHelper from "../common/user-permissions/order-details-user-permission.helper";
import { useAppContext } from "../../../../context/app.context";
import classNames from "classnames";
import useRouteQueryParams from "../../../../common/hooks/use-route-query-params";
import OrderActiveListingRouteQueryParams from "../../common/routes/types/order-active-listing-route-params";

type OrderDetailsDriversProps = {
  drivers: OrderDetailsDriver[];
  orderUuid: string;
  planEntryUuid?: string;
};

const OrderDetailsDriversComponent: FC<OrderDetailsDriversProps> = (props) => {
  const translations = orderTranslationsHelper.getDetailsTranslations().drivers;

  const { user } = useAppContext();
  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<OrderActiveListingRouteQueryParams>();

  const handlePushRouteWithSelectedOrderId = () => {
    const newSearchParams = { ...routeQueryParams };
    newSearchParams.defaultSelectedOrderUuid = props.orderUuid;
    setRouteQueryParams(newSearchParams);
  };

  const userPermissions = orderDetailsUserPermissionsHelper.getPermissions(
    user?.roles!
  );

  const isDriverPlanLinkVisible =
    userPermissions.hasAccessToRedirectToDriverPlan;

  return (
    <div className="order_details_drivers">
      {!!props.drivers.length && (
        <>
          <div>{translations.headingText}</div>
          <ul className="order_details_drivers_list">
            {props.drivers.map((driver, index) => {
              if (isDriverPlanLinkVisible) {
                return (
                  <LinkButtonComponent
                    classNames={{
                      root: "order_details_drivers_list_item_button",
                      content: classNames(
                        "order_details_drivers_list_item_button__content",
                        driver.role === OrderDetailsDriverRole.CANDIDATE &&
                          "candidate"
                      ),
                    }}
                    key={`order-${props.orderUuid}-driver-${driver.uuid}-${index}`}
                    title={orderDetailsHelper.getDriverButtonTitle(driver)}
                    to={
                      !!props.planEntryUuid
                        ? userRoutesHelper.getDriverFinishedPlanRoute({
                            planEntryUuid: props.planEntryUuid,
                          })
                        : userRoutesHelper.getDriverPlanRoute({
                            driverUuid: driver.uuid,
                          })
                    }
                    onClick={handlePushRouteWithSelectedOrderId}
                  >
                    <FontAwesomeIcon
                      icon={faUser}
                      className="order_details_drivers_list_item_button__content__icon"
                    />
                    <div className="order_details_drivers_list_item_button__content__name">
                      {driver.displayName}
                    </div>
                  </LinkButtonComponent>
                );
              }

              return (
                <li
                  className={"order_details_drivers_list_item"}
                  key={`order-${props.orderUuid}-driver-${driver.uuid}`}
                >
                  <div
                    className="order_details_drivers_list_item__content"
                    title={orderDetailsHelper.getDriverTitle(driver)}
                  >
                    <FontAwesomeIcon
                      icon={faUser}
                      className={classNames(
                        "order_details_drivers_list_item__content__icon",
                        driver.role === OrderDetailsDriverRole.CANDIDATE &&
                          "candidate"
                      )}
                    />
                    <div
                      className={classNames(
                        "order_details_drivers_list_item__content__name",
                        driver.role === OrderDetailsDriverRole.CANDIDATE &&
                          "candidate"
                      )}
                    >
                      {driver.displayName}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
};

export default OrderDetailsDriversComponent;
