import L, { DivIcon } from "leaflet";
import { renderToString } from "react-dom/server";
import { ReactComponent as MapMarkerIcon } from "../../../../assets/icons/map-marker-filled.svg";
import MapMarkerIconParams, {
  MapMarkerStandardIconParams,
} from "./map-marker-icon.params";

const createIcon = (params: MapMarkerIconParams): DivIcon => {
  return new DivIcon({
    html: renderToString(
      <>
        <MapMarkerIcon />
        {params.content && (
          <span className="map_marker_icon_content_wrapper">
            {params.content}
          </span>
        )}
      </>
    ),
    iconSize: [25, 41],
    iconAnchor: [12, 33],
    ...params,
  });
};

const createStandardIcon = (params: MapMarkerStandardIconParams): DivIcon => {
  return createIcon({ ...params, className: "map_marker standard" });
};

const createEmptyIcon = () =>
  L.divIcon({
    className: "custom_blue_dot",
    iconSize: [7, 7],
    iconAnchor: [3, 3],
  });

const createCurrentGpsIcon = () =>
  L.divIcon({
    className: "custom_current_gps_dot",
    iconSize: [7, 7],
    iconAnchor: [3, 3],
  });

const mapMarkerIconFactory = {
  createIcon,
  createStandardIcon,
  createEmptyIcon,
  createCurrentGpsIcon,
};

export default mapMarkerIconFactory;
