import { FC, useMemo } from "react";
import TableComponent, {
  TableProps,
} from "../../../../../../../common/components/table/table.component";
import {
  OrderOptimizerDetailsItemDriver,
  OrderOptimizerDetailsItemStepsOrdersPoints,
} from "../../../../common/types/order-optimizer-details-item";
import { useAppContext } from "../../../../../../../context/app.context";
import orderOptimizerDetailsHiddenDetailsCenterTablesOrdersOrderTableHelper from "./order-optimizer-details-hidden-details-center-tables-orders-order-table.helper";
import OrderOptimizerDetailsHiddenDetailsCenterTablesOrdersOrderTableRow from "../../types/order-optimizer-details-hidden-details-center-tables-orders-order-table-row";
import OrderOptimizerDetailsTableDotComponent from "../../../../dot/order-optimizer-details-orders-table-dot.component";
import uuidService from "../../../../../../../common/utils/uuid/uuid.service";
import TableLinkButtonComponent from "../../../../../../../common/components/table/button/link/table-link-button.component";
import orderOptimizerDetailsHelper from "../../../../order-optimizer-details.helper";
import { faMap } from "@fortawesome/free-regular-svg-icons";

type OrderOptimizerDetailsHiddenDetailsCenterTablesOrdersOrdTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  driver: OrderOptimizerDetailsItemDriver | null;
  allStepPoints: OrderOptimizerDetailsItemStepsOrdersPoints[];
  stepPoints: OrderOptimizerDetailsItemStepsOrdersPoints[];
  stepOffset: number;
};

const OrderOptimizerDetailsHiddenDetailsCenterTablesOrdersOrdTableComponent: FC<
  OrderOptimizerDetailsHiddenDetailsCenterTablesOrdersOrdTableProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const columns = useMemo(() => {
    return orderOptimizerDetailsHiddenDetailsCenterTablesOrdersOrderTableHelper.getColumns();
  }, [selectedAppLanguage]);

  const createTableRow = (
    detailsStepsOrdersPointsItem: OrderOptimizerDetailsItemStepsOrdersPoints,
    index: number
  ): OrderOptimizerDetailsHiddenDetailsCenterTablesOrdersOrderTableRow => {
    const timeLabel = orderOptimizerDetailsHelper.getTimeLabel(
      detailsStepsOrdersPointsItem.time
    );
    const timeTitle = orderOptimizerDetailsHelper.getTimeLabel(
      detailsStepsOrdersPointsItem.time
    );

    const distanceLabel = orderOptimizerDetailsHelper.getDistanceLabel(
      detailsStepsOrdersPointsItem.distance
    );
    const distanceTitle = orderOptimizerDetailsHelper.getDistanceLabel(
      detailsStepsOrdersPointsItem.distance
    );

    const haltingTimeLabel = orderOptimizerDetailsHelper.getTimeDeltaLabel(
      detailsStepsOrdersPointsItem.haltingTime
    );
    const haltingTimeTitle = orderOptimizerDetailsHelper.getTimeDeltaLabel(
      detailsStepsOrdersPointsItem.haltingTime
    );

    const dotColor = "#d9d9d9";
    const stepPointIndex = props.stepOffset + index;

    const isFirstPoint = stepPointIndex === 0;
    const isLastStepPoint = index === props.stepPoints.length - 1;

    const googleMapsPoints: OrderOptimizerDetailsItemStepsOrdersPoints[] = [];

    if (!isFirstPoint) {
      googleMapsPoints.push(props.allStepPoints[stepPointIndex - 1]);
    }
    googleMapsPoints.push(props.allStepPoints[stepPointIndex]);

    return {
      id: uuidService.generate(),
      value: {
        humanId: (
          <div className="order_optimizer_details_hidden_details_center_tables_order__humanId">
            <span style={{ width: "8px" }} title={String(stepPointIndex + 1)}>
              {stepPointIndex + 1}
            </span>
            <OrderOptimizerDetailsTableDotComponent color={dotColor} />
          </div>
        ),
        address: (
          <div title={detailsStepsOrdersPointsItem.displayName}>
            {detailsStepsOrdersPointsItem.displayName}
          </div>
        ),
        time: (
          <div
            className={isLastStepPoint ? "last_driver_time" : ""}
            title={timeTitle}
          >
            {timeLabel}
          </div>
        ),
        haltingTime: <div title={haltingTimeTitle!}>{haltingTimeLabel}</div>,
        distance: <div title={distanceTitle}>{distanceLabel}</div>,
        actions: (
          <TableLinkButtonComponent
            icon={faMap}
            openInNewTab
            to={orderOptimizerDetailsHelper.getGoogleMapsUrl(
              googleMapsPoints,
              props.driver?.startLat,
              props.driver?.startLon,
              isFirstPoint,
              false
            )}
            type="primary"
          />
        ),
      },
    };
  };

  const rows: OrderOptimizerDetailsHiddenDetailsCenterTablesOrdersOrderTableRow[] =
    useMemo(() => {
      return props.stepPoints.map(createTableRow);
    }, [props.stepPoints, selectedAppLanguage]);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isLoading={props.isLoading}
      isError={props.isError}
      noHeader
    />
  );
};

export default OrderOptimizerDetailsHiddenDetailsCenterTablesOrdersOrdTableComponent;
