import { FC, RefObject, useCallback, useEffect, useState } from "react";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryTooltip,
} from "victory";
import chartHelper from "./chart.helper";
import useChart from "./use-chart";
import useHorizontalScrollOnWheel from "../../hooks/use-horizontal-scroll-on-wheel";
import ChartTopLabelComponent from "./chart-top-label.component";
import ChartData from "./types/chart-data";

type ChartProps = {
  chartHeight?: number;
  barColor?: string;
  barHeight?: number;
  data: ChartData;
  domainPadding?: number;
  tickLabelsFontSize?: number;
  referenceGroupValue?: number;
  topLabelGroupName?: string;
  topLabelFontSize?: number;
  barLabelsAngle?: number;
  barLabelsTopPadding?: number;
  barLabelsLeftPadding?: number;
  parentRef: RefObject<HTMLDivElement> | undefined;
};

const ChartComponent: FC<ChartProps> = (props) => {
  const [chartWidth, setChartWidth] = useState(0);
  const chartRef = useHorizontalScrollOnWheel();

  const { result, tracks, days, ticks } = useChart({
    data: props.data,
    barLabelsLeftPadding: props.barLabelsLeftPadding,
    barLabelsTopPadding: props.barLabelsTopPadding,
    barLabelsAngle: props.barLabelsAngle,
    topLabelFontSize: props.topLabelFontSize,
    referenceGroupValue: props.referenceGroupValue,
  });

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndScroll);

    return () => {
      window.removeEventListener("resize", updateWidthAndScroll);
    };
  }, []);

  useEffect(() => {
    if (!props.parentRef) return;
    updateWidthAndScroll();
  }, [props.parentRef?.current, chartWidth]);

  const updateWidthAndScroll = useCallback(() => {
    if (!props.parentRef) return;

    const parentWidth = props.parentRef.current?.getBoundingClientRect().width;
    setChartWidth(parentWidth!);

    const ratio = chartHelper.referenceGroupPositionRatio(
      props.referenceGroupValue!,
      ticks,
      result.groupedPoints
    );

    chartRef.current?.scrollBy({
      left: days * parentWidth! * ratio - parentWidth! / 2 + 30,
    });
  }, []);

  if (!chartWidth) return null;

  return (
    <div
      className="chart"
      ref={chartRef}
      style={{
        width: chartWidth,
        overflowX: days > 1 ? "scroll" : "hidden",
      }}
    >
      <VictoryChart
        width={days * chartWidth}
        height={props.chartHeight}
        domainPadding={{ x: props.domainPadding }}
        padding={{
          top: 18,
          bottom: 35,
          left: 16,
          right: 18,
        }}
      >
        <VictoryAxis
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            tickLabels: { fill: "transparent" },
          }}
          tickValues={[10, 20, 30, 35]}
        />
        <VictoryAxis
          dependentAxis
          style={{
            tickLabels: {
              fontSize: props.tickLabelsFontSize,
            },
          }}
          tickValues={ticks}
          tickFormat={(tick) => chartHelper.setHourFormat(tick)}
        />
        <VictoryBar
          containerComponent={<VictoryContainer responsive={true} />}
          barWidth={({ datum }) =>
            props.barHeight! - (datum.strokeWidth ? datum.strokeWidth : 0)
          }
          data={result.data}
          horizontal
          labels={({ datum }) => datum.label}
          maxDomain={{ x: 100 }}
          labelComponent={
            <VictoryTooltip
              constrainToVisibleArea={true}
              centerOffset={{ y: -40 }}
              cornerRadius={12}
              flyoutPadding={{ top: 0, bottom: 0, left: 25, right: 25 }}
              flyoutHeight={40}
              flyoutStyle={{
                fill: "rgb(255,255,255)",
                stroke: "none",
                filter: "drop-shadow(0 1px 4px rgba(0, 0, 0, 0.4))",
                fontSize: "20",
              }}
              style={{ fill: "rgb(51, 51, 51)", fontSize: 16 }}
              text={(item): string =>
                item.datum.tooltipLabel ? item.datum.tooltipLabel : ""
              }
            />
          }
          style={{
            data: {
              fill: ({ datum }) => datum.fill,
              fillOpacity: ({ datum }) => datum.opacity,
              strokeWidth: ({ datum }) =>
                datum.strokeWidth ? datum.strokeWidth : 0,
              stroke: ({ datum }) => (datum.stroke ? datum.stroke : datum.fill),
            },
          }}
        />
        <ChartTopLabelComponent
          tracks={tracks}
          groupedPoints={result.groupedPoints}
          fontSize={props.topLabelFontSize}
          topLabelGroupName={props.topLabelGroupName}
        />
        {result.labels}
      </VictoryChart>
    </div>
  );
};

export default ChartComponent;
