import BillingModel from "../../../../../types/billing-model";
import { RelatedRailyTaxiBillingDataApiResponse } from "../../api/related-raily-taxi-billings.response";
import RelatedRailyTaxiBillingData from "../types/related-raily-taxi-billing data";

const createBillingsData = (data: RelatedRailyTaxiBillingDataApiResponse[]) => {
  const relatedBillings: RelatedRailyTaxiBillingData[] = data.map(
    (billingDataItem) => {
      return {
        contractDetails: {
          companyName: billingDataItem.contract.taxi_corporation.company_name,
          distanceRate: billingDataItem.contract.distance_rate,
          model: billingDataItem.contract.model as BillingModel,
        },
        baseAmount: billingDataItem.base_amount,
        haltingCost: billingDataItem.halting_amount,
        highwayCharge: billingDataItem.highway_charge,
        distance: billingDataItem.distance,
        routeDistanceCost: billingDataItem.distance_amount,
        internalOrderId: billingDataItem.cargo_order.human_id,
        total: billingDataItem.total_amount,
        isDraft: billingDataItem.draft,
        date: billingDataItem.date,
        bonus: billingDataItem.bonus,
        penalty: billingDataItem.penalty,
      };
    }
  );

  return relatedBillings;
};

const relatedRailyTaxiBillingsDataFactory = {
  createBillingsData,
};

export default relatedRailyTaxiBillingsDataFactory;
