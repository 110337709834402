import HttpResponse from "../../../../utils/http/http.response";

export enum RevertOrderCancelRequestNotAcceptableResponseErrorType {
  ORDER_CANCELLED = "ORDER_CANCELLED",
  ORDER_CANCEL_NOT_REQUESTED = "ORDER_CANCEL_NOT_REQUESTED",
  ORDER_FINISHED = "ORDER_FINISHED",
}

type RevertOrderCancelRequestNotAcceptableResponseOrderCancelledError = {
  errorType: RevertOrderCancelRequestNotAcceptableResponseErrorType.ORDER_CANCELLED;
};

type RevertOrderCancelRequestNotAcceptableResponseOrderCancelNotRequestedError =
  {
    errorType: RevertOrderCancelRequestNotAcceptableResponseErrorType.ORDER_CANCEL_NOT_REQUESTED;
  };

type RevertOrderCancelRequestNotAcceptableResponseOrderFinishedError = {
  errorType: RevertOrderCancelRequestNotAcceptableResponseErrorType.ORDER_FINISHED;
};

export type RevertOrderCancelRequestNotAcceptableResponseError =
  | RevertOrderCancelRequestNotAcceptableResponseOrderCancelledError
  | RevertOrderCancelRequestNotAcceptableResponseOrderCancelNotRequestedError
  | RevertOrderCancelRequestNotAcceptableResponseOrderFinishedError;

type RevertOrderCancelRequestSuccessResponse = HttpResponse<null>;

export type RevertOrderCancelRequestNotAcceptableResponse =
  HttpResponse<RevertOrderCancelRequestNotAcceptableResponseError>;

type RevertOrderCancelRequestResponse =
  | RevertOrderCancelRequestSuccessResponse
  | RevertOrderCancelRequestNotAcceptableResponse;

export default RevertOrderCancelRequestResponse;
