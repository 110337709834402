import { FC } from "react";
import FormValidationResult from "../../../../../../../common/utils/validation/types/form-validation-result";
import DriverEditAccountFormData from "../../form/types/driver-edit-account-form-data";
import userTranslationsHelper from "../../../../../../../languages/user-translations.helper";
import CardComponent from "../../../../../../../common/components/card/card.component";
import Row from "../../../../../../../common/components/grid/row";
import Column from "../../../../../../../common/components/grid/column";
import FormFieldComponent from "../../../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../../../common/components/form/input/input.component";

type DriverEditAccountDataProps = {
  validateAccountData: (
    formKey: keyof DriverEditAccountFormData,
    value?: unknown
  ) => Promise<boolean>;
  onAccountDataChange: (
    formKey: keyof DriverEditAccountFormData,
    value: any
  ) => void;
  accountValidationResults: Record<
    keyof DriverEditAccountFormData,
    FormValidationResult
  >;
  accountFormValues: DriverEditAccountFormData;
};

const DriverEditAccountDataComponent: FC<DriverEditAccountDataProps> = (
  props
) => {
  const translations =
    userTranslationsHelper.getDriverEditTranslations().form.accountData;

  return (
    <CardComponent header={{ title: translations.headingText }}>
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.emailLabel}
            isRequired
            errorMessage={props.accountValidationResults.email.errorMessage}
            classNames={{ root: "mt-0" }}
          >
            <InputComponent
              placeholder={translations.emailPlaceholder}
              value={props.accountFormValues.email}
              onChange={(value) => props.onAccountDataChange("email", value)}
              onBlur={() => {
                props.validateAccountData("email");
              }}
              hasError={!!props.accountValidationResults.email.errorMessage}
              idForTesting={`account-data-email`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.usernameLabel}
            isRequired
            classNames={{ root: "mt-0" }}
          >
            <InputComponent
              isDisabled
              placeholder={translations.usernamePlaceholder}
              value={props.accountFormValues.username}
              idForTesting={`account-data-user-name`}
            />
          </FormFieldComponent>
        </Column>
      </Row>
    </CardComponent>
  );
};

export default DriverEditAccountDataComponent;
