import { FC, useState } from "react";
import DriverEditAddress from "../../../types/driver-edit-address";
import userTranslationsHelper from "../../../../../../../../languages/user-translations.helper";
import MapMarker from "../../../../../../../../common/components/map/types/map-marker";
import driverEditUserDataAddressFactory from "../../../factory/driver-edit-user-data-address.factory";
import DriverAddressFormAddress from "../../../../../common/address-form/types/driver-address-form-address";
import DriverEditAddressType from "../../../types/driver-edit-address-type";
import Row from "../../../../../../../../common/components/grid/row";
import Column from "../../../../../../../../common/components/grid/column";
import DriverEditUserDataAddressTableComponent from "./table/driver-edit-user-data-address-table.component";
import ButtonComponent from "../../../../../../../../common/components/button/button.component";
import MapComponent from "../../../../../../../../common/components/map/map.component";
import DriverAddressFormComponent from "../../../../../common/address-form/driver-address-form.component";

type DriverEditUserDataAddressProps = {
  addresses: DriverEditAddress[];
  onRemoveAddressButtonClick: (index: number) => void;
  onAddNewAddress: (address: DriverEditAddress) => void;
};

const DriverEditUserDataAddressComponent: FC<DriverEditUserDataAddressProps> = (
  props
) => {
  const userDataFormTranslations =
    userTranslationsHelper.getDriverEditTranslations().form.userData;

  const [isAddNewAddressModalOpen, setIsAddNewAddressModalOpen] =
    useState(false);

  const mapMarkers: MapMarker[] =
    driverEditUserDataAddressFactory.createMapMarkers(props.addresses);

  const openAddNewAddressModal = () => {
    setIsAddNewAddressModalOpen(true);
  };

  const closeAddNewAddressModal = () => {
    setIsAddNewAddressModalOpen(false);
  };

  const handleAddNewAddress = (address: DriverAddressFormAddress) => {
    const convertedAddress: DriverEditAddress = {
      ...address,
      type: address.type as unknown as DriverEditAddressType,
    };

    props.onAddNewAddress(convertedAddress);
  };

  return (
    <div className="driver_edit_user_add_address">
      <Row>
        <Column xl={4}>
          <DriverEditUserDataAddressTableComponent
            addresses={props.addresses}
            onRemoveAddressButtonClick={props.onRemoveAddressButtonClick}
          />
          <div className="driver_edit_user_add_address__add_button_wrapper">
            <ButtonComponent
              onClick={openAddNewAddressModal}
              title={userDataFormTranslations.addNewAddressButtonTitle}
              type="primary"
              idForTesting="user-data-open-add-new-address-modal-button"
            >
              {userDataFormTranslations.addNewAddressButtonText}
            </ButtonComponent>
          </div>
        </Column>
        <Column xl={8}>
          <div className="driver_edit_user_add_address__map_wrapper">
            <MapComponent markers={mapMarkers} autoFit autoFitOnUpdate />
          </div>
        </Column>
      </Row>
      <DriverAddressFormComponent
        isOpen={isAddNewAddressModalOpen}
        onClose={closeAddNewAddressModal}
        onAddNewAddress={handleAddNewAddress}
      />
    </div>
  );
};

export default DriverEditUserDataAddressComponent;
