import DriverAddRequest, { DriverAddRequestBody } from "./driver-add.request";

const createBody = (request: DriverAddRequest): DriverAddRequestBody => {
  return {
    driver: request.driver,
    email: request.email,
    firstName: request.firstName,
    lastName: request.lastName,
    mobilePrimary: request.mobilePrimary,
    mobilePrimaryPrefix: request.mobilePrimaryPrefix,
    mobileSecondary: request.mobileSecondary,
    mobileSecondaryPrefix: request.mobileSecondaryPrefix,
    password: request.password,
    username: request.username,
  };
};

const driverAddRequestFactory = {
  createBody,
};

export default driverAddRequestFactory;
