import { FC } from "react";
import orderOptimizerTranslationsHelper from "../../../../languages/order-optimizer-translations.helper";
import OrderOptimizerDetailsItem from "../common/types/order-optimizer-details-item";
import OrderOptimizerDetailsHiddenDetailsDriverTableComponent from "./heading-tables/driver/order-optimizer-details-hidden-details-heading-tables-driver-table.component";
import OrderOptimizerDetailsHiddenDetailsOrderTableComponent from "./heading-tables/order/order-optimizer-details-hidden-details-heading-tables-order-table.component";
import OrderOptimizerDetailsHiddenDetailsSummaryFooterComponent from "./summary-footer/order-optimizer-details-hidden-details-summary-footer.component";
import OrderOptimizerDetailsHiddenDetailsCenterTablesTableComponent from "./center-tables/order-optimizer-details-hidden-details-center-tables.component";
import OrderOptimizerDetailsHiddenDetailsDriverSelectComponent from "./driver/order-optimizer-details-hidden-details-driver-select.component";
import OrderOptimizerDetailsHiddenDetailsDriver from "./driver/types/order-optimizer-details-hidden-details-driver";

type OrderOptimizerDetailsHiddenDetailsProps = {
  optimizationId: string | undefined;
  detailsItem: OrderOptimizerDetailsItem;
  onPotentialDriverChange: (
    driver: OrderOptimizerDetailsHiddenDetailsDriver | null
  ) => void;
};

const OrderOptimizerDetailsHiddenDetailsComponent: FC<
  OrderOptimizerDetailsHiddenDetailsProps
> = (props) => {
  const translations =
    orderOptimizerTranslationsHelper.getDetailsTranslations();

  return (
    <>
      <div className="order_optimizer_details_hidden_details">
        <div className="order_optimizer_details_hidden_details__heading_section">
          <div className="order_optimizer_details_hidden_details__heading_section__left">
            <div>
              <OrderOptimizerDetailsHiddenDetailsDriverSelectComponent
                optimizationId={props.optimizationId}
                onPotentialDriverChange={props.onPotentialDriverChange}
              />
            </div>
          </div>
          <div className="order_optimizer_details_hidden_details__heading_section__center">
            <h4 className="order_optimizer_details_hidden_details_order__text">
              {translations.table.headingTables.headingOrdersLabel}
            </h4>
            <div className="order_optimizer_details_hidden_details_order">
              <OrderOptimizerDetailsHiddenDetailsOrderTableComponent
                detailsStepsHomeToStartItems={
                  props.detailsItem.steps.homeToStart
                }
              />
            </div>
          </div>
          <div className="order_optimizer_details_hidden_details__heading_section__right">
            <h4 className="order_optimizer_details_hidden_details_order__text">
              {translations.table.headingTables.headingDrierLabel}
            </h4>
            <div className="order_optimizer_details_hidden_details_driver">
              <OrderOptimizerDetailsHiddenDetailsDriverTableComponent
                detailsStepsHomeToStartItem={
                  props.detailsItem.steps.homeToStart
                }
              />
            </div>
          </div>
        </div>
        <div className="order_optimizer_details_hidden_details__center_section">
          <OrderOptimizerDetailsHiddenDetailsCenterTablesTableComponent
            orders={props.detailsItem.orders}
            ordersSteps={props.detailsItem.steps.orders}
            driver={props.detailsItem.driver}
          />
        </div>
        <div className="order_optimizer_details_hidden_details__lower_section">
          <OrderOptimizerDetailsHiddenDetailsSummaryFooterComponent
            detailsItem={props.detailsItem}
          />
        </div>
      </div>
    </>
  );
};

export default OrderOptimizerDetailsHiddenDetailsComponent;
