import { FC, useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../../../../context/app.context";
import { BillingsOrderForwardCargoTaxiItem } from "../../../../../../common/services/billings/order-forward/cargo-taxi/billings-order-forward-cargo-taxi";
import useBillingsOrderForwardCargoTaxi from "../../../../../../common/services/billings/order-forward/cargo-taxi/use-billings-order-forward-cargo-taxi";
import useAbort from "../../../../../../common/hooks/use-abort";
import CardComponent from "../../../../../../common/components/card/card.component";
import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";
import RelatedBillingsCargoTaxiTableRow from "../../../../common/related-billings/cargo-taxi/types/related-billings-cargo-taxi-table-row";
import RelatedBillingsCargoTaxiTableComponent from "../../../../common/related-billings/cargo-taxi/related-billings-cargo-taxi-table.component";
import relatedBillingsCargoTaxiFactory from "./related-billings-cargo-taxi.factory";

type RelatedBillingsCargoTaxiProps = {
  forwardingId: string;
};

const RelatedBillingsCargoTaxiComponent: FC<RelatedBillingsCargoTaxiProps> = (
  props
) => {
  const translations =
    billingsTranslationsHelper.getBillingsTranslations().relatedBillings
      .cargo_taxi;

  const [relatedBillingsCargoTaxiData, setRelatedBillingsCargoTaxiData] =
    useState<BillingsOrderForwardCargoTaxiItem[]>([]);

  const { selectedAppLanguage } = useAppContext();
  const cargoTaxi = useBillingsOrderForwardCargoTaxi();
  const cargoTaxiAbort = useAbort();

  const cargoTaxiData = async () => {
    cargoTaxi.load(
      { forwardingUuid: props.forwardingId },
      cargoTaxiAbort.signal
    );
  };

  useEffect(() => {
    if (!props.forwardingId) return;

    cargoTaxiData();
  }, [props.forwardingId]);

  useEffect(() => {
    if (!cargoTaxi.data.data) return;

    setRelatedBillingsCargoTaxiData(cargoTaxi.data.data);
  }, [cargoTaxi.data.data]);

  const tableRows: RelatedBillingsCargoTaxiTableRow[] = useMemo(() => {
    if (!relatedBillingsCargoTaxiData) return [];

    return relatedBillingsCargoTaxiFactory.createTableRows(
      relatedBillingsCargoTaxiData
    );
  }, [relatedBillingsCargoTaxiData, selectedAppLanguage]);

  return (
    <CardComponent
      classNames={{ root: "related_billings" }}
      header={{ title: translations.summaryLabel }}
    >
      <RelatedBillingsCargoTaxiTableComponent
        tableRows={tableRows}
        isLoading={cargoTaxi.isLoading && !!props.forwardingId}
      />
    </CardComponent>
  );
};

export default RelatedBillingsCargoTaxiComponent;
