enum OrderDetailsHistoryEventType {
  BIND_PASSENGER = "BIND_PASSENGER",
  CANCEL_ORDER = "CANCEL_ORDER",
  CHANGE_RIDE = "CHANGE_RIDE",
  CHANGE_TARGET_TAXI = "CHANGE_TARGET_TAXI",
  DEALER_APPROVAL = "DEALER_APPROVAL",
  DISPATCH_APPROVAL = "DISPATCH_APPROVAL",
  EXTERNAL_ID_UPDATE = "EXTERNAL_ID_UPDATE",
  INITIALIZE = "INITIALIZE",
  MERGE_ORDERS = "MERGE_ORDERS",
  OPERATIONAL_APPROVAL = "OPERATIONAL_APPROVAL",
  REQUEST_CANCEL_ORDER = "REQUEST_CANCEL_ORDER",
  REVERT_CANCEL_ORDER_REQUEST = "REVERT_CANCEL_ORDER_REQUEST",
  SHUFFLE_MERGE = "SHUFFLE_MERGE_COMMAND",
  FORWARD_ADD = "FORWARD",
  FORWARD_CANCEL = "FORWARD_CANCEL",
  ACCEPTED_CPE_CANDIDATURE = "ACCEPTED_CPE_CANDIDATURE",
  ACCEPTED_CSO_CANDIDATURE = "ACCEPTED_CSO_CANDIDATURE",
  ACCEPTED_DSO_CANDIDATURE = "ACCEPTED_DSO_CANDIDATURE",
}

export default OrderDetailsHistoryEventType;
