import { FC, useEffect, useState } from "react";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import DriverEditViewBasicProps from "../common/types/driver-edit-view-basic-props";
import { useAppContext } from "../../../../../context/app.context";
import { useNavigate, useParams } from "react-router-dom";
import UserDriverEditRouteParams from "../../../common/routes/types/user-driver-edit-route-params";
import userBreadcrumbsHelper from "../../../common/breadcrumbs/user-breadcrumbs.helper";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../../common/hooks/use-document-title";
import useAbort from "../../../../../common/hooks/use-abort";
import DriverEditFormOfEmploymentType from "../common/types/driver-edit-form-of-employment-type";
import userRoutesHelper from "../../../common/routes/user-routes.helper";
import notificationService from "../../../../../common/utils/notification/notification.service";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import Row from "../../../../../common/components/grid/row";
import Column from "../../../../../common/components/grid/column";
import DriverEditAccountDataComponent from "../common/components/account/driver-edit-account-data.component";
import DriverEditVehicleDataComponent from "../common/components/vehicle/driver-edit-vehicle.component";
import ButtonComponent from "../../../../../common/components/button/button.component";
import RailyDriverEditUserDataComponent from "../common/components/user-data/raily-driver-edit-user-data.component";
import useDriverEditForm from "../../../../../common/services/driver/edit-form/use-driver-edit-form";
import useForm from "../../../../../common/components/form/use-form";
import driverEditAccountFormHelper from "../common/form/driver-edit-account-data-form.helper";
import DriverEditFormLoadParams from "../../../../../common/services/driver/edit-form/driver-edit-form-load-params";
import driverEditVehicleFormHelper from "../common/form/driver-edit-vehicle-form.helper";
import railyDriverEditParamsFactory from "../common/factory/raily-driver-edit-params.factory";
import driverService from "../../../../../common/services/driver/driver.service";
import DriverEditTaxiCorporationFormData from "../common/factory/driver-edit-taxi-corporation-form-data";
import FormValidationResult from "../../../../../common/utils/validation/types/form-validation-result";
import driverEditTaxiCorporationHelper from "../common/form/driver-edit-taxi-corporation-form.helper";
import railyDriverEditUserFormHelper from "../common/form/raily-driver-add-user-data-form.helper";
import railyDriverEditFactory from "../common/factory/raily-driver-edit.factory";
import DriverEditTaxiCorporationComponent from "../common/components/taxi-corporation/driver-edit-taxi-corporation.component";

type RailyDriverEditProps = DriverEditViewBasicProps;

const RailyDriverEditComponent: FC<RailyDriverEditProps> = (props) => {
  const translations = userTranslationsHelper.getDriverEditTranslations();

  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  const { driverUuid } = useParams<UserDriverEditRouteParams>();

  const navigate = useNavigate();

  const [driverName, setDriverName] = useState("");

  useEffect(() => {
    const breadcrumbs = userBreadcrumbsHelper.getDriverEditBreadcrumbs({
      driverName: driverName,
      driverUuid: driverUuid ?? "",
    });
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, driverName]);

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(
    documentTitleTranslations.userDriverEdit.replace(
      "#{driverName}",
      driverName
    )
  );

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const driverEditForm = useDriverEditForm();
  const driverEditFormAbort = useAbort();

  const formAccountFormData = useForm({
    emptyValues: driverEditAccountFormHelper.getDefaultAccountFormData(),
    validationDefinition:
      driverEditAccountFormHelper.getAccountValidationDefinition(),
  });

  const formUserFormData = useForm({
    emptyValues: railyDriverEditUserFormHelper.getDefaultUserFormData(),
    validationDefinition:
      railyDriverEditUserFormHelper.getUserValidationDefinition(),
  });

  const formVehicleFormData = useForm({
    emptyValues: driverEditVehicleFormHelper.getDefaultVehicleFormData(),
    validationDefinition:
      driverEditVehicleFormHelper.getVehicleValidationDefinition(),
  });

  const [formTaxiCorporationsData, setFormTaxiCorporationsData] = useState<
    DriverEditTaxiCorporationFormData[]
  >([driverEditTaxiCorporationHelper.getDefaultTaxiCorporationData()]);

  const [
    formTaxiCorporationValidationResults,
    setFormTaxiCorporationValidationResults,
  ] = useState<
    Record<keyof DriverEditTaxiCorporationFormData, FormValidationResult>[]
  >([]);

  const setValue = (
    formKey: keyof DriverEditTaxiCorporationFormData,
    value: DriverEditTaxiCorporationFormData[keyof DriverEditTaxiCorporationFormData],
    index: number
  ) => {
    const newData = { ...formTaxiCorporationsData[index], [formKey]: value };
    const newTaxiCorporationsData = formTaxiCorporationsData.map((item, i) =>
      i === index ? newData : item
    );

    setFormTaxiCorporationsData(newTaxiCorporationsData);
  };

  const validateField = async (
    formKey: keyof DriverEditTaxiCorporationFormData,
    index: number,
    overrideValue?: any
  ): Promise<boolean> => {
    const currentData = formTaxiCorporationsData[index];

    const updatedData =
      overrideValue !== undefined
        ? { ...currentData, [formKey]: overrideValue }
        : currentData;

    const validationResult = await driverEditTaxiCorporationHelper
      .getTaxiCorporationValidationDefinition()
      [formKey](updatedData);

    const results = {
      ...formTaxiCorporationValidationResults[index],
      [formKey]: validationResult,
    };

    const newValidationResults = formTaxiCorporationValidationResults.map(
      (item, i) => (i === index ? results : item)
    );

    setFormTaxiCorporationValidationResults(newValidationResults);

    return validationResult.isValid;
  };

  const validateTaxiCorporationData = async (
    data: DriverEditTaxiCorporationFormData,
    index: number
  ): Promise<boolean> => {
    const validationDefinition =
      driverEditTaxiCorporationHelper.getTaxiCorporationValidationDefinition();
    let isValid = true;

    const updatedResults = { ...formTaxiCorporationValidationResults[index] };

    for (const key in validationDefinition) {
      const fieldKey = key as keyof DriverEditTaxiCorporationFormData;
      const result = await validationDefinition[fieldKey](data);
      updatedResults[fieldKey] = result;
      if (!result.isValid) {
        isValid = false;
      }
    }

    formTaxiCorporationValidationResults[index] = updatedResults;
    setFormTaxiCorporationValidationResults(
      formTaxiCorporationValidationResults
    );

    return isValid;
  };

  useEffect(() => {
    if (!driverEditForm.data) {
      return;
    }

    const userFormData = railyDriverEditFactory.createUserFormData(
      driverEditForm.data
    );
    const accountFormData = railyDriverEditFactory.createAccountFormData(
      driverEditForm.data
    );
    const vehicleFormData = railyDriverEditFactory.createVehicleFormData(
      driverEditForm.data.driver
    );
    const taxiCorporationFormData =
      railyDriverEditFactory.createTaxiCorporationFormData(driverEditForm.data);

    const driverName = `${driverEditForm.data.user.firstName} ${driverEditForm.data.user.lastName}`;

    formUserFormData.setValues(userFormData);
    formAccountFormData.setValues(accountFormData);
    formVehicleFormData.setValues(vehicleFormData);
    setFormTaxiCorporationsData(taxiCorporationFormData);

    const taxiCorporationFormValidationResults =
      driverEditTaxiCorporationHelper.getTaxiCorporationValidationResults(
        taxiCorporationFormData
      );

    setFormTaxiCorporationValidationResults(
      taxiCorporationFormValidationResults
    );

    setDriverName(driverName);
  }, [driverEditForm.data]);

  const fetchFormInitData = () => {
    if (!driverUuid) {
      return;
    }

    const params: DriverEditFormLoadParams = { id: driverUuid };

    driverEditForm.load(params, driverEditFormAbort.signal);
  };

  useEffect(() => {
    if (!driverEditForm.data) return;

    const taxiCorporationFormData =
      railyDriverEditFactory.createTaxiCorporationFormData(driverEditForm.data);

    setFormTaxiCorporationsData(taxiCorporationFormData);
  }, [driverEditForm.data]);

  useEffect(() => {
    fetchFormInitData();
  }, [driverUuid]);

  const navigateToListing = () => {
    const dealerListingRoute = userRoutesHelper.getDriverListingRoute();
    navigate(dealerListingRoute);
  };

  const onDriverEditSuccess = () => {
    notificationService.success(translations.successEditNotificationText);
    navigateToListing();
  };

  const onDriverEditFailure = () => {
    notificationService.error(translations.failureEditNotificationText);
  };

  const submitForm = async () => {
    const isFormAccountFormDataValid = await formAccountFormData.validateAll();
    const isFormUserFormDataValid = await formUserFormData.validateAll();
    const isFormVehicleFormDataValid = await formVehicleFormData.validateAll();

    const taxiValidationResults = await Promise.all(
      formTaxiCorporationsData.map((data, index) =>
        validateTaxiCorporationData(data, index)
      )
    );

    const isFormTaxiCorporationDataValid = taxiValidationResults.every(
      (value) => value
    );

    if (
      !isFormAccountFormDataValid ||
      !isFormTaxiCorporationDataValid ||
      !isFormUserFormDataValid ||
      !isFormVehicleFormDataValid ||
      !driverUuid
    )
      return;

    const params = railyDriverEditParamsFactory.createSubmitFormParams(
      formAccountFormData.values,
      formUserFormData.values,
      formVehicleFormData.values,
      formTaxiCorporationsData
    );

    try {
      setIsFormSubmitting(true);
      await driverService.editDriver(
        params,
        driverEditFormAbort.signal,
        driverUuid
      );

      onDriverEditSuccess();
    } catch {
      onDriverEditFailure();
    } finally {
      setIsFormSubmitting(false);
    }
  };

  if (!driverName) {
    return null;
  }

  return (
    <div className="user_driver_edit">
      <HeadingComponent
        title={translations.header.headingText.replace(
          "#{driverName}",
          driverName
        )}
        actions={props.changeViewButtons}
      />
      <Row>
        <Column withPaddings>
          <DriverEditAccountDataComponent
            accountFormValues={formAccountFormData.values}
            onAccountDataChange={formAccountFormData.setValue}
            validateAccountData={formAccountFormData.validate}
            accountValidationResults={formAccountFormData.validationResults}
          />
        </Column>
      </Row>
      <Row>
        <Column withPaddings>
          <RailyDriverEditUserDataComponent
            onUserDataChange={formUserFormData.setValue}
            validateUserData={formUserFormData.validate}
            citizenship={formUserFormData.values.citizenship!}
            userFormValues={formUserFormData.values}
            userValidationResults={formUserFormData.validationResults}
          />
        </Column>
      </Row>
      {formTaxiCorporationsData.map((form, index) => (
        <Row key={index}>
          <Column withPaddings>
            <DriverEditTaxiCorporationComponent
              onTaxiCorporationChange={(formKey, value) =>
                setValue(formKey, value, index)
              }
              validateTaxiCorporation={(formKey, value) =>
                validateField(formKey, index, value)
              }
              taxiCorporationFormValues={form}
              taxiCorporationValidationResults={
                formTaxiCorporationValidationResults[index]
              }
              isCargoDataSectionVisible={
                form.formOfEmployment === DriverEditFormOfEmploymentType.B2B
              }
              formTaxiCorporationsData={formTaxiCorporationsData}
            />
          </Column>
        </Row>
      ))}
      <Row>
        <Column withPaddings>
          <DriverEditVehicleDataComponent
            onVehicleDataChange={formVehicleFormData.setValue}
            validateVehicleData={formVehicleFormData.validate}
            carOwnershipType={formVehicleFormData.values.ownership!}
            vehicleFormValues={formVehicleFormData.values}
            vehicleValidationResults={formVehicleFormData.validationResults}
          />
        </Column>
      </Row>
      <ButtonComponent
        onClick={submitForm}
        type="primary"
        isLoading={isFormSubmitting}
        classNames={{ root: "mt-2" }}
        idForTesting={`submit-button`}
        title={translations.form.submitButtonTitle}
      >
        {translations.form.submitButtonText}
      </ButtonComponent>
    </div>
  );
};

export default RailyDriverEditComponent;
