import { FC } from "react";
import DriverAddCompanyFormData from "../../form/types/driver-add-company-form-data";
import userTranslationsHelper from "../../../../../../../languages/user-translations.helper";
import Row from "../../../../../../../common/components/grid/row";
import Column from "../../../../../../../common/components/grid/column";
import FormValidationResult from "../../../../../../../common/utils/validation/types/form-validation-result";
import DriverAddCompanyDataComponent from "./data/driver-add-company-data";
import DriverAddCompanyAddressComponent from "./address/driver-add-company-address";

type DriverAddCompanyDataProps = {
  validateCompanyData: (
    formKey: keyof DriverAddCompanyFormData,
    value?: unknown
  ) => Promise<boolean>;
  onCompanyDataChange: (
    formKey: keyof DriverAddCompanyFormData,
    value: any
  ) => void;
  companyValidationResults: Record<
    keyof DriverAddCompanyFormData,
    FormValidationResult
  >;
  companyFormValues: DriverAddCompanyFormData;
};

const RailyDriverAddCompanyDataComponent: FC<DriverAddCompanyDataProps> = (
  props
) => {
  const translations =
    userTranslationsHelper.getDriverAddTranslations().form.companyData;

  return (
    <Row>
      <Column lg={6}>
        <>
          <div className="driver_add_company_heading_text">
            {translations.headingText}
          </div>
          <DriverAddCompanyDataComponent
            companyFormValues={props.companyFormValues}
            companyValidationResults={props.companyValidationResults}
            onCompanyDataChange={props.onCompanyDataChange}
            validateCompanyData={props.validateCompanyData}
          />
        </>
      </Column>
      <Column lg={6}>
        <>
          <DriverAddCompanyAddressComponent
            companyFormValues={props.companyFormValues}
            companyValidationResults={props.companyValidationResults}
            onCompanyDataChange={props.onCompanyDataChange}
            validateCompanyData={props.validateCompanyData}
          />
        </>
      </Column>
    </Row>
  );
};

export default RailyDriverAddCompanyDataComponent;
