import { FC, useEffect, useState } from "react";
import DelegationAddRouteItem from "../../types/delegation-add-route-item";
import DelegationAddAddress from "../../types/delegation-add-address";
import delegationTranslationsHelper from "../../../../../../languages/delegation-translations.helper";
import delegationAddRoutesHelper from "./delegation-add-routes.helper";
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  DropResult,
} from "react-beautiful-dnd";
import Droppable from "../../../../../../common/components/drag-and-drop/droppable.component";
import DelegationAddRouteItemComponent from "./delegation-add-route-item.component";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import DelegationAddRouteGroup from "../../types/delegation-add-route-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faTrash } from "@fortawesome/free-solid-svg-icons";
import Column from "../../../../../../common/components/grid/column";
import FormErrorComponent from "../../../../../../common/components/form/error/form-error.component";
import FormValidationResult from "../../../../../../common/utils/validation/types/form-validation-result";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import delegationAddFormValidationService from "../../form/delegation-add-form-validation.service";
import DateTimeInputComponent from "../../../../../../common/components/form/input/date-time/date-time-input.component";
import useDelegationRoadRoute from "../../../../../../common/services/delegation/road-route/use-delegation-road-route";
import DelegationRoadRouteLoadParams from "../../../../../../common/services/delegation/road-route/delegation-road-route-load-params";
import DelegationAddRouteWaypoint from "../../types/delegation-add-route-waypoint";
import delegationAddRouteFactory from "../../factory/delegation-add-route.factory";
import MapRoute from "../../../../../../common/components/map/types/map-route";
import delegationAddHelper from "../../factory/delegation-add-helper";
import { round } from "lodash";
import FormFieldComponent from "../../../../../../common/components/form/field/form-field.component";
import DelegationAddVehicleTypeSelectComponent from "../vehicle-type-select/delegation-add-vehicle-type-select.component";
import useForm from "../../../../../../common/components/form/use-form";
import delegationAddFormHelper from "../../form/delegation-add-form.helper";
import CardComponent from "../../../../../../common/components/card/card.component";
import NumericInputComponent from "../../../../../../common/components/form/input/numeric-input/numeric-input.component";
import DelegationAddVehicleType from "../../types/delegation-add-vehicle-type";
import CargoCompanyMileageContractDetails from "../../../../../../common/services/cargo-company/mileage-contract/details/cargo-company-mileage-contract-details";

type DelegationAddRoutesGroupProps = {
  group: DelegationAddRouteGroup;
  groupIndex: number;
  onGroupChange: (group: DelegationAddRouteGroup) => void;
  deleteGroup: () => void;
  additionalAddresses: DelegationAddAddress[];
  cargoCompanyAddresses: DelegationAddAddress[];
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  mileageContract: CargoCompanyMileageContractDetails | null;
  isAddButtonDisabled?: boolean;
  onBlur?: () => void;
  idForTesting?: string;
  dateMax?: Date | null;
  dateMin?: Date | null;
};

const DelegationAddRoutesGroupComponent: FC<DelegationAddRoutesGroupProps> = (
  props
) => {
  const translations =
    delegationTranslationsHelper.getDelegationAddTranslations();

  const [routesValidationResult, setRoutesValidationResult] =
    useState<FormValidationResult>(formValidationService.ok());

  const [startDateValidationResult, setStartDateValidationResult] =
    useState<FormValidationResult>(formValidationService.ok());

  const [endDateValidationResult, setEndDateValidationResult] =
    useState<FormValidationResult>(formValidationService.ok());

  const roadRoute = useDelegationRoadRoute();

  const form = useForm({
    emptyValues: delegationAddFormHelper.getDefaultFormData(),
    validationDefinition: delegationAddFormHelper.getValidationDefinition(),
  });

  const loadRoadRoute = (waypoints: DelegationAddRouteWaypoint[]) => {
    const params: DelegationRoadRouteLoadParams | null =
      delegationAddRouteFactory.createSearchRoutingRequest(waypoints);

    if (!params) return;

    roadRoute.load(params);
  };

  useEffect(() => {
    const distance = props.group.distance ?? 0;

    const groupCost =
      distance *
      delegationAddRoutesHelper.getDistanceRateForVehicleType(
        props.group.vehicleType,
        props.mileageContract
      );

    const newGroup: DelegationAddRouteGroup = {
      ...props.group,
      groupCost: groupCost,
    };

    props.onGroupChange(newGroup);
  }, [props.mileageContract]);

  useEffect(() => {
    loadRoadRoute(props.group.mapWaypoints);
  }, [props.group.mapWaypoints]);

  useEffect(() => {
    if (!roadRoute.data) return;

    const mapRoute: MapRoute = delegationAddRouteFactory.createMapRoute(
      roadRoute.data
    );

    let distance = 0;
    let duration = 0;

    roadRoute.data.legs.forEach((leg) => {
      distance += leg.distance;
      duration += leg.duration;
    });

    distance = round(distance / 1000);

    const newRoutes = delegationAddRouteFactory.createNewRoutes(
      props.group.routes,
      props.group.startDate,
      roadRoute.data
    );

    let endDate = props.group.startDate;

    if (endDate) {
      endDate = delegationAddHelper.calculateDate(
        props.group.startDate!,
        duration
      );
    }

    const groupCost =
      distance *
      delegationAddRoutesHelper.getDistanceRateForVehicleType(
        props.group.vehicleType,
        props.mileageContract
      );

    const newGroup: DelegationAddRouteGroup = {
      ...props.group,
      routes: newRoutes,
      mapRoute: mapRoute,
      distance: distance,
      duration: duration,
      endDate: endDate,
      groupCost: groupCost,
    };

    props.onGroupChange(newGroup);
  }, [roadRoute.data]);

  const updateRouteItem = (updatedRouteItem: DelegationAddRouteItem) => {
    const routeToUpdate = props.group.routes.find(
      (route) => route.uuid === updatedRouteItem.uuid
    );

    if (!routeToUpdate) {
      return;
    }

    const newRoutes: DelegationAddRouteItem[] = [...props.group.routes];

    const indexOfRouteToUpdate = newRoutes.indexOf(routeToUpdate);
    newRoutes[indexOfRouteToUpdate] = updatedRouteItem;

    const newWaypoints: DelegationAddRouteWaypoint[] =
      delegationAddRouteFactory.createNewWaypoint(newRoutes, props.groupIndex);

    const newGroup: DelegationAddRouteGroup = {
      ...props.group,
      routes: newRoutes,
      mapWaypoints: newWaypoints,
    };

    props.onGroupChange(newGroup);
  };

  const deleteRouteItem = (routeItemUuid: DelegationAddRouteItem["uuid"]) => {
    const newRoutes: DelegationAddRouteItem[] = props.group.routes.filter(
      (route) => route.uuid !== routeItemUuid
    );

    while (newRoutes.length < 2) {
      newRoutes.push(delegationAddRoutesHelper.getNewEmptyRoute());
    }

    const newWaypoints: DelegationAddRouteWaypoint[] =
      delegationAddRouteFactory.createNewWaypoint(newRoutes, props.groupIndex);

    const newGroup: DelegationAddRouteGroup = {
      ...props.group,
      routes: newRoutes,
      mapWaypoints: newWaypoints,
    };

    props.onGroupChange(newGroup);
  };

  const onStartDateChange = (newDate: Date | null) => {
    let newEndDate = newDate;

    if (newDate && props.group.duration) {
      newEndDate = delegationAddHelper.calculateDate(
        newDate,
        props.group.duration
      );
    }

    const newRoutes = delegationAddRouteFactory.createNewRoutes(
      props.group.routes,
      newDate,
      roadRoute.data
    );

    const newGroup: DelegationAddRouteGroup = {
      ...props.group,
      routes: newRoutes,
      startDate: newDate,
      endDate: newEndDate,
    };

    props.onGroupChange(newGroup);
  };

  const onDragRouteListItemEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedRoutes = delegationAddRoutesHelper.getReorderedRouteItems(
      result.source.index,
      result.destination.index,
      props.group
    );

    const newWaypoints: DelegationAddRouteWaypoint[] =
      delegationAddRouteFactory.createNewWaypoint(
        reorderedRoutes,
        props.groupIndex
      );

    const newGroup: DelegationAddRouteGroup = {
      ...props.group,
      routes: reorderedRoutes,
      mapWaypoints: newWaypoints,
    };
    props.onGroupChange(newGroup);
  };

  const onDistanceChange = (distance: number | null) => {
    const newdistance = distance ?? 0;

    const groupCost =
      newdistance *
      delegationAddRoutesHelper.getDistanceRateForVehicleType(
        props.group.vehicleType,
        props.mileageContract
      );

    const newGroup: DelegationAddRouteGroup = {
      ...props.group,
      distance: distance,
      groupCost: groupCost,
    };

    props.onGroupChange(newGroup);
  };

  const onVehicleTypeChange = (vehicleType: DelegationAddVehicleType) => {
    const groupCost =
      (props.group.distance ?? 0) *
      delegationAddRoutesHelper.getDistanceRateForVehicleType(
        vehicleType,
        props.mileageContract
      );

    const newGroup: DelegationAddRouteGroup = {
      ...props.group,
      vehicleType: vehicleType,
      groupCost: groupCost,
    };

    props.onGroupChange(newGroup);
  };

  useEffect(() => {
    const addresses: DelegationAddAddress[] = [];

    props.group.routes.forEach((item) => {
      if (item.address) {
        addresses.push(item.address);
      }
    });

    setRoutesValidationResult(
      delegationAddFormValidationService.validateAddressGroupRoutes(addresses)
    );
  }, [props.group.routes]);

  useEffect(() => {
    setStartDateValidationResult(
      delegationAddFormValidationService.validateAddressGroupStartDate(
        props.group.startDate,
        props.dateMin ?? null,
        props.dateMax ?? null
      )
    );
  }, [props.group.startDate, props.dateMin, props.dateMax]);

  useEffect(() => {
    setEndDateValidationResult(
      delegationAddFormValidationService.validateAddressGroupEndDate(
        props.group.endDate,
        props.dateMin ?? null,
        props.dateMax ?? null
      )
    );
  }, [props.group.endDate, props.dateMin, props.dateMax]);

  const isDraggingEnabled = props.group.routes.length > 1;

  return (
    <CardComponent>
      <div className="delegation_add_group_list_item__top_row">
        <button
          className="delegation_add_group_list_item__drag_button"
          title={translations.routes.dragButtonTitle}
          {...props.dragHandleProps}
        >
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </button>
        <div className="delegation_add_group_list_item__index">
          {props.groupIndex + 1}
        </div>
        <DragDropContext onDragEnd={onDragRouteListItemEnd}>
          <Droppable droppableId="delegation-add-group-drop-area">
            {(provided) => (
              <div
                {...provided.droppableProps}
                className="delegation_add_group_list_item__droppable"
                ref={provided.innerRef}
              >
                <div className="delegation_add_group_list_item__row">
                  <Column lg={12}>
                    <FormFieldComponent
                      classNames={{
                        root: "delegation_add_group_list_item__space",
                      }}
                      label={translations.vehicleType.headingLabel}
                    >
                      <DelegationAddVehicleTypeSelectComponent
                        vehicleType={props.group.vehicleType}
                        onVehicleTypeChange={onVehicleTypeChange}
                      />
                    </FormFieldComponent>
                  </Column>
                  <Column lg={6}>
                    <FormFieldComponent
                      classNames={{
                        root: "delegation_add_group_list_item__space",
                      }}
                      label={translations.distancePerKilometerCost.headingLabel}
                    >
                      <NumericInputComponent
                        value={delegationAddRoutesHelper.getDistanceRateForVehicleType(
                          props.group.vehicleType,
                          props.mileageContract
                        )}
                        onChange={() => {}}
                        idForTesting="cargo-delegation-add-distance-per-kilometer-cost"
                        isDisabled
                      />
                    </FormFieldComponent>
                  </Column>
                  <Column lg={6}>
                    <FormFieldComponent
                      classNames={{
                        root: "delegation_add_group_list_item__space",
                      }}
                      label={translations.distance.headingLabel}
                      errorMessage={
                        form.validationResults.totalDistance.errorMessage
                      }
                    >
                      <NumericInputComponent
                        value={props.group.distance}
                        onChange={onDistanceChange}
                        idForTesting="cargo-delegation-add-total-distance"
                        placeholder={translations.distance.selectPlaceholder}
                        hasError={
                          !!form.validationResults.totalDistance.errorMessage
                        }
                        isIntegerOnly
                      />
                    </FormFieldComponent>
                  </Column>
                  <Column lg={6}>
                    <FormFieldComponent
                      classNames={{
                        root: "delegation_add_group_list_item__space",
                      }}
                      label={translations.startDate.headingLabel}
                      errorMessage={
                        form.validationResults.dateFrom.errorMessage
                      }
                    >
                      <DateTimeInputComponent
                        date={props.group.startDate}
                        maxDate={props.dateMax ?? undefined}
                        minDate={props.dateMin ?? undefined}
                        onChange={onStartDateChange}
                        idForTesting="delegation-add-group-list-date-from"
                        placeholder={
                          translations.routes.placeholderStartDateLabel
                        }
                      />
                      <FormErrorComponent
                        message={startDateValidationResult.errorMessage}
                      />
                    </FormFieldComponent>
                  </Column>
                  <Column lg={6}>
                    <FormFieldComponent
                      classNames={{
                        root: "delegation_add_group_list_item__space",
                      }}
                      label={translations.endDate.headingLabel}
                      errorMessage={form.validationResults.dateTo.errorMessage}
                    >
                      <DateTimeInputComponent
                        date={props.group.endDate}
                        maxDate={props.dateMax ?? undefined}
                        minDate={props.dateMin ?? undefined}
                        onChange={() => {}}
                        idForTesting="delegation-add-group-list-date-to"
                        placeholder={
                          translations.routes.placeholderEndDateLabel
                        }
                      />
                      <FormErrorComponent
                        message={endDateValidationResult.errorMessage}
                      />
                    </FormFieldComponent>
                  </Column>
                </div>
                {props.group.routes.map((route, routeIndex) => {
                  return (
                    <Column lg={12} key={route.uuid}>
                      <Draggable
                        draggableId={route.uuid}
                        index={routeIndex}
                        isDragDisabled={!isDraggingEnabled}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <DelegationAddRouteItemComponent
                              routeIndex={routeIndex}
                              routeItem={route}
                              updateRouteItem={updateRouteItem}
                              deleteRouteItem={() =>
                                deleteRouteItem(route.uuid)
                              }
                              additionalAddresses={props.additionalAddresses}
                              cargoCompanyAddresses={
                                props.cargoCompanyAddresses
                              }
                              dragHandleProps={provided.dragHandleProps}
                              onBlur={props.onBlur}
                              idForTesting={props.idForTesting}
                            />
                          </div>
                        )}
                      </Draggable>
                    </Column>
                  );
                })}
                {provided.placeholder}
                <FormErrorComponent
                  message={routesValidationResult.errorMessage}
                />
              </div>
            )}
          </Droppable>
          <ButtonComponent
            onClick={props.deleteGroup}
            classNames={{
              root: "delegation_add_group_list_item__delete_button",
            }}
            idForTesting={`delegation-add-group-item-${props.group.uuid}-delete-button`}
          >
            <FontAwesomeIcon icon={faTrash} />
          </ButtonComponent>
        </DragDropContext>
      </div>
    </CardComponent>
  );
};

export default DelegationAddRoutesGroupComponent;
