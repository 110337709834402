import { FC } from "react";
import { OrderDetailsTHandlingLogAssignmentOrderCancelEvent } from "../../../common/types/order-details-handling-log-entry-event";
import orderTranslationsHelper from "../../../../../../../languages/order-translations.helper";
import orderDetailsHistoryEntryAssignmentOrderCancelContentHelper from "./order-details-history-entry-assignment-order-cancel-content.helper";

type OrderDetailsHistoryEntryAssignmentOrderCancelProps = {
  event: OrderDetailsTHandlingLogAssignmentOrderCancelEvent;
};

const OrderDetailsHistoryEntryAssignmentOrderCancelContentComponent: FC<
  OrderDetailsHistoryEntryAssignmentOrderCancelProps
> = (props) => {
  const translations =
    orderTranslationsHelper.getDetailsHistoryTranslations()
      .assignmentOrderCancel;

  const routeLabel =
    orderDetailsHistoryEntryAssignmentOrderCancelContentHelper.getRouteLabel(
      props.event.planEntryUuid
    );

  return (
    <div className="order_details_history_entry_list_item_content">
      <div className="order_details_history_entry_list_item_content__heading_text">
        {translations.headingLabel}
      </div>
      <div
        className="order_details_history_entry_list_item_content__value"
        title={routeLabel}
      >
        {routeLabel}
      </div>
    </div>
  );
};

export default OrderDetailsHistoryEntryAssignmentOrderCancelContentComponent;
