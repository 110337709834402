import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import LinkButtonComponent from "../../../../common/components/button/link/link-button.component";
import CardComponent from "../../../../common/components/card/card.component";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import PaginationComponent from "../../../../common/components/pagination/pagination.component";
import TableComponent from "../../../../common/components/table/table.component";
import useClientSidePagination from "../../../../common/hooks/use-clientside-pagination";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import cargoTranslationsHelper from "../../../../languages/cargo-translations.helper";
import CargoAddressListingBreadcrumbsParams from "../../common/breadcrumbs/types/cargo-address-listing-breadcrumbs-params";
import cargoBreadcrumbsHelper from "../../common/breadcrumbs/cargo-breadcrumbs.helper";
import cargoRoutesHelper from "../../common/routes/cargo-routes.helper";
import CargoAddressListingRouteParams from "../../common/routes/types/cargo-address-listing-route-params";
import cargoAddressListingApiService from "./common/api/cargo-address-listing-api.service";
import CargoAddressListingResponse from "./common/api/cargo-address-listing.response";
import cargoAddressListingFactory from "./common/factory/cargo-address-listing.factory";
import cargoAddressListingFilterHelper from "./common/helper/cargo-address-listing-filter.helper";
import cargoAddressListingSortHelper from "./common/helper/cargo-address-listing-sort.helper";
import cargoAddressListingHelper from "./common/helper/cargo-address-listing.helper";
import CargoAddressListingFilter from "./common/types/cargo-address-listing-filter";
import CargoAddressListingItem from "./common/types/cargo-address-listing-item";
import CargoAddressListingSortKey from "./common/types/cargo-address-listing-sort-key";
import CargoAddressListingTableRow from "./common/types/cargo-address-listing-table-row";
import CargoAddressListingFiltersComponent from "./filters/cargo-address-listing-filters.component";
import CargoAddressListingSortComponent from "./sort/cargo-address-listing-sort.component";
import TableLinkButtonComponent from "../../../../common/components/table/button/link/table-link-button.component";
import useAbort from "../../../../common/hooks/use-abort";
import useCargoCompanyDetails from "../../../../common/services/cargo-company/cargo-company/details/use-cargo-company-details";

type CargoAddressListingProps = {};

const CargoAddressListingComponent: FC<CargoAddressListingProps> = () => {
  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  const { cargoCompanyUuid } = useParams<CargoAddressListingRouteParams>();

  useDocumentTitle(documentTitleTranslations.cargoAddressListing);

  const translations = cargoTranslationsHelper.getAddressListingTranslations();

  const [filters, setFilters] = useState<CargoAddressListingFilter[]>([]);
  const [isListingFetching, setIsListingFetching] = useState(false);
  const [isListingFetchingError, setIsListingFetchingError] = useState(false);
  const [listingItems, setListingItems] = useState<CargoAddressListingItem[]>(
    []
  );
  const [filteredListingItems, setFilteredListingItems] = useState<
    CargoAddressListingItem[]
  >([]);
  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();
  const [cargoCompanyName, setCargoCompanyName] = useState("");

  const cargoCompany = useCargoCompanyDetails();
  const cargoCompanyAbort = useAbort();

  useEffect(() => {
    if (!cargoCompanyUuid) return;

    cargoCompany.load(
      {
        cargoCompanyUuid,
      },
      cargoCompanyAbort.signal
    );

    return () => cargoCompanyAbort.revoke();
  }, [cargoCompanyUuid]);

  useEffect(() => {
    if (!cargoCompany.data) return;

    setCargoCompanyName(cargoCompany.data.displayName);
  }, [cargoCompany.data]);

  const createTableRow = (
    listingItem: CargoAddressListingItem
  ): CargoAddressListingTableRow => {
    const EditAddressLinkButton = (
      <TableLinkButtonComponent
        to={cargoRoutesHelper.getAddressEditRoute({
          addressUuid: listingItem.uuid,
          cargoCompanyUuid: cargoCompanyUuid!,
        })}
        title={translations.table.actions.editAddressLinkButtonTitle}
        icon={faPenToSquare}
      />
    );

    return {
      id: listingItem.uuid,
      value: {
        description: (
          <div title={listingItem.description}>{listingItem.description}</div>
        ),
        country: <div title={listingItem.country}>{listingItem.country}</div>,
        town: <div title={listingItem.town}>{listingItem.town}</div>,
        zipCode: <div title={listingItem.zipCode}>{listingItem.zipCode}</div>,
        street: <div title={listingItem.street}>{listingItem.street}</div>,
        houseApartmentNumber: (
          <div title={listingItem.houseApartmentNumber ?? ""}>
            {listingItem.houseApartmentNumber}
          </div>
        ),
        type: (
          <div
            title={cargoAddressListingHelper.getAddressTypeLabel(
              listingItem.type
            )}
          >
            {cargoAddressListingHelper.getAddressTypeLabel(listingItem.type)}
          </div>
        ),
        geoCoordinates: (
          <div title={listingItem.geoCoordinates ?? ""}>
            {listingItem.geoCoordinates}
          </div>
        ),
        actions: (
          <div
            data-test-id={`cargo-address-listing-item-${listingItem.uuid}-edit-button`}
          >
            {EditAddressLinkButton}
          </div>
        ),
      },
    };
  };

  const { pageData, page, pageSize, setPage, setPageSize, totalResults } =
    useClientSidePagination({
      data: filteredListingItems,
      totalResults: filteredListingItems.length,
      defaultPageSize: 100,
    });

  const [selectedSortKey, setSelectedSortKey] =
    useState<CargoAddressListingSortKey | null>(
      cargoAddressListingSortHelper.getDefaultSelectOption().value
    );

  const addNewFilter = (newFilter: CargoAddressListingFilter) => {
    setFilters((curr) => [...curr, newFilter]);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filterItem) => {
      return filters.indexOf(filterItem) !== index;
    });

    setFilters(newFilters);
  };

  const deleteAllFilters = () => {
    setFilters([]);
  };

  const onSortKeyChange = (key: CargoAddressListingSortKey | null) => {
    setSelectedSortKey(key);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const breadcrumbsParams: CargoAddressListingBreadcrumbsParams = {
      cargoCompanyName,
      cargoCompanyUuid: cargoCompanyUuid!,
    };

    const breadcrumbs =
      cargoBreadcrumbsHelper.getAddressListingBreadcrumbs(breadcrumbsParams);

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, cargoCompanyName]);

  const onListingFetchSuccess = (response: CargoAddressListingResponse) => {
    const listingItems = cargoAddressListingFactory.createListingItems(
      response.data
    );

    setListingItems(listingItems);
  };

  const onListingFetchFailure = () => {
    setIsListingFetchingError(true);
  };

  const handleListingResponse = (response: CargoAddressListingResponse) => {
    if (response.status === 200) {
      onListingFetchSuccess(response);
      return;
    }

    onListingFetchFailure();
  };

  const fetchListing = () => {
    setIsListingFetching(true);
    setIsListingFetchingError(false);

    cargoAddressListingApiService
      .fetchListing(cargoCompanyUuid!)
      .then(handleListingResponse)
      .catch(onListingFetchFailure)
      .finally(() => {
        setIsListingFetching(false);
      });
  };

  useEffect(() => {
    fetchListing();
  }, []);

  useEffect(() => {
    setPage(1);

    const _filteredListingItems =
      cargoAddressListingFilterHelper.filterListingItems(listingItems, filters);

    const sortedListingItems = cargoAddressListingSortHelper.sortListingItems(
      _filteredListingItems,
      selectedSortKey
    );

    setFilteredListingItems(sortedListingItems);
  }, [selectedSortKey, JSON.stringify(filters), JSON.stringify(listingItems)]);

  const tableColumns = cargoAddressListingHelper.getTableColumns();

  const tableRows: CargoAddressListingTableRow[] = useMemo(() => {
    return pageData.map((listingItem) => createTableRow(listingItem));
  }, [pageData]);

  const AddNewAddressLinkButton = useMemo(
    () => (
      <LinkButtonComponent
        type="primary"
        to={cargoRoutesHelper.getAddressAddRoute({
          cargoCompanyUuid: cargoCompanyUuid!,
        })}
        title={translations.header.addAddressLinkButtonTitle}
        idForTesting="cargo-address-listing-add-button"
      >
        {translations.header.addAddressLinkButtonText}
      </LinkButtonComponent>
    ),
    [translations]
  );

  return (
    <div className="cargo_address_listing">
      <HeadingComponent
        title={translations.header.headingText}
        actions={[AddNewAddressLinkButton]}
      />
      <div className="cargo_address_listing_tools">
        <CargoAddressListingFiltersComponent
          filters={filters}
          onAddNewFilter={addNewFilter}
          onDeleteFilterClick={deleteFilter}
          onDeleteAllFiltersButtonClick={deleteAllFilters}
        />
        <CargoAddressListingSortComponent
          sortKey={selectedSortKey}
          onSortKeyChange={onSortKeyChange}
        />
      </div>
      <CardComponent>
        <TableComponent
          columns={tableColumns}
          rows={tableRows}
          isLoading={isListingFetching}
          isError={isListingFetchingError}
        />
        <div className="cargo_address_listing__pagination_wrapper">
          <PaginationComponent
            onPageChange={onPageChange}
            onPageSizeChange={setPageSize}
            page={page}
            pageSize={pageSize}
            totalResults={totalResults}
          />
        </div>
      </CardComponent>
    </div>
  );
};

export default CargoAddressListingComponent;
