import { FC, useState, useMemo } from "react";
import SingleSelectComponent from "../../../../common/components/form/select/single-select/single-select.component";
import { useAppContext } from "../../../../context/app.context";
import mileageTranslationsHelper from "../../../../languages/mileage-translations.helper";
import MileageSettingsMileageNumberCreationModel from "../types/mileage-settings-mileage-number-creation-model";
import MileageSettingsMileageNumberCreationModelSelectOption from "../types/mileage-settings-mileage-number-creation-model-select-option";
import mileageSettingsFactory from "../factory/mileage-settings.factory";

type MileageSettingsMileageNumberCreationModelSelectProps = {
  creationModel: MileageSettingsMileageNumberCreationModel;
  onCreationModelChange: (
    creationModel: MileageSettingsMileageNumberCreationModel
  ) => void;
  onBlur?: () => void;
};

const MileageSettingsMileageNumberCreationModelSelectComponent: FC<
  MileageSettingsMileageNumberCreationModelSelectProps
> = (props) => {
  const translations =
    mileageTranslationsHelper.getMileageSettingsTranslations();

  const { selectedAppLanguage } = useAppContext();

  const [creationModelQuery, setCreationModelQuery] = useState("");
  const creationModelSelectOptions = useMemo(
    mileageSettingsFactory.createMileageNumberCreationModelSelectOptions,
    [selectedAppLanguage]
  );

  const creationModelSelectOption = props.creationModel
    ? mileageSettingsFactory.createMileageNumberCreationModelSelectOption(
        props.creationModel
      )
    : null;

  return (
    <SingleSelectComponent
      placeholder={translations.creationModel.selectPlaceholder}
      value={creationModelSelectOption}
      options={creationModelSelectOptions}
      onChange={(
        value: MileageSettingsMileageNumberCreationModelSelectOption | null
      ) => {
        if (!value) {
          return;
        }

        props.onCreationModelChange(value.value);
      }}
      idForTesting="mileage-settings-mileage-number-creation-model-select-component"
      isSearchable
      filterFunction={() => true}
      inputValue={creationModelQuery}
      onInputChange={setCreationModelQuery}
      onBlur={props.onBlur}
    />
  );
};

export default MileageSettingsMileageNumberCreationModelSelectComponent;
