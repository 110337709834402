import { FC, useEffect, useState } from "react";
import DriverAddBasicProps from "../common/types/driver-add-basic-props";
import ButtonComponent from "../../../../../common/components/button/button.component";
import Row from "../../../../../common/components/grid/row";
import Column from "../../../../../common/components/grid/column";
import DriverAddVehicleDataComponent from "../common/components/vehicle/driver-add-vehicle.component";
import DriverAddAccountDataComponent from "../common/components/account/driver-add-account-data.component";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import driverService from "../../../../../common/services/driver/driver.service";
import notificationService from "../../../../../common/utils/notification/notification.service";
import userRoutesHelper from "../../../common/routes/user-routes.helper";
import DriverAddFormOfEmploymentType from "../common/types/driver-add-form-of-employment-type";
import driverAddVehicleFormHelper from "../common/form/driver-add-vehicle-form.helper";
import useForm from "../../../../../common/components/form/use-form";
import driverAddCompanyFormHelper from "../common/form/driver-add-company-form.helper";
import driverAddAccountFormHelper from "../common/form/driver-add-account-data-form.helper";
import useAbort from "../../../../../common/hooks/use-abort";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "../../../../../common/hooks/use-document-title";
import { useAppContext } from "../../../../../context/app.context";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import userBreadcrumbsHelper from "../../../common/breadcrumbs/user-breadcrumbs.helper";
import TaxiDriverAddUserDataComponent from "../common/components/user-data/taxi-driver-add-user-data.component";
import DriverAddTaxiCorporation from "../common/types/driver-add-taxi-corporation";
import taxiDriverAddParamsFactory from "../common/factory/taxi-driver-add-params.factory";
import taxiDriverAddUserFormHelper from "../common/form/taxi-driver-add-user-data-form.helper";
import TaxiDriverAddCompanyDataComponent from "../common/components/company/taxi-driver-add-company.component";

type TaxiDriverAddProps = DriverAddBasicProps;

const TaxiDriverAddComponent: FC<TaxiDriverAddProps> = (props) => {
  const translations = userTranslationsHelper.getDriverAddTranslations();

  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  const { user } = useAppContext();

  const navigate = useNavigate();

  useEffect(() => {
    const breadcrumbs = userBreadcrumbsHelper.getDriverAddBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.userDriverAdd);

  const abortSignal = useAbort();

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const formAccountFormData = useForm({
    emptyValues: driverAddAccountFormHelper.getDefaultAccountFormData(),
    validationDefinition:
      driverAddAccountFormHelper.getAccountValidationDefinition(),
  });

  const formCompanyFormData = useForm({
    emptyValues: driverAddCompanyFormHelper.getDefaultCompanyFormData(),
    validationDefinition:
      driverAddCompanyFormHelper.getCompanyValidationDefinition(),
  });

  const formUserFormData = useForm({
    emptyValues: taxiDriverAddUserFormHelper.getDefaultUserFormData(),
    validationDefinition:
      taxiDriverAddUserFormHelper.getUserValidationDefinition(),
  });

  const formVehicleFormData = useForm({
    emptyValues: driverAddVehicleFormHelper.getDefaultVehicleFormData(),
    validationDefinition:
      driverAddVehicleFormHelper.getVehicleValidationDefinition(),
  });

  const isCargoDataSectionVisible =
    formUserFormData.values.formOfEmployment ===
    DriverAddFormOfEmploymentType.B2B;

  const navigateToListing = () => {
    const dealerListingRoute = userRoutesHelper.getDriverListingRoute();
    navigate(dealerListingRoute);
  };

  const onDriverAddSuccess = () => {
    notificationService.success(translations.successAddNotificationText);
    navigateToListing();
  };

  const onDriverAddFailure = () => {
    notificationService.error(translations.failureAddNotificationText);
  };

  const taxiCorporationUuid: string =
    user?.aspects.dealer?.taxiCorporationUuid ??
    user?.aspects.taxiOfficer?.taxiCorporationUuid!;

  useEffect(() => {
    formUserFormData.setValue("taxiCorporation", {
      ...(formUserFormData.values.taxiCorporation || {}),
      uuid: taxiCorporationUuid,
    } as DriverAddTaxiCorporation);
  }, [taxiCorporationUuid]);

  const submitForm = async () => {
    const isFormAccountFormDataValid = await formAccountFormData.validateAll();
    const isFormCompanyFormDataValid = isCargoDataSectionVisible
      ? await formCompanyFormData.validateAll()
      : true;
    const isFormUserFormDataValid = await formUserFormData.validateAll();
    const isFormVehicleFormDataValid = await formVehicleFormData.validateAll();

    if (
      !isFormAccountFormDataValid ||
      !isFormCompanyFormDataValid ||
      !isFormUserFormDataValid ||
      !isFormVehicleFormDataValid
    )
      return;

    const params = taxiDriverAddParamsFactory.createSubmitFormParams(
      formAccountFormData.values,
      formUserFormData.values,
      formVehicleFormData.values,
      formCompanyFormData.values,
      taxiCorporationUuid
    );

    try {
      setIsFormSubmitting(true);
      await driverService.addDriver(params, abortSignal.signal);

      onDriverAddSuccess();
    } catch {
      onDriverAddFailure();
    } finally {
      setIsFormSubmitting(false);
    }
  };

  return (
    <div className="user_driver_add">
      <HeadingComponent
        title={translations.header.headingText}
        actions={props.changeViewButtons}
      />
      <Row>
        <Column withPaddings>
          <DriverAddAccountDataComponent
            accountFormValues={formAccountFormData.values}
            onAccountDataChange={formAccountFormData.setValue}
            validateAccountData={formAccountFormData.validate}
            accountValidationResults={formAccountFormData.validationResults}
          />
        </Column>
      </Row>
      <Row>
        <Column withPaddings>
          <TaxiDriverAddUserDataComponent
            onUserDataChange={formUserFormData.setValue}
            validateUserData={formUserFormData.validate}
            userFormValues={formUserFormData.values}
            userValidationResults={formUserFormData.validationResults}
            taxiCorporationUuid={taxiCorporationUuid}
          />
        </Column>
      </Row>
      {isCargoDataSectionVisible && (
        <Row>
          <Column withPaddings>
            <TaxiDriverAddCompanyDataComponent
              onCompanyDataChange={formCompanyFormData.setValue}
              validateCompanyData={formCompanyFormData.validate}
              companyFormValues={formCompanyFormData.values}
              companyValidationResults={formCompanyFormData.validationResults}
            />
          </Column>
        </Row>
      )}
      <Row>
        <Column withPaddings>
          <DriverAddVehicleDataComponent
            onVehicleDataChange={formVehicleFormData.setValue}
            validateVehicleData={formVehicleFormData.validate}
            carOwnershipType={formVehicleFormData.values.ownership!}
            vehicleFormValues={formVehicleFormData.values}
            vehicleValidationResults={formVehicleFormData.validationResults}
          />
        </Column>
      </Row>
      <ButtonComponent
        onClick={submitForm}
        type="primary"
        isLoading={isFormSubmitting}
        classNames={{ root: "mt-2" }}
        idForTesting={`submit-button`}
        title={translations.form.submitButtonTitle}
      >
        {translations.form.submitButtonText}
      </ButtonComponent>
    </div>
  );
};

export default TaxiDriverAddComponent;
