import { FC, useEffect, useMemo, useState } from "react";
import BillingFormData from "./types/billing-form.data";
import billingDataRouteRequestsFactory from "./factory/billing-data-route-requests.factory";
import billingApiService from "./api/billings-cargo-taxi-api.service";
import billingMapRoutesFactory from "./factory/billing-map-routes.factory";
import BillingDataResponse from "./api/billing-data.response";
import billingDataFactory from "./factory/billing-data.factory";
import BillingDetailsComponent from "./billing-details/billing-details.component";
import ContractDetailsComponent from "./contract-details/contract-details.component";
import billingsApiService from "./api/billings-api.service";
import BillingGpsResponse, {
  BillingGpsDataCoordinates,
} from "./api/billing-gps-data.response";
import BillingStatusUpdateResponse from "./api/billing-status-update.response";
import MapRoute from "../../../../common/components/map/types/map-route";
import MapMarker from "../../../../common/components/map/types/map-marker";
import Row from "../../../../common/components/grid/row";
import Column from "../../../../common/components/grid/column";
import MapComponent from "../../../../common/components/map/map.component";
import ButtonComponent from "../../../../common/components/button/button.component";
import CardComponent from "../../../../common/components/card/card.component";
import SearchRoadRoutingResponse from "../../../../common/utils/search-road-route/search-road-routing.response";
import billingsTranslationsHelper from "../../../../languages/billings-translations.helper";
import billingsUserPermissionsHelper from "../../common/user-permissions/billings-user-permission.helper";
import { useAppContext } from "../../../../context/app.context";
import { ActionOption } from "./types/action-option";
import BillingsCargoTaxiModal from "./billings-cargo-taxi-modal";
import BillingStatus from "../../types/billing-status";
import billingStatusUpdateRequestFactory from "./factory/billing-status-update.factory";
import billingCargoTaxiApiService from "./api/billings-cargo-taxi-api.service";
import { useParams } from "react-router-dom";
import BillingCargoTaxiDetailsRouteParams from "../../common/routes/types/billing-cargo-taxi-details-route-params";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import billingBreadcrumbsHelper from "../../common/breadcrumbs/billings-breadcrumbs.helper";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import RelatedBillingsTaxiDriverComponent from "../common/related-billings/taxi-driver/related-billings-taxi-driver.component";
import RelatedBillingsTaxiTaxiComponent from "../common/related-billings/taxi-taxi/related-billings-taxi-taxi.component";
import BillingsRouteDetailsOverviewComponent from "../../common/route-details/billings-route-details-overview.component";
import MessengerComponent from "../../../../common/components/messenger/messenger.component";
import billingsCargoTaxiHelper from "./billings-cargo-taxi.helper";
import MessengerBillingType from "../../../../common/components/messenger/types/messenger-billing-type";
import billingsMapMarkersFactory from "../../common/map-markers/billings-map-markers.factory";
import TabsComponent from "../../../../common/components/tabs/tabs.component";
import TabsData from "../../../../common/components/tabs/common/types/tabs-data";
import OrderDetailsHistoryComponent from "../../../order/details/history/order-details-history.component";
import BillingRouteDetailsComponent from "./billing-route-details/billing-route-details.component";
import BillingsToolsComponent from "../../common/tools/billings-tools.component";
import BillingModel from "../../types/billing-model";
import billingsMapRouteFactory from "./factory/billings-map-route.factory";
import mapMarkerIconFactory from "../../../../common/components/map/marker/map-marker-icon.factory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar } from "@fortawesome/free-solid-svg-icons";
import dateService from "../../../../common/utils/date/date.service";

type BillingComponentProps = {
  isCargoView?: boolean;
};

const BillingsCargoTaxiViewComponent: FC<BillingComponentProps> = (props) => {
  const { billingUuid } = useParams<BillingCargoTaxiDetailsRouteParams>();

  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  const [orderInternalId, setOrderInternalId] = useState("");

  const [billingData, setBillingData] = useState<BillingFormData>();
  const [plannedMapRoute, setPlannedMapRoute] = useState<MapRoute | null>(null);
  const [completedMapRoute, setCompletedMapRoute] = useState<MapRoute | null>(
    null
  );

  const [isCompletedRoutesFetching, setIsCompletedRoutesFetching] =
    useState(false);
  const [shouldShowCompletedMapRoutes, setShouldShowCompletedMapRoutes] =
    useState(false);

  const [hoveredMarker, setHoveredMarker] = useState<MapMarker | null>(null);

  const [completedRouteData, setCompletedRouteData] = useState<
    BillingGpsDataCoordinates[]
  >([]);

  const [shouldRetrieveRouteData, setShouldRetrieveRouteData] = useState(false);
  const [isBillingDataLoading, setIsBillingDataLoading] = useState(false);

  const [orderUuid, setOrderUuid] = useState("");

  const [isModalVisible, setIsModaVisible] = useState(false);
  const [actionOption, setActionOption] = useState<ActionOption>();
  const [shouldShowOrderHistory, setShouldShowOrderHistory] = useState(false);

  const documentTitle = appTranslationsHelper
    .getDocumentTitleTranslations()
    .billingsCargoWithTaxiDetails.replace(
      "#{orderInternalId}",
      orderInternalId
    );

  useDocumentTitle(documentTitle);

  useEffect(() => {
    if (props.isCargoView) {
      const breadcrumbs =
        billingBreadcrumbsHelper.getCargoWithTaxiForCargoDetailsBreadcrumbs({
          billingUuid: billingUuid!,
          orderInternalId,
        });

      setBreadcrumbs(breadcrumbs);
    } else {
      const breadcrumbs =
        billingBreadcrumbsHelper.getCargoWithTaxiForTaxiDetailsBreadcrumbs({
          billingUuid: billingUuid!,
          orderInternalId,
        });

      setBreadcrumbs(breadcrumbs);
    }
  }, [props.isCargoView, selectedAppLanguage, orderInternalId]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setIsBillingDataLoading(true);
    billingApiService
      .fetchBillingData(billingUuid!)
      .then(handleBillingDataResponse)
      .finally(() => {
        setIsBillingDataLoading(false);
      });
  };

  useEffect(() => {
    setIsCompletedRoutesFetching(true);

    billingsApiService
      .fetchGpsData(orderUuid!)
      .then(handleGpsDataResponse)
      .finally(() => setIsCompletedRoutesFetching(false));
  }, [orderUuid]);

  useEffect(() => {
    if (!billingData || !shouldRetrieveRouteData) {
      return;
    }

    const routeRequests =
      billingDataRouteRequestsFactory.createBillingDataRouteRequests(
        billingData.billingNodes
      );

    const fetchPromises: Promise<SearchRoadRoutingResponse>[] = [];

    routeRequests.forEach((routeRequest) => {
      const fetch = billingApiService.fetchRoute(routeRequest);

      fetchPromises.push(fetch);
    });

    Promise.all(fetchPromises).then((responses) => {
      const mapRouteWaypointGroups: MapRoute["waypoints"][] = [];
      const lastResponseIndex = responses.length - 1;
      let lastResponseDuration = 0;

      responses.forEach((response, index) => {
        const mapRoute = response.routes[0]
          ? billingMapRoutesFactory.createMapRoute(
              response.routes[0].geometry.coordinates
            )
          : null;

        if (mapRoute?.waypoints) {
          mapRouteWaypointGroups.push(mapRoute.waypoints);
        }

        if (index === lastResponseIndex) {
          lastResponseDuration = response.routes[0].duration;
        }
      });

      const newMapRouteWaypoints: MapRoute["waypoints"] = [];

      mapRouteWaypointGroups.forEach((waypoint) => {
        newMapRouteWaypoints.push(...waypoint);
      });

      const newMapRoute: MapRoute = {
        waypoints: newMapRouteWaypoints,
        options: { color: "red" },
      };

      if (
        billingData.contractDetails &&
        billingData.contractDetails.model !== BillingModel.AB
      ) {
        const lastBillingNodeIndex = billingData.billingNodes.length - 1;
        const secondToLastBillingNodePlannedDate =
          billingData.billingNodes[lastBillingNodeIndex - 1].plannedDate;

        if (secondToLastBillingNodePlannedDate) {
          const lastBillingNodePlannedDate = new Date(
            new Date(secondToLastBillingNodePlannedDate).getTime() +
              1000 * lastResponseDuration
          );

          billingData.billingNodes[lastBillingNodeIndex].plannedDate =
            lastBillingNodePlannedDate;
          billingData.billingNodes[lastBillingNodeIndex].checkoutDate =
            lastBillingNodePlannedDate;

          setBillingData(billingData);
        }
      }

      setPlannedMapRoute(newMapRoute);
      setShouldRetrieveRouteData(false);
    });
  }, [shouldRetrieveRouteData]);

  const handleBillingDataResponse = (response: BillingDataResponse) => {
    if (response.status === 200) {
      onBillingDataFetchSuccess(response);
    }
  };

  const handleGpsDataResponse = (response: BillingGpsResponse) => {
    if (response.status === 200) {
      onGpsDataFetchSuccess(response.data);
    }
  };

  const handleBillingStatusUpdateResponse = (
    response: BillingStatusUpdateResponse
  ) => {
    if (response.status === 200) {
      fetchData();
    }
  };

  const onBillingDataFetchSuccess = (response: BillingDataResponse) => {
    const billingData = billingDataFactory.createBillingData(response.data);

    setBillingData(billingData);
    setOrderUuid(response.data.cargo_order.id);
    setOrderInternalId(String(response.data.cargo_order.human_id));
    setShouldRetrieveRouteData(true);
  };

  const onGpsDataFetchSuccess = (response: BillingGpsDataCoordinates[]) => {
    setCompletedRouteData(response);
    const gpsData: MapRoute =
      billingsMapRouteFactory.createCompletedMapRoute(response);

    setCompletedMapRoute(gpsData);
    setShouldShowCompletedMapRoutes(true);
  };

  useEffect(() => {
    if (completedRouteData.length > 0 && !isCompletedRoutesFetching) {
      const gpsData: MapRoute =
        billingsMapRouteFactory.createCompletedMapRoute(completedRouteData);
      setCompletedMapRoute(gpsData);
    }
  }, [completedRouteData, isCompletedRoutesFetching]);

  const locationsMapMarkers: MapMarker[] = useMemo(() => {
    const mapMarkers = billingsMapMarkersFactory
      .createMapMarkers(billingData?.billingNodes ?? [])
      .map((marker) => ({ ...marker, isRouteMarker: true }));

    return mapMarkers;
  }, [billingData?.billingNodes]);

  const mapRoutes: MapRoute[] = useMemo(() => {
    const finalMapRoutes: MapRoute[] = [];
    if (plannedMapRoute) {
      finalMapRoutes.push(plannedMapRoute);
    }
    if (completedMapRoute) {
      finalMapRoutes.push(completedMapRoute);
    }
    return finalMapRoutes;
  }, [plannedMapRoute, completedMapRoute]);

  const onMarkerHover = (marker: MapMarker | null) => {
    setHoveredMarker(marker);
  };

  const generateTimeBasedMarkers = (
    completedRoute: BillingGpsDataCoordinates[],
    existingRouteMarkers: MapMarker[]
  ): MapMarker[] => {
    if (!completedRoute || completedRoute.length === 0) {
      return [];
    }

    const markers: MapMarker[] = [];
    const parseTime = (timestamp: Date | string): number =>
      new Date(timestamp).getTime();

    let startTime = parseTime(completedRoute[0].record_time);
    let nextMarkerTime = startTime;

    completedRoute.forEach((currPoint) => {
      let currTime = parseTime(currPoint.record_time);

      if (currTime >= nextMarkerTime) {
        nextMarkerTime = currTime + 60000;
        const date = new Date(currPoint.record_time);

        if (dateService.checkIsValidDate(date)) {
          const formattedTime = dateService.formatTime(date);

          const newMarker = {
            coordinate: { latitude: currPoint.lat, longitude: currPoint.lon },
            icon: mapMarkerIconFactory.createEmptyIcon(),
            title: formattedTime,
            isGpsRouteMarker: true,
            onMouseOver: () =>
              onMarkerHover({
                coordinate: {
                  latitude: currPoint.lat,
                  longitude: currPoint.lon,
                },
                icon: mapMarkerIconFactory.createEmptyIcon(),
                title: formattedTime,
              }),
            onMouseOut: () => onMarkerHover(null),
          };

          const isDuplicate = existingRouteMarkers.some(
            (routeMarker) =>
              routeMarker.coordinate.latitude ===
                newMarker.coordinate.latitude &&
              routeMarker.coordinate.longitude ===
                newMarker.coordinate.longitude &&
              routeMarker.isGpsRouteMarker
          );

          if (!isDuplicate) {
            markers.push(newMarker);
          }
        }
      }
    });

    return markers;
  };

  const gpsRouteMarkers = useMemo(() => {
    if (!shouldShowCompletedMapRoutes) return [];

    const gpsMarkers = generateTimeBasedMarkers(completedRouteData, []);

    return gpsMarkers;
  }, [shouldShowCompletedMapRoutes, completedRouteData]);

  const mapMarkers: MapMarker[] = useMemo(() => {
    return [...locationsMapMarkers, ...gpsRouteMarkers];
  }, [locationsMapMarkers, gpsRouteMarkers]);

  const gpsMarkers = useMemo(() => {
    if (!shouldShowCompletedMapRoutes) return [];

    const timeFormatRegex = /\b\d{2}:\d{2}\b$/;

    const filteredMarkers = (gpsRouteMarkers ?? []).filter(
      (marker) =>
        marker.isGpsRouteMarker && timeFormatRegex.test(marker.title ?? "")
    );

    return filteredMarkers;
  }, [shouldShowCompletedMapRoutes, gpsRouteMarkers]);

  const memoizedMarkers = useMemo(() => {
    if (!completedMapRoute) {
      return (
        <MapComponent
          key={
            hoveredMarker?.coordinate.latitude +
            "-" +
            hoveredMarker?.coordinate.longitude
          }
          markers={mapMarkers}
          autoFit
          routes={mapRoutes}
          shouldShowCompletedMapRoutes={shouldShowCompletedMapRoutes}
          isBilling
        />
      );
    }

    const timeMarkers: MapMarker[] = generateTimeBasedMarkers(
      completedRouteData,
      locationsMapMarkers
    );
    const allMarkers: MapMarker[] = [...mapMarkers, ...timeMarkers];
    const allRoutes: MapRoute[] = [...mapRoutes, completedMapRoute];

    return (
      <MapComponent
        key={
          hoveredMarker?.coordinate.latitude +
          "-" +
          hoveredMarker?.coordinate.longitude
        }
        markers={allMarkers}
        gpsMarkers={gpsMarkers}
        autoFit
        routes={allRoutes}
        shouldShowCompletedMapRoutes={shouldShowCompletedMapRoutes}
        isBilling
      />
    );
  }, [shouldShowCompletedMapRoutes, completedRouteData, mapMarkers, mapRoutes]);

  const onSubmitHandle = (comment: string) => {
    const request =
      billingStatusUpdateRequestFactory.createUpdateBillingStatusRequest(
        actionOption!,
        comment
      );

    billingCargoTaxiApiService
      .updateBillingStatus(billingUuid!, request)
      .then(handleBillingStatusUpdateResponse);

    setIsModaVisible(false);
  };

  const onBillingStatusUpdate = (actionOption: ActionOption) => {
    setActionOption(actionOption);
    setIsModaVisible(true);
  };

  const onModalClose = () => {
    setIsModaVisible(false);
  };

  const { user } = useAppContext();

  const translations =
    billingsTranslationsHelper.getCargoTaxiViewBillingsTranslations();

  const userPermissions = billingsUserPermissionsHelper.getPermissions(
    user?.roles!
  );

  const messengerChannelsAvailability =
    billingsCargoTaxiHelper.getMessengerChannelAvailability();

  const tabsData: TabsData = useMemo(() => {
    if (!billingData) {
      return [];
    }

    return billingDataFactory.createTabsData(
      billingData.billingContributions,
      isBillingDataLoading
    );
  }, [billingData]);

  const onOrderHistoryButtonClick = () => {
    if (shouldShowOrderHistory) {
      setShouldShowOrderHistory(false);
      return;
    }

    setShouldShowOrderHistory(true);
  };

  const billingsCargoTaxiToolsProps = {
    shouldShowOrderHistory,
    onOrderHistoryButtonClick,
  };

  return (
    <>
      <div className="billings">
        <HeadingComponent
          title={translations.header.headingText.replace(
            "#{orderInternalId}",
            orderInternalId
          )}
        />
        <Row>
          <Column lg={8}>
            <Row>
              <Column withPaddings>
                <div className="billings_map_wrapper">
                  {memoizedMarkers}
                  <BillingsToolsComponent {...billingsCargoTaxiToolsProps} />
                </div>
              </Column>
              <Column withPaddings>
                <ContractDetailsComponent
                  isLoading={isBillingDataLoading}
                  contractDetails={billingData?.contractDetails ?? null}
                />
              </Column>
              <Column withPaddings>
                <Row>
                  <Column lg={12}>
                    <BillingsRouteDetailsOverviewComponent
                      billingNodes={billingData?.billingNodes}
                    />
                  </Column>
                </Row>
              </Column>
              <Column withPaddings>
                <BillingRouteDetailsComponent billingData={billingData} />
              </Column>
              {!!billingData && (
                <Column withPaddings>
                  <TabsComponent data={tabsData} />
                </Column>
              )}
              <Column withPaddings>
                <BillingDetailsComponent billingData={billingData} />
              </Column>
              <Column>
                <div className="billings_submit_buttons_container">
                  {userPermissions.hasAccessToAccept &&
                    billingData?.status === BillingStatus.CREATED && (
                      <ButtonComponent
                        type="success"
                        onClick={() =>
                          onBillingStatusUpdate(ActionOption.ACCEPT)
                        }
                      >
                        {translations.acceptLabel}
                      </ButtonComponent>
                    )}

                  {userPermissions.hasAccessToReject &&
                    billingData?.status === BillingStatus.CREATED && (
                      <ButtonComponent
                        type="danger"
                        onClick={() =>
                          onBillingStatusUpdate(ActionOption.REJECT)
                        }
                      >
                        {translations.rejectLabel}
                      </ButtonComponent>
                    )}

                  {userPermissions.hasAccessToReopen &&
                    billingData?.status === BillingStatus.REOPEN_REQUEST && (
                      <ButtonComponent
                        type="primary"
                        onClick={() =>
                          onBillingStatusUpdate(ActionOption.REOPEN)
                        }
                      >
                        {translations.reopenLabel}
                      </ButtonComponent>
                    )}

                  {userPermissions.hasAccessToRequestToReopen &&
                    billingData?.status === BillingStatus.ACCEPTED && (
                      <ButtonComponent
                        type="primary"
                        onClick={() =>
                          onBillingStatusUpdate(
                            ActionOption.SEND_REQUEST_TO_REOPEN
                          )
                        }
                      >
                        {translations.sendRequestToReopenLabel}
                      </ButtonComponent>
                    )}
                </div>
              </Column>
              <Column withPaddings>
                <RelatedBillingsTaxiDriverComponent
                  planEntryId={billingData?.planEntryId}
                  orderId={orderUuid}
                />
              </Column>
              <Column withPaddings>
                <RelatedBillingsTaxiTaxiComponent orderId={orderUuid} />
              </Column>
            </Row>
          </Column>
          <Column lg={4} withPaddings>
            <CardComponent
              classNames={{
                root: "billings_history_messenger_container",
                content: "h-100",
              }}
            >
              {!!billingData &&
                (shouldShowOrderHistory ? (
                  <OrderDetailsHistoryComponent
                    orderUuid={orderUuid}
                    refetchFlag={false}
                  />
                ) : (
                  <MessengerComponent
                    channelsAvailability={messengerChannelsAvailability}
                    billingType={MessengerBillingType.CARGO}
                    billingUuid={billingUuid!}
                    orderUuid={orderUuid}
                    planEntryUuid={billingData.planEntryId}
                  />
                ))}
            </CardComponent>
          </Column>
        </Row>

        <BillingsCargoTaxiModal
          isModalVisible={isModalVisible}
          onSubmit={(value) => onSubmitHandle(value)}
          onClose={onModalClose}
          actionOption={actionOption!}
        />
      </div>
    </>
  );
};

export default BillingsCargoTaxiViewComponent;
