import { FC } from "react";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import useOpen from "../../../../../common/hooks/use-open";
import OrderDetailsToolButtonComponent from "../../common/tool-button/order-details-tool-button.component";
import OrderDetailsOrderCancelRequestConfirmationModalComponent from "../../cancel-request-confirmation/order-details-order-cancel-request-confirmation.component";
import OrderDetailsCargoOrder from "../../common/types/order-details-cargo-order";
import OrderDetailsToolsTranslations from "../../../../../languages/types/order/details/order-detials-tools.translation";

type OrderDetialsCancelRequestComponentProps = {
  details: OrderDetailsCargoOrder | null;
  tools: OrderDetailsToolsTranslations;
  isLoading: boolean;
  hasAccess: boolean;
  onConfirm: () => void;
  onConfirmRevert: () => void;
};

const OrderDetialsCancelRequestComponent: FC<
  OrderDetialsCancelRequestComponentProps
> = ({ tools, details, isLoading, hasAccess, onConfirm, onConfirmRevert }) => {
  const { isOpen, open, close } = useOpen();
  const { isFinished, isCancelled, isCancelRequestSent, cargoCompanyOrderId } =
    details!;

  if (!(!isFinished && !isCancelled && hasAccess)) return null;

  const { cancelOrderRequestButtonTitle, revertCancelOrderRequestButtonTitle } =
    tools;

  const isRevertMode = isCancelRequestSent;

  const handleConfirm = () => {
    if (isRevertMode) {
      onConfirmRevert();
    } else {
      onConfirm();
    }
    close();
  };

  return (
    <>
      <OrderDetailsToolButtonComponent
        onClick={open}
        icon={faBan}
        type="danger"
        title={
          isRevertMode
            ? revertCancelOrderRequestButtonTitle
            : cancelOrderRequestButtonTitle
        }
        isLoading={isLoading}
      />
      {!!isOpen && (
        <OrderDetailsOrderCancelRequestConfirmationModalComponent
          isOpen={isOpen}
          onClose={close}
          onConfirm={handleConfirm}
          cargoCompanyOrderId={cargoCompanyOrderId}
          isRevertMode={isRevertMode}
        />
      )}
    </>
  );
};

export default OrderDetialsCancelRequestComponent;
