import { FormValidationDefinition } from "../../../../../common/components/form/use-form";
import mileageDictionaryAddFormValidationService from "./mileage-dictionary-add-form-validation.service";
import MileageDictionaryAddFormData from "./types/mileage-dictionary-add-form-data";

const getDefaultFormData = (): MileageDictionaryAddFormData => {
  return {
    name: undefined,
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<MileageDictionaryAddFormData> => {
    return {
      name: (values) =>
        mileageDictionaryAddFormValidationService.validateName(values.name),
    };
  };

const mileageDictionaryAddFormHelper = {
  getDefaultFormData,
  getValidationDefinition,
};

export default mileageDictionaryAddFormHelper;
