import dateService from "../../../../../../common/utils/date/date.service";
import BillingsCargoOrderListingTableStatusComponent from "./status/billings-cargo-order-listing-table-status.component";
import TableLinkButtonComponent from "../../../../../../common/components/table/button/link/table-link-button.component";
import billingRoutesHelper from "../../../../../billings/common/routes/billing-routes.helper";
import { faCircleInfo, faCrosshairs } from "@fortawesome/free-solid-svg-icons";
import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";
import BillingsCargoOrderListingTableColumn from "../types/billings-cargo-order-listing-table-column";
import BillingsCargoOrderListingItem from "../types/billings-cargo-order-listing-item";
import { BillingsCargoOrderListingUserPermissions } from "../user-permissions/billings-cargo-order-listing-user-permission";
import BillingsCargoOrderListingTableRow from "../types/billings-cargo-order-listing-table-row";
import BillingsCargoOrderListingItemBillingType from "../types/billings-cargo-order-listing-item-billing-type";
import BillingsCargoOrderListingBillingsAcceptData from "../types/billings-cargo-order-listing-billings-accept-data";
import TableButtonComponent from "../../../../../../common/components/table/button/table-button.component";
import BillingsCargoOrderListingItemBillingStatus from "../types/billings-cargo-order-listing-item-billing-status";

const getColumns = (): BillingsCargoOrderListingTableColumn[] => {
  const translations =
    billingsTranslationsHelper.getCargoOrderBillingsListingTranslations().table
      .headers;

  return [
    {
      accessor: "billingStatus",
      header: "",
      title: "",
      colSpan: 2,
    },
    {
      accessor: "orderStartDate",
      header: translations.orderStartDateLabel,
      title: translations.orderStartDateTitle,
      colSpan: 10,
    },
    {
      accessor: "internalOrderId",
      header: translations.internalOrderIdTitle,
      title: translations.internalOrderIdTitle,
      colSpan: 10,
    },
    {
      accessor: "externalOrderId",
      header: translations.externalOrderIdLabel,
      title: translations.externalOrderIdTitle,
      colSpan: 10,
    },
    {
      accessor: "route",
      header: translations.routeLabel,
      title: translations.routeTitle,
      colSpan: 30,
    },
    {
      accessor: "passengers",
      header: translations.passengersLabel,
      title: translations.passengersTitle,
      colSpan: 10,
    },
    {
      accessor: "taxi",
      header: translations.taxiCorporationLabel,
      title: translations.taxiCorporationTitle,
      colSpan: 10,
    },
    {
      accessor: "dispatch",
      header: translations.dispatchLabel,
      title: translations.dispatchTitle,
      colSpan: 10,
    },
    {
      accessor: "distance",
      header: translations.distanceLabel,
      title: translations.distanceTitle,
      colSpan: 10,
    },
    {
      accessor: "baseDistanceRate",
      header: translations.baseDistanceRateLabel,
      title: translations.baseDistanceRateTitle,
      colSpan: 10,
    },
    {
      accessor: "amountForDistance",
      header: translations.amountForDistanceLabel,
      title: translations.amountForDistanceTitle,
      colSpan: 10,
    },
    {
      accessor: "stopoverTime",
      header: translations.stopoverTimeLabel,
      title: translations.stopoverTimeTitle,
      colSpan: 8,
    },
    {
      accessor: "stopCost",
      header: translations.stopoverCostLabel,
      title: translations.stopoverCostTitle,
      colSpan: 9,
    },
    {
      accessor: "tollRoadsCost",
      header: translations.tollRoadsCostLabel,
      title: translations.tollRoadsCostTitle,
      colSpan: 10,
    },
    {
      accessor: "discountAmount",
      header: translations.discountAmountLabel,
      title: translations.discountAmountTitle,
      colSpan: 6,
    },
    {
      accessor: "sumOfDiscounts",
      header: translations.sumOfDiscountsLabel,
      title: translations.sumOfDiscountsTitle,
      colSpan: 9,
    },
    {
      accessor: "totalCost",
      header: translations.totalCostLabel,
      title: translations.totalCostTitle,
      colSpan: 9,
    },
    {
      accessor: "actions",
      header: "",
      title: "",
      colSpan: 9,
    },
  ];
};

const checkIsAddressGroup = (
  address: string | string[]
): address is string[] => {
  return Array.isArray(address);
};

const getRouteLabel = (
  addresses: BillingsCargoOrderListingItem["routeAddresses"]
): string => {
  const addressesWithFlattenedGroups = addresses.map((address) => {
    const isAddressGroup = checkIsAddressGroup(address);

    if (!isAddressGroup) {
      return address;
    }

    const connectedAddresses = address.join(" | ");
    return `[ ${connectedAddresses} ]`;
  });

  return addressesWithFlattenedGroups.join(" > ");
};

const getRouteTitle = (
  addresses: BillingsCargoOrderListingItem["routeAddresses"]
): string => {
  return addresses.join(`\r`);
};

const getDistanceLabel = (distance: number): string => {
  return `${distance} km`;
};

const getDistanceTitle = (distance: number): string => {
  return `${distance} km`;
};

const getBaseDistanceRateLabel = (baseDistanceRate: number): string => {
  return `${String(baseDistanceRate.toFixed(2))} PLN/km`;
};

const getBaseDistanceRateTitle = (baseDistanceRate: number): string => {
  return `${String(baseDistanceRate.toFixed(2))} PLN/km`;
};

const getStopoverTimeLabel = (stopoverTime: number): string => {
  return `${String(stopoverTime.toFixed(2))} h`;
};

const getStopoverTimeTitle = (stopoverTime: number): string => {
  return `${String(stopoverTime.toFixed(2))} h`;
};

const getStopoverCostLabel = (stopoverCost: number): string => {
  return `${String(stopoverCost.toFixed(2))} PLN`;
};

const getStopoverCostTitle = (stopoverCost: number): string => {
  return `${String(stopoverCost.toFixed(2))} PLN`;
};

const getTollRoadsCostLabel = (tollRoadsCost: number): string => {
  return `${String(tollRoadsCost.toFixed(2))} PLN`;
};

const getTollRoadsCostTitle = (tollRoadsCost: number): string => {
  return `${String(tollRoadsCost.toFixed(2))} PLN`;
};

const getDiscountAmountLabel = (discountAmountPercent: number): string => {
  return `${discountAmountPercent}%`;
};

const getDiscountAmountTitle = (discountAmountPercent: number): string => {
  return `${discountAmountPercent}%`;
};

const getSumOfDiscountsLabel = (discountCost: number): string => {
  return `${String(discountCost.toFixed(2))} PLN`;
};

const getSumOfDiscountsTitle = (discountCost: number): string => {
  return `${String(discountCost.toFixed(2))} PLN`;
};

const getAmountForDistanceLabel = (amountForDistance: number): string => {
  return `${String(amountForDistance.toFixed(2))} PLN`;
};

const getAmountForDistanceTitle = (amountForDistance: number): string => {
  return `${String(amountForDistance.toFixed(2))} PLN`;
};

const getTotalCostLabel = (totalCost: number): string => {
  return `${String(totalCost.toFixed(2))} PLN`;
};

const getTotalCostTitle = (totalCost: number): string => {
  return `${String(totalCost.toFixed(2))} PLN`;
};

const getRow = (
  listingItem: BillingsCargoOrderListingItem,
  userPermissions: BillingsCargoOrderListingUserPermissions,
  isAcceptBillingsEnabled: boolean,
  selectedBillingsAcceptData: BillingsCargoOrderListingBillingsAcceptData[],
  onSelectedBillingsAcceptDataChange: (
    selectedBillingsAcceptData: BillingsCargoOrderListingBillingsAcceptData[]
  ) => void
): BillingsCargoOrderListingTableRow => {
  const translations =
    billingsTranslationsHelper.getCargoOrderBillingsListingTranslations().table;

  const routeAddressesLabel = getRouteLabel(listingItem.routeAddresses);
  const routeAddressesTitle = getRouteTitle(listingItem.routeAddresses);

  const distanceLabel = getDistanceLabel(listingItem.distance);
  const distanceTitle = getDistanceTitle(listingItem.distance);

  const baseDistanceRateLabel = getBaseDistanceRateLabel(
    listingItem.baseDistanceRate
  );
  const baseDistanceRateTitle = getBaseDistanceRateTitle(
    listingItem.baseDistanceRate
  );

  const stoppingTimeLabel = getStopoverTimeLabel(listingItem.stoppingTime);
  const stoppingTimeTitle = getStopoverTimeTitle(listingItem.stoppingTime);

  const stopoverCostLabel = getStopoverCostLabel(listingItem.stopoverCost);
  const stopoverCostTitle = getStopoverCostTitle(listingItem.stopoverCost);

  const tollRoadsCostLabel = getTollRoadsCostLabel(listingItem.tollRoadsCost);
  const tollRoadsCostTitle = getTollRoadsCostTitle(listingItem.tollRoadsCost);

  const discountAmountLabel = getDiscountAmountLabel(
    listingItem.discountAmountPercent
  );
  const discountAmountTitle = getDiscountAmountTitle(
    listingItem.discountAmountPercent
  );

  const sumOfDiscountsLabel = getSumOfDiscountsLabel(
    listingItem.sumOfDiscounts
  );
  const sumOfDiscountsTitle = getSumOfDiscountsTitle(
    listingItem.sumOfDiscounts
  );

  const amountForDistanceLabel = getAmountForDistanceLabel(
    listingItem.amountForDistance
  );
  const amountForDistanceTitle = getAmountForDistanceTitle(
    listingItem.amountForDistance
  );

  const totalCostLabel = getTotalCostLabel(listingItem.totalCost);
  const totalCostTitle = getTotalCostTitle(listingItem.totalCost);

  const startDateLabel = dateService.format(
    listingItem.orderStartDate,
    "dd.mm.yyyy"
  );

  const startDateTitle = dateService.format(
    listingItem.orderStartDate,
    "dd.mm.yyyy"
  );

  const isCargoWithTaxiBillingDetailsButtonVisible =
    userPermissions.hasAccessToDetailsCargoWithTaxiBilling &&
    listingItem.billingType ===
      BillingsCargoOrderListingItemBillingType.PRIV_CARGO_2_TAXI;

  const onSelectBillingForAcceptButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();

    if (
      selectedBillingsAcceptData.find((item) => item.uuid === listingItem.uuid)
    ) {
      onSelectedBillingsAcceptDataChange(
        selectedBillingsAcceptData.filter(
          (item) => item.uuid !== listingItem.uuid
        )
      );

      return;
    }

    onSelectedBillingsAcceptDataChange([
      ...selectedBillingsAcceptData,
      {
        uuid: listingItem.uuid,
        internalOrderId: listingItem.internalOrderId,
      },
    ]);
  };

  return {
    id: listingItem.uuid,
    value: {
      orderStartDate: <div title={startDateLabel}>{startDateTitle}</div>,
      internalOrderId: (
        <div title={listingItem.internalOrderId}>
          {listingItem.internalOrderId}
        </div>
      ),
      route: <div title={routeAddressesTitle}>{routeAddressesLabel}</div>,
      passengers: (
        <div>
          {listingItem.passengers.map((passenger, index) => (
            <div key={index} title={passenger}>
              {passenger}
            </div>
          ))}
        </div>
      ),
      taxi: (
        <div title={listingItem.taxiCorporationName ?? `Raily`}>
          {listingItem.taxiCorporationName ?? `Raily`}
        </div>
      ),
      externalOrderId: (
        <div title={listingItem.externalOrderId}>
          {listingItem.externalOrderId}
        </div>
      ),
      dispatch: (
        <div title={listingItem.dispatchName}>{listingItem.dispatchName}</div>
      ),
      billingStatus: (
        <BillingsCargoOrderListingTableStatusComponent
          status={listingItem.status}
        />
      ),
      distance: <div title={distanceTitle}>{distanceLabel}</div>,
      baseDistanceRate: (
        <div title={baseDistanceRateTitle}>{baseDistanceRateLabel}</div>
      ),
      amountForDistance: (
        <div title={amountForDistanceTitle}>{amountForDistanceLabel}</div>
      ),
      stopoverTime: <div title={stoppingTimeTitle}>{stoppingTimeLabel}</div>,
      stopCost: <div title={stopoverCostTitle}>{stopoverCostLabel}</div>,
      tollRoadsCost: <div title={tollRoadsCostTitle}>{tollRoadsCostLabel}</div>,
      discountAmount: (
        <div title={discountAmountTitle}>{discountAmountLabel}</div>
      ),
      sumOfDiscounts: (
        <div title={sumOfDiscountsTitle}>{sumOfDiscountsLabel}</div>
      ),
      totalCost: <div title={totalCostTitle}>{totalCostLabel}</div>,
      actions: (
        <div className="d-flex">
          {isCargoWithTaxiBillingDetailsButtonVisible && (
            <TableLinkButtonComponent
              icon={faCircleInfo}
              to={billingRoutesHelper.getCargoWithTaxiForCargoDetailsRoute({
                billingUuid: listingItem.uuid,
              })}
              title={translations.cargoWithTaxiBillingDetailsButtonTitle}
              idForTesting={`billings-cargo-order-listing-table-item-${listingItem.uuid}-details-button`}
            />
          )}
          {isAcceptBillingsEnabled &&
            listingItem.status ===
              BillingsCargoOrderListingItemBillingStatus.CREATED && (
              <TableButtonComponent
                icon={faCrosshairs}
                type={
                  selectedBillingsAcceptData.find(
                    (item) => item.uuid === listingItem.uuid
                  )
                    ? "success"
                    : "brand"
                }
                isDisabled={!isAcceptBillingsEnabled}
                onClick={onSelectBillingForAcceptButtonClick}
                idForTesting={`billings-cargo-order-listing-table-item-${listingItem.uuid}-select-billing-accept-button`}
              />
            )}
        </div>
      ),
    },
  };
};

const getRows = (
  listingItems: BillingsCargoOrderListingItem[],
  userPermissions: BillingsCargoOrderListingUserPermissions,
  isAcceptBillingsEnabled: boolean,
  selectedBillingsAcceptData: BillingsCargoOrderListingBillingsAcceptData[],
  onSelectedBillingsAcceptDataChange: (
    selectedBillingsAcceptData: BillingsCargoOrderListingBillingsAcceptData[]
  ) => void
): BillingsCargoOrderListingTableRow[] => {
  return listingItems.map((listingItem) =>
    getRow(
      listingItem,
      userPermissions,
      isAcceptBillingsEnabled,
      selectedBillingsAcceptData,
      onSelectedBillingsAcceptDataChange
    )
  );
};

const billingsCargoOrderListingTableHelper = {
  getColumns,
  getRows,
};

export default billingsCargoOrderListingTableHelper;
