import { FC, useState } from "react";
import Calendar, { Detail } from "react-calendar";
import {
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ComponentClassnames from "../../../../types/component-classnames";
import { useAppContext } from "../../../../../context/app.context";
import classNames from "classnames";
import datePickerHelper from "../date-picker.helper";

type DatePickerProps = {
  classNames?: ComponentClassnames;
  date: Date | null;
  onChange: (date: Date | null) => void;
  minDate?: Date;
  maxDate?: Date;
};

const DatePickerComponent: FC<DatePickerProps> = (props) => {
  const { selectedAppLanguage } = useAppContext();
  const dateLocale = datePickerHelper.getDatePickerLocale(selectedAppLanguage);

  const [viewMode, setViewMode] = useState<Detail>("month");

  const DatePickerIcon = ({ icon }: { icon: IconProp }) => (
    <FontAwesomeIcon size="sm" icon={icon} />
  );

  const onDateChange = (date: Date | Date[]) => {
    if (date instanceof Date) {
      props.onChange(date);
    }
  };

  return (
    <div className={classNames("date_picker", props.classNames?.root)}>
      <Calendar
        locale={dateLocale}
        minDate={props.minDate}
        maxDate={props.maxDate}
        view={viewMode}
        onViewChange={(value) => setViewMode(value.view)}
        prev2Label={<DatePickerIcon icon={faAngleDoubleLeft} />}
        prevLabel={<DatePickerIcon icon={faAngleLeft} />}
        nextLabel={<DatePickerIcon icon={faAngleRight} />}
        next2Label={<DatePickerIcon icon={faAngleDoubleRight} />}
        className="date_picker_calendar"
        value={props.date}
        onChange={onDateChange}
      />
    </div>
  );
};

export default DatePickerComponent;
