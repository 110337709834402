import {
  DriverEditFormResponseAssociation,
  DriverEditFormResponseCarOwnership,
  DriverEditFormResponseCitizenship,
  DriverEditFormResponseDriver,
  DriverEditFormResponseFormOfEmployment,
  DriverEditFormResponseItem,
  DriverEditFormResponseLanguage,
  DriverEditFormResponseAddress,
  DriverEditFormResponseUser,
  DriverEditFormResponseFleetPartner,
  DriverEditFormResponseTaxiCorporation,
} from "../../../api/raily/driver/edit-form/driver-edit-form.response";
import DriverEditForm, {
  DriverEditFormAddress,
  DriverEditFormAddressType,
  DriverEditFormAssociation,
  DriverEditFormCarOwnership,
  DriverEditFormCitizenship,
  DriverEditFormDriver,
  DriverEditFormFleetPartner,
  DriverEditFormFormOfEmployment,
  DriverEditFormLanguage,
  DriverEditFormTaxiCorporation,
  DriverEditFormUser,
} from "./driver-edit-form";

const createFormOfEmployment = (
  formOfEmployment: DriverEditFormResponseFormOfEmployment
): DriverEditFormFormOfEmployment => {
  switch (formOfEmployment) {
    case DriverEditFormResponseFormOfEmployment.B2B:
      return DriverEditFormFormOfEmployment.B2B;
    case DriverEditFormResponseFormOfEmployment.EMPLOYMENT:
      return DriverEditFormFormOfEmployment.EMPLOYMENT;
  }
};

const createLanguage = (
  type: DriverEditFormResponseLanguage
): DriverEditFormLanguage => {
  switch (type) {
    case DriverEditFormResponseLanguage.BY:
      return DriverEditFormLanguage.BY;
    case DriverEditFormResponseLanguage.CZ:
      return DriverEditFormLanguage.CZ;
    case DriverEditFormResponseLanguage.DE:
      return DriverEditFormLanguage.DE;
    case DriverEditFormResponseLanguage.LT:
      return DriverEditFormLanguage.LT;
    case DriverEditFormResponseLanguage.PL:
      return DriverEditFormLanguage.PL;
    case DriverEditFormResponseLanguage.RU:
      return DriverEditFormLanguage.RU;
    case DriverEditFormResponseLanguage.SK:
      return DriverEditFormLanguage.SK;
    case DriverEditFormResponseLanguage.UA:
      return DriverEditFormLanguage.UA;
    case DriverEditFormResponseLanguage.EN:
      return DriverEditFormLanguage.EN;
    case DriverEditFormResponseLanguage.ES:
      return DriverEditFormLanguage.ES;
    case DriverEditFormResponseLanguage.FR:
      return DriverEditFormLanguage.FR;
  }
};

const createLanguages = (
  langueages: DriverEditFormResponseLanguage[]
): DriverEditFormLanguage[] => {
  const requestLanguages: DriverEditFormLanguage[] =
    langueages.map(createLanguage);

  return requestLanguages;
};

const createCitizenship = (
  type: DriverEditFormResponseCitizenship
): DriverEditFormCitizenship => {
  switch (type) {
    case DriverEditFormResponseCitizenship.BY:
      return DriverEditFormCitizenship.BY;
    case DriverEditFormResponseCitizenship.CZ:
      return DriverEditFormCitizenship.CZ;
    case DriverEditFormResponseCitizenship.DE:
      return DriverEditFormCitizenship.DE;
    case DriverEditFormResponseCitizenship.LT:
      return DriverEditFormCitizenship.LT;
    case DriverEditFormResponseCitizenship.PL:
      return DriverEditFormCitizenship.PL;
    case DriverEditFormResponseCitizenship.RU:
      return DriverEditFormCitizenship.RU;
    case DriverEditFormResponseCitizenship.SK:
      return DriverEditFormCitizenship.SK;
    case DriverEditFormResponseCitizenship.UA:
      return DriverEditFormCitizenship.UA;
  }
};

const createCarOwnership = (
  type: DriverEditFormResponseCarOwnership
): DriverEditFormCarOwnership => {
  switch (type) {
    case DriverEditFormResponseCarOwnership.BUSINESS:
      return DriverEditFormCarOwnership.BUSINESS;
    case DriverEditFormResponseCarOwnership.OWNER:
      return DriverEditFormCarOwnership.OWNER;
  }
};

const createFleetPartner = (
  fleetPartner: DriverEditFormResponseFleetPartner
): DriverEditFormFleetPartner => {
  return {
    createdAt: fleetPartner.createdAt,
    createdBy: fleetPartner.createdBy,
    modifiedAt: fleetPartner.modifiedAt,
    modifiedBy: fleetPartner.modifiedBy,
    name: fleetPartner.name,
    taxiCorporationId: fleetPartner.taxiCorporationId,
    uuid: fleetPartner.id,
  };
};

const createFleetPartners = (
  fleetPartners: DriverEditFormResponseFleetPartner[]
): DriverEditFormFleetPartner[] => {
  return fleetPartners.map(createFleetPartner);
};

const createTaxiCorporation = (
  taxiCorporation: DriverEditFormResponseTaxiCorporation
): DriverEditFormTaxiCorporation => {
  return {
    companyName: taxiCorporation.companyName,
    contractMaintenancePolicy: taxiCorporation.contractMaintenancePolicy,
    createdAt: taxiCorporation.createdAt,
    createdBy: taxiCorporation.createdBy,
    displayName: taxiCorporation.displayName,
    fleetPartners: createFleetPartners(taxiCorporation.fleetPartners),
    headquartersAddressString: taxiCorporation.headquartersAddressString,
    id: taxiCorporation.id,
    krsNo: taxiCorporation.krsNo,
    modifiedAt: taxiCorporation.modifiedAt,
    modifiedBy: taxiCorporation.modifiedBy,
    notes: taxiCorporation.notes,
    regon: taxiCorporation.regon,
    taxId: taxiCorporation.taxId,
  };
};

const createTaxiDriverAssociation = (
  association: DriverEditFormResponseAssociation
): DriverEditFormAssociation => {
  return {
    companyName: association.companyName,
    apartment: association.apartment,
    contractType: createFormOfEmployment(association.contractType),
    country: association.country,
    createdAt: association.createdAt,
    createdBy: association.createdBy,
    description: association.description,
    fleetPartner: association.fleetPartner
      ? createFleetPartner(association.fleetPartner)
      : null,
    fleetPartnerId: association.fleetPartnerId,
    houseNo: association.houseNo,
    id: association.id,
    krsNo: association.krsNo,
    modifiedAt: association.modifiedAt,
    modifiedBy: association.modifiedBy,
    regon: association.regon,
    street: association.street,
    taxiCorporation: createTaxiCorporation(association.taxiCorporation),
    taxId: association.taxId,
    town: association.town,
    zipCode: association.zipCode,
  };
};

const createTaxiDriverAssociations = (
  associations: DriverEditFormResponseAssociation[]
): DriverEditFormAssociation[] => {
  return associations.map(createTaxiDriverAssociation);
};

const createUser = (user: DriverEditFormResponseUser): DriverEditFormUser => {
  return {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    mobilePrimary: user.mobilePrimary,
    mobilePrimaryPrefix: user.mobilePrimaryPrefix,
    mobileSecondary: user.mobileSecondary,
    mobileSecondaryPrefix: user.mobileSecondaryPrefix,
    username: user.username,
  };
};

const createAddress = (
  address: DriverEditFormResponseAddress,
  type: DriverEditFormAddressType
): DriverEditFormAddress => {
  return {
    apartment: address.apartment,
    country: address.country,
    description: address.description,
    displayName: address.displayName,
    houseNo: address.houseNo,
    lat: address.lat,
    lon: address.lon,
    street: address.street,
    town: address.town,
    type: type,
    zipCode: address.zipCode,
  };
};

const createAddresses = (
  startingAddress: DriverEditFormResponseAddress,
  mailingAddress: DriverEditFormResponseAddress
): DriverEditFormAddress[] => {
  const requestAddresses: DriverEditFormAddress[] = [];

  requestAddresses.push(
    createAddress(startingAddress, DriverEditFormAddressType.STARTING)
  );
  requestAddresses.push(
    createAddress(mailingAddress, DriverEditFormAddressType.MAILING)
  );

  return requestAddresses;
};

const createDriver = (
  driver: DriverEditFormResponseDriver
): DriverEditFormDriver => {
  return {
    birthDate: driver.birthDate,
    birthPlace: driver.birthPlace,
    carMake: driver.carMake,
    carModel: driver.carModel,
    carOwnership: createCarOwnership(driver.carOwnership),
    carProdYear: driver.carProdYear,
    carRegNo: driver.carRegNo,
    carSeatsNo: driver.carSeatsNo,
    citizenship: createCitizenship(driver.citizenship),
    experience: driver.experience,
    idNumber: driver.idNumber,
    languages: createLanguages(driver.languages),
    mobileModel: driver.mobileModel,
  };
};

const create = (item: DriverEditFormResponseItem): DriverEditForm => {
  return {
    addresses: createAddresses(item.startingAddress, item.mailingAddress),
    associations: createTaxiDriverAssociations(item.associations),
    driver: createDriver(item.driver),
    user: createUser(item.user),
  };
};

const driverEditFormFactory = { create };

export default driverEditFormFactory;
