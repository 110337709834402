import { FC, useEffect, useState } from "react";
import ButtonComponent from "../../../../../common/components/button/button.component";
import CardComponent from "../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../common/components/form/input/input.component";
import PhoneNumberInputComponent from "../../../../../common/components/form/input/phone-number/phone-number-input.component";
import Column from "../../../../../common/components/grid/column";
import Row from "../../../../../common/components/grid/row";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import notificationService from "../../../../../common/utils/notification/notification.service";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import PassengerFormDataAddressComponent from "../../common/components/address/passenger-address.component";
import PassengerAddResponse from "../common/api/passenger-add-response";
import PassengerAddress from "../../common/types/passenger-address";
import PassengerAddByRailyCargoCompanySelectOption from "./common/types/passenger-add-by-raily-cargo-company-select-option";
import PassengerDispatchSelectOption from "../../common/types/passenger-dispatch-select-option";
import PassengerAddByRailyFormData from "./common/types/passenger-add-by-raily-form-data";
import PassengerAddViewBasicProps from "../common/types/passenger-add-view-basic-props";
import passengerAddByRailyFormHelper from "./common/passenger-add-by-raily-form.helper";
import PassengerAddByRailyFormValidationResults from "./common/types/passenger-add-by-raily-form-validation.results";
import PassengerAddByRailyCargoCompaniesResponse from "./common/api/passenger-add-by-raily-cargo-companies-response";
import passengerAddByRailyFactory from "./common/passenger-add-by-raily.factory";
import passengerAddByRailyApiService from "./common/api/passenger-add-by-raily-api.service";
import { useNavigate } from "react-router-dom";
import userRoutesHelper from "../../../common/routes/user-routes.helper";
import passengerAddByRailyFormValidationService from "./common/passenger-add-by-raily-form-validation.service";
import userApiService from "../../../common/api/user-api.service";
import UserCheckExistsResponse from "../../../common/api/user-check-exists.response";
import MultiSelectComponent from "../../../../../common/components/form/select/multi-select/multi-select.component";
import { useAppContext } from "../../../../../context/app.context";
import useAbort from "../../../../../common/hooks/use-abort";
import CargoCompanyDispatchList from "../../../../../common/services/cargo-company/dispatch/list/cargo-company-dispatch-list";
import useCargoCompanyDispatchMultiList from "../../../../../common/services/cargo-company/dispatch/multi-list/use-cargo-company-dispatch-multi-list";

type PassengerAddByRailyProps = PassengerAddViewBasicProps;

const PassengerAddByRailyComponent: FC<PassengerAddByRailyProps> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const navigate = useNavigate();

  const translations = userTranslationsHelper.getPassengerAddTranslations();

  const [isCargoCompaniesFetching, setIsCargoCompaniesFetching] =
    useState(true);

  const [cargoCompaniesSelectOptions, setCargoCompaniesSelectOptions] =
    useState<PassengerAddByRailyCargoCompanySelectOption[]>([]);

  const [dispatchesSelectOptions, setDispatchesSelectOptions] = useState<
    PassengerDispatchSelectOption[]
  >([]);

  const [formData, setFormData] = useState<PassengerAddByRailyFormData>(() => {
    return passengerAddByRailyFormHelper.getDefaultFormData();
  });

  const [formValidationResults, setValidationResults] =
    useState<PassengerAddByRailyFormValidationResults>(() => {
      return passengerAddByRailyFormHelper.getFormValidationResults();
    });

  const [isCheckUserExistsFetching, setIsCheckUserExistsFetching] =
    useState(false);

  const cargoCompanyDispatchMultiList = useCargoCompanyDispatchMultiList();
  const cargoCompanyDispatchMultiListAbort = useAbort();

  const setUserExists = () => {
    const validationResult: PassengerAddByRailyFormValidationResults["username"] =
      {
        isValid: false,
        errorMessage:
          userTranslationsHelper.getUserAddTranslations()
            .userExistsValidationMessage,
      };

    setValidationResults((curr) => ({
      ...curr,
      username: validationResult,
    }));
  };

  const handleUserExistsFetchResponseSuccess = (
    response: UserCheckExistsResponse
  ) => {
    const isUserExists = response.data;
    if (isUserExists) {
      setUserExists();
    }
  };

  const handleUserExistsFetchResponse = (response: UserCheckExistsResponse) => {
    if (response.status === 200) {
      handleUserExistsFetchResponseSuccess(response);
      return;
    }
  };

  const checkUserExists = (username: string) => {
    setIsCheckUserExistsFetching(true);
    userApiService
      .fetchIsUserExist(username)
      .then(handleUserExistsFetchResponse)
      .finally(() => setIsCheckUserExistsFetching(false));
  };

  const validateAndCheckUsernameExists = () => {
    if (!validateUsername()) {
      return;
    }

    checkUserExists(formData.username);
  };

  // temporary solution - remove after refactor
  useEffect(() => {
    const revalidationFields = Object.keys(formValidationResults).filter(
      (formKey) =>
        formValidationResults[
          formKey as keyof PassengerAddByRailyFormValidationResults
        ].isValid === false
    );

    revalidationFields.forEach((field) => {
      switch (field) {
        case "firstName":
          return validateFirstName();
        case "lastName":
          return validateLastName();
        case "username":
          return validateUsername();
        case "email":
          return validateEmail();
        case "mobile":
          return validateMobile();
        case "alternativeMobile":
          return validateAlternativeMobile();
        case "password":
          return validatePassword();
        case "passwordRepeat":
          return validatePasswordRepeat();
        case "addresses":
          return validateAddresses();
        case "cargoCompany":
          return validateCargoCompanies();
        case "dispatches":
          return validateDispatches();
      }
    });
  }, [selectedAppLanguage]);

  const fetchCargoCompanies = () => {
    passengerAddByRailyApiService
      .fetchCargoCompanies()
      .then(handleCargoCompaniesResponse)
      .catch(onCargoCompaniesFetchError)
      .finally(() => {
        setIsCargoCompaniesFetching(false);
      });
  };

  useEffect(() => {
    fetchCargoCompanies();
  }, []);

  const handleCargoCompaniesResponse = (
    response: PassengerAddByRailyCargoCompaniesResponse
  ) => {
    if (response.status === 200) {
      onCargoCompaniesFetchSuccess(response);
      return;
    }
    onCargoCompaniesFetchError();
  };

  const onCargoCompaniesFetchSuccess = (
    response: PassengerAddByRailyCargoCompaniesResponse
  ) => {
    const cargoCompaniesSelectOptions =
      passengerAddByRailyFactory.createCargoCompanySelectOptions(response.data);

    setCargoCompaniesSelectOptions(cargoCompaniesSelectOptions);
  };

  const onCargoCompaniesFetchError = () => {
    notificationService.error(
      translations.failureFetchCargoCompaniesNotificationText
    );
  };

  useEffect(() => {
    if (!!!formData.cargoCompanies.length) return;

    const cargoCompanyUuids = formData.cargoCompanies.map(
      (company) => company.value.uuid
    );

    cargoCompanyDispatchMultiList.load(
      {
        cargoCompanyUuids,
      },
      cargoCompanyDispatchMultiListAbort.signal
    );

    return () => {
      cargoCompanyDispatchMultiListAbort.revoke();
    };
  }, [formData.cargoCompanies]);

  useEffect(() => {
    if (!cargoCompanyDispatchMultiList.data) return;

    onDispatchesFetchSuccess(cargoCompanyDispatchMultiList.data);
  }, [cargoCompanyDispatchMultiList.data]);

  const onDispatchesFetchSuccess = (response: CargoCompanyDispatchList) => {
    const dispatchSelectOptions =
      passengerAddByRailyFactory.createDispatchSelectOptions(response.data);

    setDispatchesSelectOptions(dispatchSelectOptions);
  };

  const validateFirstName = () => {
    const validationResult =
      passengerAddByRailyFormValidationService.validateFirstName(
        formData.firstName
      );

    setValidationResults((curr) => ({
      ...curr,
      firstName: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateLastName = () => {
    const validationResult =
      passengerAddByRailyFormValidationService.validateLastName(
        formData.lastName
      );

    setValidationResults((curr) => ({
      ...curr,
      lastName: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateMobile = () => {
    const validationResult =
      passengerAddByRailyFormValidationService.validateMobile(formData.mobile);

    setValidationResults((curr) => ({
      ...curr,
      mobile: validationResult,
    }));

    return validationResult;
  };

  const validateAlternativeMobile = () => {
    const validationResult =
      passengerAddByRailyFormValidationService.validateAlternativeMobile(
        formData.alternativeMobile
      );

    setValidationResults((curr) => ({
      ...curr,
      alternativeMobile: validationResult,
    }));

    return validationResult;
  };

  const validateEmail = () => {
    const validationResult =
      passengerAddByRailyFormValidationService.validateEmail(formData.email);

    setValidationResults((curr) => ({
      ...curr,
      email: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateUsername = async () => {
    const validationResult =
      await passengerAddByRailyFormValidationService.validateUsername(
        formData.username
      );

    setValidationResults((curr) => ({
      ...curr,
      username: validationResult,
    }));

    return validationResult.isValid;
  };

  const validatePassword = () => {
    const validationResult =
      passengerAddByRailyFormValidationService.validatePassword(
        formData.password
      );

    setValidationResults((curr) => ({
      ...curr,
      password: validationResult,
    }));

    if (!!formData.passwordRepeat) {
      validatePasswordRepeat();
    }

    return validationResult.isValid;
  };

  const validatePasswordRepeat = () => {
    const validationResult =
      passengerAddByRailyFormValidationService.validatePasswordRepeat(
        formData.password,
        formData.passwordRepeat
      );

    setValidationResults((curr) => ({
      ...curr,
      passwordRepeat: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCargoCompanies = (
    value: PassengerAddByRailyFormData["cargoCompanies"] = formData.cargoCompanies
  ) => {
    const validationResult =
      passengerAddByRailyFormValidationService.validateCargoCompanies(value);

    setValidationResults((curr) => ({
      ...curr,
      cargoCompany: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateDispatches = (
    value: PassengerAddByRailyFormData["dispatches"] = formData.dispatches
  ) => {
    const validationResult =
      passengerAddByRailyFormValidationService.validateDispatches(value);

    setValidationResults((curr) => ({
      ...curr,
      dispatches: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateAddresses = (
    value: PassengerAddByRailyFormData["addresses"] = formData.addresses
  ) => {
    const validationResult =
      passengerAddByRailyFormValidationService.validateAddresses(value);

    setValidationResults((curr) => ({
      ...curr,
      addresses: validationResult,
    }));

    return validationResult.isValid;
  };

  const checkIsFormValid = () => {
    const isCargoCompanyValid = validateCargoCompanies();
    const isDispatchIdsValid = validateDispatches();
    const isEmailValid = validateEmail();
    const isUsernameValid = validateUsername();
    const isPasswordValid = validatePassword();
    const isPasswordRepeatValid = validatePasswordRepeat();
    const isFirstNameValid = validateFirstName();
    const isLastNameValid = validateLastName();
    const isMobileValid = validateMobile();
    const isMobileAlternativeValid = validateAlternativeMobile();
    const isAddressesValid = validateAddresses();

    return (
      isFirstNameValid &&
      isLastNameValid &&
      isEmailValid &&
      isMobileValid &&
      isMobileAlternativeValid &&
      isPasswordValid &&
      isPasswordRepeatValid &&
      isCargoCompanyValid &&
      isDispatchIdsValid &&
      isAddressesValid &&
      isUsernameValid
    );
  };

  const submitForm = async () => {
    const isFormValid = checkIsFormValid();

    if (!isFormValid) return;

    const request =
      passengerAddByRailyFactory.createAddPassengerRequest(formData);

    passengerAddByRailyApiService
      .addPassenger(request)
      .then(handleSubmitResponse);
  };

  const navigateToListing = () => {
    const passengerListingRoute = userRoutesHelper.getPassengerListingRoute();
    navigate(passengerListingRoute);
  };

  const handleSubmitResponse = (response: PassengerAddResponse) => {
    if (response.status === 201) {
      notificationService.success(translations.successAddNotificationText);
      navigateToListing();
      return;
    }
    notificationService.error(translations.failureAddNotificationText);
  };

  const setFirstName = (
    firstName: PassengerAddByRailyFormData["firstName"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      firstName: firstName,
    }));
  };

  const setLastName = (lastName: PassengerAddByRailyFormData["lastName"]) => {
    setFormData((curr) => ({
      ...curr,
      lastName: lastName,
    }));
  };

  const setUsername = (username: PassengerAddByRailyFormData["username"]) => {
    setFormData((curr) => ({
      ...curr,
      username: username.toLowerCase(),
    }));
  };

  const setEmail = (email: PassengerAddByRailyFormData["email"]) => {
    setFormData((curr) => ({ ...curr, email: email }));
  };

  const setPassword = (password: PassengerAddByRailyFormData["password"]) => {
    setFormData((curr) => ({
      ...curr,
      password: password,
    }));
  };

  const setPasswordRepeat = (
    password: PassengerAddByRailyFormData["passwordRepeat"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      passwordRepeat: password,
    }));
  };

  const setMobile = (phoneNumber: PassengerAddByRailyFormData["mobile"]) => {
    setFormData((curr) => ({
      ...curr,
      mobile: phoneNumber,
    }));
  };
  const setAlternativeMobile = (
    phoneNumber: PassengerAddByRailyFormData["alternativeMobile"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      alternativeMobile: phoneNumber,
    }));
  };

  const addNewAddress = (newAddress: PassengerAddress) => {
    const newAddresses: PassengerAddByRailyFormData["addresses"] = [
      ...formData.addresses,
      newAddress,
    ];

    setFormData((curr: PassengerAddByRailyFormData) => ({
      ...curr,
      addresses: newAddresses,
    }));

    validateAddresses(newAddresses);
  };

  const removeAddress = (index: number) => {
    const filteredAddresses: PassengerAddress[] = formData.addresses.filter(
      (_address, addressIndex: number) => {
        return index !== addressIndex;
      }
    );

    setFormData((curr: PassengerAddByRailyFormData) => ({
      ...curr,
      addresses: filteredAddresses,
    }));

    validateAddresses(filteredAddresses);
  };

  const setCargoCompanies = (
    value: PassengerAddByRailyFormData["cargoCompanies"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      cargoCompanies: value,
    }));
  };

  const setDispatches = (value: PassengerAddByRailyFormData["dispatches"]) => {
    setFormData((curr) => ({
      ...curr,
      dispatches: value,
    }));
  };

  const onCargoCompaniesChange = (
    value: PassengerAddByRailyFormData["cargoCompanies"]
  ) => {
    setCargoCompanies(value);
    validateCargoCompanies(value);
  };

  const onDispatchesChange = (
    value: PassengerAddByRailyFormData["dispatches"]
  ) => {
    setDispatches(value);
    validateDispatches(value);
  };

  return (
    <div className="user_passenger_add">
      <HeadingComponent
        title={translations.header.headingText}
        actions={props.changeViewButtons}
      />
      <CardComponent>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.cargoCompaniesLabel}
              isRequired
              classNames={{ root: "mt-0" }}
              errorMessage={formValidationResults.cargoCompanies.errorMessage}
            >
              <MultiSelectComponent
                isLoading={isCargoCompaniesFetching}
                placeholder={translations.form.cargoCompaniesPlaceholder}
                isSearchable
                onChange={(option) => onCargoCompaniesChange(option)}
                options={cargoCompaniesSelectOptions}
                value={formData.cargoCompanies}
                hasError={!!formValidationResults.cargoCompanies.errorMessage}
                idForTesting={`cargo-company-select`}
              />
            </FormFieldComponent>
          </Column>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.dispatchesLabel}
              isRequired
              classNames={{ root: "mt-0" }}
              errorMessage={formValidationResults.dispatches.errorMessage}
            >
              <MultiSelectComponent
                isLoading={cargoCompanyDispatchMultiList.isLoading}
                placeholder={translations.form.dispatchesPlaceholder}
                onChange={(option) => onDispatchesChange(option)}
                options={dispatchesSelectOptions}
                value={formData.dispatches}
                hasError={!!formValidationResults.dispatches.errorMessage}
                idForTesting={`dispatches-select`}
              />
            </FormFieldComponent>
          </Column>
        </Row>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.emailLabel}
              isRequired
              errorMessage={formValidationResults.email.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.emailPlaceholder}
                value={formData.email}
                onChange={setEmail}
                onBlur={validateEmail}
                hasError={!!formValidationResults.email.errorMessage}
                idForTesting={`email-input`}
              />
            </FormFieldComponent>
          </Column>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.usernameLabel}
              isRequired
              errorMessage={formValidationResults.username.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.usernamePlaceholder}
                value={formData.username}
                onChange={setUsername}
                onBlur={validateAndCheckUsernameExists}
                hasError={!!formValidationResults.username.errorMessage}
                idForTesting={`username-input`}
                isDisabled={isCheckUserExistsFetching}
              />
            </FormFieldComponent>
          </Column>
        </Row>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.firstNameLabel}
              isRequired
              errorMessage={formValidationResults.firstName.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.firstNamePlaceholder}
                value={formData.firstName}
                onChange={setFirstName}
                onBlur={validateFirstName}
                hasError={!!formValidationResults.firstName.errorMessage}
                idForTesting={`first-name-input`}
              />
            </FormFieldComponent>
          </Column>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.lastNameLabel}
              isRequired
              errorMessage={formValidationResults.lastName.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.lastNamePlaceholder}
                value={formData.lastName}
                onChange={setLastName}
                onBlur={validateLastName}
                hasError={!!formValidationResults.lastName.errorMessage}
                idForTesting={`last-name-input`}
              />
            </FormFieldComponent>
          </Column>
        </Row>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.mobileLabel}
              isRequired
              errorMessage={formValidationResults.mobile.errorMessage}
            >
              <PhoneNumberInputComponent
                placeholder={translations.form.mobilePlaceholder}
                phoneNumber={formData.mobile}
                onChange={setMobile}
                onBlur={validateMobile}
                hasError={!!formValidationResults.mobile.errorMessage}
                idForTesting={`mobile`}
              />
            </FormFieldComponent>
          </Column>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.alternativeMobileLabel}
              errorMessage={
                formValidationResults.alternativeMobile.errorMessage
              }
            >
              <PhoneNumberInputComponent
                placeholder={translations.form.alternativeMobilePlaceholder}
                phoneNumber={formData.alternativeMobile}
                onChange={setAlternativeMobile}
                onBlur={validateAlternativeMobile}
                hasError={
                  !!formValidationResults.alternativeMobile.errorMessage
                }
                idForTesting={`alternative-mobile`}
              />
            </FormFieldComponent>
          </Column>
        </Row>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.passwordLabel}
              isRequired
              errorMessage={formValidationResults.password.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.passwordPlaceholder}
                value={formData.password}
                onChange={setPassword}
                onBlur={validatePassword}
                type={"password"}
                hasError={!!formValidationResults.password.errorMessage}
                idForTesting={`password-input`}
              />
            </FormFieldComponent>
          </Column>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.passwordRepeatLabel}
              isRequired
              errorMessage={formValidationResults.passwordRepeat.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.passwordRepeatPlaceholder}
                value={formData.passwordRepeat}
                onChange={setPasswordRepeat}
                onBlur={validatePasswordRepeat}
                type={"password"}
                hasError={!!formValidationResults.passwordRepeat.errorMessage}
                idForTesting={`password-repeat-input`}
              />
            </FormFieldComponent>
          </Column>
        </Row>
        <Row>
          <Column>
            <FormFieldComponent
              label={translations.form.addressLabel}
              isRequired
              errorMessage={formValidationResults.addresses.errorMessage}
            >
              <PassengerFormDataAddressComponent
                onAddressAdd={addNewAddress}
                onAddressRemove={removeAddress}
                addresses={formData.addresses}
              />
            </FormFieldComponent>
          </Column>
        </Row>
      </CardComponent>
      <ButtonComponent
        onClick={submitForm}
        type="primary"
        title={translations.form.submitButtonTitle}
        classNames={{ root: "mt-4" }}
        idForTesting={`submit-button`}
      >
        {translations.form.submitButtonText}
      </ButtonComponent>
    </div>
  );
};
export default PassengerAddByRailyComponent;
