import HttpResponse from "../../../../utils/http/http.response";

export enum CargoTaxiContractDetailsResponseItemBillingModel {
  ABA = "ABA",
  SABS = "SABS",
  AB = "AB",
  OTHER = "OTHER",
}

export enum CargoTaxiContractDetailsResponseItemTaxiCorporationContractMaintenancePolicy {
  BASIC = "BASIC",
  AUCTION = "AUCTION",
}

export type CargoTaxiContractDetailsResponseItemTaxiCorporationFleetPartner = {
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  taxiCorporationId: string;
  name: string;
};

export type CargoTaxiContractDetailsResponseItemTaxiCorporation = {
  taxId: string;
  regon: string | null;
  krsNo: string | null;
  companyName: string;
  displayName: string;
  headquartersAddressString: string;
  contractMaintenancePolicy: CargoTaxiContractDetailsResponseItemTaxiCorporationContractMaintenancePolicy;
  notes: string;
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  fleetPartners: CargoTaxiContractDetailsResponseItemTaxiCorporationFleetPartner[];
};

export type CargoTaxiContractDetailsResponseItemCargoCompany = {
  taxId: string;
  regon: string | null;
  krsNo: string | null;
  companyName: string;
  displayName: string;
  headquartersAddressString: string;
  notes: string;
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
};

export type CargoTaxiContractDetailsResponseItem = {
  allowChargeWhileApproaching: boolean;
  allowChargeWhileReturning: boolean;
  allowChargeDuringRide: boolean;
  discountLimit: number;
  validSince: Date;
  validTo: Date;
  disabled: boolean;
  model: CargoTaxiContractDetailsResponseItemBillingModel;
  distanceRate: number;
  haltingTimeRate: number;
  haltingTimeAfterMinutes: number;
  publishingAllowed: boolean;
  cargoCompanyId: string;
  taxiCorporationId: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  taxiCorporation: CargoTaxiContractDetailsResponseItemTaxiCorporation;
  cargoCompany: CargoTaxiContractDetailsResponseItemCargoCompany;
  id: string;
  contactPassengerAfterMinutes: number;
  cancelByTaxiAllowed: boolean;
  editByTaxiAllowed: boolean;
};

type CargoTaxiContractDetailsResponse =
  HttpResponse<CargoTaxiContractDetailsResponseItem>;

export default CargoTaxiContractDetailsResponse;
