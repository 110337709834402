import userTranslationsHelper from "../../../../../../../../../languages/user-translations.helper";
import DriverEditUserDataAddressTableColumn from "../../../../types/driver-edit-user-data-address-table-column";

const getColumns = (): DriverEditUserDataAddressTableColumn[] => {
  const translations =
    userTranslationsHelper.getDriverEditTranslations().form.userData;

  const tableColumns: DriverEditUserDataAddressTableColumn[] = [
    {
      header: translations.addressTableHeader.addressName,
      title: translations.addressTableHeader.addressName,
      accessor: "address",
      colSpan: 6,
    },
    {
      header: "",
      title: "",
      accessor: "actions",
    },
  ];
  return tableColumns;
};

const driverEditUserDataAddressTableHelper = { getColumns };

export default driverEditUserDataAddressTableHelper;
