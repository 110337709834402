import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import {
  RevertOrderCancelledError,
  RevertOrderCancelNotRequestedError,
  RevertOrderCancelRequestErrorType,
  RevertOrderFinishedError,
} from "./revert-order-cancel-request-error";

const createRevertOrderCancelledError = (): RevertOrderCancelledError => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().revertCancelOrderRequest
      .errors;

  return {
    message: translations.orderCancelledError,
    params: {},
    type: RevertOrderCancelRequestErrorType.ORDER_CANCELLED,
  };
};

const createRevertOrderCancelNotRequestedError =
  (): RevertOrderCancelNotRequestedError => {
    const translations =
      orderTranslationsHelper.getDetailsTranslations().revertCancelOrderRequest
        .errors;

    return {
      message: translations.orderCancelRequestedError,
      params: {},
      type: RevertOrderCancelRequestErrorType.ORDER_CANCEL_NOT_REQUESTED,
    };
  };

const createRevertOrderFinishedError = (): RevertOrderFinishedError => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().revertCancelOrderRequest
      .errors;

  return {
    message: translations.orderFinishedError,
    params: {},
    type: RevertOrderCancelRequestErrorType.ORDER_FINISHED,
  };
};

const revertOrderCancelRequestErrorFactory = {
  createRevertOrderCancelledError,
  createRevertOrderCancelNotRequestedError,
  createRevertOrderFinishedError,
};

export default revertOrderCancelRequestErrorFactory;
