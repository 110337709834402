import { FormValidationDefinition } from "../../../../../../common/components/form/use-form";
import driverEditVehicleFormValidationService from "./driver-edit-vehicle-form-validation.service";
import DriverEditVehicleFormData from "./types/driver-edit-vehicle-form-data";

const getDefaultVehicleFormData = (): DriverEditVehicleFormData => {
  return {
    make: "",
    model: "",
    numberOfSeats: null,
    ownership: null,
    productionYear: null,
    registrationNumber: "",
  };
};

const getVehicleValidationDefinition =
  (): FormValidationDefinition<DriverEditVehicleFormData> => {
    return {
      make: (values) =>
        driverEditVehicleFormValidationService.validateMake(values.make),
      model: (values) =>
        driverEditVehicleFormValidationService.validateModel(values.model),
      numberOfSeats: (values) =>
        driverEditVehicleFormValidationService.validateNumberOfSeats(
          values.numberOfSeats
        ),
      ownership: (values) =>
        driverEditVehicleFormValidationService.validateOwnership(
          values.ownership
        ),
      productionYear: (values) =>
        driverEditVehicleFormValidationService.validateProductionYear(
          values.productionYear
        ),
      registrationNumber: (values) =>
        driverEditVehicleFormValidationService.validateRegistrationNumber(
          values.registrationNumber
        ),
    };
  };

const driverEditVehicleFormHelper = {
  getDefaultVehicleFormData,
  getVehicleValidationDefinition,
};

export default driverEditVehicleFormHelper;
