export enum DriverEditRequestAddressType {
  STARTING = "STARTING",
  MAILING = "MAILING",
}

export enum DriverEditRequestFormOfEmployment {
  B2B = "B2B",
  EMPLOYMENT = "EMPLOYMENT",
}

export enum DriverEditRequestCitizenship {
  PL = "PL",
  DE = "DE",
  SK = "SK",
  CZ = "CZ",
  UA = "UA",
  RU = "RU",
  BY = "BY",
  LT = "LT",
}

export enum DriverEditRequestLanguage {
  PL = "PL",
  EN = "EN",
  DE = "DE",
  FR = "FR",
  ES = "ES",
  SK = "SK",
  CZ = "CZ",
  UA = "UA",
  RU = "RU",
  BY = "BY",
  LT = "LT",
}

export enum DriverEditRequestCarOwnership {
  OWNER = "OWNER",
  BUSINESS = "BUSINESS",
}

export type DriverEditRequestUser = {
  firstName: string;
  lastName: string;
  mobilePrimaryPrefix: string;
  mobilePrimary: string;
  mobileSecondaryPrefix: string | null;
  mobileSecondary: string | null;
  email: string;
};

export type DriverEditRequestDriver = {
  birthDate: string;
  birthPlace: string;
  idNumber: string;
  citizenship: DriverEditRequestCitizenship;
  languages: DriverEditRequestLanguage[];
  experience: number;
  carOwnership: DriverEditRequestCarOwnership;
  carMake: string;
  carModel: string;
  carRegNo: string;
  carProdYear: number;
  carSeatsNo: number;
};

export type DriverEditRequestAddress = {
  type: DriverEditRequestAddressType;
  lat: number;
  lon: number;
  country: string;
  town: string;
  zipCode: string;
  street: string;
  houseNo: string;
  apartment: string;
  description: string | null;
};

export type DriverEditRequestTaxiDriverAssociationForEmployment = {
  contractType: DriverEditRequestFormOfEmployment;
  fleetPartnerId: string | null;
  id: string;
};

export type DriverEditRequestTaxiDriverAssociationForB2B = {
  contractType: DriverEditRequestFormOfEmployment;
  taxId: string;
  regon: string | null;
  krsNo: string | null;
  fleetPartnerId: string | null;
  companyName: string;
  country: string;
  town: string;
  zipCode: string;
  street: string;
  houseNo: string;
  apartment: string;
  description: string;
  id: string;
};

export type DriverEditRequestTaxiDriverAssociation =
  | DriverEditRequestTaxiDriverAssociationForEmployment
  | DriverEditRequestTaxiDriverAssociationForB2B;

export type DriverEditRequestBody = {
  user: DriverEditRequestUser;
  driver: DriverEditRequestDriver;
  associations: DriverEditRequestTaxiDriverAssociation[];
  addresses: DriverEditRequestAddress[];
};

type DriverEditRequest = {
  user: DriverEditRequestUser;
  driver: DriverEditRequestDriver;
  associations: DriverEditRequestTaxiDriverAssociation[];
  addresses: DriverEditRequestAddress[];
};

export default DriverEditRequest;
