import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import OrderCancelParams from "./cancel/order-cancel-params";
import OrderCancelRequest from "../../api/raily/order/order-cancel/order-cancel.request";
import orderCancelRequestFactory from "./cancel/order-cancel-request.factory";
import orderApiService from "../../api/raily/order/order-api.service";
import {
  OrderCancelNotAcceptableResponse,
  OrderCancelNotAcceptableResponseErrorType,
} from "../../api/raily/order/order-cancel/order-cancel.response";
import OrderCancelError from "./cancel/order-cancel-error";
import {
  OrderCancelRequestNotAcceptableResponse,
  OrderCancelRequestNotAcceptableResponseErrorType,
} from "../../api/raily/order/order-cancel-request/order-cancel-request.response";
import OrderCancelErrorFactory from "./cancel/order-cancel-error.factory";
import OrderCancelRequestParams from "./cancel-request/order-cancel-request-params";
import OrderCancelRequestRequest from "../../api/raily/order/order-cancel-request/order-cancel-request.request";
import orderCancelRequestRequestFactory from "./cancel-request/order-cancel-request-request.factory";
import OrderCancelRequestError from "./cancel-request/order-cancel-request-error";
import OrderCancelRequestErrorFactory from "./cancel-request/order-cancel-request-error.factory";
import {
  RevertOrderCancelRequestNotAcceptableResponse,
  RevertOrderCancelRequestNotAcceptableResponseErrorType,
} from "../../api/raily/order/revert-cancel-request/revert-cancel-request.response";
import RevertOrderCancelRequestError from "./revert-cancel-request/revert-order-cancel-request-error";
import revertOrderCancelRequestErrorFactory from "./revert-cancel-request/revert-order-cancel-request-error.factory";
import RevertOrderCancelRequestParams from "./revert-cancel-request/revert-order-cancel-request-params";
import RevertOrderCancelRequestRequest from "../../api/raily/order/revert-cancel-request/revert-cancel-request.request";
import revertOrderCancelRequestRequestFactory from "./revert-cancel-request/revert-order-cancel-request-request.factory";

const handleCancelError = (
  error: HttpError | OrderCancelError
): OrderCancelError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const createOrderCancelNotAcceptableError = (
  response: OrderCancelNotAcceptableResponse
): OrderCancelError => {
  switch (response.data.errorType) {
    case OrderCancelNotAcceptableResponseErrorType.ORDER_CANCELLED:
      return OrderCancelErrorFactory.createOrderCancelError();
    case OrderCancelNotAcceptableResponseErrorType.PASSENGER_ON_BOARD:
      return OrderCancelErrorFactory.createPassengerOnBoardError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const cancel = async (
  params: OrderCancelParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: OrderCancelRequest = orderCancelRequestFactory.create(params);

  try {
    const response = await orderApiService().cancel(request, abortSignal);

    if (response.status === 406) {
      throw createOrderCancelNotAcceptableError(
        response as OrderCancelNotAcceptableResponse
      );
    }

    if (response.status !== 201) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | OrderCancelError;

    throw handleCancelError(error);
  }
};

const handleCancelRequestError = (
  error: HttpError | OrderCancelRequestError
): OrderCancelRequestError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const createOrderCancelRequestNotAcceptableError = (
  response: OrderCancelRequestNotAcceptableResponse
): OrderCancelRequestError => {
  switch (response.data.errorType) {
    case OrderCancelRequestNotAcceptableResponseErrorType.ORDER_CANCELLED:
      return OrderCancelRequestErrorFactory.createOrderCancelError();
    case OrderCancelRequestNotAcceptableResponseErrorType.ORDER_CANCEL_REQUESTED:
      return OrderCancelRequestErrorFactory.createOrderCancelRequestError();
    case OrderCancelRequestNotAcceptableResponseErrorType.ORDER_FINISHED:
      return OrderCancelRequestErrorFactory.createOrderFinishedError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const sendCancelRequest = async (
  params: OrderCancelRequestParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: OrderCancelRequestRequest =
    orderCancelRequestRequestFactory.create(params);

  try {
    const response = await orderApiService().sendCancelRequest(
      request,
      abortSignal
    );

    if (response.status === 406) {
      throw createOrderCancelRequestNotAcceptableError(
        response as OrderCancelRequestNotAcceptableResponse
      );
    }

    if (response.status !== 201) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | OrderCancelRequestError;

    throw handleCancelRequestError(error);
  }
};

const handleRevertCancelRequestError = (
  error: HttpError | RevertOrderCancelRequestError
): RevertOrderCancelRequestError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const createRevertOrderCancelRequestNotAcceptableError = (
  response: RevertOrderCancelRequestNotAcceptableResponse
): RevertOrderCancelRequestError => {
  switch (response.data.errorType) {
    case RevertOrderCancelRequestNotAcceptableResponseErrorType.ORDER_CANCELLED:
      return revertOrderCancelRequestErrorFactory.createRevertOrderCancelledError();
    case RevertOrderCancelRequestNotAcceptableResponseErrorType.ORDER_CANCEL_NOT_REQUESTED:
      return revertOrderCancelRequestErrorFactory.createRevertOrderCancelNotRequestedError();
    case RevertOrderCancelRequestNotAcceptableResponseErrorType.ORDER_FINISHED:
      return revertOrderCancelRequestErrorFactory.createRevertOrderFinishedError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const sendRevertCancelRequest = async (
  params: RevertOrderCancelRequestParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: RevertOrderCancelRequestRequest =
    revertOrderCancelRequestRequestFactory.create(params);

  try {
    const response = await orderApiService().sentRevertCancelRequest(
      request,
      abortSignal
    );

    if (response.status === 406) {
      throw createRevertOrderCancelRequestNotAcceptableError(
        response as RevertOrderCancelRequestNotAcceptableResponse
      );
    }

    if (response.status !== 204) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | RevertOrderCancelRequestError;

    throw handleRevertCancelRequestError(error);
  }
};

const orderService = {
  cancel,
  sendCancelRequest,
  sendRevertCancelRequest,
};

export default orderService;
