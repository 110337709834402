import { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MapMarker from "../../../../../../common/components/map/types/map-marker";
import DriverAddAddress from "../types/driver-add-address";
import { faEnvelope, faHome } from "@fortawesome/free-solid-svg-icons";
import mapMarkerIconFactory from "../../../../../../common/components/map/marker/map-marker-icon.factory";
import driverAddUserDataAddressHelper from "../components/user-data/address/driver-add-user-data-address.helper";
import DriverAddAddressType from "../types/driver-add-address-type";

const createMapMarker = (
  address: DriverAddAddress,
  content?: ReactNode
): MapMarker => {
  const icon = mapMarkerIconFactory.createIcon({
    content,
    className: "map_marker standard",
  });

  return {
    coordinate: {
      latitude: address.latitude,
      longitude: address.longitude,
    },
    title: driverAddUserDataAddressHelper.getAddressLabel(address),
    tooltip: driverAddUserDataAddressHelper.getAddressLabel(address),
    icon,
  };
};

const getIconForAddressType = (type: DriverAddAddressType) => {
  switch (type) {
    case DriverAddAddressType.STARTING:
      return (
        <FontAwesomeIcon
          icon={faHome}
          className="driver_add_user_add_address__map_marker_signature_icon"
        />
      );
    case DriverAddAddressType.MAILING:
      return (
        <FontAwesomeIcon
          icon={faEnvelope}
          className="driver_add_user_add_address__map_marker_signature_icon"
        />
      );
    default:
      return undefined;
  }
};

const createMapMarkers = (addresses: DriverAddAddress[]): MapMarker[] => {
  return addresses.map((address) =>
    createMapMarker(address, getIconForAddressType(address.type))
  );
};

const driverAddUserDataAddressFactory = {
  createMapMarkers,
  createMapMarker,
};

export default driverAddUserDataAddressFactory;
