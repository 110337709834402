import Joi from "joi";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import userTranslationsHelper from "../../../../../../languages/user-translations.helper";
import DriverEditCarOwnershipType from "../types/driver-edit-car-ownership-type";

const validateMake = (value: string) => {
  const validationSchema = Joi.string().max(50).trim().required();

  return formValidationService.validate(value, validationSchema);
};

const validateModel = (value: string) => {
  const validationSchema = Joi.string().max(50).trim().required();

  return formValidationService.validate(value, validationSchema);
};

const validateProductionYear = (value: number | null) => {
  const validationSchema = Joi.number()
    .min(1971)
    .max(new Date().getFullYear())
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateNumberOfSeats = (value: number | null) => {
  const validationSchema = Joi.number().min(2).max(25).required();

  return formValidationService.validate(value, validationSchema);
};

const validateRegistrationNumber = (value: string) => {
  const registrationPattern = new RegExp(`^[A-Z0-9]*$`);
  const validationSchema = Joi.string()
    .min(4)
    .max(8)
    .trim()
    .pattern(registrationPattern)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateOwnership = (value: DriverEditCarOwnershipType | null) => {
  const translations = userTranslationsHelper.getDriverEditTranslations();

  const validationSchema = Joi.string()
    .valid(...Object.values(DriverEditCarOwnershipType))
    .required()
    .messages({
      "any.only": translations.selectComponentNotificationError,
    });

  return formValidationService.validate(value, validationSchema);
};

const driverEditVehicleFormValidationService = {
  validateMake,
  validateModel,
  validateProductionYear,
  validateNumberOfSeats,
  validateRegistrationNumber,
  validateOwnership,
};

export default driverEditVehicleFormValidationService;
