import { FC, useState } from "react";
import userTranslationsHelper from "../../../../../../../languages/user-translations.helper";
import CardComponent from "../../../../../../../common/components/card/card.component";
import Row from "../../../../../../../common/components/grid/row";
import Column from "../../../../../../../common/components/grid/column";
import FormFieldComponent from "../../../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../../../common/components/form/input/input.component";
import userService from "../../../../../../../common/services/user/user.service";
import useAbort from "../../../../../../../common/hooks/use-abort";
import DriverAddAccountFormData from "../../form/types/driver-add-account-form-data";
import FormValidationResult from "../../../../../../../common/utils/validation/types/form-validation-result";

type DriverAddAccountDataProps = {
  validateAccountData: (
    formKey: keyof DriverAddAccountFormData,
    value?: unknown
  ) => Promise<boolean>;
  onAccountDataChange: (
    formKey: keyof DriverAddAccountFormData,
    value: any
  ) => void;
  accountValidationResults: Record<
    keyof DriverAddAccountFormData,
    FormValidationResult
  >;
  accountFormValues: DriverAddAccountFormData;
};

const DriverAddAccountDataComponent: FC<DriverAddAccountDataProps> = (
  props
) => {
  const translations =
    userTranslationsHelper.getDriverAddTranslations().form.accountData;

  const abortSignal = useAbort();

  const [isCheckUserExistsFetching, setIsCheckUserExistsFetching] =
    useState(false);

  const checkUserExists = (username: string) => {
    setIsCheckUserExistsFetching(true);
    userService
      .checkUserExists({ username }, abortSignal.signal)
      .finally(() => setIsCheckUserExistsFetching(false));
  };

  const onUsernameBlur = () => {
    checkUserExists;
    props.onAccountDataChange("username", props.accountFormValues.username);
    props.validateAccountData("username");
  };

  return (
    <CardComponent header={{ title: translations.headingText }}>
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.emailLabel}
            isRequired
            errorMessage={props.accountValidationResults.email.errorMessage}
            classNames={{ root: "mt-0" }}
          >
            <InputComponent
              placeholder={translations.emailPlaceholder}
              value={props.accountFormValues.email}
              onChange={(value) => props.onAccountDataChange("email", value)}
              onBlur={() => {
                props.validateAccountData("email");
              }}
              hasError={!!props.accountValidationResults.email.errorMessage}
              idForTesting={`account-data-email`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.usernameLabel}
            isRequired
            errorMessage={props.accountValidationResults.username.errorMessage}
            classNames={{ root: "mt-0" }}
          >
            <InputComponent
              isDisabled={isCheckUserExistsFetching}
              placeholder={translations.usernamePlaceholder}
              value={props.accountFormValues.username}
              onChange={(value) =>
                props.onAccountDataChange("username", value.toLowerCase())
              }
              onBlur={onUsernameBlur}
              hasError={!!props.accountValidationResults.username.errorMessage}
              idForTesting={`account-data-user-name`}
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.passwordLabel}
            isRequired
            errorMessage={props.accountValidationResults.password.errorMessage}
          >
            <InputComponent
              placeholder={translations.passwordPlaceholder}
              value={props.accountFormValues.password}
              type="password"
              onChange={(value) => props.onAccountDataChange("password", value)}
              onBlur={() => {
                props.validateAccountData("password");
              }}
              hasError={!!props.accountValidationResults.password.errorMessage}
              idForTesting={`account-data-password`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.passwordRepeatLabel}
            isRequired
            errorMessage={
              props.accountValidationResults.passwordRepeat.errorMessage
            }
          >
            <InputComponent
              placeholder={translations.passwordRepeatPlaceholder}
              value={props.accountFormValues.passwordRepeat}
              type="password"
              onChange={(value) =>
                props.onAccountDataChange("passwordRepeat", value)
              }
              onBlur={() => {
                props.validateAccountData("passwordRepeat");
              }}
              hasError={
                !!props.accountValidationResults.passwordRepeat.errorMessage
              }
              idForTesting={`account-data-password-repeat`}
            />
          </FormFieldComponent>
        </Column>
      </Row>
    </CardComponent>
  );
};

export default DriverAddAccountDataComponent;
