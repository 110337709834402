import DriverAddParams, {
  DriverAddParamsAddress,
  DriverAddParamsAddressType,
  DriverAddParamsCarOwnership,
  DriverAddParamsCitizenship,
  DriverAddParamsFormOfEmployment,
  DriverAddParamsLanguage,
  DriverAddParamsTaxiDriverAssociation,
  DriverAddParamsTaxiDriverAssociationForB2B,
  DriverAddParamsTaxiDriverAssociationForEmployment,
} from "../../../../../../common/services/driver/add/driver-add-params";
import dateService from "../../../../../../common/utils/date/date.service";
import DriverAddAccountFormData from "../form/types/driver-add-account-form-data";
import DriverAddCompanyFormData from "../form/types/driver-add-company-form-data";
import DriverAddVehicleFormData from "../form/types/driver-add-vehicle-form-data";
import TaxiDriverAddUserFormData from "../form/types/taxi-driver-add-user-form-data";
import DriverAddAddress from "../types/driver-add-address";
import DriverAddAddressType from "../types/driver-add-address-type";
import DriverAddCarOwnershipType from "../types/driver-add-car-ownership-type";
import DriverAddCitizenshipType from "../types/driver-add-citizenship-type";
import DriverAddFormOfEmploymentType from "../types/driver-add-form-of-employment-type";
import DriverAddLanguageType from "../types/driver-add-language-type";

const driverAddParamsType = (
  type: DriverAddAddressType
): DriverAddParamsAddressType => {
  switch (type) {
    case DriverAddAddressType.MAILING:
      return DriverAddParamsAddressType.MAILING;
    case DriverAddAddressType.STARTING:
      return DriverAddParamsAddressType.STARTING;
  }
};

const driverAddParamsContractType = (
  type: DriverAddFormOfEmploymentType | null
): DriverAddParamsFormOfEmployment | undefined => {
  switch (type) {
    case DriverAddFormOfEmploymentType.B2B:
      return DriverAddParamsFormOfEmployment.B2B;
    case DriverAddFormOfEmploymentType.EMPLOYMENT:
      return DriverAddParamsFormOfEmployment.EMPLOYMENT;
    default:
      return undefined;
  }
};

const driverAddParamsCarOwnershipType = (
  type: DriverAddCarOwnershipType
): DriverAddParamsCarOwnership => {
  switch (type) {
    case DriverAddCarOwnershipType.BUSINESS:
      return DriverAddParamsCarOwnership.BUSINESS;
    case DriverAddCarOwnershipType.OWNER:
      return DriverAddParamsCarOwnership.OWNER;
  }
};

const driverAddParamsCitizenshipType = (
  type: DriverAddCitizenshipType
): DriverAddParamsCitizenship => {
  switch (type) {
    case DriverAddCitizenshipType.BY:
      return DriverAddParamsCitizenship.BY;
    case DriverAddCitizenshipType.CZ:
      return DriverAddParamsCitizenship.CZ;
    case DriverAddCitizenshipType.DE:
      return DriverAddParamsCitizenship.DE;
    case DriverAddCitizenshipType.LT:
      return DriverAddParamsCitizenship.LT;
    case DriverAddCitizenshipType.PL:
      return DriverAddParamsCitizenship.PL;
    case DriverAddCitizenshipType.RU:
      return DriverAddParamsCitizenship.RU;
    case DriverAddCitizenshipType.SK:
      return DriverAddParamsCitizenship.SK;
    case DriverAddCitizenshipType.UA:
      return DriverAddParamsCitizenship.UA;
  }
};

const driverAddParamsLanguageType = (
  type: DriverAddLanguageType
): DriverAddParamsLanguage => {
  switch (type) {
    case DriverAddLanguageType.BY:
      return DriverAddParamsLanguage.BY;
    case DriverAddLanguageType.CZ:
      return DriverAddParamsLanguage.CZ;
    case DriverAddLanguageType.DE:
      return DriverAddParamsLanguage.DE;
    case DriverAddLanguageType.LT:
      return DriverAddParamsLanguage.LT;
    case DriverAddLanguageType.PL:
      return DriverAddParamsLanguage.PL;
    case DriverAddLanguageType.RU:
      return DriverAddParamsLanguage.RU;
    case DriverAddLanguageType.SK:
      return DriverAddParamsLanguage.SK;
    case DriverAddLanguageType.UA:
      return DriverAddParamsLanguage.UA;
    case DriverAddLanguageType.EN:
      return DriverAddParamsLanguage.EN;
    case DriverAddLanguageType.ES:
      return DriverAddParamsLanguage.ES;
    case DriverAddLanguageType.FR:
      return DriverAddParamsLanguage.FR;
  }
};

const driverAddParamsLanguagesType = (
  types: DriverAddLanguageType[]
): DriverAddParamsLanguage[] => {
  return types.map(driverAddParamsLanguageType);
};

const createDriverAddParamsAddress = (
  userDataAddress: DriverAddAddress
): DriverAddParamsAddress => {
  const paramsAddress: DriverAddParamsAddress = {
    apartment: userDataAddress.apartmentNumber.trim(),
    country: userDataAddress.country.trim(),
    description: !!userDataAddress.description
      ? userDataAddress.description.trim()
      : null,
    houseNo: userDataAddress.houseNumber.trim(),
    lat: userDataAddress.latitude,
    lon: userDataAddress.longitude,
    street: userDataAddress.street.trim(),
    town: userDataAddress.town.trim(),
    type: driverAddParamsType(userDataAddress.type),
    zipCode: userDataAddress.zipCode.trim(),
  };

  return paramsAddress;
};

const createDriverAddParamsAddresses = (
  userDataAddresses: DriverAddAddress[]
): DriverAddParamsAddress[] => {
  return userDataAddresses.map(createDriverAddParamsAddress);
};

const createDriverAddParamsTaxiDriverAssociationForEmployment = (
  formUserFormData: TaxiDriverAddUserFormData,
  taxiCorporationUuid: string
): DriverAddParamsTaxiDriverAssociationForEmployment => {
  return {
    contractType: driverAddParamsContractType(
      formUserFormData.formOfEmployment
    )!,
    taxiCorporationId: taxiCorporationUuid,
    displayName: `${formUserFormData.firstName} ${formUserFormData.lastName}`,
    fleetPartnerId: formUserFormData.fleetPartner?.uuid ?? null,
  };
};

const createDriverAddParamsTaxiDriverAssociationForB2B = (
  formUserFormData: TaxiDriverAddUserFormData,
  formCompanyData: DriverAddCompanyFormData,
  taxiCorporationUuid: string
): DriverAddParamsTaxiDriverAssociationForB2B => {
  return {
    apartment: formCompanyData.apartmentNumber.trim(),
    companyName: formCompanyData.name.trim(),
    contractType: driverAddParamsContractType(
      formUserFormData.formOfEmployment
    )!,
    fleetPartnerId: formUserFormData.fleetPartner?.uuid ?? null,
    country: formCompanyData.country.trim(),
    description: formCompanyData.description.trim(),
    houseNo: formCompanyData.houseNumber.trim(),
    regon: formCompanyData.companyId.trim() || null,
    taxId: formCompanyData.taxNumber.trim(),
    zipCode: formCompanyData.zipCode.trim(),
    town: formCompanyData.town.trim(),
    krsNo: formCompanyData.nationalCourtRegisterNumber.trim() || null,
    street: formCompanyData.street.trim(),
    taxiCorporationId: taxiCorporationUuid,
    displayName: `${formUserFormData.firstName} ${formUserFormData.lastName}`,
  };
};

const createDriverAddParamsTaxiDriverAssociation = (
  formUserFormData: TaxiDriverAddUserFormData,
  formCompanyData: DriverAddCompanyFormData,
  taxiCorporationUuid: string
): DriverAddParamsTaxiDriverAssociation => {
  if (formUserFormData.formOfEmployment === DriverAddFormOfEmploymentType.B2B) {
    return createDriverAddParamsTaxiDriverAssociationForB2B(
      formUserFormData,
      formCompanyData,
      taxiCorporationUuid
    );
  }

  return createDriverAddParamsTaxiDriverAssociationForEmployment(
    formUserFormData,
    taxiCorporationUuid
  );
};

const createSubmitFormParams = (
  formAccountFormData: DriverAddAccountFormData,
  formUserFormData: TaxiDriverAddUserFormData,
  formVehicleFormData: DriverAddVehicleFormData,
  formCompanyData: DriverAddCompanyFormData,
  taxiCorporationUuid: string
): DriverAddParams => {
  return {
    email: formAccountFormData.email.trim(),
    username: formAccountFormData.username.trim(),
    password: formAccountFormData.password.trim(),
    firstName: formUserFormData.firstName.trim(),
    lastName: formUserFormData.lastName.trim(),
    mobilePrimaryPrefix: formUserFormData.mobile.dialingCode!.trim(),
    mobilePrimary: formUserFormData.mobile.number!.trim(),
    mobileSecondaryPrefix:
      formUserFormData.alternativeMobile.dialingCode.trim() || null,
    mobileSecondary: formUserFormData.alternativeMobile.number.trim() || null,
    driver: {
      birthDate: dateService.format(formUserFormData.birthDate!, "yyyy-mm-dd"),
      birthPlace: formUserFormData.birthPlace.trim(),
      carMake: formVehicleFormData.make.trim(),
      carModel: formVehicleFormData.model.trim(),
      carOwnership: driverAddParamsCarOwnershipType(
        formVehicleFormData.ownership!
      ),
      carProdYear: formVehicleFormData.productionYear!,
      carRegNo: formVehicleFormData.registrationNumber.trim(),
      carSeatsNo: formVehicleFormData.numberOfSeats!,
      citizenship: driverAddParamsCitizenshipType(
        formUserFormData.citizenship!
      ),
      experience: formUserFormData.yearsOfExperience!,
      idNumber: formUserFormData.personalIdNumber.trim(),
      languages: driverAddParamsLanguagesType(
        formUserFormData.languages.map((lang) => lang)
      ),
      taxiDriverAssociations: [
        createDriverAddParamsTaxiDriverAssociation(
          formUserFormData,
          formCompanyData,
          taxiCorporationUuid
        ),
      ],
      addresses: createDriverAddParamsAddresses(formUserFormData.addresses),
    },
  };
};

const taxiDriverAddParamsFactory = { createSubmitFormParams };

export default taxiDriverAddParamsFactory;
