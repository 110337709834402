import DataLoadError, {
  DataLoadDefaultErrors,
} from "../../../utils/data-load-error/data-load-error";

export enum RevertOrderCancelRequestErrorType {
  ORDER_CANCELLED = "ORDER_CANCELLED",
  ORDER_CANCEL_NOT_REQUESTED = "ORDER_CANCEL_NOT_REQUESTED",
  ORDER_FINISHED = "ORDER_FINISHED",
}

export type RevertOrderCancelledError = DataLoadError<
  RevertOrderCancelRequestErrorType.ORDER_CANCELLED,
  {}
>;

export type RevertOrderCancelNotRequestedError = DataLoadError<
  RevertOrderCancelRequestErrorType.ORDER_CANCEL_NOT_REQUESTED,
  {}
>;

export type RevertOrderFinishedError = DataLoadError<
  RevertOrderCancelRequestErrorType.ORDER_FINISHED,
  {}
>;

type RevertOrderCancelRequestError =
  | DataLoadDefaultErrors
  | RevertOrderCancelledError
  | RevertOrderCancelNotRequestedError
  | RevertOrderFinishedError;

export default RevertOrderCancelRequestError;
