import Joi from "joi";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import DriverEditUserPhoneNumber from "../types/driver-edit-user-phone-number";
import DriverEditAddressType from "../types/driver-edit-address-type";
import DriverEditAddress from "../types/driver-edit-address";
import DriverEditUserFleetPartner from "../types/driver-edit-user-fleet-partner";
import DriverEditFormOfEmploymentType from "../types/driver-edit-form-of-employment-type";
import userTranslationsHelper from "../../../../../../languages/user-translations.helper";
import DriverEditLanguageType from "../types/driver-edit-language-type";
import DriverEditCitizenshipType from "../types/driver-edit-citizenship-type";

const validateBirthDate = (value: Date | null) => {
  const currentDate = new Date();
  const pastDate = new Date().setDate(currentDate.getDate() - 1);

  const validationSchema = Joi.date()
    .min("1940-01-01")
    .max(pastDate)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateBirthPlace = (value: string) => {
  const validationSchema = Joi.string().min(2).max(50).trim().required();

  return formValidationService.validate(value, validationSchema);
};

const validatePersonalIdNumber = (value: string) => {
  const pattern = new RegExp(`^[0-9]*$`);
  const validationSchema = Joi.string()
    .min(2)
    .max(20)
    .trim()
    .regex(pattern)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateCitizenship = (value: DriverEditCitizenshipType | null) => {
  const translations = userTranslationsHelper.getDriverEditTranslations();

  const validationSchema = Joi.string()
    .valid(...Object.values(DriverEditCitizenshipType))
    .required()
    .messages({
      "any.only": translations.selectComponentNotificationError,
    });

  return formValidationService.validate(value, validationSchema);
};

const validateLanguages = (value: DriverEditLanguageType[]) => {
  const validationSchema = Joi.array()
    .min(1)
    .max(10)
    .items(Joi.string().valid(...Object.values(DriverEditLanguageType)))
    .required();

  const languages = value.map((item) => item);

  return formValidationService.validate(languages, validationSchema);
};

const validateYearsOfExperience = (value: number | null) => {
  const validationSchema = Joi.number().integer().min(0).max(50).required();

  return formValidationService.validate(value, validationSchema);
};

const validateFormOfEmployment = (
  value: DriverEditFormOfEmploymentType | null
) => {
  const translations = userTranslationsHelper.getDriverEditTranslations();

  const validationSchema = Joi.array()
    .valid(...Object.values(DriverEditFormOfEmploymentType))
    .required()
    .messages({
      "any.only": translations.selectComponentNotificationError,
    });

  return formValidationService.validate(value, validationSchema);
};

const validateFleetPartner = (value: DriverEditUserFleetPartner | null) => {
  const validationSchema = Joi.object().allow(null).required();

  return formValidationService.validate(value, validationSchema);
};

const validateAddresses = (value: DriverEditAddress[]) => {
  const lengthValidationSchema = Joi.array().required();

  const lengthValidationResult = formValidationService.validate(
    value,
    lengthValidationSchema
  );

  if (!lengthValidationResult.isValid) {
    return lengthValidationResult;
  }

  const mailingAddress = value.find(
    (item) => item.type === DriverEditAddressType.MAILING
  );
  const startingAddress = value.find(
    (item) => item.type === DriverEditAddressType.STARTING
  );

  if (!mailingAddress) {
    return formValidationService.bad(
      formValidationService.getValidationCustomMessages()
        .driverSignUpMailingAddressValidationError
    );
  }

  if (!startingAddress) {
    return formValidationService.bad(
      formValidationService.getValidationCustomMessages()
        .driverSignUpStartingAddressValidationError
    );
  }

  return lengthValidationResult;
};

const validateFirstName = (value: string) => {
  const pattern = /^[^\s].*[^\s]$/;

  const validationSchema = Joi.string()
    .min(2)
    .max(20)
    .regex(pattern)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateLastName = (value: string) => {
  const pattern = /^[^\s].*[^\s]$/;

  const validationSchema = Joi.string()
    .min(2)
    .max(20)
    .regex(pattern)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const mobileDialingCodeValidationSchema = Joi.string()
  .min(2)
  .max(4)
  .trim()
  .required();

const mobileNumberPattern = new RegExp(`^[0-9]*$`);
const mobileNumberValidationSchema = Joi.string()
  .min(9)
  .max(14)
  .regex(mobileNumberPattern)
  .required();

const requiredMobileValidationSchema = Joi.object({
  dialingCode: mobileDialingCodeValidationSchema,
  number: mobileNumberValidationSchema,
});

const validateMobile = (value: DriverEditUserPhoneNumber) => {
  const validationSchema = requiredMobileValidationSchema;

  return formValidationService.validate(value, validationSchema);
};

const validateAlternativeMobile = (value: DriverEditUserPhoneNumber | null) => {
  const emptyValidationSchema = Joi.object({
    dialingCode: Joi.string().equal(""),
    number: Joi.string().equal(""),
  });

  const validationSchema = Joi.alternatives().try(
    requiredMobileValidationSchema,
    emptyValidationSchema
  );

  return formValidationService.validate(value, validationSchema);
};

const driverEditUserDataFormValidationService = {
  validateBirthDate,
  validateBirthPlace,
  validatePersonalIdNumber,
  validateCitizenship,
  validateLanguages,
  validateYearsOfExperience,
  validateFormOfEmployment,
  validateFleetPartner,
  validateAddresses,
  validateFirstName,
  validateLastName,
  validateMobile,
  validateAlternativeMobile,
};

export default driverEditUserDataFormValidationService;
