import CargoTaxiContractDetails from "../../../../../common/services/cargo-taxi-contract/details/cargo-taxi-contract-details";
import TaxiCargoContractEditFormData from "./types/taxi-cargo-contract-edit-form-data";

const create = (
  data: CargoTaxiContractDetails
): TaxiCargoContractEditFormData => {
  return {
    cancelOrderByTaxiAllowed: data.cancelByTaxiAllowed,
    contactPassengerAfterMinutes: data.contactPassengerAfterMinutes,
    editOrderByTaxiAllowed: data.editByTaxiAllowed,
    isActive: data.isActive,
  };
};

const taxiCargoContractEditFormFactory = {
  create,
};

export default taxiCargoContractEditFormFactory;
