export enum DriverAddParamsAddressType {
  STARTING = "STARTING",
  MAILING = "MAILING",
}

export enum DriverAddParamsFormOfEmployment {
  B2B = "B2B",
  EMPLOYMENT = "EMPLOYMENT",
}

export enum DriverAddParamsCitizenship {
  PL = "PL",
  DE = "DE",
  SK = "SK",
  CZ = "CZ",
  UA = "UA",
  RU = "RU",
  BY = "BY",
  LT = "LT",
}

export enum DriverAddParamsLanguage {
  PL = "PL",
  EN = "EN",
  DE = "DE",
  FR = "FR",
  ES = "ES",
  SK = "SK",
  CZ = "CZ",
  UA = "UA",
  RU = "RU",
  BY = "BY",
  LT = "LT",
}

export enum DriverAddParamsCarOwnership {
  OWNER = "OWNER",
  BUSINESS = "BUSINESS",
}

export type DriverAddParamsAddress = {
  type: DriverAddParamsAddressType;
  lat: number;
  lon: number;
  country: string;
  town: string;
  zipCode: string;
  street: string;
  houseNo: string;
  apartment: string;
  description: string | null;
};

export type DriverAddParamsTaxiDriverAssociationForEmployment = {
  contractType: DriverAddParamsFormOfEmployment;
  displayName: string;
  taxiCorporationId: string;
  fleetPartnerId: string | null;
};

export type DriverAddParamsTaxiDriverAssociationForB2B = {
  contractType: DriverAddParamsFormOfEmployment;
  taxId: string;
  regon: string | null;
  krsNo: string | null;
  fleetPartnerId: string | null;
  companyName: string;
  displayName: string;
  country: string;
  town: string;
  zipCode: string;
  street: string;
  houseNo: string;
  apartment: string;
  description: string;
  taxiCorporationId: string;
};

export type DriverAddParamsTaxiDriverAssociation =
  | DriverAddParamsTaxiDriverAssociationForEmployment
  | DriverAddParamsTaxiDriverAssociationForB2B;

export type DriverAddParamsDriver = {
  taxiDriverAssociations: DriverAddParamsTaxiDriverAssociation[];
  addresses: DriverAddParamsAddress[];
  birthDate: string;
  birthPlace: string;
  idNumber: string;
  citizenship: DriverAddParamsCitizenship;
  languages: DriverAddParamsLanguage[];
  experience: number;
  carOwnership: DriverAddParamsCarOwnership;
  carMake: string;
  carModel: string;
  carRegNo: string;
  carProdYear: number;
  carSeatsNo: number;
};

type DriverAddParams = {
  firstName?: string;
  lastName?: string;
  mobilePrimaryPrefix?: string;
  mobilePrimary?: string;
  mobileSecondaryPrefix?: string | null;
  mobileSecondary?: string | null;
  email?: string;
  username?: string;
  password?: string;
  driver: DriverAddParamsDriver;
};

export default DriverAddParams;
