import { FC } from "react";
import TaxiListingItem from "../common/types/taxi-listing-item";
import useTaxiListingUserPermissions from "../common/user-permissions/use-taxi-listing-user-permissions";
import TaxiListingTableColumn from "../common/types/taxi-listing-table-column";
import taxiListingTableHelper from "./taxi-listing-table.helper";
import TaxiListingTableRow from "../common/types/taxi-listing-table-row";
import taxiTranslationsHelper from "../../../../languages/taxi-translations.helper";
import TableLinkButtonComponent from "../../../../common/components/table/button/link/table-link-button.component";
import { faContactCard, faHandshake } from "@fortawesome/free-solid-svg-icons";
import taxiRoutesHelper from "../../common/routes/taxi-routes.helper";
import TableComponent from "../../../../common/components/table/table.component";

type TaxiListingTableProps = {
  isError: boolean;
  isLoading: boolean;
  listingItems: TaxiListingItem[];
};

const TaxiListingTableComponent: FC<TaxiListingTableProps> = (props) => {
  const userPermissions = useTaxiListingUserPermissions();

  const columns: TaxiListingTableColumn[] = taxiListingTableHelper.getColumns();

  const createTableRow = (
    listingItem: TaxiListingItem
  ): TaxiListingTableRow => {
    const translations =
      taxiTranslationsHelper.getTaxiListingTranslations().table;

    return {
      id: listingItem.uuid,
      idForTesting: listingItem.uuid,
      value: {
        companyName: (
          <div title={listingItem.companyName}>{listingItem.companyName}</div>
        ),
        companyDisplayName: (
          <div title={listingItem.companyDisplayName}>
            {listingItem.companyDisplayName}
          </div>
        ),
        taxId: <div title={listingItem.taxNumber}>{listingItem.taxNumber}</div>,
        companyId: (
          <div title={listingItem.companyId ?? ""}>{listingItem.companyId}</div>
        ),
        nationalCourtRegister: (
          <div title={listingItem.nationalCourtRegister ?? ""}>
            {listingItem.nationalCourtRegister}
          </div>
        ),
        address: (
          <div title={listingItem.address ?? ""}>{listingItem.address}</div>
        ),
        notes: <div title={listingItem.notes ?? ""}>{listingItem.notes}</div>,
        actions: (
          <div className="d-flex taxi_listing_table_actions">
            {(userPermissions.hasAccessToTaxiContractListing ||
              (userPermissions.hasAccessToTaxiContractListingForRailyOfficer &&
                listingItem.areTaxiTaxiContractsVisibleForRailyOfficer)) && (
              <TableLinkButtonComponent
                icon={faContactCard}
                to={taxiRoutesHelper.getTaxiContractListingRoute({
                  taxiCorporationUuid: listingItem.uuid,
                })}
                title={translations.actions.taxiContractListingLinkButtonTitle}
                idForTesting="taxi-listing-table-taxi-contract-button"
              />
            )}
            {userPermissions.hasAccessToCargoContractListing && (
              <TableLinkButtonComponent
                icon={faContactCard}
                to={taxiRoutesHelper.getCargoContractListingRoute({
                  taxiCorporationUuid: listingItem.uuid,
                })}
                title={translations.actions.cargoContractListingLinkButtonTitle}
                idForTesting="taxi-listing-table-cargo-contract-button"
              />
            )}
            {userPermissions.hasAccessToTaxiFleetPartnerListing && (
              <TableLinkButtonComponent
                icon={faHandshake}
                to={taxiRoutesHelper.getFleetPartnerListingRoute({
                  taxiCorporationUuid: listingItem.uuid,
                })}
                title={
                  translations.actions.taxiFleetPartnerListingLinkButtonTitle
                }
                idForTesting="taxi-listing-table-taxi-fleet-partner-button"
              />
            )}
          </div>
        ),
      },
    };
  };

  const rows: TaxiListingTableRow[] = props.listingItems.map((listingItem) =>
    createTableRow(listingItem)
  );

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default TaxiListingTableComponent;
