import { FC, useCallback, useEffect, useState } from "react";
import debounce from "lodash/debounce";
import passengerAddAddressFactory from "./common/factory/passenger-address-add.factory";
import passengerAddressAddFormValidationService from "./passenger-address-add-form-validation.service";
import PassengerFormDataAddressProposition from "./common/types/passenger-form-data-address-proposition";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import PassengerFormDataAddressPropositionSelectOption from "./common/types/passenger-form-data-address-select-option";
import PassengerAddress from "../../../types/passenger-address";
import formValidationService from "../../../../../../../common/utils/validation/form-validation.service";
import MapMarker from "../../../../../../../common/components/map/types/map-marker";
import userTranslationsHelper from "../../../../../../../languages/user-translations.helper";
import SearchAddressResponse from "../../../../../../../common/utils/search-address/search-address.response";
import searchAddressApiService from "../../../../../../../common/utils/search-address/search-address.service";
import MapCoordinate from "../../../../../../../common/components/map/types/map-coordinate";
import ButtonComponent from "../../../../../../../common/components/button/button.component";
import ModalComponent from "../../../../../../../common/components/modal/modal.component";
import Row from "../../../../../../../common/components/grid/row";
import Column from "../../../../../../../common/components/grid/column";
import FormFieldComponent from "../../../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../../../common/components/form/input/input.component";
import MapComponent from "../../../../../../../common/components/map/map.component";
import SingleSelectComponent from "../../../../../../../common/components/form/select/single-select/single-select.component";

type PassengerAddressAddProps = {
  isModalOpen: boolean;
  onCloseClick: () => void;
  onAddressAddSuccess: (newAddress: PassengerAddress) => void;
};

const PassengerAddressAddComponent: FC<PassengerAddressAddProps> = (props) => {
  const [mapRefreshFlag, setMapRefreshFlag] = useState(false);
  const [refreshMapFitFlag, setRefreshMapFitFlag] = useState(false);
  const [addressSearchQuery, setAddressSearchQuery] = useState("");
  const [isAddressFetching, setIsAddressFetching] = useState(false);
  const [addressSelectOptions, setAddressSelectOptions] = useState<
    PassengerFormDataAddressPropositionSelectOption[]
  >([]);
  const [selectedAddressOption, setSelectedAddressOption] =
    useState<PassengerFormDataAddressPropositionSelectOption | null>(null);
  const [street, setStreet] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [apartmentNumber, setApartmentNumber] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [town, setTown] = useState("");
  const [description, setDescription] = useState("");
  const [validationResults, setValidationResults] = useState({
    addressType: formValidationService.defaultValidationResult,
    street: formValidationService.defaultValidationResult,
    houseNumber: formValidationService.defaultValidationResult,
    apartmentNumber: formValidationService.defaultValidationResult,
    zipCode: formValidationService.defaultValidationResult,
    country: formValidationService.defaultValidationResult,
    town: formValidationService.defaultValidationResult,
    description: formValidationService.defaultValidationResult,
    map: formValidationService.defaultValidationResult,
  });

  const [mapMarker, setMapMarker] = useState<MapMarker | null>(null);
  const [selectedCandidatureMapMarker, setSelectedCandidatureMapMarker] =
    useState<MapMarker | null>(null);
  const [selectedAddressCandidature, setSelectedAddressCandidature] =
    useState<PassengerFormDataAddressProposition | null>(null);

  const refreshMapFit = () => {
    setRefreshMapFitFlag((curr) => !curr);
  };

  const isAddressDetailsFormFieldEnabled = !!selectedAddressOption || mapMarker;

  const translations =
    userTranslationsHelper.getPassengerAddressTranslations().addNewAddressForm;

  const fetchResolvedAddress = (
    addressSearchQuery: string
  ): Promise<SearchAddressResponse> => {
    return searchAddressApiService.searchByQuery(addressSearchQuery);
  };

  const getAddressSelectOptions = (addressSearchQuery: string) => {
    setIsAddressFetching(true);

    fetchResolvedAddress(addressSearchQuery)
      .then((response) => {
        const selectOptions =
          passengerAddAddressFactory.createAddressSelectOptions(response);

        setAddressSelectOptions(selectOptions);
      })
      .finally(() => {
        setIsAddressFetching(false);
      });
  };

  const getDebounceAddressSelectOptions = useCallback(
    debounce((addressSearchQuery: string) => {
      getAddressSelectOptions(addressSearchQuery);
    }, 300),
    []
  );

  const clearForm = () => {
    setAddressSelectOptions([]);
    setSelectedAddressOption(null);
    setAddressSearchQuery("");
    setStreet("");
    setHouseNumber("");
    setApartmentNumber("");
    setZipCode("");
    setCountry("");
    setTown("");
    setDescription("");
    setMapMarker(null);
    setValidationResults({
      addressType: formValidationService.defaultValidationResult,
      street: formValidationService.defaultValidationResult,
      houseNumber: formValidationService.defaultValidationResult,
      apartmentNumber: formValidationService.defaultValidationResult,
      zipCode: formValidationService.defaultValidationResult,
      country: formValidationService.defaultValidationResult,
      town: formValidationService.defaultValidationResult,
      description: formValidationService.defaultValidationResult,
      map: formValidationService.defaultValidationResult,
    });
    setMapRefreshFlag((curr) => !curr);
    setSelectedCandidatureMapMarker(null);
    setSelectedAddressCandidature(null);
  };

  useEffect(() => {
    if (props.isModalOpen) {
      return;
    }

    clearForm();
  }, [props.isModalOpen]);

  useEffect(() => {
    if (!selectedAddressOption) {
      clearForm();
      return;
    }

    const streetValue = selectedAddressOption.value.street ?? "";
    const houseNumberValue = selectedAddressOption.value.houseNumber ?? "";
    const zipCodeValue = selectedAddressOption.value.zipCode ?? "";
    const countryValue = selectedAddressOption.value.country;
    const townValue = selectedAddressOption.value.town ?? "";

    setStreet(streetValue);
    setHouseNumber(houseNumberValue);
    setApartmentNumber("");
    setZipCode(zipCodeValue);
    setCountry(countryValue);
    setTown(townValue);
    setMapMarker({
      coordinate: {
        latitude: selectedAddressOption.value.latitude,
        longitude: selectedAddressOption.value.longitude,
      },
      isDraggable: true,
    });

    validateStreet(streetValue);
    validateHouseNumber(houseNumberValue);
    validateZipCode(zipCodeValue);
    validateCountry(countryValue);
    validateTown(townValue);

    refreshMapFit();
  }, [selectedAddressOption]);

  useEffect(() => {
    if (!addressSearchQuery) {
      return;
    }

    getDebounceAddressSelectOptions(addressSearchQuery);
  }, [addressSearchQuery]);

  const validateStreet = (_street: string = street) => {
    const validationResult =
      passengerAddressAddFormValidationService.validateStreet(_street);

    setValidationResults((curr) => ({
      ...curr,
      street: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateHouseNumber = (_houseNumber: string = houseNumber) => {
    const validationResult =
      passengerAddressAddFormValidationService.validateHouseNumber(
        _houseNumber
      );

    setValidationResults((curr) => ({
      ...curr,
      houseNumber: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateApartmentNumber = () => {
    const validationResult =
      passengerAddressAddFormValidationService.validateApartmentNumber(
        apartmentNumber
      );

    setValidationResults((curr) => ({
      ...curr,
      apartmentNumber: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateZipCode = (_zipCode: string = zipCode) => {
    const validationResult =
      passengerAddressAddFormValidationService.validateZipCode(_zipCode);

    setValidationResults((curr) => ({
      ...curr,
      zipCode: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCountry = (_country: string = country) => {
    const validationResult =
      passengerAddressAddFormValidationService.validateCountry(_country);

    setValidationResults((curr) => ({
      ...curr,
      country: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateTown = (_town: string = town) => {
    const validationResult =
      passengerAddressAddFormValidationService.validateTown(_town);

    setValidationResults((curr) => ({
      ...curr,
      town: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateDescription = () => {
    const validationResult =
      passengerAddressAddFormValidationService.validateDescription(description);

    setValidationResults((curr) => ({
      ...curr,
      description: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateForm = () => {
    const isStreetValid = validateStreet();
    const isHouseNumberValid = validateHouseNumber();
    const isApartmentNumberValid = validateApartmentNumber();
    const isTownValid = validateTown();
    const isZipCodeValid = validateZipCode();
    const isCountryValid = validateCountry();
    const isDescriptionValid = validateDescription();

    return (
      isStreetValid &&
      isHouseNumberValid &&
      isApartmentNumberValid &&
      isTownValid &&
      isZipCodeValid &&
      isCountryValid &&
      isDescriptionValid
    );
  };

  const isSubmitButtonEnabled = true;

  const addNewAddress = () => {
    if (!mapMarker) {
      return;
    }

    const newAddress: PassengerAddress = {
      apartmentNumber,
      country,
      description,
      houseNumber,
      latitude: mapMarker.coordinate.latitude,
      longitude: mapMarker.coordinate.longitude,
      street,
      town,
      zipCode,
    };

    props.onAddressAddSuccess(newAddress);
  };

  const onSubmitButtonClick = () => {
    const isFormValid = validateForm();

    if (!isFormValid) {
      return;
    }

    props.onCloseClick();
    addNewAddress();
  };

  const updateSelectedAddressCandidature = () => {
    if (!selectedCandidatureMapMarker) {
      setSelectedAddressCandidature(null);
      return;
    }

    const searchQuery = `${selectedCandidatureMapMarker.coordinate.latitude}, ${selectedCandidatureMapMarker.coordinate.longitude}`;
    fetchResolvedAddress(searchQuery).then((response) => {
      if (!response.length) {
        console.error(`No location found`);
        return;
      }

      const foundLocation = response[0];

      const address = passengerAddAddressFactory.createAddress(foundLocation);

      setSelectedAddressCandidature(address);
    });
  };

  useEffect(() => {
    updateSelectedAddressCandidature();
  }, [selectedCandidatureMapMarker]);

  const handleLongMapClick = (coordinate: MapCoordinate) => {
    setSelectedCandidatureMapMarker({
      coordinate,
      isDraggable: true,
    });
  };

  const acceptAddressCandidature = () => {
    if (!selectedAddressCandidature || !selectedCandidatureMapMarker) {
      return;
    }

    const streetValue = selectedAddressCandidature.street ?? "";
    const houseNumberValue = selectedAddressCandidature.houseNumber ?? "";
    const zipCodeValue = selectedAddressCandidature.zipCode ?? "";
    const countryValue = selectedAddressCandidature.country;
    const townValue = selectedAddressCandidature.town ?? "";

    setStreet(streetValue);
    setHouseNumber(houseNumberValue);
    setApartmentNumber("");
    setZipCode(zipCodeValue);
    setCountry(countryValue);
    setTown(townValue);

    validateStreet(streetValue);
    validateHouseNumber(houseNumberValue);
    validateZipCode(zipCodeValue);
    validateCountry(countryValue);
    validateTown(townValue);

    setSelectedAddressCandidature(null);
    setMapMarker({ ...selectedCandidatureMapMarker });
    setSelectedCandidatureMapMarker(null);
    refreshMapFit();
  };

  const isMessageBoxVisible = !!selectedAddressCandidature;

  const checkIsFormModified = (): boolean => {
    return !!(
      mapMarker ||
      selectedCandidatureMapMarker ||
      addressSearchQuery !== "" ||
      street !== "" ||
      houseNumber !== "" ||
      apartmentNumber !== "" ||
      zipCode !== "" ||
      country !== "" ||
      town !== "" ||
      description !== ""
    );
  };

  const onMarkerCoordinateChange = (newMarker: MapMarker) => {
    setMapMarker(newMarker);
  };

  const onTemporaryMarkerCoordinateChange = (newMarker: MapMarker) => {
    setSelectedCandidatureMapMarker({
      ...newMarker,
    });
  };

  const isFormModified = checkIsFormModified();

  const mapMessageBox = selectedAddressCandidature ? (
    <div style={{ display: `flex`, maxHeight: 40 }}>
      <div
        style={{
          fontSize: 12,
          textOverflow: "ellipsis",
          overflow: "hidden",
          display: `-webkit-box`,
          WebkitLineClamp: 2,
          WebkitBoxOrient: `vertical`,
        }}
        title={selectedAddressCandidature.displayName}
      >
        {selectedAddressCandidature.displayName}
      </div>
      <div>
        <ButtonComponent onClick={acceptAddressCandidature} type="success">
          <FontAwesomeIcon icon={faCheck} />
        </ButtonComponent>
      </div>
    </div>
  ) : null;

  return (
    <ModalComponent
      header={{ title: translations.headingText }}
      classNames={{ root: "passenger_address_add" }}
      isOpen={props.isModalOpen}
      onClose={props.onCloseClick}
      actions={[
        <ButtonComponent
          onClick={onSubmitButtonClick}
          type="primary"
          isDisabled={!isSubmitButtonEnabled}
          title={translations.submitButtonTitle}
          idForTesting="user-data-add-new-address-submit-button"
        >
          {translations.submitButtonText}
        </ButtonComponent>,
      ]}
      closeConfirmation={{
        title: translations.closeConfirmation.headingText,
        messageText: translations.closeConfirmation.textMessage,
        shouldBeVisibleOnCloseClick: isFormModified,
      }}
    >
      <div style={{ fontSize: 14, padding: `0 0 20px 0` }}>
        {translations.tipText}
      </div>
      <Row>
        <Column xl={6}>
          <FormFieldComponent
            label={translations.searchLabel}
            classNames={{ root: "mt-0" }}
          >
            <SingleSelectComponent
              placeholder={translations.searchPlaceholder}
              options={addressSelectOptions}
              value={selectedAddressOption}
              isLoading={isAddressFetching}
              inputValue={addressSearchQuery}
              onInputChange={setAddressSearchQuery}
              isClearable
              onChange={(option) => setSelectedAddressOption(option)}
              isSearchable
              menuPlacement="bottom"
              filterFunction={() => true}
              idForTesting="user-data-add-new-address-search-select"
              menuPosition="fixed"
            />
          </FormFieldComponent>
          <Row>
            <Column xl={6}>
              <FormFieldComponent
                label={translations.streetLabel}
                isRequired
                errorMessage={validationResults.street.errorMessage}
              >
                <InputComponent
                  value={street}
                  onChange={setStreet}
                  placeholder={translations.streetPlaceholder}
                  isDisabled={!isAddressDetailsFormFieldEnabled}
                  hasError={!!validationResults.street.errorMessage}
                  onBlur={validateStreet}
                  idForTesting="user-data-add-new-address-street-input"
                />
              </FormFieldComponent>
            </Column>
            <Column xl={3}>
              <FormFieldComponent
                label={translations.houseNumberLabel}
                isRequired
                errorMessage={validationResults.houseNumber.errorMessage}
              >
                <InputComponent
                  value={houseNumber}
                  onChange={setHouseNumber}
                  placeholder={translations.houseNumberPlaceholder}
                  isDisabled={!isAddressDetailsFormFieldEnabled}
                  hasError={!!validationResults.houseNumber.errorMessage}
                  onBlur={validateHouseNumber}
                  idForTesting="user-data-add-new-address-house-number-input"
                />
              </FormFieldComponent>
            </Column>
            <Column xl={3}>
              <FormFieldComponent
                label={translations.apartmentNumberLabel}
                errorMessage={validationResults.apartmentNumber.errorMessage}
              >
                <InputComponent
                  value={apartmentNumber}
                  onChange={setApartmentNumber}
                  placeholder={translations.apartmentNumberPlaceholder}
                  isDisabled={!isAddressDetailsFormFieldEnabled}
                  hasError={!!validationResults.apartmentNumber.errorMessage}
                  onBlur={validateApartmentNumber}
                  idForTesting="user-data-add-new-address-apartment-number-input"
                />
              </FormFieldComponent>
            </Column>
          </Row>
          <Row>
            <Column xl={6}>
              <FormFieldComponent
                label={translations.townLabel}
                classNames={{ root: "mt-0" }}
                isRequired
                errorMessage={validationResults.town.errorMessage}
              >
                <InputComponent
                  value={town}
                  onChange={setTown}
                  placeholder={translations.townPlaceholder}
                  isDisabled={!isAddressDetailsFormFieldEnabled}
                  hasError={!!validationResults.town.errorMessage}
                  onBlur={validateTown}
                  idForTesting="user-data-add-new-address-town-input"
                />
              </FormFieldComponent>
            </Column>
            <Column xl={6}>
              <FormFieldComponent
                label={translations.zipCodeLabel}
                classNames={{ root: "mt-0" }}
                isRequired
                errorMessage={validationResults.zipCode.errorMessage}
              >
                <InputComponent
                  value={zipCode}
                  onChange={setZipCode}
                  placeholder={translations.zipCodePlaceholder}
                  isDisabled={!isAddressDetailsFormFieldEnabled}
                  hasError={!!validationResults.zipCode.errorMessage}
                  onBlur={validateZipCode}
                  idForTesting="user-data-add-new-address-zip-code-input"
                />
              </FormFieldComponent>
            </Column>
          </Row>
          <FormFieldComponent
            label={translations.countryLabel}
            classNames={{ root: "mt-0" }}
            isRequired
            errorMessage={validationResults.country.errorMessage}
          >
            <InputComponent
              value={country}
              onChange={setCountry}
              placeholder={translations.countryPlaceholder}
              isDisabled={!isAddressDetailsFormFieldEnabled}
              hasError={!!validationResults.country.errorMessage}
              onBlur={validateCountry}
              idForTesting="user-data-add-new-address-country-input"
            />
          </FormFieldComponent>
          <FormFieldComponent
            label={translations.descriptionLabel}
            classNames={{ root: "mt-0" }}
            errorMessage={validationResults.description.errorMessage}
          >
            <InputComponent
              value={description}
              onChange={setDescription}
              placeholder={translations.descriptionPlaceholder}
              isDisabled={!isAddressDetailsFormFieldEnabled}
              hasError={!!validationResults.description.errorMessage}
              onBlur={validateDescription}
              idForTesting="user-data-add-new-address-description-input"
            />
          </FormFieldComponent>
        </Column>
        <Column xl={6}>
          <FormFieldComponent
            label={translations.mapLabel}
            classNames={{
              root: "mt-0 passenger_address_add_map_form_field",
            }}
            errorMessage={validationResults.map.errorMessage}
          >
            <MapComponent
              key={`add-address-map-${mapRefreshFlag}`}
              markers={mapMarker ? [mapMarker] : []}
              onMarkerCoordinateChange={onMarkerCoordinateChange}
              temporaryMarkers={
                selectedCandidatureMapMarker
                  ? [selectedCandidatureMapMarker]
                  : []
              }
              onTemporaryMarkerCoordinateChange={
                onTemporaryMarkerCoordinateChange
              }
              onMapLongClick={handleLongMapClick}
              messageBox={mapMessageBox}
              isMessageBoxVisible={isMessageBoxVisible}
              autoFit
              refreshFitFlag={refreshMapFitFlag}
            />
          </FormFieldComponent>
        </Column>
      </Row>
    </ModalComponent>
  );
};

export default PassengerAddressAddComponent;
