import { DriverContractAddTaxiAssociationsResponseDataItem } from "../../../common/api/driver-contract-add-taxi-associations.response";
import DriverContractAddByRailyTaxiAssociationSelectOption from "../types/driver-contract-add-by-raily-taxi-association-select-option";

const createTaxiAssociationSelectOption = (
  responseTaxiAssociation: DriverContractAddTaxiAssociationsResponseDataItem
): DriverContractAddByRailyTaxiAssociationSelectOption => {
  return {
    label: responseTaxiAssociation.taxi_corporation.display_name,
    value: {
      uuid: responseTaxiAssociation.id,
    },
  };
};

const createTaxiAssociationSelectOptions = (
  responseTaxiAssociations: DriverContractAddTaxiAssociationsResponseDataItem[]
): DriverContractAddByRailyTaxiAssociationSelectOption[] => {
  return responseTaxiAssociations.map(createTaxiAssociationSelectOption);
};

const driverContractAddByRailyFactory = {
  createTaxiAssociationSelectOptions,
};

export default driverContractAddByRailyFactory;
