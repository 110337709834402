import appConfig from "../../../../../../app.config";
import searchRoadRoutingService from "../../../../../../common/utils/search-road-route/search-road-routing.service";
import urlService from "../../../../../../common/utils/url/url.service";
import authService from "../../../../../auth/common/auth.service";
import OrderDetailsManageDriverAssignmentDriversRequest from "./order-details-manage-driver-assignment-drivers.request";
import OrderDetailsManageDriverAssignmentDriversResponse from "./order-details-manage-driver-assignment-drivers.response";
import OrderDetailsManageDriverAssignmentPlanEntryRequest from "./order-details-manage-driver-assignment-plan-entry.request";
import OrderDetailsManageDriverAssignmentPlanEntryRespose from "./order-details-manage-driver-assignment-plan-entry.response";
import OrderDetailsManageDriverAssignmentRouteRequest from "./order-details-manage-driver-assignment-route.request";
import OrderDetailsManageDriverAssignmentRouteResponse from "./order-details-manage-driver-assignment-route.response";

const fetchDrivers = async (
  request: OrderDetailsManageDriverAssignmentDriversRequest,
  transportingOrderUuid: string
): Promise<OrderDetailsManageDriverAssignmentDriversResponse> => {
  const path = `${appConfig.baseApiUrl}/orders/transporting-orders/${transportingOrderUuid}/available-drivers`;

  const url = urlService.buildWithoutEmptyParams(path, request);
  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const fetchPlanEntry = async (
  request: OrderDetailsManageDriverAssignmentPlanEntryRequest
): Promise<OrderDetailsManageDriverAssignmentPlanEntryRespose> => {
  const url = `${appConfig.baseApiUrl}/plan-entries/${request.planEntryUuid}`;
  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const fetchRoute = async (
  request: OrderDetailsManageDriverAssignmentRouteRequest
): Promise<OrderDetailsManageDriverAssignmentRouteResponse> => {
  return searchRoadRoutingService.search(request);
};

const orderDetailsManageDriverAssignmentApiService = {
  fetchDrivers,
  fetchRoute,
  fetchPlanEntry,
};

export default orderDetailsManageDriverAssignmentApiService;
