import { FC, useEffect, useState } from "react";
import orderRouteEditSummaryHelper from "./common/order-route-edit-summary.helper";
import OrderRouteEditSummaryRouteComponent from "./route/order-route-edit-summary-route.component";
import orderRouteEditSummaryFactory from "./common/order-route-edit-summary.factory";
import orderRouteEditSummaryMapFactory from "./route/order-route-edit-summary-map-markers.factory";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import CardComponent from "../../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../../common/components/form/field/form-field.component";
import MapComponent from "../../../../../../common/components/map/map.component";
import MapMarker from "../../../../../../common/components/map/types/map-marker";
import MapRoute from "../../../../../../common/components/map/types/map-route";
import SearchRoadRoutingRequest from "../../../../../../common/utils/search-road-route/search-road-routing.request";
import SearchRoadRoutingResponse, {
  SearchRoadRoutingResponseRouteLeg,
} from "../../../../../../common/utils/search-road-route/search-road-routing.response";
import orderTranslationsHelper from "../../../../../../languages/order-translations.helper";
import OrderRouteEditSolvedRidesResponse, {
  OrderRouteEditSolvedRidesResponseDataCargoOrderSolvedRide,
  OrderRouteEditSolvedRidesResponseRideSolvedWaypoint,
} from "../../api/order-route-edit-solved-rides.response";
import OrderRouteEditPassengerListItem from "../../types/order-route-edit-passenger-list-item";
import OrderRouteEditSolvedRidesRequest from "../../api/order-route-edit-solved-rides.request";
import orderRouteEditFactory from "../../factory/order-route-edit.factory";
import orderRouteEditApiService from "../../api/order-route-edit-api.service";
import OrderRouteEditRouteItem from "../../types/order-route-edit-route-waypoint";
import orderRouteEditValidationService from "../../validation/order-route-edit-validation.service";
import OrderRouteEditPassenger from "../../types/order-route-edit-passenger";
import OrderRouteEditTaxiContract from "../../types/order-route-edit-taxi-contract";
import orderRoutesHelper from "../../../../common/routes/order-routes.helper";
import { useNavigate } from "react-router-dom";
import OrderRouteEditCancelEditConfirmationComponent from "../../../cancel-edit-confirmation/order-route-edit-cancel-edit-confirmation.component";

type OrderRouteEditSummaryProps = {
  passengerList: OrderRouteEditPassengerListItem[];
  routes: OrderRouteEditRouteItem[];
  selectedPassengerUuid: OrderRouteEditPassenger["uuid"] | null;
  taxiContract: OrderRouteEditTaxiContract;
  restoreForm: () => void;
  validateForm: () => boolean;
  cargoCompanyOrderId: number | undefined;
  orderId: string | undefined;
  onSubmitButtonClick: () => void;
  isEditOrderFetching: boolean;
  excludeHighway: boolean;
};

const OrderRouteEditSummaryComponent: FC<OrderRouteEditSummaryProps> = (
  props
) => {
  const translations = orderTranslationsHelper.getEditTranslations().summary;

  const [mapRoutes, setMapRoutes] = useState<MapRoute[]>([]);

  const [responseRouteLegs, setResponseRouteLegs] = useState<
    SearchRoadRoutingResponseRouteLeg[]
  >([]);

  const [solvedWaypoints, setSolvedWaypoints] = useState<
    OrderRouteEditSolvedRidesResponseRideSolvedWaypoint[]
  >([]);

  const [
    isOrderCancelEditConfirmationModalOpen,
    setIsOrderCancelEditConfirmationModalOpen,
  ] = useState(false);

  const selectedPassengers = props.passengerList.map(
    (listItem) => listItem.passenger
  );

  const navigate = useNavigate();

  const onSolvedRidesFetchSuccess = (
    responseCargoOrderRide: OrderRouteEditSolvedRidesResponseDataCargoOrderSolvedRide
  ) => {
    setSolvedWaypoints(responseCargoOrderRide.seq);
  };

  const handleSolvedRidesResponse = (
    response: OrderRouteEditSolvedRidesResponse
  ) => {
    if (response.status === 200) {
      onSolvedRidesFetchSuccess(response.data);
    }
  };

  const fetchSolvedRides = () => {
    const request: OrderRouteEditSolvedRidesRequest =
      orderRouteEditFactory.createSolvedRidesRequest(
        props.routes,
        props.taxiContract
      );

    orderRouteEditApiService
      .fetchSolvedRides(request)
      .then(handleSolvedRidesResponse);
  };

  const waypoints = orderRouteEditSummaryFactory.createSummaryRouteWaypoints(
    props.routes,
    responseRouteLegs,
    solvedWaypoints
  );

  const validateRoutesForSolvedOrderFetch = () => {
    const validationErrors =
      orderRouteEditValidationService.validateRoutesForSolvedOrderFetch(
        props.passengerList,
        props.routes
      );

    return !validationErrors.length;
  };

  useEffect(() => {
    if (!props.routes.length || !validateRoutesForSolvedOrderFetch()) {
      setSolvedWaypoints([]);
      return;
    }

    fetchSolvedRides();
  }, [JSON.stringify(props.routes)]);

  const mapMarkers: MapMarker[] =
    orderRouteEditSummaryMapFactory.createMapMarkers(waypoints);

  const fetchRoute = () => {
    const waypointsCouldBeConnected =
      orderRouteEditSummaryHelper.getWaypointGroupsCouldBeConnected(waypoints);

    const coordinatesOfWaypointsCouldBeConnected =
      orderRouteEditSummaryHelper.getCoordinatesOfWaypointsCouldBeConnected(
        waypointsCouldBeConnected
      );

    const fetchPromises: Promise<SearchRoadRoutingResponse>[] = [];

    coordinatesOfWaypointsCouldBeConnected.forEach((coordinates) => {
      const request: SearchRoadRoutingRequest = {
        waypointCoordinates: coordinates,
        excludeHighway: props.excludeHighway,
      };

      const fetch = orderRouteEditApiService.fetchRoute(request);

      fetchPromises.push(fetch);
    });

    Promise.all(fetchPromises).then((responses) => {
      const mapRouteWaypointGroups: MapRoute["waypoints"][] = [];

      responses.forEach((response) => {
        const mapRoute = response.routes[0]
          ? orderRouteEditSummaryMapFactory.createMapRoute(
              response.routes[0].geometry.coordinates
            )
          : null;

        if (mapRoute?.waypoints) {
          mapRouteWaypointGroups.push(mapRoute.waypoints);
        }
      });

      const newMapRoutes: MapRoute[] = mapRouteWaypointGroups.map(
        (routeWaypointGroup) => ({
          waypoints: routeWaypointGroup,
        })
      );

      setMapRoutes(newMapRoutes);

      const responseRouteLegs = responses
        .map((response) => response.routes.map((route) => route.legs).flat())
        .flat();

      setResponseRouteLegs(responseRouteLegs);
    });
  };

  useEffect(() => {
    if (!waypoints.length) {
      return;
    }

    fetchRoute();
  }, [JSON.stringify(waypoints)]);

  const navigateToActiveOrderListing = () => {
    navigate(
      orderRoutesHelper.getListingOfActiveOrdersRoute({
        defaultSelectedOrderUuid: props.orderId,
      })
    );
  };

  const confirmOrderCancelEditModal = () => {
    navigateToActiveOrderListing();
  };

  const closeCancelEditModal = () => {
    setIsOrderCancelEditConfirmationModalOpen(false);
  };

  const openOrderCancelEditModal = () => {
    setIsOrderCancelEditConfirmationModalOpen(true);
  };

  const onOrderCancelEditButtonClick = () => {
    openOrderCancelEditModal();
  };

  return (
    <>
      <CardComponent
        header={{ title: translations.headingText }}
        classNames={{ root: "order_edit_summary" }}
      >
        <div className="order_edit_summary__map_wrapper">
          <MapComponent
            markers={mapMarkers}
            autoFit
            autoFitOnUpdate
            routes={mapRoutes}
          />
        </div>
        <FormFieldComponent label={translations.passengerListLabel}>
          {orderRouteEditSummaryHelper.getPassengersListLabel(
            selectedPassengers
          )}
        </FormFieldComponent>
        <FormFieldComponent label={translations.routeLabel}>
          <OrderRouteEditSummaryRouteComponent
            waypoints={waypoints}
            selectedPassengerUuid={props.selectedPassengerUuid}
          />
        </FormFieldComponent>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridColumnGap: "1em",
          }}
        >
          <ButtonComponent
            onClick={props.onSubmitButtonClick}
            type="primary"
            classNames={{ root: "w-100" }}
            isLoading={props.isEditOrderFetching}
          >
            {translations.submitButtonText}
          </ButtonComponent>
          <ButtonComponent
            onClick={onOrderCancelEditButtonClick}
            type="danger"
            classNames={{ root: "w-100" }}
          >
            {translations.cancelEditButtonText}
          </ButtonComponent>
        </div>
      </CardComponent>
      <OrderRouteEditCancelEditConfirmationComponent
        onClose={closeCancelEditModal}
        onConfirm={confirmOrderCancelEditModal}
        cargoCompanyOrderId={props.cargoCompanyOrderId}
        isOpen={isOrderCancelEditConfirmationModalOpen}
      />
    </>
  );
};

export default OrderRouteEditSummaryComponent;
