import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../../../../context/app.context";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import useForm from "../../../../../common/components/form/use-form";
import useAbort from "../../../../../common/hooks/use-abort";
import notificationService from "../../../../../common/utils/notification/notification.service";
import cargoRoutesHelper from "../../../common/routes/cargo-routes.helper";
import cargoBreadcrumbsHelper from "../../../common/breadcrumbs/cargo-breadcrumbs.helper";
import Row from "../../../../../common/components/grid/row";
import Column from "../../../../../common/components/grid/column";
import CardComponent from "../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../common/components/form/input/input.component";
import ButtonComponent from "../../../../../common/components/button/button.component";
import cargoTranslationsHelper from "../../../../../languages/cargo-translations.helper";
import cargoDispatchFormHelper from "../common/cargo-dispatch-form.helper";
import CargoDispatchFormData from "../common/types/cargo-dispatch-form-data";
import cargoCompanyDispatchService from "../../../../../common/services/cargo-company/dispatch/cargo-company-dispatch.service";
import CargoDispatchUpdateRouteParams from "../../../common/routes/types/cargo-dispatch-update-route-params";
import useCargoCompanyDispatchDetails from "../../../../../common/services/cargo-company/dispatch/details/use-cargo-company-dispatch-details";
import useCargoCompanyDetails from "../../../../../common/services/cargo-company/cargo-company/details/use-cargo-company-details";
import CargoCompanyDispatchUpdateParams from "../../../../../common/services/cargo-company/dispatch/update/cargo-company-dispatch-update-params";

type CargoDispatchUpdateProps = {};

const CargoDispatchUpdateComponent: FC<CargoDispatchUpdateProps> = () => {
  const { cargoCompanyUuid, dispatchUuid } =
    useParams<CargoDispatchUpdateRouteParams>();

  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const [isDispatchUpdatePending, setIsDispatchUpdatePending] = useState(false);

  const cargoCompany = useCargoCompanyDetails();
  const cargoCompanyAbort = useAbort();

  const cargoDispatch = useCargoCompanyDispatchDetails();
  const cargoDispatchAbort = useAbort();

  const cargoDispatchUpdateAbort = useAbort();
  const navigate = useNavigate();

  const form = useForm<CargoDispatchFormData>({
    emptyValues: cargoDispatchFormHelper.getEmptyFormData(),
    validationDefinition: cargoDispatchFormHelper.getValidationDefinition(),
  });

  const translations = cargoTranslationsHelper.getDispatchUpdateTranslations();

  useEffect(() => {
    if (!dispatchUuid || !cargoCompany.data) return;

    cargoDispatch.load(
      {
        cargoCompanyUuid: cargoCompany.data?.uuid!,
        dispatchUuid: dispatchUuid,
      },
      cargoDispatchAbort.signal
    );

    return () => {
      cargoDispatchAbort.revoke();
    };
  }, [dispatchUuid, cargoCompany.data]);

  useEffect(() => {
    if (!cargoCompanyUuid) return;

    cargoCompany.load(
      {
        cargoCompanyUuid,
      },
      cargoCompanyAbort.signal
    );

    return () => cargoCompanyAbort.revoke();
  }, [cargoCompanyUuid]);

  useEffect(() => {
    const breadcrumbs = cargoBreadcrumbsHelper.getDispatchUpdateBreadcrumbs({
      cargoCompanyName: cargoCompany.data?.displayName ?? "",
      dispatchName: cargoDispatch.data?.displayName ?? "",
      cargoCompanyUuid: cargoCompany.data?.uuid!,
      dispatchUuid: cargoDispatch.data?.uuid!,
    });

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, cargoCompany.data, cargoDispatch.data]);

  useEffect(() => {
    if (!cargoDispatch.data) return;

    form.setValues({
      name: cargoDispatch.data?.name,
      displayName: cargoDispatch.data?.displayName,
      address: cargoDispatch.data?.address,
    });
  }, [cargoDispatch.data]);

  const onCargoDispatchUpdateSuccess = () => {
    notificationService.success(translations.successAddNotificationLabel);
    navigate(
      cargoRoutesHelper.getDispatchListingRoute({
        cargoCompanyUuid: cargoCompanyUuid!,
      })
    );
  };

  const onCargoDispatchUpdateFailure = () => {
    notificationService.error(translations.failureAddNotificationLabel);
  };

  const updateDispatch = async () => {
    setIsDispatchUpdatePending(true);

    const params: CargoCompanyDispatchUpdateParams = {
      companyUuid: cargoCompanyUuid!,
      name: form.values.name,
      displayName: form.values.displayName,
      address: form.values.address,
      dispatchUuid: dispatchUuid!,
    };

    try {
      await cargoCompanyDispatchService.update(
        params,
        cargoDispatchUpdateAbort.signal
      );

      onCargoDispatchUpdateSuccess();
    } catch {
      onCargoDispatchUpdateFailure();
    } finally {
      setIsDispatchUpdatePending(false);
    }
  };

  const onSubmitButtonClick = async () => {
    const isFormValid = await form.validateAll();

    if (!isFormValid) return;

    updateDispatch();
  };

  return (
    <div className="cargo_taxi_contract_listing">
      <HeadingComponent title={translations.header.headingText} />
      <Row>
        <Column xl={8}>
          <CardComponent>
            <Row>
              <Column xl={6}>
                <FormFieldComponent
                  label={translations.form.nameLabel}
                  isRequired
                  errorMessage={form.validationResults.name.errorMessage}
                >
                  <InputComponent
                    value={form.values.name}
                    onChange={(value) => form.setValue("name", value)}
                    placeholder={translations.form.namePlaceholder}
                    hasError={!!form.validationResults.name.errorMessage}
                    onBlur={() => form.validate("name")}
                    idForTesting="cargo-dispatch-update-name"
                  />
                </FormFieldComponent>
              </Column>
              <Column xl={6}>
                <FormFieldComponent
                  label={translations.form.displayNameLabel}
                  isRequired
                  errorMessage={form.validationResults.displayName.errorMessage}
                >
                  <InputComponent
                    value={form.values.displayName}
                    onChange={(value) => form.setValue("displayName", value)}
                    placeholder={translations.form.displayNamePlaceholder}
                    hasError={!!form.validationResults.displayName.errorMessage}
                    onBlur={() => form.validate("displayName")}
                    idForTesting="carg-dispatch-update-display-name"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column xl={6}>
                <FormFieldComponent
                  label={translations.form.addressLabel}
                  isRequired
                  errorMessage={form.validationResults.address.errorMessage}
                >
                  <InputComponent
                    value={form.values.address}
                    onChange={(value) => form.setValue("address", value)}
                    placeholder={translations.form.addressPlaceholder}
                    hasError={!!form.validationResults.address.errorMessage}
                    onBlur={() => form.validate("address")}
                    idForTesting="cargo-dispatch-update-address"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <ButtonComponent
              onClick={onSubmitButtonClick}
              type="primary"
              isDisabled={isDispatchUpdatePending}
              idForTesting="cargo-dispatch-update-submit-button"
              classNames={{ root: "mt-2" }}
              title={translations.submitButtonTitle}
            >
              {translations.submitButtonLabel}
            </ButtonComponent>
          </CardComponent>
        </Column>
      </Row>
    </div>
  );
};

export default CargoDispatchUpdateComponent;
