import { FC, useMemo } from "react";
import FormValidationResult from "../../../../../../../common/utils/validation/types/form-validation-result";
import { useAppContext } from "../../../../../../../context/app.context";
import DriverEditVehicleFormData from "../../form/types/driver-edit-vehicle-form-data";
import DriverEditCarOwnershipType from "../../types/driver-edit-car-ownership-type";
import userTranslationsHelper from "../../../../../../../languages/user-translations.helper";
import DriverEditCarOwnershipTypeSelectOption from "../../types/driver-edit-car-ownership-type-select-option";
import CardComponent from "../../../../../../../common/components/card/card.component";
import Row from "../../../../../../../common/components/grid/row";
import Column from "../../../../../../../common/components/grid/column";
import FormFieldComponent from "../../../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../../../common/components/form/input/input.component";
import NumericInputComponent from "../../../../../../../common/components/form/input/numeric-input/numeric-input.component";
import SingleSelectComponent from "../../../../../../../common/components/form/select/single-select/single-select.component";
import driverEditCarOwnershipTypeFactory from "../../factory/driver-edit-car-ownership-type.factory";

type DriverEditVehicleDataProps = {
  carOwnershipType: DriverEditCarOwnershipType;
  validateVehicleData: (
    formKey: keyof DriverEditVehicleFormData,
    value?: unknown
  ) => Promise<boolean>;
  onVehicleDataChange: (
    formKey: keyof DriverEditVehicleFormData,
    value: any
  ) => void;
  vehicleValidationResults: Record<
    keyof DriverEditVehicleFormData,
    FormValidationResult
  >;
  vehicleFormValues: DriverEditVehicleFormData;
};

const DriverEditVehicleDataComponent: FC<DriverEditVehicleDataProps> = (
  props
) => {
  const { selectedAppLanguage } = useAppContext();

  const translations =
    userTranslationsHelper.getDriverEditTranslations().form.vehicleData;

  const ownershipSelectOptions = useMemo(
    driverEditCarOwnershipTypeFactory.createSelectOptions,
    [selectedAppLanguage]
  );

  const ownershipSelectOption = props.vehicleFormValues.ownership
    ? ownershipSelectOptions.find(
        (option) => option.value === props.vehicleFormValues.ownership
      ) || null
    : null;

  const handleVehicleOwnershipTypeChange = async (
    value: DriverEditCarOwnershipTypeSelectOption | null
  ) => {
    if (!value) return;

    const isValid = await props.validateVehicleData("ownership", value.value);
    if (isValid) {
      props.onVehicleDataChange("ownership", value.value);
    }
  };

  return (
    <CardComponent header={{ title: translations.headingText }}>
      <Row>
        <Column md={6}>
          <FormFieldComponent
            isRequired
            label={translations.makeLabel}
            errorMessage={props.vehicleValidationResults.make.errorMessage}
            classNames={{ root: "mt-0" }}
          >
            <InputComponent
              placeholder={translations.makePlaceholder}
              value={props.vehicleFormValues.make}
              onChange={(value) => props.onVehicleDataChange("make", value)}
              onBlur={() => {
                props.validateVehicleData("make");
              }}
              hasError={!!props.vehicleValidationResults.make.errorMessage}
              idForTesting="vehicle-make"
            />
          </FormFieldComponent>
        </Column>
        <Column md={6}>
          <FormFieldComponent
            isRequired
            label={translations.modelLabel}
            errorMessage={props.vehicleValidationResults.model.errorMessage}
            classNames={{ root: "mt-0" }}
          >
            <InputComponent
              placeholder={translations.modelPlaceholder}
              value={props.vehicleFormValues.model}
              onChange={(value) => props.onVehicleDataChange("model", value)}
              onBlur={() => {
                props.validateVehicleData("model");
              }}
              hasError={!!props.vehicleValidationResults.model.errorMessage}
              idForTesting="vehicle-model"
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column md={6}>
          <FormFieldComponent
            isRequired
            label={translations.productionYearLabel}
            errorMessage={
              props.vehicleValidationResults.productionYear.errorMessage
            }
          >
            <NumericInputComponent
              placeholder={translations.productionYearPlaceholder}
              value={props.vehicleFormValues.productionYear}
              isIntegerOnly
              onChange={(value) =>
                props.onVehicleDataChange("productionYear", value)
              }
              onBlur={() => {
                props.validateVehicleData("productionYear");
              }}
              hasError={
                !!props.vehicleValidationResults.productionYear.errorMessage
              }
              idForTesting="vehicle-production-year"
            />
          </FormFieldComponent>
        </Column>
        <Column md={6}>
          <FormFieldComponent
            isRequired
            label={translations.numberOfSeatsLabel}
            errorMessage={
              props.vehicleValidationResults.numberOfSeats.errorMessage
            }
          >
            <NumericInputComponent
              placeholder={translations.numberOfSeatsPlaceholder}
              value={props.vehicleFormValues.numberOfSeats}
              isIntegerOnly
              onChange={(value) =>
                props.onVehicleDataChange("numberOfSeats", value)
              }
              onBlur={() => {
                props.validateVehicleData("numberOfSeats");
              }}
              hasError={
                !!props.vehicleValidationResults.numberOfSeats.errorMessage
              }
              idForTesting="vehicle-number-of-seats"
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column md={6}>
          <FormFieldComponent
            isRequired
            label={translations.registrationNumberLabel}
            errorMessage={
              props.vehicleValidationResults.registrationNumber.errorMessage
            }
          >
            <InputComponent
              placeholder={translations.registrationNumberPlaceholder}
              value={props.vehicleFormValues.registrationNumber}
              onChange={(value) =>
                props.onVehicleDataChange(
                  "registrationNumber",
                  value.toUpperCase()
                )
              }
              onBlur={() => {
                props.validateVehicleData("registrationNumber");
              }}
              hasError={
                !!props.vehicleValidationResults.registrationNumber.errorMessage
              }
              idForTesting="vehicle-registration-number"
            />
          </FormFieldComponent>
        </Column>
        <Column md={6}>
          <FormFieldComponent
            isRequired
            label={translations.ownershipLabel}
            errorMessage={props.vehicleValidationResults.ownership.errorMessage}
          >
            <SingleSelectComponent
              placeholder={translations.ownershipPlaceholder}
              value={ownershipSelectOption}
              options={ownershipSelectOptions}
              onChange={handleVehicleOwnershipTypeChange}
              hasError={!!props.vehicleValidationResults.ownership.errorMessage}
              idForTesting="car-ownership"
            />
          </FormFieldComponent>
        </Column>
      </Row>
    </CardComponent>
  );
};

export default DriverEditVehicleDataComponent;
