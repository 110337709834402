import TaxiOfficerEditViewBasicProps from "../common/taxi-officer-edit-view-basic-props";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAbort from "../../../../../common/hooks/use-abort";
import useTaxiCorporationList from "../../../../../common/services/taxi-corporation/list/use-taxi-corporation-list";
import useForm from "../../../../../common/components/form/use-form";
import TaxiOfficerEditByRailyFormData from "./common/types/taxi-officer-edit-by-raily-form-data";
import taxiOfficerEditByRailyFormHelper from "./common/taxi-officer-edit-by-raily-form.helper";
import userRoutesHelper from "../../../common/routes/user-routes.helper";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import notificationService from "../../../../../common/utils/notification/notification.service";
import TaxiOfficerEditParams from "../../../../../common/services/taxi-officer/edit/taxi-officer-edit-params";
import taxiOfficerEditByRailyParamsFactory from "./common/taxi-officer-edit-by-raily-params.factory";
import taxiOfficerService from "../../../../../common/services/taxi-officer/taxi-officer.service";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import Row from "../../../../../common/components/grid/row";
import Column from "../../../../../common/components/grid/column";
import CardComponent from "../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import TaxiCorporationSingleSelectComponent from "../../../../../common/components/form/select/taxi-corporation/taxi-corporation-single-select.component";
import InputComponent from "../../../../../common/components/form/input/input.component";
import PhoneNumberInputComponent from "../../../../../common/components/form/input/phone-number/phone-number-input.component";
import ButtonComponent from "../../../../../common/components/button/button.component";
import useTaxiOfficerDetails from "../../../../../common/services/taxi-officer/details/use-taxi-officer-details";
import taxiOfficerEditByRailyFormDataFactory from "./common/taxi-officer-edit-by-raily-form-data.factory";

type TaxiOfficerEditByRailyProps = TaxiOfficerEditViewBasicProps & {
  taxiOfficerUuid: string;
};

const TaxiOfficerEditByRailyComponent: FC<TaxiOfficerEditByRailyProps> = (
  props
) => {
  const [isTaxiOfficerEditPending, setIsTaxiOfficerEditPending] =
    useState(false);

  const navigate = useNavigate();
  const taxiOfficerEditAbort = useAbort();
  const taxiCorporationListAbort = useAbort();
  const taxiCorporationList = useTaxiCorporationList();

  const form = useForm<TaxiOfficerEditByRailyFormData>({
    emptyValues: taxiOfficerEditByRailyFormHelper.getDefaultFormData(),
    validationDefinition:
      taxiOfficerEditByRailyFormHelper.getValidationDefinition(),
  });

  useEffect(() => {
    taxiCorporationList.load({}, taxiCorporationListAbort.signal);
  }, []);

  const taxiOfficerDetails = useTaxiOfficerDetails();

  const taxiOfficerDetailsAbort = useAbort();

  useEffect(() => {
    if (props.taxiOfficerUuid) {
      taxiOfficerDetails.load(
        { taxiOfficerUuid: props.taxiOfficerUuid },
        taxiOfficerDetailsAbort.signal
      );
    }
  }, [props.taxiOfficerUuid]);

  useEffect(() => {
    if (!taxiOfficerDetails.data) {
      return;
    }

    const formData = taxiOfficerEditByRailyFormDataFactory.create(
      taxiOfficerDetails.data
    );
    form.setValues(formData);
  }, [taxiOfficerDetails.data]);

  const navigateToListing = () => {
    const taxiOfficerListingRoute =
      userRoutesHelper.getTaxiOfficerListingRoute();
    navigate(taxiOfficerListingRoute);
  };

  const translations = userTranslationsHelper.getTaxiOfficerEditTranslations();

  const onTaxiOfficerEditSuccess = () => {
    notificationService.success(translations.successEditNotificationLabel);
    navigateToListing();
  };

  const onTaxiOfficerEditFailure = () => {
    notificationService.error(translations.failureEditNotificationLabel);
  };

  const editTaxiOfficer = async () => {
    setIsTaxiOfficerEditPending(true);

    const params: TaxiOfficerEditParams =
      taxiOfficerEditByRailyParamsFactory.create(form.values);

    try {
      await taxiOfficerService.edit(
        props.taxiOfficerUuid,
        params,
        taxiOfficerEditAbort.signal
      );

      onTaxiOfficerEditSuccess();
    } catch {
      onTaxiOfficerEditFailure();
    } finally {
      setIsTaxiOfficerEditPending(false);
    }
  };

  const submitForm = async () => {
    const isFormValid = await form.validateAll();

    if (!isFormValid) return;

    editTaxiOfficer();
  };

  const isTaxiCorporationSelectDisabled = taxiCorporationList.isError;

  return (
    <>
      <HeadingComponent
        title={translations.header.headingLabel}
        actions={props.changeViewButtons}
      />
      <Row>
        <Column xl={8}>
          <CardComponent>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.emailLabel}
                  isRequired
                  errorMessage={form.validationResults.email.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.emailPlaceholder}
                    value={form.values.email}
                    onChange={(value) => form.setValue("email", value)}
                    onBlur={() => form.validate("email")}
                    hasError={!!form.validationResults.email.errorMessage}
                    idForTesting={`email`}
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.firstNameLabel}
                  isRequired
                  errorMessage={form.validationResults.firstName.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.firstNamePlaceholder}
                    value={form.values.firstName}
                    onChange={(value) => form.setValue("firstName", value)}
                    onBlur={() => form.validate("firstName")}
                    hasError={!!form.validationResults.firstName.errorMessage}
                    idForTesting={`first-name`}
                  />
                </FormFieldComponent>
              </Column>
            </Row>

            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.lastNameLabel}
                  isRequired
                  errorMessage={form.validationResults.lastName.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.lastNamePlaceholder}
                    value={form.values.lastName}
                    onChange={(value) => form.setValue("lastName", value)}
                    onBlur={() => form.validate("lastName")}
                    hasError={!!form.validationResults.lastName.errorMessage}
                    idForTesting={`last-name`}
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.phoneNumberLabel}
                  isRequired
                  errorMessage={form.validationResults.phoneNumber.errorMessage}
                >
                  <PhoneNumberInputComponent
                    placeholder={translations.form.phoneNumberPlaceholder}
                    phoneNumber={form.values.phoneNumber}
                    onChange={(value) => form.setValue("phoneNumber", value)}
                    onBlur={() => form.validate("phoneNumber")}
                    hasError={!!form.validationResults.phoneNumber.errorMessage}
                    idForTesting={`phone-number`}
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.alternativePhoneNumberLabel}
                  errorMessage={
                    form.validationResults.alternativePhoneNumber.errorMessage
                  }
                >
                  <PhoneNumberInputComponent
                    placeholder={
                      translations.form.alternativePhoneNumberPlaceholder
                    }
                    phoneNumber={form.values.alternativePhoneNumber}
                    onChange={(value) =>
                      form.setValue("alternativePhoneNumber", value)
                    }
                    onBlur={() => form.validate("alternativePhoneNumber")}
                    hasError={
                      !!form.validationResults.alternativePhoneNumber
                        .errorMessage
                    }
                    idForTesting={`alternative-phone-number`}
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.taxiCorporationLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.taxiCorporationUuid.errorMessage
                  }
                >
                  <TaxiCorporationSingleSelectComponent
                    idForTesting={`taxi-corporation`}
                    onChange={(value) => {
                      form.setValue("taxiCorporationUuid", value?.uuid ?? "");
                    }}
                    onBlur={() => form.validate("taxiCorporationUuid")}
                    placeholder={translations.form.taxiCorporationPlaceholder}
                    selectedTaxiCorporationUuid={
                      form.values.taxiCorporationUuid
                    }
                    taxiCorporationList={taxiCorporationList.data.data}
                    isError={
                      !!form.validationResults.taxiCorporationUuid.errorMessage
                    }
                    isLoading={taxiCorporationList.isLoading}
                    isDisabled={isTaxiCorporationSelectDisabled}
                  />
                </FormFieldComponent>
              </Column>
            </Row>
          </CardComponent>
        </Column>
      </Row>
      <ButtonComponent
        onClick={submitForm}
        type="primary"
        isDisabled={isTaxiOfficerEditPending}
        classNames={{ root: "mt-4" }}
        idForTesting={`submit-button`}
        title={translations.form.submitButtonTitle}
      >
        {translations.form.submitButtonLabel}
      </ButtonComponent>
    </>
  );
};

export default TaxiOfficerEditByRailyComponent;
