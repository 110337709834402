import DriverEditForm, {
  DriverEditFormAddress,
  DriverEditFormCarOwnership,
  DriverEditFormCitizenship,
  DriverEditFormFleetPartner,
  DriverEditFormFormOfEmployment,
  DriverEditFormLanguage,
  DriverEditFormTaxiCorporation,
} from "../../../../../../common/services/driver/edit-form/driver-edit-form";
import DriverEditAccountFormData from "../form/types/driver-edit-account-form-data";
import DriverEditVehicleFormData from "../form/types/driver-edit-vehicle-form-data";
import RailyDriverEditUserFormData from "../form/types/raily-driver-edit-user-form-data";
import DriverEditAddress from "../types/driver-edit-address";
import DriverEditAddressType from "../types/driver-edit-address-type";
import DriverEditCarOwnershipType from "../types/driver-edit-car-ownership-type";
import DriverEditCitizenshipType from "../types/driver-edit-citizenship-type";
import DriverEditFormOfEmploymentType from "../types/driver-edit-form-of-employment-type";
import DriverEditLanguageType from "../types/driver-edit-language-type";
import DriverEditTaxiCorporation from "../types/driver-edit-taxi-corporation";
import DriverEditUserFleetPartnerSelectOption from "../types/driver-edit-user-fleet-partner-select-option";
import DriverEditUserPhoneNumber from "../types/driver-edit-user-phone-number";
import DriverEditTaxiCorporationFormData from "./driver-edit-taxi-corporation-form-data";

const createFleetPartnerSelectOption = (
  fleetPartnerDataItem: DriverEditFormFleetPartner
): DriverEditUserFleetPartnerSelectOption => {
  return {
    value: {
      name: fleetPartnerDataItem.name,
      uuid: fleetPartnerDataItem.uuid,
    },
    label: fleetPartnerDataItem.name,
  };
};

const createFleetPartnerSelectOptions = (
  responseFleetPartners: DriverEditFormFleetPartner[]
): DriverEditUserFleetPartnerSelectOption[] => {
  return responseFleetPartners.map(createFleetPartnerSelectOption);
};

const createCarOwnershipType = (
  type: DriverEditFormCarOwnership
): DriverEditCarOwnershipType | null => {
  switch (type) {
    case DriverEditFormCarOwnership.BUSINESS:
      return DriverEditCarOwnershipType.BUSINESS;
    case DriverEditFormCarOwnership.OWNER:
      return DriverEditCarOwnershipType.OWNER;
  }
};

const createCitizenship = (
  citizenship: DriverEditFormCitizenship
): DriverEditCitizenshipType | null => {
  switch (citizenship) {
    case DriverEditFormCitizenship.BY:
      return DriverEditCitizenshipType.BY;
    case DriverEditFormCitizenship.CZ:
      return DriverEditCitizenshipType.CZ;
    case DriverEditFormCitizenship.DE:
      return DriverEditCitizenshipType.DE;
    case DriverEditFormCitizenship.LT:
      return DriverEditCitizenshipType.LT;
    case DriverEditFormCitizenship.PL:
      return DriverEditCitizenshipType.PL;
    case DriverEditFormCitizenship.RU:
      return DriverEditCitizenshipType.RU;
    case DriverEditFormCitizenship.SK:
      return DriverEditCitizenshipType.SK;
    case DriverEditFormCitizenship.UA:
      return DriverEditCitizenshipType.UA;
  }
};

const createFormOfEmployment = (
  formOfEmployment: DriverEditFormFormOfEmployment
): DriverEditFormOfEmploymentType | null => {
  switch (formOfEmployment) {
    case DriverEditFormFormOfEmployment.B2B:
      return DriverEditFormOfEmploymentType.B2B;
    case DriverEditFormFormOfEmployment.EMPLOYMENT:
      return DriverEditFormOfEmploymentType.EMPLOYMENT;
  }
};

const createLanguageType = (
  type: DriverEditFormLanguage
): DriverEditLanguageType => {
  switch (type) {
    case DriverEditFormLanguage.BY:
      return DriverEditLanguageType.BY;
    case DriverEditFormLanguage.CZ:
      return DriverEditLanguageType.CZ;
    case DriverEditFormLanguage.DE:
      return DriverEditLanguageType.DE;
    case DriverEditFormLanguage.LT:
      return DriverEditLanguageType.LT;
    case DriverEditFormLanguage.PL:
      return DriverEditLanguageType.PL;
    case DriverEditFormLanguage.RU:
      return DriverEditLanguageType.RU;
    case DriverEditFormLanguage.SK:
      return DriverEditLanguageType.SK;
    case DriverEditFormLanguage.UA:
      return DriverEditLanguageType.UA;
    case DriverEditFormLanguage.EN:
      return DriverEditLanguageType.EN;
    case DriverEditFormLanguage.ES:
      return DriverEditLanguageType.ES;
    case DriverEditFormLanguage.FR:
      return DriverEditLanguageType.FR;
  }
};

const createLanguagesType = (
  types: DriverEditFormLanguage[]
): DriverEditLanguageType[] => {
  return types.map(createLanguageType);
};

const createAddressType = (
  addressTypeDataItem: string
): DriverEditAddressType => {
  switch (addressTypeDataItem) {
    case "MAILING":
      return DriverEditAddressType.MAILING;
    case "STARTING":
      return DriverEditAddressType.STARTING;
    default:
      return DriverEditAddressType.STARTING;
  }
};

const createTaxiCorporation = (
  taxiCorporation: DriverEditFormTaxiCorporation
): DriverEditTaxiCorporation => {
  return {
    displayName: taxiCorporation.displayName,
    name: taxiCorporation.companyName,
    uuid: taxiCorporation.id,
  };
};

const createAddress = (address: DriverEditFormAddress): DriverEditAddress => {
  return {
    apartmentNumber: address.apartment,
    country: address.country,
    description: address.description,
    houseNumber: address.houseNo,
    latitude: address.lat,
    longitude: address.lon,
    street: address.street,
    town: address.town,
    type: createAddressType(address.type),
    zipCode: address.zipCode,
  };
};

const createAddresses = (
  addresses: DriverEditFormAddress[]
): DriverEditAddress[] => {
  return addresses.map(createAddress);
};

const createMobile = (
  dialingCode: string,
  number: string
): DriverEditUserPhoneNumber => {
  return {
    dialingCode: dialingCode ?? undefined,
    number: number ?? undefined,
  };
};

const createVehicleFormData = (
  driverEditFormDataResponse: DriverEditForm["driver"]
): DriverEditVehicleFormData => {
  return {
    make: driverEditFormDataResponse.carMake,
    model: driverEditFormDataResponse.carModel,
    productionYear: driverEditFormDataResponse.carProdYear,
    numberOfSeats: driverEditFormDataResponse.carSeatsNo,
    registrationNumber: driverEditFormDataResponse.carRegNo,
    ownership: createCarOwnershipType(driverEditFormDataResponse.carOwnership),
  };
};

const createAccountFormData = (
  driverEditFormDataResponse: DriverEditForm
): DriverEditAccountFormData => {
  const userDataItem = driverEditFormDataResponse.user;
  return {
    username: userDataItem.username,
    email: userDataItem.email,
  };
};

const createUserFormData = (
  driverEditFormData: DriverEditForm
): RailyDriverEditUserFormData => {
  const userFormData = driverEditFormData.user;
  const driverFormData = driverEditFormData.driver;
  const addressesFormData = driverEditFormData.addresses;

  return {
    addresses: createAddresses(addressesFormData),
    alternativeMobile: createMobile(
      userFormData.mobileSecondaryPrefix,
      userFormData.mobileSecondary
    ),
    birthDate: new Date(driverFormData.birthDate),
    birthPlace: driverFormData.birthPlace,
    citizenship: createCitizenship(driverFormData.citizenship),
    firstName: userFormData.firstName,
    languages: createLanguagesType(driverFormData.languages),
    lastName: userFormData.lastName,
    mobile: createMobile(
      userFormData.mobilePrimaryPrefix,
      userFormData.mobilePrimary
    ),
    personalIdNumber: driverFormData.idNumber,
    yearsOfExperience: driverFormData.experience,
  };
};

const createTaxiCorporationFormData = (
  driverEditFormData: DriverEditForm
): DriverEditTaxiCorporationFormData[] => {
  return driverEditFormData.associations.map((association) => {
    return {
      id: association.id,
      name: association.companyName ?? "",
      companyId: association.regon ?? "",
      taxNumber: association.taxId ?? "",
      nationalCourtRegisterNumber: association.krsNo ?? "",
      country: association.country ?? "",
      zipCode: association.zipCode ?? "",
      town: association.town ?? "",
      street: association.street ?? "",
      houseNumber: association.houseNo ?? "",
      apartmentNumber: association.apartment ?? "",
      description: association.description ?? "",
      fleetPartner: association.fleetPartner,
      formOfEmployment: createFormOfEmployment(association.contractType),
      taxiCorporation: createTaxiCorporation(association.taxiCorporation),
    };
  });
};

const railyDriverEditFactory = {
  createUserFormData,
  createAccountFormData,
  createVehicleFormData,
  createFleetPartnerSelectOptions,
  createTaxiCorporationFormData,
};

export default railyDriverEditFactory;
