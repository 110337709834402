import TaxiFleetPartnerAddParams from "../../../../../common/services/taxi-fleet-partner/add/taxi-fleet-partner-add-params";
import TaxiFleetPartnerAddFormData from "./types/taxi-fleet-partner-add-form-data";

const create = (
  formData: TaxiFleetPartnerAddFormData,
  taxiCorporationUuid: string
): TaxiFleetPartnerAddParams => {
  return {
    name: formData.name,
    notes: formData.notes,
    taxiCorporationUuid,
  };
};

const taxiFleetPartnerAddParamsFactory = {
  create,
};

export default taxiFleetPartnerAddParamsFactory;
