export enum DriverAddRequestAddressType {
  STARTING = "STARTING",
  MAILING = "MAILING",
}

export enum DriverAddRequestFormOfEmployment {
  B2B = "B2B",
  EMPLOYMENT = "EMPLOYMENT",
}

export enum DriverAddRequestCitizenship {
  PL = "PL",
  DE = "DE",
  SK = "SK",
  CZ = "CZ",
  UA = "UA",
  RU = "RU",
  BY = "BY",
  LT = "LT",
}

export enum DriverAddRequestLanguage {
  PL = "PL",
  EN = "EN",
  DE = "DE",
  FR = "FR",
  ES = "ES",
  SK = "SK",
  CZ = "CZ",
  UA = "UA",
  RU = "RU",
  BY = "BY",
  LT = "LT",
}

export enum DriverAddRequestCarOwnership {
  OWNER = "OWNER",
  BUSINESS = "BUSINESS",
}

export type DriverAddRequestAddress = {
  type: DriverAddRequestAddressType;
  lat: number;
  lon: number;
  country: string;
  town: string;
  zipCode: string;
  street: string;
  houseNo: string;
  apartment: string;
  description: string | null;
};

export type DriverAddRequestTaxiDriverAssociationForEmployment = {
  contractType: DriverAddRequestFormOfEmployment;
  displayName: string;
  taxiCorporationId: string;
  fleetPartnerId: string | null;
};

export type DriverAddRequestTaxiDriverAssociationForB2B = {
  contractType: DriverAddRequestFormOfEmployment;
  taxId: string;
  regon: string | null;
  krsNo: string | null;
  fleetPartnerId: string | null;
  companyName: string;
  displayName: string;
  country: string;
  town: string;
  zipCode: string;
  street: string;
  houseNo: string;
  apartment: string;
  description: string;
  taxiCorporationId: string;
};

export type DriverAddRequestTaxiDriverAssociation =
  | DriverAddRequestTaxiDriverAssociationForEmployment
  | DriverAddRequestTaxiDriverAssociationForB2B;

export type DriverAddRequestDriver = {
  taxiDriverAssociations: DriverAddRequestTaxiDriverAssociation[];
  addresses: DriverAddRequestAddress[];
  birthDate: string;
  birthPlace: string;
  idNumber: string;
  citizenship: DriverAddRequestCitizenship;
  languages: DriverAddRequestLanguage[];
  experience: number;
  carOwnership: DriverAddRequestCarOwnership;
  carMake: string;
  carModel: string;
  carRegNo: string;
  carProdYear: number;
  carSeatsNo: number;
};

export type DriverAddRequestBody = {
  firstName: string | undefined;
  lastName: string | undefined;
  mobilePrimaryPrefix: string | undefined;
  mobilePrimary: string | undefined;
  mobileSecondaryPrefix: string | null | undefined;
  mobileSecondary: string | null | undefined;
  email: string | undefined;
  username: string | undefined;
  password: string | undefined;
  driver: DriverAddRequestDriver;
};

type DriverAddRequest = {
  firstName?: string;
  lastName?: string;
  mobilePrimaryPrefix?: string;
  mobilePrimary?: string;
  mobileSecondaryPrefix?: string | null;
  mobileSecondary?: string | null;
  email?: string;
  username?: string;
  password?: string;
  driver: DriverAddRequestDriver;
};

export default DriverAddRequest;
