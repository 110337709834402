import CargoTaxiContractUpdateRequest from "../../../api/raily/cargo-taxi-contract/update/cargo-taxi-contract-update.request";
import CargoTaxiContractUpdateParams from "./cargo-taxi-contract-update-params";

const create = (
  params: CargoTaxiContractUpdateParams
): CargoTaxiContractUpdateRequest => {
  return {
    disabled: !params.isActive,
    contractId: params.contractUuid,
    contactPassengerAfterMinutes: params.contactPassengerAfterMinutes,
    cancelOrderByTaxiAllowed: params.cancelOrderByTaxiAllowed,
    editOrderByTaxiAllowed: params.editOrderByTaxiAllowed,
  };
};

const cargoTaxiContractUpdateRequestFactory = {
  create,
};

export default cargoTaxiContractUpdateRequestFactory;
