import { FC, useState } from "react";
import PassengerListingFilter from "../common/types/passenger-listing-filter";
import PassengerListingSortKey from "../common/types/passenger-listing-sort-key";
import ListingFilterDefinition from "../../../../../common/components/listing/filter/types/listing-filter-definition";
import passengerListingFilterHelper from "./passenger-listing-filter.helper";
import ListingSortDefinition from "../../../../../common/components/listing/filter/types/listing-sort-definition";
import ListingFilterComponent from "../../../../../common/components/listing/filter/listing-filter.component";
import ListingFilter from "../../../../../common/types/listing-filter";
import FormErrorComponent from "../../../../../common/components/form/error/form-error.component";
import passengerListingFilterValidationService from "./passenger-listing-filter-validation.service";

type PassengerListingFilterProps = {
  filters: PassengerListingFilter[];
  onFiltersChange: (filters: PassengerListingFilter[]) => void;
  sortKey: PassengerListingSortKey;
  onSortKeyChange: (sortKey: PassengerListingSortKey) => void;
};

const PassengerListingFilterComponent: FC<PassengerListingFilterProps> = (
  props
) => {
  const [addressErrorMessage, setErrorAddressMessage] = useState<
    string | undefined
  >(undefined);
  const filterDefinition: ListingFilterDefinition<PassengerListingFilter> =
    passengerListingFilterHelper().getFilterDefinition();

  const sortDefinition: ListingSortDefinition =
    passengerListingFilterHelper().getSortDefinition();

  const handleFiltersChange = (appliedFilters: PassengerListingFilter[]) => {
    const addressFilter = appliedFilters.find(
      (filter) => filter.type === "ADDRESS"
    );

    const errorMessage = addressFilter
      ? passengerListingFilterValidationService.validateAddress(
          addressFilter.value
        )
      : undefined;

    setErrorAddressMessage(errorMessage);
    props.onFiltersChange(appliedFilters);

    return addressErrorMessage;
  };

  return (
    <>
      <ListingFilterComponent<PassengerListingFilter>
        appliedFilters={props.filters as ListingFilter[]}
        filterDefinition={filterDefinition}
        onFiltersChange={(appliedFilters) =>
          handleFiltersChange(appliedFilters as PassengerListingFilter[])
        }
        appliedSortKey={props.sortKey}
        onSortKeyChange={(value) =>
          props.onSortKeyChange(value as PassengerListingSortKey)
        }
        sortDefinition={sortDefinition}
        idForTestingFilter="passenger-listing-filter"
        idForTestingSort="passenger-listing-sort"
      />
      <FormErrorComponent message={addressErrorMessage} />
    </>
  );
};

export default PassengerListingFilterComponent;
