import { FC } from "react";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import OrderOptimizerDetailsToolButtonComponent from "../../common/tool-button/order-optimizer-details-tool-button.component";
import orderOptimizerTranslationsHelper from "../../../../../languages/order-optimizer-translations.helper";
import OrderOptimizerDetailsItem from "../../common/types/order-optimizer-details-item";

export type OrderOptimizerDetailsProps = {
  details: OrderOptimizerDetailsItem;
  idDisabled?: boolean;
  isButtonClicked: boolean;
  onSuccess: () => void;
};

const OrderOptimizerDetailsShowDrivingRouteComponent: FC<
  OrderOptimizerDetailsProps
> = (props) => {
  const translations =
    orderOptimizerTranslationsHelper.getDetailsTranslations().tools;

  return (
    <OrderOptimizerDetailsToolButtonComponent
      onClick={props.onSuccess}
      icon={faHome}
      title={translations.showDriverDrivingRouteButtonTittle}
      isDisabled={props.idDisabled}
      isButtonClicked={props.isButtonClicked}
    />
  );
};

export default OrderOptimizerDetailsShowDrivingRouteComponent;
