export enum DriverDetailsDriverPlanEntryWaypointType {
  DRIVER_HOME = "DRIVER_HOME",
  ORDER_WAYPOINT = "ORDER_WAYPOINT",
}

export type DriverDetailsDriverPlanEntryWaypoint = {
  uuid: string;
  type: DriverDetailsDriverPlanEntryWaypointType;
  clientDisplayName: string;
  order: {
    uuid: string;
    cargoInternalOrderId: number;
  } | null;
  address: {
    latitude: number;
    longitude: number;
    displayName: string;
  };
  plannedDate: Date | null;
  estimatedDate: Date | null;
  plannedHaltingTime: number | null;
  checkoutDate: Date | null;
  forcedHaltingTime: number | null;
  highwayCost: number | null;
  isCheckouted: boolean;
  checkoutEventUuid: string | null;
  isCheckoutManagementEnabled: boolean;
  solvedOrderUuid: string | null;
  stageNo: number;
  createdAt: Date | null;
  createdByName: string | null;
};

export type DriverDetailsDriverPlanEntryNodeExclusions = {
  approaching: boolean;
  inside: boolean;
  returning: boolean;
  nodeIds: string[];
};

type DriverDetailsDriverPlanEntry = {
  isHidden: boolean;
  isAnonymized: boolean;
  uuid: string;
  id: number;
  waypoints: DriverDetailsDriverPlanEntryWaypoint[];
  nodeExclusions: DriverDetailsDriverPlanEntryNodeExclusions[];
};

export default DriverDetailsDriverPlanEntry;
