import UserRole from "../../../../common/types/user-role";
import RouteItemDefinition from "../../../../routes/types/route-item-definition";
import PlanningDriverComponent from "../../driver/planning-driver.component";
import PlanningOrderComponent from "../../order/planning-order.component";
import PlanningOverviewComponent from "../../overview/planning-overview.component";
import planningRoutesUrls from "./planning-routes-urls";

const planningRoutesDefinition: RouteItemDefinition[] = [
  {
    component: <PlanningOverviewComponent />,
    path: planningRoutesUrls.overview,
    userRolesWhitelist: [UserRole.ADMIN],
  },
  {
    component: <PlanningOrderComponent />,
    path: planningRoutesUrls.order,
    userRolesWhitelist: [UserRole.ADMIN],
  },
  {
    component: <PlanningDriverComponent />,
    path: planningRoutesUrls.driver,
    userRolesWhitelist: [UserRole.ADMIN],
  },
];

export default planningRoutesDefinition;
