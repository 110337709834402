import { FormValidationDefinition } from "../../../../../../common/components/form/use-form";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import driverEditAccountFormValidationService from "./driver-edit-account-data-form-validation.service";
import DriverEditAccountFormData from "./types/driver-edit-account-form-data";

const getDefaultAccountFormData = (): DriverEditAccountFormData => {
  return {
    email: "",
    username: "",
  };
};

const getAccountValidationDefinition =
  (): FormValidationDefinition<DriverEditAccountFormData> => {
    return {
      email: (values) =>
        driverEditAccountFormValidationService.validateEmail(values.email),
      username: formValidationService.ok,
    };
  };

const driverEditAccountFormHelper = {
  getDefaultAccountFormData,
  getAccountValidationDefinition,
};

export default driverEditAccountFormHelper;
