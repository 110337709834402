import { FC, useEffect, useState } from "react";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import { useAppContext } from "../../../../../context/app.context";
import { useNavigate, useParams } from "react-router-dom";
import userBreadcrumbsHelper from "../../../common/breadcrumbs/user-breadcrumbs.helper";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../../common/hooks/use-document-title";
import useAbort from "../../../../../common/hooks/use-abort";
import useForm from "../../../../../common/components/form/use-form";
import driverEditAccountFormHelper from "../common/form/driver-edit-account-data-form.helper";
import driverEditCompanyFormHelper from "../common/form/driver-edit-company-form.helper";
import driverEditVehicleFormHelper from "../common/form/driver-edit-vehicle-form.helper";
import DriverEditFormOfEmploymentType from "../common/types/driver-edit-form-of-employment-type";
import userRoutesHelper from "../../../common/routes/user-routes.helper";
import notificationService from "../../../../../common/utils/notification/notification.service";
import UserDriverEditRouteParams from "../../../common/routes/types/user-driver-edit-route-params";
import driverService from "../../../../../common/services/driver/driver.service";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import Row from "../../../../../common/components/grid/row";
import Column from "../../../../../common/components/grid/column";
import DriverEditAccountDataComponent from "../common/components/account/driver-edit-account-data.component";
import DriverEditVehicleDataComponent from "../common/components/vehicle/driver-edit-vehicle.component";
import ButtonComponent from "../../../../../common/components/button/button.component";
import DriverEditViewBasicProps from "../common/types/driver-edit-view-basic-props";
import TaxiDriverEditUserDataComponent from "../common/components/user-data/taxi-driver-edit-user-data.component";
import DriverEditUserFleetPartnerSelectOption from "../common/types/driver-edit-user-fleet-partner-select-option";
import useDriverEditForm from "../../../../../common/services/driver/edit-form/use-driver-edit-form";
import DriverEditFormLoadParams from "../../../../../common/services/driver/edit-form/driver-edit-form-load-params";
import taxiDriverEditUserFormHelper from "../common/form/taxi-driver-add-user-data-form.helper";
import taxiDriverEditParamsFactory from "../common/factory/taxi-driver-edit-params.factory";
import taxiDriverEditFactory from "../common/factory/taxi-driver-edit.factory";
import TaxiDriverEditCompanyDataComponent from "../common/components/company/taxi-driver-edit-company.component";

type TaxiDriverEditProps = DriverEditViewBasicProps;

const TaxiDriverEditComponent: FC<TaxiDriverEditProps> = (props) => {
  const translations = userTranslationsHelper.getDriverEditTranslations();

  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  const { driverUuid } = useParams<UserDriverEditRouteParams>();

  const navigate = useNavigate();

  const [taxiDriverAssociationUuid, setTaxiDriverAssociationUuid] =
    useState("");

  const [fleetPartnerSelectOptions, setFleetPartnerSelectOptions] = useState<
    DriverEditUserFleetPartnerSelectOption[]
  >([]);

  const [driverName, setDriverName] = useState("");

  useEffect(() => {
    const breadcrumbs = userBreadcrumbsHelper.getDriverEditBreadcrumbs({
      driverName: driverName,
      driverUuid: driverUuid ?? "",
    });
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, driverName]);

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(
    documentTitleTranslations.userDriverEdit.replace(
      "#{driverName}",
      driverName
    )
  );

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const driverEditForm = useDriverEditForm();
  const driverEditFormAbort = useAbort();

  const formAccountFormData = useForm({
    emptyValues: driverEditAccountFormHelper.getDefaultAccountFormData(),
    validationDefinition:
      driverEditAccountFormHelper.getAccountValidationDefinition(),
  });

  const formCompanyFormData = useForm({
    emptyValues: driverEditCompanyFormHelper.getDefaultCompanyFormData(),
    validationDefinition:
      driverEditCompanyFormHelper.getCompanyValidationDefinition(),
  });

  const formUserFormData = useForm({
    emptyValues: taxiDriverEditUserFormHelper.getDefaultUserFormData(),
    validationDefinition:
      taxiDriverEditUserFormHelper.getUserValidationDefinition(),
  });

  const formVehicleFormData = useForm({
    emptyValues: driverEditVehicleFormHelper.getDefaultVehicleFormData(),
    validationDefinition:
      driverEditVehicleFormHelper.getVehicleValidationDefinition(),
  });

  useEffect(() => {
    if (!driverEditForm.data) {
      return;
    }

    const userFormData = taxiDriverEditFactory.createUserFormData(
      driverEditForm.data
    );
    const accountFormData = taxiDriverEditFactory.createAccountFormData(
      driverEditForm.data
    );
    const companyFormData = taxiDriverEditFactory.createCompanyFormData(
      driverEditForm.data
    );
    const vehicleFormData = taxiDriverEditFactory.createVehicleFormData(
      driverEditForm.data.driver
    );

    const fleetPartnerSelectOptions =
      taxiDriverEditFactory.createFleetPartnerSelectOptions(
        driverEditForm.data.associations[0].taxiCorporation.fleetPartners
      );

    const driverName = `${driverEditForm.data.user.firstName} ${driverEditForm.data.user.lastName}`;

    formUserFormData.setValues(userFormData);
    formAccountFormData.setValues(accountFormData);
    formCompanyFormData.setValues(companyFormData);
    formVehicleFormData.setValues(vehicleFormData);

    setTaxiDriverAssociationUuid(driverEditForm.data.associations[0].id);
    setFleetPartnerSelectOptions(fleetPartnerSelectOptions);
    setDriverName(driverName);
  }, [driverEditForm.data]);

  const fetchFormInitData = () => {
    if (!driverUuid) {
      return;
    }

    const params: DriverEditFormLoadParams = { id: driverUuid };

    driverEditForm.load(params, driverEditFormAbort.signal);
  };

  useEffect(() => {
    fetchFormInitData();
  }, [driverUuid]);

  const isCargoDataSectionVisible =
    formUserFormData.values.formOfEmployment ===
    DriverEditFormOfEmploymentType.B2B;

  const navigateToListing = () => {
    const dealerListingRoute = userRoutesHelper.getDriverListingRoute();
    navigate(dealerListingRoute);
  };

  const onDriverEditSuccess = () => {
    notificationService.success(translations.successEditNotificationText);
    navigateToListing();
  };

  const onDriverEditFailure = () => {
    notificationService.error(translations.failureEditNotificationText);
  };

  const submitForm = async () => {
    const isFormAccountFormDataValid = await formAccountFormData.validateAll();
    const isFormCompanyFormDataValid = isCargoDataSectionVisible
      ? await formCompanyFormData.validateAll()
      : true;
    const isFormUserFormDataValid = await formUserFormData.validateAll();
    const isFormVehicleFormDataValid = await formVehicleFormData.validateAll();

    if (
      !isFormAccountFormDataValid ||
      !isFormCompanyFormDataValid ||
      !isFormUserFormDataValid ||
      !isFormVehicleFormDataValid ||
      !driverUuid
    )
      return;

    const params = taxiDriverEditParamsFactory.createSubmitFormParams(
      formAccountFormData.values,
      formUserFormData.values,
      formVehicleFormData.values
    );

    try {
      setIsFormSubmitting(true);
      await driverService.editDriver(
        params,
        driverEditFormAbort.signal,
        driverUuid
      );

      onDriverEditSuccess();
    } catch {
      onDriverEditFailure();
    } finally {
      setIsFormSubmitting(false);
    }
  };

  if (!driverName) {
    return null;
  }

  return (
    <div className="user_driver_edit">
      <HeadingComponent
        title={translations.header.headingText.replace(
          "#{driverName}",
          driverName
        )}
        actions={props.changeViewButtons}
      />
      <Row>
        <Column withPaddings>
          <DriverEditAccountDataComponent
            accountFormValues={formAccountFormData.values}
            onAccountDataChange={formAccountFormData.setValue}
            validateAccountData={formAccountFormData.validate}
            accountValidationResults={formAccountFormData.validationResults}
          />
        </Column>
      </Row>
      <Row>
        <Column withPaddings>
          <TaxiDriverEditUserDataComponent
            onUserDataChange={formUserFormData.setValue}
            validateUserData={formUserFormData.validate}
            citizenship={formUserFormData.values.citizenship!}
            formOfEmployment={formUserFormData.values.formOfEmployment!}
            userFormValues={formUserFormData.values}
            userValidationResults={formUserFormData.validationResults}
            taxiCorporationUuid={taxiDriverAssociationUuid}
            fleetPartnerSelectOptions={fleetPartnerSelectOptions}
          />
        </Column>
      </Row>
      {isCargoDataSectionVisible && (
        <Row>
          <Column withPaddings>
            <TaxiDriverEditCompanyDataComponent
              onCompanyDataChange={formCompanyFormData.setValue}
              validateCompanyData={formCompanyFormData.validate}
              companyFormValues={formCompanyFormData.values}
              companyValidationResults={formCompanyFormData.validationResults}
            />
          </Column>
        </Row>
      )}
      <Row>
        <Column withPaddings>
          <DriverEditVehicleDataComponent
            onVehicleDataChange={formVehicleFormData.setValue}
            validateVehicleData={formVehicleFormData.validate}
            carOwnershipType={formVehicleFormData.values.ownership!}
            vehicleFormValues={formVehicleFormData.values}
            vehicleValidationResults={formVehicleFormData.validationResults}
          />
        </Column>
      </Row>
      <ButtonComponent
        onClick={submitForm}
        type="primary"
        isLoading={isFormSubmitting}
        classNames={{ root: "mt-2" }}
        idForTesting={`submit-button`}
        title={translations.form.submitButtonTitle}
      >
        {translations.form.submitButtonText}
      </ButtonComponent>
    </div>
  );
};

export default TaxiDriverEditComponent;
