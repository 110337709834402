import { FC, useCallback, useEffect, useMemo, useState } from "react";
import SingleSelectComponent from "../../../../../../common/components/form/select/single-select/single-select.component";
import MileageAddWorkerSelectOption from "../../types/mileage-add-worker-select-option";
import { debounce } from "lodash";
import useAbort from "../../../../../../common/hooks/use-abort";
import MileageAddWorker from "../../types/mileage-add-worker";
import mileageAddWorkerFactory from "../../factory/mileage-add-worker.factory";
import mileageTranslationsHelper from "../../../../../../languages/mileage-translations.helper";
import useCargoCompanyPassengerList from "../../../../../../common/services/cargo-company/passenger/list/use-cargo-company-passenger-list";
import useCargoCompanyDispatcherList from "../../../../../../common/services/cargo-company/dispatcher/list/use-cargo-company-dispatcher-list";
import CargoCompanyPassengerListLoadParams from "../../../../../../common/services/cargo-company/passenger/list/cargo-company-passenger-list-load-params";
import CargoCompanyDispatcherListLoadParams from "../../../../../../common/services/cargo-company/dispatcher/list/cargo-company-dispatcher-list-load-params";
import { MileageAddWorkerListItem } from "../../types/mileage-add-worker-list";
import mileageAddWorkerListFactory from "../../factory/mileage-add-worker-list.factory";

type MileageAddWorkerSelectProps = {
  worker: MileageAddWorker | null;
  onWorkerChange: (worker: MileageAddWorker | null) => void;
  workerCompanyUuid: string | null;
  onBlur: () => void;
  idForTesting?: string;
  hasError?: boolean;
};

const MileageAddWorkerSelectComponent: FC<MileageAddWorkerSelectProps> = (
  props
) => {
  const translations =
    mileageTranslationsHelper.getMileageAddTranslations().workers;

  const [workerSearchQuery, setWorkerSearchQuery] = useState("");
  const [workerSelectOptions, setWorkersSelectOptions] = useState<
    MileageAddWorkerSelectOption[]
  >([]);

  const passengerList = useCargoCompanyPassengerList();
  const passengerListAbort = useAbort();

  const dispatcherList = useCargoCompanyDispatcherList();
  const dispatcherListAbort = useAbort();

  const loadPassengerListDebounced = useCallback(
    debounce((workerCoId: string, searchQuery: string) => {
      const loadParams: CargoCompanyPassengerListLoadParams = {
        companyUuid: workerCoId,
        searchQuery: searchQuery,
      };

      return passengerList.load(loadParams, passengerListAbort.signal);
    }, 500),
    []
  );

  useEffect(() => {
    if (!props.workerCompanyUuid) {
      return;
    }

    loadPassengerListDebounced(props.workerCompanyUuid, workerSearchQuery);

    return () => {
      passengerListAbort.revoke();
      loadPassengerListDebounced.cancel();
    };
  }, [props.workerCompanyUuid, workerSearchQuery, loadPassengerListDebounced]);

  useEffect(() => {
    if (!props.workerCompanyUuid) {
      return;
    }

    const loadParams: CargoCompanyDispatcherListLoadParams = {
      companyUuid: props.workerCompanyUuid,
    };

    dispatcherList.load(loadParams, dispatcherListAbort.signal);

    return () => {
      dispatcherListAbort.revoke();
    };
  }, [props.workerCompanyUuid]);

  useEffect(() => {
    const passengerWorkerList = mileageAddWorkerListFactory.createPassengerList(
      passengerList.data
    );
    const dispatcherWorkerList =
      mileageAddWorkerListFactory.createDispatcherList(
        dispatcherList.data.filter((worker) =>
          worker.displayName
            .toLowerCase()
            .includes(workerSearchQuery.toLowerCase())
        )
      );

    const workers: MileageAddWorkerListItem[] =
      mileageAddWorkerListFactory.combineWorkerLists(
        passengerWorkerList,
        dispatcherWorkerList
      );

    const options: MileageAddWorkerSelectOption[] =
      mileageAddWorkerFactory.createWorkerSelectOptions(workers);

    setWorkersSelectOptions(options);
  }, [passengerList.data, dispatcherList.data]);

  const selectedWorkerSelectOption = useMemo(() => {
    if (!props.worker) {
      return null;
    }

    return mileageAddWorkerFactory.createWorkerSelectOption(props.worker);
  }, [props.worker]);

  const isWorkerListLoading = useMemo(() => {
    if (!props.workerCompanyUuid) {
      return false;
    }
    return passengerList.isLoading || dispatcherList.isLoading;
  }, [passengerList.isLoading, dispatcherList.isLoading]);

  const isWorkersFetchingError = useMemo(() => {
    return passengerList.isError || dispatcherList.isError;
  }, [passengerList.isError, dispatcherList.isError]);

  const isSearchSelectDisabled =
    isWorkersFetchingError || !props.workerCompanyUuid;

  return (
    <>
      <SingleSelectComponent
        classNames={{ root: "mileage_add_passenger_list_item__select" }}
        placeholder={translations.selectPlaceholder}
        value={selectedWorkerSelectOption}
        onChange={(value: MileageAddWorkerSelectOption | null) => {
          if (!value) {
            return;
          }

          props.onWorkerChange(value.value);
        }}
        isLoading={isWorkerListLoading}
        isDisabled={isSearchSelectDisabled}
        options={workerSelectOptions}
        isSearchable
        filterFunction={() => true}
        inputValue={workerSearchQuery}
        onInputChange={setWorkerSearchQuery}
        noOptionsMessage={(inputValue) => {
          if (inputValue) {
            return translations.searchNoOptionsMessage;
          }
          return translations.searchTipMessage;
        }}
        onBlur={props.onBlur}
        idForTesting={props.idForTesting}
        hasError={props.hasError}
      />
    </>
  );
};

export default MileageAddWorkerSelectComponent;
