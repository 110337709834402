import { FormValidationDefinition } from "../../../../../../common/components/form/use-form";
import driverEditCompanyFormValidationService from "./driver-edit-company-form-validation.service";
import DriverEditCompanyFormData from "./types/driver-edit-company-form-data";

const getDefaultCompanyFormData = (): DriverEditCompanyFormData => {
  return {
    apartmentNumber: "",
    companyId: "",
    country: "",
    description: "",
    houseNumber: "",
    name: "",
    nationalCourtRegisterNumber: "",
    street: "",
    taxNumber: "",
    town: "",
    zipCode: "",
  };
};

const getCompanyValidationDefinition =
  (): FormValidationDefinition<DriverEditCompanyFormData> => {
    return {
      companyId: (values) =>
        driverEditCompanyFormValidationService.validateCompanyId(
          values.companyId
        ),
      name: (values) =>
        driverEditCompanyFormValidationService.validateName(values.name),
      nationalCourtRegisterNumber: (values) =>
        driverEditCompanyFormValidationService.validateNationalCourtRegister(
          values.nationalCourtRegisterNumber
        ),
      taxNumber: (values) =>
        driverEditCompanyFormValidationService.validateTaxNumber(
          values.taxNumber
        ),
      street: (values) =>
        driverEditCompanyFormValidationService.validateStreet(values.street),
      houseNumber: (values) =>
        driverEditCompanyFormValidationService.validateHouseNumber(
          values.houseNumber
        ),
      apartmentNumber: (values) =>
        driverEditCompanyFormValidationService.validateApartmentNumber(
          values.apartmentNumber
        ),
      town: (values) =>
        driverEditCompanyFormValidationService.validateTown(values.town),
      country: (values) =>
        driverEditCompanyFormValidationService.validateCountry(values.country),
      zipCode: (values) =>
        driverEditCompanyFormValidationService.validateZipCode(values.zipCode),
      description: (values) =>
        driverEditCompanyFormValidationService.validateDescription(
          values.description
        ),
    };
  };

const driverEditCompanyFormHelper = {
  getDefaultCompanyFormData,
  getCompanyValidationDefinition,
};

export default driverEditCompanyFormHelper;
