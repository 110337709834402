import DriverAddRequest, {
  DriverAddRequestAddress,
  DriverAddRequestAddressType,
  DriverAddRequestCarOwnership,
  DriverAddRequestCitizenship,
  DriverAddRequestDriver,
  DriverAddRequestFormOfEmployment,
  DriverAddRequestLanguage,
  DriverAddRequestTaxiDriverAssociation,
} from "../../../api/raily/driver/add/driver-add.request";
import DriverAddParams, {
  DriverAddParamsAddress,
  DriverAddParamsAddressType,
  DriverAddParamsCarOwnership,
  DriverAddParamsCitizenship,
  DriverAddParamsDriver,
  DriverAddParamsFormOfEmployment,
  DriverAddParamsLanguage,
  DriverAddParamsTaxiDriverAssociation,
} from "./driver-add-params";

const createFormOfEmployment = (
  formOfEmployment: DriverAddParamsFormOfEmployment
): DriverAddRequestFormOfEmployment => {
  switch (formOfEmployment) {
    case DriverAddParamsFormOfEmployment.B2B:
      return DriverAddRequestFormOfEmployment.B2B;
    case DriverAddParamsFormOfEmployment.EMPLOYMENT:
      return DriverAddRequestFormOfEmployment.EMPLOYMENT;
  }
};

const createLanguage = (
  type: DriverAddParamsLanguage
): DriverAddRequestLanguage => {
  switch (type) {
    case DriverAddParamsLanguage.BY:
      return DriverAddRequestLanguage.BY;
    case DriverAddParamsLanguage.CZ:
      return DriverAddRequestLanguage.CZ;
    case DriverAddParamsLanguage.DE:
      return DriverAddRequestLanguage.DE;
    case DriverAddParamsLanguage.LT:
      return DriverAddRequestLanguage.LT;
    case DriverAddParamsLanguage.PL:
      return DriverAddRequestLanguage.PL;
    case DriverAddParamsLanguage.RU:
      return DriverAddRequestLanguage.RU;
    case DriverAddParamsLanguage.SK:
      return DriverAddRequestLanguage.SK;
    case DriverAddParamsLanguage.UA:
      return DriverAddRequestLanguage.UA;
    case DriverAddParamsLanguage.EN:
      return DriverAddRequestLanguage.EN;
    case DriverAddParamsLanguage.ES:
      return DriverAddRequestLanguage.ES;
    case DriverAddParamsLanguage.FR:
      return DriverAddRequestLanguage.FR;
  }
};

const createLanguages = (
  langueages: DriverAddParamsLanguage[]
): DriverAddRequestLanguage[] => {
  const requestLanguages: DriverAddRequestLanguage[] =
    langueages.map(createLanguage);

  return requestLanguages;
};

const createCitizenship = (
  type: DriverAddParamsCitizenship
): DriverAddRequestCitizenship => {
  switch (type) {
    case DriverAddParamsCitizenship.BY:
      return DriverAddRequestCitizenship.BY;
    case DriverAddParamsCitizenship.CZ:
      return DriverAddRequestCitizenship.CZ;
    case DriverAddParamsCitizenship.DE:
      return DriverAddRequestCitizenship.DE;
    case DriverAddParamsCitizenship.LT:
      return DriverAddRequestCitizenship.LT;
    case DriverAddParamsCitizenship.PL:
      return DriverAddRequestCitizenship.PL;
    case DriverAddParamsCitizenship.RU:
      return DriverAddRequestCitizenship.RU;
    case DriverAddParamsCitizenship.SK:
      return DriverAddRequestCitizenship.SK;
    case DriverAddParamsCitizenship.UA:
      return DriverAddRequestCitizenship.UA;
  }
};

const createCarOwnership = (
  type: DriverAddParamsCarOwnership
): DriverAddRequestCarOwnership => {
  switch (type) {
    case DriverAddParamsCarOwnership.BUSINESS:
      return DriverAddRequestCarOwnership.BUSINESS;
    case DriverAddParamsCarOwnership.OWNER:
      return DriverAddRequestCarOwnership.OWNER;
  }
};

const createAddressType = (
  type: DriverAddParamsAddressType
): DriverAddRequestAddressType => {
  switch (type) {
    case DriverAddParamsAddressType.MAILING:
      return DriverAddRequestAddressType.MAILING;
    case DriverAddParamsAddressType.STARTING:
      return DriverAddRequestAddressType.STARTING;
  }
};

const createTaxiDriverAssociation = (
  association: DriverAddParamsTaxiDriverAssociation
): DriverAddRequestTaxiDriverAssociation => {
  if ("taxId" in association) {
    return {
      contractType: createFormOfEmployment(association.contractType),
      taxId: association.taxId,
      regon: association.regon,
      krsNo: association.krsNo,
      fleetPartnerId: association.fleetPartnerId,
      companyName: association.companyName,
      displayName: association.displayName,
      country: association.country,
      town: association.town,
      zipCode: association.zipCode,
      street: association.street,
      houseNo: association.houseNo,
      apartment: association.apartment,
      description: association.description,
      taxiCorporationId: association.taxiCorporationId,
    };
  } else {
    return {
      contractType: createFormOfEmployment(association.contractType),
      displayName: association.displayName,
      taxiCorporationId: association.taxiCorporationId,
      fleetPartnerId: association.fleetPartnerId,
    };
  }
};

const createTaxiDriverAssociations = (
  associations: DriverAddParamsTaxiDriverAssociation[]
): DriverAddRequestTaxiDriverAssociation[] => {
  return associations.map(createTaxiDriverAssociation);
};

const createAddress = (
  address: DriverAddParamsAddress
): DriverAddRequestAddress => {
  return {
    apartment: address.apartment,
    country: address.country,
    description: address.description,
    houseNo: address.houseNo,
    lat: address.lat,
    lon: address.lon,
    street: address.street,
    town: address.town,
    type: createAddressType(address.type),
    zipCode: address.zipCode,
  };
};

const createAddresses = (
  addresses: DriverAddParamsAddress[]
): DriverAddRequestAddress[] => {
  const requestAddresses: DriverAddRequestAddress[] =
    addresses.map(createAddress);

  return requestAddresses;
};

const createDriver = (
  driver: DriverAddParamsDriver
): DriverAddRequestDriver => {
  return {
    addresses: createAddresses(driver.addresses),
    birthDate: driver.birthDate,
    birthPlace: driver.birthPlace,
    carMake: driver.carMake,
    carModel: driver.carModel,
    carOwnership: createCarOwnership(driver.carOwnership),
    carProdYear: driver.carProdYear,
    carRegNo: driver.carRegNo,
    carSeatsNo: driver.carSeatsNo,
    citizenship: createCitizenship(driver.citizenship),
    experience: driver.experience,
    idNumber: driver.idNumber,
    languages: createLanguages(driver.languages),
    taxiDriverAssociations: createTaxiDriverAssociations(
      driver.taxiDriverAssociations
    ),
  };
};

const create = (params: DriverAddParams): DriverAddRequest => {
  return {
    driver: createDriver(params.driver),
    email: params.email,
    firstName: params.firstName,
    lastName: params.lastName,
    mobilePrimary: params.mobilePrimary,
    mobilePrimaryPrefix: params.mobilePrimaryPrefix,
    mobileSecondary: params.mobileSecondary,
    mobileSecondaryPrefix: params.mobileSecondaryPrefix,
    password: params.password,
    username: params.username,
  };
};

const driverAddRequestFactory = {
  create,
};

export default driverAddRequestFactory;
