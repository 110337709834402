import { FC } from "react";
import FormValidationResult from "../../../../../../../common/utils/validation/types/form-validation-result";
import DriverEditCompanyFormData from "../../form/types/driver-edit-company-form-data";
import userTranslationsHelper from "../../../../../../../languages/user-translations.helper";
import Row from "../../../../../../../common/components/grid/row";
import Column from "../../../../../../../common/components/grid/column";
import DriverEditCompanyDataComponent from "./data/driver-edit-company-data.component";
import DriverEditCompanyAddressComponent from "./address/driver-edit-company-address.component";

type DriverEditCompanyDataProps = {
  validateCompanyData: (
    formKey: keyof DriverEditCompanyFormData,
    value?: unknown
  ) => Promise<boolean>;
  onCompanyDataChange: (
    formKey: keyof DriverEditCompanyFormData,
    value: any
  ) => void;
  companyValidationResults: Record<
    keyof DriverEditCompanyFormData,
    FormValidationResult
  >;
  companyFormValues: DriverEditCompanyFormData;
};

const RailyDriverEditCompanyDataComponent: FC<DriverEditCompanyDataProps> = (
  props
) => {
  const translations =
    userTranslationsHelper.getDriverEditTranslations().form.companyData;

  return (
    <Row>
      <Column lg={6}>
        <>
          <div className="driver_edit_company_heading_text">
            {translations.headingText}
          </div>
          <DriverEditCompanyDataComponent
            companyFormValues={props.companyFormValues}
            companyValidationResults={props.companyValidationResults}
            onCompanyDataChange={props.onCompanyDataChange}
            validateCompanyData={props.validateCompanyData}
          />
        </>
      </Column>
      <Column lg={6}>
        <>
          <div className="driver_edit_company_heading_text">
            {translations.address.headingText}
          </div>
          <DriverEditCompanyAddressComponent
            companyFormValues={props.companyFormValues}
            companyValidationResults={props.companyValidationResults}
            onCompanyDataChange={props.onCompanyDataChange}
            validateCompanyData={props.validateCompanyData}
          />
        </>
      </Column>
    </Row>
  );
};

export default RailyDriverEditCompanyDataComponent;
