import CargoTaxiContractUpdateParams from "../../../../../common/services/cargo-taxi-contract/update/cargo-taxi-contract-update-params";
import TaxiCargoContractEditFormData from "./types/taxi-cargo-contract-edit-form-data";

const create = (
  formData: TaxiCargoContractEditFormData,
  contractUuid: string
): CargoTaxiContractUpdateParams => {
  return {
    contractUuid: contractUuid,
    cancelOrderByTaxiAllowed: formData.cancelOrderByTaxiAllowed,
    contactPassengerAfterMinutes: formData.contactPassengerAfterMinutes,
    editOrderByTaxiAllowed: formData.editOrderByTaxiAllowed,
    isActive: formData.isActive!,
  };
};

const taxiCargoContractEditFormRequestFactory = {
  create,
};

export default taxiCargoContractEditFormRequestFactory;
