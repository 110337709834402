import HttpResponse from "../../../../utils/http/http.response";

export enum DriverEditFormResponseFormOfEmployment {
  B2B = "B2B",
  EMPLOYMENT = "EMPLOYMENT",
}

export enum DriverEditFormResponseCitizenship {
  PL = "PL",
  DE = "DE",
  SK = "SK",
  CZ = "CZ",
  UA = "UA",
  RU = "RU",
  BY = "BY",
  LT = "LT",
}

export enum DriverEditFormResponseLanguage {
  PL = "PL",
  EN = "EN",
  DE = "DE",
  FR = "FR",
  ES = "ES",
  SK = "SK",
  CZ = "CZ",
  UA = "UA",
  RU = "RU",
  BY = "BY",
  LT = "LT",
}

export enum DriverEditFormResponseCarOwnership {
  OWNER = "OWNER",
  BUSINESS = "BUSINESS",
}

export type DriverEditFormResponseUser = {
  username: string;
  firstName: string;
  lastName: string;
  mobilePrimaryPrefix: string;
  mobilePrimary: string;
  mobileSecondaryPrefix: string;
  mobileSecondary: string;
  email: string;
};

export type DriverEditFormResponseFleetPartner = {
  id: string;
  modifiedAt: string;
  modifiedBy: string;
  createdAt: string;
  createdBy: string;
  taxiCorporationId: string;
  name: string;
};

export type DriverEditFormResponseTaxiCorporation = {
  taxId: string;
  regon: string | null;
  krsNo: string;
  companyName: string;
  displayName: string;
  headquartersAddressString: string;
  contractMaintenancePolicy: string;
  notes: string;
  id: string;
  modifiedAt: string;
  modifiedBy: string;
  createdAt: string;
  createdBy: string;
  fleetPartners: DriverEditFormResponseFleetPartner[];
};

export type DriverEditFormResponseAssociation = {
  id: string;
  modifiedAt: string;
  modifiedBy: string;
  createdAt: string;
  createdBy: string;
  contractType: DriverEditFormResponseFormOfEmployment;
  taxId: string;
  regon: string | null;
  krsNo: string;
  companyName: string;
  fleetPartnerId: string;
  country: string;
  town: string;
  zipCode: string;
  street: string;
  houseNo: string;
  apartment: string;
  description: string | null;
  taxiCorporation: DriverEditFormResponseTaxiCorporation;
  fleetPartner: DriverEditFormResponseFleetPartner | null;
};

export type DriverEditFormResponseAddress = {
  lat: number;
  lon: number;
  country: string;
  town: string;
  zipCode: string;
  street: string;
  houseNo: string;
  apartment: string;
  description: string;
  displayName: string;
};

export type DriverEditFormResponseDriver = {
  birthDate: string;
  birthPlace: string;
  idNumber: string;
  citizenship: DriverEditFormResponseCitizenship;
  languages: DriverEditFormResponseLanguage[];
  experience: number;
  carOwnership: DriverEditFormResponseCarOwnership;
  carMake: string;
  carModel: string;
  carRegNo: string;
  carProdYear: number;
  carSeatsNo: number;
  mobileModel: string;
};

export type DriverEditFormResponseItem = {
  driver: DriverEditFormResponseDriver;
  user: DriverEditFormResponseUser;
  associations: DriverEditFormResponseAssociation[];
  startingAddress: DriverEditFormResponseAddress;
  mailingAddress: DriverEditFormResponseAddress;
};

type DriverEditFormResponse = HttpResponse<DriverEditFormResponseItem>;

export default DriverEditFormResponse;
