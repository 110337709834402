import { FC, useEffect, useState } from "react";
import DriverAddBasicProps from "../common/types/driver-add-basic-props";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import Row from "../../../../../common/components/grid/row";
import Column from "../../../../../common/components/grid/column";
import DriverAddAccountDataComponent from "../common/components/account/driver-add-account-data.component";
import RailyDriverAddUserDataComponent from "../common/components/user-data/raily-driver-add-user-data.component";
import DriverAddVehicleDataComponent from "../common/components/vehicle/driver-add-vehicle.component";
import ButtonComponent from "../../../../../common/components/button/button.component";
import useForm from "../../../../../common/components/form/use-form";
import DriverAddFormOfEmploymentType from "../common/types/driver-add-form-of-employment-type";
import useAbort from "../../../../../common/hooks/use-abort";
import { useAppContext } from "../../../../../context/app.context";
import userBreadcrumbsHelper from "../../../common/breadcrumbs/user-breadcrumbs.helper";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../../common/hooks/use-document-title";
import { useNavigate } from "react-router-dom";
import userRoutesHelper from "../../../common/routes/user-routes.helper";
import notificationService from "../../../../../common/utils/notification/notification.service";
import driverAddAccountFormHelper from "../common/form/driver-add-account-data-form.helper";
import driverAddVehicleFormHelper from "../common/form/driver-add-vehicle-form.helper";
import driverService from "../../../../../common/services/driver/driver.service";
import driverAddTaxiCorporationHelper from "../common/form/driver-add-taxi-corporation-form.helper";
import DriverAddTaxiCorporationComponent from "../common/components/taxi-corporation/driver-add-taxi-corporation.component";
import DriverAddTaxiCorporationFormData from "../common/form/types/driver-add-taxi-corporation-form-data";
import railyDriverAddParamsFactory from "../common/factory/raily-driver-add-params.factory";
import FormValidationResult from "../../../../../common/utils/validation/types/form-validation-result";
import railyDriverAddUserFormHelper from "../common/form/raily-driver-add-user-data-form.helper";

type RailyDriverAddProps = DriverAddBasicProps;

const RailyDriverAddComponent: FC<RailyDriverAddProps> = (props) => {
  const translations = userTranslationsHelper.getDriverAddTranslations();

  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  const navigate = useNavigate();

  useEffect(() => {
    const breadcrumbs = userBreadcrumbsHelper.getDriverAddBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.userDriverAdd);

  const abortSignal = useAbort();

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const [formTaxiCorporationsData, setFormTaxiCorporationsData] = useState<
    DriverAddTaxiCorporationFormData[]
  >([driverAddTaxiCorporationHelper.getDefaultTaxiCorporationData()]);

  const formTaxiCorporationData = useForm({
    emptyValues: driverAddTaxiCorporationHelper.getDefaultTaxiCorporationData(),
    validationDefinition:
      driverAddTaxiCorporationHelper.getTaxiCorporationValidationDefinition(),
  });

  const [
    formTaxiCorporationValidationResults,
    setFormTaxiCorporationValidationResults,
  ] = useState<
    Record<keyof DriverAddTaxiCorporationFormData, FormValidationResult>[]
  >([{ ...formTaxiCorporationData.validationResults }]);

  const formAccountFormData = useForm({
    emptyValues: driverAddAccountFormHelper.getDefaultAccountFormData(),
    validationDefinition:
      driverAddAccountFormHelper.getAccountValidationDefinition(),
  });

  const formUserFormData = useForm({
    emptyValues: railyDriverAddUserFormHelper.getDefaultUserFormData(),
    validationDefinition:
      railyDriverAddUserFormHelper.getUserValidationDefinition(),
  });

  const addTaxiCorporation = () => {
    const newEmptyTaxiCorporation =
      driverAddTaxiCorporationHelper.getDefaultTaxiCorporationData();

    const newTaxiCorporations = [
      ...formTaxiCorporationsData,
      newEmptyTaxiCorporation,
    ];

    const newTaxiCorporationValidationResults = [
      ...formTaxiCorporationValidationResults,
      { ...formTaxiCorporationData.validationResults },
    ];

    setFormTaxiCorporationsData(newTaxiCorporations);
    setFormTaxiCorporationValidationResults(
      newTaxiCorporationValidationResults
    );
  };

  const deleteTaxiCorporation = (indexToRemove: number) => {
    const updatedTaxiCorporations = formTaxiCorporationsData.filter(
      (_taxiCorporation, index) => index !== indexToRemove
    );

    const updatedTaxiCorporationValidationResults =
      formTaxiCorporationValidationResults.filter(
        (_validationResult, index) => index !== indexToRemove
      );

    setFormTaxiCorporationsData(updatedTaxiCorporations);
    setFormTaxiCorporationValidationResults(
      updatedTaxiCorporationValidationResults
    );
  };

  const setValue = (
    formKey: keyof DriverAddTaxiCorporationFormData,
    value: DriverAddTaxiCorporationFormData[keyof DriverAddTaxiCorporationFormData],
    index: number
  ) => {
    const newData = { ...formTaxiCorporationsData[index], [formKey]: value };
    const newTaxiCorporationsData = formTaxiCorporationsData.map((item, i) =>
      i === index ? newData : item
    );

    setFormTaxiCorporationsData(newTaxiCorporationsData);
  };

  const validateField = async (
    formKey: keyof DriverAddTaxiCorporationFormData,
    index: number,
    overrideValue?: any
  ): Promise<boolean> => {
    const currentData = formTaxiCorporationsData[index];

    const updatedData =
      overrideValue !== undefined
        ? { ...currentData, [formKey]: overrideValue }
        : currentData;

    const validationResult = await driverAddTaxiCorporationHelper
      .getTaxiCorporationValidationDefinition()
      [formKey](updatedData);

    const results = {
      ...formTaxiCorporationValidationResults[index],
      [formKey]: validationResult,
    };

    const newValidationResults = formTaxiCorporationValidationResults.map(
      (item, i) => (i === index ? results : item)
    );

    setFormTaxiCorporationValidationResults(newValidationResults);

    return validationResult.isValid;
  };

  const validateTaxiCorporationData = async (
    data: DriverAddTaxiCorporationFormData,
    index: number
  ): Promise<boolean> => {
    const validationDefinition =
      driverAddTaxiCorporationHelper.getTaxiCorporationValidationDefinition();
    let isValid = true;

    const updatedResults = { ...formTaxiCorporationValidationResults[index] };

    for (const key in validationDefinition) {
      const fieldKey = key as keyof DriverAddTaxiCorporationFormData;
      const result = await validationDefinition[fieldKey](data);
      updatedResults[fieldKey] = result;
      if (!result.isValid) {
        isValid = false;
      }
    }

    formTaxiCorporationValidationResults[index] = updatedResults;
    setFormTaxiCorporationValidationResults(
      formTaxiCorporationValidationResults
    );

    return isValid;
  };

  const formVehicleFormData = useForm({
    emptyValues: driverAddVehicleFormHelper.getDefaultVehicleFormData(),
    validationDefinition:
      driverAddVehicleFormHelper.getVehicleValidationDefinition(),
  });

  const navigateToListing = () => {
    const dealerListingRoute = userRoutesHelper.getDriverListingRoute();

    navigate(dealerListingRoute);
  };

  const onDriverAddSuccess = () => {
    notificationService.success(translations.successAddNotificationText);

    navigateToListing();
  };

  const onDriverAddFailure = () => {
    notificationService.error(translations.failureAddNotificationText);
  };

  const submitForm = async () => {
    const isFormAccountFormDataValid = await formAccountFormData.validateAll();
    const isFormUserFormDataValid = await formUserFormData.validateAll();
    const isFormVehicleFormDataValid = await formVehicleFormData.validateAll();

    const taxiValidationResults = await Promise.all(
      formTaxiCorporationsData.map((data, index) =>
        validateTaxiCorporationData(data, index)
      )
    );

    const isFormTaxiCorporationDataValid = taxiValidationResults.every(
      (value) => value
    );

    if (
      !isFormAccountFormDataValid ||
      !isFormUserFormDataValid ||
      !isFormTaxiCorporationDataValid ||
      !isFormVehicleFormDataValid
    ) {
      return;
    }

    const params = railyDriverAddParamsFactory.createSubmitFormParams(
      formAccountFormData.values,
      formUserFormData.values,
      formVehicleFormData.values,
      formTaxiCorporationsData
    );

    try {
      setIsFormSubmitting(true);
      await driverService.addDriver(params, abortSignal.signal);
      onDriverAddSuccess();
    } catch {
      onDriverAddFailure();
    } finally {
      setIsFormSubmitting(false);
    }
  };

  return (
    <>
      <HeadingComponent
        title={translations.header.headingText}
        actions={props.changeViewButtons}
      />
      <Row>
        <Column withPaddings>
          <DriverAddAccountDataComponent
            accountFormValues={formAccountFormData.values}
            onAccountDataChange={formAccountFormData.setValue}
            validateAccountData={formAccountFormData.validate}
            accountValidationResults={formAccountFormData.validationResults}
          />
        </Column>
      </Row>
      <Row>
        <Column withPaddings>
          <RailyDriverAddUserDataComponent
            onUserDataChange={formUserFormData.setValue}
            validateUserData={formUserFormData.validate}
            userFormValues={formUserFormData.values}
            userValidationResults={formUserFormData.validationResults}
          />
        </Column>
      </Row>
      {formTaxiCorporationsData.map((form, index) => (
        <Row key={index}>
          <Column withPaddings>
            <DriverAddTaxiCorporationComponent
              onTaxiCorporationChange={(formKey, value) =>
                setValue(formKey, value, index)
              }
              validateTaxiCorporation={(formKey, value) =>
                validateField(formKey, index, value)
              }
              taxiCorporationFormValues={form}
              taxiCorporationValidationResults={
                formTaxiCorporationValidationResults[index]
              }
              isCargoDataSectionVisible={
                form.formOfEmployment === DriverAddFormOfEmploymentType.B2B
              }
              index={index}
              addTaxiCorporation={addTaxiCorporation}
              deleteTaxiCorporation={deleteTaxiCorporation}
              formTaxiCorporationsData={formTaxiCorporationsData}
            />
          </Column>
        </Row>
      ))}
      <Row>
        <Column withPaddings>
          <DriverAddVehicleDataComponent
            onVehicleDataChange={formVehicleFormData.setValue}
            validateVehicleData={formVehicleFormData.validate}
            carOwnershipType={formVehicleFormData.values.ownership!}
            vehicleFormValues={formVehicleFormData.values}
            vehicleValidationResults={formVehicleFormData.validationResults}
          />
        </Column>
      </Row>
      <ButtonComponent
        onClick={submitForm}
        type="primary"
        isLoading={isFormSubmitting}
        classNames={{ root: "mt-2" }}
        idForTesting={`submit-button`}
        title={translations.form.submitButtonTitle}
      >
        {translations.form.submitButtonText}
      </ButtonComponent>
    </>
  );
};

export default RailyDriverAddComponent;
