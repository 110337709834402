import { round } from "lodash";
import { FC, useState, useEffect } from "react";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import CardComponent from "../../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../../common/components/form/field/form-field.component";
import Column from "../../../../../../common/components/grid/column";
import Row from "../../../../../../common/components/grid/row";
import MapComponent from "../../../../../../common/components/map/map.component";
import MapMarker from "../../../../../../common/components/map/types/map-marker";
import MapRoute from "../../../../../../common/components/map/types/map-route";
import useAbort from "../../../../../../common/hooks/use-abort";
import useDocumentTitle from "../../../../../../common/hooks/use-document-title";
import MileageAddParams from "../../../../../../common/services/mileage/add/mileage-add-params";
import mileageService from "../../../../../../common/services/mileage/mileage.service";
import useMileageRoadRoute from "../../../../../../common/services/mileage/road-route/use-mileage-road-route";
import appTranslationsHelper from "../../../../../../languages/app-translations.helper";
import mileageTranslationsHelper from "../../../../../../languages/mileage-translations.helper";
import mileageAddParamsFactory from "../../factory/mileage-add-params.factory";
import MileageAddSuccessModalComponent from "../add-success-modal/mileage-add-success-modal.component";
import mileageAddSummaryFactory from "./mileage-add-summary.factory";
import mileageAddSummaryHelper from "./mileage-add-summary.helper";
import MileageAddSummaryRouteComponent from "./route/mileage-add-summary-route.component";
import MileageAddSummaryRouteWaypoint from "./route/types/mileage-add-summary-route-waypoint";
import mileageAddSummaryRouteFactory from "./route/factory/mileage-add-summary-route.factory";
import useOpen from "../../../../../../common/hooks/use-open";
import MileageAddFormData from "../../form/types/mileage-add-form-data";
import notificationService from "../../../../../../common/utils/notification/notification.service";
import CargoCompanyMileageContractDetails from "../../../../../../common/services/cargo-company/mileage-contract/details/cargo-company-mileage-contract-details";

type MileageAddSummaryProps = {
  workerName: string | undefined;
  companyName: string;
  formData: MileageAddFormData;
  restoreForm: () => void;
  validateForm: () => Promise<boolean>;
  onRouteFetched: (distance: number) => void;
  mileageContract: CargoCompanyMileageContractDetails | null;
};

const MileageAddSummaryComponent: FC<MileageAddSummaryProps> = (props) => {
  const translations =
    mileageTranslationsHelper.getMileageAddTranslations().summary;

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.mileageAdd);

  const { isOpen, open, close } = useOpen();

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const formSubmitAbort = useAbort();

  const roadRoute = useMileageRoadRoute();

  const [waypoints, setWaypoints] = useState<MileageAddSummaryRouteWaypoint[]>(
    []
  );

  const mapMarkers: MapMarker[] =
    mileageAddSummaryRouteFactory.createMapMarkers(waypoints);

  const [mapRoutes, setMapRoutes] = useState<MapRoute[]>([]);

  const [routeLegDistances, setRouteLegDistances] = useState<number[]>([]);

  const [routingLegDurations, setRoutingLegDurations] = useState<number[]>([]);

  useEffect(() => {
    const preparedWaypoints = mileageAddSummaryFactory.createWaypoints(
      props.formData.addressSequence
    );

    setWaypoints(preparedWaypoints);

    if (preparedWaypoints.length > 1) {
      const request =
        mileageAddSummaryFactory.createSearchRoutingRequest(preparedWaypoints);
      roadRoute.load(request);
    } else {
      setMapRoutes([]);
    }
  }, [JSON.stringify(props.formData.addressSequence)]);

  useEffect(() => {
    if (!roadRoute.data) {
      return;
    }

    const route = mileageAddSummaryRouteFactory.createMapRoute(
      roadRoute.data.coordinates
    );
    setMapRoutes([route]);

    const fixedWaypoints = [...waypoints];
    let totalDistance = 0;
    const tempRouteLegDistances: number[] = [];

    roadRoute.data.legs.forEach((leg, index) => {
      tempRouteLegDistances.push(round(leg.distance / 1000));
      fixedWaypoints[index + 1].distance = leg.distance;
      totalDistance += leg.distance;
    });

    setRouteLegDistances(tempRouteLegDistances);
    setWaypoints(fixedWaypoints);
    props.onRouteFetched(round(totalDistance / 1000));

    setRoutingLegDurations(roadRoute.data.legs.map((leg) => leg.duration));
  }, [roadRoute.data]);

  useEffect(() => {
    if (
      !routingLegDurations ||
      routingLegDurations.length !== waypoints.length - 1
    )
      return;

    const fixedWaypoints = [...waypoints];

    fixedWaypoints[0].time = props.formData.mileageDate;

    routingLegDurations.forEach((duration, index) => {
      fixedWaypoints[index + 1].time = new Date(
        fixedWaypoints[index].time?.getTime()! + 1000 * duration
      );
    });

    setWaypoints(fixedWaypoints);
  }, [routingLegDurations, JSON.stringify(props.formData.mileageDate)]);

  const onAddMileageSuccessModalCloseButtonClick = () => {
    props.restoreForm();
    close();
    setMapRoutes([]);
  };

  const onSubmitButtonClick = async () => {
    const isFormValid = await props.validateForm();

    if (!isFormValid) {
      return;
    }

    const params: MileageAddParams = mileageAddParamsFactory.create(
      props.formData,
      routingLegDurations,
      routeLegDistances
    );

    setIsFormSubmitting(true);

    mileageService
      .add(params, formSubmitAbort.signal)
      .then(() => {
        open();
      })
      .catch(() => {
        notificationService.error(translations.failureAddNotificationLabel);
      })
      .finally(() => {
        setIsFormSubmitting(false);
      });
  };

  return (
    <>
      <CardComponent
        header={{ title: translations.headingLabel }}
        classNames={{ root: "mileage_add_summary" }}
      >
        <div className="mileage_add_summary__map_wrapper">
          <MapComponent
            markers={mapMarkers}
            autoFit
            autoFitOnUpdate
            routes={mapRoutes}
          />
        </div>
        <Row>
          <Column sm={6}>
            <FormFieldComponent label={translations.companyLabel}>
              <span>
                {mileageAddSummaryHelper.getCargoCompanyLabel(
                  props.companyName
                )}
              </span>
            </FormFieldComponent>
            <FormFieldComponent label={translations.passengerLabel}>
              <span>
                {mileageAddSummaryHelper.getWorkerLabel(props.workerName)}
              </span>
            </FormFieldComponent>
          </Column>
          <Column sm={6}>
            <FormFieldComponent label={translations.mileageNumberLabel}>
              <span>
                {mileageAddSummaryHelper.getMileageNumberLabel(
                  props.formData.mileageNumber
                )}
              </span>
            </FormFieldComponent>
            <FormFieldComponent label={translations.cardNumberLabel}>
              <span>
                {mileageAddSummaryHelper.getCardNumberLabel(
                  props.formData.cardNumber
                )}
              </span>
            </FormFieldComponent>
          </Column>
        </Row>
        <FormFieldComponent label={translations.routeLabel}>
          <MileageAddSummaryRouteComponent waypoints={waypoints} />
        </FormFieldComponent>
        <Row>
          <Column>
            <FormFieldComponent
              label={translations.orderNumberLabel}
              classNames={{
                content:
                  "mileage_add_summary__mileage_number_form_field_content",
              }}
            >
              {mileageAddSummaryHelper.getCommissionNumberLabel(
                props.formData.commissionNumber
              )}
            </FormFieldComponent>
          </Column>
        </Row>
        <ButtonComponent
          onClick={onSubmitButtonClick}
          type="primary"
          classNames={{ root: "w-100" }}
          isLoading={isFormSubmitting}
          idForTesting="mileage-add-summary-submit-button"
          isDisabled={!props.mileageContract}
        >
          {!!props.mileageContract
            ? translations.submitButtonLabel
            : translations.submitButtonNoContractLabel}
        </ButtonComponent>
      </CardComponent>
      <MileageAddSuccessModalComponent
        isOpen={isOpen}
        onCloseClick={onAddMileageSuccessModalCloseButtonClick}
      />
    </>
  );
};

export default MileageAddSummaryComponent;
