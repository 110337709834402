import TaxiCorporationListResponse, {
  TaxiCorporationListResponseItemContractMaintenancePolicy,
  TaxiCorporationListResponseItem,
} from "../../../api/raily/taxi-corporation/list/taxi-corporation-list.response";
import ContractMaintenancePolicy from "../../../types/contract-maintenance-policy";
import TaxiCorporationList, {
  TaxiCorporationListItem,
} from "./taxi-corporation-list";

const createContractMaintenancePolicy = (
  contractMaintenancePolicy: TaxiCorporationListResponseItemContractMaintenancePolicy
): ContractMaintenancePolicy => {
  switch (contractMaintenancePolicy) {
    case TaxiCorporationListResponseItemContractMaintenancePolicy.AUCTION:
      return ContractMaintenancePolicy.AUCTION;
    case TaxiCorporationListResponseItemContractMaintenancePolicy.BASIC:
      return ContractMaintenancePolicy.BASIC;

    default:
      return ContractMaintenancePolicy.BASIC;
  }
};

const createDataItem = (
  responseDataItem: TaxiCorporationListResponseItem
): TaxiCorporationListItem => {
  return {
    uuid: responseDataItem.id,
    name: responseDataItem.companyName,
    contractMaintenancePolicy: createContractMaintenancePolicy(
      responseDataItem.contractMaintenancePolicy
    ),
    displayName: responseDataItem.displayName,
    address: responseDataItem.headquartersAddressString,
    nationalCourtRegister: responseDataItem.krsNo,
    notes: responseDataItem.notes,
    companyId: responseDataItem.regon,
    taxNumber: responseDataItem.taxId,
    creationDate: responseDataItem.createdAt,
    isCsi: responseDataItem.isCsi ?? false,
  };
};

const createData = (
  responseData: TaxiCorporationListResponseItem[]
): TaxiCorporationListItem[] => {
  return responseData.map(createDataItem);
};

const create = (response: TaxiCorporationListResponse): TaxiCorporationList => {
  return {
    data: createData(response.data.data),
    totalCount: response.data.totalCount,
  };
};

const taxiCorporationListFactory = {
  create,
};

export default taxiCorporationListFactory;
