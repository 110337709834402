import { ReactNode } from "react";
import DriverEditAddress from "../types/driver-edit-address";
import MapMarker from "../../../../../../common/components/map/types/map-marker";
import mapMarkerIconFactory from "../../../../../../common/components/map/marker/map-marker-icon.factory";
import driverEditUserDataAddressHelper from "../components/user-data/address/driver-edit-user-data-address.helper";
import DriverEditAddressType from "../types/driver-edit-address-type";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faHome } from "@fortawesome/free-solid-svg-icons";

const createMapMarker = (
  address: DriverEditAddress,
  content?: ReactNode
): MapMarker => {
  const icon = mapMarkerIconFactory.createIcon({
    content,
    className: "map_marker standard",
  });

  return {
    coordinate: {
      latitude: address.latitude,
      longitude: address.longitude,
    },
    title: driverEditUserDataAddressHelper.getAddressLabel(address),
    tooltip: driverEditUserDataAddressHelper.getAddressLabel(address),
    icon,
  };
};

const getIconForAddressType = (type: DriverEditAddressType) => {
  switch (type) {
    case DriverEditAddressType.STARTING:
      return (
        <FontAwesomeIcon
          icon={faHome}
          className="driver_edit_user_add_address__map_marker_signature_icon"
        />
      );
    case DriverEditAddressType.MAILING:
      return (
        <FontAwesomeIcon
          icon={faEnvelope}
          className="driver_edit_user_add_address__map_marker_signature_icon"
        />
      );
    default:
      return undefined;
  }
};

const createMapMarkers = (addresses: DriverEditAddress[]): MapMarker[] => {
  return addresses.map((address) =>
    createMapMarker(address, getIconForAddressType(address.type))
  );
};

const driverEditUserDataAddressFactory = {
  createMapMarkers,
  createMapMarker,
};

export default driverEditUserDataAddressFactory;
