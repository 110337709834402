import { FC } from "react";
import { faMapLocation } from "@fortawesome/free-solid-svg-icons";
import RouteDetailsToolButtonComponent from "../../tool-button/route-details-tool-button.component";
import RouteDetailsToolsTranslations from "../../../../../../../languages/types/route/details/route-details-tools.translations";

type RouteDetailsCompletedRouteComponentProps = {
  tools: RouteDetailsToolsTranslations;
  isLoading: boolean;
  isActive: boolean;
  onSuccess: () => void;
};

const RouteDetailsCompletedRouteComponent: FC<
  RouteDetailsCompletedRouteComponentProps
> = (props) => {
  const { hideCompletionRouteButtonTitle, showCompletionRouteButtonTitle } =
    props.tools;

  const title = props.isActive
    ? hideCompletionRouteButtonTitle
    : showCompletionRouteButtonTitle;

  return (
    <RouteDetailsToolButtonComponent
      onClick={props.onSuccess}
      icon={faMapLocation}
      title={title}
      isActive={props.isActive}
      isLoading={props.isLoading}
    />
  );
};

export default RouteDetailsCompletedRouteComponent;
