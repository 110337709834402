import { FC, useEffect, useMemo } from "react";
import driverDetailsFinishedRoutePlansPlanEntryFactory from "./common/driver-details-finished-route-plans-plan-entry.factory";
import LoaderComponent from "../../../../../common/components/loader/loader.component";
import DriverDetailsFinishedRoutePlansContentComponent from "./driver-details-finished-route-plans-content.component";
import { useParams } from "react-router-dom";
import UserDriverFinishedPlanRouteParams from "../../../common/routes/types/user-driver-finished-plan-route-params";
import useAbort from "../../../../../common/hooks/use-abort";
import usePlanEntryDetails from "../../../../../common/services/plan-entry/details/use-plan-entry-details";
import PlanEntryDetailsLoadParams from "../../../../../common/services/plan-entry/details/plan-entry-details-load-params";

type DriverDetailsPlanProps = {};

const DriverDetailsFinishedRoutePlansComponent: FC<DriverDetailsPlanProps> = (
  props
) => {
  const { planEntryUuid } = useParams<UserDriverFinishedPlanRouteParams>();

  const planEntryDetails = usePlanEntryDetails();
  const abort = useAbort();

  const planEntryDetailsData = useMemo(() => {
    if (!planEntryDetails.data) {
      return undefined;
    }
    return driverDetailsFinishedRoutePlansPlanEntryFactory.createPlanEntry(
      planEntryDetails.data
    );
  }, [planEntryDetails.data]);

  const loadPlanEntryDetails = async () => {
    if (!planEntryUuid) {
      return;
    }

    const params: PlanEntryDetailsLoadParams = {
      planEntryUuid: planEntryUuid,
    };

    planEntryDetails.load(params, abort.signal);
  };

  useEffect(() => {
    loadPlanEntryDetails();

    return abort.revoke;
  }, [planEntryUuid]);

  if (planEntryDetails.isLoading && !planEntryDetails.data) {
    return (
      <div className="driver_details_finished_route_plans_loader_wrapper">
        <LoaderComponent type="primary" />
      </div>
    );
  }

  return (
    <DriverDetailsFinishedRoutePlansContentComponent
      planEntry={planEntryDetailsData}
      refetchPlan={loadPlanEntryDetails}
      asCardComponent={true}
    />
  );
};

export default DriverDetailsFinishedRoutePlansComponent;
