import mileageTranslationsHelper from "../../../../languages/mileage-translations.helper";
import MileageListingActionData from "../common/types/mileage-listing-action-data";

const getDataLabel = (data: MileageListingActionData[]): string => {
  const translations =
    mileageTranslationsHelper.getMileageListingTranslations().generate;

  return translations.joinedMileageTemplateLabel.replace(
    "#{internalMileageIds}",
    data.length === 0
      ? translations.mileageNotSelectedLabel
      : data.map((source) => source.humanId).join(", ")
  );
};

const mileageListingActionsModalHelper = { getDataLabel };

export default mileageListingActionsModalHelper;
