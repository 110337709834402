import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import {
  DriverPlanCandidatureAddByOrderIdNotAcceptableResponseCandidateDoesNotFitError,
  DriverPlanCandidatureAddByOrderIdNotAcceptableResponseCandidateFitsWithNotEnoughTimeError,
  DriverPlanCollisionSubjectDirectionResponse,
} from "../../../api/raily/driver-plan-candidature/add-by-order-id/driver-plan-candidature-add-by-order-id.response";
import {
  DriverHasCandidatureError,
  DriverPlanCandidateFitsWithNotEnoughTimeError,
  DriverPlanCandidatureAddByOrderIdErrorType,
  DriverPlanCandidatureDoesNotFitError,
  DriverPlanCollisionSubjectDirection,
  DriverPlanEntryCandidateCollisionError,
  DriverPlanOrderInProgressError,
  DriverPlanOrderNotExistsError,
  DriverPlanPlanEntryCandidateStartTimeExpiredError,
  DriverPlanSolvedOrderCandidateStartTimeExpiredError,
  DriverPlanTaxiDriverAssociationNotExistsError,
  DriverPlanTaxiDriverContractNotValidError,
  DriverPlanTaxiRailyContractNotExistsError,
} from "./driver-plan-candidature-add-by-order-id-error";

const createDriverHasCandidatureError = (): DriverHasCandidatureError => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().manageDriverAssignment
      .apiErrors;

  return {
    message: translations.driverHasCandidatureErrorLabel,
    params: {},
    type: DriverPlanCandidatureAddByOrderIdErrorType.DRIVER_HAS_CANDIDATURE,
  };
};

const createPlanEntryCandidateCollisionError =
  (): DriverPlanEntryCandidateCollisionError => {
    const translations =
      orderTranslationsHelper.getDetailsTranslations().manageDriverAssignment
        .apiErrors;

    return {
      message: translations.planEntryCandidateCollisionErrorLabel,
      params: {},
      type: DriverPlanCandidatureAddByOrderIdErrorType.PLAN_ENTRY_CANDIDATE_COLLISION,
    };
  };

const createTaxiDriverAssociationNotExistsError =
  (): DriverPlanTaxiDriverAssociationNotExistsError => {
    const translations =
      orderTranslationsHelper.getDetailsTranslations().manageDriverAssignment
        .apiErrors;

    return {
      message: translations.taxiDriverAssociationNotExistsErrorLabel,
      params: {},
      type: DriverPlanCandidatureAddByOrderIdErrorType.TAXI_DRIVER_ASSOCIATION_NOT_EXISTS,
    };
  };

const createOrderNotExistsError = (): DriverPlanOrderNotExistsError => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().manageDriverAssignment
      .apiErrors;

  return {
    message: translations.orderNotExistsErrorLabel,
    params: {},
    type: DriverPlanCandidatureAddByOrderIdErrorType.ORDER_NOT_EXISTS,
  };
};

const createOrderInProgressError = (): DriverPlanOrderInProgressError => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().manageDriverAssignment
      .apiErrors;

  return {
    message: translations.orderInProgressErrorLabel,
    params: {},
    type: DriverPlanCandidatureAddByOrderIdErrorType.ORDER_IN_PROGRESS,
  };
};

const createTaxiDriverContractNotValidError =
  (): DriverPlanTaxiDriverContractNotValidError => {
    const translations =
      orderTranslationsHelper.getDetailsTranslations().manageDriverAssignment
        .apiErrors;

    return {
      message: translations.taxiDriverContractNotValidErrorLabel,
      params: {},
      type: DriverPlanCandidatureAddByOrderIdErrorType.TAXI_DRIVER_CONTRACT_NOT_VALID,
    };
  };

const createTaxiRailyContractNotValidError =
  (): DriverPlanTaxiRailyContractNotExistsError => {
    const translations =
      orderTranslationsHelper.getDetailsTranslations().manageDriverAssignment
        .apiErrors;

    return {
      message: translations.taxiRailyContractNotValidErrorLabel,
      params: {},
      type: DriverPlanCandidatureAddByOrderIdErrorType.TAXI_RAILY_CONTRACT_NOT_VALID,
    };
  };

const createPlanEntryCandidateStartTimeExpiredError =
  (): DriverPlanPlanEntryCandidateStartTimeExpiredError => {
    const translations =
      orderTranslationsHelper.getDetailsTranslations().manageDriverAssignment
        .apiErrors;

    return {
      message: translations.planEntryCandidateStartTimeExpiredError,
      params: {},
      type: DriverPlanCandidatureAddByOrderIdErrorType.PLAN_ENTRY_CANDIDATE_START_TIME_EXPIRED_ERROR,
    };
  };

const createSolvedOrderCandidateStartTimeExpiredError =
  (): DriverPlanSolvedOrderCandidateStartTimeExpiredError => {
    const translations =
      orderTranslationsHelper.getDetailsTranslations().manageDriverAssignment
        .apiErrors;

    return {
      message: translations.solvedOrderCandidateStartTimeExpiredError,
      params: {},
      type: DriverPlanCandidatureAddByOrderIdErrorType.SOLVED_ORDER_CANDIDATE_START_TIME_EXPIRED_ERROR,
    };
  };

const createCollisionDirection = (
  direction: DriverPlanCollisionSubjectDirectionResponse
): DriverPlanCollisionSubjectDirection => {
  switch (direction) {
    case DriverPlanCollisionSubjectDirectionResponse.NEXT:
      return DriverPlanCollisionSubjectDirection.NEXT;
    case DriverPlanCollisionSubjectDirectionResponse.PREVIOUS:
      return DriverPlanCollisionSubjectDirection.PREVIOUS;
  }
};

const createPlanCandidateFitsWithNotEnoughTimeError = (
  response: DriverPlanCandidatureAddByOrderIdNotAcceptableResponseCandidateFitsWithNotEnoughTimeError
): DriverPlanCandidateFitsWithNotEnoughTimeError => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().manageDriverAssignment
      .apiErrors;

  return {
    message: translations.planEntryCandidateFitsWithNotEnoughTimeError,
    params: {
      collisionDirection: createCollisionDirection(
        response.collisionSubject.collisionDirection
      ),
      existingGap: response.collisionInfo.existingGap,
      requiredGap: response.collisionInfo.requiredGap,
    },
    type: DriverPlanCandidatureAddByOrderIdErrorType.CANDIDATE_FITS_WITH_NOT_ENOUGH_TIME,
  };
};

const createPlanCandidateDoesNotFitError = (
  response: DriverPlanCandidatureAddByOrderIdNotAcceptableResponseCandidateDoesNotFitError
): DriverPlanCandidatureDoesNotFitError => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().manageDriverAssignment
      .apiErrors;

  return {
    message: translations.planEntryCandidateDoesNotFitError,
    params: {
      collisionDirection: createCollisionDirection(
        response.collisionSubject.collisionDirection
      ),
      existingGap: response.collisionInfo.existingGap,
      requiredGap: response.collisionInfo.requiredGap,
    },
    type: DriverPlanCandidatureAddByOrderIdErrorType.CANDIDATE_DOES_NOT_FIT,
  };
};

const driverPlanCandidatureAddByOrderIdPreviewErrorFactory = {
  createDriverHasCandidatureError,
  createPlanEntryCandidateCollisionError,
  createTaxiDriverAssociationNotExistsError,
  createOrderNotExistsError,
  createOrderInProgressError,
  createTaxiDriverContractNotValidError,
  createTaxiRailyContractNotValidError,
  createPlanEntryCandidateStartTimeExpiredError,
  createPlanCandidateFitsWithNotEnoughTimeError,
  createPlanCandidateDoesNotFitError,
  createSolvedOrderCandidateStartTimeExpiredError,
};

export default driverPlanCandidatureAddByOrderIdPreviewErrorFactory;
