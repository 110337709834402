import RailyOfficerListLoadParams, {
  RailyOfficerListLoadParamsOrder,
} from "../../../../../common/services/raily-officer/list/raily-officer-list-load-params";
import RailyOfficerListingFilter, {
  RailyOfficerListingEmailFilter,
  RailyOfficerListingFirstNameFilter,
  RailyOfficerListingLastNameFilter,
  RailyOfficerListingActivityStatusFilter,
  RailyOfficerListingUsernameFilter,
} from "./types/raily-officer-listing-filter";
import RailyOfficerListingFilterType from "./types/raily-officer-listing-filter-type";
import RailyOfficerListingSortKey from "./types/raily-officer-listing-sort-key";

const createOrder = (
  sortKey: RailyOfficerListingSortKey | null
): RailyOfficerListLoadParamsOrder | undefined => {
  const definition: Record<
    RailyOfficerListingSortKey,
    RailyOfficerListLoadParamsOrder
  > = {
    [RailyOfficerListingSortKey.FIRST_NAME_ASC]:
      RailyOfficerListLoadParamsOrder.FIRST_NAME_ASC,
    [RailyOfficerListingSortKey.FIRST_NAME_DESC]:
      RailyOfficerListLoadParamsOrder.FIRST_NAME_DESC,
    [RailyOfficerListingSortKey.LAST_NAME_ASC]:
      RailyOfficerListLoadParamsOrder.LAST_NAME_ASC,
    [RailyOfficerListingSortKey.LAST_NAME_DESC]:
      RailyOfficerListLoadParamsOrder.LAST_NAME_DESC,
    [RailyOfficerListingSortKey.USERNAME_ASC]:
      RailyOfficerListLoadParamsOrder.USERNAME_ASC,
    [RailyOfficerListingSortKey.USERNAME_DESC]:
      RailyOfficerListLoadParamsOrder.USERNAME_DESC,
  };

  return sortKey ? definition[sortKey] : undefined;
};

const create = (
  page: number,
  pageSize: number,
  filters: RailyOfficerListingFilter[],
  sortKey: RailyOfficerListingSortKey | null
): RailyOfficerListLoadParams => {
  const statusFilter = filters.find(
    (filter) => filter.type === RailyOfficerListingFilterType.ACTIVITY_STATUS
  )?.value as RailyOfficerListingActivityStatusFilter["value"] | undefined;

  return {
    page,
    pageSize,
    firstName: filters.find(
      (filter) => filter.type === RailyOfficerListingFilterType.FIRST_NAME
    )?.value as RailyOfficerListingFirstNameFilter["value"] | undefined,
    lastName: filters.find(
      (filter) => filter.type === RailyOfficerListingFilterType.LAST_NAME
    )?.value as RailyOfficerListingLastNameFilter["value"] | undefined,
    email: filters.find(
      (filter) => filter.type === RailyOfficerListingFilterType.EMAIL
    )?.value as RailyOfficerListingEmailFilter["value"] | undefined,
    username: filters.find(
      (filter) => filter.type === RailyOfficerListingFilterType.USERNAME
    )?.value as RailyOfficerListingUsernameFilter["value"] | undefined,
    order: createOrder(sortKey),
    status: statusFilter,
  };
};

const RailyOfficerListingLoadParamsFactory = {
  create,
};

export default RailyOfficerListingLoadParamsFactory;
