import { ListingFilter } from "./../types/cargo-address-listing-filter";
import CargoAddressListingFilter from "../types/cargo-address-listing-filter";
import CargoAddressListingFilterType from "../types/cargo-address-listing-filter-type";
import CargoAddressListingItem from "../types/cargo-address-listing-item";

//Move to higher order helper?
//---
function filterListingItemsByStringKey<T, K extends keyof T>(
  listingItems: T[],
  key: K,
  queries: string[]
): T[] {
  let filteredListingItems: T[] = [];

  queries.forEach((query) => {
    const newFilteredItems = listingItems.filter((item) =>
      (item[key] as string)
        .trim()
        .toLowerCase()
        .includes(query.trim().toLowerCase())
    );

    filteredListingItems = [...filteredListingItems, ...newFilteredItems];
  });

  return filteredListingItems;
}

function getFilterQueries<T extends ListingFilter<E>, E>(
  filters: T[],
  type: E
) {
  return filters
    .filter((item) => item.type === type)
    .map((item) => item.value) as T["value"][];
}
//---

const filterListingItems = (
  listingItems: CargoAddressListingItem[],
  filters: CargoAddressListingFilter[]
): CargoAddressListingItem[] => {
  if (!filters.length) {
    return [...listingItems];
  }

  let filteredListingItems: CargoAddressListingItem[] = [...listingItems];

  const descriptionFilterQueries = getFilterQueries(
    filters,
    CargoAddressListingFilterType.DESCRIPTION
  );
  if (descriptionFilterQueries.length) {
    filteredListingItems = filterListingItemsByStringKey(
      filteredListingItems,
      "description",
      descriptionFilterQueries
    );
  }

  const countryFilterQueries = getFilterQueries(
    filters,
    CargoAddressListingFilterType.COUNTRY
  );
  if (countryFilterQueries.length) {
    filteredListingItems = filterListingItemsByStringKey(
      filteredListingItems,
      "country",
      countryFilterQueries
    );
  }

  const townFilterQueries = getFilterQueries(
    filters,
    CargoAddressListingFilterType.TOWN
  );
  if (townFilterQueries.length) {
    filteredListingItems = filterListingItemsByStringKey(
      filteredListingItems,
      "town",
      townFilterQueries
    );
  }

  const streetFilterQueries = getFilterQueries(
    filters,
    CargoAddressListingFilterType.STREET
  );
  if (streetFilterQueries.length) {
    filteredListingItems = filterListingItemsByStringKey(
      filteredListingItems,
      "street",
      streetFilterQueries
    );
  }

  const zipCodeFilterQueries = getFilterQueries(
    filters,
    CargoAddressListingFilterType.ZIP_CODE
  );
  if (zipCodeFilterQueries.length) {
    filteredListingItems = filterListingItemsByStringKey(
      filteredListingItems,
      "zipCode",
      zipCodeFilterQueries
    );
  }

  return filteredListingItems;
};

const cargoAddressListingFilterHelper = {
  filterListingItems,
};

export default cargoAddressListingFilterHelper;
