import BillingModel from "../../../../../types/billing-model";
import { RelatedTaxiRailyBillingDataApiResponse } from "../../api/related-taxi-raily-billings.response";
import RelatedTaxiRailyBillingData from "../types/related-taxi-raily-billing data";

const createBillingsData = (data: RelatedTaxiRailyBillingDataApiResponse[]) => {
  const relatedBillings: RelatedTaxiRailyBillingData[] = data.map(
    (billingDataItem) => {
      return {
        contractDetails: {
          companyName: billingDataItem.contract.cargo_company.company_name,
          distanceRate: billingDataItem.contract.distance_rate,
          model: billingDataItem.contract.model as BillingModel,
        },
        baseAmount: billingDataItem.base_amount,
        routeDistanceCost: billingDataItem.discount_amount,
        haltingCost: billingDataItem.halting_amount,
        highwayCharge: billingDataItem.highway_charge,
        distance: billingDataItem.distance,
        distanceAmount: billingDataItem.distance_amount,
        internalOrderId: billingDataItem.cargo_order.human_id,
        discount: billingDataItem.discount,
        total: billingDataItem.total_amount,
        discountAmount: billingDataItem.discount_amount,
        isDraft: billingDataItem.draft,
        date: billingDataItem.date,
      };
    }
  );

  return relatedBillings;
};
const relatedTaxiRailyBillingsDataFactory = {
  createBillingsData,
};

export default relatedTaxiRailyBillingsDataFactory;
