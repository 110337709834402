export enum DriverEditFormAddressType {
  STARTING = "STARTING",
  MAILING = "MAILING",
}

export enum DriverEditFormFormOfEmployment {
  B2B = "B2B",
  EMPLOYMENT = "EMPLOYMENT",
}

export enum DriverEditFormCitizenship {
  PL = "PL",
  DE = "DE",
  SK = "SK",
  CZ = "CZ",
  UA = "UA",
  RU = "RU",
  BY = "BY",
  LT = "LT",
}

export enum DriverEditFormLanguage {
  PL = "PL",
  EN = "EN",
  DE = "DE",
  FR = "FR",
  ES = "ES",
  SK = "SK",
  CZ = "CZ",
  UA = "UA",
  RU = "RU",
  BY = "BY",
  LT = "LT",
}

export enum DriverEditFormCarOwnership {
  OWNER = "OWNER",
  BUSINESS = "BUSINESS",
}

export type DriverEditFormUser = {
  username: string;
  firstName: string;
  lastName: string;
  mobilePrimaryPrefix: string;
  mobilePrimary: string;
  mobileSecondaryPrefix: string;
  mobileSecondary: string;
  email: string;
};

export type DriverEditFormFleetPartner = {
  uuid: string;
  modifiedAt: string;
  modifiedBy: string;
  createdAt: string;
  createdBy: string;
  taxiCorporationId: string;
  name: string;
};

export type DriverEditFormTaxiCorporation = {
  taxId: string;
  regon: string | null;
  krsNo: string;
  companyName: string;
  displayName: string;
  headquartersAddressString: string;
  contractMaintenancePolicy: string;
  notes: string;
  id: string;
  modifiedAt: string;
  modifiedBy: string;
  createdAt: string;
  createdBy: string;
  fleetPartners: DriverEditFormFleetPartner[];
};

export type DriverEditFormAssociation = {
  id: string;
  modifiedAt: string;
  modifiedBy: string;
  createdAt: string;
  createdBy: string;
  contractType: DriverEditFormFormOfEmployment;
  taxId: string;
  regon: string | null;
  krsNo: string;
  companyName: string;
  fleetPartnerId: string;
  country: string;
  town: string;
  zipCode: string;
  street: string;
  houseNo: string;
  apartment: string;
  description: string | null;
  taxiCorporation: DriverEditFormTaxiCorporation;
  fleetPartner: DriverEditFormFleetPartner | null;
};

export type DriverEditFormAddress = {
  lat: number;
  lon: number;
  country: string;
  town: string;
  zipCode: string;
  street: string;
  houseNo: string;
  apartment: string;
  description: string;
  displayName: string;
  type: DriverEditFormAddressType;
};

export type DriverEditFormDriver = {
  birthDate: string;
  birthPlace: string;
  idNumber: string;
  citizenship: DriverEditFormCitizenship;
  languages: DriverEditFormLanguage[];
  experience: number;
  carOwnership: DriverEditFormCarOwnership;
  carMake: string;
  carModel: string;
  carRegNo: string;
  carProdYear: number;
  carSeatsNo: number;
  mobileModel: string;
};

type DriverEditForm = {
  driver: DriverEditFormDriver;
  user: DriverEditFormUser;
  associations: DriverEditFormAssociation[];
  addresses: DriverEditFormAddress[];
};

export default DriverEditForm;
