import phoneNumberInputHelper from "../../../../../../common/components/form/input/phone-number/phone-number-input.helper";
import { FormValidationDefinition } from "../../../../../../common/components/form/use-form";
import driverEditUserDataFormValidationService from "./driver-edit-user-data-form-validation.service";
import RailyDriverEditUserFormData from "./types/raily-driver-edit-user-form-data";

const getDefaultUserFormData = (): RailyDriverEditUserFormData => {
  return {
    firstName: "",
    lastName: "",
    mobile: phoneNumberInputHelper.getEmptyValue(),
    alternativeMobile: phoneNumberInputHelper.getEmptyValue(),
    birthDate: null,
    birthPlace: "",
    personalIdNumber: "",
    citizenship: null,
    languages: [],
    yearsOfExperience: null,
    addresses: [],
  };
};

const getUserValidationDefinition =
  (): FormValidationDefinition<RailyDriverEditUserFormData> => {
    return {
      addresses: (values) =>
        driverEditUserDataFormValidationService.validateAddresses(
          values.addresses
        ),
      alternativeMobile: (values) =>
        driverEditUserDataFormValidationService.validateAlternativeMobile(
          values.alternativeMobile
        ),
      birthDate: (values) =>
        driverEditUserDataFormValidationService.validateBirthDate(
          values.birthDate
        ),
      birthPlace: (values) =>
        driverEditUserDataFormValidationService.validateBirthPlace(
          values.birthPlace
        ),
      citizenship: (values) =>
        driverEditUserDataFormValidationService.validateCitizenship(
          values.citizenship
        ),
      firstName: (values) =>
        driverEditUserDataFormValidationService.validateFirstName(
          values.firstName
        ),
      languages: (values) =>
        driverEditUserDataFormValidationService.validateLanguages(
          values.languages
        ),
      lastName: (values) =>
        driverEditUserDataFormValidationService.validateLastName(
          values.lastName
        ),
      mobile: (values) =>
        driverEditUserDataFormValidationService.validateMobile(values.mobile),
      personalIdNumber: (values) =>
        driverEditUserDataFormValidationService.validatePersonalIdNumber(
          values.personalIdNumber
        ),
      yearsOfExperience: (values) =>
        driverEditUserDataFormValidationService.validateYearsOfExperience(
          values.yearsOfExperience
        ),
    };
  };

const railyDriverEditUserFormHelper = {
  getDefaultUserFormData,
  getUserValidationDefinition,
};

export default railyDriverEditUserFormHelper;
