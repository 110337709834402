import { FC, useMemo } from "react";
import billingDetailsHelper from "./billing-details.helper";
import BillingFormData, {
  BillingSummaryData,
} from "../types/billing-form.data";
import BillingDetailsTableRow from "./types/billing-details-table-row";
import dateService from "../../../../../common/utils/date/date.service";
import TableComponent from "../../../../../common/components/table/table.component";
import { BillingsTaxiDriverContributionBonusType } from "../../common/contributions/bonus/types/billings-taxi-driver-contributions-bonus";

type OrderBillingDetailsProps = {
  billingData: BillingFormData | undefined;
  billingSummaryData: BillingSummaryData | undefined;
};

const BillingDetailsComponent: FC<OrderBillingDetailsProps> = (props) => {
  const createTableRow = (
    billingData: BillingFormData,
    billingSummaryData: BillingSummaryData
  ): BillingDetailsTableRow => {
    const favorableDistance = billingData.billingContributions.bonus.find(
      (contribution) =>
        contribution.type ===
        BillingsTaxiDriverContributionBonusType.BONUS_FAVORABLE_DISTANCE
    )?.distance;

    return {
      id: billingSummaryData.internalOrderId.toString(),
      value: {
        date: (
          <div
            title={dateService.format(billingSummaryData.date, "dd/mm/yyyy")}
          >
            {dateService.format(billingSummaryData.date, "dd/mm/yyyy")}
          </div>
        ),
        internalOrderId: (
          <div title={billingSummaryData.internalOrderId.toString()}>
            {billingSummaryData.internalOrderId}
          </div>
        ),
        driver: (
          <div
            title={`${billingSummaryData.driver.first_name} ${billingSummaryData.driver.last_name}`}
          >
            {`${billingSummaryData.driver.first_name} ${billingSummaryData.driver.last_name}`}
          </div>
        ),
        passengers: (
          <div>
            {billingSummaryData.passengers.map((passenger, index) => (
              <div key={index} title={passenger}>
                {passenger}
              </div>
            ))}
          </div>
        ),
        model: (
          <div title={billingSummaryData.correctedContractModel}>
            {billingSummaryData.correctedContractModel}
          </div>
        ),
        rate: (
          <div
            title={billingSummaryData.contractDetails?.distanceRate.toFixed(2)}
          >
            {billingSummaryData.contractDetails?.distanceRate.toFixed(2)} PLN
          </div>
        ),
        calculatedRateForDistance: (
          <div title={billingSummaryData.rateForDistance.toFixed(2)}>
            {billingSummaryData.rateForDistance.toFixed(2)} PLN
          </div>
        ),
        distance: (
          <div title={billingSummaryData.distance.toString()}>
            {billingSummaryData.distance}
          </div>
        ),
        additionalDistance: (
          <div title={favorableDistance ? favorableDistance.toString() : "0"}>
            {favorableDistance ?? 0}
          </div>
        ),
        sumOfDistance: (
          <div
            title={
              favorableDistance
                ? (favorableDistance + billingSummaryData.distance).toString()
                : billingSummaryData.distance.toString()
            }
          >
            {favorableDistance
              ? favorableDistance + billingSummaryData.distance
              : billingSummaryData.distance}
          </div>
        ),
        amountForDistance: (
          <div title={billingSummaryData.amountForDistance.toString()}>
            {billingSummaryData.amountForDistance.toFixed(2)} PLN
          </div>
        ),
        amountForChargeHaltings: (
          <div title={billingSummaryData.amountForChargeHaltings.toString()}>
            {billingSummaryData.amountForChargeHaltings.toFixed(2)} PLN
          </div>
        ),
        amountForChargeHighways: (
          <div title={billingSummaryData.amountForChargeHighways.toString()}>
            {billingSummaryData.amountForChargeHighways.toFixed(2)} PLN
          </div>
        ),
        sumOfBonuses: (
          <div title={billingSummaryData.sumOfBonuses.toString()}>
            {billingSummaryData.sumOfBonuses.toFixed(2)} PLN
          </div>
        ),
        sumOfPenalties: (
          <div title={billingSummaryData.sumOfPenalties.toString()}>
            {billingSummaryData.sumOfPenalties.toFixed(2)} PLN
          </div>
        ),
        allContributionsAmount: (
          <div title={billingSummaryData.allContributionsAmount?.toString()}>
            {billingSummaryData.allContributionsAmount.toFixed(2)} PLN
          </div>
        ),
      },
    };
  };

  const tableColumns = billingDetailsHelper.getTableColumns();

  const tableRows: BillingDetailsTableRow[] = useMemo(() => {
    if (props.billingSummaryData && props.billingData) {
      var tableRow = createTableRow(
        props.billingData,
        props.billingSummaryData
      );
      return [tableRow];
    }

    return [];
  }, [props.billingSummaryData, props.billingData]);

  return <TableComponent columns={tableColumns} rows={tableRows} />;
};

export default BillingDetailsComponent;
