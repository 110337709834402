import { FC, useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../../../../../context/app.context";
import userTranslationsHelper from "../../../../../../../languages/user-translations.helper";
import CardComponent from "../../../../../../../common/components/card/card.component";
import Row from "../../../../../../../common/components/grid/row";
import Column from "../../../../../../../common/components/grid/column";
import FormFieldComponent from "../../../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../../../common/components/form/input/input.component";
import PhoneNumberInputComponent from "../../../../../../../common/components/form/input/phone-number/phone-number-input.component";
import DateInputComponent from "../../../../../../../common/components/form/input/date/date-input.component";
import SingleSelectComponent from "../../../../../../../common/components/form/select/single-select/single-select.component";
import MultiSelectComponent from "../../../../../../../common/components/form/select/multi-select/multi-select.component";
import NumericInputComponent from "../../../../../../../common/components/form/input/numeric-input/numeric-input.component";
import driverAddCitizenshipTypeFactory from "../../factory/driver-add-user-data.factory";
import DriverAddCitizenshipTypeSelectOption from "../../types/driver-add-citizenship-type-select-option";
import DriverAddFormOfEmploymentTypeSelectOption from "../../types/driver-add-form-of-employment-type-select-option";
import DriverAddLanguageTypeSelectOption from "../../types/driver-add-language-type-select-option";
import DriverAddUserFleetPartnerSelectOption from "../../types/driver-add-user-fleet-partner-select-option";
import useAbort from "../../../../../../../common/hooks/use-abort";
import useTaxiCorporationList from "../../../../../../../common/services/taxi-corporation/list/use-taxi-corporation-list";
import useTaxiFleetPartnerList from "../../../../../../../common/services/taxi-fleet-partner/list/use-taxi-fleet-partner-list";
import TaxiFleetPartnerListLoadParams from "../../../../../../../common/services/taxi-fleet-partner/list/taxi-fleet-partner-list-load-params";
import DriverAddUserDataAddressComponent from "./address/driver-add-user-data-address.component";
import DriverAddAddress from "../../types/driver-add-address";
import FormValidationResult from "../../../../../../../common/utils/validation/types/form-validation-result";
import TaxiDriverAddUserFormData from "../../form/types/taxi-driver-add-user-form-data";

type TaxiDriverAddUserDataProps = {
  validateUserData: (
    formKey: keyof TaxiDriverAddUserFormData,
    value?: unknown
  ) => Promise<boolean>;
  onUserDataChange: (
    formKey: keyof TaxiDriverAddUserFormData,
    value: any
  ) => void;
  userValidationResults: Record<
    keyof TaxiDriverAddUserFormData,
    FormValidationResult
  >;
  userFormValues: TaxiDriverAddUserFormData;
  taxiCorporationUuid: string;
};

const TaxiDriverAddUserDataComponent: FC<TaxiDriverAddUserDataProps> = (
  props
) => {
  const { selectedAppLanguage } = useAppContext();

  const translations = userTranslationsHelper.getDriverAddTranslations().form;

  const [fleetPartnerSelectOptions, setFleetPartnerSelectOptions] = useState<
    DriverAddUserFleetPartnerSelectOption[]
  >([]);

  const taxiCorporationList = useTaxiCorporationList();
  const taxiCorporationListAbort = useAbort();

  const taxiFleetPartnerList = useTaxiFleetPartnerList();
  const taxiFleetPartnerListAbort = useAbort();

  const loadTaxiFleetPartner = async () => {
    if (!props.taxiCorporationUuid) {
      return;
    }

    const params: TaxiFleetPartnerListLoadParams = {
      taxiCorporationUuid: props.taxiCorporationUuid,
    };

    taxiFleetPartnerList.load(params, taxiFleetPartnerListAbort.signal);
  };

  useEffect(() => {
    taxiCorporationList.load({}, taxiCorporationListAbort.signal);
  }, []);

  useEffect(() => {
    if (props.taxiCorporationUuid) {
      loadTaxiFleetPartner();
    }
  }, [props.taxiCorporationUuid]);

  useEffect(() => {
    if (taxiFleetPartnerList.data) {
      setFleetPartnerSelectOptions(
        taxiFleetPartnerList.data.map((partner) => ({
          label: partner.name,
          value: partner,
        }))
      );
    }
  }, [taxiFleetPartnerList.data]);

  const citizenshipSelectOptions = useMemo(
    driverAddCitizenshipTypeFactory.createCitizenshipSelectOptions,
    [selectedAppLanguage]
  );

  const languageSelectOptions = useMemo(
    driverAddCitizenshipTypeFactory.createLanguageSelectOptions,
    [selectedAppLanguage]
  );

  const formOfEmploymentSelectOptions = useMemo(
    driverAddCitizenshipTypeFactory.createFormOfEmploymentSelectOptions,
    [selectedAppLanguage]
  );

  const citizenshipSelectOption = props.userFormValues.citizenship
    ? citizenshipSelectOptions.find(
        (option) => option.value === props.userFormValues.citizenship
      ) || null
    : null;

  const formOfEmploymentSelectOption = props.userFormValues.formOfEmployment
    ? formOfEmploymentSelectOptions.find(
        (option) => option.value === props.userFormValues.formOfEmployment
      ) || null
    : null;

  const fleetPartnerSelectOption = props.userFormValues.fleetPartner
    ? fleetPartnerSelectOptions.find(
        (option) =>
          option.value.uuid === props.userFormValues.fleetPartner?.uuid
      ) || null
    : null;

  const languageSelectOption = props.userFormValues.languages
    .map((type) =>
      languageSelectOptions.find((option) => option.value === type)
    )
    .filter((option): option is DriverAddLanguageTypeSelectOption => !!option);

  const handleLanguagesChange = async (
    values: DriverAddLanguageTypeSelectOption[] | null
  ) => {
    const selectedLanguages = values ? values.map((value) => value.value) : [];

    const isValid = await props.validateUserData(
      "languages",
      selectedLanguages
    );
    if (isValid || selectedLanguages.length === 0) {
      props.onUserDataChange("languages", selectedLanguages);
    }
  };

  const handleCitizenshipChange = async (
    value: DriverAddCitizenshipTypeSelectOption | null
  ) => {
    if (!value) return;

    const isValid = await props.validateUserData("citizenship", value.value);
    if (isValid) {
      props.onUserDataChange("citizenship", value.value);
    }
  };

  const handleFormOfEmploymentChange = async (
    value: DriverAddFormOfEmploymentTypeSelectOption | null
  ) => {
    if (!value) return;

    const isValid = await props.validateUserData(
      "formOfEmployment",
      value.value
    );
    if (isValid) {
      props.onUserDataChange("formOfEmployment", value.value);
    }
  };

  const handleFleetPartnerChange = async (
    value: DriverAddUserFleetPartnerSelectOption | null
  ) => {
    if (!value) return;

    const isValid = await props.validateUserData("fleetPartner", value.value);
    if (isValid) {
      props.onUserDataChange("fleetPartner", value.value);
    }
  };

  const addAddress = async (newAddress: DriverAddAddress) => {
    const updatedAddresses = [...props.userFormValues.addresses, newAddress];

    props.onUserDataChange("addresses", updatedAddresses);

    await props.validateUserData("addresses", updatedAddresses);
  };

  const handleBirthDateChange = async (value: Date | null) => {
    props.onUserDataChange("birthDate", value);
    await props.validateUserData("birthDate", value);
  };

  const removeAddress = async (indexToRemove: number) => {
    const updatedAddresses = props.userFormValues.addresses.filter(
      (_address, index) => index !== indexToRemove
    );

    props.onUserDataChange("addresses", updatedAddresses);

    await props.validateUserData("addresses", updatedAddresses);
  };

  return (
    <CardComponent header={{ title: translations.headingText }}>
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.userData.firstNameLabel}
            isRequired
            errorMessage={props.userValidationResults.firstName.errorMessage}
            classNames={{ root: "mt-0" }}
          >
            <InputComponent
              placeholder={translations.userData.firstNamePlaceholder}
              value={props.userFormValues.firstName}
              onChange={(value) => props.onUserDataChange("firstName", value)}
              onBlur={() => {
                props.validateUserData("firstName");
              }}
              hasError={!!props.userValidationResults.firstName.errorMessage}
              idForTesting={`user-data-first-name-input`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.userData.lastNameLabel}
            isRequired
            errorMessage={props.userValidationResults.lastName.errorMessage}
            classNames={{ root: "mt-0" }}
          >
            <InputComponent
              placeholder={translations.userData.lastNamePlaceholder}
              value={props.userFormValues.lastName}
              onChange={(value) => props.onUserDataChange("lastName", value)}
              onBlur={() => {
                props.validateUserData("lastName");
              }}
              hasError={!!props.userValidationResults.lastName.errorMessage}
              idForTesting={`user-data-last-name-input`}
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.userData.mobileLabel}
            isRequired
            errorMessage={props.userValidationResults.mobile.errorMessage}
          >
            <PhoneNumberInputComponent
              placeholder={translations.userData.mobilePlaceholder}
              phoneNumber={props.userFormValues.mobile}
              onChange={(value) => props.onUserDataChange("mobile", value)}
              onBlur={() => {
                props.validateUserData("mobile");
              }}
              hasError={!!props.userValidationResults.mobile.errorMessage}
              idForTesting={`user-data-mobile`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.userData.alternativeMobileLabel}
            errorMessage={
              props.userValidationResults.alternativeMobile.errorMessage
            }
          >
            <PhoneNumberInputComponent
              placeholder={translations.userData.alternativeMobilePlaceholder}
              phoneNumber={props.userFormValues.alternativeMobile}
              onChange={(value) =>
                props.onUserDataChange("alternativeMobile", value)
              }
              onBlur={() => {
                props.validateUserData("alternativeMobile");
              }}
              hasError={
                !!props.userValidationResults.alternativeMobile.errorMessage
              }
              idForTesting={`user-data-alternative-mobile`}
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.userData.birthDateLabel}
            isRequired
            errorMessage={props.userValidationResults.birthDate.errorMessage}
          >
            <DateInputComponent
              placeholder={translations.userData.birthDatePlaceholder}
              date={props.userFormValues.birthDate}
              onChange={handleBirthDateChange}
              hasError={!!props.userValidationResults.birthDate.errorMessage}
              maxDate={new Date()}
              idForTesting={`user-data-birth-date-picker-input`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.userData.birthPlaceLabel}
            isRequired
            errorMessage={props.userValidationResults.birthPlace.errorMessage}
          >
            <InputComponent
              placeholder={translations.userData.birthPlacePlaceholder}
              value={props.userFormValues.birthPlace}
              onChange={(value) => props.onUserDataChange("birthPlace", value)}
              onBlur={() => {
                props.validateUserData("birthPlace");
              }}
              hasError={!!props.userValidationResults.birthPlace.errorMessage}
              idForTesting={`user-data-birth-place-input`}
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.userData.personalIdNumberLabel}
            isRequired
            errorMessage={
              props.userValidationResults.personalIdNumber.errorMessage
            }
          >
            <InputComponent
              placeholder={translations.userData.personalIdNumberPlaceholder}
              value={props.userFormValues.personalIdNumber}
              onChange={(value) =>
                props.onUserDataChange("personalIdNumber", value)
              }
              onBlur={() => {
                props.validateUserData("personalIdNumber");
              }}
              hasError={
                !!props.userValidationResults.personalIdNumber.errorMessage
              }
              idForTesting={`user-data-personal-id-number-input`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.userData.citizenshipLabel}
            isRequired
            errorMessage={props.userValidationResults.citizenship.errorMessage}
          >
            <SingleSelectComponent
              placeholder={translations.userData.citizenshipPlaceholder}
              value={citizenshipSelectOption}
              options={citizenshipSelectOptions}
              onChange={handleCitizenshipChange}
              hasError={!!props.userValidationResults.citizenship.errorMessage}
              idForTesting={`user-data-citizenship-select`}
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.userData.languagesLabel}
            isRequired
            errorMessage={props.userValidationResults.languages.errorMessage}
          >
            <MultiSelectComponent
              placeholder={translations.userData.languagesPlaceholder}
              value={languageSelectOption}
              options={languageSelectOptions}
              onChange={handleLanguagesChange}
              hasError={!!props.userValidationResults.languages.errorMessage}
              idForTesting={`user-data-languages-select`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.userData.experienceLabel}
            isRequired
            errorMessage={
              props.userValidationResults.yearsOfExperience.errorMessage
            }
          >
            <NumericInputComponent
              placeholder={translations.userData.experiencePlaceholder}
              value={props.userFormValues.yearsOfExperience}
              isIntegerOnly
              onChange={(value) =>
                props.onUserDataChange("yearsOfExperience", value)
              }
              onBlur={() => {
                props.validateUserData("yearsOfExperience");
              }}
              hasError={
                !!props.userValidationResults.yearsOfExperience.errorMessage
              }
              idForTesting={`user-data-experience-input`}
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.taxiCorporationData.formOfEmploymentLabel}
            isRequired
            errorMessage={
              props.userValidationResults.formOfEmployment.errorMessage
            }
          >
            <SingleSelectComponent
              placeholder={
                translations.taxiCorporationData.formOfEmploymentPlaceholder
              }
              value={formOfEmploymentSelectOption}
              options={formOfEmploymentSelectOptions}
              onChange={handleFormOfEmploymentChange}
              hasError={
                !!props.userValidationResults.formOfEmployment.errorMessage
              }
              idForTesting={`user-data-form-of-employment-select`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.taxiCorporationData.fleetPartnerLabel}
            errorMessage={props.userValidationResults.fleetPartner.errorMessage}
          >
            <SingleSelectComponent
              placeholder={
                translations.taxiCorporationData.fleetPartnerPlaceholder
              }
              value={fleetPartnerSelectOption}
              options={fleetPartnerSelectOptions}
              onChange={handleFleetPartnerChange}
              isClearable
              isLoading={
                props.taxiCorporationUuid
                  ? taxiFleetPartnerList.isLoading
                  : false
              }
              isDisabled={taxiFleetPartnerList.isError}
              hasError={!!props.userValidationResults.fleetPartner.errorMessage}
              idForTesting={`user-data-fleet-partner-select`}
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column>
          <FormFieldComponent
            label={translations.userData.addressesLabel}
            isRequired
            errorMessage={props.userValidationResults.addresses.errorMessage}
          >
            <DriverAddUserDataAddressComponent
              addresses={props.userFormValues.addresses}
              onRemoveAddressButtonClick={removeAddress}
              onAddNewAddress={addAddress}
            />
          </FormFieldComponent>
        </Column>
      </Row>
    </CardComponent>
  );
};

export default TaxiDriverAddUserDataComponent;
