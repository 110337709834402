import Joi from "joi";
import FormValidationResult from "../../../../common/utils/validation/types/form-validation-result";
import formValidationService from "../../../../common/utils/validation/form-validation.service";
import TaxiCargoContractAddFormData from "./common/types/taxi-cargo-contract-add-form-data";

const validateBillingModel = (
  value: TaxiCargoContractAddFormData["billingModel"]
): FormValidationResult => {
  const validationSchema = Joi.string().required();

  return formValidationService.validate(value, validationSchema);
};

const validateDiscountPercentLimit = (
  value: TaxiCargoContractAddFormData["discountPercentLimit"]
): FormValidationResult => {
  const validationSchema = Joi.number().min(0).max(99).required();

  return formValidationService.validate(value, validationSchema);
};

const validateDistanceRate = (
  value: TaxiCargoContractAddFormData["distanceRate"]
): FormValidationResult => {
  const validationSchema = Joi.number()
    .greater(0)
    .less(100)
    .precision(2)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateFreeStoppingPeriodMinutes = (
  value: TaxiCargoContractAddFormData["freeStoppingPeriodMinutes"]
): FormValidationResult => {
  const validationSchema = Joi.number().min(0).required();

  return formValidationService.validate(value, validationSchema);
};

const validateIsOrderPublishingAllowed = (
  value: TaxiCargoContractAddFormData["isOrderPublishingAllowed"]
): FormValidationResult => {
  const validationSchema = Joi.any().invalid(null).required();

  return formValidationService.validate(value, validationSchema);
};

const validateIsTollRoadsDuringOrderAllowed = (
  value: TaxiCargoContractAddFormData["isTollRoadsDuringOrderAllowed"]
): FormValidationResult => {
  const validationSchema = Joi.any().invalid(null).required();

  return formValidationService.validate(value, validationSchema);
};

const validateIsTollRoadsWhileArrivalAllowed = (
  value: TaxiCargoContractAddFormData["isTollRoadsWhileArrivalAllowed"]
): FormValidationResult => {
  const validationSchema = Joi.any().invalid(null).required();

  return formValidationService.validate(value, validationSchema);
};

const validateIsTollRoadsWhileReturningAllowed = (
  value: TaxiCargoContractAddFormData["isTollRoadsWhileReturningAllowed"]
): FormValidationResult => {
  const validationSchema = Joi.any().invalid(null).required();
  return formValidationService.validate(value, validationSchema);
};

const validatePeriodOfValidity = (
  value: TaxiCargoContractAddFormData["periodOfValidity"]
): FormValidationResult => {
  const validationSchema = Joi.object<
    TaxiCargoContractAddFormData["periodOfValidity"]
  >()
    .keys({
      from: Joi.date().min("1970-01-01").required(),
      to: Joi.date().required(),
    })
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateStoppingRate = (
  value: TaxiCargoContractAddFormData["stoppingRate"]
): FormValidationResult => {
  const validationSchema = Joi.number().min(0).required();

  return formValidationService.validate(value, validationSchema);
};

const validateCargoCompany = (
  value: TaxiCargoContractAddFormData["cargoCompanyUuid"]
): FormValidationResult => {
  const validationSchema = Joi.string().required();

  return formValidationService.validate(value, validationSchema);
};

const validateActivity = (
  value: TaxiCargoContractAddFormData["isActive"]
): FormValidationResult => {
  const validationSchema = Joi.any().invalid(null).required();

  return formValidationService.validate(value, validationSchema);
};

const validateContactPassengerAfterMinutes = (
  value: TaxiCargoContractAddFormData["contactPassengerAfterMinutes"]
): FormValidationResult => {
  const validationSchema = Joi.number().integer().min(0).max(1440).required();

  return formValidationService.validate(value, validationSchema);
};

const validateCancelOrderByTaxiAllowed = (
  value: TaxiCargoContractAddFormData["cancelOrderByTaxiAllowed"]
): FormValidationResult => {
  const validationSchema = Joi.any().invalid(null).required();

  return formValidationService.validate(value, validationSchema);
};

const validateEditOrderByTaxiAllowed = (
  value: TaxiCargoContractAddFormData["editOrderByTaxiAllowed"]
): FormValidationResult => {
  const validationSchema = Joi.any().invalid(null).required();

  return formValidationService.validate(value, validationSchema);
};

const taxiCargoContractAddFormValidationService = {
  validateBillingModel,
  validateDiscountPercentLimit,
  validateDistanceRate,
  validateFreeStoppingPeriodMinutes,
  validateIsOrderPublishingAllowed,
  validateIsTollRoadsDuringOrderAllowed,
  validateIsTollRoadsWhileArrivalAllowed,
  validateIsTollRoadsWhileReturningAllowed,
  validatePeriodOfValidity,
  validateStoppingRate,
  validateCargoCompany,
  validateActivity,
  validateContactPassengerAfterMinutes,
  validateCancelOrderByTaxiAllowed,
  validateEditOrderByTaxiAllowed,
};

export default taxiCargoContractAddFormValidationService;
