import TaxiFleetPartnerUpdateRequest from "../../../api/raily/taxi-fleet-partner/update/taxi-fleet-partner-update.request";
import TaxiFleetPartnerUpdateParams from "./taxi-fleet-partner-update-params";

const create = (
  params: TaxiFleetPartnerUpdateParams
): TaxiFleetPartnerUpdateRequest => {
  return {
    taxiCorporationId: params.taxiCorporationUuid,
    fleetPartnerId: params.fleetPartnerUuid,
    name: params.name,
    notes: params.notes,
  };
};

const taxiFleetPartnerUpdateRequestFactory = { create };

export default taxiFleetPartnerUpdateRequestFactory;
