import { FC, useEffect, useState } from "react";
import CardComponent from "../../../common/components/card/card.component";
import FormFieldComponent from "../../../common/components/form/field/form-field.component";
import InputComponent from "../../../common/components/form/input/input.component";
import Column from "../../../common/components/grid/column";
import Row from "../../../common/components/grid/row";
import HeadingComponent from "../../../common/components/heading/heading.component";
import ButtonComponent from "../../../common/components/button/button.component";
import formValidationService from "../../../common/utils/validation/form-validation.service";
import { useAppContext } from "../../../context/app.context";
import notificationService from "../../../common/utils/notification/notification.service";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import useDocumentTitle from "../../../common/hooks/use-document-title";
import taxiTranslationsHelper from "../../../languages/taxi-translations.helper";
import taxiAddFormHelper from "./common/taxi-add-form.helper";
import TaxiAddFormData from "../common/types/taxi-add-form-data";
import taxiBreadcrumbsHelper from "../common/breadcrumbs/taxi-breadcrumbs.helper";
import TaxiAddValidationResult from "../common/types/taxi-add-validation.result";
import taxiAddFormValidationService from "./taxi-add-form-validation.service";
import taxiAddFactory from "./common/factory/taxi-add.factory";
import taxiAddApiService from "./common/api/taxi-add-api.service";
import TaxiAddResponse from "./common/api/taxi-add-response";
import TaxiContractMaintenancePolicy from "../common/types/taxi-contract-maintenance-policy";
import { useNavigate } from "react-router-dom";
import taxiRoutesHelper from "../common/routes/taxi-routes.helper";
import TaxiCompanyContractMaintenancePolicySelectComponent from "./select/taxi-company-contract-maintenance-policy-select.component";

type TaxiAddProps = {};

const TaxiAddComponent: FC<TaxiAddProps> = () => {
  const navigate = useNavigate();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.taxiAdd);

  const translations = taxiTranslationsHelper.getAddTranslations();

  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const [formData, setFormData] = useState<TaxiAddFormData>(
    taxiAddFormHelper.getDefaultFormData()
  );

  useEffect(() => {
    const breadcrumbs = taxiBreadcrumbsHelper.getAddBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  // temporary solution - remove after refactor
  useEffect(() => {
    const revalidationFields = Object.keys(formValidationResults).filter(
      (formKey) =>
        formValidationResults[formKey as keyof TaxiAddValidationResult]
          .isValid === false
    );

    revalidationFields.forEach((field) => {
      switch (field) {
        case "companyName":
          return validateCompanyName();
        case "companyDisplayName":
          return validateCompanyDisplayName();
        case "taxId":
          return validateTaxId();
        case "companyId":
          return validateCompanyId();
        case "address":
          return validateAddress();
        case "contractMaintenancePolicy":
          return validateContractMaintenancePolicy(
            formData.contractMaintenancePolicy!
          );
        case "notes":
          return validateNotes();
      }
    });
  }, [selectedAppLanguage]);

  const [formValidationResults, setFormValidationResults] =
    useState<TaxiAddValidationResult>({
      companyName: formValidationService.defaultValidationResult,
      companyDisplayName: formValidationService.defaultValidationResult,
      taxId: formValidationService.defaultValidationResult,
      nationalCourtRegister: formValidationService.defaultValidationResult,
      companyId: formValidationService.defaultValidationResult,
      address: formValidationService.defaultValidationResult,
      contractMaintenancePolicy: formValidationService.defaultValidationResult,
      notes: formValidationService.defaultValidationResult,
    });

  const validateCompanyName = () => {
    const validationResult = taxiAddFormValidationService.validateCompanyName(
      formData.companyName
    );

    setFormValidationResults((curr) => ({
      ...curr,
      companyName: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyDisplayName = () => {
    const validationResult =
      taxiAddFormValidationService.validateCompanyDisplayName(
        formData.displayName
      );

    setFormValidationResults((curr) => ({
      ...curr,
      companyDisplayName: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateTaxId = () => {
    const validationResult = taxiAddFormValidationService.validateTaxId(
      formData.taxId
    );

    setFormValidationResults((curr) => ({
      ...curr,
      taxId: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateNationalCourtRegister = () => {
    const validationResult =
      taxiAddFormValidationService.validateNationalCourtRegister(
        formData.nationalCourtRegister
      );

    setFormValidationResults((curr) => ({
      ...curr,
      nationalCourtRegister: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateCompanyId = () => {
    const validationResult = taxiAddFormValidationService.validateCompanyId(
      formData.companyId
    );

    setFormValidationResults((curr) => ({
      ...curr,
      companyId: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateAddress = () => {
    const validationResult = taxiAddFormValidationService.validateAddress(
      formData.address
    );

    setFormValidationResults((curr) => ({
      ...curr,
      address: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateContractMaintenancePolicy = (
    contractMaintenancePolicy: TaxiContractMaintenancePolicy
  ) => {
    const validationResult =
      taxiAddFormValidationService.validateContractMaintenancePolicy(
        contractMaintenancePolicy
      );

    setFormValidationResults((curr) => ({
      ...curr,
      contractMaintenancePolicy: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateNotes = () => {
    const validationResult = taxiAddFormValidationService.validateNotes(
      formData.notes
    );

    setFormValidationResults((curr) => ({
      ...curr,
      notes: validationResult,
    }));

    return validationResult.isValid;
  };

  const checkIsTaxiAddFormValid = () => {
    const isCompanyNameValid = validateCompanyName();
    const isCompanyDisplayNameValid = validateCompanyDisplayName();
    const isTaxIdValid = validateTaxId();
    const isCompanyIdValid = validateCompanyId();
    const isAddressValid = validateAddress();
    const isContractMaintenancePolicyValid = validateContractMaintenancePolicy(
      formData.contractMaintenancePolicy!
    );
    const isNotesValid = validateNotes();

    const isFormValid =
      isCompanyNameValid &&
      isCompanyDisplayNameValid &&
      isTaxIdValid &&
      isCompanyIdValid &&
      isAddressValid &&
      isContractMaintenancePolicyValid &&
      isNotesValid;

    return isFormValid;
  };

  const submitForm = async () => {
    const isFormValid = checkIsTaxiAddFormValid();

    if (!isFormValid) return;

    setIsFormSubmitting(true);

    const request = taxiAddFactory.createAddTaxiRequest(formData);

    taxiAddApiService.addTaxi(request).then(handleSubmitResponse);
  };

  const handleSubmitResponse = (response: TaxiAddResponse) => {
    if (response.status === 201) {
      notificationService.success(translations.taxi.successToastText);
      navigateToListing();
    }

    setIsFormSubmitting(false);
  };

  const navigateToListing = () => {
    navigate(taxiRoutesHelper.getListingRoute());
  };

  const setContractMaintenancePolicy = (
    value: TaxiAddFormData["contractMaintenancePolicy"]
  ) => setFormData((curr) => ({ ...curr, contractMaintenancePolicy: value }));

  const setCompanyName = (value: TaxiAddFormData["companyName"]) =>
    setFormData((curr) => ({ ...curr, companyName: value }));

  const setTaxId = (value: TaxiAddFormData["taxId"]) =>
    setFormData((curr) => ({ ...curr, taxId: value }));

  const setCompanyId = (value: TaxiAddFormData["companyId"]) =>
    setFormData((curr) => ({ ...curr, companyId: value }));

  const setNationalCourtRegister = (
    value: TaxiAddFormData["nationalCourtRegister"]
  ) => setFormData((curr) => ({ ...curr, nationalCourtRegister: value }));

  const setDisplayName = (value: TaxiAddFormData["displayName"]) =>
    setFormData((curr) => ({ ...curr, displayName: value }));

  const setAddress = (value: TaxiAddFormData["address"]) =>
    setFormData((curr) => ({ ...curr, address: value }));

  const setNotes = (value: TaxiAddFormData["notes"]) =>
    setFormData((curr) => ({ ...curr, notes: value }));

  return (
    <div className="taxi_add">
      <HeadingComponent title={translations.header.headingText} />
      <Row>
        <Column xl={8}>
          <CardComponent header={{ title: translations.taxi.headingText }}>
            <Row>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.taxi.companyNameLabel}
                  classNames={{ root: `mt-0` }}
                  isRequired
                  errorMessage={formValidationResults.companyName.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.taxi.companyNameInputPlaceholder}
                    value={formData.companyName}
                    onChange={setCompanyName}
                    onBlur={validateCompanyName}
                    hasError={!!formValidationResults.companyName.errorMessage}
                    idForTesting="taxi-add-company-name"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.taxi.companyDisplayNameLabel}
                  classNames={{ root: `mt-0` }}
                  isRequired
                  errorMessage={
                    formValidationResults.companyDisplayName.errorMessage
                  }
                >
                  <InputComponent
                    placeholder={
                      translations.taxi.companyDisplayNameInputPlaceholder
                    }
                    value={formData.displayName}
                    onChange={setDisplayName}
                    onBlur={validateCompanyDisplayName}
                    hasError={
                      !!formValidationResults.companyDisplayName.errorMessage
                    }
                    idForTesting="taxi-add-company-display-name"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.taxi.taxNumberLabel}
                  classNames={{ root: `mt-0` }}
                  isRequired
                  errorMessage={formValidationResults.taxId.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.taxi.taxNumberInputPlaceholder}
                    value={formData.taxId}
                    onChange={setTaxId}
                    onBlur={validateTaxId}
                    hasError={!!formValidationResults.taxId.errorMessage}
                    idForTesting="taxi-add-taxi-id"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.taxi.companyIdLabel}
                  errorMessage={formValidationResults.companyId.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.taxi.companyIdInputPlaceholder}
                    value={formData.companyId}
                    onChange={setCompanyId}
                    onBlur={validateCompanyId}
                    hasError={!!formValidationResults.companyId.errorMessage}
                    idForTesting="taxi-add-company-id"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.taxi.nationalCourtRegisterLabel}
                  errorMessage={
                    formValidationResults.nationalCourtRegister.errorMessage
                  }
                >
                  <InputComponent
                    placeholder={
                      translations.taxi.nationalCourtRegisterInputPlaceholder
                    }
                    value={formData.nationalCourtRegister}
                    onChange={setNationalCourtRegister}
                    onBlur={validateNationalCourtRegister}
                    hasError={
                      !!formValidationResults.nationalCourtRegister.errorMessage
                    }
                    idForTesting="taxi-add-national-court-register"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.taxi.sourceOfContractLabel}
                  isRequired
                  errorMessage={
                    formValidationResults.contractMaintenancePolicy.errorMessage
                  }
                >
                  <TaxiCompanyContractMaintenancePolicySelectComponent
                    onChange={(value) => {
                      setContractMaintenancePolicy(value!);
                    }}
                    onBlur={() => validateContractMaintenancePolicy}
                    placeholder={
                      translations.taxi.sourceOfContractSelectPlaceholder
                    }
                    selectedContractMaintenancePolicy={
                      formData.contractMaintenancePolicy!
                    }
                    isError={
                      !!formValidationResults.contractMaintenancePolicy
                        .errorMessage
                    }
                    idForTesting="taxi-add-company-contract-maintenance-policy"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.taxi.addressLabel}
                  isRequired
                  errorMessage={formValidationResults.address.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.taxi.addressInputPlaceholder}
                    value={formData.address}
                    onChange={setAddress}
                    onBlur={validateAddress}
                    hasError={!!formValidationResults.address.errorMessage}
                    idForTesting="taxi-add-address"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={4}>
                <FormFieldComponent
                  label={translations.taxi.notesLabel}
                  errorMessage={formValidationResults.notes.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.taxi.notesInputPlaceholder}
                    value={formData.notes}
                    onChange={setNotes}
                    onBlur={validateNotes}
                    hasError={!!formValidationResults.notes.errorMessage}
                    idForTesting="taxi-add-notes"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <ButtonComponent
              onClick={submitForm}
              type="primary"
              isDisabled={isFormSubmitting}
              idForTesting="taxi-add-submit-button"
              classNames={{ root: "mt-2" }}
            >
              {translations.taxi.addButton}
            </ButtonComponent>
          </CardComponent>
        </Column>
      </Row>
    </div>
  );
};

export default TaxiAddComponent;
