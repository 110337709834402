import { FC } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import useOpen from "../../../../../common/hooks/use-open";
import OrderDetailsToolButtonComponent from "../../common/tool-button/order-details-tool-button.component";
import OrderDetailsOrderCancelConfirmationModalComponent from "../../cancel-confirmation/order-details-order-cancel-confirmation.component";
import OrderDetailsCargoOrder from "../../common/types/order-details-cargo-order";
import OrderDetailsToolsTranslations from "../../../../../languages/types/order/details/order-detials-tools.translation";

type OrderDetialsCancelComponentProps = {
  details: OrderDetailsCargoOrder | null;
  tools: OrderDetailsToolsTranslations;
  isLoading: boolean;
  hasAccess: boolean;
  canCancelImmediately: boolean;
  onConfirm: () => void;
};

const OrderDetialsCancelComponent: FC<OrderDetialsCancelComponentProps> = ({
  details,
  tools,
  isLoading,
  hasAccess,
  canCancelImmediately,
  onConfirm,
}) => {
  const { isOpen, open, close } = useOpen();
  const { isFinished, isCancelled, cargoCompanyOrderId } = details!;

  const isCancelOrderButtonVisible = !isFinished && !isCancelled && hasAccess;

  if (!isCancelOrderButtonVisible) return null;

  const { cancelOrderButtonTitle } = tools;

  const isCancelButtonDisabled = !(
    canCancelImmediately || details?.isCancelRequestSent
  );

  const handleOnConfirm = () => {
    onConfirm();
    close();
  };

  return (
    <>
      <OrderDetailsToolButtonComponent
        onClick={open}
        icon={faTrash}
        title={cancelOrderButtonTitle}
        isLoading={isLoading}
        isDisabled={isCancelButtonDisabled}
        type="danger"
      />
      {!!isOpen && (
        <OrderDetailsOrderCancelConfirmationModalComponent
          isOpen={isOpen}
          onClose={close}
          onConfirm={handleOnConfirm}
          cargoCompanyOrderId={cargoCompanyOrderId}
        />
      )}
    </>
  );
};

export default OrderDetialsCancelComponent;
