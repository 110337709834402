import { FormValidationDefinition } from "../../../../../../common/components/form/use-form";
import driverAddCompanyFormValidationService from "./driver-add-company-form-validation.service";
import DriverAddCompanyFormData from "./types/driver-add-company-form-data";

const getDefaultCompanyFormData = (): DriverAddCompanyFormData => {
  return {
    apartmentNumber: "",
    country: "",
    description: "",
    houseNumber: "",
    street: "",
    town: "",
    zipCode: "",
    companyId: "",
    name: "",
    nationalCourtRegisterNumber: "",
    taxNumber: "",
  };
};

const getCompanyValidationDefinition =
  (): FormValidationDefinition<DriverAddCompanyFormData> => {
    return {
      companyId: (values) =>
        driverAddCompanyFormValidationService.validateCompanyId(
          values.companyId
        ),
      name: (values) =>
        driverAddCompanyFormValidationService.validateName(values.name),
      nationalCourtRegisterNumber: (values) =>
        driverAddCompanyFormValidationService.validateNationalCourtRegister(
          values.nationalCourtRegisterNumber
        ),
      taxNumber: (values) =>
        driverAddCompanyFormValidationService.validateTaxNumber(
          values.taxNumber
        ),
      street: (values) =>
        driverAddCompanyFormValidationService.validateStreet(values.street),
      houseNumber: (values) =>
        driverAddCompanyFormValidationService.validateHouseNumber(
          values.houseNumber
        ),
      apartmentNumber: (values) =>
        driverAddCompanyFormValidationService.validateApartmentNumber(
          values.apartmentNumber
        ),
      town: (values) =>
        driverAddCompanyFormValidationService.validateTown(values.town),
      country: (values) =>
        driverAddCompanyFormValidationService.validateCountry(values.country),
      zipCode: (values) =>
        driverAddCompanyFormValidationService.validateZipCode(values.zipCode),
      description: (values) =>
        driverAddCompanyFormValidationService.validateDescription(
          values.description
        ),
    };
  };

const driverAddCompanyFormHelper = {
  getDefaultCompanyFormData,
  getCompanyValidationDefinition,
};

export default driverAddCompanyFormHelper;
