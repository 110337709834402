import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import delegationTranslationsHelper from "../../../languages/delegation-translations.helper";
import useIsComponentMounted from "../../../common/hooks/use-is-component-mounted";
import delegationListingHelper from "./common/delegation-listing.helper";
import { useAppContext } from "../../../context/app.context";
import delegationBreadcrumbsHelper from "../common/breadcrumps/delegation-breadcrumbs.helper";
import HeadingComponent from "../../../common/components/heading/heading.component";
import StatusLegendComponent from "../../../common/components/status-legend/status-legend.component";
import CardComponent from "../../../common/components/card/card.component";
import ListingPaginationComponent from "../../../common/components/listing/pagination/listing-pagination.component";
import DelegationListingTableComponent from "./table/delegation-listing-table.component";
import LinkButtonComponent from "../../../common/components/button/link/link-button.component";
import delegationRoutesHelper from "../common/routes/delegation-routes.helper";
import DelegationListingFilterComponent from "./filter/delegation-listing-filters.component";
import DelegationListingFilter from "./common/types/delegation-listing-filter";
import useDelegationListingFiltersDao from "./filter/use-delegation-listing-filters.dao";
import usePagination from "../../../common/hooks/use-pagination";
import useDelegationList from "../../../common/services/delegation/list/use-delegation-list";
import DelegationDeleteComponent from "../common/delete/common/delegation-delete.component";
import DelegationDeleteDelegationData from "../common/delete/common/types/delegation-delete-delegation-data";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import useDocumentTitle from "../../../common/hooks/use-document-title";
import ButtonComponent from "../../../common/components/button/button.component";
import Row from "../../../common/components/grid/row";
import Column from "../../../common/components/grid/column";
import delegationListingLoadParamsFactory from "./common/delegation-listing-load-params.factory";
import useAbort from "../../../common/hooks/use-abort";
import DelegationListLoadParams from "../../../common/services/delegation/list/delegation-list-load-params";
import DelegationListingItem from "./common/types/delegation-listing-item";
import delegationListingItemFactory from "./common/delegation-listing-item.factory";
import DelegationListingSortKey from "./common/types/delegation-listing-sort-key";
import DelegationDeleteParams from "../../../common/services/delegation/delete/delegation-delete-params";
import delegationService from "../../../common/services/delegation/delegation.service";
import useOpen from "../../../common/hooks/use-open";
import DelegationListingActionData from "./common/types/delegation-listing-action-data";
import delegationListingActionDataFactory from "./common/delegation-listing-action-data.factory";
import notificationService from "../../../common/utils/notification/notification.service";
import DelegationDownloadParams, {
  DelegationDownloadParamsFileFormat,
} from "../../../common/services/delegation/download/delegation-download-params";
import DelegationListingActionsModalComponent from "./actions-modal/delegation-listing-actions-modal.component";
import DelegationListingDelegationStatus from "./common/types/delegation-listing-delegation-status";

const DelegationListingComponent: FC = () => {
  const translations =
    delegationTranslationsHelper.getDelegationListingTranslations();
  const isComponentMounted = useIsComponentMounted();
  const statusOptions = delegationListingHelper.getStatusOptions();
  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();
  const filtersDao = useDelegationListingFiltersDao();
  const delegationList = useDelegationList();

  const delegationDeleteModalOpen = useOpen();
  const downloadModalOpen = useOpen();

  const [delegationSelectedToDelete, setDelegationSelectedToDelete] =
    useState<DelegationDeleteDelegationData | null>(null);

  const delegationDeleteAbort = useAbort();

  const [isDownloadCSVEnabled, setIsDownloadCSVEnabled] = useState(false);
  const [isDownloadPDFEnabled, setIsDownloadPDFEnabled] = useState(false);

  const [actionDataList, setActionDataList] = useState<
    DelegationListingActionData[]
  >([]);

  const onActionButtonClick = useCallback(
    (listingItem: DelegationListingItem) => {
      if (listingItem.status === DelegationListingDelegationStatus.DELETED) {
        return;
      }

      const result = actionDataList.find(
        (source) => source.delegationUuid === listingItem.uuid
      );

      if (result) {
        setActionDataList(
          actionDataList.filter(
            (source) => source.delegationUuid !== result.delegationUuid
          )
        );
      } else {
        setActionDataList([
          ...actionDataList,
          delegationListingActionDataFactory.create(listingItem),
        ]);
      }
    },
    [actionDataList]
  );

  const onActionSelectAllButtonClick = useCallback(() => {
    if (actionDataList.length < nonDeletedListingItems.length) {
      setActionDataList(
        nonDeletedListingItems.map(delegationListingActionDataFactory.create)
      );
    } else {
      setActionDataList([]);
    }
  }, [actionDataList]);

  useEffect(() => {
    if (!downloadModalOpen.isOpen) {
      setIsDownloadCSVEnabled(false);
      setIsDownloadPDFEnabled(false);
      setActionDataList([]);
    }
  }, [downloadModalOpen.isOpen]);

  useEffect(() => {
    if (isDownloadCSVEnabled || isDownloadPDFEnabled) {
      downloadModalOpen.open();
    } else {
      downloadModalOpen.close();
    }
  }, [isDownloadCSVEnabled, isDownloadPDFEnabled]);

  useEffect(() => {
    const breadcrumbs = delegationBreadcrumbsHelper.getListingBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.mileageDelegationListing);

  const onSelectDelegationToDelete = (
    delegationData: DelegationDeleteDelegationData
  ) => {
    setDelegationSelectedToDelete(delegationData);
    delegationDeleteModalOpen.open();
  };

  const createHeadingActions = (): ReactNode[] => {
    const DelegationAddLink = (
      <LinkButtonComponent
        to={delegationRoutesHelper.getAddRoute()}
        type="primary"
        title={translations.header.addNewDelegationLinkTitle}
      >
        {translations.header.addNewDelegationLinkLabel}
      </LinkButtonComponent>
    );

    const actions: ReactNode[] = [DelegationAddLink];

    return actions;
  };

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: delegationList.data.totalCount,
    defaultPageSize: filtersDao.getPageSize(),
    defaultPage: filtersDao.getPage(),
  });

  const [filters, setFilters] = useState<DelegationListingFilter[]>(() =>
    filtersDao.getFilters()
  );

  const [sortKey, setSortKey] = useState<DelegationListingSortKey>(
    () => filtersDao.getSortKey() ?? DelegationListingSortKey.CREATION_DATE_DESC
  );

  const [expandedRowsDelegationUuids, setExpandedRowsDelegationUuids] =
    useState<string[]>([]);

  const listingItemsAbort = useAbort();

  const loadListingItems = async () => {
    const params: DelegationListLoadParams =
      delegationListingLoadParamsFactory.create(
        page,
        pageSize,
        filters,
        sortKey
      );
    delegationList.load(params, listingItemsAbort.signal);
  };

  const deleteDelegation = () => {
    if (!delegationSelectedToDelete) {
      return;
    }

    const request: DelegationDeleteParams = {
      delegationUuid: delegationSelectedToDelete.delegationUuid,
    };

    delegationService
      .deleteDelegation(request, delegationDeleteAbort.signal)
      .then(() => {
        delegationDeleteModalOpen.close();
        loadListingItems();
      });
  };

  const listingItems: DelegationListingItem[] = useMemo(() => {
    return delegationListingItemFactory.create(delegationList.data.data);
  }, [delegationList.data.data]);

  const nonDeletedListingItems = listingItems.filter(
    (item) => item.status !== DelegationListingDelegationStatus.DELETED
  );

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    filtersDao.saveFilters(filters, sortKey, page, pageSize);
  }, [filters, sortKey, page, pageSize]);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, [page]);

  useEffect(() => {
    setExpandedRowsDelegationUuids([]);
    loadListingItems();

    return () => listingItemsAbort.revoke();
  }, [page, pageSize, filters, sortKey]);

  const onDelegationDownloadSuccess = () => {
    notificationService.success(translations.download.onSuccessMessage);
    downloadModalOpen.close();
    loadListingItems();
  };

  const onDelegationDownloadFailure = () => {
    notificationService.error(translations.download.onErrorMessage);
  };

  const delegationDownload = async () => {
    const params: DelegationDownloadParams = {
      fileFormat: isDownloadPDFEnabled
        ? DelegationDownloadParamsFileFormat.PDF
        : DelegationDownloadParamsFileFormat.CSV,
      delegationUuids: actionDataList.map((data) => data.delegationUuid),
    };
    try {
      await delegationService.download(params);

      onDelegationDownloadSuccess();
    } catch {
      onDelegationDownloadFailure();
    }
  };

  const createDownloadActions = useCallback((): ReactNode[] => {
    const onDelegationPDFJoinButtonClick = () => {
      if (!isDownloadPDFEnabled) {
        setIsDownloadCSVEnabled(false);
        setActionDataList([]);
      }

      setIsDownloadPDFEnabled(!isDownloadPDFEnabled);
    };

    const onDelegationCSVJoinButtonClick = () => {
      if (!isDownloadCSVEnabled) {
        setIsDownloadPDFEnabled(false);
        setActionDataList([]);
      }

      setIsDownloadCSVEnabled(!isDownloadCSVEnabled);
    };

    const DownloadPDFLink = (
      <ButtonComponent
        type={isDownloadPDFEnabled ? "success" : "primary"}
        title={
          isDownloadPDFEnabled
            ? translations.header.downloadPDFEnableTitle
            : translations.header.downloadPDFTitle
        }
        onClick={onDelegationPDFJoinButtonClick}
        idForTesting="delegation-listing-delegation-join-button"
      >
        {translations.header.downloadPDFLabel}
      </ButtonComponent>
    );

    const DownloadCSVLink = (
      <ButtonComponent
        type={isDownloadCSVEnabled ? "success" : "primary"}
        title={
          isDownloadCSVEnabled
            ? translations.header.downloadCSVEnableTitle
            : translations.header.downloadCSVTitle
        }
        onClick={onDelegationCSVJoinButtonClick}
        idForTesting="delegation-listing-delegation-join-button"
      >
        {translations.header.downloadCSVLabel}
      </ButtonComponent>
    );

    return [DownloadPDFLink, DownloadCSVLink];
  }, [selectedAppLanguage, isDownloadCSVEnabled, isDownloadPDFEnabled]);

  const headingActions = useMemo(() => {
    return createHeadingActions();
  }, [selectedAppLanguage, createHeadingActions]);

  const headingDownloadActions = useMemo(() => {
    return createDownloadActions();
  }, [selectedAppLanguage, createDownloadActions]);

  const onTableRowClick = (listingItemUuid: string) => {
    const expandRow = () => {
      setExpandedRowsDelegationUuids((curr) => [...curr, listingItemUuid]);
    };

    const collapseRow = () => {
      const filteredExpandedRows = expandedRowsDelegationUuids.filter(
        (expandedMileageUuid) => expandedMileageUuid !== listingItemUuid
      );

      setExpandedRowsDelegationUuids(filteredExpandedRows);
    };

    const isRowExpanded = expandedRowsDelegationUuids.includes(listingItemUuid);

    if (isRowExpanded) {
      collapseRow();
      return;
    }

    expandRow();
  };

  return (
    <>
      <HeadingComponent
        title={translations.header.headingLabel}
        actions={headingActions}
      />
      <Row>
        <Column xl={8}>
          <StatusLegendComponent statusData={statusOptions} />
        </Column>
        <Column xl={4}>
          <HeadingComponent title={""} actions={headingDownloadActions} />
        </Column>
      </Row>
      <DelegationListingFilterComponent
        filters={filters}
        sortKey={sortKey}
        onFiltersChange={(filters) => {
          setFilters(filters);
          setPage(1);
        }}
        onSortKeyChange={(sortKey) => {
          setSortKey(sortKey);
          setPage(1);
        }}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <DelegationListingTableComponent
          listingItems={listingItems}
          nonDeletedListingItems={nonDeletedListingItems}
          onRowClick={onTableRowClick}
          expandedRowsDelegationUuids={expandedRowsDelegationUuids}
          isError={delegationList.isError}
          isLoading={delegationList.isLoading}
          onSelectDelegationToDelete={onSelectDelegationToDelete}
          isDownloadCSVEnabled={isDownloadCSVEnabled}
          isDownloadPDFEnabled={isDownloadPDFEnabled}
          actionDataList={actionDataList}
          onActionButtonClick={onActionButtonClick}
          onActionSelectAllButtonClick={onActionSelectAllButtonClick}
        />
        <ListingPaginationComponent
          onPageChange={(number) => {
            setPage(number);
          }}
          onPageSizeChange={(number) => {
            setPageSize(number);
          }}
          page={page}
          pageSize={pageSize}
          totalResults={delegationList.data.totalCount}
        />
        <DelegationDeleteComponent
          isOpen={delegationDeleteModalOpen.isOpen}
          onClose={delegationDeleteModalOpen.close}
          delegationData={delegationSelectedToDelete}
          onSuccess={deleteDelegation}
          isDeletePending={false}
        />
      </CardComponent>
      {downloadModalOpen.isOpen && (
        <DelegationListingActionsModalComponent
          actionDataList={actionDataList}
          onSuccess={delegationDownload}
          onCancel={downloadModalOpen.close}
        />
      )}
    </>
  );
};

export default DelegationListingComponent;
