import appConfig from "../../../../../../app.config";
import SignUpDealerRequest from "./sign-up-dealer.request";
import SignUpDealerResponse from "./sign-up-dealer.response";

const signUpDealer = async (
  request: SignUpDealerRequest,
  token: string
): Promise<SignUpDealerResponse> => {
  const path = `/dealers`;
  const url = `${appConfig.baseApiUrl}${path}`;

  const init: RequestInit = {
    body: JSON.stringify(request),
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const r = await fetch(url, init);
  const responseData = await r.json();
  return {
    status: r.status,
    data: responseData,
  };
};

const signUpDealerApiService = {
  signUpDealer,
};

export default signUpDealerApiService;
