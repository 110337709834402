import classNames from "classnames";
import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import ComponentClassnames from "../../../types/component-classnames";
import ButtonType from "../button.type";

export type LinkButtonProps = {
  to: string;
  children: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  classNames?: ComponentClassnames & {
    content?: string;
  };
  isDisabled?: boolean;
  type?: ButtonType;
  isLoading?: boolean;
  title?: string;
  idForTesting?: string;
  openInNewTab?: boolean;
};

const LinkButtonComponent: FC<LinkButtonProps> = (props) => {
  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    props.onClick && props.onClick(event);
  };

  const getClassnameByType = () => {
    switch (props.type) {
      case "primary":
        return "primary";
      case "brand":
        return "brand";
      case "danger":
        return "danger";
      case "success":
        return "success";
      case "warning":
        return "warning";
      case "info":
        return "info";
      default:
        return undefined;
    }
  };

  const rootClassNames = classNames(
    "button",
    props.classNames?.root,
    props.isDisabled && "disabled",
    props.isLoading && "loading",
    getClassnameByType()
  );

  const buttonContentClassnames = classNames(
    "button_content",
    props.classNames?.content,
    props.isLoading && "loading"
  );

  return (
    <Link
      target={props.openInNewTab ? "_blank" : undefined}
      to={props.to}
      className={rootClassNames}
      data-test-id={props.idForTesting}
      onClick={onClick}
      title={props.title}
    >
      <div className={buttonContentClassnames}>{props.children}</div>
    </Link>
  );
};

export default LinkButtonComponent;
