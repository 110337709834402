import Joi from "joi";
import SelectOption from "../../../../../common/components/form/select/common/option/select-option";
import UserCitizenship from "../../../../../common/types/user-citizenship";
import UserFormOfEmployment from "../../../../../common/types/user-form-of-employment";
import UserLanguage from "../../../../../common/types/user-language";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../../common/utils/validation/types/form-validation-result";
import authValidationService from "../../../common/validation/auth-validation.service";
import SignUpDriverAddressType from "../common/types/sign-up-driver-address-type";
import SignUpDriverFormUserAddress from "../common/types/sign-up-driver-form-user-address";

const validateBirthDate = (birthDate: Date | null): FormValidationResult => {
  const validationSchema = Joi.date().required();

  return formValidationService.validate(birthDate, validationSchema);
};

const validateBirthPlace = (birthPlace: string): FormValidationResult => {
  const validationSchema = Joi.string().min(2).max(50).required();

  return formValidationService.validate(birthPlace, validationSchema);
};

const validatePersonalIdNumber = (
  personalIdNumber: string
): FormValidationResult => {
  const pattern = new RegExp(`^[0-9]*$`);
  const validationSchema = Joi.string()
    .min(2)
    .max(50)
    .regex(pattern)
    .required();

  return formValidationService.validate(personalIdNumber, validationSchema);
};

const validateCitizenship = (
  citizenshipSelectOption: SelectOption<UserCitizenship> | null
): FormValidationResult => {
  const validValues = Object.values(UserCitizenship);

  const validationSchema = Joi.string()
    .valid(...validValues)
    .required();

  const citizenship = citizenshipSelectOption?.value;

  return formValidationService.validate(citizenship, validationSchema);
};

const validateLanguages = (
  languageSelectOptions: SelectOption<UserLanguage>[] | null
): FormValidationResult => {
  const validValues = Object.values(UserLanguage);

  const validationSchema = Joi.array()
    .min(1)
    .max(10)
    .items(Joi.string().valid(...validValues))
    .required();

  const languages = languageSelectOptions?.map((item) => item.value);

  return formValidationService.validate(languages, validationSchema);
};

const validateYearsOfExperience = (
  yearsOfExperience: number | null
): FormValidationResult => {
  const validationSchema = Joi.number().integer().min(0).required();

  return formValidationService.validate(yearsOfExperience, validationSchema);
};

const validateFormOfEmployment = (
  formOfEmploymentOption: SelectOption<UserFormOfEmployment> | null
): FormValidationResult => {
  const validValues = Object.values(UserFormOfEmployment);

  const validationSchema = Joi.array()
    .valid(...validValues)
    .required();

  const formOfEmployment = formOfEmploymentOption?.value;

  return formValidationService.validate(formOfEmployment, validationSchema);
};

const validateAddresses = (
  addresses: SignUpDriverFormUserAddress[]
): FormValidationResult => {
  const lengthValidationSchema = Joi.array().length(2).required();

  const lengthValidationResult = formValidationService.validate(
    addresses,
    lengthValidationSchema
  );

  if (!lengthValidationResult.isValid) {
    return lengthValidationResult;
  }

  const mailingAddress = addresses.find(
    (item) => item.type === SignUpDriverAddressType.MAILING
  );
  const startingAddress = addresses.find(
    (item) => item.type === SignUpDriverAddressType.STARTING
  );

  if (!mailingAddress) {
    return formValidationService.bad(
      formValidationService.getValidationCustomMessages()
        .driverSignUpMailingAddressValidationError
    );
  }

  if (!startingAddress) {
    return formValidationService.bad(
      formValidationService.getValidationCustomMessages()
        .driverSignUpStartingAddressValidationError
    );
  }

  return lengthValidationResult;
};

const signUpDriverUserDataFormValidationService = {
  validateFirstname: authValidationService.validateFirstName,
  validateLastname: authValidationService.validateLastName,
  validateMobile: authValidationService.validateMobile,
  validateAlternativeMobile: authValidationService.validateAlternativeMobile,
  validateBirthDate,
  validateBirthPlace,
  validatePersonalIdNumber,
  validateCitizenship,
  validateLanguages,
  validateYearsOfExperience,
  validateFormOfEmployment,
  validateAddresses,
};

export default signUpDriverUserDataFormValidationService;
