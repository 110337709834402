import UserRole from "../../../../../common/types/user-role";
import UserPermissions from "../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../common/utils/user/permissions/user-permissions-definition";

export type TaxiListingUserPermissionOption =
  | "hasAccessToCargoContractListing"
  | "hasAccessToTaxiContractListingForRailyOfficer"
  | "hasAccessToTaxiContractListing"
  | "hasAccessToTaxiFleetPartnerListing";

export type TaxiListingUserPermissionsDefinition =
  UserPermissionsDefinition<TaxiListingUserPermissionOption>;

export type TaxiListingUserPermissions =
  UserPermissions<TaxiListingUserPermissionOption>;

const taxiListingUserPermissionDefinition: TaxiListingUserPermissionsDefinition =
  {
    hasAccessToCargoContractListing: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
    hasAccessToTaxiContractListingForRailyOfficer: [UserRole.RAILY_OFFICER],
    hasAccessToTaxiContractListing: [UserRole.ADMIN, UserRole.TAXI_OFFICER],
    hasAccessToTaxiFleetPartnerListing: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
    ],
  };

export default taxiListingUserPermissionDefinition;
