import { useEffect, useState } from "react";
import FormValidationResult from "../../utils/validation/types/form-validation-result";
import formValidationService from "../../utils/validation/form-validation.service";
import { useAppContext } from "../../../context/app.context";

export type FormValidationDefinition<Values extends Record<string, unknown>> =
  Record<
    keyof Values,
    (values: Values) => FormValidationResult | Promise<FormValidationResult>
  >;

type FormConfig<Values extends Record<string, unknown>> = {
  emptyValues: Values;
  validationDefinition: FormValidationDefinition<Values>;
};

const useForm = <Values extends Record<string, unknown>>(
  params: FormConfig<Values>
) => {
  const { selectedAppLanguage } = useAppContext();

  const [values, setValues] = useState<Values>(params.emptyValues);

  useEffect(() => {
    const revalidationFields = Object.keys(validationResults).filter(
      (formKey) => validationResults[formKey].isValid === false
    );

    revalidationFields.forEach((field) => validateField(field));
  }, [selectedAppLanguage]);

  const getDefaultValidationResults = (): Record<
    keyof Values,
    FormValidationResult
  > => {
    const results: Record<keyof Values, FormValidationResult> = {} as Record<
      keyof Values,
      FormValidationResult
    >;

    Object.keys(params.emptyValues).forEach((formKey) => {
      results[formKey as keyof Values] =
        formValidationService.defaultValidationResult;
    });

    return results;
  };

  const getDefaultDirtyFields = (): Record<keyof Values, boolean> => {
    const results: Record<keyof Values, boolean> = {} as Record<
      keyof Values,
      boolean
    >;

    Object.keys(params.emptyValues).forEach((formKey) => {
      results[formKey as keyof Values] = false;
    });

    return results;
  };

  const [validationResults, setValidationResults] = useState<
    Record<keyof Values, FormValidationResult>
  >(() => getDefaultValidationResults());

  const [dirtyFields, setDirtyFields] = useState<Record<keyof Values, boolean>>(
    () => getDefaultDirtyFields()
  );

  const setValue = (formKey: keyof Values, value: Values[keyof Values]) => {
    setValues((curr) => ({
      ...curr,
      [formKey]: value,
    }));

    setDirtyFields((curr) => ({
      ...curr,
      [formKey]: true,
    }));
  };

  const validate = async (
    formKey: keyof Values,
    value?: unknown
  ): Promise<boolean> => {
    //topic to analyze after modifying ux datepicker- for the consistency of the entire application,
    // it is worth considering the same behavior for datapikers in the context of onblur

    if (!dirtyFields[formKey] && value === undefined) return true;

    return validateField(formKey, value);
  };

  const validateField = async (
    formKey: keyof Values,
    value?: unknown
  ): Promise<boolean> => {
    const valuesToValidation: Record<keyof Values, unknown> = { ...values };

    if (value) {
      valuesToValidation[formKey] = value;
    }

    const validationResult = await params.validationDefinition[formKey](
      valuesToValidation as Values
    );

    setValidationResults((curr) => ({
      ...curr,
      [formKey]: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateAll = async (): Promise<boolean> => {
    const formKeys = Object.keys(params.emptyValues) as (keyof Values)[];

    const validationResults: Record<keyof Values, boolean> = {} as Record<
      keyof Values,
      boolean
    >;

    for (const formKey of formKeys) {
      validationResults[formKey] = await validateField(formKey as keyof Values);
    }

    return Object.values(validationResults).every((item) => item === true);
  };

  const updateValues = (values: Values) => {
    setValues(values);
  };

  const restoreValues = () => {
    setValues(params.emptyValues);
  };

  const restoreValidationResults = () => {
    const defaultResults = getDefaultValidationResults();
    setValidationResults(defaultResults);
  };

  const restore = () => {
    restoreValues();
    restoreValidationResults();
  };

  return {
    values,
    setValue,
    setValues: updateValues,
    restoreValues,
    validate,
    validateAll,
    validationResults,
    restoreValidationResults,
    restore,
    setValidationResults,
  };
};

export default useForm;
