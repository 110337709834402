import { FC, useEffect, useState } from "react";
import CardComponent from "../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../common/components/form/input/input.component";
import Column from "../../../../common/components/grid/column";
import Row from "../../../../common/components/grid/row";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import formValidationService from "../../../../common/utils/validation/form-validation.service";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import orderBreadcrumbsHelper from "../../common/breadcrumbs/order-breadcrumbs.helper";
import orderAddApiService from "../common/api/order-add-api.service";
import OrderAddCargoCompaniesResponse, {
  OrderAddCargoCompaniesResponseCompany,
} from "../common/api/order-add-cargo-companies.response";
import OrderAddDispatchersResponse, {
  OrderAddDispatchersResponseItem,
} from "../common/api/order-add-dispatchers.response";
import OrderAddPassengersComponent from "../common/components/passengers/order-add-passengers.component";
import OrderAddRouteValidationModalComponent from "../common/components/route-validation-modal/order-add-route-validation-modal.component";
import OrderAddRoutesComponent from "../common/components/routes/order-add-routes.component";
import OrderAddSummaryComponent from "../common/components/summary/order-add-summary.component";
import orderAddFactory from "../common/factory/order-add.factory";
import OrderAddBasicProps from "../common/types/order-add-basic-props";
import OrderAddCargoCompanySelectOption from "../common/types/order-add-cargo-company-select-option";
import OrderAddDispatcherSelectOption from "../common/types/order-add-dispatcher-select-option";
import OrderAddFormValidationResults from "../common/types/order-add-form-validation-results";
import OrderAddPassenger, {
  OrderAddPassengerAddress,
} from "../common/types/order-add-passenger";
import OrderAddPassengerListItem from "../common/types/order-add-passenger-list-item";
import OrderAddRouteItem, {
  OrderAddRouteWaypoint,
} from "../common/types/order-add-route-waypoint";
import OrderAddTaxiCorporationSelectOption from "../common/types/order-add-taxi-corporation-select-option";
import orderAddValidationService from "../common/validation/order-add-validation.service";
import OrderAddChangeContractorConfirmationModalComponent from "./change-contractor-modal/order-add-change-contractor-confirmation-modal.component";
import SingleSelectComponent from "../../../../common/components/form/select/single-select/single-select.component";
import contractService from "../../../../common/services/contract/contract.service";
import useAbort from "../../../../common/hooks/use-abort";
import ContractValidCollectionResponse, {
  ContractValidCollectionResponseData,
} from "../../../../common/api/raily/contracts/valid-collection/contract-valid-collection.response";
import uuidService from "../../../../common/utils/uuid/uuid.service";

type RailyOrderAddProps = OrderAddBasicProps;

const RailyOrderAddComponent: FC<RailyOrderAddProps> = (props) => {
  const [
    selectedCargoCompanySelectOption,
    setSelectedCargoCompanySelectOption,
  ] = useState<OrderAddCargoCompanySelectOption | null>(null);
  const [selectedDispatcherSelectOption, setSelectedDispatcherSelectOption] =
    useState<OrderAddDispatcherSelectOption | null>(null);
  const [
    selectedTaxiCorporationSelectOption,
    setSelectedTaxiCorporationSelectOption,
  ] = useState<OrderAddTaxiCorporationSelectOption | null>(null);
  const [passengerList, setPassengerList] = useState<
    OrderAddPassengerListItem[]
  >([]);
  const [selectedPassengerUuid, setSelectedPassengerUuid] = useState<
    OrderAddPassenger["uuid"] | null
  >(null);
  const [routes, setRoutes] = useState<OrderAddRouteItem[]>([]);
  const [isReturnRideActive, setIsReturnRideActive] = useState<string[]>([]);

  const [returnRideRouteItemUuids, setReturnRideRouteItemUuids] = useState<
    {
      onboarding: string | null;
      outboarding: string | null;
      passengerUuid: string;
    }[]
  >([]);

  const [formValidationResults, setFormValidationResults] =
    useState<OrderAddFormValidationResults>({
      contractor: formValidationService.defaultValidationResult,
      dispatcher: formValidationService.defaultValidationResult,
      taxiCorporation: formValidationService.defaultValidationResult,
      orderNumber: formValidationService.defaultValidationResult,
    });
  const [routesValidationErrors, setRoutesValidationErrors] = useState<
    string[]
  >([]);
  const [orderNumber, setOrderNumber] = useState("");

  const validateContractor = (
    contractor: OrderAddCargoCompanySelectOption | null
  ) => {
    const validationResult =
      orderAddValidationService.validateContractor(contractor);

    setFormValidationResults((curr) => ({
      ...curr,
      contractor: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateDispatcher = (
    dispatcher: OrderAddDispatcherSelectOption | null
  ) => {
    const validationResult =
      orderAddValidationService.validateDispatcher(dispatcher);

    setFormValidationResults((curr) => ({
      ...curr,
      dispatcher: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateTaxiCorporation = (
    taxiCorporation: OrderAddTaxiCorporationSelectOption | null
  ) => {
    const validationResult =
      orderAddValidationService.validateTaxiCorporation(taxiCorporation);

    setFormValidationResults((curr) => ({
      ...curr,
      taxiCorporation: validationResult,
    }));

    return validationResult.isValid;
  };

  const translations = orderTranslationsHelper.getAddTranslations();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.orderAdd);

  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  const [areCargoCompaniesFetching, setAreCargoCompaniesFetching] =
    useState(false);

  const [isCargoCompaniesFetchingError, setIsCargoCompaniesFetchingError] =
    useState(false);

  const [cargoCompaniesSelectOptions, setCargoCompaniesSelectOptions] =
    useState<OrderAddCargoCompanySelectOption[]>([]);

  const [areDispatchersFetching, setAreDispatchersFetching] = useState(false);

  const [isDispatchersFetchingError, setIsDispatchersFetchingError] =
    useState(false);

  const [dispatchersSelectOptions, setDispatchersSelectOptions] = useState<
    OrderAddDispatcherSelectOption[]
  >([]);

  const [areTaxiContractsFetching, setAreTaxiContractsFetching] =
    useState(false);

  const [isTaxiContractsFetchingError, setIsTaxiContractsFetchingError] =
    useState(false);

  const [taxiCorporationsSelectOptions, setTaxiCorporationsSelectOptions] =
    useState<OrderAddTaxiCorporationSelectOption[]>([]);

  const [
    contractorSelectCandidateSelectOption,
    setContractorSelectCandidateSelectOption,
  ] = useState<OrderAddCargoCompanySelectOption | null>(null);

  const [
    isContractorChangeConfirmationModalOpen,
    setIsContractorChangeConfirmationModalOpen,
  ] = useState(false);

  const validateOrderNumber = (orderNumber: string) => {
    const validationResult =
      orderAddValidationService.validateOrderNumber(orderNumber);

    setFormValidationResults((curr) => ({
      ...curr,
      orderNumber: validationResult,
    }));

    return validationResult.isValid;
  };

  const [isRouteErrorsModalOpen, setIsRouteErrorsModalOpen] = useState(false);

  const contractAbort = useAbort();

  useEffect(() => {
    const breadcrumbs = orderBreadcrumbsHelper.getAddBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  // temporary solution - remove after refactor
  useEffect(() => {
    const revalidationFields = Object.keys(formValidationResults).filter(
      (formKey) =>
        formValidationResults[formKey as keyof OrderAddFormValidationResults]
          .isValid === false
    );

    revalidationFields.forEach((field) => {
      switch (field) {
        case "contractor":
          return validateContractor(selectedCargoCompanySelectOption);
        case "dispatcher":
          return validateDispatcher(selectedDispatcherSelectOption);
        case "taxiCorporation":
          return validateTaxiCorporation(selectedTaxiCorporationSelectOption);
        case "orderNumber":
          return validateOrderNumber(orderNumber);
      }
    });

    if (routesValidationErrors.length) {
      validateRoutes();
    }
  }, [selectedAppLanguage]);

  const onCargoCompaniesFetchSuccess = (
    responseCompanies: OrderAddCargoCompaniesResponseCompany[]
  ) => {
    const cargoCompaniesSelectOptions =
      orderAddFactory.createCargoCompaniesSelectOptions(responseCompanies);

    setCargoCompaniesSelectOptions(cargoCompaniesSelectOptions);
  };

  const handleCargoCompaniesResponse = (
    response: OrderAddCargoCompaniesResponse
  ) => {
    if (response.status === 200) {
      onCargoCompaniesFetchSuccess(response.data);
      return;
    }

    setIsCargoCompaniesFetchingError(true);
  };

  useEffect(() => {
    setAreCargoCompaniesFetching(true);

    orderAddApiService
      .fetchCargoCompanies()
      .then(handleCargoCompaniesResponse)
      .finally(() => {
        setAreCargoCompaniesFetching(false);
      });
  }, []);

  const onTaxiContractsFetchSuccess = (
    responseTaxiContractsData: ContractValidCollectionResponseData
  ) => {
    const taxiCorporationsSelectOptions =
      orderAddFactory.createTaxiCorporationsSelectOptions(
        responseTaxiContractsData
      );

    setTaxiCorporationsSelectOptions(taxiCorporationsSelectOptions);

    if (taxiCorporationsSelectOptions.length === 1) {
      setSelectedTaxiCorporationSelectOption(taxiCorporationsSelectOptions[0]);
    }
  };

  const handleTaxiContractsResponse = (
    response: ContractValidCollectionResponse
  ) => {
    if (response.status === 200) {
      onTaxiContractsFetchSuccess(response.data);
      return;
    }

    setIsTaxiContractsFetchingError(true);
  };

  useEffect(() => {
    if (!selectedDispatcherSelectOption || !selectedCargoCompanySelectOption) {
      return;
    }

    contractService
      .getValidCollection(
        { cargoCompanyUuid: selectedCargoCompanySelectOption.value.uuid },
        contractAbort.signal
      )
      .then(handleTaxiContractsResponse)
      .finally(() => {
        setAreTaxiContractsFetching(false);
      });
  }, [selectedDispatcherSelectOption?.value.uuid]);

  const onDispatchersFetchSuccess = (
    responseDispatchers: OrderAddDispatchersResponseItem[]
  ) => {
    const dispatchersSelectOptions =
      orderAddFactory.createDispatcherSelectOptions(responseDispatchers);

    setDispatchersSelectOptions(dispatchersSelectOptions);
  };

  const handleDispatchersResponse = (response: OrderAddDispatchersResponse) => {
    if (response.status === 200) {
      onDispatchersFetchSuccess(response.data);
      return;
    }

    setIsDispatchersFetchingError(true);
  };

  useEffect(() => {
    if (!selectedCargoCompanySelectOption) {
      return;
    }

    setAreDispatchersFetching(true);

    orderAddApiService
      .fetchDispatchers(selectedCargoCompanySelectOption.value.uuid)
      .then(handleDispatchersResponse)
      .finally(() => {
        setAreDispatchersFetching(false);
      });
  }, [selectedCargoCompanySelectOption?.value.uuid]);

  const onContractorSelectValueChange = (
    selectOption: OrderAddCargoCompanySelectOption
  ) => {
    if (
      selectedDispatcherSelectOption ||
      selectedTaxiCorporationSelectOption ||
      passengerList.length ||
      orderNumber
    ) {
      setIsContractorChangeConfirmationModalOpen(true);
      setContractorSelectCandidateSelectOption(selectOption);
      validateContractor(selectOption);
      return;
    }

    setSelectedCargoCompanySelectOption(selectOption);
    validateContractor(selectOption);
  };

  const onDispatcherSelectValueChange = (
    selectOption: OrderAddDispatcherSelectOption
  ) => {
    setSelectedDispatcherSelectOption(selectOption);
    validateDispatcher(selectOption);
  };

  const onTaxiCorporationSelectValueChange = (
    selectOption: OrderAddTaxiCorporationSelectOption
  ) => {
    setSelectedTaxiCorporationSelectOption(selectOption);
    validateTaxiCorporation(selectOption);
  };

  useEffect(() => {
    if (!routesValidationErrors.length) {
      return;
    }

    setIsRouteErrorsModalOpen(true);
  }, [routesValidationErrors]);

  const clearForm = () => {
    setSelectedCargoCompanySelectOption(null);
    setSelectedDispatcherSelectOption(null);
    setSelectedTaxiCorporationSelectOption(null);
    setPassengerList([]);
    setOrderNumber("");
    setRoutes([]);
  };

  const onContractorChangeConfirmButtonClick = () => {
    clearForm();
    setIsContractorChangeConfirmationModalOpen(false);
    setSelectedCargoCompanySelectOption(contractorSelectCandidateSelectOption);
  };

  const isContractorSelectDisabled = isCargoCompaniesFetchingError;

  const isDispatcherSelectDisabled =
    isDispatchersFetchingError || !selectedCargoCompanySelectOption;

  const isTaxiSelectDisabled =
    !selectedDispatcherSelectOption || isTaxiContractsFetchingError;

  const restoreForm = () => {
    setSelectedCargoCompanySelectOption(null);
    setSelectedDispatcherSelectOption(null);
    setSelectedTaxiCorporationSelectOption(null);
    setPassengerList([]);
    setRoutes([]);
    setOrderNumber("");
    setFormValidationResults({
      contractor: formValidationService.defaultValidationResult,
      dispatcher: formValidationService.defaultValidationResult,
      taxiCorporation: formValidationService.defaultValidationResult,
      orderNumber: formValidationService.defaultValidationResult,
    });
    setRoutesValidationErrors([]);
  };

  const validateRoutes = (): boolean => {
    const validationErrors = orderAddValidationService.validateRoutes(
      passengerList,
      routes
    );

    const resolvedErrors =
      orderAddValidationService.resolveValidationErrorLabels(validationErrors);

    setRoutesValidationErrors(resolvedErrors);

    return !validationErrors.length;
  };

  const validateForm = (): boolean => {
    return (
      validateOrderNumber(orderNumber) &&
      validateContractor(selectedCargoCompanySelectOption) &&
      validateDispatcher(selectedDispatcherSelectOption) &&
      validateTaxiCorporation(selectedTaxiCorporationSelectOption) &&
      validateRoutes()
    );
  };

  const addReturnRide = (
    passenger: OrderAddPassenger,
    address: OrderAddPassengerAddress
  ) => {
    if (
      isReturnRideActive.find(
        (passengerUuid) => passengerUuid === passenger.uuid
      )
    )
      return;

    const filteredWaypoints = routes.filter(
      (route): route is OrderAddRouteWaypoint =>
        "onboardingPassengerListItems" in route && route.address !== null
    );

    const lastDropOffWaypoint = [...filteredWaypoints]
      .reverse()
      .find((route) =>
        route.outboardingPassengerListItems.some(
          (outboardingPassenger) =>
            outboardingPassenger.passenger.uuid === passenger.uuid
        )
      );

    if (!lastDropOffWaypoint || !lastDropOffWaypoint.address) {
      return;
    }

    const passengerItemUuid =
      lastDropOffWaypoint.outboardingPassengerListItems.find(
        (outboardingPassenger) =>
          outboardingPassenger.passenger.uuid === passenger.uuid
      )?.uuid;

    if (!passengerItemUuid) {
      return;
    }

    const commonOutboardingWaypoint = [...filteredWaypoints]
      .reverse()
      .find((route) =>
        route.outboardingPassengerListItems.some(
          (outboardingPassenger) =>
            outboardingPassenger.passenger.uuid === passenger.uuid
        )
      );

    let existingOutboardingRoute: OrderAddRouteWaypoint | undefined;

    for (let returnWayppintData of returnRideRouteItemUuids) {
      existingOutboardingRoute = filteredWaypoints.find(
        (route) => route.uuid === returnWayppintData.onboarding
      )!;
      if (
        commonOutboardingWaypoint?.address === existingOutboardingRoute?.address
      ) {
        break;
      }
    }

    let returnPickupWaypoint: OrderAddRouteWaypoint = {
      uuid: uuidService.generate(),
      address:
        commonOutboardingWaypoint?.address || lastDropOffWaypoint.address,
      date: null,
      haltingTimeMinutes: null,
      onboardingPassengerListItems: [{ uuid: passengerItemUuid, passenger }],
      outboardingPassengerListItems: [],
    };

    if (existingOutboardingRoute) {
      returnPickupWaypoint = {
        ...existingOutboardingRoute,
        onboardingPassengerListItems: [
          ...existingOutboardingRoute.onboardingPassengerListItems,
          { uuid: passengerItemUuid, passenger },
        ],
      };
    }

    const returnDropOffWaypoint: OrderAddRouteWaypoint = {
      uuid: uuidService.generate(),
      address: {
        uuid: address.uuid,
        displayName: address.displayName,
        latitude: address.latitude,
        longitude: address.longitude,
        zipCode: address.zipCode,
        town: address.town,
        street: address.street,
        houseNumber: address.houseNumber,
        apartmentNumber: address.apartmentNumber,
      },
      date: null,
      haltingTimeMinutes: null,
      onboardingPassengerListItems: [],
      outboardingPassengerListItems: [{ uuid: passengerItemUuid, passenger }],
    };

    const newIsReturnRideActive = [...isReturnRideActive, passenger.uuid];

    if (existingOutboardingRoute) {
      setRoutes((prevRoutes) => {
        const existingIndex = prevRoutes.findIndex(
          (route) => route.uuid === returnPickupWaypoint.uuid
        );
        prevRoutes[existingIndex] = returnPickupWaypoint;
        return [...prevRoutes, returnDropOffWaypoint];
      });
    } else {
      setRoutes((prevRoutes) => [
        ...prevRoutes,
        returnPickupWaypoint,
        returnDropOffWaypoint,
      ]);
    }
    setReturnRideRouteItemUuids([
      ...returnRideRouteItemUuids,
      {
        onboarding: returnPickupWaypoint.uuid,
        outboarding: returnDropOffWaypoint.uuid,
        passengerUuid: passenger.uuid,
      },
    ]);
    setIsReturnRideActive(newIsReturnRideActive);
  };

  return (
    <>
      <div className="order_add">
        <HeadingComponent
          title={translations.header.headingText}
          actions={props.changeViewButtons}
        />
        <Row>
          <Column xl={7}>
            <CardComponent header={{ title: translations.order.headingText }}>
              <Row>
                <Column lg={4}>
                  <FormFieldComponent
                    label={translations.order.contractorLabel}
                    classNames={{ root: `mt-0` }}
                    isRequired
                    errorMessage={formValidationResults.contractor.errorMessage}
                  >
                    <SingleSelectComponent
                      placeholder={
                        translations.order.contractorSelectInputPlaceholder
                      }
                      value={selectedCargoCompanySelectOption}
                      onChange={(value) =>
                        onContractorSelectValueChange(value!)
                      }
                      options={cargoCompaniesSelectOptions}
                      isLoading={areCargoCompaniesFetching}
                      isDisabled={isContractorSelectDisabled}
                      isSearchable
                      idForTesting="contractor-select"
                      hasError={!!formValidationResults.contractor.errorMessage}
                    />
                  </FormFieldComponent>
                </Column>
                <Column lg={4}>
                  <FormFieldComponent
                    label={translations.order.dispatcherLabel}
                    classNames={{ root: `mt-0` }}
                    isRequired
                    errorMessage={formValidationResults.dispatcher.errorMessage}
                  >
                    <SingleSelectComponent
                      placeholder={
                        translations.order.dispatcherSelectInputPlaceholder
                      }
                      value={selectedDispatcherSelectOption}
                      onChange={(value) =>
                        onDispatcherSelectValueChange(value!)
                      }
                      options={dispatchersSelectOptions}
                      isLoading={areDispatchersFetching}
                      isDisabled={isDispatcherSelectDisabled}
                      idForTesting="dispatcher-select"
                      hasError={!!formValidationResults.dispatcher.errorMessage}
                      isSearchable
                    />
                  </FormFieldComponent>
                </Column>
                <Column lg={4}>
                  <FormFieldComponent
                    label={translations.order.taxiLabel}
                    classNames={{ root: `mt-0` }}
                    isRequired
                    errorMessage={
                      formValidationResults.taxiCorporation.errorMessage
                    }
                  >
                    <SingleSelectComponent
                      placeholder={
                        translations.order.taxiSelectInputPlaceholder
                      }
                      value={selectedTaxiCorporationSelectOption}
                      onChange={(value) =>
                        onTaxiCorporationSelectValueChange(value!)
                      }
                      options={taxiCorporationsSelectOptions}
                      isLoading={areTaxiContractsFetching}
                      isDisabled={isTaxiSelectDisabled}
                      idForTesting="taxi-select"
                      hasError={
                        !!formValidationResults.taxiCorporation.errorMessage
                      }
                      isSearchable
                    />
                  </FormFieldComponent>
                </Column>
              </Row>
              <Row>
                <Column lg={4}>
                  <OrderAddPassengersComponent
                    onPassengerListChange={setPassengerList}
                    passengerList={passengerList}
                    onSelectedPassengerUuidChange={setSelectedPassengerUuid}
                    contractorUuid={
                      selectedCargoCompanySelectOption?.value.uuid ?? null
                    }
                    selectedPassengerUuid={selectedPassengerUuid}
                    addReturnRide={addReturnRide}
                    isReturnRideActive={isReturnRideActive}
                    routes={routes}
                    setIsReturnRideActive={setIsReturnRideActive}
                  />
                </Column>
                <Column lg={8}>
                  <OrderAddRoutesComponent
                    onRoutesChange={setRoutes}
                    passengerList={passengerList}
                    routes={routes}
                    contractorUuid={
                      selectedCargoCompanySelectOption?.value.uuid ?? null
                    }
                    isReturnRideActive={isReturnRideActive}
                    setIsReturnRideActive={setIsReturnRideActive}
                    returnRideRouteItemUuids={returnRideRouteItemUuids}
                    setReturnRideRouteItemUuids={setReturnRideRouteItemUuids}
                  />
                </Column>
              </Row>
              <Row>
                <Column lg={4}>
                  <FormFieldComponent
                    label={translations.order.orderNumberLabel}
                    errorMessage={
                      formValidationResults.orderNumber.errorMessage
                    }
                  >
                    <InputComponent
                      placeholder={
                        translations.order.orderNumberInputPlaceholder
                      }
                      value={orderNumber}
                      onChange={setOrderNumber}
                      onBlur={() => validateOrderNumber(orderNumber)}
                      hasError={
                        !!formValidationResults.orderNumber.errorMessage
                      }
                      idForTesting="order-number"
                    />
                  </FormFieldComponent>
                </Column>
              </Row>
            </CardComponent>
          </Column>
          <Column xl={5}>
            <OrderAddSummaryComponent
              passengerList={passengerList}
              routes={routes}
              selectedTaxiCorporation={
                selectedTaxiCorporationSelectOption?.value ?? null
              }
              orderNumber={orderNumber}
              restoreForm={restoreForm}
              selectedDispatcherUuid={
                selectedDispatcherSelectOption?.value.uuid ?? null
              }
              validateForm={validateForm}
              selectedPassengerUuid={selectedPassengerUuid}
              setValidationMessage={setRoutesValidationErrors}
              setIsReturnRideActive={setIsReturnRideActive}
            />
          </Column>
        </Row>
      </div>
      <OrderAddChangeContractorConfirmationModalComponent
        isModalOpen={isContractorChangeConfirmationModalOpen}
        onCloseClick={() => {
          setIsContractorChangeConfirmationModalOpen(false);
        }}
        onConfirmClick={onContractorChangeConfirmButtonClick}
      />
      <OrderAddRouteValidationModalComponent
        isModalOpen={isRouteErrorsModalOpen}
        onCloseClick={() => {
          setIsRouteErrorsModalOpen(false);
        }}
        routesValidationErrors={routesValidationErrors}
      />
    </>
  );
};

export default RailyOrderAddComponent;
