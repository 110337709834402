import { FC, useEffect, useMemo, useState } from "react";
import DriverDetailsDriverPlanEntry, {
  DriverDetailsDriverPlanEntryNodeExclusions,
  DriverDetailsDriverPlanEntryWaypoint,
  DriverDetailsDriverPlanEntryWaypointType,
} from "./common/types/driver-details-plan-entry";
import driverDetailsActiveRoutePlansByRailyApiService from "./by-raily/common/api/driver-details-active-route-plans-by-raily-api.service";
import DriverDetailsPlanByRailyRouteListingComponent from "./route-listing/driver-details-plan-by-raily-route-listing.component";
import Row from "../../../../../common/components/grid/row";
import Column from "../../../../../common/components/grid/column";
import MapComponent from "../../../../../common/components/map/map.component";
import ButtonComponent from "../../../../../common/components/button/button.component";
import MapMarker from "../../../../../common/components/map/types/map-marker";
import SearchRoadRoutingRequest from "../../../../../common/utils/search-road-route/search-road-routing.request";
import MapRoute from "../../../../../common/components/map/types/map-route";
import DriverDetailsPlanAddCheckoutComponent from "./checkout/add/driver-details-plan-add-checkout.component";
import DriverDetailsPlanDeleteCheckoutComponent from "./checkout/delete/driver-details-plan-delete-checkout.component";
import DriverDetailsPlanEditCheckoutComponent from "./checkout/edit/driver-details-plan-edit-checkout.component";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import { useAppContext } from "../../../../../context/app.context";
import DriverDetailsPlanDeleteOrderComponent from "./order-delete/driver-details-active-route-plans-order-delete.component";
import mapMarkerIconFactory from "../../../../../common/components/map/marker/map-marker-icon.factory";
import { isEqual } from "lodash";
import ContentWrapper from "../../../../../common/components/content-wrapper/content-wrapper.component";
import MessengerComponent from "../../../../../common/components/messenger/messenger.component";
import DriverDetailsActiveRoutePlansHelper from "./common/driver-details-active-route-plans-helper";
import googleMapsRouteService from "../../../../../common/utils/google-maps-route/google-maps-route.service";
import LinkButtonComponent from "../../../../../common/components/button/link/link-button.component";

type DriverDetailsActiveRoutePlansContentProps = {
  driverUuid: string;
  refetchPlan: () => void;
  planEntries: DriverDetailsDriverPlanEntry[];
  asCardComponent?: boolean;
};

const DriverDetailsActiveRoutePlansContentComponent: FC<
  DriverDetailsActiveRoutePlansContentProps
> = (props) => {
  useAppContext();
  const translations =
    userTranslationsHelper.getDriverDetailsActiveRoutePlansTranslations();

  const [isAddCheckoutModalOpen, setIsAddCheckoutModalOpen] = useState(false);
  const [isEditCheckoutModalOpen, setIsEditCheckoutModalOpen] = useState(false);
  const [isDeleteCheckoutModalOpen, setIsDeleteCheckoutModalOpen] =
    useState(false);
  const [isDeleteOrderModalOpen, setIsDeleteOrderModalOpen] = useState(false);

  const [mapRoutes, setMapRoutes] = useState<MapRoute[]>([]);

  const [
    selectedRouteWaypointUuidForCheckoutOperations,
    setSelectedRouteWaypointUuidForCheckoutOperations,
  ] = useState("");

  const [
    selectedPlanEntryUuidForCheckoutOperations,
    setSelectedPlanEntryUuidForCheckoutOperations,
  ] = useState("");

  const [
    selectedCheckoutEventUuidToDelete,
    setSelectedCheckoutEventUuidForCheckoutOperations,
  ] = useState("");

  const [selectedPlanEntryUuid, setSelectedPlanEntryUuid] = useState("");
  const [
    selectedPlanEntryUuidForDeleteOrderOperations,
    setSelectedPlanEntryUuidForDeleteOrderOperations,
  ] = useState("");

  const openDeleteOrderModal = () => {
    setIsDeleteOrderModalOpen(true);
  };

  const closeDeleteOrderModal = () => {
    setIsDeleteOrderModalOpen(false);
  };

  const openAddCheckoutModal = () => {
    setIsAddCheckoutModalOpen(true);
  };

  const closeAddCheckoutModal = () => {
    setIsAddCheckoutModalOpen(false);
  };

  const openEditCheckoutModal = () => {
    setIsEditCheckoutModalOpen(true);
  };

  const closeEditCheckoutModal = () => {
    setIsEditCheckoutModalOpen(false);
  };

  const openDeleteCheckoutModal = () => {
    setIsDeleteCheckoutModalOpen(true);
  };

  const closeDeleteCheckoutModal = () => {
    setIsDeleteCheckoutModalOpen(false);
  };

  const onAddCheckoutSuccess = () => {
    closeAddCheckoutModal();
    props.refetchPlan();
  };

  const onEditCheckoutSuccess = () => {
    closeEditCheckoutModal();
    props.refetchPlan();
  };

  const onDeleteCheckoutSuccess = () => {
    closeDeleteCheckoutModal();
    props.refetchPlan();
  };

  const onDeleteOrderSuccess = () => {
    closeDeleteOrderModal();
    props.refetchPlan();
  };

  const onAddCheckoutButtonClick = (
    routeWaypointUuid: DriverDetailsDriverPlanEntryWaypoint["uuid"],
    planEntryUuid: DriverDetailsDriverPlanEntry["uuid"]
  ) => {
    openAddCheckoutModal();
    setSelectedRouteWaypointUuidForCheckoutOperations(routeWaypointUuid);
    setSelectedPlanEntryUuidForCheckoutOperations(planEntryUuid);
  };

  const onEditCheckoutButtonClick = (
    routeWaypointUuid: DriverDetailsDriverPlanEntryWaypoint["uuid"],
    checkoutEventUuid: DriverDetailsDriverPlanEntryWaypoint["checkoutEventUuid"],
    planEntryUuid: DriverDetailsDriverPlanEntry["uuid"]
  ) => {
    openEditCheckoutModal();
    setSelectedRouteWaypointUuidForCheckoutOperations(routeWaypointUuid);
    setSelectedCheckoutEventUuidForCheckoutOperations(checkoutEventUuid!);
    setSelectedPlanEntryUuidForCheckoutOperations(planEntryUuid);
  };

  const onDeleteCheckoutButtonClick = (
    routeWaypointUuid: DriverDetailsDriverPlanEntryWaypoint["uuid"],
    checkoutEventUuid: DriverDetailsDriverPlanEntryWaypoint["checkoutEventUuid"],
    planEntryUuid: DriverDetailsDriverPlanEntry["uuid"]
  ) => {
    openDeleteCheckoutModal();
    setSelectedRouteWaypointUuidForCheckoutOperations(routeWaypointUuid);
    setSelectedCheckoutEventUuidForCheckoutOperations(checkoutEventUuid!);
    setSelectedPlanEntryUuidForCheckoutOperations(planEntryUuid);
  };

  useEffect(() => {
    if (!props.planEntries.length) {
      return;
    }

    const nonAnonymizedPlanEntry = props.planEntries.filter(
      (planEntry) => !(planEntry.isHidden || planEntry.isAnonymized)
    )[0];

    setSelectedPlanEntryUuid(nonAnonymizedPlanEntry?.uuid ?? "");
  }, [props.planEntries]);

  const onPlanEntrySelectButtonClick = (
    planEntryUuid: DriverDetailsDriverPlanEntry["uuid"]
  ) => {
    setSelectedPlanEntryUuid(planEntryUuid);
  };

  const onDeleteOrderButtonClick = (
    planEntryUuid: DriverDetailsDriverPlanEntry["uuid"]
  ) => {
    setSelectedPlanEntryUuidForDeleteOrderOperations(planEntryUuid);
    openDeleteOrderModal();
  };

  const isHighwaySegmentAllowed = (
    waypoint: DriverDetailsDriverPlanEntryWaypoint,
    nextWaypoint: DriverDetailsDriverPlanEntryWaypoint,
    planEntry: DriverDetailsDriverPlanEntry
  ): boolean => {
    const waypointIsDriverHome =
      DriverDetailsDriverPlanEntryWaypointType.DRIVER_HOME;

    const hasMatchingUUID =
      (uuid: string) =>
      (excludedNodes: DriverDetailsDriverPlanEntryNodeExclusions) =>
        excludedNodes.nodeIds.includes(uuid);

    const matchedExclusion =
      planEntry.nodeExclusions.find(hasMatchingUUID(waypoint.uuid)) ||
      planEntry.nodeExclusions.find(hasMatchingUUID(nextWaypoint.uuid));

    const highwaySegmentExclusion =
      waypoint.type === waypointIsDriverHome
        ? matchedExclusion?.approaching
        : nextWaypoint.type === waypointIsDriverHome
        ? matchedExclusion?.returning
        : matchedExclusion?.inside;

    return highwaySegmentExclusion!;
  };

  const mapMarkers: MapMarker[] = useMemo(() => {
    if (!selectedPlanEntryUuid) {
      return [];
    }

    const planEntry = props.planEntries.find(
      (planEntry) => planEntry.uuid === selectedPlanEntryUuid
    )!;

    return planEntry.waypoints
      .filter((item) => !item.order || item.order.cargoInternalOrderId !== null)
      .map((item) => {
        const allWaypointsOnThisPlace = planEntry.waypoints.filter((w) =>
          isEqual(w.address, item.address)
        );

        const signature = allWaypointsOnThisPlace
          .map((w) => w.stageNo)
          .join("/");

        const mapMarkerIcon = mapMarkerIconFactory.createStandardIcon({
          content: signature,
        });

        const mapMarker: MapMarker = {
          coordinate: {
            latitude: item.address.latitude,
            longitude: item.address.longitude,
          },
          title: item.address.displayName,
          tooltip: item.address.displayName,
          icon: mapMarkerIcon,
        };

        return mapMarker;
      });
  }, [selectedPlanEntryUuid]);

  useEffect(() => {
    setMapRoutes([]);
    if (!selectedPlanEntryUuid) {
      return;
    }

    const planEntry = props.planEntries.find(
      (planEntry) => planEntry.uuid === selectedPlanEntryUuid
    );

    if (!planEntry) {
      return;
    }

    const waypointPairsForRoute: {
      from: { latitude: number; longitude: number };
      to: { latitude: number; longitude: number };
      excludeHighway: boolean;
    }[] = [];

    planEntry.waypoints.forEach((waypoint, index) => {
      const nextWaypoint = planEntry.waypoints[index + 1];

      if (
        !nextWaypoint ||
        waypoint.order?.cargoInternalOrderId === null ||
        nextWaypoint.order?.cargoInternalOrderId === null
      ) {
        return;
      }

      waypointPairsForRoute.push({
        from: {
          latitude: waypoint.address.latitude,
          longitude: waypoint.address.longitude,
        },
        to: {
          latitude: nextWaypoint.address.latitude,
          longitude: nextWaypoint.address.longitude,
        },
        excludeHighway: isHighwaySegmentAllowed(
          waypoint,
          nextWaypoint,
          planEntry
        ),
      });
    });

    const routeFetchPromises = waypointPairsForRoute.map((pair) => {
      const request: SearchRoadRoutingRequest = {
        waypointCoordinates: [pair.from, pair.to],
        excludeHighway: pair.excludeHighway,
      };

      return driverDetailsActiveRoutePlansByRailyApiService.fetchRoute(request);
    });

    Promise.all(routeFetchPromises).then((responses) => {
      const mapRoutes: MapRoute[] = responses.map((response) => {
        return {
          waypoints: response.routes[0].geometry.coordinates.map(
            (coordinate) => {
              return {
                latitude: coordinate[1],
                longitude: coordinate[0],
              };
            }
          ),
        };
      });
      setMapRoutes(mapRoutes);
    });
  }, [selectedPlanEntryUuid]);

  if (!props.planEntries.length) {
    return (
      <div className="driver_details_active_route_plans">
        <div>{translations.noActivePlansNotificationMessage}</div>
      </div>
    );
  }

  const selectedPlanEntryForCheckoutOperations = props.planEntries.find(
    (planEntry) => planEntry.uuid === selectedPlanEntryUuidForCheckoutOperations
  );

  const selectedPlanEntryForDeleteOrderOperation = props.planEntries.find(
    (planEntry) =>
      planEntry.uuid === selectedPlanEntryUuidForDeleteOrderOperations
  );

  const selectedPlanEntry = props.planEntries.find(
    (planEntry) => planEntry.uuid === selectedPlanEntryUuid
  );

  const selectedPlanEntryWaypointForCheckoutOperations =
    selectedPlanEntryForCheckoutOperations?.waypoints.find(
      (waypoint) =>
        waypoint.uuid === selectedRouteWaypointUuidForCheckoutOperations
    );

  const indexOfSelectedWaypointInPlanEntryWaypointsForCheckoutOperations =
    selectedPlanEntryWaypointForCheckoutOperations
      ? selectedPlanEntryForCheckoutOperations?.waypoints.indexOf(
          selectedPlanEntryWaypointForCheckoutOperations
        ) ?? -1
      : -1;

  const previousPlanEntryWaypointForCheckoutOperations =
    selectedPlanEntryForCheckoutOperations?.waypoints[
      indexOfSelectedWaypointInPlanEntryWaypointsForCheckoutOperations - 1
    ];

  const currentPlanEntryWaypointForCheckoutOperations =
    selectedPlanEntryForCheckoutOperations?.waypoints[
      indexOfSelectedWaypointInPlanEntryWaypointsForCheckoutOperations
    ];

  const isMessengerVisible = !!selectedPlanEntry;

  const messengerChannelsAvailability =
    DriverDetailsActiveRoutePlansHelper.getMessengerChannelAvailability();

  const messengerPostsHeadingText =
    DriverDetailsActiveRoutePlansHelper.getMessengerPostsHeadingText(
      selectedPlanEntry?.id
    );

  return (
    <>
      <div className="driver_details_active_route_plans">
        <Row>
          <Column xl={7}>
            <ContentWrapper asCardComponent={props.asCardComponent}>
              {props.planEntries.map((planEntry) => {
                const googleMapsRouteUrl =
                  googleMapsRouteService.createGoogleMapsRouteUrl(
                    planEntry.waypoints
                      .filter(
                        (waypoint) =>
                          !waypoint.order ||
                          waypoint.order.cargoInternalOrderId !== null
                      )
                      .map((waypoint) => ({
                        latitude: waypoint.address.latitude,
                        longitude: waypoint.address.longitude,
                      }))
                  );

                return (
                  <div key={planEntry.uuid} className="mb-4">
                    <div>
                      {planEntry.isHidden
                        ? translations.hiddenRouteHeadingText
                        : planEntry.isAnonymized
                        ? translations.anonymizedRouteHeadingText
                        : translations.routeHeadingTemplateText.replace(
                            "#{routeId}",
                            String(planEntry.id)
                          )}
                    </div>
                    <DriverDetailsPlanByRailyRouteListingComponent
                      routeWaypoints={planEntry.waypoints}
                      hiddenPlan={planEntry.isHidden}
                      onAddCheckoutButtonClick={(
                        routeWaypointUuid: DriverDetailsDriverPlanEntryWaypoint["uuid"]
                      ) =>
                        onAddCheckoutButtonClick(
                          routeWaypointUuid,
                          planEntry.uuid
                        )
                      }
                      onEditCheckoutButtonClick={(
                        routeWaypointUuid: DriverDetailsDriverPlanEntryWaypoint["uuid"],
                        checkoutEventUuid: DriverDetailsDriverPlanEntryWaypoint["checkoutEventUuid"]
                      ) =>
                        onEditCheckoutButtonClick(
                          routeWaypointUuid,
                          checkoutEventUuid,
                          planEntry.uuid
                        )
                      }
                      onDeleteCheckoutButtonClick={(
                        routeWaypointUuid: DriverDetailsDriverPlanEntryWaypoint["uuid"],
                        checkoutEventUuid: DriverDetailsDriverPlanEntryWaypoint["checkoutEventUuid"]
                      ) =>
                        onDeleteCheckoutButtonClick(
                          routeWaypointUuid,
                          checkoutEventUuid,
                          planEntry.uuid
                        )
                      }
                    />
                    <div className="driver_details_active_route_plans__button_container mt-2">
                      {!planEntry.isHidden && (
                        <>
                          <ButtonComponent
                            onClick={() => {
                              onDeleteOrderButtonClick(planEntry.uuid);
                            }}
                            key={planEntry.uuid}
                            type="danger"
                            title={translations.deleteOrder.deleteButtonTitle}
                          >
                            {translations.deleteOrder.deleteButtonLabel}
                          </ButtonComponent>
                          <LinkButtonComponent
                            to={googleMapsRouteUrl}
                            key={`${planEntry.uuid}-link`}
                            type="primary"
                            title={translations.googleMapsRouteLabel}
                            openInNewTab
                          >
                            {translations.googleMapsRouteLabel}
                          </LinkButtonComponent>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </ContentWrapper>
          </Column>
          <Column xl={5}>
            <ContentWrapper asCardComponent={props.asCardComponent}>
              <div className="d-flex mb-2">
                {props.planEntries
                  .filter(
                    (planEntry) =>
                      !(planEntry.isHidden || planEntry.isAnonymized)
                  )
                  .map((planEntry) => {
                    const isSelected = selectedPlanEntryUuid === planEntry.uuid;

                    return (
                      <ButtonComponent
                        key={planEntry.uuid}
                        onClick={() =>
                          onPlanEntrySelectButtonClick(planEntry.uuid)
                        }
                        type={isSelected ? "success" : undefined}
                      >
                        {planEntry.id}
                      </ButtonComponent>
                    );
                  })}
              </div>
              <div className="driver_details_active_route_plans_map_wrapper">
                <MapComponent
                  markers={mapMarkers}
                  autoFit
                  autoFitOnUpdate
                  routes={mapRoutes}
                />
              </div>
            </ContentWrapper>
            {isMessengerVisible && (
              <ContentWrapper
                asCardComponent={props.asCardComponent}
                classNames={{ root: "mt-4" }}
              >
                <MessengerComponent
                  channelsAvailability={messengerChannelsAvailability}
                  classNames={{
                    root: "driver_details_active_route_plans__messenger",
                  }}
                  postsHeadingText={messengerPostsHeadingText}
                  planEntryUuid={selectedPlanEntry!.uuid}
                  withoutChannelList
                />
              </ContentWrapper>
            )}
          </Column>
        </Row>
      </div>
      <DriverDetailsPlanAddCheckoutComponent
        isOpen={isAddCheckoutModalOpen}
        onClose={closeAddCheckoutModal}
        onAddCheckoutSuccess={onAddCheckoutSuccess}
        driverUuid={props.driverUuid}
        planEntryUuid={selectedPlanEntryUuidForCheckoutOperations}
        waypointUuid={selectedRouteWaypointUuidForCheckoutOperations}
        previousWaypointCheckoutDate={
          previousPlanEntryWaypointForCheckoutOperations?.checkoutDate ?? null
        }
        currentWaypointEstimatedDate={
          (currentPlanEntryWaypointForCheckoutOperations?.estimatedDate &&
            new Date(
              currentPlanEntryWaypointForCheckoutOperations?.estimatedDate
            )) ??
          null
        }
      />
      <DriverDetailsPlanEditCheckoutComponent
        isOpen={isEditCheckoutModalOpen}
        onClose={closeEditCheckoutModal}
        onEditCheckoutSuccess={onEditCheckoutSuccess}
        driverUuid={props.driverUuid}
        planEntry={selectedPlanEntryForCheckoutOperations}
        waypoint={selectedPlanEntryWaypointForCheckoutOperations}
        checkoutEventUuid={selectedCheckoutEventUuidToDelete}
      />
      <DriverDetailsPlanDeleteCheckoutComponent
        isOpen={isDeleteCheckoutModalOpen}
        onClose={closeDeleteCheckoutModal}
        onDeleteCheckoutSuccess={onDeleteCheckoutSuccess}
        driverUuid={props.driverUuid}
        planEntry={selectedPlanEntryForCheckoutOperations}
        waypoint={selectedPlanEntryWaypointForCheckoutOperations}
        checkoutEventUuid={selectedCheckoutEventUuidToDelete}
      />
      <DriverDetailsPlanDeleteOrderComponent
        isOpen={isDeleteOrderModalOpen}
        onClose={closeDeleteOrderModal}
        planEntryWaypoints={
          selectedPlanEntryForDeleteOrderOperation?.waypoints ?? []
        }
        driverUuid={props.driverUuid}
        onDeleteOrderSuccess={onDeleteOrderSuccess}
      />
    </>
  );
};

export default DriverDetailsActiveRoutePlansContentComponent;
