export enum DriverEditParamsAddressType {
  STARTING = "STARTING",
  MAILING = "MAILING",
}

export enum DriverEditParamsFormOfEmployment {
  B2B = "B2B",
  EMPLOYMENT = "EMPLOYMENT",
}

export enum DriverEditParamsCitizenship {
  PL = "PL",
  DE = "DE",
  SK = "SK",
  CZ = "CZ",
  UA = "UA",
  RU = "RU",
  BY = "BY",
  LT = "LT",
}

export enum DriverEditParamsLanguage {
  PL = "PL",
  EN = "EN",
  DE = "DE",
  FR = "FR",
  ES = "ES",
  SK = "SK",
  CZ = "CZ",
  UA = "UA",
  RU = "RU",
  BY = "BY",
  LT = "LT",
}

export enum DriverEditParamsCarOwnership {
  OWNER = "OWNER",
  BUSINESS = "BUSINESS",
}

export type DriverEditParamsUser = {
  firstName: string;
  lastName: string;
  mobilePrimaryPrefix: string;
  mobilePrimary: string;
  mobileSecondaryPrefix: string | null;
  mobileSecondary: string | null;
  email: string;
};

export type DriverEditParamsDriver = {
  birthDate: string;
  birthPlace: string;
  idNumber: string;
  citizenship: DriverEditParamsCitizenship;
  languages: DriverEditParamsLanguage[];
  experience: number;
  carOwnership: DriverEditParamsCarOwnership;
  carMake: string;
  carModel: string;
  carRegNo: string;
  carProdYear: number;
  carSeatsNo: number;
};

export type DriverEditParamsAddress = {
  type: DriverEditParamsAddressType;
  lat: number;
  lon: number;
  country: string;
  town: string;
  zipCode: string;
  street: string;
  houseNo: string;
  apartment: string;
  description: string | null;
};

export type DriverEditParamsTaxiDriverAssociationForEmployment = {
  contractType: DriverEditParamsFormOfEmployment;
  fleetPartnerId: string | null;
  id: string;
};

export type DriverEditParamsTaxiDriverAssociationForB2B = {
  contractType: DriverEditParamsFormOfEmployment;
  taxId: string;
  regon: string | null;
  krsNo: string | null;
  fleetPartnerId: string | null;
  companyName: string;
  country: string;
  town: string;
  zipCode: string;
  street: string;
  houseNo: string;
  apartment: string;
  description: string;
  id: string;
};

export type DriverEditParamsTaxiDriverAssociation =
  | DriverEditParamsTaxiDriverAssociationForEmployment
  | DriverEditParamsTaxiDriverAssociationForB2B;

type DriverEditParams = {
  user: DriverEditParamsUser;
  driver: DriverEditParamsDriver;
  associations: DriverEditParamsTaxiDriverAssociation[];
  addresses: DriverEditParamsAddress[];
};

export default DriverEditParams;
