import { FormValidationDefinition } from "../../../../../../common/components/form/use-form";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import DriverAddFormOfEmploymentType from "../types/driver-add-form-of-employment-type";
import driverAddTaxiCorporationFormValidationService from "./driver-add-taxi-corporation-form-validation.service";
import DriverAddTaxiCorporationFormData from "./types/driver-add-taxi-corporation-form-data";

const getDefaultTaxiCorporationData = (): DriverAddTaxiCorporationFormData => {
  return {
    apartmentNumber: "",
    country: "",
    description: "",
    houseNumber: "",
    street: "",
    town: "",
    zipCode: "",
    companyId: "",
    name: "",
    nationalCourtRegisterNumber: "",
    taxNumber: "",
    formOfEmployment: null,
    taxiCorporation: null,
    fleetPartner: null,
  };
};

const getTaxiCorporationValidationDefinition =
  (): FormValidationDefinition<DriverAddTaxiCorporationFormData> => {
    return {
      fleetPartner: (values) =>
        driverAddTaxiCorporationFormValidationService.validateFleetPartner(
          values.fleetPartner
        ),
      formOfEmployment: (values) =>
        driverAddTaxiCorporationFormValidationService.validateFormOfEmployment(
          values.formOfEmployment
        ),
      taxiCorporation: (values) =>
        driverAddTaxiCorporationFormValidationService.validateTaxiCorporation(
          values.taxiCorporation
        ),
      companyId: (values) =>
        values.formOfEmployment === DriverAddFormOfEmploymentType.B2B
          ? driverAddTaxiCorporationFormValidationService.validateCompanyId(
              values.companyId
            )
          : formValidationService.ok(),
      name: (values) =>
        values.formOfEmployment === DriverAddFormOfEmploymentType.B2B
          ? driverAddTaxiCorporationFormValidationService.validateName(
              values.name
            )
          : formValidationService.ok(),
      nationalCourtRegisterNumber: (values) =>
        values.formOfEmployment === DriverAddFormOfEmploymentType.B2B
          ? driverAddTaxiCorporationFormValidationService.validateNationalCourtRegister(
              values.nationalCourtRegisterNumber
            )
          : formValidationService.ok(),

      taxNumber: (values) =>
        values.formOfEmployment === DriverAddFormOfEmploymentType.B2B
          ? driverAddTaxiCorporationFormValidationService.validateTaxNumber(
              values.taxNumber
            )
          : formValidationService.ok(),

      street: (values) =>
        values.formOfEmployment === DriverAddFormOfEmploymentType.B2B
          ? driverAddTaxiCorporationFormValidationService.validateStreet(
              values.street
            )
          : formValidationService.ok(),

      houseNumber: (values) =>
        values.formOfEmployment === DriverAddFormOfEmploymentType.B2B
          ? driverAddTaxiCorporationFormValidationService.validateHouseNumber(
              values.houseNumber
            )
          : formValidationService.ok(),

      apartmentNumber: (values) =>
        values.formOfEmployment === DriverAddFormOfEmploymentType.B2B
          ? driverAddTaxiCorporationFormValidationService.validateApartmentNumber(
              values.apartmentNumber
            )
          : formValidationService.ok(),

      town: (values) =>
        values.formOfEmployment === DriverAddFormOfEmploymentType.B2B
          ? driverAddTaxiCorporationFormValidationService.validateTown(
              values.town
            )
          : formValidationService.ok(),

      country: (values) =>
        values.formOfEmployment === DriverAddFormOfEmploymentType.B2B
          ? driverAddTaxiCorporationFormValidationService.validateCountry(
              values.country
            )
          : formValidationService.ok(),

      zipCode: (values) =>
        values.formOfEmployment === DriverAddFormOfEmploymentType.B2B
          ? driverAddTaxiCorporationFormValidationService.validateZipCode(
              values.zipCode
            )
          : formValidationService.ok(),

      description: (values) =>
        values.formOfEmployment === DriverAddFormOfEmploymentType.B2B
          ? driverAddTaxiCorporationFormValidationService.validateDescription(
              values.description
            )
          : formValidationService.ok(),
    };
  };

const driverAddTaxiCorporationHelper = {
  getDefaultTaxiCorporationData,
  getTaxiCorporationValidationDefinition,
};

export default driverAddTaxiCorporationHelper;
