import userTranslationsHelper from "../../../../../../../../../languages/user-translations.helper";
import DriverAddUserDataAddressTableColumn from "../../../../types/driver-add-user-data-address-table-column";

const getColumns = (): DriverAddUserDataAddressTableColumn[] => {
  const translations =
    userTranslationsHelper.getDriverAddTranslations().form.userData;

  const tableColumns: DriverAddUserDataAddressTableColumn[] = [
    {
      header: translations.addressTableHeader.addressName,
      title: translations.addressTableHeader.addressName,
      accessor: "address",
      colSpan: 6,
    },
    {
      header: "",
      title: "",
      accessor: "actions",
    },
  ];
  return tableColumns;
};

const driverAddUserDataAddressTableHelper = { getColumns };

export default driverAddUserDataAddressTableHelper;
