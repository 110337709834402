import dateService from "../../../common/utils/date/date.service";
import GoogleMapsRouteWaypoint from "../../../common/utils/google-maps-route/google-maps-route-waypoint";
import googleMapsRouteService from "../../../common/utils/google-maps-route/google-maps-route.service";
import orderOptimizerTranslationsHelper from "../../../languages/order-optimizer-translations.helper";
import { OrderOptimizerDetailsItemStepsOrdersPoints } from "./common/types/order-optimizer-details-item";

const getHeadingText = (id: string) => {
  const translations =
    orderOptimizerTranslationsHelper.getDetailsTranslations();

  if (!id) {
    return "";
  }

  return translations.header.headingLabel.replace("#{id}", id);
};

const getDateLabel = (date: Date | null): string => {
  if (!date) {
    return "---";
  }

  return dateService.formatDateTime(date);
};

const getTimeLabel = (date: Date | null): string => {
  if (!date) {
    return "---";
  }

  return dateService.formatTime(date);
};

const getTimeDeltaLabel = (timeDeltaSeconds: number = 0): string => {
  const hours = Math.floor(timeDeltaSeconds / 3600);
  const minutes = Math.floor((timeDeltaSeconds % 3600) / 60);

  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )} h`;
};

const getRateLabel = (rate: number): string => {
  return `${rate.toFixed(2)} zł`;
};

const getMarginLabel = (margin: number): string => {
  return `${margin.toFixed()}%`;
};

const calculateMargin = (
  totalCargoIncome: number,
  totalDriverHaltingCost: number,
  distance: number,
  rate: number
) => {
  return (
    100 *
    ((totalCargoIncome - (totalDriverHaltingCost + distance * rate)) /
      totalCargoIncome)
  );
};

const getGoogleMapsUrl = (
  points: OrderOptimizerDetailsItemStepsOrdersPoints[],
  driverStartLat?: number,
  driverStartLon?: number,
  homeToStart?: boolean,
  endToHome?: boolean
) => {
  let waypoints = points.map((point): GoogleMapsRouteWaypoint => {
    return {
      latitude: point.lat,
      longitude: point.lon,
    };
  });

  if (driverStartLat && driverStartLon) {
    const driverHome: GoogleMapsRouteWaypoint = {
      latitude: driverStartLat,
      longitude: driverStartLon,
    };

    if (homeToStart) {
      waypoints = [driverHome, ...waypoints];
    }

    if (endToHome) {
      waypoints = [...waypoints, driverHome];
    }
  }

  return googleMapsRouteService.createGoogleMapsRouteUrl(waypoints);
};

const getDistanceLabel = (distance: number = 0) => {
  return `${distance.toFixed()} KM`;
};

const getScoreLabel = (score?: number) => {
  const translations =
    orderOptimizerTranslationsHelper.getDetailsTranslations().table;

  return `${translations.scoreLabel} ${score?.toFixed() ?? "---"}`;
};

const getIdLabel = (id?: number) => {
  const translations =
    orderOptimizerTranslationsHelper.getDetailsTranslations().table;

  return `${translations.idLabel} ${id ?? "---"}`;
};

const orderOptimizerDetailsHelper = {
  getHeadingText,
  getDateLabel,
  getTimeLabel,
  getTimeDeltaLabel,
  getDistanceLabel,
  getRateLabel,
  getMarginLabel,
  calculateMargin,
  getGoogleMapsUrl,
  getScoreLabel,
  getIdLabel,
};

export default orderOptimizerDetailsHelper;
