import { FormValidationDefinition } from "../../../../../common/components/form/use-form";
import taxiFleetPartnerAddFormValidationService from "./taxi-fleet-partner-add-form-validation.service";
import TaxiFleetPartnerAddFormData from "./types/taxi-fleet-partner-add-form-data";

const getEmptyFormData = (): TaxiFleetPartnerAddFormData => {
  return {
    name: "",
    notes: "",
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<TaxiFleetPartnerAddFormData> => {
    return {
      name: (values) =>
        taxiFleetPartnerAddFormValidationService.validateName(values.name),
      notes: (values) =>
        taxiFleetPartnerAddFormValidationService.validateNotes(values.notes),
    };
  };

const taxiFleetPartnerAddFormHelper = {
  getEmptyFormData,
  getValidationDefinition,
};

export default taxiFleetPartnerAddFormHelper;
