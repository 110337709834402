import { round } from "lodash";
import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import CardComponent from "../../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../../common/components/form/field/form-field.component";
import Column from "../../../../../../common/components/grid/column";
import Row from "../../../../../../common/components/grid/row";
import MapComponent from "../../../../../../common/components/map/map.component";
import MapMarker from "../../../../../../common/components/map/types/map-marker";
import MapRoute from "../../../../../../common/components/map/types/map-route";
import useAbort from "../../../../../../common/hooks/use-abort";
import useDocumentTitle from "../../../../../../common/hooks/use-document-title";
import MileageDetails from "../../../../../../common/services/mileage/details/mileage-details";
import mileageService from "../../../../../../common/services/mileage/mileage.service";
import useMileageRoadRoute from "../../../../../../common/services/mileage/road-route/use-mileage-road-route";
import MileageUpdateParams from "../../../../../../common/services/mileage/update/mileage-update-params";
import notificationService from "../../../../../../common/utils/notification/notification.service";
import appTranslationsHelper from "../../../../../../languages/app-translations.helper";
import mileageTranslationsHelper from "../../../../../../languages/mileage-translations.helper";
import mileageRoutesHelper from "../../../../common/routes/mileage-routes.helper";
import mileageUpdateParamsFactory from "../../factory/mileage-update-params.factory";
import MileageUpdateFormData from "../../form/types/mileage-update-form-data";
import mileageUpdateSummaryFactory from "./mileage-update-summary.factory";
import mileageUpdateSummaryHelper from "./mileage-update-summary.helper";
import mileageUpdateSummaryRouteFactory from "./route/factory/mileage-update-summary-route.factory";
import MileageUpdateSummaryRouteComponent from "./route/mileage-update-summary-route.component";
import MileageUpdateSummaryRouteWaypoint from "./route/types/mileage-update-summary-route-waypoint";
import MileageUpdateError from "../../../../../../common/services/mileage/update/mileage-update-error";

type MileageUpdateSummaryProps = {
  details: MileageDetails | null;
  companyName: string;
  formData: MileageUpdateFormData;
  validateForm: () => Promise<boolean>;
  onRouteFetched: (distance: number) => void;
};

const MileageUpdateSummaryComponent: FC<MileageUpdateSummaryProps> = (
  props
) => {
  const translations =
    mileageTranslationsHelper.getMileageUpdateTranslations().summary;

  const navigate = useNavigate();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.mileageAdd);

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const formSubmitAbort = useAbort();

  const roadRoute = useMileageRoadRoute();

  const [waypoints, setWaypoints] = useState<
    MileageUpdateSummaryRouteWaypoint[]
  >([]);

  const mapMarkers: MapMarker[] =
    mileageUpdateSummaryRouteFactory.createMapMarkers(waypoints);

  const [mapRoutes, setMapRoutes] = useState<MapRoute[]>([]);

  const [routeLegDistances, setRouteLegDistances] = useState<number[]>([]);

  const [routingLegDurations, setRoutingLegDurations] = useState<number[]>([]);

  useEffect(() => {
    const preparedWaypoints = mileageUpdateSummaryFactory.createWaypoints(
      props.formData.addressSequence
    );

    setWaypoints(preparedWaypoints);

    if (preparedWaypoints.length > 1) {
      const request =
        mileageUpdateSummaryFactory.createSearchRoutingRequest(
          preparedWaypoints
        );
      roadRoute.load(request);
    } else {
      setMapRoutes([]);
    }
  }, [props.formData.addressSequence]);

  useEffect(() => {
    if (!roadRoute.data) {
      return;
    }

    const route = mileageUpdateSummaryRouteFactory.createMapRoute(
      roadRoute.data.coordinates
    );
    setMapRoutes([route]);

    const fixedWaypoints = [...waypoints];
    let totalDistance = 0;
    const tempRouteLegDistances: number[] = [];

    roadRoute.data.legs.forEach((leg, index) => {
      tempRouteLegDistances.push(round(leg.distance / 1000));
      fixedWaypoints[index + 1].distance = leg.distance;
      totalDistance += leg.distance;
    });

    setRouteLegDistances(tempRouteLegDistances);
    setWaypoints(fixedWaypoints);
    props.onRouteFetched(round(totalDistance / 1000));

    setRoutingLegDurations(roadRoute.data.legs.map((leg) => leg.duration));
  }, [roadRoute.data]);

  useEffect(() => {
    if (
      !routingLegDurations ||
      routingLegDurations.length !== waypoints.length - 1
    )
      return;

    const fixedWaypoints = [...waypoints];

    fixedWaypoints[0].time = props.formData.mileageDate;

    routingLegDurations.forEach((duration, index) => {
      fixedWaypoints[index + 1].time = new Date(
        fixedWaypoints[index].time?.getTime()! + 1000 * duration
      );
    });

    setWaypoints(fixedWaypoints);
  }, [routingLegDurations, JSON.stringify(props.formData.mileageDate)]);

  const onSubmitButtonClick = async () => {
    const isFormValid = await props.validateForm();

    if (!isFormValid || !props.details) {
      return;
    }

    const params: MileageUpdateParams = mileageUpdateParamsFactory.create(
      props.formData,
      props.details?.id,
      routingLegDurations
    );

    setIsFormSubmitting(true);

    mileageService
      .update(params, formSubmitAbort.signal)
      .then(() => {
        notificationService.success(translations.submitSuccessNotification);
        navigate(mileageRoutesHelper.getListingRoute());
      })
      .catch((error) => {
        notificationService.error((error as MileageUpdateError).message);
      })
      .finally(() => {
        setIsFormSubmitting(false);
      });
  };

  const onSubmitCancel = () => {
    navigate(mileageRoutesHelper.getListingRoute());
  };

  return (
    <CardComponent
      header={{ title: translations.headingLabel }}
      classNames={{ root: "mileage_update_summary" }}
    >
      <div className="mileage_update_summary__map_wrapper">
        <MapComponent
          markers={mapMarkers}
          autoFit
          autoFitOnUpdate
          routes={mapRoutes}
        />
      </div>
      <Row>
        <Column sm={6}>
          <FormFieldComponent label={translations.companyLabel}>
            <span>
              {mileageUpdateSummaryHelper.getCargoCompanyLabel(
                props.companyName
              )}
            </span>
          </FormFieldComponent>
          <FormFieldComponent label={translations.passengerLabel}>
            <span>
              {mileageUpdateSummaryHelper.getWorkerLabel(
                props.details?.workerName
              )}
            </span>
          </FormFieldComponent>
        </Column>
        <Column sm={6}>
          <FormFieldComponent label={translations.mileageNumberLabel}>
            <span>
              {mileageUpdateSummaryHelper.getMileageNumberLabel(
                props.details?.mileageNumber ?? undefined
              )}
            </span>
          </FormFieldComponent>
          <FormFieldComponent label={translations.cardNumberLabel}>
            <span>
              {mileageUpdateSummaryHelper.getCardNumberLabel(
                props.details?.cardNumber ?? undefined
              )}
            </span>
          </FormFieldComponent>
        </Column>
      </Row>
      <FormFieldComponent label={translations.routeLabel}>
        <MileageUpdateSummaryRouteComponent waypoints={waypoints} />
      </FormFieldComponent>
      <Row>
        <Column>
          <FormFieldComponent
            label={translations.orderNumberLabel}
            classNames={{
              content:
                "mileage_update_summary__mileage_number_form_field_content",
            }}
          >
            {mileageUpdateSummaryHelper.getCommissionNumber(
              props.details?.commissionNumber ?? undefined
            )}
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column lg={6}>
          <ButtonComponent
            onClick={onSubmitButtonClick}
            type="primary"
            title={translations.submitButtonTitle}
            classNames={{ root: "w-100" }}
            isLoading={isFormSubmitting}
            idForTesting="mileage-update-summary-submit-button"
          >
            {translations.submitButtonLabel}
          </ButtonComponent>
        </Column>
        <Column lg={6}>
          <ButtonComponent
            onClick={onSubmitCancel}
            type="danger"
            title={translations.submitCancelButtonTitle}
            classNames={{ root: "w-100" }}
            isDisabled={isFormSubmitting}
            idForTesting="mileage-update-summary-cancel-button"
          >
            {translations.submitCancelButtonLabel}
          </ButtonComponent>
        </Column>
      </Row>
    </CardComponent>
  );
};

export default MileageUpdateSummaryComponent;
