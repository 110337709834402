import Joi from "joi";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import DriverAddUserPhoneNumber from "../types/driver-add-user-phone-number";
import DriverAddCitizenshipType from "../types/driver-add-citizenship-type";
import DriverAddLanguageType from "../types/driver-add-language-type";
import DriverAddAddress from "../types/driver-add-address";
import DriverAddAddressType from "../types/driver-add-address-type";
import userTranslationsHelper from "../../../../../../languages/user-translations.helper";
import DriverAddFormOfEmploymentType from "../types/driver-add-form-of-employment-type";
import DriverAddTaxiCorporation from "../types/driver-add-taxi-corporation";
import DriverAddUserFleetPartner from "../types/driver-add-user-fleet-partner";

const validateBirthDate = (value: Date | null) => {
  const currentDate = new Date();
  const pastDate = new Date().setDate(currentDate.getDate() - 1);

  const validationSchema = Joi.date()
    .min("1940-01-01")
    .max(pastDate)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateBirthPlace = (value: string) => {
  const validationSchema = Joi.string().min(2).max(50).trim().required();

  return formValidationService.validate(value, validationSchema);
};

const validatePersonalIdNumber = (value: string) => {
  const pattern = new RegExp(`^[0-9]*$`);
  const validationSchema = Joi.string()
    .min(2)
    .max(20)
    .trim()
    .regex(pattern)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateCitizenship = (value: DriverAddCitizenshipType | null) => {
  const translations = userTranslationsHelper.getDriverAddTranslations();

  const validationSchema = Joi.string()
    .valid(...Object.values(DriverAddCitizenshipType))
    .required()
    .messages({
      "any.only": translations.selectComponentNotificationError,
    });

  return formValidationService.validate(value, validationSchema);
};

const validateLanguages = (value: DriverAddLanguageType[]) => {
  const validationSchema = Joi.array()
    .min(1)
    .max(10)
    .items(Joi.string().valid(...Object.values(DriverAddLanguageType)))
    .required();

  const languages = value.map((item) => item);

  return formValidationService.validate(languages, validationSchema);
};

const validateYearsOfExperience = (value: number | null) => {
  const validationSchema = Joi.number().integer().min(0).max(50).required();

  return formValidationService.validate(value, validationSchema);
};

const validateAddresses = (value: DriverAddAddress[]) => {
  const lengthValidationSchema = Joi.array().required();

  const lengthValidationResult = formValidationService.validate(
    value,
    lengthValidationSchema
  );

  if (!lengthValidationResult.isValid) {
    return lengthValidationResult;
  }

  const mailingAddress = value.find(
    (item) => item.type === DriverAddAddressType.MAILING
  );
  const startingAddress = value.find(
    (item) => item.type === DriverAddAddressType.STARTING
  );

  if (!mailingAddress) {
    return formValidationService.bad(
      formValidationService.getValidationCustomMessages()
        .driverSignUpMailingAddressValidationError
    );
  }

  if (!startingAddress) {
    return formValidationService.bad(
      formValidationService.getValidationCustomMessages()
        .driverSignUpStartingAddressValidationError
    );
  }

  return lengthValidationResult;
};

const validateFirstName = (value: string) => {
  const pattern = /^[^\s].*[^\s]$/;

  const validationSchema = Joi.string()
    .min(2)
    .max(20)
    .regex(pattern)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateLastName = (value: string) => {
  const pattern = /^[^\s].*[^\s]$/;

  const validationSchema = Joi.string()
    .min(2)
    .max(20)
    .regex(pattern)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const mobileDialingCodeValidationSchema = Joi.string()
  .min(2)
  .max(4)
  .trim()
  .required();

const mobileNumberPattern = new RegExp(`^[0-9]*$`);
const mobileNumberValidationSchema = Joi.string()
  .min(9)
  .max(14)
  .regex(mobileNumberPattern)
  .required();

const requiredMobileValidationSchema = Joi.object({
  dialingCode: mobileDialingCodeValidationSchema,
  number: mobileNumberValidationSchema,
});

const validateMobile = (value: DriverAddUserPhoneNumber) => {
  const validationSchema = requiredMobileValidationSchema;

  return formValidationService.validate(value, validationSchema);
};

const validateAlternativeMobile = (value: DriverAddUserPhoneNumber | null) => {
  const emptyValidationSchema = Joi.object({
    dialingCode: Joi.string().equal(""),
    number: Joi.string().equal(""),
  });

  const validationSchema = Joi.alternatives().try(
    requiredMobileValidationSchema,
    emptyValidationSchema
  );

  return formValidationService.validate(value, validationSchema);
};

const validateTaxiCorporation = (
  value: DriverAddTaxiCorporation | null | undefined
) => {
  const translations = userTranslationsHelper.getDriverAddTranslations();

  const validationSchema = Joi.object().required().messages({
    "any.required": translations.selectComponentNotificationError,
    "object.base": translations.selectComponentNotificationError,
  });

  const result = formValidationService.validate(value, validationSchema);

  return result;
};

const validateFormOfEmployment = (
  value: DriverAddFormOfEmploymentType | null
) => {
  const translations = userTranslationsHelper.getDriverAddTranslations();

  const validationSchema = Joi.array()
    .valid(...Object.values(DriverAddFormOfEmploymentType))
    .required()
    .messages({
      "any.only": translations.selectComponentNotificationError,
    });

  return formValidationService.validate(value, validationSchema);
};

const validateFleetPartner = (value: DriverAddUserFleetPartner | null) => {
  const validationSchema = Joi.object().allow(null).required();

  return formValidationService.validate(value, validationSchema);
};

const driverAddUserDataFormValidationService = {
  validateBirthDate,
  validateBirthPlace,
  validatePersonalIdNumber,
  validateCitizenship,
  validateLanguages,
  validateYearsOfExperience,
  validateAddresses,
  validateFirstName,
  validateLastName,
  validateMobile,
  validateAlternativeMobile,
  validateTaxiCorporation,
  validateFormOfEmployment,
  validateFleetPartner,
};

export default driverAddUserDataFormValidationService;
