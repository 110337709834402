import { FC, useState } from "react";
import DriverAddAddress from "../../../types/driver-add-address";
import DriverAddressFormComponent from "../../../../../common/address-form/driver-address-form.component";
import MapComponent from "../../../../../../../../common/components/map/map.component";
import Column from "../../../../../../../../common/components/grid/column";
import Row from "../../../../../../../../common/components/grid/row";
import ButtonComponent from "../../../../../../../../common/components/button/button.component";
import MapMarker from "../../../../../../../../common/components/map/types/map-marker";
import DriverAddUserDataAddressTableComponent from "./table/driver-add-user-data-address-table.component";
import driverAddUserDataAddressFactory from "../../../factory/driver-add-user-data-address.factory";
import userTranslationsHelper from "../../../../../../../../languages/user-translations.helper";
import DriverAddressFormAddress from "../../../../../common/address-form/types/driver-address-form-address";
import DriverAddAddressType from "../../../types/driver-add-address-type";

type DriverAddUserDataAddressProps = {
  addresses: DriverAddAddress[];
  onRemoveAddressButtonClick: (index: number) => void;
  onAddNewAddress: (address: DriverAddAddress) => void;
};

const DriverAddUserDataAddressComponent: FC<DriverAddUserDataAddressProps> = (
  props
) => {
  const userDataFormTranslations =
    userTranslationsHelper.getDriverAddTranslations().form.userData;

  const [isAddNewAddressModalOpen, setIsAddNewAddressModalOpen] =
    useState(false);

  const mapMarkers: MapMarker[] =
    driverAddUserDataAddressFactory.createMapMarkers(props.addresses);

  const openAddNewAddressModal = () => {
    setIsAddNewAddressModalOpen(true);
  };

  const closeAddNewAddressModal = () => {
    setIsAddNewAddressModalOpen(false);
  };

  const handleAddNewAddress = (address: DriverAddressFormAddress) => {
    const convertedAddress: DriverAddAddress = {
      ...address,
      type: address.type as unknown as DriverAddAddressType,
    };

    props.onAddNewAddress(convertedAddress);
  };

  return (
    <div className="driver_add_user_add_address">
      <Row>
        <Column xl={4}>
          <DriverAddUserDataAddressTableComponent
            addresses={props.addresses}
            onRemoveAddressButtonClick={props.onRemoveAddressButtonClick}
          />
          <div className="driver_add_user_add_address__add_button_wrapper">
            <ButtonComponent
              onClick={openAddNewAddressModal}
              title={userDataFormTranslations.addNewAddressButtonTitle}
              type="primary"
              idForTesting="user-data-open-add-new-address-modal-button"
            >
              {userDataFormTranslations.addNewAddressButtonText}
            </ButtonComponent>
          </div>
        </Column>
        <Column xl={8}>
          <div className="driver_add_user_add_address__map_wrapper">
            <MapComponent markers={mapMarkers} autoFit autoFitOnUpdate />
          </div>
        </Column>
      </Row>
      <DriverAddressFormComponent
        isOpen={isAddNewAddressModalOpen}
        onClose={closeAddNewAddressModal}
        onAddNewAddress={handleAddNewAddress}
      />
    </div>
  );
};

export default DriverAddUserDataAddressComponent;
