import {
  faAdd,
  faBookOpen,
  faBuildingUser,
  faCodePullRequest,
  faFileCircleCheck,
  faFileInvoiceDollar,
  faFileWaveform,
  faGear,
  faList,
  faMapMarkedAlt,
  faR,
  faRoad,
  faRoute,
  faSliders,
  faTaxi,
  faTrash,
  faTruck,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import orderRoutesHelper from "../../../order/common/routes/order-routes.helper";
import cargoRoutesHelper from "../../../cargo/common/routes/cargo-routes.helper";
import AppAsideNavigationDefinitionItem, {
  AppAsideNavigationDefinitionLink,
} from "./types/app-aside-navigation-definition";
import UserRole from "../../../../common/types/user-role";
import userRoutesHelper from "../../../user/common/routes/user-routes.helper";
import taxiRoutesHelper from "../../../taxi/common/routes/taxi-routes.helper";
import billingRoutesHelper from "../../../billings/common/routes/billing-routes.helper";
import routeRoutesHelper from "../../../route/common/routes/route-routes.helper";
import { AppAsideAttentionCountersResponseData } from "../../common/api/app-aside-attention-counters.response";
import AppAsideNavigationDefinitionAttentionCounterDefinitionItem from "./types/app-aside-navigation-definition-attention-counter-definition-item";
import AppAsideNavigationDefinitionAttentionCounterItem from "./types/app-aside-navigation-definition-attention-counter-item";
import mileageRoutesHelper from "../../../mileage/common/routes/mileage-routes.helper";
import delegationRoutesHelper from "../../../delegation/common/routes/delegation-routes.helper";
import orderOptimizerRoutesHelper from "../../../order-optimizer/common/routes/order-optimizer-routes.helper";

const attentionCountersDefinition: AppAsideNavigationDefinitionAttentionCounterDefinitionItem[] =
  [
    {
      group: "order",
      navigationKey: "order-active",
      responseKey: "active_orders",
      userRolesWhitelist: [
        UserRole.ADMIN,
        UserRole.DEALER,
        UserRole.DISPATCHER,
        UserRole.OPERATOR,
      ],
    },
    {
      group: "order",
      navigationKey: "order-finished",
      responseKey: "finished_orders",
      userRolesWhitelist: [
        UserRole.ADMIN,
        UserRole.DEALER,
        UserRole.DISPATCHER,
        UserRole.OPERATOR,
      ],
    },
    {
      group: "billings",
      navigationKey: "cargo-company-billed-orders-listing",
      responseKey: "order_billings_cargo",
      userRolesWhitelist: [UserRole.ADMIN, UserRole.CARGO_OFFICER],
    },
    {
      group: "billings",
      navigationKey: "taxi-billed-orders-listing",
      responseKey: "order_billings_taxi",
      userRolesWhitelist: [UserRole.ADMIN, UserRole.TAXI_OFFICER],
    },
    {
      group: "billings",
      navigationKey: "taxi-unbilled-orders-listing",
      responseKey: "order_not_yet_billed_taxi",
      userRolesWhitelist: [UserRole.ADMIN, UserRole.TAXI_OFFICER],
    },
    {
      group: "billings",
      navigationKey: "taxi-unbilled-routes-listing",
      responseKey: "route_not_yet_billed_taxi",
      userRolesWhitelist: [UserRole.ADMIN, UserRole.TAXI_OFFICER],
    },
    {
      group: "billings",
      navigationKey: "taxi-unbilled-transferred-orders-listing",
      responseKey: "forwarding_not_yet_billed_taxi",
      userRolesWhitelist: [UserRole.ADMIN, UserRole.TAXI_OFFICER],
    },
    {
      group: "billings",
      navigationKey: "taxi-billed-transferred-orders-listing",
      responseKey: "order_forwarding_billings",
      userRolesWhitelist: [UserRole.ADMIN, UserRole.TAXI_OFFICER],
    },
  ];

const getAttentionCounters = (
  userRoles: UserRole[],
  attentionCountersResponseData:
    | AppAsideAttentionCountersResponseData
    | undefined
): AppAsideNavigationDefinitionAttentionCounterItem[] => {
  if (!attentionCountersResponseData) {
    return [];
  }

  const attentionCounters: AppAsideNavigationDefinitionAttentionCounterItem[] =
    [];

  Object.keys(attentionCountersResponseData).forEach((responseKey) => {
    const attentionCounterDefinitionItem = attentionCountersDefinition.find(
      (definition) => definition.responseKey === responseKey
    );

    if (!attentionCounterDefinitionItem) {
      return;
    }

    const hasPermission = userRoles.some((userRole) =>
      attentionCounterDefinitionItem.userRolesWhitelist.includes(userRole)
    );

    const value = hasPermission
      ? attentionCountersResponseData[
          responseKey as keyof AppAsideAttentionCountersResponseData
        ] ?? 0
      : 0;

    const item: AppAsideNavigationDefinitionAttentionCounterItem = {
      navigationKey: attentionCounterDefinitionItem!.navigationKey,
      value,
      group: attentionCounterDefinitionItem!.group,
    };

    attentionCounters.push(item);
  });

  return attentionCounters;
};

const getNavigationDefinition = (
  userRoles: UserRole[],
  attentionCountersResponseData:
    | AppAsideAttentionCountersResponseData
    | undefined
): AppAsideNavigationDefinitionItem[] => {
  const translations = appTranslationsHelper.getNavigationTranslations();

  const attentionCounters: AppAsideNavigationDefinitionAttentionCounterItem[] =
    getAttentionCounters(userRoles, attentionCountersResponseData);

  const getItemAttentionCounter = (navigationKey: string): number =>
    attentionCounters.find((counter) => counter.navigationKey === navigationKey)
      ?.value ?? 0;

  const getGroupAttentionCounter = (group: string): number =>
    attentionCounters.reduce(
      (accumulator, counter) =>
        counter.group === group ? accumulator + counter.value : accumulator,
      0
    );

  return [
    {
      icon: faRoute,
      text: translations.route.groupLabel,
      key: `route`,
      userRolesWhitelist: [UserRole.ADMIN, UserRole.OPERATOR, UserRole.DEALER],
      items: [
        {
          icon: faFileWaveform,
          text: translations.route.activeListing,
          key: `route-active`,
          path: routeRoutesHelper.getActiveRoutesRoute(),
          userRolesWhitelist: [
            UserRole.ADMIN,
            UserRole.OPERATOR,
            UserRole.DEALER,
          ],
        },
        {
          icon: faFileCircleCheck,
          text: translations.route.finishedListing,
          key: `route-finished`,
          path: routeRoutesHelper.getFinishedRoutesRoute(),
          userRolesWhitelist: [
            UserRole.ADMIN,
            UserRole.OPERATOR,
            UserRole.DEALER,
          ],
        },
      ],
    },
    {
      icon: faRoad,
      text: translations.order.groupLabel,
      key: `order`,
      userRolesWhitelist: [
        UserRole.ADMIN,
        UserRole.DEALER,
        UserRole.DISPATCHER,
        UserRole.OPERATOR,
        UserRole.TAXI_OFFICER,
        UserRole.CARGO_OFFICER,
      ],
      attentionCounter: getGroupAttentionCounter("order"),
      items: [
        {
          icon: faFileWaveform,
          text: translations.order.activeListing,
          key: `order-active`,
          path: orderRoutesHelper.getListingOfActiveOrdersRoute(),
          userRolesWhitelist: [
            UserRole.ADMIN,
            UserRole.DEALER,
            UserRole.DISPATCHER,
            UserRole.OPERATOR,
            UserRole.TAXI_OFFICER,
          ],
          attentionCounter: getItemAttentionCounter("order-active"),
        },
        {
          icon: faFileCircleCheck,
          text: translations.order.finishedListing,
          key: `order-finished`,
          path: orderRoutesHelper.getListingOfFinishedOrdersRoute(),
          userRolesWhitelist: [
            UserRole.ADMIN,
            UserRole.DISPATCHER,
            UserRole.OPERATOR,
            UserRole.DEALER,
            UserRole.TAXI_OFFICER,
          ],
          attentionCounter: getItemAttentionCounter("order-finished"),
        },
        {
          icon: faTrash,
          text: translations.order.abandonedListing,
          key: `order-abandoned`,
          path: orderRoutesHelper.getListingOfAbandonedOrdersRoute(),
          userRolesWhitelist: [
            UserRole.ADMIN,
            UserRole.OPERATOR,
            UserRole.DEALER,
            UserRole.DISPATCHER,
            UserRole.CARGO_OFFICER,
          ],
        },
        {
          icon: faAdd,
          text: translations.order.addNew,
          key: `order-add`,
          path: orderRoutesHelper.getAddRoute(),
          userRolesWhitelist: [
            UserRole.ADMIN,
            UserRole.OPERATOR,
            UserRole.DISPATCHER,
          ],
        },
      ],
    },
    {
      icon: faTruck,
      text: translations.cargoCompany.groupLabel,
      key: `cargo-company`,
      userRolesWhitelist: [
        UserRole.ADMIN,
        UserRole.RAILY_OFFICER,
        UserRole.CARGO_OFFICER,
        UserRole.DISPATCHER,
        UserRole.OPERATOR,
      ],
      items: [
        {
          icon: faList,
          text: translations.cargoCompany.listing,
          key: `cargo-company-listing`,
          path: cargoRoutesHelper.getCompanyListingRoute(),
          userRolesWhitelist: [
            UserRole.ADMIN,
            UserRole.RAILY_OFFICER,
            UserRole.CARGO_OFFICER,
            UserRole.DISPATCHER,
            UserRole.OPERATOR,
          ],
        },
        {
          icon: faList,
          text: translations.cargoCompany.cargoTaxiContractListing,
          key: `cargo-company-contract-listing`,
          path: cargoRoutesHelper.getCargoTaxiContractListingRoute(),
          userRolesWhitelist: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
        },
        {
          icon: faAdd,
          text: translations.cargoCompany.addNew,
          key: `cargo-company-add-new`,
          path: cargoRoutesHelper.getCompanyAddRoute(),
          userRolesWhitelist: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
        },
      ],
    },
    {
      icon: faTaxi,
      text: translations.taxi.groupLabel,
      key: `taxi`,
      userRolesWhitelist: [
        UserRole.ADMIN,
        UserRole.RAILY_OFFICER,
        UserRole.OPERATOR,
        UserRole.TAXI_OFFICER,
      ],
      items: [
        {
          icon: faList,
          text: translations.taxi.taxiListing,
          key: `taxi-listing`,
          path: taxiRoutesHelper.getListingRoute(),
          userRolesWhitelist: [
            UserRole.ADMIN,
            UserRole.RAILY_OFFICER,
            UserRole.OPERATOR,
            UserRole.TAXI_OFFICER,
          ],
        },
        {
          icon: faAdd,
          text: translations.taxi.addNew,
          key: `taxi-add-new`,
          path: taxiRoutesHelper.getAddRoute(),
          userRolesWhitelist: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
        },
      ],
    },
    {
      icon: faUsers,
      text: translations.users.groupLabel,
      key: `users`,
      userRolesWhitelist: [
        UserRole.ADMIN,
        UserRole.CARGO_OFFICER,
        UserRole.RAILY_OFFICER,
        UserRole.TAXI_OFFICER,
        UserRole.DISPATCHER,
        UserRole.OPERATOR,
        UserRole.DEALER,
      ],
      items: [
        {
          icon: faUser,
          text: translations.users.cargoOfficer.groupLabel,
          key: `users-cargo-officer`,
          userRolesWhitelist: [
            UserRole.ADMIN,
            UserRole.CARGO_OFFICER,
            UserRole.RAILY_OFFICER,
          ],
          items: [
            {
              icon: faList,
              text: translations.users.cargoOfficer.listing,
              key: `users-cargo-officer-listing`,
              path: userRoutesHelper.getCargoOfficerListingRoute(),
              userRolesWhitelist: [
                UserRole.ADMIN,
                UserRole.RAILY_OFFICER,
                UserRole.CARGO_OFFICER,
              ],
            },
            {
              icon: faAdd,
              text: translations.users.cargoOfficer.addNew,
              key: `users-cargo-officer-add-new`,
              path: userRoutesHelper.getCargoOfficerAddRoute(),
              userRolesWhitelist: [
                UserRole.ADMIN,
                UserRole.CARGO_OFFICER,
                UserRole.RAILY_OFFICER,
              ],
            },
          ],
        },
        {
          icon: faUser,
          text: translations.users.dealer.groupLabel,
          key: `users-dealer`,
          userRolesWhitelist: [
            UserRole.ADMIN,
            UserRole.RAILY_OFFICER,
            UserRole.TAXI_OFFICER,
          ],
          items: [
            {
              icon: faList,
              text: translations.users.dealer.listing,
              key: `users-dealer-listing`,
              path: userRoutesHelper.getDealerListingRoute(),
              userRolesWhitelist: [
                UserRole.ADMIN,
                UserRole.RAILY_OFFICER,
                UserRole.TAXI_OFFICER,
              ],
            },
            {
              icon: faAdd,
              text: translations.users.dealer.addNew,
              key: `users-dealer-add-new`,
              path: userRoutesHelper.getDealerAddRoute(),
              userRolesWhitelist: [
                UserRole.ADMIN,
                UserRole.RAILY_OFFICER,
                UserRole.TAXI_OFFICER,
              ],
            },
          ],
        },
        {
          icon: faUser,
          text: translations.users.dispatcher.groupLabel,
          key: `users-dispatcher`,
          userRolesWhitelist: [
            UserRole.ADMIN,
            UserRole.RAILY_OFFICER,
            UserRole.CARGO_OFFICER,
          ],
          items: [
            {
              icon: faList,
              text: translations.users.dispatcher.listing,
              key: `users-dispatcher-listing`,
              path: userRoutesHelper.getDispatcherListingRoute(),
              userRolesWhitelist: [
                UserRole.ADMIN,
                UserRole.RAILY_OFFICER,
                UserRole.TAXI_OFFICER,
                UserRole.CARGO_OFFICER,
              ],
            },
            {
              icon: faAdd,
              text: translations.users.dispatcher.addNew,
              key: `users-dispatcher-add-new`,
              path: userRoutesHelper.getDispatcherAddRoute(),
              userRolesWhitelist: [
                UserRole.ADMIN,
                UserRole.RAILY_OFFICER,
                UserRole.CARGO_OFFICER,
              ],
            },
          ],
        },
        {
          icon: faUser,
          text: translations.users.driver.groupLabel,
          key: `users-driver`,
          userRolesWhitelist: [
            UserRole.ADMIN,
            UserRole.RAILY_OFFICER,
            UserRole.TAXI_OFFICER,
            UserRole.OPERATOR,
            UserRole.DEALER,
          ],
          items: [
            {
              icon: faList,
              text: translations.users.driver.listing,
              key: `users-driver-listing`,
              path: userRoutesHelper.getDriverListingRoute(),
              userRolesWhitelist: [
                UserRole.ADMIN,
                UserRole.RAILY_OFFICER,
                UserRole.OPERATOR,
                UserRole.TAXI_OFFICER,
                UserRole.DEALER,
              ],
            },
            {
              icon: faAdd,
              text: translations.users.driver.addNew,
              key: `users-driver-add-new`,
              path: userRoutesHelper.getDriverAddRoute(),
              userRolesWhitelist: [
                UserRole.ADMIN,
                UserRole.RAILY_OFFICER,
                UserRole.TAXI_OFFICER,
                UserRole.OPERATOR,
                UserRole.DEALER,
              ],
            },
          ],
        },
        {
          icon: faUser,
          text: translations.users.operator.groupLabel,
          key: `users-operator`,
          userRolesWhitelist: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
          items: [
            {
              icon: faList,
              text: translations.users.operator.listing,
              key: `users-operator-listing`,
              path: userRoutesHelper.getOperatorListingRoute(),
              userRolesWhitelist: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
            },
            {
              icon: faAdd,
              text: translations.users.operator.addNew,
              key: `users-operator-add-new`,
              path: userRoutesHelper.getOperatorAddRoute(),
              userRolesWhitelist: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
            },
          ],
        },
        {
          icon: faUser,
          text: translations.users.passenger.groupLabel,
          key: `users-passenger`,
          userRolesWhitelist: [
            UserRole.ADMIN,
            UserRole.RAILY_OFFICER,
            UserRole.CARGO_OFFICER,
            UserRole.DISPATCHER,
            UserRole.OPERATOR,
          ],
          items: [
            {
              icon: faList,
              text: translations.users.passenger.listing,
              key: `users-passenger-listing`,
              path: userRoutesHelper.getPassengerListingRoute(),
              userRolesWhitelist: [
                UserRole.ADMIN,
                UserRole.RAILY_OFFICER,
                UserRole.CARGO_OFFICER,
                UserRole.DISPATCHER,
                UserRole.OPERATOR,
              ],
            },
            {
              icon: faAdd,
              text: translations.users.passenger.addNew,
              key: `users-passenger-add-new`,
              path: userRoutesHelper.getPassengerAddRoute(),
              userRolesWhitelist: [
                UserRole.ADMIN,
                UserRole.RAILY_OFFICER,
                UserRole.CARGO_OFFICER,
                UserRole.DISPATCHER,
                UserRole.OPERATOR,
              ],
            },
          ],
        },
        {
          icon: faUser,
          text: translations.users.railyOfficer.groupLabel,
          key: `users-raily-officer`,
          userRolesWhitelist: [UserRole.ADMIN],
          items: [
            {
              icon: faList,
              text: translations.users.railyOfficer.listing,
              key: `users-raily-officer-listing`,
              path: userRoutesHelper.getRailyOfficerListingRoute(),
              userRolesWhitelist: [UserRole.ADMIN],
            },
            {
              icon: faAdd,
              text: translations.users.railyOfficer.addNew,
              key: `users-raily-officer-add-new`,
              path: userRoutesHelper.getRailyOfficerAddRoute(),
              userRolesWhitelist: [UserRole.ADMIN],
            },
          ],
        },
        {
          icon: faUser,
          text: translations.users.taxiOfficer.groupLabel,
          key: `users-taxi-officer`,
          userRolesWhitelist: [
            UserRole.ADMIN,
            UserRole.RAILY_OFFICER,
            UserRole.TAXI_OFFICER,
          ],
          items: [
            {
              icon: faList,
              text: translations.users.taxiOfficer.listing,
              key: `users-taxi-officer-listing`,
              path: userRoutesHelper.getTaxiOfficerListingRoute(),
              userRolesWhitelist: [
                UserRole.ADMIN,
                UserRole.RAILY_OFFICER,
                UserRole.TAXI_OFFICER,
              ],
            },
            {
              icon: faAdd,
              text: translations.users.taxiOfficer.addNew,
              key: `users-taxi-officer-add-new`,
              path: userRoutesHelper.getTaxiOfficerAddRoute(),
              userRolesWhitelist: [
                UserRole.ADMIN,
                UserRole.RAILY_OFFICER,
                UserRole.TAXI_OFFICER,
              ],
            },
          ],
        },
      ],
    },
    {
      icon: faFileInvoiceDollar,
      text: translations.billings.groupLabel,
      key: `billings`,
      userRolesWhitelist: [
        UserRole.ADMIN,
        UserRole.TAXI_OFFICER,
        UserRole.RAILY_OFFICER,
        UserRole.CARGO_OFFICER,
      ],
      attentionCounter: getGroupAttentionCounter("billings"),
      items: [
        {
          icon: faTruck,
          text: translations.billings.cargo.groupLabel,
          key: `cargo-company-billings`,
          userRolesWhitelist: [
            UserRole.ADMIN,
            UserRole.RAILY_OFFICER,
            UserRole.CARGO_OFFICER,
          ],
          items: [
            {
              icon: faList,
              text: translations.billings.cargo.orderBillingListing,
              key: `cargo-company-billed-orders-listing`,
              path: billingRoutesHelper.getCargoOrderListingRoute(),
              userRolesWhitelist: [
                UserRole.ADMIN,
                UserRole.RAILY_OFFICER,
                UserRole.CARGO_OFFICER,
              ],
              attentionCounter: getItemAttentionCounter(
                "cargo-company-billed-orders-listing"
              ),
            },
          ],
        },
        {
          icon: faTaxi,
          text: translations.billings.taxi.groupLabel,
          key: `taxi-billings`,
          userRolesWhitelist: [
            UserRole.ADMIN,
            UserRole.TAXI_OFFICER,
            UserRole.RAILY_OFFICER,
          ],
          items: [
            {
              icon: faList,
              text: translations.billings.taxi.orderBillingListing,
              key: `taxi-billed-orders-listing`,
              path: billingRoutesHelper.getTaxiOrderListingRoute(),
              userRolesWhitelist: [
                UserRole.ADMIN,
                UserRole.TAXI_OFFICER,
                UserRole.RAILY_OFFICER,
              ],
              attentionCounter: getItemAttentionCounter(
                "taxi-billed-orders-listing"
              ),
            },
            {
              icon: faList,
              text: translations.billings.taxi.routeBillingListing,
              key: `taxi-billed-routes-listing`,
              path: billingRoutesHelper.getTaxiRouteListingRoute(),
              userRolesWhitelist: [
                UserRole.ADMIN,
                UserRole.TAXI_OFFICER,
                UserRole.RAILY_OFFICER,
              ],
            },
            {
              icon: faList,
              text: translations.billings.taxi.unbilledOrderListing,
              key: `taxi-billed-orders-listing`,
              path: billingRoutesHelper.getTaxiUnbilledOrderListingRoute(),
              userRolesWhitelist: [
                UserRole.ADMIN,
                UserRole.TAXI_OFFICER,
                UserRole.RAILY_OFFICER,
              ],
              attentionCounter: getItemAttentionCounter(
                "taxi-billed-orders-listing"
              ),
            },
            {
              icon: faList,
              text: translations.billings.taxi.unbilledPrivateOrderListing,
              key: `taxi-unbilled-private-orders-listing`,
              path: billingRoutesHelper.getTaxiUnbilledPrivateOrderListingRoute(),
              userRolesWhitelist: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
            },
            {
              icon: faList,
              text: translations.billings.taxi.unbilledRouteListing,
              key: `taxi-unbilled-routes-listing`,
              path: billingRoutesHelper.getTaxiUnbilledRouteListingRoute(),
              userRolesWhitelist: [
                UserRole.ADMIN,
                UserRole.TAXI_OFFICER,
                UserRole.RAILY_OFFICER,
              ],
              attentionCounter: getItemAttentionCounter(
                "taxi-unbilled-routes-listing"
              ),
            },
            {
              icon: faList,
              text: translations.billings.taxi.unbilledTransferredOrderListing,
              key: `taxi-unbilled-transferred-orders-listing`,
              path: billingRoutesHelper.getTaxiUnbilledTransferredOrderListingRoute(),
              userRolesWhitelist: [
                UserRole.ADMIN,
                UserRole.TAXI_OFFICER,
                UserRole.RAILY_OFFICER,
              ],
              attentionCounter: getItemAttentionCounter(
                "taxi-unbilled-transferred-orders-listing"
              ),
            },
            {
              icon: faList,
              text: translations.billings.taxi.transferredOrderListing,
              key: `taxi-billed-transferred-orders-listing`,
              path: billingRoutesHelper.getTaxiTransferredOrderListingRoute(),
              userRolesWhitelist: [
                UserRole.ADMIN,
                UserRole.TAXI_OFFICER,
                UserRole.RAILY_OFFICER,
              ],
              attentionCounter: getItemAttentionCounter(
                "taxi-billed-transferred-orders-listing"
              ),
            },
          ],
        },
        {
          icon: faR,
          text: translations.billings.raily.groupLabel,
          key: `raily-billings`,
          userRolesWhitelist: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
          items: [
            {
              icon: faList,
              text: translations.billings.raily.orderBillingListing,
              key: `raily-unbilled-orders-listing`,
              path: billingRoutesHelper.getRailyUnbilledOrderListingRoute(),
              userRolesWhitelist: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
            },
          ],
        },
      ],
    },
    {
      icon: faCodePullRequest,
      text: translations.orderOptimizer.groupLabel,
      key: `order-optimizer`,
      userRolesWhitelist: [UserRole.ADMIN, UserRole.OPERATOR],
      items: [
        {
          icon: faList,
          text: translations.orderOptimizer.listing,
          key: `order-optimizer-listing`,
          path: orderOptimizerRoutesHelper.getListingRoute(),
          userRolesWhitelist: [UserRole.ADMIN, UserRole.OPERATOR],
        },
      ],
    },
    {
      icon: faMapMarkedAlt,
      text: translations.mileage.groupLabel,
      key: `mileage`,
      userRolesWhitelist: [
        UserRole.ADMIN,
        UserRole.RAILY_OFFICER,
        UserRole.CARGO_OFFICER,
        UserRole.DISPATCHER,
      ],
      items: [
        {
          icon: faList,
          text: translations.mileage.listing,
          key: `mileage-listing`,
          path: mileageRoutesHelper.getListingRoute(),
          userRolesWhitelist: [
            UserRole.ADMIN,
            UserRole.RAILY_OFFICER,
            UserRole.CARGO_OFFICER,
            UserRole.DISPATCHER,
          ],
        },
        {
          icon: faAdd,
          text: translations.mileage.addNew,
          key: `mileage-add-new`,
          path: mileageRoutesHelper.getAddRoute(),
          userRolesWhitelist: [
            UserRole.ADMIN,
            UserRole.RAILY_OFFICER,
            UserRole.CARGO_OFFICER,
            UserRole.DISPATCHER,
          ],
        },
        {
          icon: faGear,
          text: translations.mileage.settings.groupLabel,
          key: `mileage-settings`,
          userRolesWhitelist: [
            UserRole.ADMIN,
            UserRole.RAILY_OFFICER,
            UserRole.CARGO_OFFICER,
            UserRole.DISPATCHER,
          ],
          items: [
            {
              icon: faList,
              text: translations.mileage.settings.contractListingLabel,
              key: `mileage-settings-contracts`,
              path: mileageRoutesHelper.getContractListingRoute(),
              userRolesWhitelist: [UserRole.ADMIN],
            },
            {
              icon: faAdd,
              text: translations.mileage.settings.contractAddLabel,
              key: `mileage-settings-contracts-add`,
              path: mileageRoutesHelper.getContractAddRoute(),
              userRolesWhitelist: [UserRole.ADMIN],
            },
            {
              icon: faSliders,
              text: translations.mileage.settings.optionsLabel,
              key: `mileage-settings-options`,
              path: mileageRoutesHelper.getSettingsRoute(),
              userRolesWhitelist: [
                UserRole.ADMIN,
                UserRole.RAILY_OFFICER,
                UserRole.CARGO_OFFICER,
                UserRole.DISPATCHER,
              ],
            },
            {
              icon: faBookOpen,
              text: translations.mileage.settings.dictionaryLabel,
              key: `mileage-settings-dictionary`,
              path: mileageRoutesHelper.getDictionaryRoute(),
              userRolesWhitelist: [
                UserRole.ADMIN,
                UserRole.RAILY_OFFICER,
                UserRole.CARGO_OFFICER,
                UserRole.DISPATCHER,
              ],
            },
          ],
        },
      ],
    },
    {
      icon: faBuildingUser,
      text: translations.delegation.groupLabel,
      key: `delegation`,
      userRolesWhitelist: [
        UserRole.ADMIN,
        UserRole.RAILY_OFFICER,
        UserRole.CARGO_OFFICER,
      ],
      items: [
        {
          icon: faList,
          text: translations.delegation.delegationListing,
          key: `delegation-listing`,
          path: delegationRoutesHelper.getListingRoute(),
          userRolesWhitelist: [
            UserRole.ADMIN,
            UserRole.RAILY_OFFICER,
            UserRole.CARGO_OFFICER,
          ],
        },
        {
          icon: faAdd,
          text: translations.delegation.addNew,
          key: `delegation-add-new`,
          path: delegationRoutesHelper.getAddRoute(),
          userRolesWhitelist: [
            UserRole.ADMIN,
            UserRole.RAILY_OFFICER,
            UserRole.CARGO_OFFICER,
          ],
        },
      ],
    },
  ];
};

const filterNavigationDefinitionForUserRoles = (
  navigationDefinition: AppAsideNavigationDefinitionItem[],
  userRoles: UserRole[]
): AppAsideNavigationDefinitionItem[] => {
  return navigationDefinition.filter((navigationDefinitionItem) => {
    const isLink = checkIsDefinitionLink(navigationDefinitionItem);

    if (isLink) {
      const isOk = userRoles.some((userRole) =>
        navigationDefinitionItem.userRolesWhitelist.includes(userRole)
      );

      return isOk;
    }

    const isOk = userRoles.some((userRole) =>
      navigationDefinitionItem.userRolesWhitelist.includes(userRole)
    );

    if (isOk) {
      navigationDefinitionItem.items = filterNavigationDefinitionForUserRoles(
        navigationDefinitionItem.items,
        userRoles
      );
    }

    return isOk;
  });
};

const getNavigationDefinitionForUserRoles = (
  userRoles: UserRole[],
  attentionCounters?: AppAsideAttentionCountersResponseData
) => {
  const navigationDefinition = getNavigationDefinition(
    userRoles,
    attentionCounters
  );
  const navigationDefinitionForCurrentUserRoles =
    filterNavigationDefinitionForUserRoles(navigationDefinition, userRoles);

  return navigationDefinitionForCurrentUserRoles;
};

const checkIsDefinitionLink = (
  item: AppAsideNavigationDefinitionItem
): item is AppAsideNavigationDefinitionLink => {
  return (item as AppAsideNavigationDefinitionLink).path !== undefined;
};

const getPathToNavigationItemByPathname = (
  navigationDefinitionItems: AppAsideNavigationDefinitionItem[],
  pathname: string,
  pathKeys: string[]
): string[] => {
  let pathKeysOfFound: string[] = [];

  for (const navigationDefinitionItem of navigationDefinitionItems) {
    if (!!pathKeysOfFound.length) {
      break;
    }
    const isLink = checkIsDefinitionLink(navigationDefinitionItem);

    if (isLink) {
      const isMatching = navigationDefinitionItem.path === pathname;

      if (isMatching) {
        pathKeysOfFound = pathKeys;
        break;
      }

      continue;
    }

    pathKeysOfFound = getPathToNavigationItemByPathname(
      navigationDefinitionItem.items,
      pathname,
      [...pathKeys, navigationDefinitionItem.key]
    );
  }

  return pathKeysOfFound;
};

const getListOfDefaultOpenedNavigationItemKeys = (
  navigationDefinitionItems: AppAsideNavigationDefinitionItem[],
  pathname: string
): string[] => {
  return getPathToNavigationItemByPathname(
    navigationDefinitionItems,
    pathname,
    []
  );
};

const appAsideNavigationHelper = {
  getNavigationDefinition,
  getNavigationDefinitionForUserRoles,
  checkIsDefinitionLink,
  getListOfDefaultOpenedNavigationItemKeys,
};

export default appAsideNavigationHelper;
