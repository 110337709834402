import { FC, ReactNode, useEffect, useRef, useState, useMemo } from "react";
import { MapContainer, Polyline, TileLayer } from "react-leaflet";
import temporaryMarkerIcon from "../../../assets/icons/temporary-marker-icon.png";
import {
  Icon,
  LatLngBounds,
  LatLngTuple,
  Map,
  Point,
  LeafletMouseEvent,
  LatLngExpression,
} from "leaflet";
import MapCoordinate from "./types/map-coordinate";
import MapMarker from "./types/map-marker";
import mapHelper from "./map.helper";
import MapMarkerComponent from "./marker/map-marker.component";
import useIsComponentMounted from "../../hooks/use-is-component-mounted";
import MapRoute from "./types/map-route";
import FlagIcon from "./icons/flag";
import ButtonComponent from "../button/button.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import HorizontalSlider from "../horizontal-slider/horizontal-slider.component";
import orderTranslationsHelper from "../../../languages/order-translations.helper";
import { useAppContext } from "../../../context/app.context";

type MapProps = {
  onMapLongClick?: (coordinate: MapCoordinate) => void;
  markers?: MapMarker[];
  gpsMarkers?: MapMarker[];
  onMarkerCoordinateChange?: (updatedMarker: MapMarker) => void;
  temporaryMarkers?: MapMarker[];
  onTemporaryMarkerCoordinateChange?: (
    updatedTemporaryMarker: MapMarker
  ) => void;
  centerLocation?: MapCoordinate;
  zoom?: number;
  autoFit?: boolean;
  autoFitOnUpdate?: boolean;
  isMessageBoxVisible?: boolean;
  messageBox?: ReactNode;
  refreshFitFlag?: boolean;
  routes?: MapRoute[];
  shouldShowCompletedMapRoutes?: boolean;
  isBilling?: boolean;
};

const MapComponent: FC<MapProps> = (props) => {
  const mapRef = useRef<Map>(null);
  const mapConfig = mapHelper.getDefaultConfig();
  const isComponentMounted = useIsComponentMounted();
  const [isMapReady, setIsMapReady] = useState(false);
  const [sliderExpanded, setSliderExpanded] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState<number | null>(
    null
  );

  const gpsMarkersCount = props.gpsMarkers?.length ?? 0;
  const translations = orderTranslationsHelper.getDetailsTranslations();
  const { selectedAppLanguage } = useAppContext();

  const refreshZoom = (lat?: number, lon?: number) => {
    const markerLatLngTuples: LatLngTuple[] =
      props.markers?.map((marker) => [
        lat !== undefined ? lat : marker.coordinate.latitude,
        lon !== undefined ? lon : marker.coordinate.longitude,
      ]) ?? [];

    if (markerLatLngTuples.length === 0) {
      const defaultLatLongTuple: LatLngTuple = [
        mapConfig.defaultCenterLocation.lat,
        mapConfig.defaultCenterLocation.lng,
      ];
      mapRef.current?.setView(defaultLatLongTuple, zoom);
      return;
    }

    if (!props.shouldShowCompletedMapRoutes) {
      const bounds = new LatLngBounds(markerLatLngTuples);
      mapRef.current?.fitBounds(bounds, {
        padding: new Point(25, 25),
      });
    } else {
      const bounds = new LatLngBounds(markerLatLngTuples);
      mapRef.current?.fitBounds(bounds, {
        padding: new Point(25, 25),
        maxZoom: mapRef.current.getZoom(),
      });
    }
  };

  const checkElementIsMapContainer = (
    element: HTMLDivElement | null
  ): boolean => {
    return element?.className?.includes(`leaflet-container`) ?? false;
  };

  useEffect(() => {
    if (
      !mapRef.current ||
      !isMapReady ||
      !props.autoFit ||
      !props.markers?.length
    ) {
      return;
    }
    refreshZoom();
  }, [
    mapRef.current,
    isMapReady,
    props.isBilling ? props.markers : props.gpsMarkers,
  ]);

  useEffect(() => {
    if (!isComponentMounted || !isMapReady) return;

    if (props.markers?.length) {
      refreshZoom();
    } else {
      const defaultLatLongTuple: LatLngTuple = [
        mapConfig.defaultCenterLocation.lat,
        mapConfig.defaultCenterLocation.lng,
      ];

      const bounds = new LatLngBounds([defaultLatLongTuple]);
      mapRef.current
        ?.fitBounds(bounds, { padding: new Point(25, 25) })
        .setZoom(mapConfig.defaultZoom);
    }
  }, [props.refreshFitFlag]);

  useEffect(() => {
    if (!mapRef.current || !props.onMapLongClick || !isMapReady) {
      return;
    }

    const longClickTime = 500;
    let longClickTimeout: NodeJS.Timeout | null = null;
    let isLongClickActive = false;

    const handleLongClickEvent = (event: LeafletMouseEvent) => {
      const coordinate: MapCoordinate = {
        latitude: event.latlng.lat,
        longitude: event.latlng.lng,
      };

      props.onMapLongClick!(coordinate);
    };

    const handleMouseDown = (event: LeafletMouseEvent) => {
      if (
        !checkElementIsMapContainer(
          event.originalEvent.currentTarget as HTMLDivElement
        )
      ) {
        return;
      }
      longClickTimeout = setTimeout(() => {
        isLongClickActive = true;
      }, longClickTime);
    };

    const handleMoveStart = () => {
      clearInterval(longClickTimeout!);
      isLongClickActive = false;
    };

    const handleMouseUp = (event: LeafletMouseEvent) => {
      if (isLongClickActive) {
        handleLongClickEvent(event);
        isLongClickActive = false;
      }
      clearInterval(longClickTimeout!);
      isLongClickActive = false;
    };

    mapRef.current?.on("mousedown", handleMouseDown);
    mapRef.current?.on("mouseup", handleMouseUp);
    mapRef.current?.on("movestart", handleMoveStart);

    return () => {
      mapRef.current?.off("mousedown", handleMouseDown);
      mapRef.current?.off("mouseup", handleMouseUp);
      mapRef.current?.off("movestart", handleMoveStart);
    };
  }, [mapRef.current, props.markers, isMapReady]);

  useEffect(() => {
    if (props.autoFitOnUpdate && mapRef.current && props.markers?.length) {
      refreshZoom();
    }
  }, [props.autoFitOnUpdate, mapRef.current, props.markers]);

  const mapMarkersWithoutDuplicates = useMemo(
    () => mapHelper.getMarkersWithoutDuplicates(props.markers),
    [props.markers]
  );

  const zoom = props.zoom ?? mapConfig.defaultZoom;

  const center: LatLngExpression = props.centerLocation
    ? {
        lat: props.centerLocation.latitude,
        lng: props.centerLocation.longitude,
      }
    : mapConfig.defaultCenterLocation;

  const handleHideSlider = () => {
    setSliderExpanded(false);
    setSelectedMarkerIndex(null);
    setSliderValue(0);
  };

  const handleShowSlider = () => {
    setSliderExpanded(true);
    setSliderValue(0);
    setSelectedMarkerIndex(0);
  };

  const handleSliderChange = (value: number) => {
    if (!gpsMarkersCount) return;
    setSliderValue(value);
    setSelectedMarkerIndex(value);
    refreshZoom(
      props.gpsMarkers![value].coordinate.latitude,
      props.gpsMarkers![value].coordinate.longitude
    );
  };

  useEffect(() => {
    if (
      props.shouldShowCompletedMapRoutes === false &&
      mapRef.current &&
      isMapReady
    ) {
      setSelectedMarkerIndex(0);
      setSliderValue(0);
      refreshZoom();
    }
  }, [props.shouldShowCompletedMapRoutes, isMapReady]);

  const firstGpsTime = useMemo(() => {
    return props.gpsMarkers?.length ? props.gpsMarkers[0].title : null;
  }, [props.gpsMarkers]);

  const lastGpsTime = useMemo(() => {
    return props.gpsMarkers?.length
      ? props.gpsMarkers[props.gpsMarkers.length - 1].title
      : null;
  }, [props.gpsMarkers]);

  const middleGpsTime = useMemo(() => {
    if (!props.gpsMarkers || props.gpsMarkers.length < 3) return null;
    const middleIndex = Math.floor(props.gpsMarkers.length / 2);
    return props.gpsMarkers[middleIndex].title;
  }, [props.gpsMarkers]);

  const firstHalfMiddleTime = useMemo(() => {
    if (!props.gpsMarkers || props.gpsMarkers.length < 5) return null;
    const firstMiddleIndex = Math.floor(props.gpsMarkers.length / 4);
    return props.gpsMarkers[firstMiddleIndex].title;
  }, [props.gpsMarkers]);

  const secondHalfMiddleTime = useMemo(() => {
    if (!props.gpsMarkers || props.gpsMarkers.length < 5) return null;
    const secondMiddleIndex = Math.floor((props.gpsMarkers.length * 3) / 4);
    return props.gpsMarkers[secondMiddleIndex].title;
  }, [props.gpsMarkers]);

  return (
    <div className="map">
      <MapContainer
        className="map_container"
        center={center}
        zoom={zoom}
        ref={mapRef}
        scrollWheelZoom={true}
        whenReady={() => setIsMapReady(true)}
        attributionControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {props.isMessageBoxVisible && (
          <div className="map__message_box">{props.messageBox}</div>
        )}
        {props.routes?.map((route, index) => {
          if (!route.waypoints.length) {
            return null;
          }
          const firstWaypoint = route.waypoints[0];
          const lastWaypoint = route.waypoints[route.waypoints.length - 1];
          return (
            <Polyline
              key={`mapPolyLine-${index}-from-${firstWaypoint.latitude}${firstWaypoint.longitude}-to-${lastWaypoint.latitude}${lastWaypoint.longitude}`}
              pathOptions={{
                color: route.options?.color ?? mapConfig.defaultRouteColor,
              }}
              positions={route.waypoints.map((waypoint) => ({
                lat: waypoint.latitude,
                lng: waypoint.longitude,
              }))}
            />
          );
        })}

        {mapMarkersWithoutDuplicates.map((marker, index) => {
          const markerKey = `mapMarker-${marker.coordinate.latitude};${marker.coordinate.longitude}-${index}`;

          return (
            <MapMarkerComponent
              key={markerKey}
              coordinate={marker.coordinate}
              icon={marker.icon}
              title={marker.title}
              tooltip={marker.tooltip}
              isDraggable={marker.isDraggable}
              onCoordinateChange={(coordinate) => {
                props.onMarkerCoordinateChange &&
                  props.onMarkerCoordinateChange({ ...marker, coordinate });
              }}
            />
          );
        })}
        {props.temporaryMarkers?.map((marker, index) => {
          const icon = new Icon({
            iconUrl: temporaryMarkerIcon,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
          });
          return (
            <MapMarkerComponent
              key={`temporaryMapMarker-${index}`}
              coordinate={marker.coordinate}
              icon={icon}
              title={marker.title}
              tooltip={marker.tooltip}
              isDraggable={marker.isDraggable}
              onCoordinateChange={(coordinate) => {
                props.onTemporaryMarkerCoordinateChange &&
                  props.onTemporaryMarkerCoordinateChange({
                    ...marker,
                    coordinate,
                  });
              }}
            />
          );
        })}
        {props.gpsMarkers?.map((marker, index) => {
          const isSelected = index === selectedMarkerIndex;
          return (
            <MapMarkerComponent
              key={`gpsMarker-${index}`}
              coordinate={marker.coordinate}
              icon={marker.icon}
              title={marker.title}
              tooltip={marker.tooltip}
              isSelected={isSelected}
              sliderActive={sliderExpanded}
              isDraggable={marker.isDraggable}
              onCoordinateChange={(coordinate) => {
                props.onTemporaryMarkerCoordinateChange &&
                  props.onTemporaryMarkerCoordinateChange({
                    ...marker,
                    coordinate,
                  });
              }}
            />
          );
        })}
      </MapContainer>
      <div className="map__attribution">
        <a href="https://leafletjs.com">
          <FlagIcon /> Leaflet
        </a>{" "}
        | © <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>{" "}
        contributors
      </div>
      {sliderExpanded &&
        props.shouldShowCompletedMapRoutes &&
        gpsMarkersCount > 0 && (
          <div className="map__slider">
            <HorizontalSlider
              value={sliderValue}
              maxValue={gpsMarkersCount - 1}
              onChange={handleSliderChange}
            />
            <div className="map__slider__timestamps">
              <span className="map__slider__time map__slider__time_left">
                {firstGpsTime}
              </span>
              {firstHalfMiddleTime && (
                <span className="map__slider__time map__slider__time_quarter_left">
                  {firstHalfMiddleTime}
                </span>
              )}
              {middleGpsTime && (
                <span className="map__slider__time map__slider__time_middle">
                  {middleGpsTime}
                </span>
              )}
              {secondHalfMiddleTime && (
                <span className="map__slider__time map__slider__time_quarter_right">
                  {secondHalfMiddleTime}
                </span>
              )}
              <span className="map__slider__time map__slider__time_right">
                {lastGpsTime}
              </span>
            </div>
          </div>
        )}

      {sliderExpanded && props.shouldShowCompletedMapRoutes && (
        <ButtonComponent
          onClick={handleHideSlider}
          classNames={{ root: "map__button" }}
          title={translations.hideSliderButtonTitle}
          type="primary"
        >
          <FontAwesomeIcon icon={faAngleUp} className="map__button__icon" />
        </ButtonComponent>
      )}

      {!sliderExpanded &&
        props.shouldShowCompletedMapRoutes &&
        gpsMarkersCount > 0 && (
          <ButtonComponent
            onClick={handleShowSlider}
            classNames={{ root: "map__button" }}
            title={translations.showSliderButtonTitle}
            type="primary"
          >
            <FontAwesomeIcon icon={faAngleDown} className="map__button__icon" />
          </ButtonComponent>
        )}
    </div>
  );
};

MapComponent.defaultProps = {};

export default MapComponent;
