import HttpResponse from "../../../utils/http/http.response";
import railyApiService from "../raily-api.service";
import OrderCancelRequestRequest from "./order-cancel-request/order-cancel-request.request";
import OrderCancelRequest from "./order-cancel/order-cancel.request";
import RevertOrderCancelRequestRequest from "./revert-cancel-request/revert-cancel-request.request";
import RevertOrderCancelRequestResponse from "./revert-cancel-request/revert-cancel-request.response";

const orderApiService = () => {
  const cancel = (
    request: OrderCancelRequest,
    abortSignal: AbortSignal
  ): Promise<HttpResponse> => {
    const url = `/orders/${request.orderId}/cancel-order`;

    return railyApiService().post({
      url,
      abortSignal,
    });
  };

  const sendCancelRequest = (
    request: OrderCancelRequestRequest,
    abortSignal: AbortSignal
  ): Promise<HttpResponse> => {
    const url = `/orders/${request.orderId}/cancel-request`;

    return railyApiService().post({
      url,
      abortSignal,
    });
  };

  const sentRevertCancelRequest = (
    request: RevertOrderCancelRequestRequest,
    abortSignal: AbortSignal
  ): Promise<RevertOrderCancelRequestResponse> => {
    const url = `/orders/${request.orderId}/revert-cancel-request`;

    return railyApiService().delete({
      url,
      abortSignal,
    });
  };

  return {
    cancel,
    sendCancelRequest,
    sentRevertCancelRequest,
  };
};

export default orderApiService;
