import userTranslationsHelper from "../../../../../../languages/user-translations.helper";
import DriverEditCitizenshipType from "../types/driver-edit-citizenship-type";
import DriverEditCitizenshipTypeSelectOption from "../types/driver-edit-citizenship-type-select-option";
import DriverEditFormOfEmploymentType from "../types/driver-edit-form-of-employment-type";
import DriverEditFormOfEmploymentTypeSelectOption from "../types/driver-edit-form-of-employment-type-select-option";
import DriverEditLanguageType from "../types/driver-edit-language-type";
import DriverEditLanguageTypeSelectOption from "../types/driver-edit-language-type-select-option";

const citizenshipLabel = (type: DriverEditCitizenshipType): string => {
  const translations =
    userTranslationsHelper.getDriverEditTranslations().form.userData
      .citizenship;
  switch (type) {
    case DriverEditCitizenshipType.BY:
      return translations.typeBY;
    case DriverEditCitizenshipType.CZ:
      return translations.typeCZ;
    case DriverEditCitizenshipType.DE:
      return translations.typeDE;
    case DriverEditCitizenshipType.LT:
      return translations.typeLT;
    case DriverEditCitizenshipType.PL:
      return translations.typePL;
    case DriverEditCitizenshipType.RU:
      return translations.typeRU;
    case DriverEditCitizenshipType.SK:
      return translations.typeSK;
    case DriverEditCitizenshipType.UA:
      return translations.typeUA;
  }
};

const languageLabel = (type: DriverEditLanguageType): string => {
  const translations =
    userTranslationsHelper.getDriverEditTranslations().form.userData.languages;
  switch (type) {
    case DriverEditLanguageType.BY:
      return translations.typeBY;
    case DriverEditLanguageType.CZ:
      return translations.typeCZ;
    case DriverEditLanguageType.DE:
      return translations.typeDE;
    case DriverEditLanguageType.LT:
      return translations.typeLT;
    case DriverEditLanguageType.PL:
      return translations.typePL;
    case DriverEditLanguageType.RU:
      return translations.typeRU;
    case DriverEditLanguageType.SK:
      return translations.typeSK;
    case DriverEditLanguageType.UA:
      return translations.typeUA;
    case DriverEditLanguageType.EN:
      return translations.typeEN;
    case DriverEditLanguageType.ES:
      return translations.typeES;
    case DriverEditLanguageType.FR:
      return translations.typeFR;
  }
};

const formOfEmploymentLabel = (
  type: DriverEditFormOfEmploymentType
): string => {
  const translations =
    userTranslationsHelper.getDriverEditTranslations().form.userData
      .formOfEmployment;
  switch (type) {
    case DriverEditFormOfEmploymentType.B2B:
      return translations.typeB2B;
    case DriverEditFormOfEmploymentType.EMPLOYMENT:
      return translations.typeEmployment;
  }
};

const createCitizenshipSelectOption = (
  type: DriverEditCitizenshipType
): DriverEditCitizenshipTypeSelectOption => {
  return {
    label: citizenshipLabel(type),
    value: type,
    idForTesting: `driver-add-citizenship-${String(type)}-select-option`,
  };
};

const createLanguageSelectOption = (
  type: DriverEditLanguageType
): DriverEditLanguageTypeSelectOption => {
  return {
    label: languageLabel(type),
    value: type,
    idForTesting: `driver-add-language-${String(type)}-select-option`,
  };
};

const createFormOfEmploymentSelectOption = (
  type: DriverEditFormOfEmploymentType
): DriverEditFormOfEmploymentTypeSelectOption => {
  return {
    label: formOfEmploymentLabel(type),
    value: type,
    idForTesting: `driver-add-form-of-employment-${String(type)}-select-option`,
  };
};

const createCitizenshipSelectOptions =
  (): DriverEditCitizenshipTypeSelectOption[] => {
    return Object.keys(DriverEditCitizenshipType).map((value) =>
      createCitizenshipSelectOption(value as DriverEditCitizenshipType)
    );
  };

const createLanguageSelectOptions =
  (): DriverEditLanguageTypeSelectOption[] => {
    return Object.keys(DriverEditLanguageType).map((value) =>
      createLanguageSelectOption(value as DriverEditLanguageType)
    );
  };

const createFormOfEmploymentSelectOptions =
  (): DriverEditFormOfEmploymentTypeSelectOption[] => {
    return Object.keys(DriverEditFormOfEmploymentType).map((value) =>
      createFormOfEmploymentSelectOption(
        value as DriverEditFormOfEmploymentType
      )
    );
  };

const driverEditCitizenshipTypeFactory = {
  createCitizenshipSelectOptions,
  createLanguageSelectOptions,
  createFormOfEmploymentSelectOptions,
  createCitizenshipSelectOption,
  createLanguageSelectOption,
  createFormOfEmploymentSelectOption,
};

export default driverEditCitizenshipTypeFactory;
