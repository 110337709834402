import { FC, useMemo } from "react";
import TableComponent, {
  TableProps,
} from "../../../../common/components/table/table.component";
import { OrderOptimizerListingItem } from "../common/types/order-optimizer-listing-item";
import { useAppContext } from "../../../../context/app.context";
import OrderOptimizerListingTableColumn from "../common/types/order-optimizer-listing-table-column";
import orderOptimizerListingTableHelper from "./order-optimizer-listing-table.helpers";
import OrderOptimizerListingTableRow from "../common/types/order-optimizer-listing-table-row";
import OrderOptimizerListingTableRowComponent from "./row/order-optimizer-listing-table-row.component";
import orderOptimizerRoutesHelper from "../../common/routes/order-optimizer-routes.helper";
import OrderOptimizerOptimizationStatusLoadParams from "../../../../common/services/order-optimizer/optimization-status/order-optimizer-optimization-status-load-params";
import useAbort from "../../../../common/hooks/use-abort";
import orderOptimizerService from "../../../../common/services/order-optimizer/order-optimizer.service";
import OrderOptimizerOptimizationStatus from "../../../../common/services/order-optimizer/optimization-status/order-optimizer-optimization-status";
import notificationService from "../../../../common/utils/notification/notification.service";
import orderOptimizerTranslationsHelper from "../../../../languages/order-optimizer-translations.helper";

type OrderOptimizerListingTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: OrderOptimizerListingItem[];
  selectedHumanId: number | undefined;
  applyOptimizationStatus: (
    optimizationStatus: OrderOptimizerOptimizationStatus,
    optimizationId: string
  ) => void;
};

const OrderOptimizerListingTableComponent: FC<
  OrderOptimizerListingTableProps
> = (props) => {
  const { selectedAppLanguage, user } = useAppContext();
  const translation =
    orderOptimizerTranslationsHelper.getListingTranslations().table;

  const columns = useMemo(
    (): OrderOptimizerListingTableColumn[] =>
      orderOptimizerListingTableHelper.getColumns(),
    []
  );

  const orderOptimizerOptimizationStatusAbort = useAbort();

  const onOptimizationClick = async (listingItemUuid: string) => {
    const optimizationStatusLoadParams: OrderOptimizerOptimizationStatusLoadParams =
      {
        optimizationId: listingItemUuid,
      };

    await orderOptimizerService
      .getOptimizationStatus(
        optimizationStatusLoadParams,
        orderOptimizerOptimizationStatusAbort.signal
      )
      .then((optimizationStatus) => {
        if (
          !!optimizationStatus.reservedAt &&
          !!optimizationStatus.reservedBy
        ) {
          props.applyOptimizationStatus(optimizationStatus, listingItemUuid);
        } else {
          const currentUserStatus: OrderOptimizerOptimizationStatus = {
            reservedAt: new Date(),
            reservedBy: `${user?.firstName} ${user?.lastName}`,
          };

          props.applyOptimizationStatus(currentUserStatus, listingItemUuid);
        }
      })
      .then(() => {
        window.open(
          orderOptimizerRoutesHelper.getDetailsRoute({
            id: listingItemUuid,
          }),
          "_blank"
        );
      })
      .catch(() => {
        notificationService.error(
          translation.optimizationReservationErrorNotification
        );
      });
  };

  const createTableRow = (
    listingItem: OrderOptimizerListingItem
  ): OrderOptimizerListingTableRow => {
    return {
      id: listingItem.id,
      value: {
        row: <></>,
      },
      hiddenComponent: (
        <OrderOptimizerListingTableRowComponent
          listingItem={listingItem}
          selectedHumanId={props.selectedHumanId}
          onOptimizationRequestClick={() => onOptimizationClick(listingItem.id)}
        />
      ),
      isHiddenComponentVisible: true,
    };
  };

  const rows: OrderOptimizerListingTableRow[] = useMemo(() => {
    return props.listingItems.map((list) => createTableRow(list));
  }, [props.listingItems, selectedAppLanguage]);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isLoading={props.isLoading}
      isError={props.isError}
      noHeader
    />
  );
};

export default OrderOptimizerListingTableComponent;
