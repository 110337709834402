import { FC } from "react";
import PlanningOrderDriverPlanListingItemChart, {
  PlanXAxisValue,
} from "../../driver-plan-listing/common/planner-order-driver-listing-item-chart";
import ChartComponent from "../../../../../common/components/chart/chart.component";
import planningOrderDriverPlanListingSelectedItemFactory from "./planning-order-driver-plan-listing-selected-item.factory";
import ChartData from "../../../../../common/components/chart/types/chart-data";
import planningTranslationsHelper from "../../../../../languages/planning-translations.helper";
import PlanningOrderDriverPlanListingSelectedItemChartData from "../types/planner-order-driver-plan-listing-selected-item-chart-data";

type PlanningOrderDriverPlanListingSelectedItemChartProps = {
  item: PlanningOrderDriverPlanListingSelectedItemChartData;
  parentRef: React.RefObject<HTMLDivElement>;
};

const PlanningOrderDriverPlanListingSelectedItemChartComponent: FC<
  PlanningOrderDriverPlanListingSelectedItemChartProps
> = (props) => {
  const translations =
    planningTranslationsHelper.getOrderTranslations().driverPlanListing;

  const chartData: PlanningOrderDriverPlanListingItemChart =
    planningOrderDriverPlanListingSelectedItemFactory.createChartItemData(
      props.item
    );

  const data: ChartData =
    planningOrderDriverPlanListingSelectedItemFactory.createChartItems(
      chartData
    );

  return (
    <ChartComponent
      data={data}
      parentRef={props.parentRef}
      barHeight={15}
      domainPadding={27}
      tickLabelsFontSize={11}
      barLabelsAngle={-50}
      referenceGroupValue={PlanXAxisValue.FIRST_ROW}
      topLabelFontSize={11}
      barLabelsLeftPadding={-10}
      barLabelsTopPadding={10}
      topLabelGroupName={translations.chart.routeLabel}
    />
  );
};

export default PlanningOrderDriverPlanListingSelectedItemChartComponent;
