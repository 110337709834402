import { FormValidationDefinition } from "../../../../../../common/components/form/use-form";
import driverAddVehicleFormValidationService from "./driver-add-vehicle-form-validation.service";
import DriverAddVehicleFormData from "./types/driver-add-vehicle-form-data";

const getDefaultVehicleFormData = (): DriverAddVehicleFormData => {
  return {
    make: "",
    model: "",
    numberOfSeats: null,
    ownership: null,
    productionYear: null,
    registrationNumber: "",
  };
};

const getVehicleValidationDefinition =
  (): FormValidationDefinition<DriverAddVehicleFormData> => {
    return {
      make: (values) =>
        driverAddVehicleFormValidationService.validateMake(values.make),
      model: (values) =>
        driverAddVehicleFormValidationService.validateModel(values.model),
      numberOfSeats: (values) =>
        driverAddVehicleFormValidationService.validateNumberOfSeats(
          values.numberOfSeats
        ),
      ownership: (values) =>
        driverAddVehicleFormValidationService.validateOwnership(
          values.ownership
        ),
      productionYear: (values) =>
        driverAddVehicleFormValidationService.validateProductionYear(
          values.productionYear
        ),
      registrationNumber: (values) =>
        driverAddVehicleFormValidationService.validateRegistrationNumber(
          values.registrationNumber
        ),
    };
  };

const driverAddVehicleFormHelper = {
  getDefaultVehicleFormData,
  getVehicleValidationDefinition,
};

export default driverAddVehicleFormHelper;
