import { FC, useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../../../context/app.context";
import DriverDetailsDriverPlanEntry, {
  DriverDetailsDriverPlanEntryNodeExclusions,
  DriverDetailsDriverPlanEntryWaypoint,
  DriverDetailsDriverPlanEntryWaypointType,
} from "./common/types/driver-details-plan-entry";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import MapRoute from "../../../../../common/components/map/types/map-route";
import MapMarker from "../../../../../common/components/map/types/map-marker";
import { isEqual } from "lodash";
import mapMarkerIconFactory from "../../../../../common/components/map/marker/map-marker-icon.factory";
import DriverDetailsFinishedRoutePlansHelper from "./common/driver-details-finished-route-plans-helper";
import Row from "../../../../../common/components/grid/row";
import Column from "../../../../../common/components/grid/column";
import ContentWrapper from "../../../../../common/components/content-wrapper/content-wrapper.component";
import googleMapsRouteService from "../../../../../common/utils/google-maps-route/google-maps-route.service";
import MapComponent from "../../../../../common/components/map/map.component";
import MessengerComponent from "../../../../../common/components/messenger/messenger.component";
import LinkButtonComponent from "../../../../../common/components/button/link/link-button.component";
import DriverDetailsPlanRouteListingComponent from "./route-listing/driver-details-plan-route-listing.component";
import planEntryService from "../../../../../common/services/plan-entry/plan-entry.service";
import PlanEntryRoadRouteLoadParams from "../../../../../common/services/plan-entry/road-route/plan-entry-road-route-load-params";

type DriverDetailsFinishedRoutePlansContentProps = {
  refetchPlan: () => void;
  planEntry: DriverDetailsDriverPlanEntry | undefined;
  asCardComponent?: boolean;
};

const DriverDetailsFinishedRoutePlansContentComponent: FC<
  DriverDetailsFinishedRoutePlansContentProps
> = (props) => {
  useAppContext();
  const translations =
    userTranslationsHelper.getDriverDetailsFinishedRoutePlansTranslations();

  const [mapRoutes, setMapRoutes] = useState<MapRoute[]>([]);

  const isHighwaySegmentAllowed = (
    waypoint: DriverDetailsDriverPlanEntryWaypoint,
    nextWaypoint: DriverDetailsDriverPlanEntryWaypoint,
    planEntry: DriverDetailsDriverPlanEntry
  ): boolean => {
    const waypointIsDriverHome =
      DriverDetailsDriverPlanEntryWaypointType.DRIVER_HOME;

    const hasMatchingUUID =
      (uuid: string) =>
      (excludedNodes: DriverDetailsDriverPlanEntryNodeExclusions) =>
        excludedNodes.nodeIds.includes(uuid);

    const matchedExclusion =
      planEntry.nodeExclusions.find(hasMatchingUUID(waypoint.uuid)) ||
      planEntry.nodeExclusions.find(hasMatchingUUID(nextWaypoint.uuid));

    const highwaySegmentExclusion =
      waypoint.type === waypointIsDriverHome
        ? matchedExclusion?.approaching
        : nextWaypoint.type === waypointIsDriverHome
        ? matchedExclusion?.returning
        : matchedExclusion?.inside;

    return highwaySegmentExclusion!;
  };

  const mapMarkers: MapMarker[] = useMemo(() => {
    if (!props.planEntry) {
      return [];
    }

    return props.planEntry.waypoints.map((item) => {
      const allWaypointsOnThisPlace = props.planEntry?.waypoints.filter((w) =>
        isEqual(w.address, item.address)
      );

      const signature = allWaypointsOnThisPlace
        ?.map((w) => w.stageNo)
        .join("/");

      const mapMarkerIcon = mapMarkerIconFactory.createStandardIcon({
        content: signature,
      });

      const mapMarker: MapMarker = {
        coordinate: {
          latitude: item.address.latitude,
          longitude: item.address.longitude,
        },
        title: item.address.displayName,
        tooltip: item.address.displayName,
        icon: mapMarkerIcon,
      };

      return mapMarker;
    });
  }, [props.planEntry]);

  useEffect(() => {
    setMapRoutes([]);
    if (!props.planEntry) {
      return;
    }

    const waypointPairsForRoute: {
      from: { latitude: number; longitude: number };
      to: { latitude: number; longitude: number };
      excludeHighway: boolean;
    }[] = [];

    props.planEntry.waypoints.forEach((waypoint, index) => {
      const nextWaypoint = props.planEntry?.waypoints[index + 1];

      if (!nextWaypoint) {
        return;
      }

      waypointPairsForRoute.push({
        from: {
          latitude: waypoint.address.latitude,
          longitude: waypoint.address.longitude,
        },
        to: {
          latitude: nextWaypoint.address.latitude,
          longitude: nextWaypoint.address.longitude,
        },
        excludeHighway: isHighwaySegmentAllowed(
          waypoint,
          nextWaypoint,
          props.planEntry!
        ),
      });
    });

    const routeFetchPromises = waypointPairsForRoute.map((pair) => {
      const request: PlanEntryRoadRouteLoadParams = {
        waypoints: [
          {
            lat: pair.from.latitude,
            lon: pair.from.longitude,
          },
          {
            lat: pair.to.latitude,
            lon: pair.to.longitude,
          },
        ],
        excludeHighway: pair.excludeHighway,
      };

      return planEntryService.getRoute(request);
    });

    Promise.all(routeFetchPromises).then((responses) => {
      const mapRoutes: MapRoute[] = responses.map((response) => {
        return {
          waypoints: response.coordinates.map((coordinate) => {
            return {
              latitude: coordinate[1],
              longitude: coordinate[0],
            };
          }),
        };
      });
      setMapRoutes(mapRoutes);
    });
  }, [props.planEntry]);

  if (!props.planEntry) {
    return (
      <div className="driver_details_finished_route_plans">
        <div>{translations.noFinishedPlansNotificationMessage}</div>
      </div>
    );
  }

  const isMessengerVisible = !!props.planEntry;

  const messengerChannelsAvailability =
    DriverDetailsFinishedRoutePlansHelper.getMessengerChannelAvailability();

  const messengerPostsHeadingText =
    DriverDetailsFinishedRoutePlansHelper.getMessengerPostsHeadingText(
      props.planEntry.id
    );

  return (
    <>
      <div className="driver_details_finished_route_plans">
        <Row>
          <Column xl={7}>
            <ContentWrapper asCardComponent={props.asCardComponent}>
              <div>
                {props.planEntry.isAnonymized
                  ? translations.anonymizedRouteHeadingText
                  : translations.routeHeadingTemplateText.replace(
                      "#{routeId}",
                      String(props.planEntry.id)
                    )}
              </div>
              <DriverDetailsPlanRouteListingComponent
                routeWaypoints={props.planEntry.waypoints}
              />
              <div className="driver_details_finished_route_plans__button_container mt-2">
                <LinkButtonComponent
                  to={googleMapsRouteService.createGoogleMapsRouteUrl(
                    props.planEntry.waypoints.map((waypoint) => ({
                      latitude: waypoint.address.latitude,
                      longitude: waypoint.address.longitude,
                    }))
                  )}
                  type="primary"
                  title={translations.googleMapsRouteLabel}
                  openInNewTab
                >
                  {translations.googleMapsRouteLabel}
                </LinkButtonComponent>
              </div>
            </ContentWrapper>
          </Column>
          <Column xl={5}>
            <ContentWrapper asCardComponent={props.asCardComponent}>
              <div className="driver_details_finished_route_plans_map_wrapper">
                <MapComponent
                  markers={mapMarkers}
                  autoFit
                  autoFitOnUpdate
                  routes={mapRoutes}
                />
              </div>
            </ContentWrapper>
            {isMessengerVisible && (
              <ContentWrapper
                asCardComponent={props.asCardComponent}
                classNames={{ root: "mt-4" }}
              >
                <MessengerComponent
                  channelsAvailability={messengerChannelsAvailability}
                  classNames={{
                    root: "driver_details_finished_route_plans__messenger",
                  }}
                  postsHeadingText={messengerPostsHeadingText}
                  planEntryUuid={props.planEntry.uuid}
                  withoutChannelList
                />
              </ContentWrapper>
            )}
          </Column>
        </Row>
      </div>
    </>
  );
};

export default DriverDetailsFinishedRoutePlansContentComponent;
