import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import routeTranslationsHelper from "../../../../../../languages/route-translations.helper";
import LinkButtonComponent from "../../../../../../common/components/button/link/link-button.component";
import routeDetailsHelper from "../../route-details.helper";
import userRoutesHelper from "../../../../../user/common/routes/user-routes.helper";
import { RouteDetailsDriver } from "../../../../../../common/services/route/details/route-details";

type RouteDetailsDriversProps = {
  driver: RouteDetailsDriver;
  planEntryUuid?: string;
};

const RouteDetailsDriverComponent: FC<RouteDetailsDriversProps> = (props) => {
  const translations = routeTranslationsHelper.getDetailsTranslations().drivers;

  return (
    <div className="route_details_drivers">
      <div>{translations.headingLabel}</div>
      <ul className="route_details_drivers_list">
        <LinkButtonComponent
          classNames={{
            root: "route_details_drivers_list_item_button",
            content: "route_details_drivers_list_item_button__content",
          }}
          title={routeDetailsHelper.getDriverButtonTitle(props.driver)}
          to={
            !!props.planEntryUuid
              ? userRoutesHelper.getDriverFinishedPlanRoute({
                  planEntryUuid: props.planEntryUuid,
                })
              : userRoutesHelper.getDriverPlanRoute({
                  driverUuid: props.driver.uuid,
                })
          }
        >
          <FontAwesomeIcon
            icon={faUser}
            className="route_details_drivers_list_item_button__content__icon"
          />
          <div className="route_details_drivers_list_item_button__content__name">
            {routeDetailsHelper.getDriverDisplayName(props.driver)}
          </div>
        </LinkButtonComponent>
      </ul>
    </div>
  );
};

export default RouteDetailsDriverComponent;
