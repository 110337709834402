import { TaxiCorporationListItem } from "../../../../common/services/taxi-corporation/list/taxi-corporation-list";
import TaxiListingItem from "./types/taxi-listing-item";

const createListingItem = (
  listItem: TaxiCorporationListItem
): TaxiListingItem => {
  return {
    uuid: listItem.uuid,
    companyName: listItem.name,
    companyDisplayName: listItem.displayName,
    address: listItem.address,
    companyId: listItem.companyId,
    notes: listItem.notes,
    nationalCourtRegister: listItem.nationalCourtRegister,
    taxNumber: listItem.taxNumber,
    areTaxiTaxiContractsVisibleForRailyOfficer: listItem.isCsi,
  };
};

const createListingItems = (
  listItems: TaxiCorporationListItem[]
): TaxiListingItem[] => {
  return listItems.map(createListingItem);
};

const taxiListingItemFactory = {
  createListingItems,
};

export default taxiListingItemFactory;
