import { FC } from "react";
import TableComponent, {
  TableProps,
} from "../../../../common/components/table/table.component";
import dateService from "../../../../common/utils/date/date.service";
import useRouteFinishedListingUserPermissions from "../common/user-permissions/use-route-finished-listing-user-permissions";
import RouteFinishedListingTableRow from "../common/types/route-finished-listing-table-row";
import RouteFinishedListingTableColumn from "../common/types/route-finished-listing-table-column";
import routeFinishedListingTableHelper from "./route-finished-listing-table.helper";
import RouteFinishedListingItem from "../common/types/route-finished-listing-item";
import RouteDetailsComponent from "../../details/route-details.component";

type RouteFinishedListingTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  expandedRowsUuids: string[];
  listingItems: RouteFinishedListingItem[];
  onExpandedRowsUuidsChange: (expandedRowsUuids: string[]) => void;
};

const RouteFinishedListingTableComponent: FC<RouteFinishedListingTableProps> = (
  props
) => {
  const userPermissions = useRouteFinishedListingUserPermissions();

  const expandRow = (routeUuid: string) => {
    props.onExpandedRowsUuidsChange([...props.expandedRowsUuids, routeUuid]);
  };

  const collapseRow = (routeUuid: string) => {
    const filteredExpandedRows = props.expandedRowsUuids.filter(
      (expandedRowUuid) => expandedRowUuid !== routeUuid
    );

    props.onExpandedRowsUuidsChange(filteredExpandedRows);
  };

  const createTableRow = (
    listingItem: RouteFinishedListingItem
  ): RouteFinishedListingTableRow => {
    const isRowExpanded = props.expandedRowsUuids.includes(listingItem.uuid);

    const onRowClick = () => {
      if (isRowExpanded) {
        collapseRow(listingItem.uuid);
        return;
      }

      expandRow(listingItem.uuid);
    };

    const startDate = dateService.formatDateTime(listingItem.startDate);
    const cargoOrderHumanIds = listingItem.cargoOrderHumanIds.join(", ");

    return {
      id: listingItem.uuid,
      idForTesting: listingItem.uuid,
      onClick: onRowClick,
      hiddenComponent: (
        <RouteDetailsComponent
          routeUuid={listingItem.uuid}
          routeHumanId={listingItem.humanId}
          isVisible={isRowExpanded}
          isOrderFinished
        />
      ),
      isHiddenComponentVisible: isRowExpanded,
      value: {
        startDate: <div title={startDate}>{startDate}</div>,
        humanId: (
          <div title={String(listingItem.humanId)}>{listingItem.humanId}</div>
        ),
        cargoOrderHumanIds: (
          <div title={cargoOrderHumanIds}>{cargoOrderHumanIds}</div>
        ),
        route: (
          <div>
            {listingItem.routeAddresses.map((routeAddress, index) => {
              const stageNo = index + 1;
              return (
                <div
                  title={routeAddress}
                  key={index}
                >{`${stageNo}. ${routeAddress}`}</div>
              );
            })}
          </div>
        ),
        passengers: (
          <div>
            {listingItem.passengers.map((passenger, index) => (
              <div title={passenger} key={index}>
                {passenger}
              </div>
            ))}
          </div>
        ),
        ...(userPermissions.hasAccessToTaxiCorporation && {
          taxiCorporation: listingItem.taxiCorporationName,
        }),
        driver: (
          <div title={listingItem.driverName}>{listingItem.driverName}</div>
        ),
      },
    };
  };

  const columns: RouteFinishedListingTableColumn[] =
    routeFinishedListingTableHelper.getColumns(userPermissions);

  const rows: RouteFinishedListingTableRow[] = props.listingItems.map((item) =>
    createTableRow(item)
  );

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default RouteFinishedListingTableComponent;
