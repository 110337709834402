import debounce from "lodash/debounce";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import ButtonComponent from "../../../../common/components/button/button.component";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import ModalComponent, {
  ModalProps,
} from "../../../../common/components/modal/modal.component";
import notificationService from "../../../../common/utils/notification/notification.service";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import OrderDetailsDriver from "../common/types/order-details-driver";
import orderDetailsManageDriverAssignmentApiService from "./common/api/order-details-manage-driver-assignment-api.service";
import OrderDetailsManageDriverAssignmentDriversRequest from "./common/api/order-details-manage-driver-assignment-drivers.request";
import OrderDetailsManageDriverAssignmentDriversResponse, {
  OrderDetailsManageDriverAssignmentDriversResponseDriver,
} from "./common/api/order-details-manage-driver-assignment-drivers.response";
import orderDetailsManageDriverAssignmentFormValidationService from "./common/order-details-manage-driver-assignment-form-validation.service";
import orderDetailsManageDriverAssignmentFactory from "./common/order-details-manage-driver-assignment.factory";
import orderDetailsManageDriverAssignmentHelper from "./common/order-details-manage-driver-assignment.helper";
import OrderDetailsManageDriverAssignmentDriverSelectOption from "./common/types/order-details-manage-driver-assignment-driver-select-option";
import OrderDetailsManageDriverAssignmentFormData from "./common/types/order-details-manage-driver-assignment-form-data";
import OrderDetailsManageDriverAssignmentFormValidationResults from "./common/types/order-details-manage-driver-assignment-form-validation-results";
import OrderDetailsManageDriverAssignmentSelectOption from "./common/types/order-details-manage-driver-assignment-select-option";
import OrderDetailsManageDriverAssignmentTaxiCorporationSelectOption from "./common/types/order-details-manage-driver-assignment-taxi-corporation-select-option";
import CheckboxComponent from "../../../../common/components/form/checkbox/checkbox.component";
import SingleSelectComponent from "../../../../common/components/form/select/single-select/single-select.component";
import driverPlanService from "../../../../common/services/driver-plan/driver-plan.service";
import DriverPlanCandidatureAddByOrderIdParams from "../../../../common/services/driver-plan/candidature-add-by-order-id/driver-plan-candidature-add-by-order-id-params";
import useAbort from "../../../../common/hooks/use-abort";
import DriverPlanCandidatureAddByOrderIdError, {
  DriverPlanCandidatureAddByOrderIdErrorType,
  DriverPlanCollisionSubjectDirection,
  DriverPlanPlanEntryCandidateStartTimeExpiredError,
} from "../../../../common/services/driver-plan/candidature-add-by-order-id/driver-plan-candidature-add-by-order-id-error";
import useOpen from "../../../../common/hooks/use-open";
import OrderDetailsManageDriverAssignmentCollisionModal from "../manage-driver-assignment-collision/order-details-manage-driver-assignment-collision.component";
import { OrderDetailsCargoOrderAddress } from "../common/types/order-details-cargo-order";
import OrderDetailsManageDriverAssignmentRouteRequest from "./common/api/order-details-manage-driver-assignment-route.request";
import { SearchRoadRoutingRequestWaypointCoordinate } from "../../../../common/utils/search-road-route/search-road-routing.request";
import OrderDetailsManageDriverAssignmentPlanEntryRequest from "./common/api/order-details-manage-driver-assignment-plan-entry.request";

type OrderDetailsManageDriverAssignmentModalProps = Pick<
  ModalProps,
  "isOpen" | "onClose"
> & {
  hasAccessToEdit: boolean;
  assignmentDrivers: OrderDetailsDriver[];
  orderUuid: string;
  cargoCompanyOrderId: number;
  cargoOrderStartingAddress: OrderDetailsCargoOrderAddress;
  onSuccess: () => void;
  transportingOrderUuid: string;
};

const OrderDetailsManageDriverAssignmentModal: FC<
  OrderDetailsManageDriverAssignmentModalProps
> = (props) => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().manageDriverAssignment;

  const [driverSelectOptions, setDriverSelectOptions] = useState<
    OrderDetailsManageDriverAssignmentDriverSelectOption[]
  >([]);

  const [assignmentSelectOptions, setAssignmentSelectOptions] = useState<
    OrderDetailsManageDriverAssignmentSelectOption[]
  >([]);
  const [isDriversFetching, setIsDriversFetching] = useState(false);
  const [driverSearchQuery, setDriverSearchQuery] = useState("");
  const [taxiCorporationSelectOptions, setTaxiCorporationSelectOptions] =
    useState<OrderDetailsManageDriverAssignmentTaxiCorporationSelectOption[]>(
      []
    );
  const [formData, setFormData] =
    useState<OrderDetailsManageDriverAssignmentFormData>(() =>
      orderDetailsManageDriverAssignmentHelper.getDefaultFormData()
    );
  const [formValidationResults, setFormValidationResults] =
    useState<OrderDetailsManageDriverAssignmentFormValidationResults>(() =>
      orderDetailsManageDriverAssignmentHelper.getDefaultFormValidationResults()
    );

  const [candidatureAddRequest, setCandidatureAddRequest] =
    useState<DriverPlanCandidatureAddByOrderIdParams | null>(null);

  const { isOpen, open, close } = useOpen();

  const [isCandidatureAdding, setIsCandidatureAdding] = useState(false);

  const candidatureAddByOrderIdAbort = useAbort();

  const [minimumCollisionGap, setMinimumCollisionGap] = useState(0);

  const minimumCollisionTime = useMemo(() => {
    return new Date(
      props.cargoOrderStartingAddress.estimatedTime.getTime() +
        minimumCollisionGap * 1000
    );
  }, [props.cargoOrderStartingAddress.estimatedTime, minimumCollisionGap]);

  const onFetchDriversSuccess = (
    responseDrivers: OrderDetailsManageDriverAssignmentDriversResponseDriver[]
  ) => {
    const driverSelectOptions =
      orderDetailsManageDriverAssignmentFactory.createDriverSelectOptions(
        responseDrivers
      );

    setDriverSelectOptions(driverSelectOptions);
  };

  const clearFormData = () => {
    setFormData(orderDetailsManageDriverAssignmentHelper.getDefaultFormData());
  };
  const clearFormValidationResults = () => {
    setFormValidationResults(
      orderDetailsManageDriverAssignmentHelper.getDefaultFormValidationResults()
    );
  };

  useEffect(() => {
    if (props.isOpen) {
      return;
    }

    clearFormData();
    clearFormValidationResults();
    setDriverSelectOptions([]);
    setAssignmentSelectOptions([]);
    setTaxiCorporationSelectOptions([]);
  }, [props.isOpen]);

  useEffect(() => {
    if (formData.assignmentOption?.value !== null) setTaxiCorporation(null);
  }, [formData.assignmentOption]);

  const onFetchDriversFailure = () => {
    notificationService.error(
      translations.failureFetchingDriversNotificationMessageText
    );
  };

  const handleFetchDriversResponse = (
    response: OrderDetailsManageDriverAssignmentDriversResponse
  ) => {
    if (response.status === 200) {
      onFetchDriversSuccess(response.data);
      return;
    }

    onFetchDriversFailure();
  };

  useEffect(() => {
    if (driverSearchQuery) {
      return;
    }

    setDriverSelectOptions([]);
  }, [driverSearchQuery]);

  const fetchDrivers = (
    driverSearchQuery: string,
    transportingOrderUuid: string
  ) => {
    setIsDriversFetching(true);

    const request: OrderDetailsManageDriverAssignmentDriversRequest = {
      fulltext_search_criteria: driverSearchQuery,
    };

    orderDetailsManageDriverAssignmentApiService
      .fetchDrivers(request, transportingOrderUuid)
      .then(handleFetchDriversResponse)
      .catch(onFetchDriversFailure)
      .finally(() => {
        setIsDriversFetching(false);
      });
  };

  const debounceFetchDrivers = useCallback(
    debounce((addressSearchQuery: string, transportingOrderUuid: string) => {
      fetchDrivers(addressSearchQuery, transportingOrderUuid);
    }, 300),
    []
  );

  useEffect(() => {
    if (driverSearchQuery.length < 2) {
      return;
    }

    debounceFetchDrivers(driverSearchQuery, props.transportingOrderUuid);
  }, [driverSearchQuery]);

  const setDriver = (
    value: OrderDetailsManageDriverAssignmentFormData["driver"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      driver: value,
    }));
  };

  const setAssignmentOption = (
    value: OrderDetailsManageDriverAssignmentFormData["assignmentOption"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      assignmentOption: value,
    }));
  };

  const setTaxiCorporation = (
    value: OrderDetailsManageDriverAssignmentFormData["taxiCorporation"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      taxiCorporation: value,
    }));
  };

  const setAcceptImmediately = (
    value: OrderDetailsManageDriverAssignmentFormData["acceptImmediately"]
  ) => {
    setFormData((curr) => ({
      ...curr,
      acceptImmediately: value,
    }));
  };

  useEffect(() => {
    if (!formData.driver) {
      return;
    }

    const newAssignmentSelectOptions =
      orderDetailsManageDriverAssignmentFactory.createAssignmentSelectOptions(
        formData.driver.value.planEntries
      );

    setAssignmentSelectOptions(newAssignmentSelectOptions);

    const newTaxiCorporationSelectOptions =
      orderDetailsManageDriverAssignmentFactory.createTaxiCorporationSelectOptions(
        formData.driver.value.taxiCorporations
      );

    setTaxiCorporationSelectOptions(newTaxiCorporationSelectOptions);

    setAssignmentOption(null);
    setTaxiCorporation(null);
  }, [formData.driver?.value.uuid]);

  const currentAssignmentDriver = props.assignmentDrivers.length
    ? props.assignmentDrivers[0]
    : null;

  const showCurrentAssignmentDriver = !!currentAssignmentDriver;

  const validateDriver = (
    driver: OrderDetailsManageDriverAssignmentFormData["driver"] = formData.driver
  ) => {
    const validationResult =
      orderDetailsManageDriverAssignmentFormValidationService.validateDriver(
        driver
      );

    setFormValidationResults((curr) => ({
      ...curr,
      driver: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateAssignmentOption = (
    assignmentOption: OrderDetailsManageDriverAssignmentFormData["assignmentOption"] = formData.assignmentOption
  ) => {
    const validationResult =
      orderDetailsManageDriverAssignmentFormValidationService.validateAssignmentOption(
        assignmentOption
      );

    setFormValidationResults((curr) => ({
      ...curr,
      assignmentOption: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateTaxiCorporation = (
    taxiCorporation: OrderDetailsManageDriverAssignmentFormData["taxiCorporation"] = formData.taxiCorporation
  ) => {
    const validationResult =
      orderDetailsManageDriverAssignmentFormValidationService.validateTaxiCorporation(
        taxiCorporation,
        formData.assignmentOption
      );

    setFormValidationResults((curr) => ({
      ...curr,
      taxiCorporation: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateAcceptImmediately = (
    acceptImmediately: OrderDetailsManageDriverAssignmentFormData["acceptImmediately"] = formData.acceptImmediately
  ) => {
    const validationResult =
      orderDetailsManageDriverAssignmentFormValidationService.validateAcceptImmediately(
        acceptImmediately
      );

    return validationResult.isValid;
  };

  const checkIsFormValid = () => {
    const isDriverValid = validateDriver();
    const isAssignmentOptionValid = validateAssignmentOption();
    const isTaxiCorporationValid = validateTaxiCorporation();
    const isAcceptImmediately = validateAcceptImmediately();

    return (
      isDriverValid &&
      isAssignmentOptionValid &&
      isTaxiCorporationValid &&
      isAcceptImmediately
    );
  };

  const onCandidatureAddByOrderIdSuccess = () => {
    notificationService.success(
      translations.successAddCandidatureNotificationMessageText
    );

    props.onSuccess();
  };

  const onCandidatureAddByOrderIdFailure = async (
    error: DriverPlanCandidatureAddByOrderIdError
  ) => {
    if (!props.hasAccessToEdit) {
      notificationService.error(`${error.message}`);
      return;
    }

    const minimumGap = 300; // 5min = 5*60s

    if (
      (error.type ==
        DriverPlanCandidatureAddByOrderIdErrorType.CANDIDATE_FITS_WITH_NOT_ENOUGH_TIME ||
        error.type ==
          DriverPlanCandidatureAddByOrderIdErrorType.CANDIDATE_DOES_NOT_FIT) &&
      error.params.collisionDirection ==
        DriverPlanCollisionSubjectDirection.PREVIOUS
    ) {
      setMinimumCollisionGap(
        error.params.requiredGap - error.params.existingGap + minimumGap
      );
      notificationService.info(`${error.message}`);
      open();
    } else if (
      error.type ==
      DriverPlanCandidatureAddByOrderIdErrorType.PLAN_ENTRY_CANDIDATE_START_TIME_EXPIRED_ERROR
    ) {
      let diverStartingCoordinates: SearchRoadRoutingRequestWaypointCoordinate =
        {
          latitude: formData.driver?.value.startingAddress.lat!,
          longitude: formData.driver?.value.startingAddress.lon!,
        };
      let lastPlanEntryNodeTime = new Date();

      if (candidatureAddRequest && candidatureAddRequest.planEntryUuid) {
        const planEntryRequest: OrderDetailsManageDriverAssignmentPlanEntryRequest =
          {
            planEntryUuid: candidatureAddRequest.planEntryUuid,
          };

        const planEntryResponse =
          await orderDetailsManageDriverAssignmentApiService.fetchPlanEntry(
            planEntryRequest
          );

        if (planEntryResponse.status === 200) {
          diverStartingCoordinates = {
            latitude: planEntryResponse.data.end_node.lat,
            longitude: planEntryResponse.data.end_node.lon,
          };

          lastPlanEntryNodeTime =
            planEntryResponse.data.end_node.time ?? new Date();
        }
      }

      const roadRouteRequest: OrderDetailsManageDriverAssignmentRouteRequest = {
        waypointCoordinates: [
          diverStartingCoordinates,
          {
            latitude: props.cargoOrderStartingAddress.lat,
            longitude: props.cargoOrderStartingAddress.lon,
          },
        ],
        excludeHighway: true,
      };

      const roadRouteResponse =
        await orderDetailsManageDriverAssignmentApiService.fetchRoute(
          roadRouteRequest
        );

      setMinimumCollisionGap(
        (lastPlanEntryNodeTime.getTime() -
          props.cargoOrderStartingAddress.estimatedTime!.getTime()) /
          1000 +
          roadRouteResponse.routes[0].duration +
          minimumGap
      );
      open();
    } else {
      notificationService.error(`${error.message}`);
    }
  };

  const addCandidature = async () => {
    setIsCandidatureAdding(true);

    const request: DriverPlanCandidatureAddByOrderIdParams = {
      driverUuid: formData.driver?.value.uuid!,
      orderUuid: props.orderUuid,
      planEntryUuid: formData.assignmentOption?.value?.planEntry.uuid,
      taxiCorporationUuid: formData.taxiCorporation?.value.uuid,
      acceptImmediately: formData.acceptImmediately,
    };

    setCandidatureAddRequest(request);

    try {
      if (
        props.cargoOrderStartingAddress.estimatedTime &&
        props.cargoOrderStartingAddress.estimatedTime < new Date()
      ) {
        const error: DriverPlanPlanEntryCandidateStartTimeExpiredError = {
          params: {},
          message:
            translations.apiErrors.planEntryCandidateStartTimeExpiredError,
          type: DriverPlanCandidatureAddByOrderIdErrorType.PLAN_ENTRY_CANDIDATE_START_TIME_EXPIRED_ERROR,
        };
        throw error;
      } else {
        await driverPlanService.candidatureAddByOrderId(
          request,
          candidatureAddByOrderIdAbort.signal
        );

        onCandidatureAddByOrderIdSuccess();
      }
    } catch (error) {
      await onCandidatureAddByOrderIdFailure(
        error as DriverPlanCandidatureAddByOrderIdError
      );
    } finally {
      setIsCandidatureAdding(false);
    }
  };

  const submitForm = () => {
    const isFormValid = checkIsFormValid();

    if (!isFormValid) {
      return;
    }

    addCandidature();
  };

  const isDriverSelectEnabled = !isCandidatureAdding;
  const isAssignmentOptionSelectEnabled =
    !isCandidatureAdding && formData.driver;
  const isTaxiSelectEnabled =
    !isCandidatureAdding &&
    formData.driver &&
    formData.assignmentOption?.value === null;

  const onCandidatureCollisionModalCloseSuccess = () => {
    close();
    props.onSuccess();
  };

  const onCandidatureCollisionModalCloseCancel = () => {
    close();
  };

  return (
    <ModalComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      classNames={{
        root: `order_active_listing_manage_driver_assignment`,
        content: `order_active_listing_manage_driver_assignment_content`,
      }}
      header={{
        title: translations.headingTemplateText.replace(
          "#{cargoCompanyOrderId}",
          String(props.cargoCompanyOrderId)
        ),
      }}
      actions={[
        <ButtonComponent
          onClick={submitForm}
          type="primary"
          title={translations.form.submitButtonTitle}
          isLoading={isCandidatureAdding}
        >
          {translations.form.submitButtonText}
        </ButtonComponent>,
        <ButtonComponent
          type="brand"
          onClick={props.onClose}
          title={translations.form.rejectButtonTitle}
        >
          {translations.form.rejectButtonText}
        </ButtonComponent>,
      ]}
    >
      {showCurrentAssignmentDriver && (
        <FormFieldComponent
          label={translations.currentDriverLabel}
          classNames={{ root: "mt-0" }}
        >
          {translations.currentDriverText
            .replace(
              "#{driverDisplayName}",
              currentAssignmentDriver.displayName
            )
            .replace(
              "#{driverPhoneNumber}",
              currentAssignmentDriver.phoneNumber
            )}
        </FormFieldComponent>
      )}

      <FormFieldComponent
        label={translations.form.driverLabel}
        classNames={{ root: "mt-0" }}
        isRequired
        errorMessage={formValidationResults.driver.errorMessage}
      >
        <SingleSelectComponent
          placeholder={translations.form.driverPlaceholder}
          options={driverSelectOptions}
          value={formData.driver}
          isLoading={isDriversFetching}
          inputValue={driverSearchQuery}
          onInputChange={setDriverSearchQuery}
          onChange={(option) => {
            setDriver(option);
            validateDriver(option);
          }}
          isDisabled={!isDriverSelectEnabled}
          isSearchable
          isClearable
          menuPlacement="bottom"
          filterFunction={() => true}
          idForTesting="driver-select"
          hasError={!!formValidationResults.driver.errorMessage}
        />
      </FormFieldComponent>
      <FormFieldComponent
        label={translations.form.assignmentOptionLabel}
        classNames={{ root: "mt-0" }}
        isRequired
        errorMessage={formValidationResults.assignmentOption.errorMessage}
      >
        <SingleSelectComponent
          placeholder={translations.form.assignmentOptionPlaceholder}
          options={assignmentSelectOptions}
          value={formData.assignmentOption}
          onChange={(option) => {
            setAssignmentOption(option);
            validateAssignmentOption(option);
          }}
          isDisabled={!isAssignmentOptionSelectEnabled}
          menuPlacement="bottom"
          idForTesting="assignment-option-select"
          hasError={!!formValidationResults.assignmentOption.errorMessage}
          menuPosition="fixed"
        />
      </FormFieldComponent>
      <FormFieldComponent
        label={translations.form.taxiCorporationLabel}
        classNames={{ root: "mt-0" }}
        isRequired={!!isTaxiSelectEnabled}
        errorMessage={formValidationResults.taxiCorporation.errorMessage}
      >
        <SingleSelectComponent
          placeholder={translations.form.taxiCorporationPlaceholder}
          options={taxiCorporationSelectOptions}
          value={formData.taxiCorporation}
          onChange={(option) => {
            setTaxiCorporation(option);
            validateTaxiCorporation(option);
          }}
          menuPlacement="bottom"
          idForTesting="taxi-corporation-select"
          isDisabled={!isTaxiSelectEnabled}
          hasError={!!formValidationResults.taxiCorporation.errorMessage}
          menuPosition="fixed"
        />
      </FormFieldComponent>
      <FormFieldComponent
        label={translations.form.acceptImmediatelyLabel}
        classNames={{ root: "mt-0" }}
        errorMessage={formValidationResults.acceptImmediately.errorMessage}
      >
        <CheckboxComponent
          isChecked={formData.acceptImmediately}
          onChange={setAcceptImmediately}
        />
      </FormFieldComponent>
      {!!isOpen && candidatureAddRequest && (
        <OrderDetailsManageDriverAssignmentCollisionModal
          isOpen={isOpen}
          onCloseCancel={onCandidatureCollisionModalCloseCancel}
          onCloseSuccess={onCandidatureCollisionModalCloseSuccess}
          cargoCompanyOrderId={props.cargoCompanyOrderId}
          orderUuid={props.orderUuid}
          estimatedStartTime={props.cargoOrderStartingAddress.estimatedTime}
          minimumStartTime={minimumCollisionTime!}
          candidatureAddRequest={candidatureAddRequest}
        />
      )}
    </ModalComponent>
  );
};

export default OrderDetailsManageDriverAssignmentModal;
