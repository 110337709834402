import DispatcherListLoadParams, {
  DispatcherListLoadParamsOrder,
} from "../../../../../common/services/dispatcher/list/dispatcher-list-load-params";
import DispatcherListingFilter, {
  DispatcherListingCargoCompanyFilter,
  DispatcherListingDispatchFilter,
  DispatcherListingEmailFilter,
  DispatcherListingFirstNameFilter,
  DispatcherListingLastNameFilter,
  DispatcherListingStatusFilter,
  DispatcherListingUsernameFilter,
} from "./types/dispatcher-listing-filter";
import DispatcherListingFilterType from "./types/dispatcher-listing-filter-type";
import DispatcherListingSortKey from "./types/dispatcher-listing-sort-key";

const createOrder = (
  sortKey: DispatcherListingSortKey | null
): DispatcherListLoadParamsOrder | undefined => {
  const definition: Record<
    DispatcherListingSortKey,
    DispatcherListLoadParamsOrder
  > = {
    [DispatcherListingSortKey.FIRST_NAME_ASC]:
      DispatcherListLoadParamsOrder.FIRST_NAME_ASC,
    [DispatcherListingSortKey.FIRST_NAME_DESC]:
      DispatcherListLoadParamsOrder.FIRST_NAME_DESC,
    [DispatcherListingSortKey.LAST_NAME_ASC]:
      DispatcherListLoadParamsOrder.LAST_NAME_ASC,
    [DispatcherListingSortKey.LAST_NAME_DESC]:
      DispatcherListLoadParamsOrder.LAST_NAME_DESC,
    [DispatcherListingSortKey.USERNAME_ASC]:
      DispatcherListLoadParamsOrder.USERNAME_ASC,
    [DispatcherListingSortKey.USERNAME_DESC]:
      DispatcherListLoadParamsOrder.USERNAME_DESC,
  };

  return sortKey ? definition[sortKey] : undefined;
};

const create = (
  page: number,
  pageSize: number,
  filters: DispatcherListingFilter[],
  sortKey: DispatcherListingSortKey | null
): DispatcherListLoadParams => {
  const statusFilter = filters.find(
    (filter) => filter.type === DispatcherListingFilterType.ACTIVITY_STATUS
  )?.value as DispatcherListingStatusFilter["value"] | undefined;

  return {
    page,
    pageSize,
    firstName: filters.find(
      (filter) => filter.type === DispatcherListingFilterType.FIRST_NAME
    )?.value as DispatcherListingFirstNameFilter["value"] | undefined,
    lastName: filters.find(
      (filter) => filter.type === DispatcherListingFilterType.LAST_NAME
    )?.value as DispatcherListingLastNameFilter["value"] | undefined,
    email: filters.find(
      (filter) => filter.type === DispatcherListingFilterType.EMAIL
    )?.value as DispatcherListingEmailFilter["value"] | undefined,
    username: filters.find(
      (filter) => filter.type === DispatcherListingFilterType.USERNAME
    )?.value as DispatcherListingUsernameFilter["value"] | undefined,
    status: statusFilter,
    cargoCompany: filters.find(
      (filter) => filter.type === DispatcherListingFilterType.CARGO_COMPANY
    )?.value as DispatcherListingCargoCompanyFilter["value"] | undefined,
    dispatch: filters.find(
      (filter) => filter.type === DispatcherListingFilterType.DISPATCH
    )?.value as DispatcherListingDispatchFilter["value"] | undefined,
    order: createOrder(sortKey),
  };
};

const dispatcherListingLoadParamsFactory = {
  create,
};

export default dispatcherListingLoadParamsFactory;
