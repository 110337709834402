import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";
import RelatedTaxiRailyBillingsTableColumn from "./types/related-taxi-raily-billings-table-column";

const getTableColumns = (): RelatedTaxiRailyBillingsTableColumn[] => {
  const translations =
    billingsTranslationsHelper.getRailyTaxiAddBillingsTranslations()
      .relatedBillings.cargo_raily.table;

  const tableColumns: RelatedTaxiRailyBillingsTableColumn[] = [
    {
      header: translations.headers.dateLabel,
      title: translations.headers.dateTitle,
      accessor: "date",
      colSpan: 5,
    },
    {
      header: translations.headers.internalOrderIdLabel,
      title: translations.headers.internalOrderIdTitle,
      accessor: "internalOrderId",
      colSpan: 5,
    },
    {
      header: translations.headers.companyNameLabel,
      title: translations.headers.companyNameTitle,
      accessor: "companyName",
      colSpan: 10,
    },
    {
      header: translations.headers.modelLabel,
      title: translations.headers.modelTitle,
      accessor: "model",
      colSpan: 5,
    },
    {
      header: translations.headers.rateLabel,
      title: translations.headers.rateTitle,
      accessor: "rate",
      colSpan: 5,
    },
    {
      header: translations.headers.distanceLabel,
      title: translations.headers.distanceTitle,
      accessor: "distance",
      colSpan: 5,
    },
    {
      header: translations.headers.routeDistanceCostLabel,
      title: translations.headers.routeDistanceCostTitle,
      accessor: "routeDistanceCost",
      colSpan: 5,
    },
    {
      header: translations.headers.discountLabel,
      title: translations.headers.discountTitle,
      accessor: "discount",
      colSpan: 5,
    },
    {
      header: translations.headers.discountTotalLabel,
      title: translations.headers.discountTotalTitle,
      accessor: "discountTotal",
      colSpan: 5,
    },
    {
      header: translations.headers.haltingCostLabel,
      title: translations.headers.haltingCostTitle,
      accessor: "haltingCost",
      colSpan: 5,
    },
    {
      header: translations.headers.highwayCostLabel,
      title: translations.headers.highwayCostTitle,
      accessor: "highwayCost",
      colSpan: 5,
    },
    {
      header: translations.headers.totalLabel,
      title: translations.headers.totalTitle,
      accessor: "total",
      colSpan: 5,
    },
    {
      header: translations.headers.actions,
      title: translations.headers.actions,
      accessor: "actions",
      colSpan: 5,
    },
  ];

  return tableColumns;
};

const relatedTaxiRailyBillingsHelper = {
  getTableColumns,
};

export default relatedTaxiRailyBillingsHelper;
