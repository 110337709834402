import DriverEditFormRequest from "../../../api/raily/driver/edit-form/driver-edit-form.request";
import DriverEditFormLoadParams from "./driver-edit-form-load-params";

const create = (params: DriverEditFormLoadParams): DriverEditFormRequest => {
  return {
    id: params.id,
  };
};

const driverEditFormRequestFactory = {
  create,
};

export default driverEditFormRequestFactory;
