import CargoTaxiContractUpdateRequest, {
  CargoTaxiContractUpdateRequestBody,
} from "./cargo-taxi-contract-update.request";

const createBody = (
  request: CargoTaxiContractUpdateRequest
): CargoTaxiContractUpdateRequestBody => {
  return {
    disabled: request.disabled,
    contactPassengerAfterMinutes: request.contactPassengerAfterMinutes,
    cancelByTaxiAllowed: request.cancelOrderByTaxiAllowed,
    editByTaxiAllowed: request.editOrderByTaxiAllowed,
  };
};

const cargoTaxiContractUpdateRequestFactory = {
  createBody,
};

export default cargoTaxiContractUpdateRequestFactory;
