import CargoOrderFinishedListReportRequest, {
  CargoOrderFinishedListReportRequestOrder,
  CargoOrderFinishedListReportRequestPublicStatus,
} from "../../../../api/raily/cargo-order/finished-list/report/cargo-order-finished-list-report-request";
import CargoOrderFinishedListReportLoadParams, {
  CargoOrderFinishedListReportLoadParamsExecutionStatus,
  CargoOrderFinishedListReportLoadParamsPublicStatus,
  CargoOrderFinishedListReportLoadParamsSortKey,
} from "./cargo-order-finished-list-report-load-params";

const createPublicStatus = (
  status: CargoOrderFinishedListReportLoadParamsPublicStatus | undefined
): CargoOrderFinishedListReportRequestPublicStatus | undefined => {
  switch (status) {
    case CargoOrderFinishedListReportLoadParamsPublicStatus.PRIVATE:
      return CargoOrderFinishedListReportRequestPublicStatus.PRIVATE;
    case CargoOrderFinishedListReportLoadParamsPublicStatus.PUBLIC:
      return CargoOrderFinishedListReportRequestPublicStatus.PUBLIC;
    case CargoOrderFinishedListReportLoadParamsPublicStatus.PUBLISHED:
      return CargoOrderFinishedListReportRequestPublicStatus.PUBLISHED;
    default:
      return undefined;
  }
};

const createOrder = (
  order: CargoOrderFinishedListReportLoadParamsSortKey | undefined
): CargoOrderFinishedListReportRequestOrder | undefined => {
  switch (order) {
    case CargoOrderFinishedListReportLoadParamsSortKey.ORDER_START_DATE_ASC:
      return CargoOrderFinishedListReportRequestOrder.START_TIME_ASC;
    case CargoOrderFinishedListReportLoadParamsSortKey.ORDER_START_DATE_DESC:
      return CargoOrderFinishedListReportRequestOrder.START_TIME_DESC;
    case CargoOrderFinishedListReportLoadParamsSortKey.INTERNAL_ORDER_ID_ASC:
      return CargoOrderFinishedListReportRequestOrder.HUMAN_ID_ASC;
    case CargoOrderFinishedListReportLoadParamsSortKey.INTERNAL_ORDER_ID_DESC:
      return CargoOrderFinishedListReportRequestOrder.HUMAN_ID_DESC;
    default:
      return undefined;
  }
};

const create = (
  params: CargoOrderFinishedListReportLoadParams
): CargoOrderFinishedListReportRequest => {
  return {
    internalOrderId: params.internalOrderId ? [params.internalOrderId] : [],
    address: params.address ? [params.address] : [],
    client: params.client ? [params.client] : [],
    taxi: params.taxi ? [params.taxi] : [],
    dispatch: params.dispatch ? [params.dispatch] : [],
    driver: params.driver ? [params.driver] : [],
    startAddress: params.pickupAddress ? [params.pickupAddress] : undefined,
    endAddress: params.destinationAddress ? [params.destinationAddress] : [],
    midAddress: params.intermediateAddress ? [params.intermediateAddress] : [],
    externalId: params.externalOrderId ? [params.externalOrderId] : [],
    passenger: params.passenger ? [params.passenger] : [],
    order: createOrder(params.sortKey),
    publicStatus: createPublicStatus(params.publicStatus),
    isApproved: params.isUrgent,
    isCancelled: params.executionStatus
      ? params.executionStatus ===
        CargoOrderFinishedListReportLoadParamsExecutionStatus.CANCELLED
      : undefined,
    startTimeSince: params.orderStartDateFrom,
    startTimeTo: params.orderStartDateTo,
  };
};

const cargoOrderFinishedListReportRequestFactory = {
  create,
};

export default cargoOrderFinishedListReportRequestFactory;
