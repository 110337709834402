import { FormValidationDefinition } from "../../../../../../common/components/form/use-form";
import driverAddAccountFormValidationService from "./driver-add-account-data-form-validation.service";
import DriverAddAccountFormData from "./types/driver-add-account-form-data";

const getDefaultAccountFormData = (): DriverAddAccountFormData => {
  return {
    email: "",
    password: "",
    passwordRepeat: "",
    username: "",
  };
};

const getAccountValidationDefinition =
  (): FormValidationDefinition<DriverAddAccountFormData> => {
    return {
      email: (values) =>
        driverAddAccountFormValidationService.validateEmail(values.email),
      password: (values) =>
        driverAddAccountFormValidationService.validatePassword(values.password),
      passwordRepeat: (values) =>
        driverAddAccountFormValidationService.validatePasswordRepeat(
          values.passwordRepeat,
          values.password
        ),
      username: (values) =>
        driverAddAccountFormValidationService.validateUsername(values.username),
    };
  };

const driverAddAccountFormHelper = {
  getDefaultAccountFormData,
  getAccountValidationDefinition,
};

export default driverAddAccountFormHelper;
