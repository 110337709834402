import CargoOrderActiveListResponse, {
  CargoOrderActiveListResponseItemPublicStatus,
  CargoOrderActiveListResponseItemStatus,
  CargoOrderActiveListResponseItem,
} from "../../../api/raily/cargo-order/active-list/cargo-order-active-list.response";
import CargoOrderActiveList, {
  CargoOrderActiveListItem,
  CargoOrderActiveListItemExecutionStatus,
  CargoOrderActiveListItemPublicStatus,
} from "./cargo-order-active-list";

const createPublicStatus = (
  responsePublicStatus: CargoOrderActiveListResponseItemPublicStatus
): CargoOrderActiveListItemPublicStatus => {
  switch (responsePublicStatus) {
    case CargoOrderActiveListResponseItemPublicStatus.PRIVATE:
      return CargoOrderActiveListItemPublicStatus.PRIVATE;
    case CargoOrderActiveListResponseItemPublicStatus.PUBLIC:
      return CargoOrderActiveListItemPublicStatus.PUBLIC;
    case CargoOrderActiveListResponseItemPublicStatus.PUBLISHED:
      return CargoOrderActiveListItemPublicStatus.PUBLISHED;
  }
};

const createExecutionStatus = (
  responseStatus: CargoOrderActiveListResponseItemStatus
): CargoOrderActiveListItemExecutionStatus => {
  switch (responseStatus) {
    case CargoOrderActiveListResponseItemStatus.CANDIDATURE:
      return CargoOrderActiveListItemExecutionStatus.CANDIDATURE;
    case CargoOrderActiveListResponseItemStatus.IN_PROGRESS:
      return CargoOrderActiveListItemExecutionStatus.IN_PROGRESS;
    case CargoOrderActiveListResponseItemStatus.PENDING:
      return CargoOrderActiveListItemExecutionStatus.PENDING;
    case CargoOrderActiveListResponseItemStatus.PLANNED:
      return CargoOrderActiveListItemExecutionStatus.PLANNED;
  }
};

const createDataItem = (
  responseItem: CargoOrderActiveListResponseItem
): CargoOrderActiveListItem => {
  return {
    uuid: responseItem.id,
    client: responseItem.client.id
      ? {
          id: responseItem.client.id,
          name: responseItem.client.name,
        }
      : null,
    consumer: responseItem.consumer?.id
      ? {
          id: responseItem.consumer.id,
          name: responseItem.consumer.name,
        }
      : null,
    dispatch: responseItem.dispatch,
    drivers: responseItem.drivers,
    externalOrderId: responseItem.externalId,
    internalOrderId: responseItem.humanId,
    isCancelRequested: responseItem.isCancelRequested,
    isMobileAppUser: responseItem.push,
    passengers: responseItem.passengers,
    producer: responseItem.producer.id
      ? {
          id: responseItem.producer.id,
          name: responseItem.producer.name,
        }
      : null,
    publicStatus: createPublicStatus(responseItem.publicStatus),
    routeAddresses: responseItem.rideAddresses,
    estimatedStartDate: responseItem.startTime,
    executionStatus: createExecutionStatus(responseItem.status),
    taxiCorporations: responseItem.taxiCorporations,
    attentions: {
      newSharedPost: responseItem.attentions?.newSharedPost,
      newPlanEntryPost: responseItem.attentions?.newPlanEntryPost,
      cargoOrderEdit: responseItem.attentions?.cargoOrderEdit,
      cargoOrderCancel: responseItem.attentions?.cargoOrderCancel,
    },
    slkStatus: responseItem.slkStatus ?? null,
  };
};

const createData = (
  responseData: CargoOrderActiveListResponseItem[]
): CargoOrderActiveListItem[] => {
  return responseData.map(createDataItem);
};

const create = (
  response: CargoOrderActiveListResponse
): CargoOrderActiveList => {
  return {
    data: createData(response.data),
  };
};

const cargoOrderActiveListFactory = {
  create,
};

export default cargoOrderActiveListFactory;
