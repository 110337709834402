import OrderOptimizerListingRouteQueryParams from "../../common/routes/types/order-optimizer-listing-route-params";
import OrderOptimizerListingFilter, {
  OrderOptimizerListingOrderHumanIdFilter,
} from "../common/types/order-optimizer-listing-filter";
import OrderOptimizerListingFilterType from "../common/types/order-optimizer-listing-filter-type";

const getPage = (
  routeQueryFilterParams: OrderOptimizerListingRouteQueryParams
): number | undefined => {
  return routeQueryFilterParams.page
    ? Number(routeQueryFilterParams.page)
    : undefined;
};

const getPageSize = (
  routeQueryFilterParams: OrderOptimizerListingRouteQueryParams
): number | undefined => {
  return routeQueryFilterParams.pageSize
    ? Number(routeQueryFilterParams.pageSize)
    : undefined;
};

const getFilters = (
  routeQueryFilterParams: OrderOptimizerListingRouteQueryParams
): OrderOptimizerListingFilter[] => {
  const filters: OrderOptimizerListingFilter[] = [];

  if (routeQueryFilterParams.orderHumanId) {
    const orderHumanIdFilter: OrderOptimizerListingOrderHumanIdFilter = {
      type: OrderOptimizerListingFilterType.ORDER_HUMAN_ID,
      value: Number(routeQueryFilterParams.orderHumanId),
    };

    filters.push(orderHumanIdFilter);
  }

  return filters;
};

const createRouteQueryParams = (
  filters: OrderOptimizerListingFilter[],
  page: number,
  pageSize: number
): OrderOptimizerListingRouteQueryParams => {
  const orderHumanId = filters.find(
    (filter) => filter.type === OrderOptimizerListingFilterType.ORDER_HUMAN_ID
  )?.value as OrderOptimizerListingOrderHumanIdFilter["value"] | undefined;

  const routeQueryParams: OrderOptimizerListingRouteQueryParams = {
    page: page ? page.toString() : undefined,
    pageSize: pageSize ? pageSize.toString() : undefined,
    orderHumanId: orderHumanId ? orderHumanId.toString() : undefined,
  };

  return routeQueryParams;
};

const orderOptimizerListingFiltersService = {
  getPage,
  getPageSize,
  getFilters,
  createRouteQueryParams,
};

export default orderOptimizerListingFiltersService;
