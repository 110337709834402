import { OrderAddCargoAddressesResponseDataAddress } from "../api/order-add-cargo-addresses.response";
import { OrderAddCargoCompaniesResponseCompany } from "../api/order-add-cargo-companies.response";
import { OrderAddDispatchersResponseItem } from "../api/order-add-dispatchers.response";
import {
  OrderAddPassengersResponseItem,
  OrderAddPassengersResponseItemAddress,
} from "../api/order-add-passengers.response";
import OrderAddSolvedRidesRequest from "../api/order-add-solved-rides.request";
import OrderAddRequestBody, {
  OrderAddRequestCargoOrderRide,
  OrderAddRequestRideSeqItem,
  OrderAddRequestRideWaypoint,
} from "../api/order-add.request";
import orderAddHelper from "../helper/order-add.helper";
import OrderAddCargoAddress from "../../../add-new/common/types/order-add-cargo-address";
import OrderAddCargoCompany from "../types/order-add-cargo-company";
import OrderAddCargoCompanySelectOption from "../types/order-add-cargo-company-select-option";
import OrderAddDispatcher from "../types/order-add-dispatcher";
import OrderAddDispatcherSelectOption from "../types/order-add-dispatcher-select-option";
import OrderAddPassenger, {
  OrderAddPassengerAddress,
} from "../types/order-add-passenger";
import OrderAddPassengerSelectOption from "../types/order-add-passenger-select-option";
import OrderAddRouteAddress from "../types/order-add-route-address";
import OrderAddRouteAddressSelectOption from "../types/order-add-route-address-select-option";
import OrderAddRouteItem, {
  OrderAddRouteWaypoint,
  OrderAddRouteWaypointGroupWaypoint,
} from "../types/order-add-route-waypoint";
import OrderAddTaxiCorporation from "../types/order-add-taxi-corporation";
import OrderAddTaxiCorporationSelectOption from "../types/order-add-taxi-corporation-select-option";
import {
  ContractValidCollectionResponseData,
  ContractValidCollectionResponseRailyContract,
  ContractValidCollectionResponseTaxiContract,
} from "../../../../../common/api/raily/contracts/valid-collection/contract-valid-collection.response";

const createCargoCompany = (
  responseCargoCompany: OrderAddCargoCompaniesResponseCompany
): OrderAddCargoCompany => {
  return {
    uuid: responseCargoCompany.id,
    displayName: responseCargoCompany.display_name,
    name: responseCargoCompany.company_name,
    headquarterAddressDisplayName:
      responseCargoCompany.headquarters_address_string,
  };
};

const createCargoCompanies = (
  responseCargoCompanies: OrderAddCargoCompaniesResponseCompany[]
): OrderAddCargoCompany[] => {
  return responseCargoCompanies.map(createCargoCompany);
};

const createCargoCompanySelectOption = (
  cargoCompany: OrderAddCargoCompany
): OrderAddCargoCompanySelectOption => {
  return {
    label: cargoCompany.displayName,
    value: cargoCompany,
    idForTesting: `cargo-company-uuid-${cargoCompany.uuid}`,
  };
};

const createCargoCompaniesSelectOptions = (
  responseCargoCompanies: OrderAddCargoCompaniesResponseCompany[]
): OrderAddCargoCompanySelectOption[] => {
  const cargoCompanies = createCargoCompanies(responseCargoCompanies);

  return cargoCompanies.map(createCargoCompanySelectOption);
};

const createTaxiCorporation = (
  responseTaxiCorporation: ContractValidCollectionResponseTaxiContract
): OrderAddTaxiCorporation => {
  return {
    uuid: responseTaxiCorporation.taxiCorporation.id,
    displayName: responseTaxiCorporation.taxiCorporation.displayName,
    contractPermitsTollRoads: responseTaxiCorporation.allowChargeDuringRide,
    billingModel: responseTaxiCorporation.billingModel,
    contractUuid: responseTaxiCorporation.id,
  };
};

const createTaxiCorporations = (
  responseTaxiCorporations: ContractValidCollectionResponseTaxiContract[]
): OrderAddTaxiCorporation[] => {
  return responseTaxiCorporations.map(createTaxiCorporation);
};

const createTaxiCorporationSelectOption = (
  taxiCorporation: OrderAddTaxiCorporation
): OrderAddTaxiCorporationSelectOption => {
  return {
    label: `${taxiCorporation.displayName} - ${taxiCorporation.billingModel}`,
    value: taxiCorporation,
    idForTesting: `taxi-uuid-${taxiCorporation.uuid}`,
  };
};

const createRailyTaxiCorporation = (
  railyContract: ContractValidCollectionResponseRailyContract
): OrderAddTaxiCorporation => {
  return {
    uuid: railyContract.cargoCompanyId,
    displayName: "Raily",
    contractPermitsTollRoads: railyContract.allowChargeDuringRide,
    billingModel: undefined,
    contractUuid: railyContract.id,
  };
};

const createTaxiCorporationsSelectOptions = (
  taxiContractsResponseData: ContractValidCollectionResponseData
): OrderAddTaxiCorporationSelectOption[] => {
  const allTaxiCorporations: OrderAddTaxiCorporation[] = [];

  if (taxiContractsResponseData.railyContract) {
    const railyTaxiCorporation = createRailyTaxiCorporation(
      taxiContractsResponseData.railyContract
    );
    allTaxiCorporations.push(railyTaxiCorporation);
  }

  const taxiCorporations = createTaxiCorporations(
    taxiContractsResponseData.taxiContracts
  );

  allTaxiCorporations.push(...taxiCorporations);

  return allTaxiCorporations.map(createTaxiCorporationSelectOption);
};

const createDispatcher = (
  responseDispatcher: OrderAddDispatchersResponseItem
): OrderAddDispatcher => {
  return {
    uuid: responseDispatcher.user.id,
    firstName: responseDispatcher.user.first_name,
    lastName: responseDispatcher.user.last_name,
    username: responseDispatcher.user.username,
  };
};

const createDispatchers = (
  responseDispatchers: OrderAddDispatchersResponseItem[]
): OrderAddDispatcher[] => {
  return responseDispatchers.map(createDispatcher);
};

const createDispatcherSelectOption = (
  dispatcher: OrderAddDispatcher
): OrderAddDispatcherSelectOption => {
  return {
    label: `${dispatcher.firstName} ${dispatcher.lastName}`,
    value: dispatcher,
    idForTesting: `dispatcher-uuid-${dispatcher.uuid}`,
  };
};

const createDispatcherSelectOptions = (
  responseDispatchers: OrderAddDispatchersResponseItem[]
): OrderAddDispatcherSelectOption[] => {
  const dispatchers = createDispatchers(responseDispatchers);

  return dispatchers.map(createDispatcherSelectOption);
};

const createPassengerAddress = (
  responsePassengerAddress: OrderAddPassengersResponseItemAddress
): OrderAddPassengerAddress => {
  return {
    uuid: responsePassengerAddress.id,
    displayName: responsePassengerAddress.display_name,
    apartmentNumber: responsePassengerAddress.apartment,
    houseNumber: responsePassengerAddress.house_no,
    street: responsePassengerAddress.street,
    town: responsePassengerAddress.town,
    zipCode: responsePassengerAddress.zip_code,
    latitude: responsePassengerAddress.lat,
    longitude: responsePassengerAddress.lon,
  };
};

const createPassengerAddresses = (
  responsePassengerAddresses: OrderAddPassengersResponseItemAddress[]
): OrderAddPassengerAddress[] => {
  return responsePassengerAddresses.map(createPassengerAddress);
};

const createPassenger = (
  responseItem: OrderAddPassengersResponseItem
): OrderAddPassenger => {
  return {
    uuid: responseItem.user.id,
    firstName: responseItem.user.first_name,
    lastName: responseItem.user.last_name,
    username: responseItem.user.username,
    addresses: createPassengerAddresses(responseItem.addresses),
  };
};

const createPassengers = (
  responsePassengers: OrderAddPassengersResponseItem[]
): OrderAddPassenger[] => {
  return responsePassengers.map(createPassenger);
};

const createPassengerSelectOption = (
  passenger: OrderAddPassenger
): OrderAddPassengerSelectOption => {
  return {
    label: `${passenger.firstName} ${passenger.lastName}`,
    value: passenger,
    idForTesting: `passenger-uuid-${passenger.uuid}`,
  };
};

const createPassengerSelectOptions = (
  responsePassengers: OrderAddPassengersResponseItem[]
): OrderAddPassengerSelectOption[] => {
  const passengers = createPassengers(responsePassengers);

  return passengers.map(createPassengerSelectOption);
};

const createCargoAddress = (
  responseCargoAddress: OrderAddCargoAddressesResponseDataAddress
): OrderAddCargoAddress => {
  return {
    uuid: responseCargoAddress.id,
    displayName: responseCargoAddress.display_name,
    apartmentNumber: responseCargoAddress.apartment,
    houseNumber: responseCargoAddress.house_no,
    street: responseCargoAddress.street,
    town: responseCargoAddress.town,
    zipCode: responseCargoAddress.zip_code,
    latitude: responseCargoAddress.lat,
    longitude: responseCargoAddress.lon,
    type: responseCargoAddress.type,
    description: responseCargoAddress.description,
  };
};

const createCargoAddresses = (
  responseCargoAddresses: OrderAddCargoAddressesResponseDataAddress[]
): OrderAddCargoAddress[] => {
  return responseCargoAddresses.map(createCargoAddress);
};

const createRouteAddressSelectOption = (
  routeAddress: OrderAddRouteAddress
): OrderAddRouteAddressSelectOption => {
  return {
    label: routeAddress.subText || routeAddress.displayName,
    value: routeAddress,
    idForTesting: `route-address-uuid-${routeAddress.uuid}`,
  };
};

const createRouteAddressesSelectOptions = (
  routeAddresses: OrderAddRouteAddress[]
): OrderAddRouteAddressSelectOption[] => {
  return routeAddresses.map(createRouteAddressSelectOption);
};

const createRailyRideWaypointFromRouteWaypoint = (
  routeWaypoint: OrderAddRouteWaypoint
) => {
  const newWaypoint: OrderAddRequestRideWaypoint = {
    lat: routeWaypoint.address!.latitude,
    lon: routeWaypoint.address!.longitude,
    display_name: routeWaypoint.address!.displayName,
    halting_time: routeWaypoint.haltingTimeMinutes
      ? routeWaypoint.haltingTimeMinutes * 60
      : 0,
    meta: {
      id: routeWaypoint.uuid,
      cargo_enter: {
        subjects: routeWaypoint.onboardingPassengerListItems.map(
          (onboardingPassengerListItem) => {
            const passengerUuid = onboardingPassengerListItem.passenger.uuid;

            return {
              id: passengerUuid,
            };
          }
        ),
      },
      cargo_exit: {
        subjects: routeWaypoint.outboardingPassengerListItems.map(
          (outboardingPassengerListItem) => {
            const passengerUuid = outboardingPassengerListItem.passenger.uuid;

            return {
              id: passengerUuid,
            };
          }
        ),
      },
    },
    time: routeWaypoint.date ? routeWaypoint.date?.toJSON() : null,
  };

  return newWaypoint;
};

const createRailyRideWaypointFromRouteGroupWaypoint = (
  routeGroupWaypoint: OrderAddRouteWaypointGroupWaypoint
) => {
  const newWaypoint: OrderAddRequestRideWaypoint = {
    lat: routeGroupWaypoint.address!.latitude,
    lon: routeGroupWaypoint.address!.longitude,
    display_name: routeGroupWaypoint.address!.displayName,
    halting_time: routeGroupWaypoint.haltingTimeMinutes
      ? routeGroupWaypoint.haltingTimeMinutes * 60
      : 0,
    meta: {
      id: routeGroupWaypoint.uuid,
      cargo_enter: {
        subjects: routeGroupWaypoint.onboardingPassengerListItems.map(
          (onboardingPassengerListItem) => {
            return {
              id: onboardingPassengerListItem.passenger.uuid,
            };
          }
        ),
      },
      cargo_exit: {
        subjects: routeGroupWaypoint.outboardingPassengerListItems.map(
          (outboardingPassengerListItem) => {
            return {
              id: outboardingPassengerListItem.passenger.uuid,
            };
          }
        ),
      },
    },
    time: null,
  };

  return newWaypoint;
};

const createRailyRide = (
  routeItems: OrderAddRouteItem[]
): OrderAddRequestBody["raily_ride"] => {
  const mainSequence: OrderAddRequestRideSeqItem[] = [];

  routeItems.forEach((routeItem) => {
    const isWaypoint = orderAddHelper.checkIsRouteItemAWaypoint(routeItem);

    if (isWaypoint) {
      const newWaypoint: OrderAddRequestRideWaypoint =
        createRailyRideWaypointFromRouteWaypoint(routeItem);

      mainSequence.push(newWaypoint);

      return;
    }

    const sequence: OrderAddRequestRideSeqItem[] = [];

    routeItem.waypoints.forEach((waypoint) => {
      const newWaypoint: OrderAddRequestRideWaypoint =
        createRailyRideWaypointFromRouteGroupWaypoint(waypoint);

      sequence.push(newWaypoint);
    });

    const newCargoOrderRide: OrderAddRequestCargoOrderRide = {
      sequence_order: "ANY",
      seq: sequence,
    };

    mainSequence.push(newCargoOrderRide);
  });

  return {
    seq: mainSequence,
    sequence_order: "STRICT",
  };
};

const createAddRequest = (
  orderNumber: string,
  selectedTaxiCorporation: OrderAddTaxiCorporation,
  routeItems: OrderAddRouteItem[]
): OrderAddRequestBody => {
  const railyRide = createRailyRide(routeItems);
  const targetTaxiId =
    selectedTaxiCorporation!.displayName === "Raily"
      ? null
      : selectedTaxiCorporation!.uuid;

  return {
    external_id: orderNumber || null,
    target_taxi_id: targetTaxiId,
    raily_ride: railyRide,
    cargo_taxi_contract_id: selectedTaxiCorporation.contractUuid || null,
  };
};

const createSolvedRidesRequest = (
  selectedTaxiCorporation: OrderAddTaxiCorporation,
  routeItems: OrderAddRouteItem[]
): OrderAddSolvedRidesRequest => {
  const railyRide = createRailyRide(routeItems);

  const nodeUuids = routeItems
    .map((routeItem) => {
      if (orderAddHelper.checkIsRouteItemAWaypoint(routeItem)) {
        return routeItem.uuid;
      }

      return routeItem.waypoints.map((waypoint) => waypoint.uuid);
    })
    .flat();

  return {
    raily_ride: railyRide,
    node_exclusion_collection: {
      exclusions: [
        {
          exclude_approaching: false,
          exclude_inside: !selectedTaxiCorporation!.contractPermitsTollRoads,
          exclude_returning: false,
          node_ids: nodeUuids,
        },
      ],
    },
  };
};

const orderAddFactory = {
  createCargoCompaniesSelectOptions,
  createTaxiCorporationsSelectOptions,
  createDispatcherSelectOptions,
  createPassengerSelectOptions,
  createCargoAddresses,
  createRouteAddressSelectOption,
  createRouteAddressesSelectOptions,
  createAddRequest,
  createSolvedRidesRequest,
};

export default orderAddFactory;
