import { FC, useCallback, useEffect, useMemo, useState } from "react";
import MileageDictionaryListingCompanySelectOption from "../../types/mileage-dictionary-listing-company-select-options";
import useCargoCompanyList from "../../../../../../../common/services/cargo-company/cargo-company/list/use-cargo-company-list";
import useAbort from "../../../../../../../common/hooks/use-abort";
import { debounce } from "lodash";
import CargoCompanyListLoadParams from "../../../../../../../common/services/cargo-company/cargo-company/list/cargo-company-list-load-params";
import mileageDictionaryListingCompanyLoadParamsFactory from "../../factory/mileage-dictionary-listing-company-load-params.factory";
import mileageDictionaryListingCompanyFactory from "../../factory/mileage-dictionary-listing-company.factory";
import MileageDictionaryListingCompany from "../../types/mileage-dictionary-listing-company";
import SingleSelectComponent from "../../../../../../../common/components/form/select/single-select/single-select.component";
import mileageTranslationsHelper from "../../../../../../../languages/mileage-translations.helper";

type MileageDictionaryListingCompanyProps = {
  company: MileageDictionaryListingCompany | null;
  onCompanyChange: (company: MileageDictionaryListingCompany | null) => void;
  onBlur?: () => void;
  idForTesting?: string;
};

const MileageDictionaryListingCompanyComponent: FC<
  MileageDictionaryListingCompanyProps
> = (props) => {
  const translations =
    mileageTranslationsHelper.getMileageDictionaryTranslations().company;

  const [companyDisplayNameQuery, setCompanyDisplayNameQuery] = useState<
    string | undefined
  >();

  const cargoCompanyList = useCargoCompanyList();
  const cargoCompanyListAbort = useAbort();

  const fetchCargoCompanyDebounced = useCallback(
    debounce((displayName: string | undefined) => {
      const loadParams: CargoCompanyListLoadParams =
        mileageDictionaryListingCompanyLoadParamsFactory.createCargoCompanyLoadParams(
          displayName
        );

      return cargoCompanyList.load(loadParams, cargoCompanyListAbort.signal);
    }, 500),
    []
  );

  useEffect(() => {
    fetchCargoCompanyDebounced(companyDisplayNameQuery);

    return () => {
      fetchCargoCompanyDebounced.cancel();
    };
  }, [companyDisplayNameQuery, fetchCargoCompanyDebounced]);

  const companySelectOptions = useMemo(() => {
    const selectOptions: MileageDictionaryListingCompanySelectOption[] =
      mileageDictionaryListingCompanyFactory.createCompanySelectOptions(
        cargoCompanyList.data.data
      );

    return selectOptions;
  }, [cargoCompanyList.data]);

  const companySelectOption = useMemo(() => {
    if (!props.company) {
      return null;
    }

    return mileageDictionaryListingCompanyFactory.createCompanySelectOption(
      props.company
    );
  }, [props.company]);

  const isCompanyListLoading =
    !!companyDisplayNameQuery && cargoCompanyList.isLoading;

  return (
    <div className="m-0">
      <SingleSelectComponent
        placeholder={translations.selectPlaceholder}
        value={companySelectOption}
        onChange={(
          value: MileageDictionaryListingCompanySelectOption | null
        ) => {
          if (!value) {
            return;
          }

          props.onCompanyChange(value.value);
        }}
        idForTesting={props.idForTesting}
        isLoading={isCompanyListLoading}
        isDisabled={cargoCompanyList.isError}
        options={companySelectOptions}
        isSearchable
        filterFunction={() => true}
        inputValue={companyDisplayNameQuery}
        onInputChange={setCompanyDisplayNameQuery}
        noOptionsMessage={(inputValue) => {
          if (inputValue) {
            return translations.searchNoOptionsMessage;
          }
          return translations.searchTipMessage;
        }}
        onBlur={props.onBlur}
      />
    </div>
  );
};

export default MileageDictionaryListingCompanyComponent;
