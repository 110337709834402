import { Marker, Tooltip, useMap } from "react-leaflet";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import MapMarker from "../types/map-marker";
import { LatLngExpression, Marker as LeafletMarker } from "leaflet";
import MapCoordinate from "../types/map-coordinate";
import mapMarkerIconFactory from "./map-marker-icon.factory";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";

export type MapMarkerProps = MapMarker & {
  onMarkerHover?: (marker: MapMarker) => void;
  isSelected?: boolean;
  sliderActive?: boolean;
};

const MapMarkerComponent: FC<MapMarkerProps> = (props) => {
  const markerRef = useRef<LeafletMarker>(null);
  const translations = orderTranslationsHelper.getDetailsTranslations();

  const position: LatLngExpression = {
    lat: props.coordinate.latitude,
    lng: props.coordinate.longitude,
  };

  const tooltipPermanent = props.sliderActive && props.isSelected;

  const tooltipContent = useMemo(() => {
    if (!props.title) return "";
    return /^\d{2}:\d{2}$/.test(props.title)
      ? `${translations.timeLabel}: ${props.title}`
      : props.title;
  }, [props.title, translations]);

  const [isTooltipReady, setIsTooltipReady] = useState(false);

  useEffect(() => {
    if (tooltipContent !== "") {
      setIsTooltipReady(true);
    }
  }, [tooltipContent]);

  useEffect(() => {
    if (markerRef.current && isTooltipReady) {
      if (tooltipPermanent) {
        markerRef.current.openTooltip();
      } else {
        markerRef.current.closeTooltip();
      }
    }
  }, [tooltipPermanent, isTooltipReady, tooltipContent]);

  useEffect(() => {
    if (!markerRef.current || !props.isDraggable) return;

    const handleDragEnd = () => {
      if (!markerRef.current || !props.onCoordinateChange) return;
      const newPosition = markerRef.current.getLatLng();
      const coordinate: MapCoordinate = {
        latitude: newPosition.lat,
        longitude: newPosition.lng,
      };
      props.onCoordinateChange(coordinate);
    };

    markerRef.current.on("dragend", handleDragEnd);
    return () => {
      markerRef.current?.off("dragend", handleDragEnd);
    };
  }, [markerRef.current]);

  useEffect(() => {
    if (markerRef.current) {
      const markerElement = markerRef.current.getElement();
      if (markerElement) {
        markerElement.style.pointerEvents =
          props.sliderActive && props.isSelected ? "none" : "auto";
      }
    }
  }, [props.sliderActive, props.isSelected]);

  const markerIcon =
    props.icon ||
    mapMarkerIconFactory.createStandardIcon({
      content: "",
    });

  const markerZIndex = props.isRouteMarker
    ? 1000
    : props.isGpsRouteMarker
    ? 500
    : 0;

  return (
    <Marker
      ref={markerRef}
      position={position}
      icon={
        props.isSelected && props.sliderActive
          ? mapMarkerIconFactory.createCurrentGpsIcon()
          : markerIcon
      }
      draggable={props.isDraggable}
      zIndexOffset={markerZIndex}
    >
      {isTooltipReady && (
        <Tooltip
          direction="top"
          offset={[0, -5]}
          opacity={1}
          permanent={tooltipPermanent}
        >
          {tooltipContent}
        </Tooltip>
      )}
    </Marker>
  );
};

export default MapMarkerComponent;
