import { FC, useMemo } from "react";
import TableComponent, {
  TableProps,
} from "../../../../../../../../../common/components/table/table.component";
import { useAppContext } from "../../../../../../../../../context/app.context";
import driverAddUserDataAddressTableHelper from "./driver-add-user-data-address-table.helper";
import DriverAddUserDataAddressTableColumn from "../../../../types/driver-add-user-data-address-table-column";
import DriverAddAddress from "../../../../types/driver-add-address";
import DriverAddUserDataAddressTableRow from "../../../../types/driver-add-user-data-address-table-row";
import { faEnvelope, faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DriverAddAddressType from "../../../../types/driver-add-address-type";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import driverAddUserDataAddressHelper from "../driver-add-user-data-address.helper";
import TableDeleteButtonComponent from "../../../../../../../../../common/components/table/button/delete/table-delete-button.component";

type DriverAddUserDataAddressTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  addresses: DriverAddAddress[];
  onRemoveAddressButtonClick: (index: number) => void;
};

const DriverAddUserDataAddressTableComponent: FC<
  DriverAddUserDataAddressTableProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const columns = useMemo(
    (): DriverAddUserDataAddressTableColumn[] =>
      driverAddUserDataAddressTableHelper.getColumns(),
    [selectedAppLanguage]
  );

  const addressTypeIconDefinitions: {
    type: DriverAddAddressType;
    icon: IconProp;
  }[] = [
    {
      icon: faEnvelope,
      type: DriverAddAddressType.MAILING,
    },
    {
      icon: faHouse,
      type: DriverAddAddressType.STARTING,
    },
  ];

  const createTableRow = (
    address: DriverAddAddress,
    index: number
  ): DriverAddUserDataAddressTableRow => {
    const icon = addressTypeIconDefinitions.find(
      (definition) => definition.type === address.type
    )?.icon;

    return {
      id: String(index),
      value: {
        address: (
          <div className="driver_add_user_add_address_table_row address">
            {icon && <FontAwesomeIcon icon={icon} className="icon" />}
            <div className="text_ellipsis">
              {driverAddUserDataAddressHelper.getAddressLabel(address)}
            </div>
          </div>
        ),
        actions: (
          <TableDeleteButtonComponent
            onClick={() => props.onRemoveAddressButtonClick(index)}
          />
        ),
      },
    };
  };

  const rows: DriverAddUserDataAddressTableRow[] = useMemo(() => {
    return props.addresses.map(createTableRow);
  }, [props.addresses]);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isLoading={props.isLoading}
      isError={props.isError}
    />
  );
};

export default DriverAddUserDataAddressTableComponent;
