import { FC, useMemo } from "react";
import billingDetailsHelper from "./billing-details.helper";
import BillingFormData from "../types/billing-form.data";
import BillingDetailsTableRow from "./types/billing-details-table-row";
import TableComponent from "../../../../../common/components/table/table.component";
import dateService from "../../../../../common/utils/date/date.service";

type OrderBillingDetailsProps = {
  billingData: BillingFormData | undefined;
};

const BillingDetailsComponent: FC<OrderBillingDetailsProps> = (props) => {
  const createTableRow = (
    billingData: BillingFormData
  ): BillingDetailsTableRow => {
    return {
      id: billingData.internalOrderId.toString(),
      value: {
        date: (
          <div
            title={
              billingData.billingNodes[0].checkoutDate
                ? dateService.format(
                    billingData.billingNodes[0].checkoutDate,
                    "dd/mm/yyyy"
                  )
                : undefined
            }
          >
            {billingData.billingNodes[0].checkoutDate
              ? dateService.format(
                  billingData.billingNodes[0].checkoutDate,
                  "dd/mm/yyyy"
                )
              : undefined}
          </div>
        ),
        internalOrderId: (
          <div title={billingData.internalOrderId.toString()}>
            {billingData.internalOrderId}
          </div>
        ),
        companyName: (
          <div title={billingData.contractDetails?.companyName}>
            {billingData.contractDetails?.companyName}
          </div>
        ),
        model: (
          <div title={billingData.contractDetails?.model}>
            {billingData.contractDetails?.model}
          </div>
        ),
        rate: (
          <div title={billingData.contractDetails?.distanceRate?.toString()}>
            {billingData.contractDetails?.distanceRate?.toFixed(2)}
          </div>
        ),
        distance: (
          <div title={billingData.distance?.toString()}>
            {billingData.distance}
          </div>
        ),
        routeDistanceCost: (
          <div title={billingData.routeDistanceCost.toString()}>
            {billingData.routeDistanceCost?.toFixed(2)}
          </div>
        ),
        discount: (
          <div title={billingData.discount?.toString()}>
            {billingData.discount?.toFixed(2)}%
          </div>
        ),
        discountTotal: (
          <div title={billingData.discountAmount.toString()}>
            {billingData.discountAmount?.toFixed(2)}
          </div>
        ),
        haltingCost: (
          <div title={billingData.haltingCost.toString()}>
            {billingData.haltingCost?.toFixed(2)}
          </div>
        ),
        highwayCost: (
          <div title={billingData.highwayCharge.toString()}>
            {billingData.highwayCharge?.toFixed(2)}
          </div>
        ),
        total: (
          <div title={billingData?.total.toString()}>
            {billingData.total?.toFixed(2)}
          </div>
        ),
      },
    };
  };

  const tableColumns = billingDetailsHelper.getTableColumns();

  const tableRows: BillingDetailsTableRow[] = useMemo(() => {
    if (props.billingData) {
      var tableRow = createTableRow(props.billingData);
      return [tableRow];
    }

    return [];
  }, [props.billingData]);

  return <TableComponent columns={tableColumns} rows={tableRows} />;
};

export default BillingDetailsComponent;
