import { faCircleInfo, faEdit } from "@fortawesome/free-solid-svg-icons";
import TableLinkButtonComponent from "../../../../../../common/components/table/button/link/table-link-button.component";
import dateService from "../../../../../../common/utils/date/date.service";
import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";
import BillingsTaxiOrderListingItem from "../types/billings-taxi-order-listing-item";
import BillingsTaxiOrderListingItemBillingStatus from "../types/billings-taxi-order-listing-item-billing-status";
import BillingsTaxiOrderListingItemBillingType from "../types/billings-taxi-order-listing-item-billing-type";
import BillingsTaxiOrderListingTableColumn from "../types/billings-taxi-order-listing-table-column";
import BillingsTaxiOrderListingTableRow from "../types/billings-taxi-order-listing-table-row";
import { BillingsTaxiOrderListingUserPermissions } from "../user-permissions/billings-taxi-order-listing-user-permission";
import BillingsTaxiOrderListingTableStatusComponent from "./status/billings-taxi-order-listing-table-status.component";
import billingRoutesHelper from "../../../../common/routes/billing-routes.helper";
import billingsTaxiOrderListingHelper from "../billings-taxi-order-listing.helper";

const getColumns = (): BillingsTaxiOrderListingTableColumn[] => {
  const translations =
    billingsTranslationsHelper.getTaxiOrderBillingsListingTranslations().table
      .headers;

  return [
    {
      accessor: "billingStatus",
      header: "",
      title: "",
      colSpan: 2,
    },
    {
      accessor: "orderStartDate",
      header: translations.orderStartDateLabel,
      title: translations.orderStartDateTitle,
      colSpan: 10,
    },
    {
      accessor: "internalOrderId",
      header: translations.internalOrderIdLabel,
      title: translations.internalOrderIdTitle,
      colSpan: 8,
    },
    {
      accessor: "planEntryHumanIds",
      header: translations.planEntryHumanIdsLabel,
      title: translations.planEntryHumanIdsTitle,
      colSpan: 8,
    },
    {
      accessor: "externalOrderId",
      header: translations.externalOrderIdLabel,
      title: translations.externalOrderIdTitle,
      colSpan: 8,
    },
    {
      accessor: "cargoCompany",
      header: translations.cargoCompanyLabel,
      title: translations.cargoCompanyTitle,
      colSpan: 14,
    },
    {
      accessor: "destinationTaxi",
      header: translations.destinationTaxiLabel,
      title: translations.destinationTaxiTitle,
      colSpan: 14,
    },
    {
      accessor: "passengers",
      header: translations.passengersLabel,
      title: translations.passengersTitle,
      colSpan: 15,
    },
    {
      accessor: "billingModel",
      header: translations.billingModelLabel,
      title: translations.billingModelTitle,
      colSpan: 6,
    },
    {
      accessor: "distance",
      header: translations.distanceLabel,
      title: translations.distanceTitle,
      colSpan: 10,
    },
    {
      accessor: "baseDistanceRate",
      header: translations.baseDistanceRateLabel,
      title: translations.baseDistanceRateTitle,
      colSpan: 10,
    },
    {
      accessor: "amountForDistance",
      header: translations.amountForDistanceLabel,
      title: translations.amountForDistanceTitle,
      colSpan: 10,
    },
    {
      accessor: "stopCost",
      header: translations.stopoverCostLabel,
      title: translations.stopoverCostTitle,
      colSpan: 10,
    },
    {
      accessor: "tollRoadsCost",
      header: translations.tollRoadsCostLabel,
      title: translations.tollRoadsCostTitle,
      colSpan: 10,
    },
    {
      accessor: "billingType",
      header: translations.billingTypeLabel,
      title: translations.billingTypeTitle,
      colSpan: 10,
    },
    {
      accessor: "sumOfDiscounts",
      header: translations.sumOfDiscountsLabel,
      title: translations.sumOfDiscountsTitle,
      colSpan: 9,
    },
    {
      accessor: "totalCost",
      header: translations.totalCostLabel,
      title: translations.totalCostTitle,
      colSpan: 10,
    },
    {
      accessor: "actions",
      header: "",
      title: "",
      colSpan: 9,
    },
  ];
};

const getDistanceLabel = (distance: number): string => {
  return `${distance} km`;
};

const getDistanceTitle = (distance: number): string => {
  return `${distance} km`;
};

const getBaseDistanceRateLabel = (baseDistanceRate: number): string => {
  return `${String(baseDistanceRate.toFixed(2))} PLN/km`;
};

const getBaseDistanceRateTitle = (baseDistanceRate: number): string => {
  return `${String(baseDistanceRate.toFixed(2))} PLN/km`;
};

const getStopoverCostLabel = (stopoverCost: number): string => {
  return `${String(stopoverCost.toFixed(2))} PLN`;
};

const getStopoverCostTitle = (stopoverCost: number): string => {
  return `${String(stopoverCost.toFixed(2))} PLN`;
};

const getTollRoadsCostLabel = (tollRoadsCost: number): string => {
  return `${String(tollRoadsCost.toFixed(2))} PLN`;
};

const getTollRoadsCostTitle = (tollRoadsCost: number): string => {
  return `${String(tollRoadsCost.toFixed(2))} PLN`;
};

const getSumOfDiscountsLabel = (discountCost: number): string => {
  return `${String(discountCost.toFixed(2))} PLN`;
};

const getSumOfDiscountsTitle = (discountCost: number): string => {
  return `${String(discountCost.toFixed(2))} PLN`;
};

const getAmountForDistanceLabel = (amountForDistance: number): string => {
  return `${String(amountForDistance.toFixed(2))} PLN`;
};

const getAmountForDistanceTitle = (amountForDistance: number): string => {
  return `${String(amountForDistance.toFixed(2))} PLN`;
};

const getTotalCostLabel = (totalCost: number): string => {
  return `${String(totalCost.toFixed(2))} PLN`;
};

const getTotalCostTitle = (totalCost: number): string => {
  return `${String(totalCost.toFixed(2))} PLN`;
};

const getRow = (
  listingItem: BillingsTaxiOrderListingItem,
  userPermissions: BillingsTaxiOrderListingUserPermissions
): BillingsTaxiOrderListingTableRow => {
  const translations =
    billingsTranslationsHelper.getTaxiOrderBillingsListingTranslations().table;

  const startDateLabel = dateService.format(
    listingItem.orderStartDate,
    "dd.mm.yyyy"
  );

  const startDateTitle = dateService.format(
    listingItem.orderStartDate,
    "dd.mm.yyyy"
  );

  const distanceLabel = getDistanceLabel(listingItem.distance);
  const distanceTitle = getDistanceTitle(listingItem.distance);

  const baseDistanceRateLabel = getBaseDistanceRateLabel(
    listingItem.baseDistanceRate
  );
  const baseDistanceRateTitle = getBaseDistanceRateTitle(
    listingItem.baseDistanceRate
  );

  const stopoverCostLabel = getStopoverCostLabel(listingItem.stopoverCost);
  const stopoverCostTitle = getStopoverCostTitle(listingItem.stopoverCost);

  const tollRoadsCostLabel = getTollRoadsCostLabel(listingItem.tollRoadsCost);
  const tollRoadsCostTitle = getTollRoadsCostTitle(listingItem.tollRoadsCost);

  const sumOfDiscountsLabel = getSumOfDiscountsLabel(
    listingItem.sumOfDiscounts
  );
  const sumOfDiscountsTitle = getSumOfDiscountsTitle(
    listingItem.sumOfDiscounts
  );

  const amountForDistanceLabel = getAmountForDistanceLabel(
    listingItem.amountForDistance
  );
  const amountForDistanceTitle = getAmountForDistanceTitle(
    listingItem.amountForDistance
  );

  const totalCostLabel = getTotalCostLabel(listingItem.totalCost);
  const totalCostTitle = getTotalCostTitle(listingItem.totalCost);

  const billingModelLabel = billingsTaxiOrderListingHelper.getBillingModelText(
    listingItem.billingModel
  );
  const billingModelTitle = billingsTaxiOrderListingHelper.getBillingModelText(
    listingItem.billingModel
  );

  const billingTypeLabel = billingsTaxiOrderListingHelper.getBillingTypeText(
    listingItem.billingType
  );
  const billingTypeTitle = billingsTaxiOrderListingHelper.getBillingTypeText(
    listingItem.billingType
  );

  const isCargoWithTaxiBillingEditButtonVisible =
    userPermissions.hasAccessToEditCargoWithTaxiBilling &&
    listingItem.billingType ===
      BillingsTaxiOrderListingItemBillingType.PRIV_CARGO_2_TAXI &&
    listingItem.status &&
    [
      BillingsTaxiOrderListingItemBillingStatus.CREATED,
      BillingsTaxiOrderListingItemBillingStatus.REJECTED,
      BillingsTaxiOrderListingItemBillingStatus.REOPENED,
    ].includes(listingItem.status);

  const isCargoWithTaxiBillingDetailsButtonVisible =
    userPermissions.hasAccessToDetailsCargoWithTaxiBilling &&
    listingItem.billingType ===
      BillingsTaxiOrderListingItemBillingType.PRIV_CARGO_2_TAXI;

  return {
    id: listingItem.uuid,
    value: {
      orderStartDate: <div title={startDateLabel}>{startDateTitle}</div>,
      internalOrderId: (
        <div title={listingItem.internalOrderId}>
          {listingItem.internalOrderId}
        </div>
      ),
      planEntryHumanIds: (
        <div title={String(listingItem.planEntryHumanIds)}>
          {listingItem.planEntryHumanIds}
        </div>
      ),
      externalOrderId: (
        <div title={String(listingItem.externalOrderId)}>
          {listingItem.externalOrderId}
        </div>
      ),
      cargoCompany: (
        <div title={listingItem.cargoCompanyName ?? "Raily"}>
          {listingItem.cargoCompanyName ?? "Raily"}
        </div>
      ),
      destinationTaxi: (
        <div title={listingItem.destinationTaxi}>
          {listingItem.destinationTaxi}
        </div>
      ),
      passengers: (
        <div title={listingItem.passengers.join("\r")}>
          {listingItem.passengers.map((passenger, index) => {
            return <div key={index}>{passenger}</div>;
          })}
        </div>
      ),
      billingModel: <div title={billingModelTitle}>{billingModelLabel}</div>,
      billingType: <div title={billingTypeTitle}>{billingTypeLabel}</div>,
      billingStatus: (
        <BillingsTaxiOrderListingTableStatusComponent
          status={listingItem.status}
        />
      ),
      distance: <div title={distanceTitle}>{distanceLabel}</div>,
      baseDistanceRate: (
        <div title={baseDistanceRateTitle}>{baseDistanceRateLabel}</div>
      ),
      amountForDistance: (
        <div title={amountForDistanceTitle}>{amountForDistanceLabel}</div>
      ),
      stopCost: <div title={stopoverCostTitle}>{stopoverCostLabel}</div>,
      tollRoadsCost: <div title={tollRoadsCostTitle}>{tollRoadsCostLabel}</div>,
      sumOfDiscounts: (
        <div title={sumOfDiscountsTitle}>{sumOfDiscountsLabel}</div>
      ),
      totalCost: <div title={totalCostTitle}>{totalCostLabel}</div>,
      actions: (
        <div className="d-flex">
          {isCargoWithTaxiBillingEditButtonVisible && (
            <TableLinkButtonComponent
              icon={faEdit}
              to={billingRoutesHelper.getCargoWithTaxiEditRoute({
                billingUuid: listingItem.uuid,
              })}
              title={translations.cargoWithTaxiBillingEditButtonTitle}
              idForTesting={`billings-taxi-order-listing-table-item-${listingItem.uuid}-edit-button`}
            />
          )}
          {isCargoWithTaxiBillingDetailsButtonVisible && (
            <TableLinkButtonComponent
              icon={faCircleInfo}
              to={billingRoutesHelper.getCargoWithTaxiForTaxiDetailsRoute({
                billingUuid: listingItem.uuid,
              })}
              title={translations.cargoWithTaxiBillingDetailsButtonTitle}
              idForTesting={`billings-taxi-order-listing-table-item-${listingItem.uuid}-details-button`}
            />
          )}
        </div>
      ),
    },
  };
};

const getRows = (
  listingItems: BillingsTaxiOrderListingItem[],
  userPermissions: BillingsTaxiOrderListingUserPermissions
): BillingsTaxiOrderListingTableRow[] => {
  return listingItems.map((listingItem) =>
    getRow(listingItem, userPermissions)
  );
};

const billingsTaxiOrderListingTableHelper = {
  getColumns,
  getRows,
};

export default billingsTaxiOrderListingTableHelper;
