import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import useIsMobile from "../../../hooks/use-is-mobile";
import BreadcrumbItem from "../common/types/breadcrumb-item";

type BreadcrumbItemProps = BreadcrumbItem & {
  icon: IconProp;
  isActive: boolean;
};

const BreadcrumbItemComponent: FC<BreadcrumbItemProps> = (props) => {
  const isMobile = useIsMobile();

  if (props.linkTo) {
    return (
      <NavLink
        to={props.linkTo}
        className={classNames("breadcrumb_item", props.isActive && "active")}
      >
        <FontAwesomeIcon
          icon={props.icon}
          size="sm"
          className="breadcrumb_item__icon"
          spin={props.isSpinning}
        />
        {!isMobile && props.text && (
          <div className="breadcrumb_item__text">{props.text}</div>
        )}
      </NavLink>
    );
  }
  return (
    <a className={"breadcrumb_item"}>
      <FontAwesomeIcon
        icon={props.icon}
        size="sm"
        className="breadcrumb_item__icon"
        spin={props.isSpinning}
      />
      {!isMobile && props.text && (
        <div className="breadcrumb_item__text">{props.text}</div>
      )}
    </a>
  );
};

export default BreadcrumbItemComponent;
