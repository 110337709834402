import { FC, useMemo } from "react";
import ButtonComponent from "../../../../common/components/button/button.component";
import ModalComponent, {
  ModalProps,
} from "../../../../common/components/modal/modal.component";
import htmlParser from "../../../../common/utils/html-parser/html-parser.service";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import orderDetailsOrderCancelRequestConfirmationHelper from "./order-details-order-cancel-request-confirmation.helper";
import { useAppContext } from "../../../../context/app.context";

type OrderDetailsOrderCancelRequestConfirmationModalComponentProps = Pick<
  ModalProps,
  "isOpen" | "onClose"
> & {
  onConfirm: () => void;
  cargoCompanyOrderId: number | undefined;
  isRevertMode: boolean;
};

const OrderDetailsOrderCancelRequestConfirmationModalComponent: FC<
  OrderDetailsOrderCancelRequestConfirmationModalComponentProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  if (props.cargoCompanyOrderId === undefined) {
    return null;
  }

  const translations = useMemo(() => {
    const detailsTranslations =
      orderTranslationsHelper.getDetailsTranslations();

    return props.isRevertMode
      ? detailsTranslations.revertCancelOrderRequest.confirmationModal
      : detailsTranslations.cancelOrderRequest.confirmationModal;
  }, [props.isRevertMode, selectedAppLanguage]);

  const contentText = props.isRevertMode
    ? orderDetailsOrderCancelRequestConfirmationHelper.getRevertContentMessageText(
        props.cargoCompanyOrderId
      )
    : orderDetailsOrderCancelRequestConfirmationHelper.getContentMessageText(
        props.cargoCompanyOrderId
      );

  return (
    <ModalComponent
      header={{ title: translations.headingText }}
      isOpen={props.isOpen}
      onClose={props.onClose}
      actions={[
        <ButtonComponent
          type="danger"
          onClick={props.onConfirm}
          title={translations.confirmationButtonTitle}
        >
          {translations.confirmationButtonText}
        </ButtonComponent>,
        <ButtonComponent
          type="brand"
          onClick={props.onClose}
          title={translations.rejectButtonTitle}
        >
          {translations.rejectButtonText}
        </ButtonComponent>,
      ]}
    >
      {htmlParser.parse(contentText)}
    </ModalComponent>
  );
};

export default OrderDetailsOrderCancelRequestConfirmationModalComponent;
