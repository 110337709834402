import { CargoDispatchRouteQueryParams } from "./types/cargo-dispatch-listing-route-params";

const getPage = (
  routeQueryParams: CargoDispatchRouteQueryParams
): number | undefined => {
  return routeQueryParams.page ? Number(routeQueryParams.page) : undefined;
};

const getPageSize = (
  routeQueryParams: CargoDispatchRouteQueryParams
): number | undefined => {
  return routeQueryParams.pageSize
    ? Number(routeQueryParams.pageSize)
    : undefined;
};

const createRouteQueryParams = (
  page: number,
  pageSize: number
): CargoDispatchRouteQueryParams => {
  const routeQueryParams: CargoDispatchRouteQueryParams = {
    page: String(page),
    pageSize: String(pageSize),
  };

  return routeQueryParams;
};

const cargoCompanyListingService = {
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default cargoCompanyListingService;
