import { FC, useState } from "react";
import DealerAddBasicProps from "../common/dealer-add-basic-props";
import useAbort from "../../../../../common/hooks/use-abort";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import { useNavigate } from "react-router-dom";
import userRoutesHelper from "../../../common/routes/user-routes.helper";
import notificationService from "../../../../../common/utils/notification/notification.service";
import DealerAddParams from "../../../../../common/services/dealer/add/dealer-add-params";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import Row from "../../../../../common/components/grid/row";
import Column from "../../../../../common/components/grid/column";
import CardComponent from "../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../common/components/form/input/input.component";
import PhoneNumberInputComponent from "../../../../../common/components/form/input/phone-number/phone-number-input.component";
import ButtonComponent from "../../../../../common/components/button/button.component";
import DealerAddByTaxiFormData from "./common/types/dealer-add-by-taxi-form-data";
import dealerAddByTaxiFormHelper from "./common/dealer-add-by-taxi-form.helper";
import dealerAddByTaxiParamsFactory from "./common/dealer-add-by-taxi-params.factory";
import { useAppContext } from "../../../../../context/app.context";
import useForm from "../../../../../common/components/form/use-form";
import dealerService from "../../../../../common/services/dealer/dealer.service";

type DealerAddByTaxiProps = DealerAddBasicProps;

const DealerAddByTaxiComponent: FC<DealerAddByTaxiProps> = (props) => {
  const dealerAddAbort = useAbort();

  const translations = userTranslationsHelper.getDealerAddTranslations();

  const navigate = useNavigate();

  const form = useForm<DealerAddByTaxiFormData>({
    emptyValues: dealerAddByTaxiFormHelper.getDefaultFormData(),
    validationDefinition: dealerAddByTaxiFormHelper.getValidationDefinition(),
  });

  const { user } = useAppContext();

  const [isDealerAddPending, setIsDealerAddPending] = useState(false);

  const navigateToListing = () => {
    const dealerListingRoute = userRoutesHelper.getDealerListingRoute();

    navigate(dealerListingRoute);
  };

  const onDealerAddSuccess = () => {
    notificationService.success(translations.successAddNotificationLabel);
    navigateToListing();
  };

  const onDealerAddFailure = () => {
    notificationService.error(translations.failureAddNotificationLabel);
  };

  const addDealer = async () => {
    setIsDealerAddPending(true);

    const params: DealerAddParams = dealerAddByTaxiParamsFactory.create(
      form.values,
      user!
    );

    try {
      await dealerService.add(params, dealerAddAbort.signal);

      onDealerAddSuccess();
    } catch {
      onDealerAddFailure();
    } finally {
      setIsDealerAddPending(false);
    }
  };

  const submitForm = async () => {
    const isFormValid = await form.validateAll();

    if (!isFormValid) return;

    addDealer();
  };

  return (
    <>
      <HeadingComponent
        title={translations.header.headingLabel}
        actions={props.changeViewButtons}
      />
      <Row>
        <Column xl={8}>
          <CardComponent>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.emailLabel}
                  isRequired
                  errorMessage={form.validationResults.email.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.emailPlaceholder}
                    value={form.values.email}
                    onChange={(value) => form.setValue("email", value)}
                    onBlur={() => form.validate("email")}
                    hasError={!!form.validationResults.email.errorMessage}
                    idForTesting={`email`}
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.usernameLabel}
                  isRequired
                  errorMessage={form.validationResults.username.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.usernamePlaceholder}
                    value={form.values.username}
                    onChange={(value) =>
                      form.setValue("username", value.toLowerCase())
                    }
                    onBlur={() => form.validate("username")}
                    hasError={!!form.validationResults.username.errorMessage}
                    idForTesting={`username`}
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.firstNameLabel}
                  isRequired
                  errorMessage={form.validationResults.firstName.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.firstNamePlaceholder}
                    value={form.values.firstName}
                    onChange={(value) => form.setValue("firstName", value)}
                    onBlur={() => form.validate("firstName")}
                    hasError={!!form.validationResults.firstName.errorMessage}
                    idForTesting={`first-name`}
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.lastNameLabel}
                  isRequired
                  errorMessage={form.validationResults.lastName.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.lastNamePlaceholder}
                    value={form.values.lastName}
                    onChange={(value) => form.setValue("lastName", value)}
                    onBlur={() => form.validate("lastName")}
                    hasError={!!form.validationResults.lastName.errorMessage}
                    idForTesting={`last-name`}
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.phoneNumberLabel}
                  isRequired
                  errorMessage={form.validationResults.phoneNumber.errorMessage}
                >
                  <PhoneNumberInputComponent
                    placeholder={translations.form.phoneNumberPlaceholder}
                    phoneNumber={form.values.phoneNumber}
                    onChange={(value) => form.setValue("phoneNumber", value)}
                    onBlur={() => form.validate("phoneNumber")}
                    hasError={!!form.validationResults.phoneNumber.errorMessage}
                    idForTesting={`phone-number`}
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.alternativePhoneNumberLabel}
                  errorMessage={
                    form.validationResults.alternativePhoneNumber.errorMessage
                  }
                >
                  <PhoneNumberInputComponent
                    placeholder={
                      translations.form.alternativePhoneNumberPlaceholder
                    }
                    phoneNumber={form.values.alternativePhoneNumber}
                    onChange={(value) =>
                      form.setValue("alternativePhoneNumber", value)
                    }
                    onBlur={() => form.validate("alternativePhoneNumber")}
                    hasError={
                      !!form.validationResults.alternativePhoneNumber
                        .errorMessage
                    }
                    idForTesting={`alternative-phone-number`}
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.passwordLabel}
                  isRequired
                  errorMessage={form.validationResults.password.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.passwordPlaceholder}
                    value={form.values.password}
                    type="password"
                    onChange={(value) => form.setValue("password", value)}
                    onBlur={() => form.validate("password")}
                    hasError={!!form.validationResults.password.errorMessage}
                    idForTesting={`password`}
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.passwordRepeatLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.passwordRepeat.errorMessage
                  }
                >
                  <InputComponent
                    placeholder={translations.form.passwordRepeatPlaceholder}
                    value={form.values.passwordRepeat}
                    type="password"
                    onChange={(value) => form.setValue("passwordRepeat", value)}
                    onBlur={() => form.validate("passwordRepeat")}
                    hasError={
                      !!form.validationResults.passwordRepeat.errorMessage
                    }
                    idForTesting={`password-repeat`}
                  />
                </FormFieldComponent>
              </Column>
            </Row>
          </CardComponent>
        </Column>
      </Row>
      <ButtonComponent
        onClick={submitForm}
        type="primary"
        isDisabled={isDealerAddPending}
        classNames={{ root: "mt-4" }}
        idForTesting={`submit-button`}
        title={translations.form.submitButtonTitle}
      >
        {translations.form.submitButtonLabel}
      </ButtonComponent>
    </>
  );
};

export default DealerAddByTaxiComponent;
