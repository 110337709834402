import {
  OrderDetailsResponseTransportingOrder,
  OrderDetailsResponseTransportingOrderSolve,
} from "./api/order-details.response";
import OrderDetailsConnectedWaypoints from "./types/order-details-connected-waypoints";
import OrderDetailsRouteWaypoint from "./types/order-details-route-waypoint";
import OrderDetailsPassenger from "./types/order-details-passenger";
import OrderDetailsDriver from "./types/order-details-driver";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import { SearchRoadRoutingRequestWaypointCoordinate } from "../../../../common/utils/search-road-route/search-road-routing.request";
import MessengerChannel from "../../../../common/components/messenger/types/messenger-channel";
import MessengerChannelAvailability from "../../../../common/components/messenger/types/messenger-channel-availability";
import UserRole from "../../../../common/types/user-role";

const checkIsRouteSequenceSolved = (
  waypoints: OrderDetailsRouteWaypoint[]
): boolean => {
  const waypointsStageNoArray = waypoints.map((waypoint) => waypoint.stageNo);

  return !waypointsStageNoArray.some((stageNo, index) => {
    return waypointsStageNoArray.indexOf(stageNo) != index;
  });
};

const checkIsOrderSolved = (
  responseOrderTransportingOrders: OrderDetailsResponseTransportingOrder[]
) => {
  if (responseOrderTransportingOrders.length === 0) {
    return false;
  }

  const isOrderSolved = responseOrderTransportingOrders.every(
    (item) => item.solve
  );

  return isOrderSolved;
};

const checkIsResponseRideSeqItemAWaypoint = (
  responseRideSeqItem: OrderDetailsResponseTransportingOrderSolve
): boolean => {
  if ((responseRideSeqItem as any).lat) {
    return true;
  }

  return false;
};

const getPassengerOnboardPlace = (
  passengerUuid: OrderDetailsPassenger["uuid"],
  routes: OrderDetailsRouteWaypoint[]
): OrderDetailsRouteWaypoint["place"] | null => {
  const flattenRoutes = routes.flat();

  return (
    flattenRoutes.find((route) =>
      route.onboardingPassengersUuids.includes(passengerUuid)
    )?.place ?? null
  );
};

const getPassengerOutboardPlace = (
  passengerUuid: OrderDetailsPassenger["uuid"],
  routes: OrderDetailsRouteWaypoint[]
): OrderDetailsRouteWaypoint["place"] | null => {
  const flattenRoutes = routes.flat();

  return (
    flattenRoutes.find((route) =>
      route.outboardingPassengersUuids.includes(passengerUuid)
    )?.place ?? null
  );
};

const getPassengerTitle = (
  passenger: OrderDetailsPassenger,
  routes: OrderDetailsRouteWaypoint[]
): string => {
  const translations = orderTranslationsHelper.getDetailsTranslations();

  const onboardPlace = getPassengerOnboardPlace(passenger.uuid, routes);
  const outboardPlace = getPassengerOutboardPlace(passenger.uuid, routes);

  return translations.passengers.passengerTitleTemplateText
    .replace("#{passenger}", passenger.displayName)
    .replace("#{mobileNumber}", passenger.phoneNumber)
    .replace("#{onboardPlace}", onboardPlace?.displayName ?? "N/A")
    .replace("#{outboardPlace}", outboardPlace?.displayName ?? "N/A");
};

const getDriverTitle = (driver: OrderDetailsDriver): string => {
  const translations = orderTranslationsHelper.getDetailsTranslations();

  return `${translations.drivers.driverTitleTemplateText
    .replace("#{driverDisplayName}", driver.displayName)
    .replace("#{phoneNumber}", driver.phoneNumber)}`;
};

const getDriverButtonTitle = (driver: OrderDetailsDriver): string => {
  const translations = orderTranslationsHelper.getDetailsTranslations();

  return `${translations.drivers.driverButtonTitleTemplateText
    .replace("#{driverDisplayName}", driver.displayName)
    .replace("#{phoneNumber}", driver.phoneNumber)}`;
};

const getWaypointGroupsCouldBeConnected = (
  waypoints: OrderDetailsRouteWaypoint[]
) => {
  let waypointGroups: OrderDetailsConnectedWaypoints[] = [];

  waypoints.forEach((waypoint, index) => {
    const nextWaypoint = waypoints[index + 1];

    if (!nextWaypoint) {
      return;
    }

    const isWaypointAndNextWaypointSameStageNo =
      waypoint.stageNo === nextWaypoint.stageNo;
    const isWaypointAlone =
      waypoints.filter((w) => w.stageNo === waypoint.stageNo).length === 1;
    const isNextWaypointAlone =
      waypoints.filter((w) => w.stageNo === nextWaypoint.stageNo).length === 1;

    if (
      !isWaypointAndNextWaypointSameStageNo &&
      isWaypointAlone &&
      isNextWaypointAlone
    ) {
      waypointGroups.push({ start: waypoint, end: nextWaypoint });
    }
  });

  return waypointGroups;
};

const getCoordinatesOfWaypointsCouldBeConnected = (
  connectedWaypoints: OrderDetailsConnectedWaypoints[]
): SearchRoadRoutingRequestWaypointCoordinate[][] => {
  let coordinatesOfWaypointsCouldBeConnected: SearchRoadRoutingRequestWaypointCoordinate[][] =
    [];

  connectedWaypoints.forEach((connection) => {
    const start = {
      latitude: connection.start.place.latitude,
      longitude: connection.start.place.longitude,
    };

    const end = {
      latitude: connection.end.place.latitude,
      longitude: connection.end.place.longitude,
    };

    const newGroup: SearchRoadRoutingRequestWaypointCoordinate[] = [start, end];

    coordinatesOfWaypointsCouldBeConnected.push(newGroup);
  });

  return coordinatesOfWaypointsCouldBeConnected;
};

const getOrderCancellationRequestSuccessMessage = (
  cargoCompanyOrderId: number
) => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().cancelOrderRequest;
  return translations.successNotificationMessageTemplateText.replace(
    "#{cargoCompanyOrderId}",
    String(cargoCompanyOrderId)
  );
};

const getOrderCancellationRequestFailureMessage = (
  cargoCompanyOrderId: number
) => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().cancelOrderRequest;
  return translations.errorNotificationMessageTemplateText.replace(
    "#{cargoCompanyOrderId}",
    String(cargoCompanyOrderId)
  );
};

const getOrderCancellationSuccessMessage = (cargoCompanyOrderId: number) => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().cancelOrder;
  return translations.successNotificationMessageTemplateText.replace(
    "#{cargoCompanyOrderId}",
    String(cargoCompanyOrderId)
  );
};

const getOrderCancellationFailureMessage = (cargoCompanyOrderId: number) => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().cancelOrder;
  return translations.errorNotificationMessageTemplateText.replace(
    "#{cargoCompanyOrderId}",
    String(cargoCompanyOrderId)
  );
};

const getRevertOrderCancellationRequestSuccessMessage = (
  cargoCompanyOrderId: number
) => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().revertCancelOrderRequest;
  return translations.successNotificationMessageTemplateText.replace(
    "#{cargoCompanyOrderId}",
    String(cargoCompanyOrderId)
  );
};

const getRevertOrderCancellationRequestFailureMessage = (
  cargoCompanyOrderId: number
) => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().revertCancelOrderRequest;
  return translations.errorNotificationMessageTemplateText.replace(
    "#{cargoCompanyOrderId}",
    String(cargoCompanyOrderId)
  );
};

const getRevertOrderCancellationSuccessMessage = (
  cargoCompanyOrderId: number
) => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().revertCancelOrderRequest;
  return translations.successNotificationMessageTemplateText.replace(
    "#{cargoCompanyOrderId}",
    String(cargoCompanyOrderId)
  );
};

const getRevertOrderCancellationFailureMessage = (
  cargoCompanyOrderId: number
) => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().revertCancelOrderRequest;
  return translations.errorNotificationMessageTemplateText.replace(
    "#{cargoCompanyOrderId}",
    String(cargoCompanyOrderId)
  );
};

const getCandidateDrivers = (
  transportingOrders: OrderDetailsResponseTransportingOrder[]
) => {
  return transportingOrders
    .map((transportingOrder) => {
      const candidatureEntries = transportingOrder.create_plan_entry_entries
        .length
        ? transportingOrder.create_plan_entry_entries
        : transportingOrder.create_solved_order_entries;

      return candidatureEntries.map((p) => p.driver);
    })
    .flat();
};

const getMessengerChannelAvailability = (
  solvedOrderUuid: string | undefined
): MessengerChannelAvailability => {
  if (!!solvedOrderUuid) {
    return {
      [MessengerChannel.CARGO]: [UserRole.CARGO_OFFICER, UserRole.DISPATCHER],
      [MessengerChannel.DRIVER]: [
        UserRole.ADMIN,
        UserRole.RAILY_OFFICER,
        UserRole.OPERATOR,
        UserRole.DEALER,
      ],
      [MessengerChannel.RAILY]: [
        UserRole.ADMIN,
        UserRole.RAILY_OFFICER,
        UserRole.OPERATOR,
      ],
      [MessengerChannel.SHARED]: [
        UserRole.ADMIN,
        UserRole.RAILY_OFFICER,
        UserRole.OPERATOR,
        UserRole.CARGO_OFFICER,
        UserRole.DISPATCHER,
        UserRole.TAXI_OFFICER,
        UserRole.DEALER,
      ],
      [MessengerChannel.TAXI]: [UserRole.DEALER, UserRole.TAXI_OFFICER],
    };
  } else {
    return {
      [MessengerChannel.CARGO]: [UserRole.CARGO_OFFICER, UserRole.DISPATCHER],
      [MessengerChannel.RAILY]: [
        UserRole.ADMIN,
        UserRole.RAILY_OFFICER,
        UserRole.OPERATOR,
      ],
      [MessengerChannel.SHARED]: [
        UserRole.ADMIN,
        UserRole.RAILY_OFFICER,
        UserRole.OPERATOR,
        UserRole.CARGO_OFFICER,
        UserRole.DISPATCHER,
        UserRole.TAXI_OFFICER,
        UserRole.DEALER,
      ],
      [MessengerChannel.TAXI]: [UserRole.DEALER, UserRole.TAXI_OFFICER],
    };
  }
};

const orderDetailsHelper = {
  checkIsRouteSequenceSolved,
  checkIsOrderSolved,
  getPassengerTitle,
  getDriverTitle,
  getDriverButtonTitle,
  getWaypointGroupsCouldBeConnected,
  getCoordinatesOfWaypointsCouldBeConnected,
  checkIsResponseRideSeqItemAWaypoint,
  getOrderCancellationRequestSuccessMessage,
  getOrderCancellationRequestFailureMessage,
  getOrderCancellationSuccessMessage,
  getOrderCancellationFailureMessage,
  getRevertOrderCancellationRequestSuccessMessage,
  getRevertOrderCancellationRequestFailureMessage,
  getRevertOrderCancellationSuccessMessage,
  getRevertOrderCancellationFailureMessage,
  getCandidateDrivers,
  getMessengerChannelAvailability,
};

export default orderDetailsHelper;
