import { TaxiFleetPartnerListItem } from "../../../../../common/services/taxi-fleet-partner/list/taxi-fleet-partner-list";
import TaxiFleetPartnerListingItem from "./types/taxi-fleet-partner-listing-item";

const createListingItem = (
  taxiFleetPartnerListItem: TaxiFleetPartnerListItem
): TaxiFleetPartnerListingItem => {
  return {
    uuid: taxiFleetPartnerListItem.uuid,
    name: taxiFleetPartnerListItem.name,
    creationDate: taxiFleetPartnerListItem.creationDate,
    notes: taxiFleetPartnerListItem.notes,
  };
};

const createListingItems = (
  responseDataItems: TaxiFleetPartnerListItem[]
): TaxiFleetPartnerListingItem[] => {
  return responseDataItems.map(createListingItem);
};

const taxiFleetPartnerListingItemFactory = {
  createListingItems,
};

export default taxiFleetPartnerListingItemFactory;
