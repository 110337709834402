import { FC, useEffect, useState } from "react";
import CardComponent from "../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../common/components/form/input/input.component";
import Column from "../../../../common/components/grid/column";
import Row from "../../../../common/components/grid/row";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import userTranslationsHelper from "../../../../languages/user-translations.helper";
import RailyOfficerAddFormData from "./common/types/raily-officer-add-form-data";
import ButtonComponent from "../../../../common/components/button/button.component";
import { useAppContext } from "../../../../context/app.context";
import notificationService from "../../../../common/utils/notification/notification.service";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import PhoneNumberInputComponent from "../../../../common/components/form/input/phone-number/phone-number-input.component";
import railyOfficerAddFormHelper from "./common/raily-officer-add-form.helper";
import userBreadcrumbsHelper from "../../common/breadcrumbs/user-breadcrumbs.helper";
import useForm from "../../../../common/components/form/use-form";
import useAbort from "../../../../common/hooks/use-abort";
import railyOfficerService from "../../../../common/services/raily-officer/raily-officer.service";
import RailyOfficerAddLoadParams from "../../../../common/services/raily-officer/add/raily-officer-add-load-params";
import railyOfficerAddLoadParamsFactory from "./common/types/raily-officer-add-params.factory";
import userRoutesHelper from "../../common/routes/user-routes.helper";
import { useNavigate } from "react-router-dom";

type RailyOfficerAddProps = {};

const RailyOfficerAddComponent: FC<RailyOfficerAddProps> = () => {
  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.userRailyOfficerAdd);

  const translations = userTranslationsHelper.getRailyOfficerAddTranslations();

  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();
  const navigate = useNavigate();

  const [isRailyOfficerAddPending, setIsRailyOfficerAddPending] =
    useState(false);

  const railyOfficerAddAbort = useAbort();

  useEffect(() => {
    const breadcrumbs = userBreadcrumbsHelper.getRailyOfficerAddBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const form = useForm<RailyOfficerAddFormData>({
    emptyValues: railyOfficerAddFormHelper.getDefaultFormData(),
    validationDefinition: railyOfficerAddFormHelper.getValidationDefinition(),
  });

  const addRailyOfficer = async () => {
    setIsRailyOfficerAddPending(true);

    const params: RailyOfficerAddLoadParams =
      railyOfficerAddLoadParamsFactory.create(form.values);

    try {
      await railyOfficerService.add(params, railyOfficerAddAbort.signal);

      onRailyOfficerAddSuccess();
    } catch {
      onRailyOfficerAddFailure();
    } finally {
      setIsRailyOfficerAddPending(false);
    }
  };

  const submitForm = async () => {
    const isFormValid: boolean = await form.validateAll();

    if (!isFormValid) return;

    addRailyOfficer();
  };

  const navigateToListing = () => {
    const railyOfficerListingRoute =
      userRoutesHelper.getRailyOfficerListingRoute();
    navigate(railyOfficerListingRoute);
  };

  const onRailyOfficerAddSuccess = () => {
    notificationService.success(translations.successAddNotificationText);

    navigateToListing();
  };

  const onRailyOfficerAddFailure = () => {
    notificationService.error(translations.failureAddNotificationText);
  };

  return (
    <div className="raily_officer_add">
      <HeadingComponent title={translations.header.headingLabel} />
      <CardComponent>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.emailLabel}
              isRequired
              errorMessage={form.validationResults.email.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.emailPlaceholder}
                value={form.values.email}
                onChange={(value) => form.setValue("email", value)}
                onBlur={() => form.validate("email")}
                hasError={!!form.validationResults.email.errorMessage}
                idForTesting={`email-input`}
              />
            </FormFieldComponent>
          </Column>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.usernameLabel}
              isRequired
              errorMessage={form.validationResults.username.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.usernamePlaceholder}
                value={form.values.username}
                onChange={(value) =>
                  form.setValue("username", value.toLowerCase())
                }
                onBlur={() => form.validate("username")}
                hasError={!!form.validationResults.username.errorMessage}
                idForTesting={`username-input`}
              />
            </FormFieldComponent>
          </Column>
        </Row>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.firstNameLabel}
              isRequired
              errorMessage={form.validationResults.firstName.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.firstNamePlaceholder}
                value={form.values.firstName}
                onChange={(value) => form.setValue("firstName", value)}
                onBlur={() => form.validate("firstName")}
                hasError={!!form.validationResults.firstName.errorMessage}
                idForTesting={`first-name-input`}
              />
            </FormFieldComponent>
          </Column>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.lastNameLabel}
              isRequired
              errorMessage={form.validationResults.lastName.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.lastNamePlaceholder}
                value={form.values.lastName}
                onChange={(value) => form.setValue("lastName", value)}
                onBlur={() => form.validate("lastName")}
                hasError={!!form.validationResults.lastName.errorMessage}
                idForTesting={`last-name-input`}
              />
            </FormFieldComponent>
          </Column>
        </Row>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.phoneNumberLabel}
              isRequired
              errorMessage={form.validationResults.phoneNumber.errorMessage}
            >
              <PhoneNumberInputComponent
                placeholder={translations.form.phoneNumberPlaceholder}
                phoneNumber={form.values.phoneNumber}
                onChange={(value) => {
                  form.setValue("phoneNumber", value);
                }}
                onBlur={() => form.validate("phoneNumber")}
                hasError={!!form.validationResults.phoneNumber.errorMessage}
                idForTesting={`phone-number`}
              />
            </FormFieldComponent>
          </Column>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.alternativePhoneNumberLabel}
              errorMessage={
                form.validationResults.alternativePhoneNumber.errorMessage
              }
            >
              <PhoneNumberInputComponent
                placeholder={
                  translations.form.alternativePhoneNumberPlaceholder
                }
                phoneNumber={form.values.alternativePhoneNumber}
                onChange={(value) => {
                  form.setValue("alternativePhoneNumber", value);
                }}
                onBlur={() => form.validate("alternativePhoneNumber")}
                hasError={
                  !!form.validationResults.alternativePhoneNumber.errorMessage
                }
                idForTesting={`alternative-phone-number`}
              />
            </FormFieldComponent>
          </Column>
        </Row>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.passwordLabel}
              isRequired
              errorMessage={form.validationResults.password.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.passwordPlaceholder}
                value={form.values.password}
                onChange={(value) => form.setValue("password", value)}
                onBlur={() => form.validate("password")}
                type={"password"}
                hasError={!!form.validationResults.password.errorMessage}
                idForTesting={`password-input`}
              />
            </FormFieldComponent>
          </Column>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.passwordRepeatLabel}
              isRequired
              errorMessage={form.validationResults.passwordRepeat.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.passwordRepeatPlaceholder}
                value={form.values.passwordRepeat}
                onChange={(value) => form.setValue("passwordRepeat", value)}
                onBlur={() => form.validate("passwordRepeat")}
                type={"password"}
                hasError={!!form.validationResults.passwordRepeat.errorMessage}
                idForTesting={`password-repeat-input`}
              />
            </FormFieldComponent>
          </Column>
        </Row>
      </CardComponent>
      <ButtonComponent
        onClick={submitForm}
        type="primary"
        title={translations.form.submitButtonTitle}
        classNames={{ root: "mt-4" }}
        isDisabled={isRailyOfficerAddPending}
        idForTesting={`submit-button`}
      >
        {translations.form.submitButtonText}
      </ButtonComponent>
    </div>
  );
};
export default RailyOfficerAddComponent;
