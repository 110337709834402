import TaxiFleetPartnerAddRequest, {
  TaxiFleetPartnerAddRequestBody,
} from "./taxi-fleet-partner-add.request";

const createBody = (
  request: TaxiFleetPartnerAddRequest
): TaxiFleetPartnerAddRequestBody => {
  return {
    name: request.name,
    notes: request.notes,
  };
};

const taxiFleetPartnerAddRequestFactory = {
  createBody,
};

export default taxiFleetPartnerAddRequestFactory;
