import { FC, useState } from "react";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import CardComponent from "../../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../../common/components/form/field/form-field.component";
import Column from "../../../../../../common/components/grid/column";
import Row from "../../../../../../common/components/grid/row";
import MapComponent from "../../../../../../common/components/map/map.component";
import MapMarker from "../../../../../../common/components/map/types/map-marker";
import MapRoute from "../../../../../../common/components/map/types/map-route";
import useAbort from "../../../../../../common/hooks/use-abort";
import useDocumentTitle from "../../../../../../common/hooks/use-document-title";
import useOpen from "../../../../../../common/hooks/use-open";
import DelegationAddParams from "../../../../../../common/services/delegation/add/delegation-add-params";
import delegationService from "../../../../../../common/services/delegation/delegation.service";
import appTranslationsHelper from "../../../../../../languages/app-translations.helper";
import delegationTranslationsHelper from "../../../../../../languages/delegation-translations.helper";
import delegationAddParamsFactory from "../../factory/delegation-add-params.factory";
import DelegationAddFormData from "../../form/types/delegation-add-form-data";
import DelegationAddSuccessModalComponent from "../add-success-modal/delegation-add-success-modal.component";
import delegationAddSummaryHelper from "./delegation-add-summary.helper";
import DelegationAddSummaryRouteComponent from "./route/delegation-add-summary-route.component";
import delegationAddRouteFactory from "../../factory/delegation-add-route.factory";
import DelegationAddRouteWaypoint from "../../types/delegation-add-route-waypoint";
import notificationService from "../../../../../../common/utils/notification/notification.service";
import DelegationAddVehicleType from "../../types/delegation-add-vehicle-type";
import CargoCompanyMileageContractDetails from "../../../../../../common/services/cargo-company/mileage-contract/details/cargo-company-mileage-contract-details";

type DelegationAddSummaryProps = {
  workerName: string | undefined;
  companyName: string;
  formData: DelegationAddFormData;
  groupVehicleTypes: DelegationAddVehicleType[];
  restoreForm: () => void;
  validateForm: () => Promise<boolean>;
  mapRoutes: MapRoute[];
  mapWaypoints: DelegationAddRouteWaypoint[];
  mileageContract: CargoCompanyMileageContractDetails | null;
};

const DelegationAddSummaryComponent: FC<DelegationAddSummaryProps> = (
  props
) => {
  const translations =
    delegationTranslationsHelper.getDelegationAddTranslations().summary;

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.delegationAdd);

  const { isOpen, open, close } = useOpen();

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const formSubmitAbort = useAbort();

  const mapMarkers: MapMarker[] = delegationAddRouteFactory.createMapMarkers(
    props.mapWaypoints
  );

  const onAddDelegationSuccessModalCloseButtonClick = () => {
    props.restoreForm();
    close();
  };

  const onSubmitButtonClick = async () => {
    const isFormValid = await props.validateForm();

    if (!isFormValid) {
      return;
    }

    const params: DelegationAddParams = delegationAddParamsFactory.create(
      props.formData,
      props.groupVehicleTypes
    );

    setIsFormSubmitting(true);

    delegationService
      .add(params, formSubmitAbort.signal)
      .then(() => {
        open();
      })
      .catch(() => {
        notificationService.error(translations.failureAddNotificationLabel);
      })
      .finally(() => {
        setIsFormSubmitting(false);
      });
  };

  return (
    <>
      <CardComponent
        header={{ title: translations.headingLabel }}
        classNames={{ root: "delegation_add_summary" }}
      >
        <div className="delegation_add_summary__map_wrapper">
          <MapComponent
            markers={mapMarkers}
            autoFit
            autoFitOnUpdate
            routes={props.mapRoutes}
          />
        </div>
        <Row>
          <Column sm={6}>
            <FormFieldComponent label={translations.companyLabel}>
              <div
                title={delegationAddSummaryHelper.getCargoCompanyTitle(
                  props.companyName
                )}
              >
                {delegationAddSummaryHelper.getCargoCompanyLabel(
                  props.companyName
                )}
              </div>
            </FormFieldComponent>
            <FormFieldComponent label={translations.delegationCostLabel}>
              <div
                title={delegationAddSummaryHelper.getDelegationCostTitle(
                  props.formData
                )}
              >
                {delegationAddSummaryHelper.getDelegationCostLabel(
                  props.formData
                )}
              </div>
            </FormFieldComponent>
          </Column>
          <Column sm={6}>
            <FormFieldComponent label={translations.workerLabel}>
              <div
                title={delegationAddSummaryHelper.getWorkerTitle(
                  props.workerName
                )}
              >
                {delegationAddSummaryHelper.getWorkerLabel(props.workerName)}
              </div>
            </FormFieldComponent>
            <FormFieldComponent label={translations.dietCostLabel}>
              <div
                title={delegationAddSummaryHelper.getDietCostTitle(
                  props.formData
                )}
              >
                {delegationAddSummaryHelper.getDietCostLabel(props.formData)}
              </div>
            </FormFieldComponent>
          </Column>
        </Row>
        <FormFieldComponent label={translations.routeLabel}>
          <DelegationAddSummaryRouteComponent waypoints={props.mapWaypoints} />
        </FormFieldComponent>
        <Row>
          <Column sm={6}>
            <FormFieldComponent label={translations.startDateLabel}>
              <div
                title={delegationAddSummaryHelper.getStartDateTitle(
                  props.formData.dateFrom
                )}
              >
                {delegationAddSummaryHelper.getStartDateLabel(
                  props.formData.dateFrom
                )}
              </div>
            </FormFieldComponent>
          </Column>
          <Column sm={6}>
            <FormFieldComponent label={translations.endDateLabel}>
              <div
                title={delegationAddSummaryHelper.getEndDateTitle(
                  props.formData.dateTo
                )}
              >
                {delegationAddSummaryHelper.getEndDateLabel(
                  props.formData.dateTo
                )}
              </div>
            </FormFieldComponent>
          </Column>
        </Row>
        <Row>
          <Column sm={6}>
            <FormFieldComponent label={translations.prepayLabel}>
              <div
                title={delegationAddSummaryHelper.getPrepayTitle(
                  props.formData.prepay
                )}
              >
                {delegationAddSummaryHelper.getPrepayLabel(
                  props.formData.prepay
                )}
              </div>
            </FormFieldComponent>
            <FormFieldComponent label={translations.startedDayDietCostLabel}>
              <div
                title={delegationAddSummaryHelper.getStartedDayDietCostTitle(
                  props.formData.startedDayDietCost
                )}
              >
                {delegationAddSummaryHelper.getStartedDayDietCostLabel(
                  props.formData.startedDayDietCost
                )}
              </div>
            </FormFieldComponent>
            <FormFieldComponent label={translations.accommodationCostLabel}>
              <div
                title={delegationAddSummaryHelper.getAccommodationCostTitle(
                  props.formData.accommodationCost
                )}
              >
                {delegationAddSummaryHelper.getAccommodationCostLabel(
                  props.formData.accommodationCost
                )}
              </div>
            </FormFieldComponent>
            <FormFieldComponent label={translations.lumpSumLabel}>
              <div
                title={delegationAddSummaryHelper.getLumpSumCostTitle(
                  props.formData.localTravelCost
                )}
              >
                {delegationAddSummaryHelper.getLumpSumCostLabel(
                  props.formData.localTravelCost
                )}
              </div>
            </FormFieldComponent>
          </Column>
          <Column sm={6}>
            <FormFieldComponent label={translations.oneDayDelegationLabel}>
              <div
                title={delegationAddSummaryHelper.getOneDayDelegationTitle(
                  props.formData.oneDayDietCost
                )}
              >
                {delegationAddSummaryHelper.getOneDayDelegationLabel(
                  props.formData.oneDayDietCost
                )}
              </div>
            </FormFieldComponent>
            <FormFieldComponent label={translations.wholeDayDietCostLabel}>
              <div
                title={delegationAddSummaryHelper.getWholeDayDietCostTitle(
                  props.formData.wholeDayDietCost
                )}
              >
                {delegationAddSummaryHelper.getWholeDayDietCostLabel(
                  props.formData.wholeDayDietCost
                )}
              </div>
            </FormFieldComponent>
            <FormFieldComponent
              label={translations.accommodationBillsCostLabel}
            >
              <div
                title={delegationAddSummaryHelper.getAccommodationBillsTitle(
                  props.formData.accommodationBillsCost
                )}
              >
                {delegationAddSummaryHelper.getAccommodationBillsLabel(
                  props.formData.accommodationBillsCost
                )}
              </div>
            </FormFieldComponent>
            <FormFieldComponent label={translations.otherExpenses}>
              <div
                title={delegationAddSummaryHelper.getOtherExpensesTitle(
                  props.formData.otherExpenses
                )}
              >
                {delegationAddSummaryHelper.getOtherExpensesLabel(
                  props.formData.otherExpenses
                )}
              </div>
            </FormFieldComponent>
          </Column>
        </Row>
        <ButtonComponent
          onClick={onSubmitButtonClick}
          type="primary"
          classNames={{ root: "w-100" }}
          isLoading={isFormSubmitting}
          idForTesting="delegation-add-summary-submit-button"
          isDisabled={!props.mileageContract}
        >
          {!!props.mileageContract
            ? translations.submitButtonLabel
            : translations.submitButtonNoContractLabel}
        </ButtonComponent>
      </CardComponent>
      <DelegationAddSuccessModalComponent
        isOpen={isOpen}
        onCloseClick={onAddDelegationSuccessModalCloseButtonClick}
      />
    </>
  );
};

export default DelegationAddSummaryComponent;
