import DriverListLoadParams from "./driver-list-load-params";
import driverService from "../driver.service";
import DriverList from "./driver-list";
import DriverListError from "./driver-list-error";
import useAsyncData from "../../../hooks/use-async-data";

const useDriverList = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<DriverList>({ data: [], totalCount: 0 });

  const load = async (
    params: DriverListLoadParams,
    signal: AbortSignal
  ): Promise<void> => {
    setIsLoading(true);
    setIsError(false);

    try {
      const driverList = await driverService.getList(params, signal);

      setData(driverList);
    } catch (_error) {
      const error = _error as DriverListError;

      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    data,
    load,
  };
};

export default useDriverList;
