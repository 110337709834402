import { FC } from "react";
import DriverEditCompanyFormData from "../../../form/types/driver-edit-company-form-data";
import FormValidationResult from "../../../../../../../../common/utils/validation/types/form-validation-result";
import userTranslationsHelper from "../../../../../../../../languages/user-translations.helper";
import FormFieldComponent from "../../../../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../../../../common/components/form/input/input.component";
import Column from "../../../../../../../../common/components/grid/column";
import Row from "../../../../../../../../common/components/grid/row";

type DriverEditCompanyDataProps = {
  validateCompanyData: (
    formKey: keyof DriverEditCompanyFormData,
    value?: unknown
  ) => Promise<boolean>;
  onCompanyDataChange: (
    formKey: keyof DriverEditCompanyFormData,
    value: any
  ) => void;
  companyValidationResults: Record<
    keyof DriverEditCompanyFormData,
    FormValidationResult
  >;
  companyFormValues: DriverEditCompanyFormData;
  isTaxi?: boolean;
};

const DriverEditCompanyDataComponent: FC<DriverEditCompanyDataProps> = (
  props
) => {
  const translations =
    userTranslationsHelper.getDriverEditTranslations().form.companyData;

  return (
    <>
      <Row>
        <Column>
          <FormFieldComponent
            label={translations.nameLabel}
            isRequired
            classNames={{ root: "mt-0" }}
            errorMessage={props.companyValidationResults.name.errorMessage}
          >
            <InputComponent
              placeholder={translations.namePlaceholder}
              value={props.companyFormValues.name}
              onChange={(value) => props.onCompanyDataChange("name", value)}
              onBlur={() => {
                props.validateCompanyData("name");
              }}
              isDisabled={props.isTaxi}
              hasError={!!props.companyValidationResults.name.errorMessage}
              idForTesting="company-data-company-name-input"
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column xl={6}>
          <FormFieldComponent
            label={translations.taxNumberLabel}
            isRequired
            errorMessage={props.companyValidationResults.taxNumber.errorMessage}
          >
            <InputComponent
              placeholder={translations.taxNumberPlaceholder}
              value={props.companyFormValues.taxNumber}
              onChange={(value) =>
                props.onCompanyDataChange("taxNumber", value)
              }
              onBlur={() => {
                props.validateCompanyData("taxNumber");
              }}
              isDisabled={props.isTaxi}
              hasError={!!props.companyValidationResults.taxNumber.errorMessage}
              idForTesting="company-data-tax-number-input"
            />
          </FormFieldComponent>
        </Column>
        <Column xl={6}>
          <FormFieldComponent
            label={translations.companyIdLabel}
            errorMessage={props.companyValidationResults.companyId.errorMessage}
          >
            <InputComponent
              placeholder={translations.companyIdPlaceholder}
              value={props.companyFormValues.companyId}
              onChange={(value) =>
                props.onCompanyDataChange("companyId", value)
              }
              onBlur={() => {
                props.validateCompanyData("companyId");
              }}
              isDisabled={props.isTaxi}
              hasError={!!props.companyValidationResults.companyId.errorMessage}
              idForTesting="company-data-company-id-input"
            />
          </FormFieldComponent>
        </Column>
        <Column xl={6}>
          <FormFieldComponent
            label={translations.nationalCourtRegisterLabel}
            errorMessage={
              props.companyValidationResults.nationalCourtRegisterNumber
                .errorMessage
            }
          >
            <InputComponent
              placeholder={translations.nationalCourtRegisterPlaceholder}
              value={props.companyFormValues.nationalCourtRegisterNumber}
              onChange={(value) =>
                props.onCompanyDataChange("nationalCourtRegisterNumber", value)
              }
              onBlur={() => {
                props.validateCompanyData("nationalCourtRegisterNumber");
              }}
              isDisabled={props.isTaxi}
              hasError={
                !!props.companyValidationResults.nationalCourtRegisterNumber
                  .errorMessage
              }
              idForTesting="company-data-national-court-register-input"
            />
          </FormFieldComponent>
        </Column>
      </Row>
    </>
  );
};

export default DriverEditCompanyDataComponent;
