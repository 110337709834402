import { FormValidationDefinition } from "../../../../../../common/components/form/use-form";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../../../common/utils/validation/types/form-validation-result";
import DriverEditTaxiCorporationFormData from "../factory/driver-edit-taxi-corporation-form-data";
import DriverEditFormOfEmploymentType from "../types/driver-edit-form-of-employment-type";
import driverEditTaxiCorporationFormValidationService from "./driver-edit-taxi-corporation-form-validation.service";

const getDefaultTaxiCorporationData = (): DriverEditTaxiCorporationFormData => {
  return {
    id: "",
    apartmentNumber: "",
    country: "",
    description: "",
    houseNumber: "",
    street: "",
    town: "",
    zipCode: "",
    companyId: "",
    name: "",
    nationalCourtRegisterNumber: "",
    taxNumber: "",
    formOfEmployment: null,
    taxiCorporation: null,
    fleetPartner: null,
  };
};

const getTaxiCorporationValidationDefinition =
  (): FormValidationDefinition<DriverEditTaxiCorporationFormData> => {
    return {
      id: () => formValidationService.ok(),
      fleetPartner: (values) =>
        driverEditTaxiCorporationFormValidationService.validateFleetPartner(
          values.fleetPartner
        ),
      formOfEmployment: (values) =>
        driverEditTaxiCorporationFormValidationService.validateFormOfEmployment(
          values.formOfEmployment
        ),
      taxiCorporation: (values) =>
        driverEditTaxiCorporationFormValidationService.validateTaxiCorporation(
          values.taxiCorporation
        ),
      companyId: (values) =>
        values.formOfEmployment === DriverEditFormOfEmploymentType.B2B
          ? driverEditTaxiCorporationFormValidationService.validateCompanyId(
              values.companyId
            )
          : formValidationService.ok(),
      name: (values) =>
        values.formOfEmployment === DriverEditFormOfEmploymentType.B2B
          ? driverEditTaxiCorporationFormValidationService.validateName(
              values.name
            )
          : formValidationService.ok(),
      nationalCourtRegisterNumber: (values) =>
        values.formOfEmployment === DriverEditFormOfEmploymentType.B2B
          ? driverEditTaxiCorporationFormValidationService.validateNationalCourtRegister(
              values.nationalCourtRegisterNumber
            )
          : formValidationService.ok(),

      taxNumber: (values) =>
        values.formOfEmployment === DriverEditFormOfEmploymentType.B2B
          ? driverEditTaxiCorporationFormValidationService.validateTaxNumber(
              values.taxNumber
            )
          : formValidationService.ok(),

      street: (values) =>
        values.formOfEmployment === DriverEditFormOfEmploymentType.B2B
          ? driverEditTaxiCorporationFormValidationService.validateStreet(
              values.street
            )
          : formValidationService.ok(),

      houseNumber: (values) =>
        values.formOfEmployment === DriverEditFormOfEmploymentType.B2B
          ? driverEditTaxiCorporationFormValidationService.validateHouseNumber(
              values.houseNumber
            )
          : formValidationService.ok(),

      apartmentNumber: (values) =>
        values.formOfEmployment === DriverEditFormOfEmploymentType.B2B
          ? driverEditTaxiCorporationFormValidationService.validateApartmentNumber(
              values.apartmentNumber
            )
          : formValidationService.ok(),

      town: (values) =>
        values.formOfEmployment === DriverEditFormOfEmploymentType.B2B
          ? driverEditTaxiCorporationFormValidationService.validateTown(
              values.town
            )
          : formValidationService.ok(),

      country: (values) =>
        values.formOfEmployment === DriverEditFormOfEmploymentType.B2B
          ? driverEditTaxiCorporationFormValidationService.validateCountry(
              values.country
            )
          : formValidationService.ok(),

      zipCode: (values) =>
        values.formOfEmployment === DriverEditFormOfEmploymentType.B2B
          ? driverEditTaxiCorporationFormValidationService.validateZipCode(
              values.zipCode
            )
          : formValidationService.ok(),

      description: (values) =>
        values.formOfEmployment === DriverEditFormOfEmploymentType.B2B
          ? driverEditTaxiCorporationFormValidationService.validateDescription(
              values.description
            )
          : formValidationService.ok(),
    };
  };

const getTaxiCorporationValidationResults = (
  taxiCorporationFormData: DriverEditTaxiCorporationFormData[]
): Record<keyof DriverEditTaxiCorporationFormData, FormValidationResult>[] => {
  return taxiCorporationFormData.map(() => {
    return {
      id: formValidationService.ok(),
      apartmentNumber: formValidationService.ok(),
      companyId: formValidationService.ok(),
      country: formValidationService.ok(),
      description: formValidationService.ok(),
      fleetPartner: formValidationService.ok(),
      formOfEmployment: formValidationService.ok(),
      houseNumber: formValidationService.ok(),
      name: formValidationService.ok(),
      nationalCourtRegisterNumber: formValidationService.ok(),
      street: formValidationService.ok(),
      taxiCorporation: formValidationService.ok(),
      taxNumber: formValidationService.ok(),
      town: formValidationService.ok(),
      zipCode: formValidationService.ok(),
    };
  });
};

const driverEditTaxiCorporationHelper = {
  getDefaultTaxiCorporationData,
  getTaxiCorporationValidationDefinition,
  getTaxiCorporationValidationResults,
};

export default driverEditTaxiCorporationHelper;
