import { values } from "lodash";
import { FormValidationDefinition } from "../../../../../common/components/form/use-form";
import taxiTranslationsHelper from "../../../../../languages/taxi-translations.helper";
import taxiFleetPartnerEditFormValidationService from "./taxi-fleet-partner-edit-form-validation.service";
import TaxiFleetPartnerEditFormData from "./types/taxi-fleet-partner-edit-form-data";

const getEmptyFormData = (): TaxiFleetPartnerEditFormData => {
  return {
    name: "",
    notes: "",
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<TaxiFleetPartnerEditFormData> => {
    return {
      name: (values) =>
        taxiFleetPartnerEditFormValidationService.validateName(values.name),
      notes: (values) =>
        taxiFleetPartnerEditFormValidationService.validateNotes(values.notes),
    };
  };

const getHeadingLabel = (fleetPartnerName: string | undefined) => {
  const translations =
    taxiTranslationsHelper.getTaxiFleetPartnerEditTranslations();

  return `${translations.header.headingLabel}${
    fleetPartnerName ? `: ${fleetPartnerName}` : ""
  }`;
};

const taxiFleetPartnerEditFormHelper = {
  getEmptyFormData,
  getValidationDefinition,
  getHeadingLabel,
};

export default taxiFleetPartnerEditFormHelper;
