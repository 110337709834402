import UserRole from "../../../../../common/types/user-role";
import UserPermissions from "../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../common/utils/user/permissions/user-permissions-definition";

export type OrderDetailsUserPermissionOption =
  | "hasAccessToCancelOrder"
  | "hasAccessToCancelOrderImmediately"
  | "hasAccessToCancelOrderAsTaxi"
  | "hasAccessToSendOrderCancelRequest"
  | "hasAccessToManageDriverAssignment"
  | "hasAccessToApproveChangesAsDispatcher"
  | "hasAccessToApproveChangesAsOperator"
  | "hasAccessToApproveChangesAsDealer"
  | "hasAccessToEditTaxi"
  | "hasAccessToEditOrderRoute"
  | "hasAccessToEditOrderRouteAsTaxi"
  | "hasAccessToEditCargoExternalId"
  | "hasAccessToRedirectToDriverPlan"
  | "hasAccessToAddOrderForwarding"
  | "hasAccessToEditOrderForwarding"
  | "hasAccessToCancelOrderForwarding";

export type OrderDetailsUserPermissionsDefinition =
  UserPermissionsDefinition<OrderDetailsUserPermissionOption>;

export type OrderDetailsUserPermissions =
  UserPermissions<OrderDetailsUserPermissionOption>;

const orderDetailsUserPermissionDefinition: OrderDetailsUserPermissionsDefinition =
  {
    hasAccessToCancelOrder: [UserRole.ADMIN, UserRole.OPERATOR],
    hasAccessToCancelOrderImmediately: [UserRole.ADMIN, UserRole.OPERATOR],
    hasAccessToCancelOrderAsTaxi: [UserRole.DEALER, UserRole.TAXI_OFFICER],
    hasAccessToSendOrderCancelRequest: [UserRole.ADMIN, UserRole.DISPATCHER],
    hasAccessToManageDriverAssignment: [
      UserRole.ADMIN,
      UserRole.OPERATOR,
      UserRole.DEALER,
    ],
    hasAccessToApproveChangesAsDealer: [UserRole.DEALER],
    hasAccessToApproveChangesAsDispatcher: [UserRole.DISPATCHER],
    hasAccessToApproveChangesAsOperator: [UserRole.OPERATOR],
    hasAccessToEditTaxi: [
      UserRole.ADMIN,
      UserRole.OPERATOR,
      UserRole.DISPATCHER,
    ],
    hasAccessToEditOrderRoute: [
      UserRole.OPERATOR,
      UserRole.DISPATCHER,
      UserRole.ADMIN,
    ],
    hasAccessToEditOrderRouteAsTaxi: [UserRole.DEALER],
    hasAccessToEditCargoExternalId: [
      UserRole.ADMIN,
      UserRole.DISPATCHER,
      UserRole.RAILY_OFFICER,
      UserRole.OPERATOR,
    ],
    hasAccessToRedirectToDriverPlan: [
      UserRole.ADMIN,
      UserRole.CARGO_OFFICER,
      UserRole.RAILY_OFFICER,
      UserRole.OPERATOR,
      UserRole.DEALER,
    ],
    hasAccessToAddOrderForwarding: [
      UserRole.ADMIN,
      UserRole.OPERATOR,
      UserRole.DEALER,
      UserRole.CARGO_OFFICER,
      UserRole.RAILY_OFFICER,
    ],
    hasAccessToEditOrderForwarding: [
      UserRole.ADMIN,
      UserRole.OPERATOR,
      UserRole.DEALER,
      UserRole.CARGO_OFFICER,
      UserRole.RAILY_OFFICER,
    ],
    hasAccessToCancelOrderForwarding: [
      UserRole.ADMIN,
      UserRole.OPERATOR,
      UserRole.DEALER,
      UserRole.CARGO_OFFICER,
      UserRole.RAILY_OFFICER,
    ],
  };

export default orderDetailsUserPermissionDefinition;
