import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CargoDispatchRouteParams from "../../../common/routes/types/cargo-dispatch-route-params";
import { useAppContext } from "../../../../../context/app.context";
import useAbort from "../../../../../common/hooks/use-abort";
import CargoDispatchFormData from "../common/types/cargo-dispatch-form-data";
import cargoDispatchFormHelper from "../common/cargo-dispatch-form.helper";
import useForm from "../../../../../common/components/form/use-form";
import cargoTranslationsHelper from "../../../../../languages/cargo-translations.helper";
import cargoBreadcrumbsHelper from "../../../common/breadcrumbs/cargo-breadcrumbs.helper";
import notificationService from "../../../../../common/utils/notification/notification.service";
import cargoRoutesHelper from "../../../common/routes/cargo-routes.helper";
import CargoCompanyDispatchAddParams from "../../../../../common/services/cargo-company/dispatch/add/cargo-company-dispatch-add-params";
import cargoCompanyDispatchService from "../../../../../common/services/cargo-company/dispatch/cargo-company-dispatch.service";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import Row from "../../../../../common/components/grid/row";
import Column from "../../../../../common/components/grid/column";
import CardComponent from "../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../common/components/form/input/input.component";
import ButtonComponent from "../../../../../common/components/button/button.component";
import useCargoCompanyDetails from "../../../../../common/services/cargo-company/cargo-company/details/use-cargo-company-details";

type CargoDispatchAddProps = {};

const CargoDispatchAddComponent: FC<CargoDispatchAddProps> = () => {
  const { cargoCompanyUuid } = useParams<CargoDispatchRouteParams>();
  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const [isDispatchAddPending, setIsDispatchAddPending] = useState(false);

  const cargoCompany = useCargoCompanyDetails();
  const cargoCompanyAbort = useAbort();

  const cargoCompanyDispatchAddAbort = useAbort();
  const navigate = useNavigate();

  const form = useForm<CargoDispatchFormData>({
    emptyValues: cargoDispatchFormHelper.getEmptyFormData(),
    validationDefinition: cargoDispatchFormHelper.getValidationDefinition(),
  });

  const translations = cargoTranslationsHelper.getDispatchTranslations();

  useEffect(() => {
    if (!cargoCompanyUuid) return;

    cargoCompany.load(
      {
        cargoCompanyUuid,
      },
      cargoCompanyAbort.signal
    );

    return () => cargoCompanyAbort.revoke();
  }, [cargoCompanyUuid]);

  const cargoCompanyName: string | undefined = useMemo(() => {
    if (!cargoCompany.data) return;

    return cargoCompany.data.displayName;
  }, [cargoCompany.data]);

  useEffect(() => {
    const breadcrumbs = cargoBreadcrumbsHelper.getDispatchAddBreadcrumbs({
      cargoCompanyName: cargoCompanyName ?? "",
      cargoCompanyUuid: cargoCompanyUuid!,
    });

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, cargoCompanyName]);

  const onCargoDispatchAddSuccess = () => {
    notificationService.success(translations.add.successAddNotificationLabel);
    navigate(
      cargoRoutesHelper.getDispatchListingRoute({
        cargoCompanyUuid: cargoCompanyUuid!,
      })
    );
  };

  const onCargoDispatchAddFailure = () => {
    notificationService.error(translations.add.failureAddNotificationLabel);
  };

  const addDispatch = async () => {
    setIsDispatchAddPending(true);

    const params: CargoCompanyDispatchAddParams = {
      companyUuid: cargoCompanyUuid!,
      name: form.values.name,
      displayName: form.values.displayName,
      address: form.values.address,
    };

    try {
      await cargoCompanyDispatchService.add(
        params,
        cargoCompanyDispatchAddAbort.signal
      );

      onCargoDispatchAddSuccess();
    } catch {
      onCargoDispatchAddFailure();
    } finally {
      setIsDispatchAddPending(false);
    }
  };

  const onSubmitButtonClick = async () => {
    const isFormValid = await form.validateAll();

    if (!isFormValid) return;

    addDispatch();
  };

  return (
    <>
      <HeadingComponent title={translations.add.header.headingText} />
      <Row>
        <Column xl={8}>
          <CardComponent>
            <Row>
              <Column xl={6}>
                <FormFieldComponent
                  label={translations.form.nameLabel}
                  isRequired
                  errorMessage={form.validationResults.name.errorMessage}
                >
                  <InputComponent
                    value={form.values.name}
                    onChange={(value) => form.setValue("name", value)}
                    placeholder={translations.form.namePlaceholder}
                    hasError={!!form.validationResults.name.errorMessage}
                    onBlur={() => form.validate("name")}
                    idForTesting="cargo-dispatch-add-name"
                  />
                </FormFieldComponent>
              </Column>
              <Column xl={6}>
                <FormFieldComponent
                  label={translations.form.displayNameLabel}
                  isRequired
                  errorMessage={form.validationResults.displayName.errorMessage}
                >
                  <InputComponent
                    value={form.values.displayName}
                    onChange={(value) => form.setValue("displayName", value)}
                    placeholder={translations.form.displayNamePlaceholder}
                    hasError={!!form.validationResults.displayName.errorMessage}
                    onBlur={() => form.validate("displayName")}
                    idForTesting="carg-dispatch-add-display-name"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column xl={6}>
                <FormFieldComponent
                  label={translations.form.addressLabel}
                  isRequired
                  errorMessage={form.validationResults.address.errorMessage}
                >
                  <InputComponent
                    value={form.values.address}
                    onChange={(value) => form.setValue("address", value)}
                    placeholder={translations.form.addressPlaceholder}
                    hasError={!!form.validationResults.address.errorMessage}
                    onBlur={() => form.validate("address")}
                    idForTesting="cargo-dispatch-add-address"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <ButtonComponent
              onClick={onSubmitButtonClick}
              type="primary"
              isDisabled={isDispatchAddPending}
              idForTesting="cargo-dispatch-add-sumbit-button"
              classNames={{ root: "mt-2" }}
              title={translations.add.submitButtonTitle}
            >
              {translations.add.submitButtonLabel}
            </ButtonComponent>
          </CardComponent>
        </Column>
      </Row>
    </>
  );
};

export default CargoDispatchAddComponent;
