import { FC, useEffect, useMemo, useState } from "react";
import FormValidationResult from "../../../../../../../common/utils/validation/types/form-validation-result";
import DriverAddTaxiCorporationFormData from "../../form/types/driver-add-taxi-corporation-form-data";
import { useAppContext } from "../../../../../../../context/app.context";
import userTranslationsHelper from "../../../../../../../languages/user-translations.helper";
import DriverAddTaxiCorporationSelectOption from "../../types/driver-add-taxi-corporation-select-option";
import DriverAddUserFleetPartnerSelectOption from "../../types/driver-add-user-fleet-partner-select-option";
import useTaxiCorporationList from "../../../../../../../common/services/taxi-corporation/list/use-taxi-corporation-list";
import useAbort from "../../../../../../../common/hooks/use-abort";
import useTaxiFleetPartnerList from "../../../../../../../common/services/taxi-fleet-partner/list/use-taxi-fleet-partner-list";
import TaxiFleetPartnerListLoadParams from "../../../../../../../common/services/taxi-fleet-partner/list/taxi-fleet-partner-list-load-params";
import driverAddCitizenshipTypeFactory from "../../factory/driver-add-user-data.factory";
import CardComponent from "../../../../../../../common/components/card/card.component";
import Row from "../../../../../../../common/components/grid/row";
import Column from "../../../../../../../common/components/grid/column";
import FormFieldComponent from "../../../../../../../common/components/form/field/form-field.component";
import SingleSelectComponent from "../../../../../../../common/components/form/select/single-select/single-select.component";
import DriverAddFormOfEmploymentTypeSelectOption from "../../types/driver-add-form-of-employment-type-select-option";
import ButtonComponent from "../../../../../../../common/components/button/button.component";
import RailyDriverAddCompanyDataComponent from "../company/raily-driver-add-company.component";

type DriverAddTaxiCorporationProps = {
  validateTaxiCorporation: (
    formKey: keyof DriverAddTaxiCorporationFormData,
    value?: unknown
  ) => Promise<boolean>;
  onTaxiCorporationChange: (
    formKey: keyof DriverAddTaxiCorporationFormData,
    value: any
  ) => void;
  taxiCorporationValidationResults: Record<
    keyof DriverAddTaxiCorporationFormData,
    FormValidationResult
  >;
  taxiCorporationFormValues: DriverAddTaxiCorporationFormData;
  isCargoDataSectionVisible: boolean;
  index: number;
  addTaxiCorporation: () => void;
  deleteTaxiCorporation: (indexToRemove: number) => void;
  formTaxiCorporationsData: DriverAddTaxiCorporationFormData[];
};

const DriverAddTaxiCorporationComponent: FC<DriverAddTaxiCorporationProps> = (
  props
) => {
  const { selectedAppLanguage } = useAppContext();

  const translations =
    userTranslationsHelper.getDriverAddTranslations().form.taxiCorporationData;

  const [taxiCorporationsSelectOptions, setTaxiCorporationsSelectOptions] =
    useState<DriverAddTaxiCorporationSelectOption[]>([]);

  const [fleetPartnerSelectOptions, setFleetPartnerSelectOptions] = useState<
    DriverAddUserFleetPartnerSelectOption[]
  >([]);

  const taxiCorporationList = useTaxiCorporationList();
  const taxiCorporationListAbort = useAbort();

  const taxiFleetPartnerList = useTaxiFleetPartnerList();
  const taxiFleetPartnerListAbort = useAbort();

  const loadTaxiFleetPartner = async () => {
    if (!props.taxiCorporationFormValues.taxiCorporation?.uuid) {
      return;
    }

    const params: TaxiFleetPartnerListLoadParams = {
      taxiCorporationUuid:
        props.taxiCorporationFormValues.taxiCorporation?.uuid,
    };

    taxiFleetPartnerList.load(params, taxiFleetPartnerListAbort.signal);
  };

  useEffect(() => {
    taxiCorporationList.load({}, taxiCorporationListAbort.signal);
  }, []);

  useEffect(() => {
    if (props.taxiCorporationFormValues.taxiCorporation?.uuid) {
      loadTaxiFleetPartner();
    } else {
      setFleetPartnerSelectOptions([]);
    }
  }, [props.taxiCorporationFormValues.taxiCorporation?.uuid]);

  useEffect(() => {
    if (taxiCorporationList.data) {
      setTaxiCorporationsSelectOptions(
        taxiCorporationList.data.data.map((corporation) => ({
          label: corporation.displayName,
          value: corporation,
        }))
      );
    }
  }, [taxiCorporationList.data]);

  useEffect(() => {
    if (taxiFleetPartnerList.data) {
      setFleetPartnerSelectOptions(
        taxiFleetPartnerList.data.map((partner) => ({
          label: partner.name,
          value: partner,
        }))
      );
    }
  }, [taxiFleetPartnerList.data]);

  const formOfEmploymentSelectOptions = useMemo(
    driverAddCitizenshipTypeFactory.createFormOfEmploymentSelectOptions,
    [selectedAppLanguage]
  );

  const formOfEmploymentSelectOption = props.taxiCorporationFormValues
    .formOfEmployment
    ? formOfEmploymentSelectOptions.find(
        (option) =>
          option.value === props.taxiCorporationFormValues.formOfEmployment
      ) || null
    : null;

  const fleetPartnerSelectOption = props.taxiCorporationFormValues.fleetPartner
    ? fleetPartnerSelectOptions.find(
        (option) =>
          option.value.uuid ===
          props.taxiCorporationFormValues.fleetPartner?.uuid
      ) || null
    : null;

  const taxiCorporationSelectOption = props.taxiCorporationFormValues
    .taxiCorporation
    ? {
        label: props.taxiCorporationFormValues.taxiCorporation.displayName,
        value: props.taxiCorporationFormValues.taxiCorporation,
      }
    : null;

  const handleTaxiCorporationChange = async (
    value: DriverAddTaxiCorporationSelectOption | null
  ) => {
    if (!value) return;

    const isValid = await props.validateTaxiCorporation(
      "taxiCorporation",
      value.value
    );
    if (isValid) {
      props.onTaxiCorporationChange("taxiCorporation", value.value);
    }
  };

  const handleFormOfEmploymentChange = async (
    value: DriverAddFormOfEmploymentTypeSelectOption | null
  ) => {
    if (!value) return;

    const isValid = await props.validateTaxiCorporation(
      "formOfEmployment",
      value.value
    );
    if (isValid) {
      props.onTaxiCorporationChange("formOfEmployment", value.value);
    }
  };

  const handleFleetPartnerChange = async (
    value: DriverAddUserFleetPartnerSelectOption | null
  ) => {
    if (!value) return;

    const isValid = await props.validateTaxiCorporation(
      "fleetPartner",
      value.value
    );
    if (isValid) {
      props.onTaxiCorporationChange("fleetPartner", value.value);
    }
  };

  useEffect(() => {
    if (taxiCorporationList.data) {
      const selectedCorporationUuids = props.formTaxiCorporationsData.map(
        (item) => item.taxiCorporation?.uuid
      );

      setTaxiCorporationsSelectOptions(
        taxiCorporationList.data.data
          .filter(
            (corporation) =>
              !selectedCorporationUuids.includes(corporation.uuid)
          )
          .map((corporation) => ({
            label: corporation.displayName,
            value: corporation,
          }))
      );
    }
  }, [taxiCorporationList.data, props.formTaxiCorporationsData]);

  return (
    <CardComponent header={{ title: translations.headingText }}>
      <Row>
        <Column lg={4}>
          <FormFieldComponent
            label={translations.formOfEmploymentLabel}
            isRequired
            errorMessage={
              props.taxiCorporationValidationResults.formOfEmployment
                .errorMessage
            }
          >
            <SingleSelectComponent
              placeholder={translations.formOfEmploymentPlaceholder}
              value={formOfEmploymentSelectOption}
              options={formOfEmploymentSelectOptions}
              onChange={handleFormOfEmploymentChange}
              hasError={
                !!props.taxiCorporationValidationResults.formOfEmployment
                  .errorMessage
              }
              idForTesting={`user-data-form-of-employment-select`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={4}>
          <FormFieldComponent
            label={translations.taxiCorporationLabel}
            isRequired
            errorMessage={
              props.taxiCorporationValidationResults.taxiCorporation
                .errorMessage
            }
          >
            <SingleSelectComponent
              placeholder={translations.taxiCorporationPlaceholder}
              value={taxiCorporationSelectOption}
              options={taxiCorporationsSelectOptions}
              isSearchable
              onChange={handleTaxiCorporationChange}
              hasError={
                !!props.taxiCorporationValidationResults.taxiCorporation
                  .errorMessage
              }
              idForTesting={`user-data-taxi-corporation-select`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={4}>
          <FormFieldComponent
            label={translations.fleetPartnerLabel}
            errorMessage={
              props.taxiCorporationValidationResults.fleetPartner.errorMessage
            }
          >
            <SingleSelectComponent
              placeholder={translations.fleetPartnerPlaceholder}
              value={fleetPartnerSelectOption}
              options={fleetPartnerSelectOptions}
              onChange={handleFleetPartnerChange}
              isClearable
              isLoading={
                props.taxiCorporationFormValues.taxiCorporation?.uuid
                  ? taxiFleetPartnerList.isLoading
                  : false
              }
              isDisabled={
                taxiFleetPartnerList.isError ||
                !props.taxiCorporationFormValues.taxiCorporation
              }
              hasError={
                !!props.taxiCorporationValidationResults.fleetPartner
                  .errorMessage
              }
              idForTesting={`user-data-fleet-partner-select`}
            />
          </FormFieldComponent>
        </Column>
      </Row>
      {props.isCargoDataSectionVisible && (
        <Row>
          <Column withPaddings>
            <RailyDriverAddCompanyDataComponent
              onCompanyDataChange={props.onTaxiCorporationChange}
              validateCompanyData={props.validateTaxiCorporation}
              companyFormValues={props.taxiCorporationFormValues}
              companyValidationResults={props.taxiCorporationValidationResults}
            />
          </Column>
        </Row>
      )}
      <div className="driver_add_buttons">
        <ButtonComponent
          onClick={() => {
            props.deleteTaxiCorporation(props.index);
          }}
          type="danger"
          classNames={{ root: "mt-2" }}
          idForTesting={`delete-employment-data-button`}
          isDisabled={props.formTaxiCorporationsData.length < 2}
          title={translations.deleteEmploymentDataTitle}
        >
          {translations.deleteEmploymentDataText}
        </ButtonComponent>
        {props.formTaxiCorporationsData[
          props.formTaxiCorporationsData.length - 1
        ] && (
          <ButtonComponent
            onClick={() => {
              props.addTaxiCorporation();
            }}
            type="primary"
            classNames={{ root: "mt-2" }}
            idForTesting={`add-new-employment-data-button`}
            isDisabled={
              !props.taxiCorporationFormValues.taxiCorporation ||
              !props.taxiCorporationFormValues.formOfEmployment
            }
            title={translations.addNewEmploymentDataTitle}
          >
            {translations.addNewEmploymentDataText}
          </ButtonComponent>
        )}
      </div>
    </CardComponent>
  );
};

export default DriverAddTaxiCorporationComponent;
