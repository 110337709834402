import ListingFilterDefinition, {
  ListingFilterDefinitionOption,
  ListingFilterDefinitionOptionBadgeData,
  ListingFilterDefinitionOptionSelectData,
  ListingFilterDefinitionOptionSelectDataType,
} from "../../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition, {
  ListingSortDefinitionOption,
} from "../../../../../common/components/listing/filter/types/listing-sort-definition";
import cargoTranslationsHelper from "../../../../../languages/cargo-translations.helper";
import CargoCargoTaxiContractListingFilter, {
  CargoCargoTaxiContractListingActiveFilter,
  CargoCargoTaxiContractListingCargoCompanyFilter,
  CargoCargoTaxiContractListingTaxiCorporationFilter,
} from "../common/types/cargo-cargo-taxi-contract-listing-filter";
import CargoCargoTaxiContractListingFilterType from "../common/types/cargo-cargo-taxi-contract-listing-filter-type";
import CargoCargoTaxiContractListingSortKey from "../common/types/cargo-cargo-taxi-contract-listing-sort-key";

const cargoCargoTaxiContractListingFilterHelper = () => {
  const translations =
    cargoTranslationsHelper.getCargoTaxiContractListingTranslations();

  const createBadgeData = (
    value: string,
    textTemplate: string,
    titleTemplate: string
  ): ListingFilterDefinitionOptionBadgeData => {
    return {
      text: textTemplate.replace("#{query}", value),
      title: titleTemplate.replace("#{query}", value),
    };
  };

  const getBadgeForActive = (
    filterValue: CargoCargoTaxiContractListingActiveFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      String(filterValue),
      filterValue
        ? translations.filters.activeFilterLabel
        : translations.filters.inactiveFilterLabel,
      filterValue
        ? translations.filters.activeFilterTitle
        : translations.filters.inactiveFilterTitle
    );
  };

  const getBadgeForCargoCompany = (
    filterValue: CargoCargoTaxiContractListingCargoCompanyFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.cargoCompanyFilterLabel,
      translations.filters.cargoCompanyFilterTitle
    );
  };

  const getBadgeForTaxiCorporation = (
    filterValue: CargoCargoTaxiContractListingTaxiCorporationFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.taxiCorporationFilterLabel,
      translations.filters.taxiCorporationFilterTitle
    );
  };

  const getSelectDataForActive =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DEFAULT,
        options: [
          {
            label: translations.filters.search.searchActiveLabel,
            value: true,
          },
          {
            label: translations.filters.search.searchInactiveLabel,
            value: false,
          },
        ],
      };
    };

  const getSelectDataForCargoCompany = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label:
            translations.filters.search.searchByCargoCompanyTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForTaxiCorporation = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label:
            translations.filters.search.searchByTaxiCorporationTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataByType = (
    filterType: CargoCargoTaxiContractListingFilterType,
    query: string
  ) => {
    const options: Record<
      CargoCargoTaxiContractListingFilterType,
      (query: string) => ListingFilterDefinitionOptionSelectData
    > = {
      [CargoCargoTaxiContractListingFilterType.ACTIVE]: getSelectDataForActive,
      [CargoCargoTaxiContractListingFilterType.CARGO_COMPANY]:
        getSelectDataForCargoCompany,
      [CargoCargoTaxiContractListingFilterType.TAXI_CORPORATION]:
        getSelectDataForTaxiCorporation,
    };

    const result = options[filterType](query);

    return result;
  };

  const getBadgeDefinitionByType = (
    filterType: CargoCargoTaxiContractListingFilterType,
    filterValue: CargoCargoTaxiContractListingFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const options: Record<
      CargoCargoTaxiContractListingFilterType,
      () => ListingFilterDefinitionOptionBadgeData
    > = {
      [CargoCargoTaxiContractListingFilterType.ACTIVE]: () =>
        getBadgeForActive(
          filterValue as CargoCargoTaxiContractListingActiveFilter["value"]
        ),
      [CargoCargoTaxiContractListingFilterType.CARGO_COMPANY]: () =>
        getBadgeForCargoCompany(
          filterValue as CargoCargoTaxiContractListingCargoCompanyFilter["value"]
        ),
      [CargoCargoTaxiContractListingFilterType.TAXI_CORPORATION]: () =>
        getBadgeForTaxiCorporation(
          filterValue as CargoCargoTaxiContractListingTaxiCorporationFilter["value"]
        ),
    };

    return options[filterType]();
  };

  const getFilterDefinition =
    (): ListingFilterDefinition<CargoCargoTaxiContractListingFilter> => {
      return {
        options: getFilterDefinitionOptions(),
      };
    };

  const getFilterDefinitionByType = (
    filterType: CargoCargoTaxiContractListingFilterType
  ): ListingFilterDefinitionOption<CargoCargoTaxiContractListingFilter> => {
    return {
      getSelectData: (query: string) => getSelectDataByType(filterType, query),
      getBadgeData: (value) => getBadgeDefinitionByType(filterType, value),
      filterType,
    };
  };

  const getFilterDefinitionOptions =
    (): ListingFilterDefinitionOption<CargoCargoTaxiContractListingFilter>[] => {
      return Object.values(CargoCargoTaxiContractListingFilterType).map(
        getFilterDefinitionByType
      );
    };

  const getSortDefinition = (): ListingSortDefinition => {
    return {
      options: getSortDefinitionOptions(),
    };
  };

  const getSortDefinitionOptions = (): ListingSortDefinitionOption[] => {
    return [
      {
        label: translations.sort.distanceRateAscLabel,
        value: CargoCargoTaxiContractListingSortKey.DISTANCE_RATE_ASC,
      },
      {
        label: translations.sort.distanceRateDescLabel,
        value: CargoCargoTaxiContractListingSortKey.DISTANCE_RATE_DESC,
      },
      {
        label: translations.sort.validSinceAscLabel,
        value: CargoCargoTaxiContractListingSortKey.VALID_SINCE_ASC,
      },
      {
        label: translations.sort.validSinceDescLabel,
        value: CargoCargoTaxiContractListingSortKey.VALID_SINCE_DESC,
      },
      {
        label: translations.sort.validToAscLabel,
        value: CargoCargoTaxiContractListingSortKey.VALID_TO_ASC,
      },
      {
        label: translations.sort.validToDescLabel,
        value: CargoCargoTaxiContractListingSortKey.VALID_TO_DESC,
      },
    ];
  };

  return {
    getFilterDefinition,
    getSortDefinition,
  };
};

export default cargoCargoTaxiContractListingFilterHelper;
