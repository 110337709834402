import { FC, useEffect, useMemo, useState } from "react";
import useIsComponentMounted from "../../../common/hooks/use-is-component-mounted";
import routeTranslationsHelper from "../../../languages/route-translations.helper";
import useRouteActiveListingUserPermissions from "./common/user-permissions/use-route-active-listing-user-permissions";
import useDocumentTitle from "../../../common/hooks/use-document-title";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import { useAppContext } from "../../../context/app.context";
import routeBreadcrumbsHelper from "../common/breadcrumbs/route-breadcrumbs.helper";
import useRouteActiveList from "../../../common/services/route/active-list/use-route-active-list";
import useRouteActiveListingFilterDao from "./filters/use-route-active-listing-filter.dao";
import RouteActiveListingFilter from "./common/types/route-active-listing-filter";
import RouteActiveListingSortKey from "./common/types/route-active-listing-sort-key";
import { RouteActiveListItem } from "../../../common/services/route/active-list/route-active-list";
import routeActiveListingFilteringService from "./common/route-active-listing-filtering.service";
import routeActiveListingSortingService from "./common/route-active-listing-sorting.service";
import useClientSidePagination from "../../../common/hooks/use-clientside-pagination";
import useAbort from "../../../common/hooks/use-abort";
import HeadingComponent from "../../../common/components/heading/heading.component";
import RouteActiveListingFilterComponent from "./filters/route-active-listing-filter.component";
import CardComponent from "../../../common/components/card/card.component";
import RouteActiveListingTableComponent from "./table/route-active-listing-table.component";
import ListingPaginationComponent from "../../../common/components/listing/pagination/listing-pagination.component";

type RouteActiveListingProps = {};

const RouteActiveListingComponent: FC<RouteActiveListingProps> = (props) => {
  const translations = routeTranslationsHelper.getActiveListingTranslations();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  const isComponentMounted = useIsComponentMounted();

  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  const userPermissions = useRouteActiveListingUserPermissions();

  const routeActiveList = useRouteActiveList();
  const routeActiveListAbort = useAbort();

  const filtersDao = useRouteActiveListingFilterDao();

  const [filters, setFilters] = useState<RouteActiveListingFilter[]>(
    filtersDao.getFilters()
  );

  const [sortKey, setSortKey] = useState<RouteActiveListingSortKey>(
    filtersDao.getSortKey() ?? RouteActiveListingSortKey.HUMAN_ID_DESC
  );

  const [expandedRowsOrderUuids, setExpandedRowsOrderUuids] = useState<
    string[]
  >([]);

  const listingItems: RouteActiveListItem[] = useMemo(() => {
    const filteredListingItems =
      routeActiveListingFilteringService.filterListingItems(
        routeActiveList.data.data,
        filters
      );

    const sortedListingItems =
      routeActiveListingSortingService.sortListingItems(
        filteredListingItems,
        sortKey
      );

    return sortedListingItems;
  }, [routeActiveList.data.data, filters, sortKey]);

  const { pageData, page, pageSize, setPage, setPageSize, totalResults } =
    useClientSidePagination({
      data: listingItems,
      totalResults: listingItems.length,
      defaultPageSize: filtersDao.getPageSize(),
      defaultPage: filtersDao.getPage(),
    });

  useDocumentTitle(documentTitleTranslations.routeActiveListing);

  useEffect(() => {
    const breadcrumbs = routeBreadcrumbsHelper.getActiveListingBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  useEffect(() => {
    loadListingItems();
  }, []);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    window.scroll({ top: 0, behavior: "smooth" });
    setExpandedRowsOrderUuids([]);
  }, [page]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    setExpandedRowsOrderUuids([]);
  }, [sortKey]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    filtersDao.saveFilters(filters, sortKey, page, pageSize);
  }, [filters, sortKey, page, pageSize]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    setPage(1);
  }, [pageSize, filters]);

  const loadListingItems = () => {
    routeActiveList.load({}, routeActiveListAbort.signal);
  };

  return (
    <div className="route_active_listing">
      <HeadingComponent title={translations.header.headingText} />
      <RouteActiveListingFilterComponent
        filters={filters}
        onFiltersChange={setFilters}
        onSortKeyChange={setSortKey}
        sortKey={sortKey}
        hasAccessToTaxiCorporation={userPermissions.hasAccessToTaxiCorporation}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <RouteActiveListingTableComponent
          listingItems={pageData}
          isError={routeActiveList.isError}
          isLoading={routeActiveList.isLoading}
          expandedRowsUuids={expandedRowsOrderUuids}
          onExpandedRowsUuidsChange={setExpandedRowsOrderUuids}
        />
        <ListingPaginationComponent
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          page={page}
          pageSize={pageSize}
          totalResults={totalResults}
        />
      </CardComponent>
    </div>
  );
};

export default RouteActiveListingComponent;
