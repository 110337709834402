import { CargoCompanyAddressListItem } from "../../../../../common/services/cargo-company/address/list/cargo-company-address-list";
import MileageAddressListItem from "../../../../../common/services/mileage-address/list/mileage-address-list";
import MileageDetails, {
  MileageDetailsAddressSequenceNode,
} from "../../../../../common/services/mileage/details/mileage-details";
import uuidService from "../../../../../common/utils/uuid/uuid.service";
import { MileageUpdateAddressRouteItem } from "../components/address/mileage-update-address-routes.types";
import MileageUpdateAddress from "../types/mileage-update-address";
import MileageUpdateAddressSelectOption from "../types/mileage-update-address-select-option";

const createAddressesFromRouteItems = (
  routeItems: MileageUpdateAddressRouteItem[]
): MileageUpdateAddress[] => {
  const seq: MileageUpdateAddress[] = [];

  routeItems.forEach((routeItem) => {
    if (routeItem.address) {
      seq.push(routeItem.address);
    }
  });

  return seq;
};

const createAddress = (item: MileageAddressListItem): MileageUpdateAddress => {
  return {
    displayName: item.displayName,
    latitude: item.lat,
    longitude: item.lon,
    apartmentNumber: item.apartment,
    houseNumber: item.houseNo,
    street: item.street,
    town: item.town,
    zipCode: item.zipCode,
  };
};

const createFromCargoAddress = (
  item: CargoCompanyAddressListItem
): MileageUpdateAddress => {
  return {
    displayName: item.displayName,
    latitude: item.lat,
    longitude: item.lon,
    apartmentNumber: item.apartment,
    houseNumber: item.houseNumber,
    street: item.street,
    town: item.town,
    zipCode: item.zipCode,
  };
};

const createAddressSelectOption = (
  address: MileageUpdateAddress
): MileageUpdateAddressSelectOption => {
  return {
    label: address.displayName,
    value: address,
  };
};

const createAddressSelectOptionFromMileageAddressListItem = (
  item: MileageAddressListItem
): MileageUpdateAddressSelectOption => {
  const address: MileageUpdateAddress = createAddress(item);
  return createAddressSelectOption(address);
};

const createRouteItem = (
  node: MileageDetailsAddressSequenceNode
): MileageUpdateAddressRouteItem => {
  return {
    uuid: uuidService.generate(),
    address: {
      displayName: node.displayName,
      latitude: node.lat,
      longitude: node.lon,
      apartmentNumber: node.apartmentNumber,
      houseNumber: node.houseNumber,
      street: node.street,
      town: node.town,
      zipCode: node.zipCode,
    },
  };
};

const createRouteItemsFromDetails = (
  details: MileageDetails
): MileageUpdateAddressRouteItem[] => {
  return details.addressSeq.seq.map(createRouteItem);
};

const mileageUpdateAddressFactory = {
  createAddressSelectOptionFromMileageAddressListItem,
  createAddressSelectOption,
  createFromCargoAddress,
  createAddressesFromRouteItems,
  createRouteItemsFromDetails,
};

export default mileageUpdateAddressFactory;
