import {
  FC,
  useEffect,
  useState,
  useMemo,
  ReactNode,
  useCallback,
} from "react";
import CardComponent from "../../../common/components/card/card.component";
import Column from "../../../common/components/grid/column";
import Row from "../../../common/components/grid/row";
import HeadingComponent from "../../../common/components/heading/heading.component";
import ListingPaginationComponent from "../../../common/components/listing/pagination/listing-pagination.component";
import StatusLegendComponent from "../../../common/components/status-legend/status-legend.component";
import useAbort from "../../../common/hooks/use-abort";
import useDocumentTitle from "../../../common/hooks/use-document-title";
import useIsComponentMounted from "../../../common/hooks/use-is-component-mounted";
import usePagination from "../../../common/hooks/use-pagination";
import MileageListLoadParams from "../../../common/services/mileage/list/mileage-list-load-params";
import useMileageList from "../../../common/services/mileage/list/use-mileage-list";
import { useAppContext } from "../../../context/app.context";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import mileageTranslationsHelper from "../../../languages/mileage-translations.helper";
import mileageBreadcrumbsHelper from "../common/breadcrumbs/mileage-breadcrumbs.helper";
import mileageListingItemFactory from "./common/mileage-listing-item.factory";
import mileageListingLoadParamsFactory from "./common/mileage-listing-load-params.factory";
import mileageListingHelper from "./common/mileage-listing.helper";
import MileageListingFilter from "./common/types/mileage-listing-filter";
import MileageListingSortKey from "./common/types/mileage-listing-sort-key";
import MileageListingItem from "./common/types/mileage-listing-item";
import MileageListingFilterComponent from "./filter/mileage-listing-filters.component";
import useMileageListingFiltersDao from "./filter/use-mileage-listing-filters.dao";
import MileageListingTableComponent from "./table/mileage-listing-table.component";
import LinkButtonComponent from "../../../common/components/button/link/link-button.component";
import mileageRoutesHelper from "../common/routes/mileage-routes.helper";
import ButtonComponent from "../../../common/components/button/button.component";
import MileageListingActionData from "./common/types/mileage-listing-action-data";
import mileageListingActionDataFactory from "./common/mileage-listing-action-data.factory";
import useOpen from "../../../common/hooks/use-open";
import MileageListingActionsModalComponent from "./actions-modal/mileage-listing-actions-modal.component";
import DelegationAddFromMileagesParams from "../../../common/services/delegation/add-from-mileages/delegation-add-from-mileages-params";
import delegationService from "../../../common/services/delegation/delegation.service";
import MileageDownloadParams from "../../../common/services/mileage/download/mileage-download-params";
import mileageService from "../../../common/services/mileage/mileage.service";
import notificationService from "../../../common/utils/notification/notification.service";

type MileageListingProps = {};

const MileageListingComponent: FC<MileageListingProps> = () => {
  const translations =
    mileageTranslationsHelper.getMileageListingTranslations();
  const isComponentMounted = useIsComponentMounted();
  const statusOptions = mileageListingHelper.getStatusOptions();
  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const [isGenerateDelegationEnabled, setIsGenerateDelegationEnabled] =
    useState(false);
  const [isDownloadCSVEnabled, setIsDownloadCSVEnabled] = useState(false);

  const [actionDataList, setActionDataList] = useState<
    MileageListingActionData[]
  >([]);

  const onActionButtonClick = useCallback(
    (listingItem: MileageListingItem) => {
      const result = actionDataList.find(
        (source) => source.mileageUuid === listingItem.uuid
      );

      if (result) {
        setActionDataList(
          actionDataList.filter(
            (source) => source.mileageUuid !== result.mileageUuid
          )
        );
      } else {
        setActionDataList([
          ...actionDataList,
          mileageListingActionDataFactory.create(listingItem),
        ]);
      }
    },
    [actionDataList]
  );

  const onActionSelectAllButtonClick = useCallback(() => {
    if (actionDataList.length < listingItems.length) {
      setActionDataList(
        listingItems.map(mileageListingActionDataFactory.create)
      );
    } else {
      setActionDataList([]);
    }
  }, [actionDataList]);

  useEffect(() => {
    const breadcrumbs = mileageBreadcrumbsHelper.getMileageListingBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.mileageListing);

  const mileageList = useMileageList();
  const listingItemsAbort = useAbort();

  const filtersDao = useMileageListingFiltersDao();

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: mileageList.data.totalCount,
    defaultPageSize: filtersDao.getPageSize(),
    defaultPage: filtersDao.getPage(),
  });

  const [expandedRowsMileageUuids, setExpandedRowsMileageUuids] = useState<
    string[]
  >([]);

  const [filters, setFilters] = useState<MileageListingFilter[]>(() =>
    filtersDao.getFilters()
  );

  const [sortKey, setSortKey] = useState<MileageListingSortKey>(
    () => filtersDao.getSortKey() ?? MileageListingSortKey.DATE_DESC
  );

  const downloadModalOpen = useOpen();
  const generateDelegationModalOpen = useOpen();

  const generateDelegationAbort = useAbort();

  useEffect(() => {
    if (!downloadModalOpen.isOpen) {
      setIsDownloadCSVEnabled(false);
      setActionDataList([]);
    }
  }, [downloadModalOpen.isOpen]);

  useEffect(() => {
    if (isDownloadCSVEnabled) {
      downloadModalOpen.open();
    } else {
      downloadModalOpen.close();
    }
  }, [isDownloadCSVEnabled]);

  useEffect(() => {
    if (!generateDelegationModalOpen.isOpen) {
      setIsGenerateDelegationEnabled(false);
      setActionDataList([]);
    }
  }, [generateDelegationModalOpen.isOpen]);

  useEffect(() => {
    if (isGenerateDelegationEnabled) {
      generateDelegationModalOpen.open();
    } else {
      generateDelegationModalOpen.close();
    }
  }, [isGenerateDelegationEnabled]);

  const loadListingItems = async () => {
    const params: MileageListLoadParams =
      mileageListingLoadParamsFactory.create(page, pageSize, filters, sortKey);
    mileageList.load(params, listingItemsAbort.signal);
  };

  const saveFilters = () => {
    filtersDao.saveFilters(filters, sortKey, page, pageSize);
  };

  const listingItems: MileageListingItem[] = useMemo(() => {
    return mileageListingItemFactory.create(mileageList.data.data);
  }, [mileageList.data.data]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    saveFilters();
  }, [filters, sortKey, page, pageSize]);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, [page]);

  useEffect(() => {
    setExpandedRowsMileageUuids([]);
    loadListingItems();

    return () => listingItemsAbort.revoke();
  }, [page, pageSize, filters, sortKey]);

  const onTableRowClick = (listingItemUuid: string) => {
    const expandRow = () => {
      setExpandedRowsMileageUuids((curr) => [...curr, listingItemUuid]);
    };

    const collapseRow = () => {
      const filteredExpandedRows = expandedRowsMileageUuids.filter(
        (expandedMileageUuid) => expandedMileageUuid !== listingItemUuid
      );

      setExpandedRowsMileageUuids(filteredExpandedRows);
    };

    const isRowExpanded = expandedRowsMileageUuids.includes(listingItemUuid);

    if (isRowExpanded) {
      collapseRow();
      return;
    }

    expandRow();
  };

  const onMileageDownloadSuccess = () => {
    notificationService.success(translations.download.onSuccessMessage);
    downloadModalOpen.close();
  };

  const onMileageDownloadFailure = () => {
    notificationService.error(translations.download.onErrorMessage);
  };

  const mileageDownload = async () => {
    const params: MileageDownloadParams = {
      mileageUuids: actionDataList.map((data) => data.mileageUuid),
    };

    try {
      await mileageService.download(params);

      onMileageDownloadSuccess();
    } catch {
      onMileageDownloadFailure();
    }
  };

  const onGenerateDelegationSuccess = () => {
    notificationService.success(translations.generate.onSuccessMessage);
    generateDelegationModalOpen.close();
    loadListingItems();
  };

  const onGenerateDelegationFailure = () => {
    notificationService.error(translations.generate.onErrorMessage);
  };

  const generateDelegation = async () => {
    const params: DelegationAddFromMileagesParams = {
      cargoCompanyUuid: actionDataList[0].workerCompanyUuid,
      workerUuid: actionDataList[0].workerUuid,
      mileageUuids: actionDataList.map((data) => data.mileageUuid),
    };

    try {
      await delegationService.addFromMileages(
        params,
        generateDelegationAbort.signal
      );

      onGenerateDelegationSuccess();
    } catch {
      onGenerateDelegationFailure();
    }
  };

  const createHeadingActions = useCallback((): ReactNode[] => {
    const MileageAddLinkButton = (
      <LinkButtonComponent
        to={mileageRoutesHelper.getAddRoute()}
        type="primary"
        title={translations.header.addNewMileageLinkTitle}
      >
        {translations.header.addNewMileageLinkLabel}
      </LinkButtonComponent>
    );

    return [MileageAddLinkButton];
  }, [selectedAppLanguage]);

  const createDownloadActions = useCallback((): ReactNode[] => {
    const onGenerateButtonEnableClick = () => {
      if (!isGenerateDelegationEnabled) {
        setIsDownloadCSVEnabled(false);
        setActionDataList([]);
      }

      setIsGenerateDelegationEnabled(!isGenerateDelegationEnabled);
    };

    const onDownloadCSVButtonClick = () => {
      if (!isDownloadCSVEnabled) {
        setIsGenerateDelegationEnabled(false);
        setActionDataList([]);
      }

      setIsDownloadCSVEnabled(!isDownloadCSVEnabled);
    };

    const GenerateDelegationEnableButton = (
      <ButtonComponent
        type={isGenerateDelegationEnabled ? "success" : "primary"}
        onClick={onGenerateButtonEnableClick}
        title={translations.header.generateDelegationButtonTitle}
      >
        {translations.header.generateDelegationButtonLabel}
      </ButtonComponent>
    );

    const DownloadCSVEnableButton = (
      <ButtonComponent
        type={isDownloadCSVEnabled ? "success" : "primary"}
        onClick={onDownloadCSVButtonClick}
        title={translations.header.downloadCSVButtonTitle}
      >
        {translations.header.downloadCSVButtonLabel}
      </ButtonComponent>
    );
    return [GenerateDelegationEnableButton, DownloadCSVEnableButton];
  }, [selectedAppLanguage, isDownloadCSVEnabled, isGenerateDelegationEnabled]);

  const headingActions = useMemo(() => {
    return createHeadingActions();
  }, [selectedAppLanguage, createHeadingActions]);

  const downloadActions = useMemo(() => {
    return createDownloadActions();
  }, [selectedAppLanguage, createDownloadActions]);

  return (
    <>
      <HeadingComponent
        title={translations.header.headingLabel}
        actions={headingActions}
      />
      <Row>
        <Column xl={8}>
          <StatusLegendComponent statusData={statusOptions} />
        </Column>
        <Column xl={4}>
          <HeadingComponent title="" actions={downloadActions} />
        </Column>
      </Row>
      <MileageListingFilterComponent
        filters={filters}
        sortKey={sortKey}
        onFiltersChange={(filters) => {
          setFilters(filters);
          setPage(1);
        }}
        onSortKeyChange={(sortKey) => {
          setSortKey(sortKey);
          setPage(1);
        }}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <MileageListingTableComponent
          listingItems={listingItems}
          onRowClick={onTableRowClick}
          expandedRowsMileageUuids={expandedRowsMileageUuids}
          isError={false}
          isLoading={false}
          isGenerateDelegationEnabled={isGenerateDelegationEnabled}
          isDownloadCSVEnabled={isDownloadCSVEnabled}
          mileageListingActionData={actionDataList}
          onActionButtonClick={onActionButtonClick}
          onActionSelectAllButtonClick={onActionSelectAllButtonClick}
          reloadRows={loadListingItems}
        />
        <ListingPaginationComponent
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          page={page}
          pageSize={pageSize}
          totalResults={mileageList.data.totalCount}
        />
      </CardComponent>
      {downloadModalOpen.isOpen && (
        <MileageListingActionsModalComponent
          mileageActionData={actionDataList}
          onCancel={downloadModalOpen.close}
          onSuccess={mileageDownload}
          translations={translations.download}
        />
      )}
      {generateDelegationModalOpen.isOpen && (
        <MileageListingActionsModalComponent
          mileageActionData={actionDataList}
          onCancel={generateDelegationModalOpen.close}
          onSuccess={generateDelegation}
          translations={translations.generate}
        />
      )}
    </>
  );
};

export default MileageListingComponent;
