import CargoTaxiContractDetailsResponse, {
  CargoTaxiContractDetailsResponseItemBillingModel,
  CargoTaxiContractDetailsResponseItemCargoCompany,
  CargoTaxiContractDetailsResponseItemTaxiCorporation,
  CargoTaxiContractDetailsResponseItemTaxiCorporationContractMaintenancePolicy,
  CargoTaxiContractDetailsResponseItemTaxiCorporationFleetPartner,
} from "../../../api/raily/cargo-taxi-contract/details/cargo-taxi-contract-details.response";
import BillingModel from "../../../types/billing-model";
import ContractMaintenancePolicy from "../../../types/contract-maintenance-policy";
import CargoTaxiContractDetails, {
  CargoTaxiContractDetailsCargoCompany,
  CargoTaxiContractDetailsTaxiCorporation,
  CargoTaxiContractDetailsTaxiCorporationFleetPartner,
} from "./cargo-taxi-contract-details";

const createBillingModel = (
  responseModel: CargoTaxiContractDetailsResponseItemBillingModel
): BillingModel => {
  switch (responseModel) {
    case CargoTaxiContractDetailsResponseItemBillingModel.AB:
      return BillingModel.AB;
    case CargoTaxiContractDetailsResponseItemBillingModel.ABA:
      return BillingModel.ABA;
    case CargoTaxiContractDetailsResponseItemBillingModel.OTHER:
      return BillingModel.OTHER;
    case CargoTaxiContractDetailsResponseItemBillingModel.SABS:
      return BillingModel.SABS;
  }
};

const createTaxiCorporationContractMaintenancePolicy = (
  responseContractMaintenancePolicy: CargoTaxiContractDetailsResponseItemTaxiCorporationContractMaintenancePolicy
): ContractMaintenancePolicy => {
  switch (responseContractMaintenancePolicy) {
    case CargoTaxiContractDetailsResponseItemTaxiCorporationContractMaintenancePolicy.AUCTION:
      return ContractMaintenancePolicy.AUCTION;
    case CargoTaxiContractDetailsResponseItemTaxiCorporationContractMaintenancePolicy.BASIC:
      return ContractMaintenancePolicy.BASIC;
  }
};

const createTaxiCorporationFleetPartner = (
  responseFleetPartner: CargoTaxiContractDetailsResponseItemTaxiCorporationFleetPartner
): CargoTaxiContractDetailsTaxiCorporationFleetPartner => {
  return {
    createdBy: responseFleetPartner.createdBy,
    creationDate: responseFleetPartner.createdAt,
    modifiedBy: responseFleetPartner.modifiedBy,
    modifitationDate: responseFleetPartner.modifiedAt,
    name: responseFleetPartner.name,
    taxiCorporationUuid: responseFleetPartner.taxiCorporationId,
    uuid: responseFleetPartner.id,
  };
};

const createTaxiCorporationFleetPartners = (
  responseFleetPartners: CargoTaxiContractDetailsResponseItemTaxiCorporationFleetPartner[]
): CargoTaxiContractDetailsTaxiCorporationFleetPartner[] => {
  return responseFleetPartners.map(createTaxiCorporationFleetPartner);
};

const createTaxiCorporation = (
  responseTaxiCorporation: CargoTaxiContractDetailsResponseItemTaxiCorporation
): CargoTaxiContractDetailsTaxiCorporation => {
  return {
    address: responseTaxiCorporation.headquartersAddressString,
    companyId: responseTaxiCorporation.regon,
    contractMaintenancePolicy: createTaxiCorporationContractMaintenancePolicy(
      responseTaxiCorporation.contractMaintenancePolicy
    ),
    createdBy: responseTaxiCorporation.createdBy,
    creationDate: responseTaxiCorporation.createdAt,
    displayName: responseTaxiCorporation.displayName,
    fleetPartners: createTaxiCorporationFleetPartners(
      responseTaxiCorporation.fleetPartners
    ),
    modifiedBy: responseTaxiCorporation.modifiedBy,
    modifitationDate: responseTaxiCorporation.modifiedAt,
    name: responseTaxiCorporation.companyName,
    nationalCourtRegister: responseTaxiCorporation.krsNo,
    notes: responseTaxiCorporation.notes,
    taxNumber: responseTaxiCorporation.taxId,
    uuid: responseTaxiCorporation.id,
  };
};

const createCargoCompany = (
  responseCargoCompany: CargoTaxiContractDetailsResponseItemCargoCompany
): CargoTaxiContractDetailsCargoCompany => {
  return {
    address: responseCargoCompany.headquartersAddressString,
    companyId: responseCargoCompany.regon,
    createdBy: responseCargoCompany.createdBy,
    creationDate: responseCargoCompany.createdAt,
    displayName: responseCargoCompany.displayName,
    modifiedBy: responseCargoCompany.modifiedBy,
    modifitationDate: responseCargoCompany.modifiedAt,
    name: responseCargoCompany.companyName,
    nationalCourtRegister: responseCargoCompany.krsNo,
    notes: responseCargoCompany.notes,
    taxNumber: responseCargoCompany.taxId,
    uuid: responseCargoCompany.id,
  };
};

const create = (
  response: CargoTaxiContractDetailsResponse
): CargoTaxiContractDetails => {
  return {
    periodOfValidity: {
      from: response.data.validSince,
      to: response.data.validTo,
    },
    isActive: !response.data.disabled,
    billingModel: createBillingModel(response.data.model),
    distanceRate: response.data.distanceRate,
    stoppingRate: response.data.haltingTimeRate,
    freeStoppingPeriodMinutes: response.data.haltingTimeAfterMinutes,
    uuid: response.data.id,
    cargoCompany: createCargoCompany(response.data.cargoCompany),
    cargoCompanyUuid: response.data.cargoCompanyId,
    createdBy: response.data.createdBy,
    creationDate: response.data.createdAt,
    discountPercentLimit: response.data.discountLimit,
    isOrderPublishingAllowed: response.data.publishingAllowed,
    isTollRoadsDuringOrderAllowed: response.data.allowChargeDuringRide,
    isTollRoadsWhileArrivalAllowed: response.data.allowChargeWhileApproaching,
    isTollRoadsWhileReturningAllowed: response.data.allowChargeWhileReturning,
    modifiedBy: response.data.modifiedBy,
    modifitationDate: response.data.modifiedAt,
    taxiCorporation: createTaxiCorporation(response.data.taxiCorporation),
    taxiCorporationUuid: response.data.taxiCorporationId,
    contactPassengerAfterMinutes: response.data.contactPassengerAfterMinutes,
    cancelByTaxiAllowed: response.data.cancelByTaxiAllowed,
    editByTaxiAllowed: response.data.editByTaxiAllowed,
  };
};

const cargoTaxiContractDetailsFactory = {
  create,
};

export default cargoTaxiContractDetailsFactory;
