import userTranslationsHelper from "../../../../../../languages/user-translations.helper";
import DriverEditCarOwnershipType from "../types/driver-edit-car-ownership-type";
import DriverEditCarOwnershipTypeSelectOption from "../types/driver-edit-car-ownership-type-select-option";

const carOwnershipLabel = (type: DriverEditCarOwnershipType): string => {
  const translations =
    userTranslationsHelper.getDriverEditTranslations().form.vehicleData;
  switch (type) {
    case DriverEditCarOwnershipType.OWNER:
      return translations.typeOwner;
    case DriverEditCarOwnershipType.BUSINESS:
      return translations.typeBusiness;
  }
};

const createSelectOption = (
  type: DriverEditCarOwnershipType
): DriverEditCarOwnershipTypeSelectOption => {
  return {
    label: carOwnershipLabel(type),
    value: type,
    idForTesting: `driver-add-car-ownership-${String(type)}-select-option`,
  };
};

const createSelectOptions = (): DriverEditCarOwnershipTypeSelectOption[] => {
  return Object.keys(DriverEditCarOwnershipType).map((value) =>
    createSelectOption(value as DriverEditCarOwnershipType)
  );
};

const driverEditCarOwnershipTypeFactory = {
  createSelectOptions,
  createSelectOption,
};

export default driverEditCarOwnershipTypeFactory;
