import { FC, useEffect, useMemo, useState } from "react";
import ButtonComponent from "../../../common/components/button/button.component";
import CardComponent from "../../../common/components/card/card.component";
import FormFieldComponent from "../../../common/components/form/field/form-field.component";
import SingleSelectComponent from "../../../common/components/form/select/single-select/single-select.component";
import Column from "../../../common/components/grid/column";
import Row from "../../../common/components/grid/row";
import HeadingComponent from "../../../common/components/heading/heading.component";
import useAbort from "../../../common/hooks/use-abort";
import useDocumentTitle from "../../../common/hooks/use-document-title";
import useCargoCompanyList from "../../../common/services/cargo-company/cargo-company/list/use-cargo-company-list";
import MileageSettingsDetailsLoadParams from "../../../common/services/mileage-settings/details/mileage-settings-details-load-params";
import useMileageSettingsDetails from "../../../common/services/mileage-settings/details/use-mileage-settings-details";
import mileageSettingsService from "../../../common/services/mileage-settings/mileage-settings.service";
import { useAppContext } from "../../../context/app.context";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import mileageTranslationsHelper from "../../../languages/mileage-translations.helper";
import mileageBreadcrumbsHelper from "../common/breadcrumbs/mileage-breadcrumbs.helper";
import mileageSettingsParamsFactory from "./factory/mileage-settings-params.factory";
import mileageSettingsFormHelper from "./form/mileage-settings-form.helper";
import MileageSettingsCargoCompanySelectOption from "./types/mileage-settings-cargo-company-select-option";
import MileageSettingsMileageNumberCreationModelSelectComponent from "./components/mileage-settings-mileage-number-creation-model-select.component";
import SwitchButton from "../../../common/components/switch-button/switch-button.component";
import mileageSettingsFactory from "./factory/mileage-settings.factory";
import mileageSettingsFormFactory from "./form/mileage-settings-form.factory";
import useForm from "../../../common/components/form/use-form";
import FormErrorComponent from "../../../common/components/form/error/form-error.component";

const MileageSettingsComponent: FC = () => {
  const translations =
    mileageTranslationsHelper.getMileageSettingsTranslations();
  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  useEffect(() => {
    const breadcrumbs =
      mileageBreadcrumbsHelper.getMileageSettingsBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();
  useDocumentTitle(documentTitleTranslations.mileageSettings);

  const form = useForm({
    emptyValues: mileageSettingsFormHelper.getDefaultFormData(),
    validationDefinition: mileageSettingsFormHelper.getValidationDefinition(),
  });

  const [selectedCargoCompanyUuid, setSelectedCargoCompanyUuid] = useState<
    string | null
  >(null);

  const optionsAddAbort = useAbort();
  const optionsUpdateAbort = useAbort();

  const cargoCompanyList = useCargoCompanyList();
  const listingItemsAbort = useAbort();

  const mileageSettingsDetails = useMileageSettingsDetails();
  const mileageSettingsDetailsAbort = useAbort();

  const cargoCompanySelectOptions = useMemo(() => {
    return mileageSettingsFactory.createCargoCompanySelectOptions(
      cargoCompanyList.data.data
    );
  }, [cargoCompanyList.data.data]);

  const selectedCargoCompanySelectOption = useMemo(() => {
    if (!selectedCargoCompanyUuid) {
      return null;
    }

    const result = cargoCompanySelectOptions.find(
      (option) => option.value.uuid === selectedCargoCompanyUuid
    );

    return result;
  }, [selectedCargoCompanyUuid]);

  const areSettingsVisible = useMemo(() => {
    return !!selectedCargoCompanyUuid;
  }, [selectedCargoCompanyUuid]);

  const hasUnsavedChanges = useMemo(() => {
    return !(
      form.validationResults.isCardNumberRequired.isValid &&
      form.validationResults.isMileageNumberRequired.isValid &&
      form.validationResults.mileageNumberCreationModel.isValid
    );
  }, [form.validationResults]);

  const loadMileageSettingsDetails = (cargoCompanyUuid: string) => {
    const loadParams: MileageSettingsDetailsLoadParams = {
      cargoCompanyUuid: cargoCompanyUuid,
    };

    mileageSettingsDetails.load(loadParams, mileageSettingsDetailsAbort.signal);
  };

  useEffect(() => {
    cargoCompanyList.load({}, listingItemsAbort.signal);

    return () => listingItemsAbort.revoke();
  }, []);

  useEffect(() => {
    if (!selectedCargoCompanyUuid) {
      return;
    }

    loadMileageSettingsDetails(selectedCargoCompanyUuid);

    return () => mileageSettingsDetailsAbort.revoke();
  }, [selectedCargoCompanyUuid]);

  useEffect(() => {
    if (mileageSettingsDetails.data === null) {
      form.setValues(mileageSettingsFormHelper.getDefaultFormData());
      return;
    }

    form.setValues(
      mileageSettingsFormFactory.create(mileageSettingsDetails.data)
    );
  }, [mileageSettingsDetails.data]);

  const onCargoCompanyChange = (
    selectedOption: MileageSettingsCargoCompanySelectOption | null
  ) => {
    if (selectedOption) {
      setSelectedCargoCompanyUuid(selectedOption.value.uuid);
    } else {
      setSelectedCargoCompanyUuid(null);
    }
  };

  const submitAdd = async () => {
    const params = mileageSettingsParamsFactory.createAddParams(
      selectedCargoCompanyUuid!,
      form.values
    );

    return mileageSettingsService.add(params, optionsAddAbort.signal);
  };
  const submitUpdate = async () => {
    const params = mileageSettingsParamsFactory.createUpdateParams(
      selectedCargoCompanyUuid!,
      form.values
    );

    return mileageSettingsService.update(params, optionsUpdateAbort.signal);
  };

  const onSubmitButtonClick = async () => {
    const validationResult = await form.validateAll();

    if (validationResult) {
      return;
    }

    const submitFunction = !mileageSettingsDetails.data
      ? submitAdd
      : submitUpdate;

    submitFunction().then(() => {
      loadMileageSettingsDetails(selectedCargoCompanyUuid!);
    });
  };

  useEffect(() => {
    form.validateAll();
  }, [form.values]);

  const onResetButtonClick = () => {
    form.setValues(mileageSettingsFormFactory.createRestoreData(form.values));
  };

  return (
    <>
      <HeadingComponent title={translations.header.headingLabel} />
      <Row>
        <Column xl={6}>
          <FormFieldComponent label={translations.cargoCompanyLabel} isRequired>
            <SingleSelectComponent
              placeholder={translations.cargoCompanyPlaceholder}
              value={selectedCargoCompanySelectOption ?? null}
              options={cargoCompanySelectOptions}
              onChange={onCargoCompanyChange}
              idForTesting={`mileage-settings-component-cargo-company-select`}
              isLoading={cargoCompanyList.isLoading}
              isDisabled={cargoCompanyList.isError}
              isSearchable
            />
          </FormFieldComponent>
        </Column>
      </Row>
      {areSettingsVisible && (
        <>
          <Row>
            <Column xl={12}>
              <CardComponent>
                <Row>
                  <Column xl={6}>
                    <FormFieldComponent
                      label={translations.cardNumber.headingLabel}
                      isRequired={
                        !!form.validationResults.isCardNumberRequired
                          .errorMessage
                      }
                    >
                      <Column>
                        <Row>
                          <p>{`${translations.cardNumber.noLabel}`}</p>
                          <SwitchButton
                            checked={form.values.isCardNumberRequired}
                            onChange={(value) => {
                              form.setValue("isCardNumberRequired", value);
                            }}
                          />
                          <p>{`${translations.cardNumber.yesLabel}`}</p>
                        </Row>
                      </Column>
                    </FormFieldComponent>
                    <FormFieldComponent
                      label={translations.mileageNumber.headingLabel}
                      isRequired={
                        !!form.validationResults.isMileageNumberRequired
                          .errorMessage
                      }
                    >
                      <Column>
                        <Row>
                          <p>{`${translations.mileageNumber.noLabel}`}</p>
                          <SwitchButton
                            checked={form.values.isMileageNumberRequired}
                            onChange={(value) => {
                              form.setValue("isMileageNumberRequired", value);
                            }}
                          />
                          <p>{`${translations.mileageNumber.yesLabel}`}</p>
                        </Row>
                      </Column>
                    </FormFieldComponent>
                  </Column>
                  <Column lg={6}>
                    <FormFieldComponent
                      label={translations.creationModel.headingLabel}
                      isRequired={
                        !!form.validationResults.mileageNumberCreationModel
                          .errorMessage
                      }
                    >
                      <MileageSettingsMileageNumberCreationModelSelectComponent
                        creationModel={form.values.mileageNumberCreationModel}
                        onCreationModelChange={(value) => {
                          form.setValue("mileageNumberCreationModel", value);
                        }}
                      />
                    </FormFieldComponent>
                  </Column>
                </Row>
                <Row>
                  <Column lg={4}>
                    <Row>
                      {hasUnsavedChanges && (
                        <FormErrorComponent
                          message={translations.unsavedChangesLabel}
                        />
                      )}
                    </Row>
                    <Row>
                      <ButtonComponent
                        onClick={onSubmitButtonClick}
                        type="primary"
                        isDisabled={!hasUnsavedChanges}
                        idForTesting="mileage-settings-component-submit-button"
                        classNames={{ root: "m-2" }}
                        title={translations.submitButtonTitle}
                      >
                        {translations.submitButtonLabel}
                      </ButtonComponent>
                      <ButtonComponent
                        onClick={onResetButtonClick}
                        type="primary"
                        isDisabled={!hasUnsavedChanges}
                        idForTesting="mileage-settings-component-reset-button"
                        classNames={{ root: "m-2" }}
                        title={translations.resetButtonTitle}
                      >
                        {translations.resetButtonLabel}
                      </ButtonComponent>
                    </Row>
                  </Column>
                </Row>
              </CardComponent>
            </Column>
          </Row>
        </>
      )}
    </>
  );
};

export default MileageSettingsComponent;
