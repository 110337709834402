import { FC, useMemo } from "react";
import FormValidationResult from "../../../../../../../common/utils/validation/types/form-validation-result";
import DriverEditCitizenshipType from "../../types/driver-edit-citizenship-type";
import DriverEditFormOfEmploymentType from "../../types/driver-edit-form-of-employment-type";
import { useAppContext } from "../../../../../../../context/app.context";
import userTranslationsHelper from "../../../../../../../languages/user-translations.helper";
import DriverEditUserFleetPartnerSelectOption from "../../types/driver-edit-user-fleet-partner-select-option";
import driverEditCitizenshipTypeFactory from "../../factory/driver-edit-user-data.factory";
import DriverEditLanguageTypeSelectOption from "../../types/driver-edit-language-type-select-option";
import DriverEditCitizenshipTypeSelectOption from "../../types/driver-edit-citizenship-type-select-option";
import DriverEditAddress from "../../types/driver-edit-address";
import CardComponent from "../../../../../../../common/components/card/card.component";
import Row from "../../../../../../../common/components/grid/row";
import Column from "../../../../../../../common/components/grid/column";
import FormFieldComponent from "../../../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../../../common/components/form/input/input.component";
import PhoneNumberInputComponent from "../../../../../../../common/components/form/input/phone-number/phone-number-input.component";
import DateInputComponent from "../../../../../../../common/components/form/input/date/date-input.component";
import SingleSelectComponent from "../../../../../../../common/components/form/select/single-select/single-select.component";
import MultiSelectComponent from "../../../../../../../common/components/form/select/multi-select/multi-select.component";
import NumericInputComponent from "../../../../../../../common/components/form/input/numeric-input/numeric-input.component";
import DriverEditUserDataAddressComponent from "./address/driver-edit-user-data-address.component";
import DriverEditFormOfEmploymentTypeSelectOption from "../../types/driver-edit-form-of-employment-type-select-option";
import TaxiDriverEditUserFormData from "../../form/types/taxi-driver-edit-user-form-data";

type TaxiDriverEditUserDataProps = {
  citizenship: DriverEditCitizenshipType;
  formOfEmployment: DriverEditFormOfEmploymentType;
  fleetPartnerSelectOptions: DriverEditUserFleetPartnerSelectOption[];
  validateUserData: (
    formKey: keyof TaxiDriverEditUserFormData,
    value?: unknown
  ) => Promise<boolean>;
  onUserDataChange: (
    formKey: keyof TaxiDriverEditUserFormData,
    value: any
  ) => void;
  userValidationResults: Record<
    keyof TaxiDriverEditUserFormData,
    FormValidationResult
  >;
  userFormValues: TaxiDriverEditUserFormData;
  taxiCorporationUuid: string;
};

const TaxiDriverEditUserDataComponent: FC<TaxiDriverEditUserDataProps> = (
  props
) => {
  const { selectedAppLanguage } = useAppContext();

  const translations =
    userTranslationsHelper.getDriverEditTranslations().form.userData;

  const citizenshipSelectOptions = useMemo(
    driverEditCitizenshipTypeFactory.createCitizenshipSelectOptions,
    [selectedAppLanguage]
  );

  const languageSelectOptions = useMemo(
    driverEditCitizenshipTypeFactory.createLanguageSelectOptions,
    [selectedAppLanguage]
  );

  const formOfEmploymentSelectOptions = useMemo(
    driverEditCitizenshipTypeFactory.createFormOfEmploymentSelectOptions,
    [selectedAppLanguage]
  );

  const citizenshipSelectOption = props.userFormValues.citizenship
    ? citizenshipSelectOptions.find(
        (option) => option.value === props.userFormValues.citizenship
      ) || null
    : null;

  const formOfEmploymentSelectOption = props.userFormValues.formOfEmployment
    ? formOfEmploymentSelectOptions.find(
        (option) => option.value === props.userFormValues.formOfEmployment
      ) || null
    : null;

  const fleetPartnerSelectOption = props.userFormValues.fleetPartner
    ? props.fleetPartnerSelectOptions.find(
        (option) =>
          option.value.uuid === props.userFormValues.fleetPartner?.uuid
      ) || null
    : null;

  const languageSelectOption = props.userFormValues.languages
    .map((type) =>
      languageSelectOptions.find((option) => option.value === type)
    )
    .filter((option): option is DriverEditLanguageTypeSelectOption => !!option);

  const handleLanguagesChange = async (
    values: DriverEditLanguageTypeSelectOption[] | null
  ) => {
    const selectedLanguages = values ? values.map((value) => value.value) : [];

    const isValid = await props.validateUserData(
      "languages",
      selectedLanguages
    );
    if (isValid || selectedLanguages.length === 0) {
      props.onUserDataChange("languages", selectedLanguages);
    }
  };

  const handleCitizenshipChange = async (
    value: DriverEditCitizenshipTypeSelectOption | null
  ) => {
    if (!value) return;

    const isValid = await props.validateUserData("citizenship", value.value);
    if (isValid) {
      props.onUserDataChange("citizenship", value.value);
    }
  };

  const handleFormOfEmploymentChange = async (
    value: DriverEditFormOfEmploymentTypeSelectOption | null
  ) => {
    if (!value) return;

    const isValid = await props.validateUserData(
      "formOfEmployment",
      value.value
    );
    if (isValid) {
      props.onUserDataChange("formOfEmployment", value.value);
    }
  };

  const handleFleetPartnerChange = async (
    value: DriverEditUserFleetPartnerSelectOption | null
  ) => {
    if (!value) return;

    const isValid = await props.validateUserData("fleetPartner", value.value);
    if (isValid) {
      props.onUserDataChange("fleetPartner", value.value);
    }
  };

  const addAddress = async (newAddress: DriverEditAddress) => {
    const updatedAddresses = [...props.userFormValues.addresses, newAddress];

    props.onUserDataChange("addresses", updatedAddresses);

    await props.validateUserData("addresses", updatedAddresses);
  };

  const handleBirthDateChange = async (value: Date | null) => {
    props.onUserDataChange("birthDate", value);
    await props.validateUserData("birthDate", value);
  };

  const removeAddress = async (indexToRemove: number) => {
    const updatedAddresses = props.userFormValues.addresses.filter(
      (_address, index) => index !== indexToRemove
    );

    props.onUserDataChange("addresses", updatedAddresses);

    await props.validateUserData("addresses", updatedAddresses);
  };

  return (
    <CardComponent header={{ title: translations.headingText }}>
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.firstNameLabel}
            isRequired
            errorMessage={props.userValidationResults.firstName.errorMessage}
            classNames={{ root: "mt-0" }}
          >
            <InputComponent
              placeholder={translations.firstNamePlaceholder}
              value={props.userFormValues.firstName}
              onChange={(value) => props.onUserDataChange("firstName", value)}
              onBlur={() => {
                props.validateUserData("firstName");
              }}
              hasError={!!props.userValidationResults.firstName.errorMessage}
              idForTesting={`user-data-first-name-input`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.lastNameLabel}
            isRequired
            errorMessage={props.userValidationResults.lastName.errorMessage}
            classNames={{ root: "mt-0" }}
          >
            <InputComponent
              placeholder={translations.lastNamePlaceholder}
              value={props.userFormValues.lastName}
              onChange={(value) => props.onUserDataChange("lastName", value)}
              onBlur={() => {
                props.validateUserData("lastName");
              }}
              hasError={!!props.userValidationResults.lastName.errorMessage}
              idForTesting={`user-data-last-name-input`}
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.mobileLabel}
            isRequired
            errorMessage={props.userValidationResults.mobile.errorMessage}
          >
            <PhoneNumberInputComponent
              placeholder={translations.mobilePlaceholder}
              phoneNumber={props.userFormValues.mobile}
              onChange={(value) => props.onUserDataChange("mobile", value)}
              onBlur={() => {
                props.validateUserData("mobile");
              }}
              hasError={!!props.userValidationResults.mobile.errorMessage}
              idForTesting={`user-data-mobile`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.alternativeMobileLabel}
            errorMessage={
              props.userValidationResults.alternativeMobile.errorMessage
            }
          >
            <PhoneNumberInputComponent
              placeholder={translations.alternativeMobilePlaceholder}
              phoneNumber={props.userFormValues.alternativeMobile}
              onChange={(value) =>
                props.onUserDataChange("alternativeMobile", value)
              }
              onBlur={() => {
                props.validateUserData("alternativeMobile");
              }}
              hasError={
                !!props.userValidationResults.alternativeMobile.errorMessage
              }
              idForTesting={`user-data-alternative-mobile`}
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.birthDateLabel}
            isRequired
            errorMessage={props.userValidationResults.birthDate.errorMessage}
          >
            <DateInputComponent
              placeholder={translations.birthDatePlaceholder}
              date={props.userFormValues.birthDate}
              onChange={handleBirthDateChange}
              hasError={!!props.userValidationResults.birthDate.errorMessage}
              maxDate={new Date()}
              idForTesting={`user-data-birth-date-picker-input`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.birthPlaceLabel}
            isRequired
            errorMessage={props.userValidationResults.birthPlace.errorMessage}
          >
            <InputComponent
              placeholder={translations.birthPlacePlaceholder}
              value={props.userFormValues.birthPlace}
              onChange={(value) => props.onUserDataChange("birthPlace", value)}
              onBlur={() => {
                props.validateUserData("birthPlace");
              }}
              hasError={!!props.userValidationResults.birthPlace.errorMessage}
              idForTesting={`user-data-birth-place-input`}
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.personalIdNumberLabel}
            isRequired
            errorMessage={
              props.userValidationResults.personalIdNumber.errorMessage
            }
          >
            <InputComponent
              placeholder={translations.personalIdNumberPlaceholder}
              value={props.userFormValues.personalIdNumber}
              onChange={(value) =>
                props.onUserDataChange("personalIdNumber", value)
              }
              onBlur={() => {
                props.validateUserData("personalIdNumber");
              }}
              hasError={
                !!props.userValidationResults.personalIdNumber.errorMessage
              }
              idForTesting={`user-data-personal-id-number-input`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.citizenshipLabel}
            isRequired
            errorMessage={props.userValidationResults.citizenship.errorMessage}
          >
            <SingleSelectComponent
              placeholder={translations.citizenshipPlaceholder}
              value={citizenshipSelectOption}
              options={citizenshipSelectOptions}
              onChange={handleCitizenshipChange}
              hasError={!!props.userValidationResults.citizenship.errorMessage}
              idForTesting={`user-data-citizenship-select`}
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.languagesLabel}
            isRequired
            errorMessage={props.userValidationResults.languages.errorMessage}
          >
            <MultiSelectComponent
              placeholder={translations.languagesPlaceholder}
              value={languageSelectOption}
              options={languageSelectOptions}
              onChange={handleLanguagesChange}
              hasError={!!props.userValidationResults.languages.errorMessage}
              idForTesting={`user-data-languages-select`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.experienceLabel}
            isRequired
            errorMessage={
              props.userValidationResults.yearsOfExperience.errorMessage
            }
          >
            <NumericInputComponent
              placeholder={translations.experiencePlaceholder}
              value={props.userFormValues.yearsOfExperience}
              isIntegerOnly
              onChange={(value) =>
                props.onUserDataChange("yearsOfExperience", value)
              }
              onBlur={() => {
                props.validateUserData("yearsOfExperience");
              }}
              hasError={
                !!props.userValidationResults.yearsOfExperience.errorMessage
              }
              idForTesting={`user-data-experience-input`}
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.formOfEmploymentLabel}
            isRequired
            errorMessage={
              props.userValidationResults.formOfEmployment.errorMessage
            }
          >
            <SingleSelectComponent
              placeholder={translations.formOfEmploymentPlaceholder}
              value={formOfEmploymentSelectOption}
              options={formOfEmploymentSelectOptions}
              onChange={handleFormOfEmploymentChange}
              isDisabled
              hasError={
                !!props.userValidationResults.formOfEmployment.errorMessage
              }
              idForTesting={`user-data-form-of-employment-select`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={6}>
          <FormFieldComponent
            label={translations.fleetPartnerLabel}
            errorMessage={props.userValidationResults.fleetPartner.errorMessage}
          >
            <SingleSelectComponent
              placeholder={translations.fleetPartnerPlaceholder}
              value={fleetPartnerSelectOption}
              options={props.fleetPartnerSelectOptions}
              onChange={handleFleetPartnerChange}
              isDisabled
              isClearable
              hasError={!!props.userValidationResults.fleetPartner.errorMessage}
              idForTesting={`user-data-fleet-partner-select`}
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column>
          <FormFieldComponent
            label={translations.addressesLabel}
            isRequired
            errorMessage={props.userValidationResults.addresses.errorMessage}
          >
            <DriverEditUserDataAddressComponent
              addresses={props.userFormValues.addresses}
              onRemoveAddressButtonClick={removeAddress}
              onAddNewAddress={addAddress}
            />
          </FormFieldComponent>
        </Column>
      </Row>
    </CardComponent>
  );
};

export default TaxiDriverEditUserDataComponent;
