import phoneNumberInputHelper from "../../../../../../common/components/form/input/phone-number/phone-number-input.helper";
import { FormValidationDefinition } from "../../../../../../common/components/form/use-form";
import driverAddUserDataFormValidationService from "./driver-add-user-data-form-validation.service";
import TaxiDriverAddUserFormData from "./types/taxi-driver-add-user-form-data";

const getDefaultUserFormData = (): TaxiDriverAddUserFormData => {
  return {
    firstName: "",
    lastName: "",
    mobile: phoneNumberInputHelper.getEmptyValue(),
    alternativeMobile: phoneNumberInputHelper.getEmptyValue(),
    birthDate: null,
    birthPlace: "",
    personalIdNumber: "",
    citizenship: null,
    languages: [],
    yearsOfExperience: null,
    formOfEmployment: null,
    taxiCorporation: null,
    fleetPartner: null,
    addresses: [],
  };
};

const getUserValidationDefinition =
  (): FormValidationDefinition<TaxiDriverAddUserFormData> => {
    return {
      addresses: (values) =>
        driverAddUserDataFormValidationService.validateAddresses(
          values.addresses
        ),
      alternativeMobile: (values) =>
        driverAddUserDataFormValidationService.validateAlternativeMobile(
          values.alternativeMobile
        ),
      birthDate: (values) =>
        driverAddUserDataFormValidationService.validateBirthDate(
          values.birthDate
        ),
      birthPlace: (values) =>
        driverAddUserDataFormValidationService.validateBirthPlace(
          values.birthPlace
        ),
      citizenship: (values) =>
        driverAddUserDataFormValidationService.validateCitizenship(
          values.citizenship
        ),
      firstName: (values) =>
        driverAddUserDataFormValidationService.validateFirstName(
          values.firstName
        ),
      languages: (values) =>
        driverAddUserDataFormValidationService.validateLanguages(
          values.languages
        ),
      lastName: (values) =>
        driverAddUserDataFormValidationService.validateLastName(
          values.lastName
        ),
      mobile: (values) =>
        driverAddUserDataFormValidationService.validateMobile(values.mobile),
      personalIdNumber: (values) =>
        driverAddUserDataFormValidationService.validatePersonalIdNumber(
          values.personalIdNumber
        ),
      yearsOfExperience: (values) =>
        driverAddUserDataFormValidationService.validateYearsOfExperience(
          values.yearsOfExperience
        ),
      fleetPartner: (values) =>
        driverAddUserDataFormValidationService.validateFleetPartner(
          values.fleetPartner
        ),
      formOfEmployment: (values) =>
        driverAddUserDataFormValidationService.validateFormOfEmployment(
          values.formOfEmployment
        ),
      taxiCorporation: (values) =>
        driverAddUserDataFormValidationService.validateTaxiCorporation(
          values.taxiCorporation
        ),
    };
  };

const taxiDriverAddUserFormHelper = {
  getDefaultUserFormData,
  getUserValidationDefinition,
};

export default taxiDriverAddUserFormHelper;
