import { FC, useEffect, useState } from "react";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import { useNavigate, useParams } from "react-router-dom";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import taxiTranslationsHelper from "../../../../languages/taxi-translations.helper";
import { useAppContext } from "../../../../context/app.context";
import taxiFleetPartnerAddFormHelper from "./common/taxi-fleet-partner-add-form.helper";
import taxiBreadcrumbsHelper from "../../common/breadcrumbs/taxi-breadcrumbs.helper";
import taxiRoutesHelper from "../../common/routes/taxi-routes.helper";
import notificationService from "../../../../common/utils/notification/notification.service";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import CardComponent from "../../../../common/components/card/card.component";
import Row from "../../../../common/components/grid/row";
import Column from "../../../../common/components/grid/column";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../common/components/form/input/input.component";
import ButtonComponent from "../../../../common/components/button/button.component";
import TaxiFleetPartnerAddRouteParams from "../../common/routes/types/taxi-fleet-partner-add-route-params";
import TaxiFleetPartnerAddFormData from "./common/types/taxi-fleet-partner-add-form-data";
import useAbort from "../../../../common/hooks/use-abort";
import TaxiFleetPartnerAddParams from "../../../../common/services/taxi-fleet-partner/add/taxi-fleet-partner-add-params";
import taxiFleetPartnerService from "../../../../common/services/taxi-fleet-partner/taxi-fleet-partner.service";
import useForm from "../../../../common/components/form/use-form";
import taxiFleetPartnerAddParamsFactory from "./common/taxi-fleet-partner-add-params.factory";

type TaxiFleetPartnerAddProps = {};

const TaxiFleetPartnerAddComponent: FC<TaxiFleetPartnerAddProps> = () => {
  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  const { taxiCorporationUuid } = useParams<TaxiFleetPartnerAddRouteParams>();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  const navigate = useNavigate();

  useDocumentTitle(documentTitleTranslations.taxiFleetPartnerAdd);

  useEffect(() => {
    if (!taxiCorporationUuid) {
      return;
    }

    const breadcrumbs = taxiBreadcrumbsHelper.getFleetPartnerAddBreadcrumbs({
      taxiCorporationUuid,
    });
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const translations =
    taxiTranslationsHelper.getTaxiFleetPartnerAddTranslations();

  const taxiFleetPartnerAddAbort = useAbort();

  const form = useForm<TaxiFleetPartnerAddFormData>({
    emptyValues: taxiFleetPartnerAddFormHelper.getEmptyFormData(),
    validationDefinition:
      taxiFleetPartnerAddFormHelper.getValidationDefinition(),
  });

  const [isFleetPartnerAddPending, setIsFleetPartnerAddPending] =
    useState(false);

  const navigateToListing = () => {
    const taxiFleetPartnerListingRoute =
      taxiRoutesHelper.getFleetPartnerListingRoute({
        taxiCorporationUuid: taxiCorporationUuid!,
      });

    navigate(taxiFleetPartnerListingRoute);
  };

  const onTaxiFleetPartnerAddSuccess = () => {
    notificationService.success(translations.successNotificationLabel);
    navigateToListing();
  };

  const onTaxiFleetPartnerAddFailure = () => {
    notificationService.error(translations.failureNotificationLabel);
  };

  const addTaxiFleetPartner = async () => {
    setIsFleetPartnerAddPending(true);

    const params: TaxiFleetPartnerAddParams =
      taxiFleetPartnerAddParamsFactory.create(
        form.values!,
        taxiCorporationUuid!
      );

    try {
      await taxiFleetPartnerService.add(
        params,
        taxiFleetPartnerAddAbort.signal
      );

      onTaxiFleetPartnerAddSuccess();
    } catch {
      onTaxiFleetPartnerAddFailure();
    } finally {
      setIsFleetPartnerAddPending(false);
    }
  };

  const submitForm = async () => {
    const isFormValid = await form.validateAll();

    if (!isFormValid) {
      return;
    }

    addTaxiFleetPartner();
  };

  return (
    <>
      <HeadingComponent title={translations.header.headingLabel} />
      <CardComponent classNames={{ root: "mt-4" }}>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.nameLabel}
              isRequired
              errorMessage={form.validationResults.name.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.namePlaceholder}
                value={form.values.name}
                onChange={(value) => form.setValue("name", value)}
                onBlur={() => form.validate("name")}
                hasError={!!form.validationResults.name.errorMessage}
                idForTesting="taxi-fleet-partner-add-name"
              />
            </FormFieldComponent>
          </Column>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.form.notesLabel}
              errorMessage={form.validationResults.notes.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.notesPlaceholder}
                value={form.values.notes}
                onChange={(value) => form.setValue("notes", value)}
                onBlur={() => form.validate("notes")}
                hasError={!!form.validationResults.notes.errorMessage}
                idForTesting="taxi-fleet-partner-add-notes"
              />
            </FormFieldComponent>
          </Column>
        </Row>
      </CardComponent>
      <ButtonComponent
        onClick={submitForm}
        type="primary"
        title={translations.form.submitButtonTitle}
        classNames={{ root: "mt-4" }}
        idForTesting="taxi-fleet-partner-add-submit-button"
        isLoading={isFleetPartnerAddPending}
      >
        {translations.form.submitButtonLabel}
      </ButtonComponent>
    </>
  );
};
export default TaxiFleetPartnerAddComponent;
