import { FC, useCallback, useEffect, useMemo, useState } from "react";
import DriverContractListingViewMode from "./common/types/driver-contract-listing-view-mode";
import DriverContractListingByRailyComponent from "./by-raily/driver-contract-listing-by-raily.component";
import ButtonComponent from "../../../../../common/components/button/button.component";
import userBreadcrumbsHelper from "../../../common/breadcrumbs/user-breadcrumbs.helper";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import { useAppContext } from "../../../../../context/app.context";
import useDocumentTitle from "../../../../../common/hooks/use-document-title";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import { useParams } from "react-router-dom";
import UserDriverContractListingRouteParams from "../../../common/routes/types/user-driver-contract-listing-route-params";
import DriverContractListingDriverResponse, {
  DriverContractListingDriverResponseData,
} from "./common/api/driver-contract-listing-driver.response";
import driverContractListingApiService from "./common/api/driver-contract-listing-api.service";
import DriverContractListingByTaxiComponent from "./by-taxi/driver-contract-listing-by-taxi.component";
import driverContractListingUserPermissionsHelper from "./common/user-permissions/driver-contract-listing-user-permission.helper";
import LinkButtonComponent from "../../../../../common/components/button/link/link-button.component";
import userRoutesHelper from "../../../common/routes/user-routes.helper";

type DriverContractListingProps = {};

const DriverContractListingComponent: FC<DriverContractListingProps> = () => {
  const { driverUuid } = useParams<UserDriverContractListingRouteParams>();

  const { user, selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const [driverName, setDriverName] = useState("");
  //TODO: remove unused useState, add catch with notification service to driverContractListingApiService
  const [isDriverDetailsFetching, setIsDriverDetailsFetching] = useState(false);

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(
    documentTitleTranslations.userDriverContractListing.replace(
      "#{driverName}",
      driverName
    )
  );

  const userPermissions = useMemo(
    () =>
      driverContractListingUserPermissionsHelper.getPermissions(user?.roles!),
    []
  );

  const translations = useMemo(
    () => userTranslationsHelper.getDriverContractListingTranslations(),
    [selectedAppLanguage]
  );

  useEffect(() => {
    if (!driverUuid) {
      return;
    }

    const breadcrumbs =
      userBreadcrumbsHelper.getDriverContractListingBreadcrumbs({
        driverName,
        driverUuid,
      });
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, driverUuid, driverName]);

  const onDriverDetailsFetchSuccess = (
    responseData: DriverContractListingDriverResponseData
  ) => {
    const driverName = `${responseData.first_name} ${responseData.last_name}`;

    setDriverName(driverName);
  };

  const handleDriverDetailsResponse = (
    response: DriverContractListingDriverResponse
  ) => {
    if (response.status === 200) {
      onDriverDetailsFetchSuccess(response.data);
      return;
    }
  };

  const fetchDriverDetails = () => {
    setIsDriverDetailsFetching(true);

    driverContractListingApiService
      .fetchDriverDetails(driverUuid!)
      .then(handleDriverDetailsResponse)
      .finally(() => setIsDriverDetailsFetching(false));
  };

  useEffect(() => {
    if (!driverUuid) {
      return;
    }

    fetchDriverDetails();
  }, [driverUuid]);

  const [selectedViewMode, setSelectedViewMode] =
    useState<DriverContractListingViewMode | null>(null);

  const ChangeViewToRailyButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() => setSelectedViewMode(DriverContractListingViewMode.RAILY)}
        title={translations.header.changeViewToRailyButtonTitle}
      >
        {translations.header.changeViewToRailyButtonLabel}
      </ButtonComponent>
    ),
    [translations]
  );

  const ChangeViewToCargoButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() => setSelectedViewMode(DriverContractListingViewMode.TAXI)}
        title={translations.header.changeViewToTaxiButtonTitle}
      >
        {translations.header.changeViewToTaxiButtonLabel}
      </ButtonComponent>
    ),
    [translations]
  );

  const AddNewContractLinkButton = useMemo(
    () => (
      <LinkButtonComponent
        type="primary"
        to={userRoutesHelper.getDriverContractAddRoute({
          driverUuid: driverUuid!,
        })}
        title={translations.header.addContractLinkButtonTitle}
      >
        {translations.header.addContractLinkButtonLabel}
      </LinkButtonComponent>
    ),
    [translations]
  );

  const viewChangeButtonOptions = useMemo(
    () => [
      {
        viewMode: DriverContractListingViewMode.RAILY,
        buttonOptions: {
          button: ChangeViewToCargoButton,
          hasPermission: userPermissions.hasAccessToTaxiView,
        },
      },
      {
        viewMode: DriverContractListingViewMode.TAXI,
        buttonOptions: {
          button: ChangeViewToRailyButton,
          hasPermission: userPermissions.hasAccessToRailyView,
        },
      },
    ],
    [userPermissions, ChangeViewToCargoButton, ChangeViewToRailyButton]
  );

  const getViewChangeButtons = useCallback(
    (viewMode: DriverContractListingViewMode) => {
      const buttonOptionsForSelectedViewMode = viewChangeButtonOptions
        .filter((option) => option.viewMode === viewMode)
        .map((option) => option.buttonOptions);

      return buttonOptionsForSelectedViewMode
        .filter((option) => option.hasPermission)
        .map((option) => option.button);
    },
    [viewChangeButtonOptions]
  );

  const viewOptions = useMemo(
    () => [
      {
        mode: DriverContractListingViewMode.RAILY,
        component: (
          <DriverContractListingByRailyComponent
            changeViewButtons={getViewChangeButtons(
              DriverContractListingViewMode.RAILY
            )}
            driverName={driverName}
            driverUuid={driverUuid!}
            addContractButton={
              userPermissions.hasAccessToAddContract && AddNewContractLinkButton
            }
          />
        ),
        hasPermission: userPermissions.hasAccessToRailyView,
      },
      {
        mode: DriverContractListingViewMode.TAXI,
        component: (
          <DriverContractListingByTaxiComponent
            changeViewButtons={getViewChangeButtons(
              DriverContractListingViewMode.TAXI
            )}
            driverName={driverName}
            driverUuid={driverUuid!}
            addContractButton={
              userPermissions.hasAccessToAddContract && AddNewContractLinkButton
            }
          />
        ),
        hasPermission: userPermissions.hasAccessToTaxiView,
      },
    ],
    [getViewChangeButtons, driverName, driverUuid]
  );

  const getPossibleViewOptions = useCallback(() => {
    return viewOptions.filter((option) => option.hasPermission);
  }, []);

  const possibleViewOptions = useMemo(
    () => getPossibleViewOptions(),
    [getPossibleViewOptions]
  );

  useEffect(() => {
    if (possibleViewOptions.length === 0) {
      return;
    }
    setSelectedViewMode(possibleViewOptions[0].mode);
  }, [possibleViewOptions]);

  const SelectedViewComponent = useMemo(
    () =>
      viewOptions.find((option) => option.mode === selectedViewMode)?.component,
    [selectedViewMode, viewOptions]
  );

  if (!SelectedViewComponent) {
    return null;
  }

  return SelectedViewComponent;
};

export default DriverContractListingComponent;
