import { FC } from "react";

const FlagIcon: FC = () => {
  return (
    <svg
      width="12"
      height="8"
      aria-hidden={true}
      viewBox="0 0 12 8"
      xmlns="http://www.w3.org/2000/svg"
      className="leaflet-attribution-flag"
    >
      <path d="M0 0h12v4H0z" fill={"#4C7BE1"} />
      <path d="M0 4h12v3H0z" fill={"#FFD500"} />
      <path d="M0 7h12v1H0z" fill={"#E0BC00"} />
    </svg>
  );
};

export default FlagIcon;
