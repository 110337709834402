import { FC, useCallback, useEffect, useMemo, useState } from "react";
import OrderOptimizerDetailsHiddenDetailsDriver from "./types/order-optimizer-details-hidden-details-driver";
import orderOptimizerTranslationsHelper from "../../../../../languages/order-optimizer-translations.helper";
import OrderOptimizerDetailsHiddenDetailsDriverSelectOption from "./types/order-optimizer-details-hidden-details-driver-select-option";
import useOrderOptimizerPotentialDriversList from "../../../../../common/services/order-optimizer/potential-drivers-list/use-order-optimizer-potential-drivers-list";
import useAbort from "../../../../../common/hooks/use-abort";
import { debounce } from "lodash";
import OrderOptimizerPotentialDriversListLoadParams from "../../../../../common/services/order-optimizer/potential-drivers-list/order-optimizer-potential-drivers-list-load-params";
import orderOptimizerDetailsHiddenDetailsDriverListFactory from "./factory/order-optimizer-details-hidden-details-driver-list.factory";
import orderOptimizerDetailsHiddenDetailsDriverFactory from "./factory/order-optimizer-details-hidden-details-driver.factory";
import SingleSelectComponent from "../../../../../common/components/form/select/single-select/single-select.component";
import ButtonComponent from "../../../../../common/components/button/button.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

type OrderOptimizerDetailsHiddenDetailsDriverSelectProps = {
  optimizationId: string | undefined;
  onPotentialDriverChange: (
    driver: OrderOptimizerDetailsHiddenDetailsDriver | null
  ) => void;
};

const OrderOptimizerDetailsHiddenDetailsDriverSelectComponent: FC<
  OrderOptimizerDetailsHiddenDetailsDriverSelectProps
> = (props) => {
  const translations =
    orderOptimizerTranslationsHelper.getDetailsTranslations().table
      .headingTables.potentialDriver;

  const [potentialDriver, setPotentialDriver] =
    useState<OrderOptimizerDetailsHiddenDetailsDriver | null>(null);

  const [potentialDriverSearchQuery, setPotentialDriverSearchQuery] =
    useState("");
  const [potentialDriverSelectOptions, setPotentialDriversSelectOptions] =
    useState<OrderOptimizerDetailsHiddenDetailsDriverSelectOption[]>([]);

  const potentialDriverList = useOrderOptimizerPotentialDriversList();
  const potentialDriverListAbort = useAbort();

  const loadPotentialDriverListDebounced = useCallback(
    debounce(() => {
      if (!props.optimizationId) return;

      const loadParams: OrderOptimizerPotentialDriversListLoadParams = {
        optimizationId: props.optimizationId,
        name: !!potentialDriverSearchQuery
          ? potentialDriverSearchQuery
          : undefined,
      };

      return potentialDriverList.load(
        loadParams,
        potentialDriverListAbort.signal
      );
    }, 500),
    [potentialDriverSearchQuery]
  );

  useEffect(() => {
    loadPotentialDriverListDebounced();

    return () => {
      potentialDriverListAbort.revoke();
      loadPotentialDriverListDebounced.cancel();
    };
  }, [props.optimizationId, potentialDriverSearchQuery]);

  useEffect(() => {
    const potentialDriversList =
      orderOptimizerDetailsHiddenDetailsDriverListFactory.createPotentialDriverList(
        potentialDriverList.data
      );

    const options: OrderOptimizerDetailsHiddenDetailsDriverSelectOption[] =
      orderOptimizerDetailsHiddenDetailsDriverFactory.createPotentialDriverSelectOptions(
        potentialDriversList
      );

    setPotentialDriversSelectOptions(options);
  }, [potentialDriverList.data]);

  const selectedPotentialDriverSelectOption = useMemo(() => {
    if (!potentialDriver) {
      return null;
    }

    return orderOptimizerDetailsHiddenDetailsDriverFactory.createPotentialDriverSelectOption(
      potentialDriver
    );
  }, [potentialDriver]);

  const isPotentialDriverListLoading =
    !!props.optimizationId && potentialDriverList.isLoading;

  const confirmPotentialDriver = debounce((clearDriver?: boolean) => {
    if (clearDriver && !potentialDriver) return;
    props.onPotentialDriverChange(clearDriver ? null : potentialDriver);
  }, 500);

  return (
    <div className="order_optimizer_details_filter">
      <div className="order_optimizer_details_filter__header">
        <div className="order_optimizer_details_filter__header__text">
          {translations.headingLabel}
        </div>
      </div>
      <div className="order_optimizer_details_filter__select">
        <SingleSelectComponent
          placeholder={translations.selectPlaceholder}
          value={selectedPotentialDriverSelectOption}
          onChange={(
            value: OrderOptimizerDetailsHiddenDetailsDriverSelectOption | null
          ) => {
            if (!value) {
              setPotentialDriver(null);
              confirmPotentialDriver(true);
            } else {
              setPotentialDriver(value.value);
            }
          }}
          isLoading={isPotentialDriverListLoading}
          options={potentialDriverSelectOptions}
          isSearchable
          isClearable
          filterFunction={() => true}
          inputValue={potentialDriverSearchQuery}
          onInputChange={setPotentialDriverSearchQuery}
          noOptionsMessage={(inputValue) => {
            if (inputValue) {
              return translations.searchNoOptionsMessage;
            }
            return translations.searchTipMessage;
          }}
          iconRight={false}
        />
        <ButtonComponent
          classNames={{
            root: "order_optimizer_details_filter__select__input_wrapper__button",
          }}
          onClick={() => confirmPotentialDriver()}
          title={translations.selectPlaceholder}
          type="primary"
        >
          <FontAwesomeIcon
            className="order_optimizer_details_filter__select__input_wrapper__button__icon"
            icon={faMagnifyingGlass}
          />
        </ButtonComponent>
      </div>
    </div>
  );
};

export default OrderOptimizerDetailsHiddenDetailsDriverSelectComponent;
