import MileageDictionaryList from "../../../../../../common/services/mileage-dictionary/list/mileage-dictionary-list";
import MileageAddDictionaryRecordSelectOption from "../../types/mileage-add-dictionary-record-select-option";

const createSelectOption = (
  value: string
): MileageAddDictionaryRecordSelectOption => {
  return {
    label: value,
    value: value,
  };
};

const createSelectOptions = (
  dictionary: MileageDictionaryList
): MileageAddDictionaryRecordSelectOption[] => {
  return dictionary.data.map((item) => createSelectOption(item.name));
};

const mileageAddDictionaryRecordFactory = {
  createSelectOption,
  createSelectOptions,
};

export default mileageAddDictionaryRecordFactory;
