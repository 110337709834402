import MileageUpdateAddress from "../types/mileage-update-address";
import MileageUpdateFormData from "../form/types/mileage-update-form-data";
import MileageUpdateVehicleType from "../types/mileage-update-vehicle-type";
import MileageUpdateParams, {
  MileageUpdateParamsNode,
  MileageUpdateParamsStatus,
  MileageUpdateParamsVehicleType,
} from "../../../../../common/services/mileage/update/mileage-update-params";

const createNode = (address: MileageUpdateAddress): MileageUpdateParamsNode => {
  return {
    displayName: address.displayName,
    lat: address.latitude,
    lon: address.longitude,
    time: address.time,
    apartmentNumber: address.apartmentNumber,
    houseNumber: address.houseNumber,
    street: address.street,
    town: address.town,
    zipCode: address.zipCode,
  };
};

const createNodes = (
  address: MileageUpdateAddress[],
  startDate: Date,
  pointToPointDurations: number[]
) => {
  const nodes = address.map(createNode);

  nodes[0].time = startDate;
  nodes[0].distance = 0;
  pointToPointDurations.forEach((duration, index) => {
    nodes[index + 1].time = new Date(
      nodes[index].time?.getTime()! + 1000 * duration
    );
  });

  pointToPointDurations.forEach((distance, index) => {
    nodes[index + 1].distance = distance;
  });

  return nodes;
};

const createVehicleType = (
  type: MileageUpdateVehicleType
): MileageUpdateParamsVehicleType => {
  switch (type) {
    case MileageUpdateVehicleType.CAR_GT_900CM3:
      return MileageUpdateParamsVehicleType.CAR_GT_900CM3;
    case MileageUpdateVehicleType.CAR_LE_900CM3:
      return MileageUpdateParamsVehicleType.CAR_LE_900CM3;
    case MileageUpdateVehicleType.COMPANY_OWNED:
      return MileageUpdateParamsVehicleType.COMPANY_OWNED;
    case MileageUpdateVehicleType.MOPED:
      return MileageUpdateParamsVehicleType.MOPED;
    case MileageUpdateVehicleType.MOTORCYCLE:
      return MileageUpdateParamsVehicleType.MOTORCYCLE;
  }
};

const create = (
  formData: MileageUpdateFormData,
  mileageUuid: string,
  pointToPointDurations: number[]
): MileageUpdateParams => {
  return {
    mileageUuid: mileageUuid,
    addressSequence: createNodes(
      formData.addressSequence,
      formData.mileageDate!,
      pointToPointDurations
    ),
    distance: formData.distance ?? undefined,
    osrmDistance: formData.osrmDistance ?? undefined,
    mileageDate: formData.mileageDate!,
    vehicleType: createVehicleType(formData.vehicleType),
    state: MileageUpdateParamsStatus.PENDING,
  };
};

const mileageUpdateParamsFactory = {
  create,
};

export default mileageUpdateParamsFactory;
