import { FC } from "react";
import { DriverDetailsDriverPlanEntryWaypoint } from "../common/types/driver-details-plan-entry";
import driverDetailsPlanByRailyRouteListingHelper from "./driver-details-plan-route-listing.helper";
import DriverDetailsPlanEntryRouteTableRow from "../common/types/driver-details-plan-entry-route-table-row";
import TableComponent from "../../../../../../common/components/table/table.component";

type DriverDetailsPlanByRailyRouteListingProps = {
  routeWaypoints: DriverDetailsDriverPlanEntryWaypoint[];
};

const DriverDetailsPlanByRailyRouteListingComponent: FC<
  DriverDetailsPlanByRailyRouteListingProps
> = (props) => {
  const columns = driverDetailsPlanByRailyRouteListingHelper.getColumns();

  const createTableRow = (
    routeWaypoint: DriverDetailsDriverPlanEntryWaypoint,
    index: number
  ): DriverDetailsPlanEntryRouteTableRow => {
    const clientLabel =
      driverDetailsPlanByRailyRouteListingHelper.getClientLabel(
        routeWaypoint.clientDisplayName
      );

    const clientTitle =
      driverDetailsPlanByRailyRouteListingHelper.getClientTitle(
        routeWaypoint.clientDisplayName
      );

    const orderIdLabel =
      driverDetailsPlanByRailyRouteListingHelper.getOrderIdLabel(
        routeWaypoint.order?.cargoInternalOrderId
      );

    const orderIdTitle =
      driverDetailsPlanByRailyRouteListingHelper.getOrderIdTitle(
        routeWaypoint.order?.cargoInternalOrderId
      );

    const plannedDateLabel =
      driverDetailsPlanByRailyRouteListingHelper.getPlannedDateLabel(
        routeWaypoint.plannedDate
      );

    const plannedDateTitle =
      driverDetailsPlanByRailyRouteListingHelper.getPlannedDateTitle(
        routeWaypoint.plannedDate
      );

    const estimatedDateLabel =
      driverDetailsPlanByRailyRouteListingHelper.getEstimatedDateLabel(
        routeWaypoint.estimatedDate
      );

    const estimatedDateTitle =
      driverDetailsPlanByRailyRouteListingHelper.getEstimatedDateTitle(
        routeWaypoint.estimatedDate
      );

    const checkoutDateLabel =
      driverDetailsPlanByRailyRouteListingHelper.getCheckoutDateLabel(
        routeWaypoint.checkoutDate
      );

    const checkoutDateTitle =
      driverDetailsPlanByRailyRouteListingHelper.getCheckoutDateTitle(
        routeWaypoint.checkoutDate
      );

    const plannedHaltingTimeLabel =
      driverDetailsPlanByRailyRouteListingHelper.getPlannedHaltingTimeLabel(
        routeWaypoint.plannedHaltingTime
      );

    const plannedHaltingTimeTitle =
      driverDetailsPlanByRailyRouteListingHelper.getPlannedHaltingTimeTitle(
        routeWaypoint.plannedHaltingTime
      );

    const forcedHaltingTimeLabel =
      driverDetailsPlanByRailyRouteListingHelper.getForcedHaltingTimeLabel(
        routeWaypoint.forcedHaltingTime
      );

    const forcedHaltingTimeTitle =
      driverDetailsPlanByRailyRouteListingHelper.getForcedHaltingTimeTitle(
        routeWaypoint.forcedHaltingTime
      );

    const highwayCostLabel =
      driverDetailsPlanByRailyRouteListingHelper.getHighwayCostLabel(
        routeWaypoint.highwayCost
      );

    const highwayCostTitle =
      driverDetailsPlanByRailyRouteListingHelper.getHighwayCostTitle(
        routeWaypoint.highwayCost
      );

    const createdAtLabel =
      driverDetailsPlanByRailyRouteListingHelper.getCreatedAtLabel(
        routeWaypoint.createdAt
      );

    const createdAtTitle =
      driverDetailsPlanByRailyRouteListingHelper.getCreatedAtTitle(
        routeWaypoint.createdAt
      );

    const createdByNameLabel =
      driverDetailsPlanByRailyRouteListingHelper.getCreatedByNameLabel(
        routeWaypoint.createdByName
      );

    const createdByNameTitle =
      driverDetailsPlanByRailyRouteListingHelper.getCreatedByNameTitle(
        routeWaypoint.createdByName
      );

    return {
      id: routeWaypoint.uuid,
      value: {
        client: <div title={clientTitle}>{clientLabel}</div>,
        order: <div title={orderIdTitle}>{orderIdLabel}</div>,
        address: (
          <div title={routeWaypoint.address.displayName}>
            {routeWaypoint.address.displayName}
          </div>
        ),
        plannedDate: <div title={plannedDateTitle}>{plannedDateLabel}</div>,
        estimatedDate: (
          <div title={estimatedDateTitle}>{estimatedDateLabel}</div>
        ),
        checkoutDate: <div title={checkoutDateTitle}>{checkoutDateLabel}</div>,
        plannedHaltingTime: (
          <div title={plannedHaltingTimeTitle}>{plannedHaltingTimeLabel}</div>
        ),
        forcedHaltingTime: (
          <div title={forcedHaltingTimeTitle}>{forcedHaltingTimeLabel}</div>
        ),
        highwayCost: <div title={highwayCostTitle}>{highwayCostLabel}</div>,
        createdAt: <div title={createdAtTitle}>{createdAtLabel}</div>,
        createdByName: (
          <div title={createdByNameTitle}>{createdByNameLabel}</div>
        ),
      },
    };
  };

  const rows = props.routeWaypoints.map(createTableRow);

  return <TableComponent columns={columns} rows={rows} />;
};

export default DriverDetailsPlanByRailyRouteListingComponent;
