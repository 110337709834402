import Joi from "joi";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import userService from "../../../../../../common/services/user/user.service";
import userTranslationsHelper from "../../../../../../languages/user-translations.helper";
import FormValidationResult from "../../../../../../common/utils/validation/types/form-validation-result";

const validateEmail = (email: string) => {
  const validationSchema = Joi.string()
    .email({ tlds: { allow: false } })
    .required();

  return formValidationService.validate(email, validationSchema);
};

const validateUsername = async (
  username: string
): Promise<FormValidationResult> => {
  const pattern = /^[A-Za-z0-9]+([-_.]?[A-Za-z0-9]+)*$/;

  const validationSchema = Joi.string()
    .min(3)
    .max(40)
    .regex(pattern)
    .required();

  const validationResult = formValidationService.validate(
    username,
    validationSchema
  );
  if (!validationResult.isValid) {
    return validationResult;
  }

  return await checkIfUserExists(username);
};

const checkIfUserExists = async (
  username: string
): Promise<FormValidationResult> => {
  const abortController = new AbortController();

  try {
    const isUserExists = (
      await userService.checkUserExists({ username }, abortController.signal)
    ).isExists;

    if (isUserExists) {
      const errorMessage =
        userTranslationsHelper.getUserAddTranslations()
          .userExistsValidationMessage;

      return formValidationService.bad(errorMessage);
    }
  } catch {}

  return formValidationService.ok();
};

const validatePassword = (value: string) => {
  const validationSchema = Joi.string().min(12).max(50).required();
  return formValidationService.validate(value, validationSchema);
};

const validatePasswordRepeat = (
  passwordRepeat: string,
  originalPassword: string
) => {
  const validationResult = validatePassword(passwordRepeat);
  if (!validationResult.isValid) {
    return validationResult;
  }

  const passwordRepeatSchema = Joi.string().valid(originalPassword).required();
  const matchResult = formValidationService.validate(
    passwordRepeat,
    passwordRepeatSchema
  );

  if (!matchResult.isValid) {
    matchResult.errorMessage =
      formValidationService.getValidationCustomMessages().passwordRepeatValidationError;
  }

  return matchResult;
};

const driverAddAccountFormValidationService = {
  validateEmail,
  validateUsername,
  validatePassword,
  validatePasswordRepeat,
  checkIfUserExists,
};

export default driverAddAccountFormValidationService;
