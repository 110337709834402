import { FC, useEffect, useState } from "react";
import ModalComponent, {
  ModalProps,
} from "../../../../common/components/modal/modal.component";
import ButtonComponent from "../../../../common/components/button/button.component";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import DateTimeInputComponent from "../../../../common/components/form/input/date-time/date-time-input.component";
import dateService from "../../../../common/utils/date/date.service";
import orderDetailsManageDriverAssignmentCollisionFormValidationService from "./common/form/order-details-manage-driver-assignment-collision-form-validation.service";
import FormValidationResult from "../../../../common/utils/validation/types/form-validation-result";
import formValidationService from "../../../../common/utils/validation/form-validation.service";
import useAbort from "../../../../common/hooks/use-abort";
import orderRouteEditApiService from "../../route-edit/common/api/order-route-edit-api.service";
import OrderRouteEditRequestBody from "../../route-edit/common/api/order-route-edit.request";
import useCargoOrderDetails from "../../../../common/services/cargo-order/details/use-cargo-order-details";
import CargoOrderDetailsLoadParams from "../../../../common/services/cargo-order/details/cargo-order-details-load-params";
import orderDetailsManageDriverAssignmentCollisionEditRequestFactory from "./common/factory/order-details-manage-driver-assignment-collision-edit-request.factory";
import OrderRouteEditResponse from "../../route-edit/common/api/order-route-edit.response";
import notificationService from "../../../../common/utils/notification/notification.service";
import DriverPlanCandidatureAddByOrderIdParams from "../../../../common/services/driver-plan/candidature-add-by-order-id/driver-plan-candidature-add-by-order-id-params";
import driverPlanService from "../../../../common/services/driver-plan/driver-plan.service";
import orderDetailsManageDriverAssignmentCollisionHelper from "./common/order-details-manage-driver-assignment-collision.helper";
import { OrderDetailsManageDriverAssignmentDriverAddress } from "../manage-driver-assignment/common/types/order-details-manage-driver-assignment-driver";

type OrderDetailsManageDriverAssignmentCollisionProps = Pick<
  ModalProps,
  "isOpen"
> & {
  onCloseCancel: () => void;
  onCloseSuccess: () => void;
  orderUuid: string;
  cargoCompanyOrderId: number;
  estimatedStartTime: Date;
  minimumStartTime: Date;
  candidatureAddRequest: DriverPlanCandidatureAddByOrderIdParams;
};

const OrderDetailsManageDriverAssignmentCollisionModal: FC<
  OrderDetailsManageDriverAssignmentCollisionProps
> = (props) => {
  const translations =
    orderTranslationsHelper.getDetailsTranslations().manageDriverAssignment
      .collisionModal;

  const cargoOrderDetails = useCargoOrderDetails();
  const cargoOrderDetailsAbort = useAbort();

  useEffect(() => {
    const params: CargoOrderDetailsLoadParams = {
      orderUuid: props.orderUuid,
    };
    cargoOrderDetails.load(params, cargoOrderDetailsAbort.signal);

    return cargoOrderDetailsAbort.revoke;
  }, []);

  const [plannedStartDate, setPlannedStartDate] = useState<Date | null>(
    new Date(props.minimumStartTime)
  );
  const [
    plannedStartDateValidationResult,
    setPlannedStartDateValidationResult,
  ] = useState<FormValidationResult>(
    formValidationService.defaultValidationResult
  );

  const candidatureAddByOrderIdAbort = useAbort();
  const [collisionIsBeingProccessed, setCollisionIsBeingProccessed] =
    useState(false);

  const validatePlannedStartDate = () => {
    const plannedDateValidation =
      orderDetailsManageDriverAssignmentCollisionFormValidationService.validatePlannedStartDate(
        plannedStartDate,
        props.minimumStartTime
      );

    setPlannedStartDateValidationResult(plannedDateValidation);

    return plannedDateValidation;
  };

  const onCandidatureAddByOrderIdSuccess = () => {
    notificationService.success(translations.candidatureSuccessfulNotification);
    props.onCloseSuccess();
  };

  const onCandidatureAddByOrderIdFailure = () => {
    notificationService.error(translations.orderEditErrorNotification);
  };

  const handleOrderEditSuccessResponse = async () => {
    try {
      await driverPlanService.candidatureAddByOrderId(
        props.candidatureAddRequest,
        candidatureAddByOrderIdAbort.signal
      );

      onCandidatureAddByOrderIdSuccess();
    } catch (error) {
      onCandidatureAddByOrderIdFailure();
    } finally {
      setCollisionIsBeingProccessed(false);
    }
  };

  const handleOrderEditErrorResponse = () => {
    setCollisionIsBeingProccessed(false);
    notificationService.error(translations.orderEditErrorNotification);
  };

  const handleOrderEditResponse = (response: OrderRouteEditResponse) => {
    if (response.status === 200) {
      handleOrderEditSuccessResponse();
      return;
    }

    handleOrderEditErrorResponse();
  };

  const submitForm = async () => {
    if (!cargoOrderDetails.data) return;

    const plannedDateValidation = validatePlannedStartDate();

    if (!plannedDateValidation.isValid) {
      return;
    }

    const request: OrderRouteEditRequestBody =
      orderDetailsManageDriverAssignmentCollisionEditRequestFactory.createRequest(
        cargoOrderDetails.data,
        plannedStartDate!
      );

    setCollisionIsBeingProccessed(true);

    orderRouteEditApiService
      .editOrder(props.orderUuid, request)
      .then(handleOrderEditResponse);
  };

  const collisionModalHeader =
    orderDetailsManageDriverAssignmentCollisionHelper.createHeader(
      props.cargoCompanyOrderId.toString()
    );

  return (
    <ModalComponent
      isOpen={props.isOpen}
      onClose={props.onCloseCancel}
      header={collisionModalHeader}
      actions={[
        <ButtonComponent
          onClick={submitForm}
          type="primary"
          title={translations.form.submitButtonTitle}
          isLoading={collisionIsBeingProccessed}
        >
          {translations.form.submitButtonText}
        </ButtonComponent>,
        <ButtonComponent
          type="brand"
          title={translations.form.rejectButtonTitle}
          onClick={props.onCloseCancel}
        >
          {translations.form.rejectButtonText}
        </ButtonComponent>,
      ]}
    >
      <FormFieldComponent label={translations.form.estimatedStartTimeLabel}>
        {props.estimatedStartTime
          ? dateService.formatDateTime(props.estimatedStartTime)
          : ""}
      </FormFieldComponent>
      <FormFieldComponent label={translations.form.minimumStartTimeLabel}>
        {props.minimumStartTime
          ? dateService.formatDateTime(props.minimumStartTime)
          : ""}
      </FormFieldComponent>
      <FormFieldComponent
        label={translations.form.plannedStartTimeLabel}
        errorMessage={plannedStartDateValidationResult.errorMessage}
        isRequired
      >
        <DateTimeInputComponent
          date={plannedStartDate}
          minDate={new Date(props.minimumStartTime.getTime())}
          onChange={(date) => {
            setPlannedStartDate(date);
          }}
          onBlur={validatePlannedStartDate}
          hasError={!plannedStartDateValidationResult.isValid}
        />
      </FormFieldComponent>
    </ModalComponent>
  );
};

export default OrderDetailsManageDriverAssignmentCollisionModal;
