import Joi from "joi";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import userTranslationsHelper from "../../../../../../languages/user-translations.helper";
import DriverAddFormOfEmploymentType from "../types/driver-add-form-of-employment-type";
import DriverAddTaxiCorporation from "../types/driver-add-taxi-corporation";
import DriverAddUserFleetPartner from "../types/driver-add-user-fleet-partner";

const validateTaxiCorporation = (
  value: DriverAddTaxiCorporation | null | undefined
) => {
  const translations = userTranslationsHelper.getDriverAddTranslations();

  const validationSchema = Joi.object().required().messages({
    "any.required": translations.selectComponentNotificationError,
    "object.base": translations.selectComponentNotificationError,
  });

  const result = formValidationService.validate(value, validationSchema);

  return result;
};

const validateFormOfEmployment = (
  value: DriverAddFormOfEmploymentType | null
) => {
  const translations = userTranslationsHelper.getDriverAddTranslations();

  const validationSchema = Joi.string()
    .valid(...Object.values(DriverAddFormOfEmploymentType))
    .required()
    .messages({
      "any.only": translations.selectComponentNotificationError,
    });

  return formValidationService.validate(value, validationSchema);
};

const validateFleetPartner = (value: DriverAddUserFleetPartner | null) => {
  const validationSchema = Joi.object().allow(null).required();

  return formValidationService.validate(value, validationSchema);
};

const validateName = (name: string) => {
  const validationSchema = Joi.string().trim().min(1).max(100).required();

  return formValidationService.validate(name, validationSchema);
};

const validateCompanyId = (companyId: string) => {
  const validationSchema = Joi.string().allow("").trim().min(2).max(14);

  return formValidationService.validate(companyId, validationSchema);
};

const validateTaxNumber = (taxNumber: string) => {
  const validationSchema = Joi.string().trim().min(2).max(10).required();

  return formValidationService.validate(taxNumber, validationSchema);
};

const validateNationalCourtRegister = (nationalCourtRegister: string) => {
  const validationSchema = Joi.string().trim().allow("").min(2).max(10);

  return formValidationService.validate(
    nationalCourtRegister,
    validationSchema
  );
};

const validateStreet = (street: string) => {
  const validationSchema = Joi.string().trim().min(2).max(50).required();

  return formValidationService.validate(street, validationSchema);
};

const validateHouseNumber = (houseNumber: string) => {
  const validationSchema = Joi.string().trim().min(1).max(10).required();

  return formValidationService.validate(houseNumber, validationSchema);
};

const validateApartmentNumber = (apartmentNumber: string) => {
  const validationSchema = Joi.string().trim().allow("").max(10).required();

  return formValidationService.validate(apartmentNumber, validationSchema);
};

const validateTown = (town: string) => {
  const validationSchema = Joi.string().trim().min(2).max(50).required();

  return formValidationService.validate(town, validationSchema);
};

const validateCountry = (country: string) => {
  const validationSchema = Joi.string().trim().min(2).max(50).required();

  return formValidationService.validate(country, validationSchema);
};

const validateZipCode = (zipCode: string) => {
  const pattern = new RegExp(`^[0-9\\-]*$`);
  const validationSchema = Joi.string()
    .trim()
    .min(2)
    .max(10)
    .pattern(pattern)
    .required();

  return formValidationService.validate(zipCode, validationSchema);
};

const validateDescription = (description: string) => {
  const validationSchema = Joi.string()
    .trim()
    .allow("")
    .min(1)
    .max(100)
    .required();

  return formValidationService.validate(description, validationSchema);
};

const driverAddTaxiCorporationFormValidationService = {
  validateTaxiCorporation,
  validateFormOfEmployment,
  validateFleetPartner,
  validateName,
  validateCompanyId,
  validateTaxNumber,
  validateNationalCourtRegister,
  validateStreet,
  validateHouseNumber,
  validateApartmentNumber,
  validateTown,
  validateCountry,
  validateZipCode,
  validateDescription,
};

export default driverAddTaxiCorporationFormValidationService;
