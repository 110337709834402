const billingRoutesUrls = {
  cargoOrderListing: `/billings/cargo/order-listing`,
  railyUnbilledOrderListing: `/billings/raily/unbilled-order-listing`,
  railyOrderListing: `/billings/raily/order-listing`,
  taxiOrderListing: `/billings/taxi/order-listing`,
  taxiRouteListing: `/billings/taxi/route-listing`,
  taxiUnbilledOrderListing: `/billings/taxi/unbilled-order-listing`,
  taxiUnbilledPrivateOrderListing: `/billings/taxi/unbilled-private-order-listing`,
  taxiUnbilledRouteListing: `/billings/taxi/unbilled-route-listing`,
  taxiUnbilledTransferredOrderListing: `/billings/taxi/unbilled-transferred-order-listing`,
  taxiTransferredOrderListing: `/billings/taxi/transferred-order-listing`,
  cargoWithRailyAdd: `/billings/cargo-raily/order/:orderUuid/add`,
  railyWithTaxiAdd: `/billings/raily-taxi/order/:orderUuid/add`,
  cargoWithTaxiAdd: `/billings/cargo-taxi/order/:orderUuid/add`,
  taxiWithDriverAdd: `/billings/taxi-driver/plan-entry/:planEntryUuid/add`,
  taxiWithRailyAdd: `/billings/taxi-raily/order/:orderUuid/add`,
  cargoWithTaxiEdit: `/billings/cargo-taxi/:billingUuid/edit`,
  cargoWithTaxiDetailsForCargo: `/billings/cargo-taxi/cargo/:billingUuid/details`,
  cargoWithTaxiDetailsForTaxi: `/billings/cargo-taxi/taxi/:billingUuid/details`,
  taxiWithDriverEdit: `/billings/taxi-driver/:billingUuid/edit`,
  taxiWithTaxiAdd: `/billings/taxi-taxi/:forwardingUuid/add`,
  taxiWithTaxiEdit: `/billings/taxi-taxi/:billingUuid/edit`,
  taxiWithTaxiDetails: `/billings/taxi-taxi/:billingUuid/details`,
  taxiWithDriverDetails: `/billings/taxi-driver/:billingUuid/details`,
};

export default billingRoutesUrls;
