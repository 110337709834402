import { FC, useMemo } from "react";
import { BillingSummaryData } from "../types/billing-form.data";
import BillingDetailsTableRow from "./types/billing-details-table-row";
import dateService from "../../../../../common/utils/date/date.service";
import billingDetailsHelper from "./billing-details.helper";
import TableComponent from "../../../../../common/components/table/table.component";

type OrderBillingDetailsProps = {
  billingSummaryData: BillingSummaryData | undefined;
};

const BillingDetailsComponent: FC<OrderBillingDetailsProps> = (props) => {
  const createTableRow = (
    billingSummaryData: BillingSummaryData
  ): BillingDetailsTableRow => {
    return {
      id: billingSummaryData.internalOrderId.toString(),
      value: {
        date: (
          <div
            title={dateService.format(billingSummaryData.date, "dd/mm/yyyy")}
          >
            {dateService.format(billingSummaryData.date, "dd/mm/yyyy")}
          </div>
        ),
        internalOrderId: (
          <div title={billingSummaryData.internalOrderId.toString()}>
            {billingSummaryData.internalOrderId}
          </div>
        ),
        companyName: (
          <div title={billingSummaryData.contractDetails?.companyName}>
            {billingSummaryData.contractDetails?.companyName}
          </div>
        ),
        passengers: (
          <div>
            {billingSummaryData.passengers.map((passenger, index) => (
              <div key={index} title={passenger}>
                {passenger}
              </div>
            ))}
          </div>
        ),
        model: (
          <div title={billingSummaryData.contractDetails?.model}>
            {billingSummaryData.contractDetails?.model}
          </div>
        ),
        rate: (
          <div
            title={billingSummaryData.contractDetails?.distanceRate?.toString()}
          >
            {billingSummaryData.contractDetails?.distanceRate?.toFixed(2)} PLN
          </div>
        ),
        distance: (
          <div title={billingSummaryData.distance?.toString()}>
            {billingSummaryData.distance}
          </div>
        ),
        baseAmountValue: (
          <div title={billingSummaryData.baseAmountValue.toString()}>
            {billingSummaryData.baseAmountValue?.toFixed(2)} PLN
          </div>
        ),
        amountForDistance: (
          <div title={billingSummaryData.amountForDistance.toString()}>
            {billingSummaryData.amountForDistance?.toFixed(2)} PLN
          </div>
        ),
        contractCorrectionPercentage: (
          <div
            title={billingSummaryData.contractCorrectionPercentage.toString()}
          >
            {billingSummaryData.contractCorrectionPercentage.toFixed(2)}%
          </div>
        ),
        sumOfDiscounts: (
          <div title={billingSummaryData.sumOfDiscounts.toString()}>
            {billingSummaryData.sumOfDiscounts?.toFixed(2)} PLN
          </div>
        ),
        amountForChargeHaltings: (
          <div title={billingSummaryData.amountForChargeHaltings.toString()}>
            {billingSummaryData.amountForChargeHaltings?.toFixed(2)} PLN
          </div>
        ),
        amountForChargeHighways: (
          <div title={billingSummaryData.amountForChargeHighways.toString()}>
            {billingSummaryData.amountForChargeHighways?.toFixed(2)} PLN
          </div>
        ),
        allContributionsAmount: (
          <div title={billingSummaryData?.allContributionsAmount.toString()}>
            {billingSummaryData.allContributionsAmount?.toFixed(2)} PLN
          </div>
        ),
      },
    };
  };

  const tableColumns = billingDetailsHelper.getTableColumns();

  const tableRows: BillingDetailsTableRow[] = useMemo(() => {
    if (props.billingSummaryData) {
      const tableRow = createTableRow(props.billingSummaryData);
      return [tableRow];
    }

    return [];
  }, [props.billingSummaryData]);

  return <TableComponent columns={tableColumns} rows={tableRows} />;
};

export default BillingDetailsComponent;
