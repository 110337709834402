import TaxiOfficerListingFilter, {
  TaxiOfficerListingEmailAddressFilter,
  TaxiOfficerListingFirstNameFilter,
  TaxiOfficerListingLastNameFilter,
  TaxiOfficerListingStatusFilter,
  TaxiOfficerListingUserNameFilter,
} from "./types/taxi-officer-listing-filter";
import TaxiOfficerListingFilterType from "./types/taxi-officer-listing-filter-type";
import TaxiOfficerListingSortKey from "./types/taxi-officer-listing-sort-key";
import TaxiOfficerListLoadParams, {
  TaxiOfficerListLoadParamsOrder,
} from "../../../../../common/services/taxi-officer/list/taxi-officer-list-load-params";

const createOrder = (
  sortKey: TaxiOfficerListingSortKey | null
): TaxiOfficerListLoadParamsOrder | undefined => {
  const definition: Record<
    TaxiOfficerListingSortKey,
    TaxiOfficerListLoadParamsOrder
  > = {
    [TaxiOfficerListingSortKey.FIRST_NAME_ASC]:
      TaxiOfficerListLoadParamsOrder.FIRST_NAME_ASC,
    [TaxiOfficerListingSortKey.FIRST_NAME_DESC]:
      TaxiOfficerListLoadParamsOrder.FIRST_NAME_DESC,
    [TaxiOfficerListingSortKey.LAST_NAME_ASC]:
      TaxiOfficerListLoadParamsOrder.LAST_NAME_ASC,
    [TaxiOfficerListingSortKey.LAST_NAME_DESC]:
      TaxiOfficerListLoadParamsOrder.LAST_NAME_DESC,
    [TaxiOfficerListingSortKey.USERNAME_ASC]:
      TaxiOfficerListLoadParamsOrder.USERNAME_ASC,
    [TaxiOfficerListingSortKey.USERNAME_DESC]:
      TaxiOfficerListLoadParamsOrder.USERNAME_DESC,
    [TaxiOfficerListingSortKey.EMAIL_ADDRESS_ASC]:
      TaxiOfficerListLoadParamsOrder.EMAIL_ADDRESS_ASC,
    [TaxiOfficerListingSortKey.EMAIL_ADDRESS_DESC]:
      TaxiOfficerListLoadParamsOrder.EMAIL_ADDRESS_DESC,
    [TaxiOfficerListingSortKey.STATUS_ASC]:
      TaxiOfficerListLoadParamsOrder.STATUS_ASC,
    [TaxiOfficerListingSortKey.STATUS_DESC]:
      TaxiOfficerListLoadParamsOrder.STATUS_DESC,
  };

  return sortKey ? definition[sortKey] : undefined;
};

const create = (
  page: number,
  pageSize: number,
  filters: TaxiOfficerListingFilter[],
  sortKey: TaxiOfficerListingSortKey | null
): TaxiOfficerListLoadParams => {
  const statusFilter = filters.find(
    (filter) => filter.type === TaxiOfficerListingFilterType.ACTIVITY_STATUS
  )?.value as TaxiOfficerListingStatusFilter["value"] | undefined;

  return {
    page,
    pageSize,
    firstName: filters.find(
      (filter) => filter.type === TaxiOfficerListingFilterType.FIRST_NAME
    )?.value as TaxiOfficerListingFirstNameFilter["value"] | undefined,
    lastName: filters.find(
      (filter) => filter.type === TaxiOfficerListingFilterType.LAST_NAME
    )?.value as TaxiOfficerListingLastNameFilter["value"] | undefined,
    email: filters.find(
      (filter) => filter.type === TaxiOfficerListingFilterType.EMAIL
    )?.value as TaxiOfficerListingEmailAddressFilter["value"] | undefined,
    username: filters.find(
      (filter) => filter.type === TaxiOfficerListingFilterType.USERNAME
    )?.value as TaxiOfficerListingUserNameFilter["value"] | undefined,
    status: statusFilter,
    order: createOrder(sortKey),
  };
};

const taxiOfficerListingLoadParamsFactory = {
  create,
};

export default taxiOfficerListingLoadParamsFactory;
