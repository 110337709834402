import DriverEditRequest, {
  DriverEditRequestAddress,
  DriverEditRequestAddressType,
  DriverEditRequestCarOwnership,
  DriverEditRequestCitizenship,
  DriverEditRequestDriver,
  DriverEditRequestFormOfEmployment,
  DriverEditRequestLanguage,
  DriverEditRequestTaxiDriverAssociation,
  DriverEditRequestUser,
} from "../../../api/raily/driver/edit/driver-edit.request";
import DriverEditParams, {
  DriverEditParamsAddress,
  DriverEditParamsAddressType,
  DriverEditParamsCarOwnership,
  DriverEditParamsCitizenship,
  DriverEditParamsDriver,
  DriverEditParamsFormOfEmployment,
  DriverEditParamsLanguage,
  DriverEditParamsTaxiDriverAssociation,
  DriverEditParamsUser,
} from "./driver-edit-params";

const createFormOfEmployment = (
  formOfEmployment: DriverEditParamsFormOfEmployment
): DriverEditRequestFormOfEmployment => {
  switch (formOfEmployment) {
    case DriverEditParamsFormOfEmployment.B2B:
      return DriverEditRequestFormOfEmployment.B2B;
    case DriverEditParamsFormOfEmployment.EMPLOYMENT:
      return DriverEditRequestFormOfEmployment.EMPLOYMENT;
  }
};

const createLanguage = (
  type: DriverEditParamsLanguage
): DriverEditRequestLanguage => {
  switch (type) {
    case DriverEditParamsLanguage.BY:
      return DriverEditRequestLanguage.BY;
    case DriverEditParamsLanguage.CZ:
      return DriverEditRequestLanguage.CZ;
    case DriverEditParamsLanguage.DE:
      return DriverEditRequestLanguage.DE;
    case DriverEditParamsLanguage.LT:
      return DriverEditRequestLanguage.LT;
    case DriverEditParamsLanguage.PL:
      return DriverEditRequestLanguage.PL;
    case DriverEditParamsLanguage.RU:
      return DriverEditRequestLanguage.RU;
    case DriverEditParamsLanguage.SK:
      return DriverEditRequestLanguage.SK;
    case DriverEditParamsLanguage.UA:
      return DriverEditRequestLanguage.UA;
    case DriverEditParamsLanguage.EN:
      return DriverEditRequestLanguage.EN;
    case DriverEditParamsLanguage.ES:
      return DriverEditRequestLanguage.ES;
    case DriverEditParamsLanguage.FR:
      return DriverEditRequestLanguage.FR;
  }
};

const createLanguages = (
  langueages: DriverEditParamsLanguage[]
): DriverEditRequestLanguage[] => {
  const requestLanguages: DriverEditRequestLanguage[] =
    langueages.map(createLanguage);

  return requestLanguages;
};

const createCitizenship = (
  type: DriverEditParamsCitizenship
): DriverEditRequestCitizenship => {
  switch (type) {
    case DriverEditParamsCitizenship.BY:
      return DriverEditRequestCitizenship.BY;
    case DriverEditParamsCitizenship.CZ:
      return DriverEditRequestCitizenship.CZ;
    case DriverEditParamsCitizenship.DE:
      return DriverEditRequestCitizenship.DE;
    case DriverEditParamsCitizenship.LT:
      return DriverEditRequestCitizenship.LT;
    case DriverEditParamsCitizenship.PL:
      return DriverEditRequestCitizenship.PL;
    case DriverEditParamsCitizenship.RU:
      return DriverEditRequestCitizenship.RU;
    case DriverEditParamsCitizenship.SK:
      return DriverEditRequestCitizenship.SK;
    case DriverEditParamsCitizenship.UA:
      return DriverEditRequestCitizenship.UA;
  }
};

const createCarOwnership = (
  type: DriverEditParamsCarOwnership
): DriverEditRequestCarOwnership => {
  switch (type) {
    case DriverEditParamsCarOwnership.BUSINESS:
      return DriverEditRequestCarOwnership.BUSINESS;
    case DriverEditParamsCarOwnership.OWNER:
      return DriverEditRequestCarOwnership.OWNER;
  }
};

const createAddressType = (
  type: DriverEditParamsAddressType
): DriverEditRequestAddressType => {
  switch (type) {
    case DriverEditParamsAddressType.MAILING:
      return DriverEditRequestAddressType.MAILING;
    case DriverEditParamsAddressType.STARTING:
      return DriverEditRequestAddressType.STARTING;
  }
};

const createTaxiDriverAssociation = (
  association: DriverEditParamsTaxiDriverAssociation
): DriverEditRequestTaxiDriverAssociation => {
  if ("taxId" in association) {
    return {
      apartment: association.apartment,
      companyName: association.companyName,
      contractType: createFormOfEmployment(association.contractType),
      country: association.country,
      description: association.description,
      fleetPartnerId: association.fleetPartnerId,
      houseNo: association.houseNo,
      id: association.id,
      krsNo: association.krsNo,
      regon: association.regon,
      street: association.street,
      taxId: association.taxId,
      town: association.town,
      zipCode: association.zipCode,
    };
  } else {
    return {
      contractType: createFormOfEmployment(association.contractType),
      fleetPartnerId: association.fleetPartnerId,
      id: association.id,
    };
  }
};

const createTaxiDriverAssociations = (
  associations: DriverEditParamsTaxiDriverAssociation[]
): DriverEditRequestTaxiDriverAssociation[] => {
  return associations.map(createTaxiDriverAssociation);
};

const createUser = (user: DriverEditParamsUser): DriverEditRequestUser => {
  return {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    mobilePrimary: user.mobilePrimary,
    mobilePrimaryPrefix: user.mobilePrimaryPrefix,
    mobileSecondary: user.mobileSecondary,
    mobileSecondaryPrefix: user.mobileSecondaryPrefix,
  };
};

const createAddress = (
  address: DriverEditParamsAddress
): DriverEditRequestAddress => {
  return {
    apartment: address.apartment,
    country: address.country,
    description: address.description,
    houseNo: address.houseNo,
    lat: address.lat,
    lon: address.lon,
    street: address.street,
    town: address.town,
    type: createAddressType(address.type),
    zipCode: address.zipCode,
  };
};

const createAddresses = (
  addresses: DriverEditParamsAddress[]
): DriverEditRequestAddress[] => {
  const requestAddresses: DriverEditRequestAddress[] =
    addresses.map(createAddress);

  return requestAddresses;
};

const createDriver = (
  driver: DriverEditParamsDriver
): DriverEditRequestDriver => {
  return {
    birthDate: driver.birthDate,
    birthPlace: driver.birthPlace,
    carMake: driver.carMake,
    carModel: driver.carModel,
    carOwnership: createCarOwnership(driver.carOwnership),
    carProdYear: driver.carProdYear,
    carRegNo: driver.carRegNo,
    carSeatsNo: driver.carSeatsNo,
    citizenship: createCitizenship(driver.citizenship),
    experience: driver.experience,
    idNumber: driver.idNumber,
    languages: createLanguages(driver.languages),
  };
};

const create = (params: DriverEditParams): DriverEditRequest => {
  return {
    driver: createDriver(params.driver),
    addresses: createAddresses(params.addresses),
    associations: createTaxiDriverAssociations(params.associations),
    user: createUser(params.user),
  };
};

const driverEditRequestFactory = {
  create,
};

export default driverEditRequestFactory;
