import Joi from "joi";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";

const validateEmail = (email: string) => {
  const validationSchema = Joi.string()
    .email({ tlds: { allow: false } })
    .required();

  return formValidationService.validate(email, validationSchema);
};

const driverEditAccountFormValidationService = {
  validateEmail,
};

export default driverEditAccountFormValidationService;
