import { FormValidationDefinition } from "../../../../../common/components/form/use-form";
import cargoTaxiContractAddFormValidationService from "../cargo-taxi-contract-add-form-validation.service";
import CargoTaxiContractAddFormData from "./types/cargo-taxi-contract-add-form-data";

const getEmptyFormData = (): CargoTaxiContractAddFormData => {
  return {
    billingModel: null,
    discountPercentLimit: null,
    distanceRate: null,
    freeStoppingPeriodMinutes: null,
    isOrderPublishingAllowed: null,
    isTollRoadsDuringOrderAllowed: null,
    isTollRoadsWhileArrivalAllowed: null,
    isTollRoadsWhileReturningAllowed: null,
    periodOfValidity: null,
    stoppingRate: null,
    taxiCorporationUuid: "",
    isActive: null,
    cancelOrderByTaxiAllowed: null,
    editOrderByTaxiAllowed: null,
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<CargoTaxiContractAddFormData> => {
    return {
      billingModel: (values) =>
        cargoTaxiContractAddFormValidationService.validateBillingModel(
          values.billingModel
        ),
      discountPercentLimit: (values) =>
        cargoTaxiContractAddFormValidationService.validateDiscountPercentLimit(
          values.discountPercentLimit
        ),
      distanceRate: (values) =>
        cargoTaxiContractAddFormValidationService.validateDistanceRate(
          values.distanceRate
        ),
      freeStoppingPeriodMinutes: (values) =>
        cargoTaxiContractAddFormValidationService.validateFreeStoppingPeriodMinutes(
          values.freeStoppingPeriodMinutes
        ),
      isOrderPublishingAllowed: (values) =>
        cargoTaxiContractAddFormValidationService.validateIsOrderPublishingAllowed(
          values.isOrderPublishingAllowed
        ),
      isTollRoadsDuringOrderAllowed: (values) =>
        cargoTaxiContractAddFormValidationService.validateIsTollRoadsDuringOrderAllowed(
          values.isTollRoadsDuringOrderAllowed
        ),
      isTollRoadsWhileArrivalAllowed: (values) =>
        cargoTaxiContractAddFormValidationService.validateIsTollRoadsWhileArrivalAllowed(
          values.isTollRoadsWhileArrivalAllowed
        ),
      isTollRoadsWhileReturningAllowed: (values) =>
        cargoTaxiContractAddFormValidationService.validateIsTollRoadsWhileReturningAllowed(
          values.isTollRoadsWhileReturningAllowed
        ),
      periodOfValidity: (values) =>
        cargoTaxiContractAddFormValidationService.validatePeriodOfValidity(
          values.periodOfValidity
        ),
      stoppingRate: (values) =>
        cargoTaxiContractAddFormValidationService.validateStoppingRate(
          values.stoppingRate
        ),
      taxiCorporationUuid: (values) =>
        cargoTaxiContractAddFormValidationService.validateTaxiCorporation(
          values.taxiCorporationUuid
        ),
      isActive: (values) =>
        cargoTaxiContractAddFormValidationService.validateActivity(
          values.isActive
        ),
      cancelOrderByTaxiAllowed: (values) =>
        cargoTaxiContractAddFormValidationService.validateCancelOrderByTaxiAllowed(
          values.cancelOrderByTaxiAllowed
        ),
      editOrderByTaxiAllowed: (values) =>
        cargoTaxiContractAddFormValidationService.validateEditOrderByTaxiAllowed(
          values.editOrderByTaxiAllowed
        ),
    };
  };

const cargoTaxiContractAddFormHelper = {
  getEmptyFormData,
  getValidationDefinition,
};

export default cargoTaxiContractAddFormHelper;
