import React from "react";

type HorizontalSliderProps = {
  value?: number;
  onChange?: (value: number) => void;
  maxValue?: number;
};

const HorizontalSlider: React.FC<HorizontalSliderProps> = ({
  value = 0,
  onChange,
  maxValue,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className="horizontal_slider">
      <input
        type="range"
        min="0"
        max={maxValue?.toFixed()}
        value={value}
        onChange={handleChange}
        className="horizontal_slider__input"
      />
    </div>
  );
};

export default HorizontalSlider;
