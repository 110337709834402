import { FC } from "react";
import OrderDetailsToolsProps from "./types/order-details-tools";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import OrderDetailsEditCargoExternalComponent from "./elements/order-details-edit-cargo-external.component";
import OrderDetialsEditTaxiComponent from "./elements/order-details-edit-taxi.component";
import OrderDetailsManageDriverAssignmentComponent from "./elements/order-details-manage-driver-assigment.component";
import OrderDetialsCompletedRouteComponent from "./elements/order-details-completed-route.component";
import OrderDetialsCopyComponent from "./elements/order-details-copy.component";
import OrderDetialsOrderRouteComponent from "./elements/order-details-order-route.component";
import OrderDetialsHistoryComponent from "./elements/order-details-history.component";
import OrderDetialsCancelComponent from "./elements/order-details-cancel.component";
import OrderDetialsCancelRequestComponent from "./elements/order-details-cancel-request.component";
import OrderDetialsForwardingAddComponent from "./elements/order-details-forwarding-add.component";
import OrderDetialsForwardingCancelComponent from "./elements/order-details-forwarding-cancel.component";
import OrderDetialsForwardingEditComponent from "./elements/order-details-forwarding-edit.component";
import useOrderDetialsForwarding from "../modals/use-order-details-forwarding";
import OrderDetailsGoogleMapsRouteComponent from "./elements/order-details-google-maps-route.component";
import OrderDetailsFilteredOrderComponent from "./elements/order-details-filtered-order.component";

const OrderDetailsToolsComponent: FC<OrderDetailsToolsProps> = ({
  drivers,
  userPermissions,
  cargoOrderDetails,
  isRouteSequenceSolved,
  cargoCompanyUuid,
  isRouteStarted,
  isCancelOrderFetching,
  isCompletedRoutesFetching,
  isCompletedRouteButtonActive,
  isCancelOrderRequestFetching,
  shouldShowOrderHistory,
  cancelOrder,
  onEditTaxiCorporationSuccess,
  onAddCandidatureSuccess,
  onCopyDetailsButtonClick,
  onEditOrderButtonClick,
  onOrderHistoryButtonClick,
  toggleCompletedMapRoutesActive,
  toggleGoogleMapsRoute,
  onCargoExternalIdChangeSuccess,
  onFilteredOrderButtonClick,
  sendOrderCancellationRequest,
  sendRevertOrderCancellationRequest,
}) => {
  const translations = orderTranslationsHelper.getDetailsTranslations();
  const { tools } = translations;
  const {
    hasAccessToEditCargoExternalId,
    hasAccessToEditTaxi,
    hasAccessToManageDriverAssignment,
    hasAccessToEditOrderRoute,
    hasAccessToEditOrderRouteAsTaxi,
    hasAccessToCancelOrder,
    hasAccessToCancelOrderImmediately,
    hasAccessToCancelOrderAsTaxi,
    hasAccessToSendOrderCancelRequest,
    hasAccessToAddOrderForwarding,
    hasAccessToEditOrderForwarding,
    hasAccessToCancelOrderForwarding,
  } = userPermissions;

  const { transportingOrderUuid: transportingOrderId } = cargoOrderDetails!;
  const { forwardingId } = useOrderDetialsForwarding({
    transportingOrderId,
  });
  const hasAccessToEditOrder =
    hasAccessToEditOrderRoute ||
    (hasAccessToEditOrderRouteAsTaxi &&
      (cargoOrderDetails?.editByTaxiAllowed ?? false));

  return (
    <div className="order_details_tools">
      <OrderDetailsEditCargoExternalComponent
        details={cargoOrderDetails}
        tools={tools}
        hasAccess={hasAccessToEditCargoExternalId}
        onSuccess={onCargoExternalIdChangeSuccess}
      />
      <OrderDetialsEditTaxiComponent
        cargoCompanyUuid={cargoCompanyUuid}
        details={cargoOrderDetails}
        drivers={drivers}
        tools={tools}
        hasAccess={hasAccessToEditTaxi}
        onSuccess={onEditTaxiCorporationSuccess}
      />
      <OrderDetialsForwardingAddComponent
        tools={tools}
        details={cargoOrderDetails}
        hasAccess={hasAccessToAddOrderForwarding}
      />
      <OrderDetialsForwardingEditComponent
        tools={tools}
        details={cargoOrderDetails}
        hasAccess={hasAccessToEditOrderForwarding}
      />
      <OrderDetailsManageDriverAssignmentComponent
        details={cargoOrderDetails}
        drivers={drivers}
        tools={tools}
        hasAccess={hasAccessToManageDriverAssignment}
        hasAccessToEdit={hasAccessToEditOrder}
        onSuccess={onAddCandidatureSuccess}
      />
      <OrderDetailsGoogleMapsRouteComponent
        isRouteSequenceSolved={isRouteSequenceSolved}
        tools={tools}
        onSuccess={toggleGoogleMapsRoute}
      />
      <OrderDetailsFilteredOrderComponent
        tools={tools}
        onSuccess={onFilteredOrderButtonClick}
      />
      <OrderDetialsCompletedRouteComponent
        details={cargoOrderDetails}
        tools={tools}
        isRouteStarted={isRouteStarted}
        isLoading={isCompletedRoutesFetching}
        isActive={isCompletedRouteButtonActive}
        onSuccess={toggleCompletedMapRoutesActive}
      />
      <OrderDetialsCopyComponent
        tools={tools}
        details={cargoOrderDetails}
        onSuccess={onCopyDetailsButtonClick}
      />
      <OrderDetialsOrderRouteComponent
        tools={tools}
        details={cargoOrderDetails}
        onSuccess={onEditOrderButtonClick}
        hasAccess={hasAccessToEditOrder}
      />
      <OrderDetialsHistoryComponent
        tools={tools}
        isActive={shouldShowOrderHistory}
        onSuccess={onOrderHistoryButtonClick}
      />
      <OrderDetialsForwardingCancelComponent
        tools={tools}
        details={cargoOrderDetails}
        forwardingId={forwardingId}
        hasAccess={hasAccessToCancelOrderForwarding}
      />
      <OrderDetialsCancelComponent
        tools={tools}
        details={cargoOrderDetails}
        isLoading={isCancelOrderFetching}
        hasAccess={
          hasAccessToCancelOrder ||
          (hasAccessToCancelOrderAsTaxi &&
            (cargoOrderDetails?.cancelByTaxiAllowed ?? false))
        }
        canCancelImmediately={hasAccessToCancelOrderImmediately}
        onConfirm={cancelOrder}
      />
      <OrderDetialsCancelRequestComponent
        details={cargoOrderDetails}
        tools={tools}
        isLoading={isCancelOrderRequestFetching}
        onConfirm={sendOrderCancellationRequest}
        onConfirmRevert={sendRevertOrderCancellationRequest}
        hasAccess={hasAccessToSendOrderCancelRequest}
      />
    </div>
  );
};

export default OrderDetailsToolsComponent;
