import DriverEditParams, {
  DriverEditParamsAddress,
  DriverEditParamsAddressType,
  DriverEditParamsCarOwnership,
  DriverEditParamsCitizenship,
  DriverEditParamsDriver,
  DriverEditParamsFormOfEmployment,
  DriverEditParamsLanguage,
  DriverEditParamsTaxiDriverAssociation,
  DriverEditParamsTaxiDriverAssociationForB2B,
  DriverEditParamsTaxiDriverAssociationForEmployment,
  DriverEditParamsUser,
} from "../../../../../../common/services/driver/edit/driver-edit-params";
import dateService from "../../../../../../common/utils/date/date.service";
import DriverEditAccountFormData from "../form/types/driver-edit-account-form-data";
import DriverEditVehicleFormData from "../form/types/driver-edit-vehicle-form-data";
import RailyDriverEditUserFormData from "../form/types/raily-driver-edit-user-form-data";
import DriverEditAddress from "../types/driver-edit-address";
import DriverEditAddressType from "../types/driver-edit-address-type";
import DriverEditCarOwnershipType from "../types/driver-edit-car-ownership-type";
import DriverEditCitizenshipType from "../types/driver-edit-citizenship-type";
import DriverEditFormOfEmploymentType from "../types/driver-edit-form-of-employment-type";
import DriverEditLanguageType from "../types/driver-edit-language-type";
import DriverEditTaxiCorporationFormData from "./driver-edit-taxi-corporation-form-data";

const driverEditParamsAddressType = (
  type: DriverEditAddressType
): DriverEditParamsAddressType => {
  switch (type) {
    case DriverEditAddressType.MAILING:
      return DriverEditParamsAddressType.MAILING;
    case DriverEditAddressType.STARTING:
      return DriverEditParamsAddressType.STARTING;
  }
};

const driverEditParamsContractType = (
  type: DriverEditFormOfEmploymentType | null
): DriverEditParamsFormOfEmployment | undefined => {
  switch (type) {
    case DriverEditFormOfEmploymentType.B2B:
      return DriverEditParamsFormOfEmployment.B2B;
    case DriverEditFormOfEmploymentType.EMPLOYMENT:
      return DriverEditParamsFormOfEmployment.EMPLOYMENT;
    default:
      return undefined;
  }
};

const driverEditParamsCarOwnershipType = (
  type: DriverEditCarOwnershipType
): DriverEditParamsCarOwnership => {
  switch (type) {
    case DriverEditCarOwnershipType.BUSINESS:
      return DriverEditParamsCarOwnership.BUSINESS;
    case DriverEditCarOwnershipType.OWNER:
      return DriverEditParamsCarOwnership.OWNER;
  }
};

const driverEditParamsCitizenshipType = (
  type: DriverEditCitizenshipType
): DriverEditParamsCitizenship => {
  switch (type) {
    case DriverEditCitizenshipType.BY:
      return DriverEditParamsCitizenship.BY;
    case DriverEditCitizenshipType.CZ:
      return DriverEditParamsCitizenship.CZ;
    case DriverEditCitizenshipType.DE:
      return DriverEditParamsCitizenship.DE;
    case DriverEditCitizenshipType.LT:
      return DriverEditParamsCitizenship.LT;
    case DriverEditCitizenshipType.PL:
      return DriverEditParamsCitizenship.PL;
    case DriverEditCitizenshipType.RU:
      return DriverEditParamsCitizenship.RU;
    case DriverEditCitizenshipType.SK:
      return DriverEditParamsCitizenship.SK;
    case DriverEditCitizenshipType.UA:
      return DriverEditParamsCitizenship.UA;
  }
};

const driverEditParamsLanguageType = (
  type: DriverEditLanguageType
): DriverEditParamsLanguage => {
  switch (type) {
    case DriverEditLanguageType.BY:
      return DriverEditParamsLanguage.BY;
    case DriverEditLanguageType.CZ:
      return DriverEditParamsLanguage.CZ;
    case DriverEditLanguageType.DE:
      return DriverEditParamsLanguage.DE;
    case DriverEditLanguageType.LT:
      return DriverEditParamsLanguage.LT;
    case DriverEditLanguageType.PL:
      return DriverEditParamsLanguage.PL;
    case DriverEditLanguageType.RU:
      return DriverEditParamsLanguage.RU;
    case DriverEditLanguageType.SK:
      return DriverEditParamsLanguage.SK;
    case DriverEditLanguageType.UA:
      return DriverEditParamsLanguage.UA;
    case DriverEditLanguageType.EN:
      return DriverEditParamsLanguage.EN;
    case DriverEditLanguageType.ES:
      return DriverEditParamsLanguage.ES;
    case DriverEditLanguageType.FR:
      return DriverEditParamsLanguage.FR;
  }
};

const driverEditParamsLanguagesType = (
  types: DriverEditLanguageType[]
): DriverEditParamsLanguage[] => {
  return types.map(driverEditParamsLanguageType);
};

const createDriverEditParamsAddress = (
  userDataAddress: DriverEditAddress
): DriverEditParamsAddress => {
  const paramsAddress: DriverEditParamsAddress = {
    apartment: userDataAddress.apartmentNumber.trim(),
    country: userDataAddress.country.trim(),
    description: (userDataAddress.description ?? "").trim(),
    houseNo: userDataAddress.houseNumber.trim(),
    lat: userDataAddress.latitude,
    lon: userDataAddress.longitude,
    street: userDataAddress.street.trim(),
    town: userDataAddress.town.trim(),
    type: driverEditParamsAddressType(userDataAddress.type),
    zipCode: userDataAddress.zipCode.trim(),
  };

  return paramsAddress;
};

const createDriverEditParamsAddresses = (
  userDataAddresses: DriverEditAddress[]
): DriverEditParamsAddress[] => {
  return userDataAddresses.map(createDriverEditParamsAddress);
};

const createDriverEditTaxiDriverAssociationForEmploymentRequest = (
  taxiCorporationData: DriverEditTaxiCorporationFormData
): DriverEditParamsTaxiDriverAssociationForEmployment => {
  return {
    contractType: driverEditParamsContractType(
      taxiCorporationData.formOfEmployment
    )!,
    fleetPartnerId: taxiCorporationData.fleetPartner?.uuid!,
    id: taxiCorporationData.id ? taxiCorporationData.id : "",
  };
};

const createDriverEditTaxiDriverAssociationForB2BRequest = (
  taxiCorporationData: DriverEditTaxiCorporationFormData
): DriverEditParamsTaxiDriverAssociationForB2B => {
  return {
    contractType: driverEditParamsContractType(
      taxiCorporationData.formOfEmployment
    )!,
    taxId: taxiCorporationData.taxNumber,
    regon: taxiCorporationData.companyId || null,
    krsNo: taxiCorporationData.nationalCourtRegisterNumber || null,
    fleetPartnerId: taxiCorporationData.fleetPartner?.uuid ?? null,
    companyName: taxiCorporationData.name,
    country: taxiCorporationData.country,
    town: taxiCorporationData.town,
    zipCode: taxiCorporationData.zipCode,
    street: taxiCorporationData.street,
    houseNo: taxiCorporationData.houseNumber,
    apartment: taxiCorporationData.apartmentNumber,
    description: taxiCorporationData.description,
    id: taxiCorporationData.id ? taxiCorporationData.id : "",
  };
};

const createDriverEditTaxiDriverAssociationRequest = (
  taxiCorporationData: DriverEditTaxiCorporationFormData
): DriverEditParamsTaxiDriverAssociation => {
  if (
    taxiCorporationData.formOfEmployment === DriverEditFormOfEmploymentType.B2B
  ) {
    return createDriverEditTaxiDriverAssociationForB2BRequest(
      taxiCorporationData
    );
  }

  return createDriverEditTaxiDriverAssociationForEmploymentRequest(
    taxiCorporationData
  );
};

const createDriver = (
  userFormData: RailyDriverEditUserFormData,
  vehicleFormData: DriverEditVehicleFormData
): DriverEditParamsDriver => {
  return {
    birthDate: dateService.format(userFormData.birthDate!, "yyyy-mm-dd"),
    birthPlace: userFormData.birthPlace,
    idNumber: userFormData.personalIdNumber,
    citizenship: driverEditParamsCitizenshipType(userFormData.citizenship!),
    languages: driverEditParamsLanguagesType(userFormData.languages),
    experience: userFormData.yearsOfExperience!,
    carOwnership: driverEditParamsCarOwnershipType(vehicleFormData.ownership!),
    carMake: vehicleFormData.make,
    carModel: vehicleFormData.model,
    carRegNo: vehicleFormData.registrationNumber,
    carProdYear: vehicleFormData.productionYear!,
    carSeatsNo: vehicleFormData.numberOfSeats!,
  };
};

const createUser = (
  accountFormData: DriverEditAccountFormData,
  userFormData: RailyDriverEditUserFormData
): DriverEditParamsUser => {
  return {
    firstName: userFormData.firstName,
    lastName: userFormData.lastName,
    mobilePrimaryPrefix: userFormData.mobile.dialingCode ?? "",
    mobilePrimary: userFormData.mobile.number ?? "",
    mobileSecondaryPrefix: userFormData.alternativeMobile.dialingCode,
    mobileSecondary: userFormData.alternativeMobile.number,
    email: accountFormData.email,
  };
};

const createSubmitFormParams = (
  accountFormData: DriverEditAccountFormData,
  userFormData: RailyDriverEditUserFormData,
  vehicleFormData: DriverEditVehicleFormData,
  taxiCorporationData: DriverEditTaxiCorporationFormData[]
): DriverEditParams => {
  return {
    user: createUser(accountFormData, userFormData),
    driver: createDriver(userFormData, vehicleFormData),
    associations: taxiCorporationData.map((entry) =>
      createDriverEditTaxiDriverAssociationRequest(entry)
    ),
    addresses: createDriverEditParamsAddresses(userFormData.addresses),
  };
};

const railyDriverEditParamsFactory = {
  createSubmitFormParams,
};

export default railyDriverEditParamsFactory;
