import { useEffect, useMemo, useState } from "react";
import OrderOptimizerDetailsBreadcrumbsParams from "../common/breadcrumbs/types/order-optimizer-details-breadcrumbs-params";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../../context/app.context";
import orderOptimizerBreadcrumbsHelper from "../common/breadcrumbs/order-optimizer-breadcrumbs.helper";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import useDocumentTitle from "../../../common/hooks/use-document-title";
import MapRoute from "../../../common/components/map/types/map-route";
import useOrderOptimizerRoadRoute from "../../../common/services/order-optimizer/road-route/use-mileage-road-route";
import MapMarker from "../../../common/components/map/types/map-marker";
import orderOptimizerDetailsMapMarkersFactory from "./common/order-optimizer-details-map-markers.factory";
import orderOptimizerDetailsWaypointFactory from "./common/order-optimizer-details-waypoint.factory";
import HeadingComponent from "../../../common/components/heading/heading.component";
import CardComponent from "../../../common/components/card/card.component";
import MapComponent from "../../../common/components/map/map.component";
import ButtonComponent from "../../../common/components/button/button.component";
import orderOptimizerDetailsHelper from "./order-optimizer-details.helper";
import OrderOptimizerRoadRouteLoadParams from "../../../common/services/order-optimizer/road-route/order-optimizer-road-route-load-params";
import orderOptimizerDetailsRouteFactory from "./common/order-optimizer-details-route.factory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import orderOptimizerTranslationsHelper from "../../../languages/order-optimizer-translations.helper";
import OrderOptimizerDetailsOrdersTableComponent from "./orders-table/order-optimizer-details-orders-table.component";
import OrderOptimizerDetailsWithoutDriverTableComponent from "./without-driver-table/order-optimizer-details-without-driver-table.component";
import OrderOptimizerDetailsHiddenDetailsComponent from "./hidden-details/order-optimizer-details-hidden-details.component";
import OrderOptimizerDetailsDriverTableComponent from "./driver-table/order-optimizer-details-driver-table.component";
import HorizontalSlider from "../../../common/components/horizontal-slider/horizontal-slider.component";
import NumericInputComponent from "../../../common/components/form/input/numeric-input/numeric-input.component";
import FormErrorComponent from "../../../common/components/form/error/form-error.component";
import FormValidationResult from "../../../common/utils/validation/types/form-validation-result";
import orderOptimizerDetailsValidationService from "./common/order-optimizer-details-validation.service";
import orderOptimizerHelper from "../common/order-optimizer.helper";
import OrderOptimizerDetailsDriverTableRates from "./driver-table/types/order-optimizer-details-driver-table-rates";
import useOrderOptimizerDetails from "../../../common/services/order-optimizer/details/use-order-optimizer-details";
import useAbort from "../../../common/hooks/use-abort";
import OrderOptimizerDetailsLoadParams from "../../../common/services/order-optimizer/details/order-optimizer-details-load-params";
import orderOptimizerDetailsItemFactory from "./common/order-optimizer-details-item.factory";
import { round } from "lodash";
import OrderOptimizerDetailsHiddenDetailsDriver from "./hidden-details/driver/types/order-optimizer-details-hidden-details-driver";
import notificationService from "../../../common/utils/notification/notification.service";
import orderOptimizerRoutesHelper from "../common/routes/order-optimizer-routes.helper";
import { OrderOptimizerDetailsItemOrders } from "./common/types/order-optimizer-details-item";
import OrderOptimizerDetailsToolsComponent from "./tools/order-optimizer-details-tools.component";
import OrderOptimizerDetailsWaypoint from "./common/types/order-optimizer-details-waypoint";

const OrderOptimizerDetailsComponent = () => {
  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();
  const { id, excludedOrders } =
    useParams<OrderOptimizerDetailsBreadcrumbsParams>();
  const roadRoute = useOrderOptimizerRoadRoute();
  const translation = orderOptimizerTranslationsHelper.getDetailsTranslations();

  const orderOptimizerDetails = useOrderOptimizerDetails();
  const orderOptimizerDetailsAbort = useAbort();

  const orderOptimizerLocalDetails = useOrderOptimizerDetails();
  const orderOptimizerLocalDetailsAbort = useAbort();

  const [waypoints, setWaypoints] = useState<OrderOptimizerDetailsWaypoint[]>(
    []
  );

  const navigate = useNavigate();
  const [homeMarker, setHomeMarker] = useState<MapMarker | null>(null);
  const [homeRoutes, setHomeRoutes] = useState<MapRoute[]>([]);

  const [isDriverSelected, setIsDriverSelected] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const [mapRoutes, setMapRoutes] = useState<MapRoute[]>([]);

  const [detailsExpanded, setDetailsExpanded] = useState(false);

  const [validationResult, setValidationResult] =
    useState<FormValidationResult | null>(null);

  const [hiddenOrders, setHiddenOrders] = useState<number[]>([]);

  const [localDetailsItem, setLocalDetailsItem] = useState<
    OrderOptimizerDetailsItemOrders[]
  >([]);
  const [taxiDriverAssociationUuid, setTaxiDriverAssociationUuid] =
    useState<string>();

  useEffect(() => {
    if (!excludedOrders || excludedOrders === "all-orders") {
      setHiddenOrders([]);
    } else {
      const updatedOrders = excludedOrders.split(",").map((hiddenOrderId) => {
        return Number(hiddenOrderId);
      });

      setHiddenOrders(updatedOrders);
    }
  }, [excludedOrders]);

  useEffect(() => {
    if (orderOptimizerDetails.data?.orders) {
      setLocalDetailsItem(
        orderOptimizerDetailsItemFactory.createDetailsItemOrders(
          orderOptimizerDetails.data.orders
        )
      );
    }
  }, [orderOptimizerDetails.data?.orders]);

  useEffect(() => {
    if (orderOptimizerDetails.data?.driver) {
      setIsDriverSelected(true);
    } else {
      setIsDriverSelected(false);
    }
  }, [orderOptimizerDetails.data?.driver]);

  const onOrderVisibilityClick = (orderId: number) => {
    const updatedOrders = hiddenOrders.includes(orderId)
      ? hiddenOrders.filter((id) => id !== orderId)
      : [...hiddenOrders, orderId];

    navigate(
      orderOptimizerRoutesHelper.getDetailsRoute({
        id: id!,
        excludedOrders:
          updatedOrders.length > 0 ? updatedOrders.join(",") : "all-orders",
      }),
      { replace: true }
    );

    loadOrderOptimizerLocalDetails(
      id!,
      updatedOrders,
      taxiDriverAssociationUuid
    );
  };

  const loadOrderOptimizerDetails = (
    optimizationId: string,
    excludedOrders?: number[],
    taxiDriverAssociationId?: string
  ) => {
    const params: OrderOptimizerDetailsLoadParams = {
      optimizationId: optimizationId,
      taxiDriverAssociationId: taxiDriverAssociationId,
      excludedOrders: excludedOrders,
    };

    orderOptimizerDetails.load(params, orderOptimizerLocalDetailsAbort.signal);
  };

  const loadOrderOptimizerLocalDetails = (
    optimizationId: string,
    excludedOrders?: number[],
    taxiDriverAssociationId?: string
  ) => {
    const params: OrderOptimizerDetailsLoadParams = {
      optimizationId: optimizationId,
      taxiDriverAssociationId: taxiDriverAssociationId,
      excludedOrders: excludedOrders,
    };

    orderOptimizerLocalDetails.load(params, orderOptimizerDetailsAbort.signal);
  };

  useEffect(() => {
    if (!id) return;

    loadOrderOptimizerDetails(id, hiddenOrders);

    return orderOptimizerDetailsAbort.revoke;
  }, []);

  const detailsItem = useMemo(() => {
    if (orderOptimizerLocalDetails.data) {
      return orderOptimizerDetailsItemFactory.createDetailsItem(
        orderOptimizerLocalDetails.data
      );
    }

    if (orderOptimizerDetails.data) {
      return orderOptimizerDetailsItemFactory.createDetailsItem(
        orderOptimizerDetails.data
      );
    }

    return undefined;
  }, [orderOptimizerDetails.data, orderOptimizerLocalDetails.data]);

  useEffect(() => {
    if (!detailsItem || roadRoute.data.length > 0) return;

    const preparedWaypoints =
      orderOptimizerDetailsWaypointFactory.createWaypoints(
        detailsItem,
        hiddenOrders
      );

    setWaypoints(preparedWaypoints.flat());

    const loadParams: OrderOptimizerRoadRouteLoadParams[] = [];
    preparedWaypoints.forEach((route) => {
      if (route.length > 1) {
        const request =
          orderOptimizerDetailsWaypointFactory.createSearchRoutingRequest(
            route
          );
        loadParams.push(request);
      }
    });

    roadRoute.load(
      loadParams,
      preparedWaypoints.map((waypoints) => waypoints[0].humanId)
    );
  }, [detailsItem, hiddenOrders]);

  const [rates, setRates] = useState<OrderOptimizerDetailsDriverTableRates>({
    aba: 0,
    saAndBs: 0,
    sabs: 0,
  });

  const [validationRateResults, setValidationRateResults] = useState<
    Record<
      keyof OrderOptimizerDetailsDriverTableRates,
      FormValidationResult | null
    >
  >({
    aba: null,
    sabs: null,
    saAndBs: null,
  });

  useEffect(() => {
    const breadcrumbs = orderOptimizerBreadcrumbsHelper.getDetailsBreadcrumbs({
      id: id!,
      excludedOrders: "",
    });
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const translations =
    orderOptimizerTranslationsHelper.getDetailsTranslations();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(
    documentTitleTranslations.orderOptimizerDetails.replace("#{id}", id!)
  );

  const mapMarkers: MapMarker[] = useMemo(() => {
    const mapMarkers = orderOptimizerDetailsMapMarkersFactory.createMapMarkers(
      waypoints,
      hiddenOrders
    );
    return mapMarkers;
  }, [waypoints, hiddenOrders]);

  useEffect(() => {
    if (!roadRoute.data) return;

    const routes = roadRoute.data
      .filter((routeItem) => !hiddenOrders.includes(routeItem.orderHumanId))
      .map((route) =>
        orderOptimizerDetailsRouteFactory.createMapRoute(
          route.coordinates,
          orderOptimizerHelper.getOrderColor(
            localDetailsItem.findIndex(
              (details) => details.humanId === route.orderHumanId
            )
          )
        )
      );
    setMapRoutes(routes);
  }, [roadRoute.data, hiddenOrders]);

  const sabsAbaDifferenceDistance = round(
    (detailsItem?.ordersByDriverSabs?.distance ?? 0) -
      (detailsItem?.ordersByDriverAba?.distance ?? 0)
  );

  const onDistanceChanged = (saAndBsDistance: number) => {
    if (!detailsItem) return;

    setSliderValue(saAndBsDistance);

    const result = orderOptimizerDetailsValidationService.validateDistance(
      saAndBsDistance,
      sabsAbaDifferenceDistance
    );
    setValidationResult(result);
  };

  const [sliderValue, setSliderValue] = useState(0);

  const handleHideDetails = () => {
    setDetailsExpanded(false);
  };

  const handleShowDetails = () => {
    setDetailsExpanded(true);
  };

  useEffect(() => {
    if (!detailsItem?.driver) return;

    const initialRates: OrderOptimizerDetailsDriverTableRates = {
      aba: detailsItem?.driver.contractRate,
      saAndBs: detailsItem?.driver.contractRate,
      sabs: detailsItem?.driver.contractRate,
    };

    setRates(initialRates);
  }, [detailsItem?.driver?.contractRate]);

  const onRateAbaChanged = (key: string, rateAba: number) => {
    setRates((prevRates) => ({
      ...prevRates,
      [key]: rateAba,
    }));

    const result = orderOptimizerDetailsValidationService.validateRate(rateAba);

    setValidationRateResults((prevResults) => ({
      ...prevResults,
      [key]: result,
    }));
  };

  const onRateSabsChanged = (key: string, rateSabs: number) => {
    setRates((prevRates) => ({
      ...prevRates,
      [key]: rateSabs,
    }));

    const result =
      orderOptimizerDetailsValidationService.validateRate(rateSabs);

    setValidationRateResults((prevResults) => ({
      ...prevResults,
      [key]: result,
    }));
  };

  const onRateSaAndBsChanged = (key: string, rateSaAndBs: number) => {
    setRates((prevRates) => ({
      ...prevRates,
      [key]: rateSaAndBs,
    }));

    const result =
      orderOptimizerDetailsValidationService.validateRate(rateSaAndBs);

    setValidationRateResults((prevResults) => ({
      ...prevResults,
      [key]: result,
    }));
  };

  const onPotentialDriverChange = (
    driver: OrderOptimizerDetailsHiddenDetailsDriver | null
  ) => {
    setTaxiDriverAssociationUuid(driver?.taxiDriverAssociationUuid);
    if (!id) {
      return;
    }

    if (!driver) {
      setHomeMarker(null);
      setHomeRoutes([]);
    } else {
      loadOrderOptimizerDetails(id, [], driver.taxiDriverAssociationUuid);
      setHomeMarker(null);
      setHomeRoutes([]);
      setIsButtonClicked(false);
    }
  };

  useEffect(() => {
    if (!id) return;
    loadOrderOptimizerDetails(id, [], taxiDriverAssociationUuid);
    orderOptimizerLocalDetails.reset();
    setHiddenOrders([]);
  }, [taxiDriverAssociationUuid]);

  useEffect(() => {
    if (orderOptimizerDetails.isError) {
      notificationService.error(translation.noDriverContractErrorNotification);
    }
  }, [orderOptimizerDetails.isError]);

  const marginAba = orderOptimizerDetailsHelper.calculateMargin(
    detailsItem?.totalCargoIncome ?? 0,
    detailsItem?.totalDriverHaltingCost ?? 0,
    detailsItem?.ordersByDriverAba?.distance ?? 0,
    rates.aba
  );

  const marginSabs = orderOptimizerDetailsHelper.calculateMargin(
    detailsItem?.totalCargoIncome ?? 0,
    detailsItem?.totalDriverHaltingCost ?? 0,
    detailsItem?.ordersByDriverSabs?.distance ?? 0,
    rates.sabs
  );

  const saAndBsDistanceMargin = orderOptimizerDetailsHelper.calculateMargin(
    detailsItem?.totalCargoIncome ?? 0,
    detailsItem?.totalDriverHaltingCost ?? 0,
    (detailsItem?.ordersByDriverAba?.distance ?? 0) + sliderValue,
    rates.saAndBs
  );

  const saAndBsMarginLabel = orderOptimizerDetailsHelper.getMarginLabel(
    saAndBsDistanceMargin
  );

  const saAndBsMarginTitle = orderOptimizerDetailsHelper.getMarginLabel(
    saAndBsDistanceMargin
  );

  const scoreLabel = orderOptimizerDetailsHelper.getScoreLabel(
    detailsItem?.score
  );

  const idLabel = orderOptimizerDetailsHelper.getIdLabel(Number(id));

  const handleAddHomeMapMarker = async () => {
    if (homeMarker) {
      setHomeMarker(null);
      setHomeRoutes([]);
      setIsButtonClicked(false);
    } else {
      if (!detailsItem?.driver || waypoints.length < 2) {
        return;
      }

      const filteredWaypoints = waypoints.filter(
        (waypoint) => !hiddenOrders.includes(waypoint.humanId)
      );

      const newHomeMarker =
        orderOptimizerDetailsRouteFactory.createHomeMapMarker(
          detailsItem.driver
        );

      const firstPoint = filteredWaypoints[0];
      const lastPoint = filteredWaypoints[filteredWaypoints.length - 1];

      const newHomeRoutes =
        await orderOptimizerDetailsRouteFactory.createRoutesFromHome(
          detailsItem.driver,
          firstPoint,
          lastPoint,
          "red"
        );

      setIsButtonClicked(true);
      setHomeMarker(newHomeMarker);
      setHomeRoutes(newHomeRoutes);
    }
  };

  useEffect(() => {
    const reloadHomeMapRoute = async () => {
      if (homeMarker) {
        if (!detailsItem?.driver || waypoints.length < 2) {
          return;
        }

        const filteredWaypoints = waypoints.filter(
          (waypoint) => !hiddenOrders.includes(waypoint.humanId)
        );

        const newHomeMarker =
          orderOptimizerDetailsRouteFactory.createHomeMapMarker(
            detailsItem.driver
          );

        const firstPoint = filteredWaypoints[0];
        const lastPoint = filteredWaypoints[filteredWaypoints.length - 1];

        const newHomeRoutes =
          await orderOptimizerDetailsRouteFactory.createRoutesFromHome(
            detailsItem.driver,
            firstPoint,
            lastPoint,
            "red"
          );

        setHomeMarker(newHomeMarker);
        setHomeRoutes(newHomeRoutes);
      }
    };

    reloadHomeMapRoute();
  }, [hiddenOrders]);

  const orderOptimizerDetailsToolsProps = {
    detailsItem,
    isDriverSelected,
    isButtonClicked,
    handleAddHomeMapMarker,
  };

  return (
    <>
      <HeadingComponent
        title={orderOptimizerDetailsHelper.getHeadingText(id!)}
      />
      <CardComponent>
        <div className="order_optimizer_details">
          <div className="order_optimizer_details__left">
            <div className="order_optimizer_details_map_wrapper">
              <MapComponent
                markers={homeMarker ? [...mapMarkers, homeMarker] : mapMarkers}
                routes={[...mapRoutes, ...homeRoutes]}
                autoFitOnUpdate
              />
              <OrderOptimizerDetailsToolsComponent
                {...orderOptimizerDetailsToolsProps}
              />
            </div>
            {detailsExpanded ? (
              <ButtonComponent
                onClick={handleHideDetails}
                classNames={{ root: "order_optimizer_details__left__button" }}
                title={translations.hideDetailsButtonTitle}
                type="primary"
              >
                {translations.hideDetailsButtonLabel}
                <FontAwesomeIcon
                  icon={faAngleUp}
                  className="order_optimizer_details__left__button__icon"
                />
              </ButtonComponent>
            ) : (
              <ButtonComponent
                onClick={handleShowDetails}
                classNames={{ root: "order_optimizer_details__left__button" }}
                title={translations.showDetailsButtonTitle}
                type="primary"
                isDisabled={!detailsItem}
              >
                {translations.showDetailsButtonLabel}
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className="order_optimizer_details__left__button__icon"
                />
              </ButtonComponent>
            )}
          </div>
          <div className="order_optimizer_details__right">
            <div className="order_optimizer_details__right__info">
              <div className="order_optimizer_details__right__info__left">
                <h1 className="order_optimizer_details__right__info__left__text">
                  {scoreLabel}
                </h1>
                <h1 className="order_optimizer_details__right__info__left__text">
                  {idLabel}
                </h1>
              </div>
              <div className="order_optimizer_details__right__info__right">
                {!!detailsItem?.reviewedAt && !!detailsItem.reviewedBy && (
                  <div
                    className="order_optimizer_details__right__info__right__badge"
                    title={`${
                      detailsItem?.reviewedBy
                    }, ${orderOptimizerDetailsHelper.getDateLabel(
                      detailsItem?.reviewedAt ?? null
                    )}`}
                  >
                    {detailsItem?.reviewedBy},{" "}
                    {orderOptimizerDetailsHelper.getDateLabel(
                      detailsItem?.reviewedAt ?? null
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="order_optimizer_details_orders_table">
              <OrderOptimizerDetailsOrdersTableComponent
                detailsOrderItems={localDetailsItem}
                hiddenOrders={hiddenOrders}
                onOrderVisibilityClick={onOrderVisibilityClick}
              />
            </div>
            <div className="order_optimizer_details_without_driver_table">
              <OrderOptimizerDetailsWithoutDriverTableComponent
                detailsOptimizedStatsItem={detailsItem?.optimizedStats}
                detailsUnoptimizedStatsItem={detailsItem?.unoptimizedStats}
              />
            </div>
            <div className="order_optimizer_details_driver_table">
              <OrderOptimizerDetailsDriverTableComponent
                detailsItem={detailsItem}
                marginAba={marginAba}
                marginSabs={marginSabs}
                validationResult={validationRateResults}
                onRateAbaChanged={onRateAbaChanged}
                onRateSaAndBsChanged={onRateSaAndBsChanged}
                onRateSabsChanged={onRateSabsChanged}
                rates={rates}
              />
            </div>
            <div className="order_optimizer_details_driver_horizontal_slider_table">
              <div
                title={translations.table.driver.grantedSaAndBsDistanceTitle}
                className="order_optimizer_details_driver_horizontal_slider_table__cell"
              >
                <div className="order_optimizer_details_driver_horizontal_slider_table__cell__text">
                  {translations.table.driver.grantedSaAndBsDistanceLabel}
                </div>
              </div>
              <div className="order_optimizer_details_driver_horizontal_slider_table__slider">
                <HorizontalSlider
                  value={sliderValue}
                  onChange={onDistanceChanged}
                  maxValue={sabsAbaDifferenceDistance}
                />
              </div>
              <div className="order_optimizer_details_driver_horizontal_slider_table__cell_input">
                <div
                  title={`${sliderValue} KM`}
                  className="order_optimizer_details_driver_horizontal_slider_table__cell_input__label"
                >
                  <NumericInputComponent
                    value={sliderValue ?? 0}
                    onChange={(value) => onDistanceChanged(value ?? 0)}
                    hasError={!!validationResult?.errorMessage}
                    placeholder={
                      translations.table.driver.distancePlaceholderLabel
                    }
                    isIntegerOnly
                  />
                  <FormErrorComponent
                    message={validationResult?.errorMessage}
                  />
                </div>
              </div>
              <div className="order_optimizer_details_driver_horizontal_slider_table__cell_margin">
                <div
                  title={saAndBsMarginTitle}
                  className="order_optimizer_details_driver_horizontal_slider_table__cell__text"
                >
                  {saAndBsMarginLabel}
                </div>
              </div>
            </div>
          </div>
        </div>
        {detailsExpanded && !!detailsItem && (
          <OrderOptimizerDetailsHiddenDetailsComponent
            optimizationId={id}
            detailsItem={detailsItem}
            onPotentialDriverChange={onPotentialDriverChange}
          />
        )}
      </CardComponent>
    </>
  );
};

export default OrderOptimizerDetailsComponent;
