import { FC, useState } from "react";
import ButtonComponent from "../../../../../common/components/button/button.component";
import CardComponent from "../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../common/components/form/input/input.component";
import PhoneNumberInputComponent from "../../../../../common/components/form/input/phone-number/phone-number-input.component";
import Column from "../../../../../common/components/grid/column";
import Row from "../../../../../common/components/grid/row";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import { useAppContext } from "../../../../../context/app.context";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import CargoOfficerAddBasicProps from "../common/cargo-officer-add-basic-props";
import cargoOfficerAddByCargoFormHelper from "./common/cargo-officer-add-by-cargo-form.helper";
import CargoOfficerAddByCargoFormData from "./common/types/cargo-officer-add-by-cargo-form-data";
import { useNavigate } from "react-router-dom";
import userRoutesHelper from "../../../common/routes/user-routes.helper";
import useAbort from "../../../../../common/hooks/use-abort";
import cargoOfficerAddByCargoParamsFactory from "./common/cargo-officer-add-by-cargo-params.factory";
import notificationService from "../../../../../common/utils/notification/notification.service";
import useForm from "../../../../../common/components/form/use-form";
import CargoOfficerAddParams from "../../../../../common/services/cargo-officer/add/cargo-officer-add-params";
import cargoOfficerService from "../../../../../common/services/cargo-officer/cargo-officer.service";

type CargoOfficerAddByCargoProps = CargoOfficerAddBasicProps;

const CargoOfficerAddByCargoComponent: FC<CargoOfficerAddByCargoProps> = (
  props
) => {
  const translations = userTranslationsHelper.getCargoOfficerAddTranslations();

  const cargoOfficerAddAbort = useAbort();
  const [isCargoOfficerAddPending, setIsCargoOfficerAddPending] =
    useState(false);

  const navigate = useNavigate();

  const { user } = useAppContext();

  const form = useForm<CargoOfficerAddByCargoFormData>({
    emptyValues: cargoOfficerAddByCargoFormHelper.getDefaultFormData(),
    validationDefinition:
      cargoOfficerAddByCargoFormHelper.getValidationDefinition(),
  });

  const navigateToListing = () => {
    const cargoOfficerListingRoute =
      userRoutesHelper.getCargoOfficerListingRoute();
    navigate(cargoOfficerListingRoute);
  };

  const onCargoOfficerAddSuccess = () => {
    notificationService.success(translations.successAddNotificationLabel);
    navigateToListing();
  };

  const onCargoOfficerAddFailure = () => {
    notificationService.error(translations.failureAddNotificationLabel);
  };

  const addCargoOfficer = async () => {
    setIsCargoOfficerAddPending(true);

    const params: CargoOfficerAddParams =
      cargoOfficerAddByCargoParamsFactory.create(form.values, user!);

    try {
      await cargoOfficerService.add(params, cargoOfficerAddAbort.signal);

      onCargoOfficerAddSuccess();
    } catch {
      onCargoOfficerAddFailure();
    } finally {
      setIsCargoOfficerAddPending(false);
    }
  };

  const submitForm = async () => {
    const isFormValid: boolean = await form.validateAll();

    if (!isFormValid) return;

    addCargoOfficer();
  };

  return (
    <>
      <HeadingComponent
        title={translations.header.headingLabel}
        actions={props.changeViewButtons}
      />
      <Row>
        <Column xl={8}>
          <CardComponent>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.emailLabel}
                  isRequired
                  errorMessage={form.validationResults.email.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.emailPlaceholder}
                    value={form.values.email}
                    onChange={(value) => form.setValue("email", value)}
                    onBlur={() => form.validate("email")}
                    hasError={!!form.validationResults.email.errorMessage}
                    idForTesting="cargo-officer-add-by-cargo-email"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.usernameLabel}
                  isRequired
                  errorMessage={form.validationResults.username.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.usernamePlaceholder}
                    value={form.values.username}
                    onChange={(value) =>
                      form.setValue("username", value.toLowerCase())
                    }
                    onBlur={() => form.validate("username")}
                    hasError={!!form.validationResults.username.errorMessage}
                    idForTesting="cargo-officer-add-by-cargo-username"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.firstNameLabel}
                  isRequired
                  errorMessage={form.validationResults.firstName.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.firstNamePlaceholder}
                    value={form.values.firstName}
                    onChange={(value) => form.setValue("firstName", value)}
                    onBlur={() => form.validate("firstName")}
                    hasError={!!form.validationResults.firstName.errorMessage}
                    idForTesting="cargo-officer-add-by-cargo-first-name"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.lastNameLabel}
                  isRequired
                  errorMessage={form.validationResults.lastName.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.lastNamePlaceholder}
                    value={form.values.lastName}
                    onChange={(value) => form.setValue("lastName", value)}
                    onBlur={() => form.validate("lastName")}
                    hasError={!!form.validationResults.lastName.errorMessage}
                    idForTesting="cargo-officer-add-by-cargo-last-name"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.phoneNumberLabel}
                  isRequired
                  errorMessage={form.validationResults.phoneNumber.errorMessage}
                >
                  <PhoneNumberInputComponent
                    placeholder={translations.form.phoneNumberPlaceholder}
                    phoneNumber={form.values.phoneNumber}
                    onChange={(value) => {
                      form.setValue("phoneNumber", value);
                    }}
                    onBlur={() => form.validate("phoneNumber")}
                    hasError={!!form.validationResults.phoneNumber.errorMessage}
                    idForTesting="cargo-officer-add-by-cargo-phone-number"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.alternativePhoneNumberLabel}
                  errorMessage={
                    form.validationResults.alternativePhoneNumber.errorMessage
                  }
                >
                  <PhoneNumberInputComponent
                    placeholder={
                      translations.form.alternativePhoneNumberPlaceholder
                    }
                    phoneNumber={form.values.alternativePhoneNumber}
                    onChange={(value) => {
                      form.setValue("alternativePhoneNumber", value);
                    }}
                    onBlur={() => form.validate("alternativePhoneNumber")}
                    hasError={
                      !!form.validationResults.alternativePhoneNumber
                        .errorMessage
                    }
                    idForTesting="cargo-officer-add-by-cargo-alternative-phone-number"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.passwordLabel}
                  isRequired
                  errorMessage={form.validationResults.password.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.passwordPlaceholder}
                    value={form.values.password}
                    type="password"
                    onChange={(value) => form.setValue("password", value)}
                    onBlur={() => form.validate("password")}
                    hasError={!!form.validationResults.password.errorMessage}
                    idForTesting="cargo-officer-add-by-cargo-password"
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.passwordRepeatLabel}
                  isRequired
                  errorMessage={
                    form.validationResults.passwordRepeat.errorMessage
                  }
                >
                  <InputComponent
                    placeholder={translations.form.passwordRepeatPlaceholder}
                    value={form.values.passwordRepeat}
                    type="password"
                    onChange={(value) => form.setValue("passwordRepeat", value)}
                    onBlur={() => form.validate("passwordRepeat")}
                    hasError={
                      !!form.validationResults.passwordRepeat.errorMessage
                    }
                    idForTesting="cargo-officer-add-by-cargo-password-repeat"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
          </CardComponent>
        </Column>
      </Row>
      <ButtonComponent
        onClick={submitForm}
        type="primary"
        isDisabled={isCargoOfficerAddPending}
        classNames={{ root: "mt-4" }}
        idForTesting="cargo-officer-add-by-cargo-submit-button"
        title={translations.form.submitButtonTitle}
      >
        {translations.form.submitButtonLabel}
      </ButtonComponent>
    </>
  );
};

export default CargoOfficerAddByCargoComponent;
