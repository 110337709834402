import railyApiService from "../raily-api.service";
import dealerAddRequestFactory from "./add/dealer-add-request.factory";
import DealerAddRequest, {
  DealerAddRequestBody,
} from "./add/dealer-add.request";
import DealerAddResponse from "./add/dealer-add.response";
import dealerListRequestFactory from "./list/dealer-list-request.factory";
import DealerListRequest, {
  DealerListRequestQueryParams,
} from "./list/dealer-list.request";
import DealerListResponse from "./list/dealer-list.response";

const dealerApiService = () => {
  const getList = async (
    request: DealerListRequest,
    abortSignal: AbortSignal
  ): Promise<DealerListResponse> => {
    const url = `/users/all/dealers`;

    const queryParams: DealerListRequestQueryParams =
      dealerListRequestFactory.createQueryParams(request);

    return railyApiService().get<DealerListResponse>({
      url,
      abortSignal,
      queryParams,
    });
  };

  const add = (
    request: DealerAddRequest,
    abortSignal: AbortSignal
  ): Promise<DealerAddResponse> => {
    const url = `/dealers`;

    const body: DealerAddRequestBody =
      dealerAddRequestFactory.createBody(request);

    return railyApiService().post({
      url,
      abortSignal,
      body,
    });
  };

  return { getList, add };
};

export default dealerApiService;
