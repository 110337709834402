import {
  MileageListAddressSequence,
  MileageListAddressSequenceNode,
  MileageListItem,
  MileageListStatus as MileageListStatus,
  MileageListVehicleType,
} from "../../../../common/services/mileage/list/mileage-list";
import MileageListingAddressSequence, {
  MileageListingAddressSequenceNode,
} from "./types/mileage-listing-address-sequence";
import MileageListingStatus from "./types/mileage-listing-status";
import MileageListingVehicleType from "./types/mileage-listing-vehicle-type";
import MileageListingItem from "./types/mileage-listing-item";

const createAddressSequenceNode = (
  node: MileageListAddressSequenceNode
): MileageListingAddressSequenceNode => {
  return {
    displayName: node.displayName,
    lat: node.lat,
    lon: node.lon,
    apartmentNumber: node.apartmentNumber,
    houseNumber: node.houseNumber,
    street: node.street,
    time: node.time,
    town: node.town,
    zipCode: node.zipCode,
  };
};

const createAddressSequence = (
  listAddressSequence: MileageListAddressSequence
): MileageListingAddressSequence => {
  const sequence: MileageListingAddressSequenceNode[] =
    listAddressSequence.seq.map(createAddressSequenceNode);

  return {
    seq: sequence,
  };
};

const createStatus = (state: MileageListStatus): MileageListingStatus => {
  switch (state) {
    case MileageListStatus.ACCEPTED:
      return MileageListingStatus.ACCEPTED;
    case MileageListStatus.DELEGATION_CREATED:
      return MileageListingStatus.DELEGATION_CREATED;
    case MileageListStatus.PENDING:
      return MileageListingStatus.PENDING;
    case MileageListStatus.REJECTED:
      return MileageListingStatus.REJECTED;
  }
};

const createVehicleType = (
  type: MileageListVehicleType
): MileageListingVehicleType => {
  switch (type) {
    case MileageListVehicleType.CAR_GT_900CM3:
      return MileageListingVehicleType.CAR_GT_900CM3;
    case MileageListVehicleType.CAR_LE_900CM3:
      return MileageListingVehicleType.CAR_LE_900CM3;
    case MileageListVehicleType.COMPANY_OWNED:
      return MileageListingVehicleType.COMPANY_OWNED;
    case MileageListVehicleType.MOPED:
      return MileageListingVehicleType.MOPED;
    case MileageListVehicleType.MOTORCYCLE:
      return MileageListingVehicleType.MOTORCYCLE;
  }
};

const createItem = (item: MileageListItem): MileageListingItem => {
  return {
    addressSeq: createAddressSequence(item.addressSeq),
    createdAt: item.createdAt,
    createdBy: item.createdBy,
    distance: item.distance,
    endAddressLabel: item.endAddressLabel,
    hasDelegation: item.hasDelegation,
    humanId: item.humanId,
    uuid: item.uuid,
    midAddressesLabels: item.midAddressesLabels,
    mileageCost: item.mileageCost,
    mileageDate: item.mileageDate,
    startAddressLabel: item.startAddressLabel,
    status: createStatus(item.status),
    vehicleType: createVehicleType(item.vehicleType),
    workerCompanyUuid: item.workerCompanyUuid,
    workerUuid: item.workerUuid,
    workerName: item.workerName,
    workerPhone: item.workerPhone,
    cardNumber: item.cardNumber,
    cargoCompanyName: item.cargoCompanyName,
    commissionNumber: item.commissionNumber,
    delegationUuid: item.delegationUuid,
    mileageNumber: item.mileageNumber,
    modificationDate: item.modificationDate,
    modifiedBy: item.modifiedBy,
    notes: item.notes,
    osrmDistance: item.osrmDistance,
    acceptedDistance: item.acceptedDistance,
  };
};

const create = (listItems: MileageListItem[]): MileageListingItem[] => {
  const listingItems: MileageListingItem[] = listItems.map(createItem);

  return listingItems;
};

const mileageListingItemFactory = {
  create,
};

export default mileageListingItemFactory;
