import appConfig from "../../../../app.config";
import authService from "../../../../modules/auth/common/auth.service";
import HttpResponse from "../../../utils/http/http.response";
import urlService from "../../../utils/url/url.service";
import railyApiService from "../raily-api.service";
import driverAddRequestFactory from "./add/driver-add-request.factory";
import DriverAddRequest, {
  DriverAddRequestBody,
} from "./add/driver-add.request";
import DriverAddResponse from "./add/driver-add.response";
import DriverDetailsRequest from "./details/driver-details.request";
import DriverDetailsResponse from "./details/driver-details.response";
import driverDownloadRequestFactory from "./download/driver-download-request.factory";
import DriverDownloadRequest, {
  DriverDownloadRequestQueryParams,
} from "./download/driver-download.request";
import DriverDownloadResponse from "./download/driver-download.response";
import driverListRequestFactory from "./list/driver-list-request.factory";
import DriverListRequest, {
  DriverListRequestQueryParams,
} from "./list/driver-list.request";
import DriverListResponse from "./list/driver-list.response";
import driverEditRequestFactory from "./edit/driver-edit-request.factory";
import DriverEditRequest from "./edit/driver-edit.request";
import DriverEditFormRequest from "./edit-form/driver-edit-form.request";
import DriverEditFormResponse from "./edit-form/driver-edit-form.response";

const driverApiService = () => {
  const getList = async (
    request: DriverListRequest,
    abortSignal: AbortSignal
  ): Promise<DriverListResponse> => {
    const url = `/users/all/drivers`;

    const queryParams: DriverListRequestQueryParams =
      driverListRequestFactory.createQueryParams(request);

    return railyApiService().get<DriverListResponse>({
      url,
      abortSignal,
      queryParams,
    });
  };

  const getDetails = async (
    request: DriverDetailsRequest,
    abortSignal: AbortSignal
  ): Promise<DriverDetailsResponse> => {
    const url = `drivers/${request.driverId}`;

    return railyApiService().get<DriverDetailsResponse>({
      url,
      abortSignal,
    });
  };

  const download = async (
    request: DriverDownloadRequest
  ): Promise<DriverDownloadResponse> => {
    const path = `${appConfig.baseApiUrl}/users/all/drivers/csv-dump`;

    const queryParams: DriverDownloadRequestQueryParams =
      driverDownloadRequestFactory.createQueryParams(request);

    const url = urlService.buildWithoutEmptyParams(path, queryParams);

    const authToken = authService.getAccessToken();

    const init: RequestInit = {
      method: "GET",
      headers: {
        "Content-Type": "attachment",
        Authorization: `Bearer ${authToken}`,
      },
      credentials: "include",
      mode: "cors",
      keepalive: true,
    };

    const response = await fetch(url, init);

    const filename = response.headers
      ?.get("content-disposition")
      ?.split(";")
      .find((n) => n.includes("filename="))
      ?.replace("filename=", "")
      .replace(/"/g, "")
      .trim()!;

    const responseData = await response.blob();

    return {
      status: response.status,
      data: {
        data: responseData,
        filename,
      },
    };
  };

  const addDriver = async (
    request: DriverAddRequest,
    abortSignal: AbortSignal
  ): Promise<DriverAddResponse> => {
    const url = `/drivers`;

    const body: DriverAddRequestBody =
      driverAddRequestFactory.createBody(request);

    return railyApiService().post({
      url,
      abortSignal,
      body,
    });
  };

  const editDriver = async (
    request: DriverEditRequest,
    abortSignal: AbortSignal,
    driverUuid: string
  ): Promise<HttpResponse<string>> => {
    const url = `/users/${driverUuid}/driver`;
    const body = driverEditRequestFactory.createBody(request);

    return railyApiService().put({
      url,
      abortSignal,
      body,
    });
  };

  const fetchFormData = async (
    request: DriverEditFormRequest,
    abortSignal: AbortSignal
  ): Promise<DriverEditFormResponse> => {
    const url = `/users/${request.id}/driver/edit-form`;

    return railyApiService().get<DriverEditFormResponse>({
      url,
      abortSignal,
    });
  };

  return {
    getList,
    getDetails,
    download,
    addDriver,
    editDriver,
    fetchFormData,
  };
};

export default driverApiService;
