import { FC, useMemo } from "react";
import TableComponent, {
  TableProps,
} from "../../../../../../../../../common/components/table/table.component";
import { useAppContext } from "../../../../../../../../../context/app.context";
import DriverEditAddress from "../../../../types/driver-edit-address";
import driverEditUserDataAddressTableHelper from "./driver-edit-user-data-address-table.helper";
import DriverEditUserDataAddressTableColumn from "../../../../types/driver-edit-user-data-address-table-column";
import DriverEditAddressType from "../../../../types/driver-edit-address-type";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faHouse } from "@fortawesome/free-solid-svg-icons";
import DriverEditUserDataAddressTableRow from "../../../../types/driver-edit-user-data-address-table-row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import driverEditUserDataAddressHelper from "../driver-edit-user-data-address.helper";
import TableDeleteButtonComponent from "../../../../../../../../../common/components/table/button/delete/table-delete-button.component";

type DriverEditUserDataAddressTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  addresses: DriverEditAddress[];
  onRemoveAddressButtonClick: (index: number) => void;
};

const DriverEditUserDataAddressTableComponent: FC<
  DriverEditUserDataAddressTableProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const columns = useMemo(
    (): DriverEditUserDataAddressTableColumn[] =>
      driverEditUserDataAddressTableHelper.getColumns(),
    [selectedAppLanguage]
  );

  const addressTypeIconDefinitions: {
    type: DriverEditAddressType;
    icon: IconProp;
  }[] = [
    {
      icon: faEnvelope,
      type: DriverEditAddressType.MAILING,
    },
    {
      icon: faHouse,
      type: DriverEditAddressType.STARTING,
    },
  ];

  const createTableRow = (
    address: DriverEditAddress,
    index: number
  ): DriverEditUserDataAddressTableRow => {
    const icon = addressTypeIconDefinitions.find(
      (definition) => definition.type === address.type
    )?.icon;

    return {
      id: String(index),
      value: {
        address: (
          <div className="driver_edit_user_add_address_table_row address">
            {icon && <FontAwesomeIcon icon={icon} className="icon" />}
            <div className="text_ellipsis">
              {driverEditUserDataAddressHelper.getAddressLabel(address)}
            </div>
          </div>
        ),
        actions: (
          <TableDeleteButtonComponent
            onClick={() => props.onRemoveAddressButtonClick(index)}
          />
        ),
      },
    };
  };

  const rows: DriverEditUserDataAddressTableRow[] = useMemo(() => {
    return props.addresses.map(createTableRow);
  }, [props.addresses]);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isLoading={props.isLoading}
      isError={props.isError}
    />
  );
};

export default DriverEditUserDataAddressTableComponent;
