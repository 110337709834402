import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../../../context/app.context";
import UserDriverEditRouteParams from "../../common/routes/types/user-driver-edit-route-params";
import DriverEditViewMode from "./common/types/driver-edit-view-mode";
import driverEditUserPermissionsHelper from "./common/user-permissions/driver-edit-user-permission.helper";
import userTranslationsHelper from "../../../../languages/user-translations.helper";
import ButtonComponent from "../../../../common/components/button/button.component";
import RailyDriverEditComponent from "./raily/raily-driver-edit.component";
import TaxiDriverEditComponent from "./taxi/taxi-driver-edit.component";

type DriverEditProps = {};

const DriverEditComponent: FC<DriverEditProps> = () => {
  const { driverUuid } = useParams<UserDriverEditRouteParams>();
  const { user, selectedAppLanguage } = useAppContext();

  const userPermissions = useMemo(
    () => driverEditUserPermissionsHelper.getPermissions(user?.roles!),
    []
  );

  const translations = useMemo(
    () => userTranslationsHelper.getDriverEditTranslations(),
    [selectedAppLanguage]
  );

  const [selectedViewMode, setSelectedViewMode] =
    useState<DriverEditViewMode | null>(null);

  const ChangeViewToRailyButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() => setSelectedViewMode(DriverEditViewMode.RAILY)}
        title={translations.header.changeViewToRailyButtonTitle}
      >
        {translations.header.changeViewToRailyButtonText}
      </ButtonComponent>
    ),
    [translations]
  );

  const ChangeViewToTaxiButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() => setSelectedViewMode(DriverEditViewMode.TAXI)}
        title={translations.header.changeViewToTaxiButtonTitle}
      >
        {translations.header.changeViewToTaxiButtonText}
      </ButtonComponent>
    ),
    [translations]
  );

  const viewChangeButtonOptions = useMemo(
    () => [
      {
        viewMode: DriverEditViewMode.RAILY,
        buttonOptions: {
          button: ChangeViewToTaxiButton,
          hasPermission: userPermissions.hasAccessToTaxiView,
        },
      },
      {
        viewMode: DriverEditViewMode.TAXI,
        buttonOptions: {
          button: ChangeViewToRailyButton,
          hasPermission: userPermissions.hasAccessToRailyView,
        },
      },
    ],
    [userPermissions, ChangeViewToTaxiButton, ChangeViewToRailyButton]
  );

  const getViewChangeButtons = useCallback(
    (viewMode: DriverEditViewMode) => {
      const buttonOptionsForSelectedViewMode = viewChangeButtonOptions
        .filter((option) => option.viewMode === viewMode)
        .map((option) => option.buttonOptions);

      return buttonOptionsForSelectedViewMode
        .filter((option) => option.hasPermission)
        .map((option) => option.button);
    },
    [viewChangeButtonOptions]
  );

  const viewOptions = useMemo(
    () => [
      {
        mode: DriverEditViewMode.RAILY,
        component: (
          <RailyDriverEditComponent
            changeViewButtons={getViewChangeButtons(DriverEditViewMode.RAILY)}
            driverUuid={driverUuid}
          />
        ),
        hasPermission: userPermissions.hasAccessToRailyView,
      },
      {
        mode: DriverEditViewMode.TAXI,
        component: (
          <TaxiDriverEditComponent
            changeViewButtons={getViewChangeButtons(DriverEditViewMode.TAXI)}
            driverUuid={driverUuid}
          />
        ),
        hasPermission: userPermissions.hasAccessToTaxiView,
      },
    ],
    [getViewChangeButtons, driverUuid]
  );

  const getPossibleViewOptions = useCallback(() => {
    return viewOptions.filter((option) => option.hasPermission);
  }, []);

  const possibleViewOptions = useMemo(
    () => getPossibleViewOptions(),
    [getPossibleViewOptions]
  );

  useEffect(() => {
    if (possibleViewOptions.length === 0) {
      return;
    }
    setSelectedViewMode(possibleViewOptions[0].mode);
  }, [possibleViewOptions]);

  const SelectedViewComponent = useMemo(
    () =>
      viewOptions.find((option) => option.mode === selectedViewMode)?.component,
    [selectedViewMode, viewOptions]
  );

  if (!SelectedViewComponent) {
    return null;
  }

  return SelectedViewComponent;
};

export default DriverEditComponent;
