import userTranslationsHelper from "../../../../../../languages/user-translations.helper";
import DriverAddCitizenshipType from "../types/driver-add-citizenship-type";
import DriverAddCitizenshipTypeSelectOption from "../types/driver-add-citizenship-type-select-option";
import DriverAddFormOfEmploymentType from "../types/driver-add-form-of-employment-type";
import DriverAddFormOfEmploymentTypeSelectOption from "../types/driver-add-form-of-employment-type-select-option";
import DriverAddLanguageType from "../types/driver-add-language-type";
import DriverAddLanguageTypeSelectOption from "../types/driver-add-language-type-select-option";

const citizenshipLabel = (type: DriverAddCitizenshipType): string => {
  const translations =
    userTranslationsHelper.getDriverAddTranslations().form.userData.citizenship;
  switch (type) {
    case DriverAddCitizenshipType.BY:
      return translations.typeBY;
    case DriverAddCitizenshipType.CZ:
      return translations.typeCZ;
    case DriverAddCitizenshipType.DE:
      return translations.typeDE;
    case DriverAddCitizenshipType.LT:
      return translations.typeLT;
    case DriverAddCitizenshipType.PL:
      return translations.typePL;
    case DriverAddCitizenshipType.RU:
      return translations.typeRU;
    case DriverAddCitizenshipType.SK:
      return translations.typeSK;
    case DriverAddCitizenshipType.UA:
      return translations.typeUA;
  }
};

const languageLabel = (type: DriverAddLanguageType): string => {
  const translations =
    userTranslationsHelper.getDriverAddTranslations().form.userData.languages;
  switch (type) {
    case DriverAddLanguageType.BY:
      return translations.typeBY;
    case DriverAddLanguageType.CZ:
      return translations.typeCZ;
    case DriverAddLanguageType.DE:
      return translations.typeDE;
    case DriverAddLanguageType.LT:
      return translations.typeLT;
    case DriverAddLanguageType.PL:
      return translations.typePL;
    case DriverAddLanguageType.RU:
      return translations.typeRU;
    case DriverAddLanguageType.SK:
      return translations.typeSK;
    case DriverAddLanguageType.UA:
      return translations.typeUA;
    case DriverAddLanguageType.EN:
      return translations.typeEN;
    case DriverAddLanguageType.ES:
      return translations.typeES;
    case DriverAddLanguageType.FR:
      return translations.typeFR;
  }
};

const formOfEmploymentLabel = (type: DriverAddFormOfEmploymentType): string => {
  const translations =
    userTranslationsHelper.getDriverAddTranslations().form.userData
      .formOfEmployment;
  switch (type) {
    case DriverAddFormOfEmploymentType.B2B:
      return translations.typeB2B;
    case DriverAddFormOfEmploymentType.EMPLOYMENT:
      return translations.typeEmployment;
  }
};

const createCitizenshipSelectOption = (
  type: DriverAddCitizenshipType
): DriverAddCitizenshipTypeSelectOption => {
  return {
    label: citizenshipLabel(type),
    value: type,
    idForTesting: `driver-add-citizenship-${String(type)}-select-option`,
  };
};

const createLanguageSelectOption = (
  type: DriverAddLanguageType
): DriverAddLanguageTypeSelectOption => {
  return {
    label: languageLabel(type),
    value: type,
    idForTesting: `driver-add-language-${String(type)}-select-option`,
  };
};

const createFormOfEmploymentSelectOption = (
  type: DriverAddFormOfEmploymentType
): DriverAddFormOfEmploymentTypeSelectOption => {
  return {
    label: formOfEmploymentLabel(type),
    value: type,
    idForTesting: `driver-add-form-of-employment-${String(type)}-select-option`,
  };
};

const createCitizenshipSelectOptions =
  (): DriverAddCitizenshipTypeSelectOption[] => {
    return Object.keys(DriverAddCitizenshipType).map((value) =>
      createCitizenshipSelectOption(value as DriverAddCitizenshipType)
    );
  };

const createLanguageSelectOptions = (): DriverAddLanguageTypeSelectOption[] => {
  return Object.keys(DriverAddLanguageType).map((value) =>
    createLanguageSelectOption(value as DriverAddLanguageType)
  );
};

const createFormOfEmploymentSelectOptions =
  (): DriverAddFormOfEmploymentTypeSelectOption[] => {
    return Object.keys(DriverAddFormOfEmploymentType).map((value) =>
      createFormOfEmploymentSelectOption(value as DriverAddFormOfEmploymentType)
    );
  };

const driverAddCitizenshipTypeFactory = {
  createCitizenshipSelectOptions,
  createLanguageSelectOptions,
  createFormOfEmploymentSelectOptions,
  createCitizenshipSelectOption,
  createLanguageSelectOption,
  createFormOfEmploymentSelectOption,
};

export default driverAddCitizenshipTypeFactory;
