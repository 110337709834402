import { FC, useMemo } from "react";
import TableComponent, {
  TableProps,
} from "../../../../common/components/table/table.component";
import { useAppContext } from "../../../../context/app.context";
import {
  OrderOptimizerDetailsItemOptimizedStats,
  OrderOptimizerDetailsItemUnoptimizedStats,
} from "../common/types/order-optimizer-details-item";
import OrderOptimizerDetailsWithoutDriverTableRow from "../common/types/order-optimizer-details-without-driver-table-row";
import orderOptimizerDetailsWithoutDriverTableHelper from "./order-optimizer-details-without-driver-table.helper";
import uuidService from "../../../../common/utils/uuid/uuid.service";
import orderOptimizerTranslationsHelper from "../../../../languages/order-optimizer-translations.helper";
import orderOptimizerDetailsHelper from "../order-optimizer-details.helper";

type OrderOptimizerDetailsWithoutDriverTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  detailsOptimizedStatsItem?: OrderOptimizerDetailsItemOptimizedStats;
  detailsUnoptimizedStatsItem?: OrderOptimizerDetailsItemUnoptimizedStats;
};

const OrderOptimizerDetailsWithoutDriverTableComponent: FC<
  OrderOptimizerDetailsWithoutDriverTableProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const columns = useMemo(() => {
    return orderOptimizerDetailsWithoutDriverTableHelper.getColumns();
  }, [selectedAppLanguage]);

  const createOptimizedStatsTableRow = (
    detailsOptimizedStatsItem: OrderOptimizerDetailsItemOptimizedStats
  ): OrderOptimizerDetailsWithoutDriverTableRow => {
    const distanceLabel = orderOptimizerDetailsHelper.getDistanceLabel(
      detailsOptimizedStatsItem.distance
    );
    const distanceTitle = orderOptimizerDetailsHelper.getDistanceLabel(
      detailsOptimizedStatsItem.distance
    );

    const contractRateLabel = orderOptimizerDetailsHelper.getRateLabel(
      detailsOptimizedStatsItem.contractRate
    );
    const contractRateTitle = orderOptimizerDetailsHelper.getRateLabel(
      detailsOptimizedStatsItem.contractRate
    );

    const totalTimeLabel = orderOptimizerDetailsHelper.getTimeDeltaLabel(
      detailsOptimizedStatsItem.totalTime
    );
    const totalTimeTitle = orderOptimizerDetailsHelper.getTimeDeltaLabel(
      detailsOptimizedStatsItem.totalTime
    );

    const haltingTimeLabel = orderOptimizerDetailsHelper.getTimeDeltaLabel(
      detailsOptimizedStatsItem.haltingTime
    );
    const haltingTimeTitle = orderOptimizerDetailsHelper.getTimeDeltaLabel(
      detailsOptimizedStatsItem.haltingTime
    );

    const marginLabel = orderOptimizerDetailsHelper.getMarginLabel(
      detailsOptimizedStatsItem.margin
    );
    const marginTitle = orderOptimizerDetailsHelper.getMarginLabel(
      detailsOptimizedStatsItem.margin
    );

    const translations =
      orderOptimizerTranslationsHelper.getDetailsTranslations().table
        .withoutDriver;

    return {
      id: uuidService.generate(), //rozwiazanie tymczasowe
      value: {
        withoutDriver: (
          <div title={translations.routeTitle}>{translations.routeLabel}</div>
        ),
        billingModel: <div title="ABA">ABA</div>,
        contractRate: <div title={contractRateTitle}>{contractRateLabel}</div>,
        totalTime: <div title={totalTimeTitle}>{totalTimeLabel}</div>,
        haltingTime: <div title={haltingTimeTitle}>{haltingTimeLabel}</div>,
        distance: <div title={distanceTitle}>{distanceLabel}</div>,
        margin: <div title={marginTitle}>{marginLabel}</div>,
      },
    };
  };

  const createUnoptimizedStatsTableRow = (
    detailsUnoptimizedStatsItem: OrderOptimizerDetailsItemUnoptimizedStats
  ): OrderOptimizerDetailsWithoutDriverTableRow => {
    const distanceLabel = orderOptimizerDetailsHelper.getDistanceLabel(
      detailsUnoptimizedStatsItem.distance
    );
    const distanceTitle = orderOptimizerDetailsHelper.getDistanceLabel(
      detailsUnoptimizedStatsItem.distance
    );

    const totalTimeLabel = orderOptimizerDetailsHelper.getTimeDeltaLabel(
      detailsUnoptimizedStatsItem.totalTime
    );
    const totalTimeTitle = orderOptimizerDetailsHelper.getTimeDeltaLabel(
      detailsUnoptimizedStatsItem.totalTime
    );

    const haltingTimeLabel = orderOptimizerDetailsHelper.getTimeDeltaLabel(
      detailsUnoptimizedStatsItem.haltingTime
    );
    const haltingTimeTitle = orderOptimizerDetailsHelper.getTimeDeltaLabel(
      detailsUnoptimizedStatsItem.haltingTime
    );

    const contractRateLabel = orderOptimizerDetailsHelper.getRateLabel(
      detailsUnoptimizedStatsItem.contractRate
    );
    const contractRateTitle = orderOptimizerDetailsHelper.getRateLabel(
      detailsUnoptimizedStatsItem.contractRate
    );

    const marginLabel = orderOptimizerDetailsHelper.getMarginLabel(
      detailsUnoptimizedStatsItem.margin
    );
    const marginTitle = orderOptimizerDetailsHelper.getMarginLabel(
      detailsUnoptimizedStatsItem.margin
    );

    const translations =
      orderOptimizerTranslationsHelper.getDetailsTranslations().table
        .withoutDriver;

    return {
      id: uuidService.generate(), //rozwiazanie tymczasowe
      value: {
        withoutDriver: (
          <div title={translations.sumOfOrdersTitle}>
            {translations.sumOfOrdersLabel}
          </div>
        ),
        billingModel: <div title="ABA">ABA</div>,
        contractRate: <div title={contractRateTitle}>{contractRateLabel}</div>,
        totalTime: <div title={totalTimeTitle}>{totalTimeLabel}</div>,
        haltingTime: <div title={haltingTimeTitle}>{haltingTimeLabel}</div>,
        distance: <div title={distanceTitle}>{distanceLabel}</div>,
        margin: <div title={marginTitle}>{marginLabel}</div>,
      },
    };
  };

  const rows: OrderOptimizerDetailsWithoutDriverTableRow[] = useMemo(() => {
    if (!props.detailsOptimizedStatsItem || !props.detailsUnoptimizedStatsItem)
      return [];

    const optimizedRows = createOptimizedStatsTableRow(
      props.detailsOptimizedStatsItem
    );

    const unoptimizedRows = createUnoptimizedStatsTableRow(
      props.detailsUnoptimizedStatsItem
    );

    return [unoptimizedRows, optimizedRows];
  }, [
    props.detailsUnoptimizedStatsItem,
    props.detailsOptimizedStatsItem,
    selectedAppLanguage,
  ]);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isLoading={props.isLoading}
      isError={props.isError}
    />
  );
};

export default OrderOptimizerDetailsWithoutDriverTableComponent;
