import { FC } from "react";
import Row from "../../../../../../../../common/components/grid/row";
import FormFieldComponent from "../../../../../../../../common/components/form/field/form-field.component";
import Column from "../../../../../../../../common/components/grid/column";
import InputComponent from "../../../../../../../../common/components/form/input/input.component";
import userTranslationsHelper from "../../../../../../../../languages/user-translations.helper";
import DriverEditCompanyFormData from "../../../form/types/driver-edit-company-form-data";
import FormValidationResult from "../../../../../../../../common/utils/validation/types/form-validation-result";

type DriverEditCompanyDataProps = {
  validateCompanyData: (
    formKey: keyof DriverEditCompanyFormData,
    value?: unknown
  ) => Promise<boolean>;
  onCompanyDataChange: (
    formKey: keyof DriverEditCompanyFormData,
    value: any
  ) => void;
  companyValidationResults: Record<
    keyof DriverEditCompanyFormData,
    FormValidationResult
  >;
  companyFormValues: DriverEditCompanyFormData;
  isTaxi?: boolean;
};

const DriverEditCompanyAddressComponent: FC<DriverEditCompanyDataProps> = (
  props
) => {
  const translations =
    userTranslationsHelper.getDriverEditTranslations().form.companyData;

  return (
    <>
      <Row>
        <Column xl={6}>
          <FormFieldComponent
            label={translations.address.streetLabel}
            isRequired
            classNames={{ root: "mt-0" }}
            errorMessage={props.companyValidationResults.street.errorMessage}
          >
            <InputComponent
              value={props.companyFormValues.street}
              onChange={(value) => props.onCompanyDataChange("street", value)}
              placeholder={translations.address.streetPlaceholder}
              onBlur={() => {
                props.validateCompanyData("street");
              }}
              isDisabled={props.isTaxi}
              hasError={!!props.companyValidationResults.street.errorMessage}
              idForTesting="company-data-address-street-input"
            />
          </FormFieldComponent>
        </Column>
        <Column xl={6}>
          <FormFieldComponent
            label={translations.address.houseNumberLabel}
            isRequired
            classNames={{ root: "mt-0" }}
            errorMessage={
              props.companyValidationResults.houseNumber.errorMessage
            }
          >
            <InputComponent
              value={props.companyFormValues.houseNumber}
              onChange={(value) =>
                props.onCompanyDataChange("houseNumber", value)
              }
              placeholder={translations.address.houseNumberPlaceholder}
              onBlur={() => {
                props.validateCompanyData("houseNumber");
              }}
              isDisabled={props.isTaxi}
              hasError={
                !!props.companyValidationResults.houseNumber.errorMessage
              }
              idForTesting="company-data-address-house-number-input"
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column xl={6}>
          <FormFieldComponent
            label={translations.address.apartmentNumberLabel}
            errorMessage={
              props.companyValidationResults.apartmentNumber.errorMessage
            }
            classNames={{ root: "mt-0" }}
          >
            <InputComponent
              value={props.companyFormValues.apartmentNumber}
              onChange={(value) =>
                props.onCompanyDataChange("apartmentNumber", value)
              }
              placeholder={translations.address.apartmentNumberPlaceholder}
              onBlur={() => {
                props.validateCompanyData("apartmentNumber");
              }}
              isDisabled={props.isTaxi}
              hasError={
                !!props.companyValidationResults.apartmentNumber.errorMessage
              }
              idForTesting="company-data-address-apartment-number-input"
            />
          </FormFieldComponent>
        </Column>

        <Column xl={6}>
          <FormFieldComponent
            label={translations.address.townLabel}
            classNames={{ root: "mt-0" }}
            isRequired
            errorMessage={props.companyValidationResults.town.errorMessage}
          >
            <InputComponent
              value={props.companyFormValues.town}
              onChange={(value) => props.onCompanyDataChange("town", value)}
              placeholder={translations.address.townPlaceholder}
              onBlur={() => {
                props.validateCompanyData("town");
              }}
              isDisabled={props.isTaxi}
              hasError={!!props.companyValidationResults.town.errorMessage}
              idForTesting="company-data-address-town-input"
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column xl={6}>
          <FormFieldComponent
            label={translations.address.zipCodeLabel}
            classNames={{ root: "mt-0" }}
            isRequired
            errorMessage={props.companyValidationResults.zipCode.errorMessage}
          >
            <InputComponent
              value={props.companyFormValues.zipCode}
              onChange={(value) => props.onCompanyDataChange("zipCode", value)}
              placeholder={translations.address.zipCodePlaceholder}
              onBlur={() => {
                props.validateCompanyData("zipCode");
              }}
              isDisabled={props.isTaxi}
              hasError={!!props.companyValidationResults.zipCode.errorMessage}
              idForTesting="company-data-address-zip-code-input"
            />
          </FormFieldComponent>
        </Column>
        <Column xl={6}>
          <FormFieldComponent
            label={translations.address.countryLabel}
            classNames={{ root: "mt-0" }}
            isRequired
            errorMessage={props.companyValidationResults.country.errorMessage}
          >
            <InputComponent
              value={props.companyFormValues.country}
              onChange={(value) => props.onCompanyDataChange("country", value)}
              placeholder={translations.address.countryPlaceholder}
              onBlur={() => {
                props.validateCompanyData("country");
              }}
              isDisabled={props.isTaxi}
              hasError={!!props.companyValidationResults.country.errorMessage}
              idForTesting="company-data-address-country-input"
            />
          </FormFieldComponent>
        </Column>
      </Row>
      <Row>
        <Column>
          <FormFieldComponent
            label={translations.address.descriptionLabel}
            classNames={{ root: "mt-0" }}
            errorMessage={
              props.companyValidationResults.description.errorMessage
            }
          >
            <InputComponent
              value={props.companyFormValues.description}
              onChange={(value) =>
                props.onCompanyDataChange("description", value)
              }
              placeholder={translations.address.descriptionPlaceholder}
              onBlur={() => {
                props.validateCompanyData("description");
              }}
              isDisabled={props.isTaxi}
              hasError={
                !!props.companyValidationResults.description.errorMessage
              }
              idForTesting="company-data-address-description-input"
            />
          </FormFieldComponent>
        </Column>
      </Row>
    </>
  );
};

export default DriverEditCompanyAddressComponent;
