import DriverContractAddBillingModelSelectOption from "./types/driver-contract-add-billing-model-select-option";
import DriverContractAddFormData from "./types/driver-contract-add-form-data";
import DriverContractAddRequest from "./api/driver-contract-add.request";
import DriverContractAddBillingModel from "./types/driver-contract-add-billing-model";

const createContractAddRequest = (
  formData: DriverContractAddFormData,
  taxiAssociationUuid: string
): DriverContractAddRequest => {
  return {
    valid_since: formData.periodOfValidity?.from?.toJSON()!,
    valid_to: formData.periodOfValidity?.to?.toJSON()!,
    model: formData.billingModel?.value!,
    distance_rate: formData.distanceRate!,
    halting_time_rate: formData.haltingTimeRate!,
    halting_time_after_minutes: formData.haltingTimeGracePeriodMinutes!,
    taxi_driver_association_id: taxiAssociationUuid,
    disabled: !formData.isActive,
    description: formData.notes!,
  };
};

const createBillingModelSelectOptions =
  (): DriverContractAddBillingModelSelectOption[] => {
    const selectOptions: DriverContractAddBillingModelSelectOption[] = [
      {
        label: DriverContractAddBillingModel.ABA,
        value: DriverContractAddBillingModel.ABA,
      },
      {
        label: DriverContractAddBillingModel.SABS,
        value: DriverContractAddBillingModel.SABS,
      },
      {
        label: DriverContractAddBillingModel.AB,
        value: DriverContractAddBillingModel.AB,
      },
      {
        label: DriverContractAddBillingModel.OTHER,
        value: DriverContractAddBillingModel.OTHER,
      },
    ];

    return selectOptions;
  };

const driverContractAddFactory = {
  createContractAddRequest,
  createBillingModelSelectOptions,
};

export default driverContractAddFactory;
