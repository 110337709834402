enum DriverAddCitizenshipType {
  PL = "PL",
  DE = "DE",
  SK = "SK",
  CZ = "CZ",
  UA = "UA",
  RU = "RU",
  BY = "BY",
  LT = "LT",
}

export default DriverAddCitizenshipType;
