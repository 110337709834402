import billingsTranslationsHelper from "../../../../../../../languages/billings-translations.helper";
import BillingsTaxiDriverContributionsBonusTableColumn from "../types/billings-taxi-driver-contributions-bonus-table-column";

const getRequiredColumnHeading = (heading: string) => (
  <>
    {heading} <span className="header_asterisk">*</span>
  </>
);

const getColumns = (): BillingsTaxiDriverContributionsBonusTableColumn[] => {
  const translations =
    billingsTranslationsHelper.getTaxiDriverContributionsTranslations().bonus
      .table.headers;

  return [
    {
      header: translations.typeLabel,
      title: translations.typeTitle,
      accessor: "type",
      colSpan: 10,
    },
    {
      header: getRequiredColumnHeading(translations.distanceLabel),
      title: translations.distanceTitle,
      accessor: "distance",
      colSpan: 10,
    },
    {
      header: getRequiredColumnHeading(translations.rateLabel),
      title: translations.rateTitle,
      accessor: "rate",
      colSpan: 10,
    },
    {
      header: translations.amountLabel,
      title: translations.amountTitle,
      accessor: "amount",
      colSpan: 10,
    },
    {
      header: getRequiredColumnHeading(translations.commentLabel),
      title: translations.commentTitle,
      accessor: "comment",
      colSpan: 10,
    },
    {
      header: "",
      title: "",
      accessor: "actions",
      colSpan: 5,
    },
  ];
};

const BillingsTaxiDriverContributionsBonusTableHelper = {
  getColumns,
};

export default BillingsTaxiDriverContributionsBonusTableHelper;
