import DriverAddRequest from "../../api/raily/driver/add/driver-add.request";
import DriverDetailsRequest from "../../api/raily/driver/details/driver-details.request";
import DriverDownloadRequest from "../../api/raily/driver/download/driver-download.request";
import driverApiService from "../../api/raily/driver/driver-api.service";
import DriverListRequest from "../../api/raily/driver/list/driver-list.request";
import DriverEditRequest from "../../api/raily/driver/edit/driver-edit.request";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import fileDownloadService from "../../utils/file-download/file-download.service";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import DriverAddError from "./add/driver-add-error";
import DriverAddParams from "./add/driver-add-params";
import driverAddRequestFactory from "./add/driver-add-request.factory";
import DriverDetails from "./details/driver-details";
import DriverDetailsLoadParams from "./details/driver-details-load-params";
import driverDetailsRequestFactory from "./details/driver-details-request.factory";
import driverDetailsFactory from "./details/driver-details.factory";
import DriverDownloadError from "./download/driver-download-error";
import DriverDownloadParams from "./download/driver-download-params";
import driverDownloadRequestFactory from "./download/driver-download-request.factory";
import DriverList from "./list/driver-list";
import DriverListError from "./list/driver-list-error";
import DriverListLoadParams from "./list/driver-list-load-params";
import driverListRequestFactory from "./list/driver-list-request.factory";
import driverListFactory from "./list/driver-list.factory";
import DriverEditError from "./edit/driver-edit-error";
import DriverEditParams from "./edit/driver-edit-params";
import driverEditRequestFactory from "./edit/driver-edit-request.factory";
import driverEditFormFactory from "./edit-form/driver-edit-form.factory";
import driverEditFormRequestFactory from "./edit-form/driver-edit-form-request.factory";
import DriverEditFormRequest from "../../api/raily/driver/edit-form/driver-edit-form.request";
import DriverEditForm from "./edit-form/driver-edit-form";
import DriverEditFormLoadParams from "./edit-form/driver-edit-form-load-params";

const handleListError = (
  error: HttpError | DriverListError
): DriverListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getList = async (
  params: DriverListLoadParams,
  abortSignal: AbortSignal
): Promise<DriverList> => {
  const request: DriverListRequest = driverListRequestFactory.create(params);

  try {
    const response = await driverApiService().getList(request, abortSignal);

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const driverList: DriverList = driverListFactory.create(response);

    return driverList;
  } catch (_error) {
    const error = _error as HttpError | DriverListError;

    throw handleListError(error);
  }
};

const handleDownloadError = (
  error: HttpError | DriverListError
): DriverListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const download = async (params: DriverDownloadParams): Promise<void> => {
  const request: DriverDownloadRequest =
    driverDownloadRequestFactory.create(params);

  try {
    const response = await driverApiService().download(request);

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    fileDownloadService.downloadFromBlob(
      response.data.data,
      response.data.filename
    );
  } catch (_error) {
    const error = _error as HttpError | DriverDownloadError;

    throw handleDownloadError(error);
  }
};

const handleDetailsError = (
  error: HttpError | DriverListError
): DriverListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getDetails = async (
  params: DriverDetailsLoadParams,
  abortSignal: AbortSignal
): Promise<DriverDetails> => {
  const request: DriverDetailsRequest =
    driverDetailsRequestFactory.create(params);

  try {
    const response = await driverApiService().getDetails(request, abortSignal);

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const driverDetails = driverDetailsFactory.create(response.data);

    return driverDetails;
  } catch (_error) {
    const error = _error as HttpError | DriverListError;

    throw handleDetailsError(error);
  }
};

const handleAddError = (error: HttpError | DriverAddError): DriverAddError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const addDriver = async (
  params: DriverAddParams,
  abortSignal: AbortSignal
): Promise<void> => {
  const request: DriverAddRequest = driverAddRequestFactory.create(params);

  try {
    const response = await driverApiService().addDriver(request, abortSignal);

    if (response.status !== 201) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | DriverAddError;

    throw handleAddError(error);
  }
};

const handleEditError = (
  error: HttpError | DriverEditError
): DriverEditError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const editDriver = async (
  params: DriverEditParams,
  abortSignal: AbortSignal,
  driverUuid: string
): Promise<void> => {
  const request: DriverEditRequest = driverEditRequestFactory.create(params);

  try {
    const response = await driverApiService().editDriver(
      request,
      abortSignal,
      driverUuid
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }
  } catch (_error) {
    const error = _error as HttpError | DriverEditError;

    throw handleEditError(error);
  }
};

const handleEditFormError = (
  error: HttpError | DriverListError
): DriverListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const fetchFormData = async (
  params: DriverEditFormLoadParams,
  abortSignal: AbortSignal
): Promise<DriverEditForm> => {
  const request: DriverEditFormRequest =
    driverEditFormRequestFactory.create(params);

  try {
    const response = await driverApiService().fetchFormData(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const driverEditForm = driverEditFormFactory.create(response.data);

    return driverEditForm;
  } catch (_error) {
    const error = _error as HttpError | DriverListError;

    throw handleEditFormError(error);
  }
};

const driverService = {
  getList,
  getDetails,
  download,
  addDriver,
  editDriver,
  fetchFormData,
};

export default driverService;
