import MessengerChannel from "../../../../common/components/messenger/types/messenger-channel";
import MessengerChannelAvailability from "../../../../common/components/messenger/types/messenger-channel-availability";
import {
  RouteDetailsDriver,
  RouteDetailsPassenger,
} from "../../../../common/services/route/details/route-details";
import UserRole from "../../../../common/types/user-role";
import phoneNumberService from "../../../../common/utils/phone-number/phone-number.service";
import routeTranslationsHelper from "../../../../languages/route-translations.helper";
import RouteDetailsRouteWaypoint, {
  RouteDetailsRouteWaypointPlace,
} from "./types/route-details-route-waypoint";

const getMessengerChannelAvailability = (): MessengerChannelAvailability => {
  return {
    [MessengerChannel.DRIVER]: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.OPERATOR,
      UserRole.DEALER,
    ],
  };
};

const getDriverDisplayName = (driver: RouteDetailsDriver): string => {
  return `${driver.firstName} ${driver.lastName}`;
};

const getDriverButtonTitle = (driver: RouteDetailsDriver): string => {
  const translations = routeTranslationsHelper.getDetailsTranslations();

  return translations.drivers.driverButtonTemplateLabel.replace(
    "#{driverDisplayName}",
    getDriverDisplayName(driver)
  );
};

const getPassengerDisplayName = (passenger: RouteDetailsPassenger): string => {
  return `${passenger.firstName} ${passenger.lastName}`;
};

const getPassengerOnboardPlace = (
  passengerUuid: string,
  waypoints: RouteDetailsRouteWaypoint[]
): RouteDetailsRouteWaypointPlace | null => {
  return (
    waypoints.find((waypoint) =>
      waypoint.outboardingPassengersUuids.includes(passengerUuid)
    )?.place ?? null
  );
};

const getPassengerOutboardPlace = (
  passengerUuid: string,
  waypoints: RouteDetailsRouteWaypoint[]
): RouteDetailsRouteWaypointPlace | null => {
  return (
    waypoints.find((waypoint) =>
      waypoint.outboardingPassengersUuids.includes(passengerUuid)
    )?.place ?? null
  );
};

const getPassengerTitle = (
  passenger: RouteDetailsPassenger,
  waypoints: RouteDetailsRouteWaypoint[]
): string => {
  const translations = routeTranslationsHelper.getDetailsTranslations();

  const displayName = getPassengerDisplayName(passenger);
  const phoneNumber = phoneNumberService.getLabel(passenger.phoneNumber);

  const onboardPlace = getPassengerOnboardPlace(passenger.uuid, waypoints);
  const outboardPlace = getPassengerOutboardPlace(passenger.uuid, waypoints);

  return translations.passengers.passengerTemplateLabel
    .replace("#{passenger}", displayName)
    .replace("#{mobileNumber}", phoneNumber)
    .replace("#{onboardPlace}", onboardPlace?.displayName ?? "N/A")
    .replace("#{outboardPlace}", outboardPlace?.displayName ?? "N/A");
};

const routeDetailsHelper = {
  getDriverButtonTitle,
  getDriverDisplayName,
  getPassengerDisplayName,
  getPassengerTitle,
  getMessengerChannelAvailability,
};

export default routeDetailsHelper;
