import { FC, ReactNode } from "react";
import dateService from "../../../../../common/utils/date/date.service";
import OrderDetailsHistoryEntry from "../common/types/order-details-history-entry";
import {
  OrderDetailsHistoryEntryCancelOrderEvent,
  OrderDetailsHistoryEntryRequestCancelOrderEvent,
  OrderDetailsHistoryEntryChangeRideEvent,
  OrderDetailsHistoryEntryDealerApprovalEvent,
  OrderDetailsHistoryEntryDispatchApprovalEvent,
  OrderDetailsHistoryEntryInitializeEvent,
  OrderDetailsHistoryEntryMergeOrdersEvent,
  OrderDetailsHistoryEntryOperationalApprovalEvent,
  OrderDetailsHistoryEntryBindPassengerEvent,
  OrderDetailsHistoryEntryChangeTargetTaxiEvent,
  OrderDetailsHistoryEntryExternalIdUpdateEvent,
  OrderDetailsHistoryEntryShuffleMergeEvent,
  OrderDetailsHistoryEventRevertCancelOrderRequestEvent,
} from "../common/types/order-details-history-entry-event";
import OrderDetailsHistoryEventType from "../common/types/order-details-history-event-type";
import OrderDetailsHistoryEntryRequestCancelOrderContentComponent from "./content/request-cancel-order/order-details-history-entry-request-cancel-order-content.component";
import OrderDetailsHistoryEntryCancelOrderContentComponent from "./content/cancel-order/order-details-history-entry-cancel-order-content.component";
import OrderDetailsHistoryEntryChangeRideContentComponent from "./content/change-ride/order-details-history-entry-change-ride-content.component";
import OrderDetailsHistoryEntryDealerApprovalContentComponent from "./content/dealer-approval/order-details-history-entry-dealer-approval-content.component";
import OrderDetailsHistoryEntryDispatchApprovalContentComponent from "./content/dispatch-approval/order-details-history-entry-dispatch-approval-content.component";
import OrderDetailsHistoryEntryInitializeContentComponent from "./content/initialize/order-details-history-entry-initialize-content.component";
import OrderDetailsHistoryEntryMergeOrdersContentComponent from "./content/merge-orders/order-details-history-entry-merge-orders-content.component";
import OrderDetailsHistoryEntryOperationalApprovalContentComponent from "./content/operational-approval/order-details-history-entry-operational-approval-content.component";
import OrderDetailsHistoryEntryBindPassengerContentComponent from "./content/bind-passenger/order-details-history-entry-bind-passenger-content.component";
import OrderDetailsHistoryEntryChangeTargetTaxiContentComponent from "./content/change-target-taxi/order-details-history-entry-change-target-taxi-content.component";
import OrderDetailsHistoryEntryExternalIdUpdateContentComponent from "./content/external-id-update/order-details-history-entry-external-id-update-content.component";
import OrderDetailsHistoryEntryShuffleMergeContentComponent from "./content/shuffle-merge/order-details-history-entry-shuffle-merge-content.component";
import OrderDetailsHistoryEntryForwardContentComponent from "./content/forward/order-details-history-entry-forward-content.component";
import OrderDetailsHistoryEntryForwardCancelContentComponent from "./content/forward-cancel/order-details-history-entry-forward-cancel-content.component";
import {
  OrderDetailsTHandlingLogAssignmentOrderCancelEvent,
  OrderDetailsTHandlingLogAssignmentOrderExistingPlanEvent,
  OrderDetailsTHandlingLogAssignmentOrderNewPlanEvent,
  OrderDetailsTHandlingLogEntryForwardCancelEvent,
  OrderDetailsTHandlingLogEntryForwardEvent,
} from "../common/types/order-details-handling-log-entry-event";
import OrderDetailsHistoryEntryAssignmentOrderNewPlanContentComponent from "./content/assignment-order-new-plan/order-details-history-entry-assignment-order-new-plan-content.component";
import OrderDetailsHistoryEntryAssignmentOrderExistingPlanContentComponent from "./content/assignment-order-existing-plan/order-details-history-entry-assignment-order-existing-plan-content.component";
import OrderDetailsHistoryEntryAssignmentOrderCancelContentComponent from "./content/assignment-order-cancel/order-details-history-entry-assignment-order-cancel-content.component";
import OrderDetailsHistoryEntryRevertCancelOrderRequestContentComponent from "./content/revert-cancel-order-request/order-details-history-entry-revert-cancel-order-request-content.component";

type OrderDetailsHistoryEntryProps = {
  entry: OrderDetailsHistoryEntry;
};

const OrderDetailsHistoryEntryComponent: FC<OrderDetailsHistoryEntryProps> = (
  props
) => {
  const contentOptions: {
    eventType: OrderDetailsHistoryEventType;
    component: ReactNode;
  }[] = [
    {
      component: (
        <OrderDetailsHistoryEntryBindPassengerContentComponent
          event={
            props.entry.event as OrderDetailsHistoryEntryBindPassengerEvent
          }
        />
      ),
      eventType: OrderDetailsHistoryEventType.BIND_PASSENGER,
    },
    {
      component: (
        <OrderDetailsHistoryEntryCancelOrderContentComponent
          event={props.entry.event as OrderDetailsHistoryEntryCancelOrderEvent}
        />
      ),
      eventType: OrderDetailsHistoryEventType.CANCEL_ORDER,
    },
    {
      component: (
        <OrderDetailsHistoryEntryChangeRideContentComponent
          event={props.entry.event as OrderDetailsHistoryEntryChangeRideEvent}
        />
      ),
      eventType: OrderDetailsHistoryEventType.CHANGE_RIDE,
    },
    {
      component: (
        <OrderDetailsHistoryEntryChangeTargetTaxiContentComponent
          event={
            props.entry.event as OrderDetailsHistoryEntryChangeTargetTaxiEvent
          }
        />
      ),
      eventType: OrderDetailsHistoryEventType.CHANGE_TARGET_TAXI,
    },
    {
      component: (
        <OrderDetailsHistoryEntryDealerApprovalContentComponent
          event={
            props.entry.event as OrderDetailsHistoryEntryDealerApprovalEvent
          }
        />
      ),
      eventType: OrderDetailsHistoryEventType.DEALER_APPROVAL,
    },
    {
      component: (
        <OrderDetailsHistoryEntryDispatchApprovalContentComponent
          event={
            props.entry.event as OrderDetailsHistoryEntryDispatchApprovalEvent
          }
        />
      ),
      eventType: OrderDetailsHistoryEventType.DISPATCH_APPROVAL,
    },
    {
      component: (
        <OrderDetailsHistoryEntryExternalIdUpdateContentComponent
          event={
            props.entry.event as OrderDetailsHistoryEntryExternalIdUpdateEvent
          }
        />
      ),
      eventType: OrderDetailsHistoryEventType.EXTERNAL_ID_UPDATE,
    },
    {
      component: (
        <OrderDetailsHistoryEntryInitializeContentComponent
          event={props.entry.event as OrderDetailsHistoryEntryInitializeEvent}
        />
      ),
      eventType: OrderDetailsHistoryEventType.INITIALIZE,
    },
    {
      component: (
        <OrderDetailsHistoryEntryMergeOrdersContentComponent
          event={props.entry.event as OrderDetailsHistoryEntryMergeOrdersEvent}
        />
      ),
      eventType: OrderDetailsHistoryEventType.MERGE_ORDERS,
    },
    {
      component: (
        <OrderDetailsHistoryEntryOperationalApprovalContentComponent
          event={
            props.entry
              .event as OrderDetailsHistoryEntryOperationalApprovalEvent
          }
        />
      ),
      eventType: OrderDetailsHistoryEventType.OPERATIONAL_APPROVAL,
    },
    {
      component: (
        <OrderDetailsHistoryEntryRequestCancelOrderContentComponent
          event={
            props.entry.event as OrderDetailsHistoryEntryRequestCancelOrderEvent
          }
        />
      ),
      eventType: OrderDetailsHistoryEventType.REQUEST_CANCEL_ORDER,
    },
    {
      component: (
        <OrderDetailsHistoryEntryShuffleMergeContentComponent
          event={props.entry.event as OrderDetailsHistoryEntryShuffleMergeEvent}
        />
      ),
      eventType: OrderDetailsHistoryEventType.SHUFFLE_MERGE,
    },
    {
      component: (
        <OrderDetailsHistoryEntryForwardContentComponent
          event={props.entry.event as OrderDetailsTHandlingLogEntryForwardEvent}
        />
      ),
      eventType: OrderDetailsHistoryEventType.FORWARD_ADD,
    },
    {
      component: (
        <OrderDetailsHistoryEntryForwardCancelContentComponent
          event={
            props.entry.event as OrderDetailsTHandlingLogEntryForwardCancelEvent
          }
        />
      ),
      eventType: OrderDetailsHistoryEventType.FORWARD_CANCEL,
    },
    {
      component: (
        <OrderDetailsHistoryEntryAssignmentOrderNewPlanContentComponent
          event={
            props.entry
              .event as OrderDetailsTHandlingLogAssignmentOrderNewPlanEvent
          }
        />
      ),
      eventType: OrderDetailsHistoryEventType.ACCEPTED_CPE_CANDIDATURE,
    },
    {
      component: (
        <OrderDetailsHistoryEntryAssignmentOrderExistingPlanContentComponent
          event={
            props.entry
              .event as OrderDetailsTHandlingLogAssignmentOrderExistingPlanEvent
          }
        />
      ),
      eventType: OrderDetailsHistoryEventType.ACCEPTED_CSO_CANDIDATURE,
    },
    {
      component: (
        <OrderDetailsHistoryEntryAssignmentOrderCancelContentComponent
          event={
            props.entry
              .event as OrderDetailsTHandlingLogAssignmentOrderCancelEvent
          }
        />
      ),
      eventType: OrderDetailsHistoryEventType.ACCEPTED_DSO_CANDIDATURE,
    },
    {
      component: (
        <OrderDetailsHistoryEntryRevertCancelOrderRequestContentComponent
          event={
            props.entry
              .event as OrderDetailsHistoryEventRevertCancelOrderRequestEvent
          }
        />
      ),
      eventType: OrderDetailsHistoryEventType.REVERT_CANCEL_ORDER_REQUEST,
    },
  ];

  const Content =
    contentOptions.find((item) => item.eventType === props.entry.event?.type)
      ?.component ?? null;

  return (
    <li className="order_details_history_entry_list_item">
      <div className="order_details_history_entry_list_item_meta">
        <div className="order_details_history_entry_list_item_date">
          {dateService.formatDateTime(props.entry.date)}
        </div>
        <div className="order_details_history_entry_list_item_author">
          {props.entry.authorDisplayName}
        </div>
      </div>
      {Content}
    </li>
  );
};

export default OrderDetailsHistoryEntryComponent;
