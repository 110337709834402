import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import OrderDetailsToolButtonComponent from "../../common/tool-button/order-details-tool-button.component";
import OrderDetailsToolsTranslations from "../../../../../languages/types/order/details/order-detials-tools.translation";
import { FC } from "react";

type OrderDetailsFilteredOrderComponentProps = {
  tools: OrderDetailsToolsTranslations;
  onSuccess: () => void;
};

const OrderDetailsFilteredOrderComponent: FC<
  OrderDetailsFilteredOrderComponentProps
> = ({ tools, onSuccess }) => {
  const { filteredOrderButtonTitle } = tools;

  return (
    <OrderDetailsToolButtonComponent
      onClick={onSuccess}
      icon={faUpRightFromSquare}
      title={filteredOrderButtonTitle}
    />
  );
};

export default OrderDetailsFilteredOrderComponent;
