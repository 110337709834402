import { CargoDispatchRouteQueryParams } from "./types/cargo-dispatch-listing-route-params";
import useRouteQueryParams from "../../../../../common/hooks/use-route-query-params";
import cargoCompanyListingService from "./cargo-dispatch-listing.service";

const useCargoDispatchListingDao = () => {
  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<CargoDispatchRouteQueryParams>();

  const getPage = (): number | undefined => {
    return cargoCompanyListingService.getPage(routeQueryParams);
  };

  const getPageSize = (): number | undefined => {
    return cargoCompanyListingService.getPageSize(routeQueryParams);
  };

  const saveParams = (page: number, pageSize: number): void => {
    const routeQueryParams = cargoCompanyListingService.createRouteQueryParams(
      page,
      pageSize
    );

    setRouteQueryParams(routeQueryParams);
  };

  return {
    getPage,
    getPageSize,
    saveParams,
  };
};

export default useCargoDispatchListingDao;
