import { FC, useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../../../../context/app.context";
import { BillingsOrderForwardTaxiDriverItem } from "../../../../../../common/services/billings/order-forward/taxi-driver/billings-order-forward-taxi-driver";
import useBillingsOrderForwardTaxiDriver from "../../../../../../common/services/billings/order-forward/taxi-driver/use-billings-order-forward-taxi-driver";
import useAbort from "../../../../../../common/hooks/use-abort";
import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";
import CardComponent from "../../../../../../common/components/card/card.component";
import RelatedBillingsTaxiDriverTableRow from "../../../../common/related-billings/taxi-driver/types/related-billings-taxi-driver-table-row";
import RelatedBillingsTaxiDriverTableComponent from "../../../../common/related-billings/taxi-driver/related-billings-taxi-driver-table.component";
import relatedBillingsTaxiDriverFactory from "./related-billings-taxi-driver.factory";

type RelatedBillingsTaxiDriverProps = {
  planEntryId: string | undefined;
  forwardingId: string;
};

const RelatedBillingsTaxiDriverComponent: FC<RelatedBillingsTaxiDriverProps> = (
  props
) => {
  const translations =
    billingsTranslationsHelper.getBillingsTranslations().relatedBillings
      .taxi_driver;

  const [relatedBillingTaxiDriverData, setRelatedBillingsTaxiDriverData] =
    useState<BillingsOrderForwardTaxiDriverItem[]>([]);

  const { selectedAppLanguage } = useAppContext();
  const taxiDriver = useBillingsOrderForwardTaxiDriver();
  const taxiDriverAbort = useAbort();

  const taxiDriverData = async () => {
    taxiDriver.load(
      { forwardingUuid: props.forwardingId },
      taxiDriverAbort.signal
    );
  };

  useEffect(() => {
    if (!props.forwardingId) return;

    taxiDriverData();
  }, [props.forwardingId]);

  useEffect(() => {
    if (!taxiDriver.data.data) return;

    setRelatedBillingsTaxiDriverData(taxiDriver.data.data);
  }, [taxiDriver.data.data]);

  const tableRows: RelatedBillingsTaxiDriverTableRow[] = useMemo(() => {
    if (!relatedBillingTaxiDriverData || !props.planEntryId) return [];

    return relatedBillingsTaxiDriverFactory.createTableRows(
      relatedBillingTaxiDriverData,
      props.planEntryId
    );
  }, [relatedBillingTaxiDriverData, selectedAppLanguage, props.planEntryId]);

  return (
    <CardComponent
      classNames={{ root: "related_billings" }}
      header={{ title: translations.summaryLabel }}
    >
      <RelatedBillingsTaxiDriverTableComponent
        tableRows={tableRows}
        isLoading={taxiDriver.isLoading && !!props.forwardingId}
      />
    </CardComponent>
  );
};

export default RelatedBillingsTaxiDriverComponent;
