import { FC } from "react";
import CargoCargoTaxiContractListingFilter from "../common/types/cargo-cargo-taxi-contract-listing-filter";
import CargoCargoTaxiContractListingSortKey from "../common/types/cargo-cargo-taxi-contract-listing-sort-key";
import ListingFilterDefinition from "../../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition from "../../../../../common/components/listing/filter/types/listing-sort-definition";
import ListingFilterComponent from "../../../../../common/components/listing/filter/listing-filter.component";
import cargoCargoTaxiContractListingFilterHelper from "./cargo-cargo-taxi-contract-listing-filter.helper";

type CargoCargoTaxiContractListingFilterProps = {
  filters: CargoCargoTaxiContractListingFilter[];
  onFiltersChange: (filters: CargoCargoTaxiContractListingFilter[]) => void;
  sortKey: CargoCargoTaxiContractListingSortKey;
  onSortKeyChange: (sortKey: CargoCargoTaxiContractListingSortKey) => void;
};

const CargoCargoTaxiContractListingFilterComponent: FC<
  CargoCargoTaxiContractListingFilterProps
> = (props) => {
  const filterDefinition: ListingFilterDefinition<CargoCargoTaxiContractListingFilter> =
    cargoCargoTaxiContractListingFilterHelper().getFilterDefinition();
  const sortDefinition: ListingSortDefinition =
    cargoCargoTaxiContractListingFilterHelper().getSortDefinition();

  return (
    <ListingFilterComponent
      appliedFilters={props.filters}
      filterDefinition={filterDefinition}
      onFiltersChange={(appliedFilters) => {
        props.onFiltersChange(
          appliedFilters as CargoCargoTaxiContractListingFilter[]
        );
      }}
      appliedSortKey={props.sortKey}
      onSortKeyChange={(value) =>
        props.onSortKeyChange(value as CargoCargoTaxiContractListingSortKey)
      }
      sortDefinition={sortDefinition}
      idForTestingFilter="cargo-cargo-taxi-contract-listing-filter"
      idForTestingSort="cargo-cargo-taxi-contract-listing-sort"
    />
  );
};

export default CargoCargoTaxiContractListingFilterComponent;
