import HttpResponse from "../../../../../common/utils/http/http.response";

export type OrderDetailsResponseTransportingOrderCandidatureEntry = {
  driver: {
    id: string;
    first_name: string;
    last_name: string;
    mobile_primary_prefix: string;
    mobile_primary: string;
    mobile_secondary_prefix: string | null;
    mobile_secondary: string | null;
  };
};

export type OrderDetailsResponseTransportingOrderDriver = {
  id: string;
  first_name: string;
  last_name: string;
  mobile_primary_prefix: string;
  mobile_primary: string;
  mobile_secondary_prefix: string | null;
  mobile_secondary: string | null;
};

export type OrderDetailsResponseTransportingOrderDriverSolve = {
  node_id: string;
  lat: number;
  lon: number;
  requested_time: string | null;
  requested_halting_time: number;
  display_name: string;
  cargo_enter: string[];
  cargo_exit: string[];
  estimated_time: string;
  checkout_event: OrderDetailsResponseTransportingOrderSolveCheckoutEvent | null;
  distance: number;
};

export type OrderDetailsResponseTransportingOrderSolveCheckoutEvent = {
  achieved_at: string;
  halting_time: string | null;
};

export type OrderDetailsResponseTransportingOrderSolve = {
  node_id: string;
  lat: number;
  lon: number;
  requested_time: string | null;
  requested_halting_time: number;
  display_name: string;
  cargo_enter: string[];
  cargo_exit: string[];
  estimated_time: string;
  checkout_event: OrderDetailsResponseTransportingOrderSolveCheckoutEvent | null;
  distance: number;
};

export enum OrderDetailsResponseTransportingOrderPassengerType {
  EXTERNAL = "EXTERNAL",
  INTERNAL = "INTERNAL",
}

export type OrderDetailsResponseTransportingOrderInternalPassenger = {
  item_type: OrderDetailsResponseTransportingOrderPassengerType.INTERNAL;
  first_name: string;
  last_name: string;
  phone_prefix: string;
  phone_no: string;
  cargo_item_id: string;
  passenger_id: string;
};

export type OrderDetailsResponseTransportingOrderExternalPassenger = {
  item_type: OrderDetailsResponseTransportingOrderPassengerType.EXTERNAL;
  first_name: string;
  last_name: string;
  phone_prefix: string;
  phone_no: string;
  cargo_item_id: string;
};

export type OrderDetailsResponseTransportingOrderPassenger =
  | OrderDetailsResponseTransportingOrderInternalPassenger
  | OrderDetailsResponseTransportingOrderExternalPassenger;

export type OrderDetailsResponseTransportingOrder = {
  id: string;
  driver: OrderDetailsResponseTransportingOrderDriver | null;
  driver_solve: OrderDetailsResponseTransportingOrderDriverSolve[] | null;
  passengers: OrderDetailsResponseTransportingOrderPassenger[];
  solve: (
    | OrderDetailsResponseTransportingOrderSolve
    | OrderDetailsResponseTransportingOrderSolve[]
  )[];
  create_solved_order_entries: OrderDetailsResponseTransportingOrderCandidatureEntry[];
  create_plan_entry_entries: OrderDetailsResponseTransportingOrderCandidatureEntry[];
  plan_entry_human_id: number | null;
  plan_entry_id: string | null;
};

export type OrderDetailsResponseData = {
  id: string;
  is_published: boolean | null;
  target_taxi_id: string | null;
  is_finished: boolean;
  external_id: string | null;
  human_id: number;
  is_cancelled: boolean;
  is_cancel_requested: boolean;
  approving_dispatcher_id: string | null;
  approving_operator_id: string | null;
  approving_dealer_id: string | null;
  dispatcher: {
    id: string;
  };
  transporting_orders: OrderDetailsResponseTransportingOrder[];
  contact_passenger_after_minutes: number;
  cancel_by_taxi_allowed: boolean;
  edit_by_taxi_allowed: boolean;
};

type OrderDetailsResponse = HttpResponse<OrderDetailsResponseData>;

export default OrderDetailsResponse;
