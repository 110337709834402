import { FormValidationDefinition } from "../../../../../common/components/form/use-form";
import taxiCargoContractAddFormValidationService from "../taxi-cargo-contract-add-form-validation.service";
import TaxiCargoContractAddFormData from "./types/taxi-cargo-contract-add-form-data";

const getEmptyFormData = (): TaxiCargoContractAddFormData => {
  return {
    billingModel: null,
    discountPercentLimit: null,
    distanceRate: null,
    freeStoppingPeriodMinutes: null,
    isOrderPublishingAllowed: null,
    isTollRoadsDuringOrderAllowed: null,
    isTollRoadsWhileArrivalAllowed: null,
    isTollRoadsWhileReturningAllowed: null,
    periodOfValidity: null,
    stoppingRate: null,
    cargoCompanyUuid: "",
    isActive: null,
    contactPassengerAfterMinutes: null,
    cancelOrderByTaxiAllowed: null,
    editOrderByTaxiAllowed: null,
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<TaxiCargoContractAddFormData> => {
    return {
      billingModel: (values) =>
        taxiCargoContractAddFormValidationService.validateBillingModel(
          values.billingModel
        ),
      discountPercentLimit: (values) =>
        taxiCargoContractAddFormValidationService.validateDiscountPercentLimit(
          values.discountPercentLimit
        ),
      distanceRate: (values) =>
        taxiCargoContractAddFormValidationService.validateDistanceRate(
          values.distanceRate
        ),
      freeStoppingPeriodMinutes: (values) =>
        taxiCargoContractAddFormValidationService.validateFreeStoppingPeriodMinutes(
          values.freeStoppingPeriodMinutes
        ),
      isOrderPublishingAllowed: (values) =>
        taxiCargoContractAddFormValidationService.validateIsOrderPublishingAllowed(
          values.isOrderPublishingAllowed
        ),
      isTollRoadsDuringOrderAllowed: (values) =>
        taxiCargoContractAddFormValidationService.validateIsTollRoadsDuringOrderAllowed(
          values.isTollRoadsDuringOrderAllowed
        ),
      isTollRoadsWhileArrivalAllowed: (values) =>
        taxiCargoContractAddFormValidationService.validateIsTollRoadsWhileArrivalAllowed(
          values.isTollRoadsWhileArrivalAllowed
        ),
      isTollRoadsWhileReturningAllowed: (values) =>
        taxiCargoContractAddFormValidationService.validateIsTollRoadsWhileReturningAllowed(
          values.isTollRoadsWhileReturningAllowed
        ),
      periodOfValidity: (values) =>
        taxiCargoContractAddFormValidationService.validatePeriodOfValidity(
          values.periodOfValidity
        ),
      stoppingRate: (values) =>
        taxiCargoContractAddFormValidationService.validateStoppingRate(
          values.stoppingRate
        ),
      cargoCompanyUuid: (values) =>
        taxiCargoContractAddFormValidationService.validateCargoCompany(
          values.cargoCompanyUuid
        ),
      isActive: (values) =>
        taxiCargoContractAddFormValidationService.validateActivity(
          values.isActive
        ),
      contactPassengerAfterMinutes: (values) =>
        taxiCargoContractAddFormValidationService.validateContactPassengerAfterMinutes(
          values.contactPassengerAfterMinutes
        ),
      cancelOrderByTaxiAllowed: (values) =>
        taxiCargoContractAddFormValidationService.validateCancelOrderByTaxiAllowed(
          values.cancelOrderByTaxiAllowed
        ),
      editOrderByTaxiAllowed: (values) =>
        taxiCargoContractAddFormValidationService.validateEditOrderByTaxiAllowed(
          values.editOrderByTaxiAllowed
        ),
    };
  };

const taxiCargoContractAddFormHelper = {
  getEmptyFormData,
  getValidationDefinition,
};

export default taxiCargoContractAddFormHelper;
