import orderOptimizerTranslationsHelper from "../../../../../../languages/order-optimizer-translations.helper";
import OrderOptimizerDetailsHiddenDetailsHeadingTablesDriverTableColumn from "../types/order-optimizer-details-hidden-details-heading-tables-driver-table-column";

const getColumns =
  (): OrderOptimizerDetailsHiddenDetailsHeadingTablesDriverTableColumn[] => {
    const translations =
      orderOptimizerTranslationsHelper.getDetailsTranslations().table
        .headingTables.driver;

    const tableColumns: OrderOptimizerDetailsHiddenDetailsHeadingTablesDriverTableColumn[] =
      [
        {
          header: translations.totalTimeLabel,
          title: translations.totalTimeTitle,
          accessor: "totalTime",
          colSpan: 3,
        },
        {
          header: translations.haltingTimeLabel,
          title: translations.haltingTimeTitle,
          accessor: "haltingTime",
          colSpan: 3,
        },
        {
          header: translations.totalDrivingTimeLabel,
          title: translations.totalDrivingTimeTitle,
          accessor: "totalDrivingTime",
          colSpan: 3,
        },
        {
          header: translations.distanceLabel,
          title: translations.distanceTitle,
          accessor: "distance",
          colSpan: 3,
        },
      ];

    return tableColumns;
  };

const orderOptimizerDetailsHiddenDetailsHeadingTablesDriverTableHelper = {
  getColumns,
};

export default orderOptimizerDetailsHiddenDetailsHeadingTablesDriverTableHelper;
