import { FC, useEffect, useMemo, useState } from "react";
import FormValidationResult from "../../../../../../../common/utils/validation/types/form-validation-result";
import { useAppContext } from "../../../../../../../context/app.context";
import userTranslationsHelper from "../../../../../../../languages/user-translations.helper";
import useTaxiFleetPartnerList from "../../../../../../../common/services/taxi-fleet-partner/list/use-taxi-fleet-partner-list";
import useAbort from "../../../../../../../common/hooks/use-abort";
import DriverEditFormOfEmploymentTypeSelectOption from "../../types/driver-edit-form-of-employment-type-select-option";
import driverEditCitizenshipTypeFactory from "../../factory/driver-edit-user-data.factory";
import DriverEditUserFleetPartnerSelectOption from "../../types/driver-edit-user-fleet-partner-select-option";
import CardComponent from "../../../../../../../common/components/card/card.component";
import Row from "../../../../../../../common/components/grid/row";
import Column from "../../../../../../../common/components/grid/column";
import FormFieldComponent from "../../../../../../../common/components/form/field/form-field.component";
import SingleSelectComponent from "../../../../../../../common/components/form/select/single-select/single-select.component";
import TaxiFleetPartnerListLoadParams from "../../../../../../../common/services/taxi-fleet-partner/list/taxi-fleet-partner-list-load-params";
import DriverEditTaxiCorporationFormData from "../../factory/driver-edit-taxi-corporation-form-data";
import DriverEditTaxiCorporationSelectOption from "../../types/driver-edit-taxi-corporation-select-option";
import RailyDriverEditCompanyDataComponent from "../company/raily-driver-edit-company.component";

type DriverEditTaxiCorporationProps = {
  validateTaxiCorporation: (
    formKey: keyof DriverEditTaxiCorporationFormData,
    value?: unknown
  ) => Promise<boolean>;
  onTaxiCorporationChange: (
    formKey: keyof DriverEditTaxiCorporationFormData,
    value: any
  ) => void;
  taxiCorporationValidationResults: Record<
    keyof DriverEditTaxiCorporationFormData,
    FormValidationResult
  >;
  taxiCorporationFormValues: DriverEditTaxiCorporationFormData;
  isCargoDataSectionVisible: boolean;
  formTaxiCorporationsData: DriverEditTaxiCorporationFormData[];
};

const DriverEditTaxiCorporationComponent: FC<DriverEditTaxiCorporationProps> = (
  props
) => {
  const { selectedAppLanguage } = useAppContext();

  const translations =
    userTranslationsHelper.getDriverEditTranslations().form.taxiCorporationData;

  const [taxiCorporationsSelectOptions, setTaxiCorporationsSelectOptions] =
    useState<DriverEditTaxiCorporationSelectOption[]>([]);

  const [fleetPartnerSelectOptions, setFleetPartnerSelectOptions] = useState<
    DriverEditUserFleetPartnerSelectOption[]
  >([]);

  const taxiFleetPartnerList = useTaxiFleetPartnerList();
  const taxiFleetPartnerListAbort = useAbort();

  const loadTaxiFleetPartner = async () => {
    if (!props.taxiCorporationFormValues.taxiCorporation?.uuid) {
      return;
    }

    const params: TaxiFleetPartnerListLoadParams = {
      taxiCorporationUuid:
        props.taxiCorporationFormValues.taxiCorporation?.uuid,
    };

    taxiFleetPartnerList.load(params, taxiFleetPartnerListAbort.signal);
  };

  useEffect(() => {
    if (props.taxiCorporationFormValues.taxiCorporation?.uuid) {
      loadTaxiFleetPartner();
    } else {
      setFleetPartnerSelectOptions([]);
    }
  }, [props.taxiCorporationFormValues.taxiCorporation?.uuid]);

  useEffect(() => {
    if (taxiFleetPartnerList.data) {
      setFleetPartnerSelectOptions(
        taxiFleetPartnerList.data.map((partner) => ({
          label: partner.name,
          value: partner,
        }))
      );
    }
  }, [taxiFleetPartnerList.data]);

  const formOfEmploymentSelectOptions = useMemo(
    driverEditCitizenshipTypeFactory.createFormOfEmploymentSelectOptions,
    [selectedAppLanguage]
  );

  const formOfEmploymentSelectOption = props.taxiCorporationFormValues
    .formOfEmployment
    ? formOfEmploymentSelectOptions.find(
        (option) =>
          option.value === props.taxiCorporationFormValues.formOfEmployment
      ) || null
    : null;

  const fleetPartnerSelectOption = props.taxiCorporationFormValues.fleetPartner
    ? fleetPartnerSelectOptions.find(
        (option) =>
          option.value.uuid ===
          props.taxiCorporationFormValues.fleetPartner?.uuid
      ) || null
    : null;

  const taxiCorporationSelectOption = props.taxiCorporationFormValues
    .taxiCorporation
    ? {
        label: props.taxiCorporationFormValues.taxiCorporation.displayName,
        value: props.taxiCorporationFormValues.taxiCorporation,
      }
    : null;

  const handleTaxiCorporationChange = async (
    value: DriverEditTaxiCorporationSelectOption | null
  ) => {
    if (!value) return;

    const isValid = await props.validateTaxiCorporation(
      "taxiCorporation",
      value.value
    );
    if (isValid) {
      props.onTaxiCorporationChange("taxiCorporation", value.value);
    }
  };

  const handleFormOfEmploymentChange = async (
    value: DriverEditFormOfEmploymentTypeSelectOption | null
  ) => {
    if (!value) return;

    const isValid = await props.validateTaxiCorporation(
      "formOfEmployment",
      value.value
    );
    if (isValid) {
      props.onTaxiCorporationChange("formOfEmployment", value.value);
    }
  };

  const handleFleetPartnerChange = async (
    value: DriverEditUserFleetPartnerSelectOption | null
  ) => {
    if (!value) return;

    const isValid = await props.validateTaxiCorporation(
      "fleetPartner",
      value.value
    );
    if (isValid) {
      props.onTaxiCorporationChange("fleetPartner", value.value);
    }
  };

  return (
    <CardComponent header={{ title: translations.headingText }}>
      <Row>
        <Column lg={4}>
          <FormFieldComponent
            label={translations.formOfEmploymentLabel}
            isRequired
            errorMessage={
              props.taxiCorporationValidationResults.formOfEmployment
                .errorMessage
            }
          >
            <SingleSelectComponent
              placeholder={translations.formOfEmploymentPlaceholder}
              value={formOfEmploymentSelectOption}
              options={formOfEmploymentSelectOptions}
              onChange={handleFormOfEmploymentChange}
              hasError={
                !!props.taxiCorporationValidationResults.formOfEmployment
                  .errorMessage
              }
              idForTesting={`user-data-form-of-employment-select`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={4}>
          <FormFieldComponent
            label={translations.taxiCorporationLabel}
            isRequired
            errorMessage={
              props.taxiCorporationValidationResults.taxiCorporation
                .errorMessage
            }
          >
            <SingleSelectComponent
              placeholder={translations.taxiCorporationPlaceholder}
              value={taxiCorporationSelectOption}
              options={taxiCorporationsSelectOptions}
              isSearchable
              onChange={handleTaxiCorporationChange}
              hasError={
                !!props.taxiCorporationValidationResults.taxiCorporation
                  .errorMessage
              }
              isDisabled
              idForTesting={`user-data-taxi-corporation-select`}
            />
          </FormFieldComponent>
        </Column>
        <Column lg={4}>
          <FormFieldComponent
            label={translations.fleetPartnerLabel}
            errorMessage={
              props.taxiCorporationValidationResults.fleetPartner.errorMessage
            }
          >
            <SingleSelectComponent
              placeholder={translations.fleetPartnerPlaceholder}
              value={fleetPartnerSelectOption}
              options={fleetPartnerSelectOptions}
              onChange={handleFleetPartnerChange}
              isClearable
              isLoading={
                props.taxiCorporationFormValues.taxiCorporation?.uuid
                  ? taxiFleetPartnerList.isLoading
                  : false
              }
              isDisabled={
                taxiFleetPartnerList.isError ||
                !props.taxiCorporationFormValues.taxiCorporation
              }
              hasError={
                !!props.taxiCorporationValidationResults.fleetPartner
                  .errorMessage
              }
              idForTesting={`user-data-fleet-partner-select`}
            />
          </FormFieldComponent>
        </Column>
      </Row>
      {props.isCargoDataSectionVisible && (
        <Row>
          <Column withPaddings>
            <RailyDriverEditCompanyDataComponent
              onCompanyDataChange={props.onTaxiCorporationChange}
              validateCompanyData={props.validateTaxiCorporation}
              companyFormValues={props.taxiCorporationFormValues}
              companyValidationResults={props.taxiCorporationValidationResults}
            />
          </Column>
        </Row>
      )}
    </CardComponent>
  );
};

export default DriverEditTaxiCorporationComponent;
